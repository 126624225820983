import React, { useState } from "react";
import { Button, Col, Row, Space, Input } from "antd";
import { ImportOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const FormVeBus = ({ dataAdd, keySide, handleSearch }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onClickAdd = () => {
        switch (keySide) {
            case 10:
                return navigate("/ncc/ve-bus/new");
            default:
                return null;
        }
    };
    const onSearch = (value) => {
        handleSearch(value);
    };
    return (
        <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
            <Col xs={9} sm={9} md={9} lg={9} xl={9}></Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Space size="large">
                    {/*<Button icon={<DownloadOutlined/>} type="default">*/}
                    {/*    {t("exportFile")}*/}
                    {/*</Button>*/}
                    {/*<Button icon={<ImportOutlined/>} type="dashed">*/}
                    {/*    {t("importFile")}*/}
                    {/*</Button>*/}
                    <Button onClick={onClickAdd} icon={<PlusOutlined />} type="primary">
                        {t("add")}
                    </Button>
                </Space>
            </Col>
        </Row>
    );
};
export default FormVeBus;
