import { Badge, Col, Collapse, Radio, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewService from "./NewService";
import TongKet from "./TongKet";
import ViewDate from "./ViewDate";
import DichVuTourService from "../../../../../api/dieu_hanh/sale_rate/DichVuTourService";
import { Button } from "@mui/material";

const style = {
  h4: { fontSize: "16px", fontWeight: "bold" },
};
const BaoGia = ({ opDichVuId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("0");
  const [tongGia, setTongGia] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loaiHienThi, setLoaiHienThi] = useState(0);
  const [countNoti, setCountNoti] = useState({
    tatCa: 0,
    daCheck: 0,
    chuaCheck: 0,
  });
  const [usingVat, setUsingVat] = useState(false);
  const [vat, setVat] = useState(0);
  const [dataSource, setDataSource] = useState({});
  const [dataNhomNgLon, setDataNhomNgLon] = useState([]);
  const [dataNhomTreEm, setDataNhomTreEm] = useState([]);
  const [reload, setReload] = useState(false);
  const options = [
    {
      label: t("hienThiTheoTungNgay"),
      value: 0,
    },
    {
      label: t("hienThiTheoTungDichVu"),
      value: 1,
    },
  ];
  useEffect(() => {
    let data = {
      opThongTinChungId: "6",
      trangThai: status, // 0: Tất cả, 1: Đã check, 2: Chưa check
      opDichVuId: 0, //opDichVuId, // 0 là mặc định mới vào màn. nếu vào rồi mà chọn dịch vụ tour thì sẽ > 0
    };
    debugger;
    getData(data);
    getDataNhomNgLonVaTreEm(opDichVuId);
  }, [status, opDichVuId, reload]);
  const getData = async (data) => {
    let response = await DichVuTourService.layTTSaleRateBaoGia(data);
    if (response.status === 200 && response.data.code === "00") {
      debugger;
      // console.log(JSON.stringify(response.data.data));
      setDataSource(response.data.data);
      setVat(response.data.data.vat);
      setUsingVat(response.data.data.ususingVat);
      setCountNoti({
        tatCa: response.data.data.tatCa,
        daCheck: response.data.data.daCheck,
        chuaCheck: response.data.data.chuaCheck,
      });
      setTongGia(response.data.data.tongGias);
      setLoaiHienThi(response.data.data.loaiHienThi);
    }
  };

  const getDataNhomNgLonVaTreEm = (opDichVuId) => {
    let dataReq = { opDichVuId };
    DichVuTourService.getDataNhomNgLonVaTreEm(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          setDataNhomNgLon(res.data.data.nhomNguoiLons);
          setDataNhomTreEm(res.data.data.nhomTreEms);
        }
      })
      .catch((err) => console.log("GetDataNhomNgLonVaTreEm Error ..." + err));
  };

  const handleReloadData = () => {
    setReload(!reload);
  };

  const cauHinh = [
    {
      key: "1",
      label: (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={17}>
            <div
              style={{
                textTransform: "uppercase",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{t("serviceProvided")}</div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={7}>
            <Radio.Group
              disabled
              style={{ marginTop: "10px" }}
              options={options}
              // onChange={onChangeRadio}
              value={loaiHienThi}
            />
          </Col>
        </Row>
      ),
      children: (
        <>
          {loaiHienThi === 0 ? (
            <ViewDate
              dataSource={dataSource}
              bgHanhTrinhs={dataSource.hanhTrinhs}
              opDichVuId={opDichVuId}
              dataNhomNgLon={dataNhomNgLon}
              dataNhomTreEm={dataNhomTreEm}
              soLuongTreEm={dataSource.soLuongTreEm}
              soLuongNgLon={dataSource.soLuongNguoiLon}
              onReload={handleReloadData}
            />
          ) : (
            <NewService
              dichVuNguoiLons={dataSource.dichVuNguoiLons}
              dichVuTreEms={dataSource.dichVuTreEms}
              bgHanhTrinhs={dataSource.hanhTrinhs}
              soLuongTreEm={dataSource.soLuongTreEm}
              soLuongNgLon={dataSource.soLuongNguoiLon}
              soNgay={dataSource.soNgay}
              opDichVuId={opDichVuId}
              dataNhomNgLon={dataNhomNgLon}
              dataNhomTreEm={dataNhomTreEm}
              onReload={handleReloadData}
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("totalPrice")}
        </div>
      ),
      children: (
        <>
          <TongKet
            // baoGiaTongQuanId={baoGiaTongQuanId}  setReload={setReload}
            tongGia={tongGia}
            vat={vat}
            usingVat={usingVat}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Space size={20}>
          <Badge count={countNoti.tatCa} key={0}>
            <Button
              disabled={status === "0"}
              variant="outlined"
              onClick={() => setStatus("0")}
            >
              {t("tatCa")}
            </Button>
          </Badge>
          <Badge count={countNoti.daCheck} key={1}>
            <Button
              disabled={status === "1"}
              variant="outlined"
              onClick={() => setStatus("1")}
            >
              {t("checked")}
            </Button>
          </Badge>
          <Badge count={countNoti.chuaCheck} key={2}>
            <Button
              disabled={status === "2"}
              variant="outlined"
              onClick={() => setStatus("2")}
            >
              {t("unchecked")}
            </Button>
          </Badge>
        </Space>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Collapse defaultActiveKey={"1"} items={cauHinh} />
        </Col>
      </Row>
    </>
  );
};
export default BaoGia;
