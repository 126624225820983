import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getChietTinh = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getData", data, {
    headers: authHeader(),
  });
};
const onSaveThongTinTE = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveGiaTreEm", data, {
    headers: authHeader(),
  });
};
const saveQuanLyKhoangKhach = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/saveQuanLyKhoangKhach",
    data,
    { headers: authHeader() }
  );
};
const getQuanLyKhoangKhach = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/getQuanLyKhoangKhach",
    data,
    { headers: authHeader() }
  );
};
const deleteQuanLyKhoangKhach = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/deleteQuanLyKhoangKhach",
    data,
    { headers: authHeader() }
  );
};
const saveThoiGianApDung = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveThoiGianApDung", data, {
    headers: authHeader(),
  });
};
const getThoiGianApDung = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getThoiGianApDung", data, {
    headers: authHeader(),
  });
};
const deleteThoiGianApDung = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/deleteThoiGianApDung",
    data,
    { headers: authHeader() }
  );
};
const getGiaTreEm = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getGiaTreEm", data, {
    headers: authHeader(),
  });
};
const saveTabChietTinh = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveTabChietTinh", data, {
    headers: authHeader(),
  });
};

const deleteTabChietTinh = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaTabChietTinh", data, {
    headers: authHeader(),
  });
};

const updateTyGia = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/updateTyGia", data, {
    headers: authHeader(),
  });
};

const changeLoaiTong = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/changeLoaiTong", data, {
    headers: authHeader(),
  });
};

const layCauHinhMucGia = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/layCauHinhMucGia", data, {
    headers: authHeader(),
  });
};

export default {
  getChietTinh,
  saveQuanLyKhoangKhach,
  getQuanLyKhoangKhach,
  deleteQuanLyKhoangKhach,
  saveThoiGianApDung,
  getThoiGianApDung,
  deleteThoiGianApDung,
  getGiaTreEm,
  onSaveThongTinTE,
  saveTabChietTinh,
  updateTyGia,
  changeLoaiTong,
  deleteTabChietTinh,
  layCauHinhMucGia,
};
