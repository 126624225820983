import axios from "axios";
import authHeader from "../../../auth_header";
import { API_BASE_URL } from "../../../../constants";

const layDsOpKhachSan = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpKhachSan", data, {
    headers: authHeader(),
  });
};

const saveOpKhachSan = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpKhachSan", data, {
    headers: authHeader(),
  });
};

const layDsOpNhaHang = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpNhaHang", data, {
    headers: authHeader(),
  });
};

const saveOpNhaHang = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpNhaHang", data, {
    headers: authHeader(),
  });
};

const layDsOpOto = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpOto", data, {
    headers: authHeader(),
  });
};

const saveOpOto = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpOto", data, {
    headers: authHeader(),
  });
};

export default {
  layDsOpKhachSan,
  saveOpKhachSan,
  layDsOpNhaHang,
  saveOpNhaHang,
  layDsOpOto,
  saveOpOto,
};
