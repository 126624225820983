export default function authHeader_download() {
    const user = JSON.parse(localStorage.getItem("auth"));
    console.log("====", user)
    if (user && user.accessToken) {
        // For Spring Boot back-end
        return {
            responseType: "arraybuffer",
            Authorization: "Bearer " + user.accessToken };

    } else {
        return {};
    }
}


