import React, { useState } from "react";
import { Modal, Input, Button, Row, Col, Form, Space, Divider } from "antd";
import { useTranslation } from "react-i18next";

const ModalTtEdit = ({ isOpen, handleCancel, handleFinish, record }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const onFinish = (values) => {
        setIsModalOpen(false);
        let data = {
            id: record.id,
            mo_ta: values.mo_ta,
        };
        handleFinish(isModalOpen, data);
        form.resetFields();
    };
    const handleClear = () => {
        form.resetFields();
    };
    const dataRender = () => {
        let html;
        if (record !== null || record !== "") {
            form.setFieldsValue({
                loai_tour: record.loai_tour,
                mo_ta: record.mo_ta,
            });
        }
        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col span={18} push={6}>
                        <Form.Item
                            name="loai_tour"
                            rules={[
                                {
                                    required: true,
                                    message: t("validate") + " " + t("typeTour").toLowerCase(),
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6} pull={18}>
                        {t("typeTour")}
                    </Col>
                </Row>
                <Row>
                    <Col span={18} push={6}>
                        <Form.Item
                            name="mo_ta"
                            rules={[
                                {
                                    required: true,
                                    message: t("validate") + " " + t("typeTour.description").toLowerCase(),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6} pull={18}>
                        {t("typeTour.description")}
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    return (
        <div>
            <Modal
                title={t("add") + " " + t("typeOfTourism").toLowerCase()}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancel}
                footer={null}
            >
                <Divider />
                <Form
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t("action.ok")}
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#898989",
                                    color: "white",
                                }}
                                key="clear"
                                onClick={(e) => handleClear(e)}
                            >
                                {t("action.reset")}
                            </Button>
                            <Button key="back" onClick={handleCancel}>
                                {t("action.cancel")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ModalTtEdit;
