import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDmTrangThai = (data) => {
    return axios.post(API_BASE_URL + "category/getDmTrangThai", data, { headers: authHeader() });
};

export default {
    getDmTrangThai,
};
