import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import ModalLP from "./ModalLP";
const { Search } = Input;

const FormLp = ({ dataAdd, handleSearch }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleFinish = (status, values) => {
        setIsModalOpen(status);
        setIsModalOpen(false);
        let data = {
            ...values,
            id: 0,
            nccThongTinChungId: 5,
            kichThuocPhong: values.kichThuocPhong !== undefined ? values.kichThuocPhong : "",
            foc: values.foc !== undefined ? values.foc : "",
            poc: values.foc !== undefined ? values.poc : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
        };
        console.log("data=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({});
                            dataLstSearch.push({});
                        }
                        dataAdd(dataLst, dataLstSearch);
                    }
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };

    const onSearch = (value) => {
        handleSearch(value);
    };
    return (
        <Row>
            {contextHolder}
            <Col
                xs={{
                    span: 6,
                }}
                lg={{
                    span: 6,
                }}
            ></Col>
            <Col
                xs={{
                    span: 11,
                    offset: 1,
                }}
                lg={{
                    span: 6,
                    offset: 2,
                }}
            ></Col>
            <Col
                xs={{
                    span: 5,
                    offset: 1,
                }}
                lg={{
                    span: 6,
                    offset: 2,
                }}
            >
                <Space size="large">
                    <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                        {t("add")}
                    </Button>
                    <ModalLP isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleFinish} />
                </Space>
            </Col>
        </Row>
    );
};
export default FormLp;
