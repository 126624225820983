import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";


const layTTBkThongTinChungById = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTBkThongTinChungById", data,  { headers: authHeader() });
};

const saveBkThongTinChung = (data) => {
    return axios.post(API_BASE_URL + "booking/saveBkThongTinChung", data,  { headers: authHeader() });
};
export default {
    layTTBkThongTinChungById,
    saveBkThongTinChung
}