import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Link, MenuItem, TextField, Box } from "@mui/material";
import Buttons from "@mui/material/Button";
import {
  Col,
  Divider,
  Modal,
  Row,
  Space,
  notification,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccKhachSan from "../../../modalNccDvLe/ModalThongTinNccKhachSan";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import {
  API_BASE_URL,
  maPhongDon,
  maPhuPhi,
} from "../../../../../../constants";
import authHeader from "../../../../../../api/auth_header";
import dayjs from "dayjs";
import moment from "moment";
import DichVuLeService from "../../../../../../api/dieu_hanh/sale_rate/DichVuLeService";

const ModalCapNhatKS = ({
  isOpen,
  onReset,
  handleCancel,
  tranxOpSaleRateId,
  hanhDong,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [dmGia, setDmGia] = useState([]);
  const [isShowNccKS, setIsShowNccKS] = useState(false);
  const { t } = useTranslation();
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [dataSource, setDataSource] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    dieuKienFoc: 0,
    foc: 0,
    ngayApDungs: [],
    soNgaySuDung: 0,
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    dieuKienFoc: 0,
    foc: 0,
    ngayApDungs: [],
    soNgaySuDung: 0,
  });
  const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
  const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
  const [action, setAction] = useState("");
  const [idxEdit, setIdxEdit] = useState(0);
  const [dmVat, setDmVat] = useState([]);
  const [reset, setReset] = useState(false);
  const [maNcc, setMaNcc] = useState("");
  const [dmKieuPhong, setDmKieuPhong] = useState([]);
  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmVat();
    getDmKieuPhong();
  }, []);

  useEffect(() => {
    if (hanhDong === "EDIT" && isOpen) {
      getKhachSanDetails(tranxOpSaleRateId);
    }
  }, [hanhDong, tranxOpSaleRateId, isOpen]);

  useEffect(() => {
    if (hanhDong === "ADD") {
      setDichVus([{ id: "NULL" }]);
      setDichVusInit([{ id: "NULL" }]);
      setDataSource({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        dieuKienFoc: 0,
        foc: 0,
        moTa: "",
        ngayApDungs: [],
        soNgaySuDung: 0,
      });
      setDataSourceInit({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        dieuKienFoc: 0,
        foc: 0,
        moTa: "",
        ngayApDungs: [],
        soNgaySuDung: 0,
      });
    }
  }, [isOpen]);

  let formatter = new Intl.NumberFormat("en-US", {});

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = formatter.format(value);
    }
    return number;
  };

  const tongChiPhiDichVu = useMemo(() => {
    let tongChiPhi = dichVus.reduce((sum, item) => {
      if (item.id !== "NULL" && !isNaN(item.tong)) sum += parseFloat(item.tong);
      return sum;
    }, 0);
    return tongChiPhi * dataSource.soNgaySuDung;
  }, [dichVus, dataSource.soNgaySuDung]);

  const foc = useMemo(() => {
    let lstdichVu = dichVus.filter((it) => it.id !== "NULL");
    let foc = dataSource.dieuKienFoc
      ? Math.min(
          ...lstdichVu.map((it) =>
            typeof it.giaNet === "string"
              ? parseFloat(it.giaNet.replace(/,/g, ""))
              : it.giaNet
          )
        ) *
        (parseInt(
          lstdichVu.reduce(
            (sum, item) => sum + parseFloat(item.soLuongPhongDoi),
            0
          ) / dataSource.dieuKienFoc
        ) *
          dataSource.foc)
      : 0;
    return foc * dataSource.soNgaySuDung;
  }, [
    dichVus,
    dataSource.dieuKienFoc,
    dataSource.foc,
    dataSource.soNgaySuDung,
  ]);

  const tongChiPhi = tongChiPhiDichVu - foc;

  const getKhachSanDetails = (tranxOpSaleRateId) => {
    let dataReq = {
      tranxOpSaleRateId: tranxOpSaleRateId,
    };
    DichVuLeService.layDetailsOpKhachSan(dataReq)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let ds = {
            opDichVuId: data.opDichVuId,
            nccThongTinChungId: data.nccThongTinChungId,
            opRateHanhTrinhId: data.opRateHanhTrinhId,
            tenNcc: data.tenNhaCungCap,
            ngayBatDau: data.ngayBatDau,
            ngayKetThuc: data.ngayKetThuc,
            dmGiaApDungId: data.dmGiaApDungId,
            dmLoaiApDung: data.dmLoaiApDungId,
            soNgaySuDung: data.soNgaySuDung,
            dieuKienFoc: data.dieuKienFoc,
            foc: data.foc,
            moTa: data.moTa,
            dmVatId: data.dmVatId,
          };
          let lstDichVu = data?.dichVus.map((it, idx) => {
            const phongDoi = it.giaDichVus?.find(
              (item) => item.maPhong !== maPhuPhi && item.maPhong !== maPhongDon
            );
            const phuPhi = it.giaDichVus?.find(
              (item) => item.maPhong === maPhuPhi
            );
            const phongDon = it.giaDichVus?.find(
              (item) => item.maPhong === maPhongDon
            );
            return {
              id: idx + 1,
              nccHangPhongId: it.nccHangPhongId,
              tranxGiaPhongId: it.tranxGiaPhongId,
              tenDichVu: it.tenDichVu,
              kieuPhong: phongDoi?.dmKieuPhong,
              dmKieuPhongId: phongDoi?.dmKieuPhongId,
              maPhong: phongDoi?.maPhong,
              donGiaPhongDoi: handleFormatMoney(phongDoi?.donGia),
              giaNet: handleFormatMoney(phongDoi?.giaBan),
              soLuongPhongDoi: phongDoi?.soLuong,
              donGiaExtra: handleFormatMoney(phongDoi?.donGia),
              giaExtra: handleFormatMoney(phuPhi?.giaBan),
              soLuongExtra: phuPhi?.soLuong,
              donGiaPhongDon: handleFormatMoney(phongDoi?.donGia),
              giaPhongDon: handleFormatMoney(phongDon?.giaBan),
              soLuongPhongDon: phongDon?.soLuong,
              tong:
                it.giaDichVus[0]?.donGia * it.giaDichVus[0]?.soLuong +
                it.giaDichVus[1]?.donGia * it.giaDichVus[1]?.soLuong +
                it.giaDichVus[2]?.donGia * it.giaDichVus[1]?.soLuong,
            };
          });
          lstDichVu.push({ id: "NULL" });

          setDichVus(lstDichVu);
          setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
          setDataSource(ds);
          setDataSourceInit({ ...ds });
        }
      })
      .catch(function (response) {
        console.log("Error response getKhachSanBooking: ====" + response);
      });
  };
  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmVat = () => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmKieuPhong = () => {
    axios({
      method: "post",
      url: API_BASE_URL + "category/getDmKieuPhongNhapLieu",
      headers: authHeader(),
    })
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data?.filter((it) => it.hieu_luc);
          setDmKieuPhong(data);
          console.log("@@@ dmKieuPhong: ", data);
        }
      })
      .catch((response) => {
        console.log("Error response getDmKieuPhong:" + response);
      });
  };

  const handleFormatMoney = (inputValue) => {
    // Định dạng số với dấu phẩy
    const [integerPart, decimalPart] = inputValue?.toString().split(".") || [
      0, 0,
    ];
    const formattedInteger = formatter.format(integerPart);
    const formattedValue =
      decimalPart !== undefined
        ? `${formattedInteger}.${decimalPart}`
        : formattedInteger;
    return formattedValue;
  };

  const handleChangeValueDataSoure = (e) => {
    let name = e.target.name;
    if (e.target.value < 0 && (name === "foc" || name === "dieuKienFoc"))
      dataSource[name] = 0;
    else dataSource[name] = e.target.value;
    if (name === "dmGiaApDungId") dataSource.ngayApDungs = [];
    setReset(!reset);
  };

  const handleChangeEleDichVu = (id, event) => {
    let index = dichVus.findIndex((item) => item.id === id);
    let name = event.target.name;
    let inputValue = event.target.value.replace(/[^\d.]/g, "");
    if (name === "giaNet" || name === "giaExtra" || name === "giaPhongDon") {
      // Loại bỏ các dấu chấm thừa
      const parts = inputValue.split(".");
      if (parts.length > 2) {
        inputValue = `${parts[0]}.${parts.slice(1).join("")}`;
      }
      // Kiểm tra và định dạng tối đa 2 chữ số thập phân
      const decimalIndex = inputValue.indexOf(".");
      if (decimalIndex >= 0) {
        inputValue =
          inputValue.slice(0, decimalIndex + 1) +
          inputValue.slice(decimalIndex + 1, decimalIndex + 3);
      }
      // Định dạng số với dấu phẩy
      const [integerPart, decimalPart] = inputValue.split(".");
      const formattedInteger = formatter.format(integerPart);
      const formattedValue =
        decimalPart !== undefined
          ? `${formattedInteger}.${decimalPart}`
          : formattedInteger;
      dichVus[index][name] = formattedValue;
    } else dichVus[index][name] = inputValue;
    if (inputValue < 0) dichVus[index][name] = 0;

    let giaNet =
      typeof dichVus[index].giaNet === "string"
        ? dichVus[index].giaNet.replace(/,/g, "")
        : dichVus[index].giaNet;
    let giaExtra =
      typeof dichVus[index].giaExtra === "string"
        ? dichVus[index].giaExtra.replace(/,/g, "")
        : dichVus[index].giaExtra;

    let giaPhongDon =
      typeof dichVus[index].giaPhongDon === "string"
        ? dichVus[index].giaPhongDon.replace(/,/g, "")
        : dichVus[index].giaPhongDon;
    dichVus[index].tong =
      parseFloat(giaNet) * dichVus[index].soLuongPhongDoi +
      parseFloat(giaExtra) * dichVus[index].soLuongExtra +
      parseFloat(giaPhongDon) * dichVus[index].soLuongPhongDon;
    setDichVus([...dichVus]);
  };

  const handleChangeDataServiceKS = (dataSelected) => {
    console.log(dataSelected);
    let lstDichVu = [];
    let soTien = 0;
    let foc = 0;
    let dieuKienFoc = 0;
    if (action === "UPDATE_NCC") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 2;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      // dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = "";
      lstDichVu = dataSelected?.map((item, idx) => {
        if (idx === 0) {
          soTien = dataSelected[idx].fitNgayThuongNet;
          foc = dataSelected[idx].foc;
          dieuKienFoc = dataSelected[idx].dieuKienFoc;
        } else if (dataSelected[idx].fitNgayThuongNet < soTien) {
          soTien = dataSelected[idx].fitNgayThuongNet;
          foc = dataSelected[idx].foc;
          dieuKienFoc = dataSelected[idx].foc;
        }
        item.id = idx + 1;
        item.tenDichVu = dataSelected[idx].hangPhong;
        item.giaNet = dataSelected[idx].fitNgayThuongNet;
        item.donGiaPhongDoi = dataSelected[idx].fitNgayThuongNet;
        item.soLuongPhongDoi = 0;
        item.giaExtra = dataSelected[idx].extraNgayThuongNet;
        item.donGiaExtra = dataSelected[idx].extraNgayThuongNet;
        item.soLuongExtra = 0;
        item.giaPhongDon =
          parseInt(dataSelected[idx].fitNgayThuongNet) -
          parseInt(dataSelected[idx].fitNgayThuongNet) /
            parseInt(dataSelected[idx].soKhachToiDa);
        item.donGiaPhongDon = item.giaPhongDon;
        item.soLuongPhongDon = 0;
        item.tong = 0;
        return item;
      });
      lstDichVu.push({ id: "NULL" });
    } else if (action === "ADD_SERVICE") {
      if (dichVus.length === 1) {
        dataSource.tenNcc = dataSelected[0].tenNcc;
        dataSource.dmGiaApDungId = 1;
        dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
        dataSource.dmVatId = dataSelected[0].dmVatId
          ? dataSelected[0].dmVatId
          : 1;
        dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
        dataSource.moTa = "";
        dataSource.tenNcc = dataSelected[0].tenNcc;
        foc = dataSelected[0].foc;
        dieuKienFoc = dataSelected[0].dieuKienFoc;
      }
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu.splice(dichVus.length - 1, 1);
      lstDichVu.push({
        id: dichVus.length,
        nccGiaPhongId: dataSelected[0].nccGiaPhongId,
        nccHangPhongId: dataSelected[0].nccHangPhongId,
        tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
        kieuPhong: dataSelected[0].kieuPhong,
        dmKieuPhongId: dataSelected[0].dmKieuPhongId,
        maPhong: dataSelected[0].maPhong,
        dmTyGiaId: dataSelected[0].dmTyGiaId,
        tenDichVu: dataSelected[0].hangPhong,
        giaNet: dataSelected[0].fitNgayThuongNet,
        donGiaPhongDoi: dataSelected[0].fitNgayThuongNet,
        soLuongPhongDoi: 0,
        giaExtra: dataSelected[0].extraNgayThuongNet,
        donGiaExtra: dataSelected[0].extraNgayThuongNet,
        soLuongExtra: 0,
        giaPhongDon:
          parseInt(dataSelected[0].fitNgayThuongNet) -
          parseInt(dataSelected[0].fitNgayThuongNet) /
            parseInt(dataSelected[0].soKhachToiDa),
        donGiaPhongDon:
          parseInt(dataSelected[0].fitNgayThuongNet) -
          parseInt(dataSelected[0].fitNgayThuongNet) /
            parseInt(dataSelected[0].soKhachToiDa),
        soLuongPhongDon: 0,
        tong: 0,
      });
      lstDichVu.push({ id: "NULL" });
    } else {
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu[idxEdit] = {
        ...dataSelected[0],
        id: idxEdit + 1,
        nccGiaPhongId: dataSelected[0].nccGiaPhongId,
        nccHangPhongId: dataSelected[0].nccHangPhongId,
        tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
        bgNhomNgLonId: 0,
        kieuPhong: dataSelected[0].kieuPhong,
        dmKieuPhongId: dataSelected[0].dmKieuPhongId,
        maPhong: dataSelected[0].maPhong,
        dmTyGiaId: dataSelected[0].dmTyGiaId,
        tenDichVu: dataSelected[0].hangPhong,
        giaNet: dataSelected[0].fitNgayThuongNet,
        donGiaPhongDoi: dataSelected[0].fitNgayThuongNet,
        soLuongPhongDoi: 0,
        giaExtra: dataSelected[0].extraNgayThuongNet,
        donGiaExtra: dataSelected[0].extraNgayThuongNet,
        soLuongExtra: 0,
        giaPhongDon:
          parseInt(dataSelected[0].fitNgayThuongNet) -
          parseInt(dataSelected[0].fitNgayThuongNet) /
            parseInt(dataSelected[0].soKhachToiDa),
        donGiaPhongDon:
          parseInt(dataSelected[0].fitNgayThuongNet) -
          parseInt(dataSelected[0].fitNgayThuongNet) /
            parseInt(dataSelected[0].soKhachToiDa),
        soLuongPhongDon: 0,
        tong: 0,
      };
    }
    dataSource.dieuKienFoc = dieuKienFoc;
    dataSource.foc = foc;

    if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
    setDichVus(lstDichVu);
    setDataSource(dataSource);
    // if (action === "ADD_SERVICE")
    //   showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
    // else showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
  };

  const handleUpdateNCC = () => {
    setMaNcc("");
    setIsShowNccKS(true);
    setAction("UPDATE_NCC");
  };

  const handleAddService = () => {
    if (dichVus.length === 1) setMaNcc("");
    setIsShowNccKS(true);
    setAction("ADD_SERVICE");
  };

  const handleUpdateService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    setIsShowNccKS(true);
    setAction("UPDATE_SERVICE");
    setIdxEdit(index);
  };

  const handleDeleteServiceKS = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    lstDichVu.splice(index, 1);
    setDichVus(lstDichVu);
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = getDataReq();
      console.log(dataReq);
      console.log(JSON.stringify(dataReq));
      DichVuLeService.saveOpKhachSan(dataReq)
        .then((response) => {
          // let action = "add";
          // if (tranxOpSaleRateId !== 0) action = "edit";
          let action = dataSource.opRateHanhTrinhId ? "edit" : "add";
          if (response.status === 200 && response.data.code === "00") {
            showNoti(
              "success",
              t(action) + " " + t("noti.success").toLowerCase()
            );
            setDataSourceInit({ ...dataSource });
            setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
            handleCancel(false);
            onReset();
          } else {
            showNoti("error", response.data.message);
          }
        })
        .catch(() => {
          showNoti("error", t("system.error"));
        });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const getDataReq = () => {
    let dataReq = {
      opDichVuId: dataSource.opDichVuId,
      tranxOpSaleRateId: tranxOpSaleRateId,
      opRateHanhTrinhId: dataSource.opRateHanhTrinhId
        ? dataSource.opRateHanhTrinhId
        : 0,
      nccThongTinChungId: dataSource.nccThongTinChungId,
      ngayBatDau: dataSource.ngayBatDau,
      ngayKetThuc: dataSource.ngayKetThuc,
      dmGiaApDungId: dataSource.dmGiaApDungId,
      dmLoaiApDungId: 1, //dataSource.dmLoaiApDungId,
      soNgaySuDung: dataSource.soNgaySuDung,
      dmVatId: dataSource.dmVatId,
      dieuKienFoc: dataSource.dieuKienFoc,
      foc: dataSource.foc,
      moTa: dataSource.moTa,
      dichVus: dichVus
        .filter((dt) => dt.id !== "NULL")
        .map((it) => {
          return {
            nccHangPhongId: it.nccHangPhongId,
            tenDichVu: it.tenDichVu,
            giaDichVus: [
              {
                dmKieuPhongId: it.dmKieuPhongId, // phong doi
                maPhong: it.maPhong,
                donGia:
                  typeof it.donGiaPhongDoi === "string"
                    ? it.donGiaPhongDoi.replace(/,/g, "")
                    : it.donGiaPhongDoi,
                giaBan:
                  typeof it.giaNet === "string"
                    ? it.giaNet.replace(/,/g, "")
                    : it.giaNet,
                soLuong: it.soLuongPhongDoi,
              },
              {
                dmKieuPhongId: dmKieuPhong.find(
                  (it) => it.ma_phong === maPhuPhi
                )?.id, // phụ phí
                maPhong: maPhuPhi,
                donGia:
                  typeof it.donGiaExtra === "string"
                    ? it.donGiaExtra.replace(/,/g, "")
                    : it.donGiaExtra,
                giaBan:
                  typeof it.giaExtra === "string"
                    ? it.giaExtra.replace(/,/g, "")
                    : it.giaExtra,
                soLuong: it.soLuongExtra,
              },
              {
                dmKieuPhongId: dmKieuPhong.find(
                  (it) => it.ma_phong === maPhongDon
                )?.id, // phòng đơn
                maPhong: maPhongDon,
                donGia:
                  typeof it.donGiaPhongDon === "string"
                    ? it.donGiaPhongDon.replace(/,/g, "")
                    : it.donGiaPhongDon,
                giaBan:
                  typeof it.giaPhongDon === "string"
                    ? it.giaPhongDon.replace(/,/g, "")
                    : it.giaPhongDon,
                soLuong: it.soLuongPhongDon,
              },
            ],
          };
        }),
    };
    return dataReq;
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    // if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNgayApDungs");
    // }
    // else if (dataNhomNgLon.length > 0 && bgNhomNgLonId === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNhomNgLon");
    // }
    return result;
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const handleChangeNgayBatDau = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    if (newDataSource.ngayKetThuc) {
      if (
        moment(newDataSource.ngayKetThuc, "DD/MM/YYYY").isSameOrAfter(value.$d)
      ) {
        let khoangCachMilliseconds = Math.abs(
          new Date(formatStringDateView(newDataSource.ngayKetThuc)).getTime() -
            value.$d.getTime()
        );
        let khoangCachNgay = Math.ceil(
          khoangCachMilliseconds / (1000 * 3600 * 24)
        );
        newDataSource.ngayBatDau = day;
        newDataSource.soNgaySuDung = khoangCachNgay === 0 ? 1 : khoangCachNgay;
        // setSelectedStartDate(value.$d);
      } else {
        newDataSource.ngayBatDau = "";
        newDataSource.soNgaySuDung = 0;
        // setSelectedStartDate(null);
        showNoti("error", t("noti.errorSelectedDate"));
        return;
      }
    } else {
      newDataSource.ngayBatDau = day;
      // setSelectedStartDate(value.$d);
    }
    setDataSource(newDataSource);
  };

  const handleChangeNgayKetThuc = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    if (newDataSource.ngayBatDau) {
      if (
        moment(newDataSource.ngayBatDau, "DD/MM/YYYY").isSameOrBefore(value.$d)
      ) {
        let khoangCachMilliseconds = Math.abs(
          value.$d.getTime() -
            new Date(formatStringDateView(newDataSource.ngayBatDau)).getTime()
        );
        let khoangCachNgay = Math.ceil(
          khoangCachMilliseconds / (1000 * 3600 * 24)
        );
        newDataSource.ngayKetThuc = day;
        newDataSource.soNgaySuDung = khoangCachNgay === 0 ? 1 : khoangCachNgay;
        // setSelectedEndDate(value.$d);
      } else {
        newDataSource.ngayKetThuc = "";
        newDataSource.soNgaySuDung = 0;
        // setSelectedEndDate(null);
        showNoti("error", t("noti.errorSelectedDate"));
      }
    } else {
      newDataSource.ngayKetThuc = day;
      // setSelectedEndDate(value.$d);
    }
    setDataSource(newDataSource);
  };

  const handleClear = () => {
    setMaNcc("");
    setDataSource({ ...dataSourceInit });
    setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
  };

  const handleCloseModal = () => {
    handleCancel(false);
    handleClear();
  };

  const onIsShow = () => {
    setIsShowNccKS(false);
  };

  const columnDichVus = [
    {
      title: t("service"),
      dataIndex: "tenDichVu",
      key: "tenDichVu",
      width: "14%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link onClick={() => handleAddService()} underline="none">
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 10,
            },
          };
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={record.tenDichVu}
            InputProps={{
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateService(record)}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleDeleteServiceKS(record)}
                  />
                </>
              ),
            }}
          />
        );
      },
    },
    {
      title: t("hotel.priceClass.typeRoom"),
      dataIndex: "kieuPhong",
      align: "center",
      width: "9%",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: (
            <TextField
              variant="outlined"
              value={record.kieuPhong}
              size="small"
              fullWidth
              disabled={true}
            ></TextField>
          ),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("tour.phongDoi"),
      children: [
        {
          title: t("hotel.priceClass.giaNet"),
          dataIndex: "giaNet",
          key: "giaNet",
          width: "9%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            // return {
            //   children: handleData(text),
            //   props: {
            //     rowSpan: 1,
            //   },
            // };
            return {
              children: (
                <TextField
                  variant="outlined"
                  name="giaNet"
                  value={record.giaNet}
                  size="small"
                  fullWidth
                  onChange={(e) => handleChangeEleDichVu(record.id, e)}
                ></TextField>
              ),
              props: {
                rowSpan: 1,
              },
            };
          },
        },
        {
          title: t("hotel.roomClass.numberOfRoom"),
          dataIndex: "soLuongPhongDoi",
          key: "soLuongPhongDoi",
          width: "9%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuongPhongDoi"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                disabled={
                  record.kieuPhong === "Single Room" ||
                  record.kieuPhong === "Phòng đơn"
                }
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      width: "18%",
      visible: true,
      align: "center",
    },
    {
      title: t("phuPhi"),
      children: [
        {
          title: t("giaExtra"),
          dataIndex: "giaExtra",
          key: "giaExtra",
          width: "9%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            // return {
            //   children: handleData(text),
            //   props: {
            //     rowSpan: 1,
            //   },
            // };
            return {
              children: (
                <TextField
                  variant="outlined"
                  name="giaExtra"
                  value={record.giaExtra}
                  size="small"
                  fullWidth
                  onChange={(e) => handleChangeEleDichVu(record.id, e)}
                ></TextField>
              ),
              props: {
                rowSpan: 1,
              },
            };
          },
        },
        {
          title: t("soLuongExtra"),
          dataIndex: "soLuongExtra",
          key: "soLuongExtra",
          width: "9%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuongExtra"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                disabled={
                  record.kieuPhong === "Single Room" ||
                  record.kieuPhong === "Phòng đơn"
                }
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      width: "18%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.phongDon"),
      children: [
        {
          title: t("giaPhongDon"),
          dataIndex: "giaPhongDon",
          key: "giaPhongDon",
          width: "9%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            // return {
            //   children: handleData(text),
            //   props: {
            //     rowSpan: 1,
            //   },
            // };
            return {
              children: (
                <TextField
                  variant="outlined"
                  name="giaPhongDon"
                  value={record.giaPhongDon}
                  size="small"
                  fullWidth
                  onChange={(e) => handleChangeEleDichVu(record.id, e)}
                ></TextField>
              ),
              props: {
                rowSpan: 1,
              },
            };
          },
        },
        {
          title: t("soLuongPhongDon"),
          dataIndex: "soLuongPhongDon",
          key: "soLuongPhongDon",
          width: "9%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuongPhongDon"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      width: "18%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.tongChiPhi"),
      dataIndex: "tong",
      key: "tong",
      width: "10%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: formatter.format(text),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Modal
        width={1400}
        title={t("tour.khachSan.capNhatDichVu")}
        footer={null}
        open={isOpen}
        onCancel={handleCloseModal}
      >
        <Divider />
        <Row>
          <h3>{t("usedTime")}</h3>
        </Row>
        <Row>
          <Col span={11}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("startTime")}
                value={
                  dataSource.ngayBatDau
                    ? dayjs(formatStringDateView(dataSource.ngayBatDau))
                    : null
                }
                onChange={(date) => handleChangeNgayBatDau(date)}
                name="ngayBatDau"
                sx={{ width: "100%" }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("endTime")}
                value={
                  dataSource.ngayKetThuc
                    ? dayjs(formatStringDateView(dataSource.ngayKetThuc))
                    : null
                }
                onChange={(date) => handleChangeNgayKetThuc(date)}
                name="ngayKetThuc"
                sx={{ width: "100%" }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Col>
        </Row>
        <Divider />
        <Row>
          <h3>{t("res.serviceProvided")}</h3>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <TextField
            variant="outlined"
            fullWidth
            label={t("supplier")}
            value={dataSource.tenNcc}
            required
            InputProps={{
              style: { height: "50px" },
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateNCC()}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClear()}
                    fontSize="small"
                  />
                </>
              ),
            }}
          ></TextField>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              dataSource={dichVus}
              columns={columnDichVus.filter((item) => item.visible)}
              bordered
              pagination={false}
              summary={(pageData) => {
                // console.log(pageData);
                let totalPhongDoi = 0;
                let totalExtra = 0;
                let totelPhongDon = 0;
                let totalChiPhi = 0;

                pageData.forEach(
                  ({
                    soLuongPhongDoi,
                    soLuongExtra,
                    soLuongPhongDon,
                    tong,
                    id,
                  }) => {
                    if (id !== "NULL") {
                      totalPhongDoi += parseInt(soLuongPhongDoi);
                      totalExtra += parseInt(soLuongExtra);
                      totelPhongDon += parseInt(soLuongPhongDon);
                      totalChiPhi += parseFloat(tong);
                    }
                  }
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2}>
                        {t("tour.tongKet")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={2} align="center">
                        <Tooltip title={t("totalRooms")}>
                          {totalPhongDoi}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} colSpan={2} align="center">
                        <Tooltip title={t("totalSurcharges")}>
                          {totalExtra}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} colSpan={2} align="center">
                        <Tooltip title={t("totalSingleRooms")}>
                          {totelPhongDon}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={2} align="center">
                        <Tooltip title={t("totalCost")}>
                          {formatter.format(totalChiPhi)}
                        </Tooltip>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              id="dmGiaApDungId"
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("priceApply")}
              value={dataSource.dmGiaApDungId}
              name="dmGiaApDungId"
              onChange={(e) => handleChangeValueDataSoure(e)}
            >
              {dmGia?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.gia_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("numberDayUse")}
              value={dataSource.soNgaySuDung}
              name="soNgaySuDung"
              // onChange={(e) => handleChangeValueDataSoure(e)}
              disabled
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeOfApply")}
              value={1}
              disabled
            >
              {dmLoaiApDung?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.loai_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("action.apply") + " " + t("thuevat")}
              value={dataSource.dmVatId}
              disabled
            >
              {dmVat?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.vat_str}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeTour.description")}
              value={dataSource.moTa}
              name="moTa"
              onChange={(e) => handleChangeValueDataSoure(e)}
              //   disabled
            ></TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <TextField
              fullWidth
              label={t("hotel.roomClass.conditionFoc")}
              name="dieuKienFoc"
              value={dataSource.dieuKienFoc}
              type="number"
              onChange={(e) => handleChangeValueDataSoure(e)}
              InputProps={{
                style: { height: "50px" },
                endAdornment: <>{t("room")}</>,
              }}
            ></TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={1}></Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <TextField
              fullWidth
              label="FOC"
              name="foc"
              type="number"
              value={dataSource.foc}
              onChange={(e) => handleChangeValueDataSoure(e)}
              InputProps={{
                style: { height: "50px" },
                endAdornment: <>FOC</>,
              }}
            ></TextField>
          </Col>
        </Row>
        <Divider />
        <Row>
          <h3>{t("tour.tongKet")}</h3>
        </Row>
        <Row>
          <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
            <Row>
              <Col
                span={11}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {t("tour.tongChiPhi") + " :"}
              </Col>

              <Col span={3}></Col>
              <Col
                span={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {formatter.format(tongChiPhiDichVu)}
              </Col>
            </Row>
            <Row>
              <Col
                span={11}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                FOC :
              </Col>

              <Col span={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {formatter.format(foc)}
              </Col>
            </Row>
            <Row>
              <Col
                span={11}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
              </Col>

              <Col span={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {formatter.format(tongChiPhi)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginTop: 6 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={10}>
            <Space>
              <Buttons
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
                onClick={(e) => handleSave(e)}
              >
                {t("action.ok")}
              </Buttons>
              <Buttons
                style={{
                  backgroundColor: "#898989",
                  color: "white",
                }}
                key="reset"
                startIcon={<RestartAltIcon />}
                onClick={(e) => handleClear(e)}
              >
                {t("action.reset")}
              </Buttons>
              <Buttons
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => handleCloseModal()}
                key="clear"
              >
                {t("action.cancel")}
              </Buttons>
            </Space>
          </Col>
        </Row>
        <ModalThongTinNccKhachSan
          isOpen={isShowNccKS}
          onIsShow={onIsShow}
          onSaves={handleChangeDataServiceKS}
          action={action}
          maNcc={maNcc}
        />
      </Modal>
    </>
  );
};
export default ModalCapNhatKS;
