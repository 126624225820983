import React from "react";
import ExchangeRate from "../category/exchange/ExchangeRate";
import TypeOfTourism from "../category/typetourism/TypeOfTourism";
import TypeTour from "../category/typetour/TypeTour";
import Location from "../category/location/Location";
import SpHotel from "../supplier/hotel/SpHotel";
import SpRestaurant from "../supplier/restaurant/SpRestaurant";
import AddHotel from "../supplier/hotel/addhotel/AddHotel";
import Ncc from "../supplier/Ncc";
import { Routes, Route } from "react-router-dom";
import AddRestaurant from "../supplier/restaurant/addRes/AddRestaurant";
import AddOto from "../supplier/oto/addOto/AddOto";
import SpOto from "../supplier/oto/SpOto";
import SpVisa from "../supplier/visa/SpVisa";
import AddVisa from "../supplier/visa/addVisa/AddVisa";
import SpDuThuyen from "../supplier/duThuyen/SpDuThuyen";
import AddDuThuyen from "../supplier/duThuyen/addDuThuyen/AddDuThuyen";
import SpPlane from "../supplier/mayBay/SpPlane";
import AddPlane from "../supplier/mayBay/addPlane/AddPlane";
import AddThangCanh from "../supplier/thangCanh/addThangCanh/AddThangCanh";
import SpThangCanh from "../supplier/thangCanh/SpThangCanh";
import SpVeThuyen from "../supplier/veThuyen/SpVeThuyen";
import AddVeThuyen from "../supplier/veThuyen/addVeThuyen/AddVeThuyen";
import SpVeBus from "../supplier/veBus/SpVeBus";
import AddVeBus from "../supplier/veBus/addVeBus/AddVeBus";
import AddVeTau from "../supplier/veTau/addVeTau/AddVeTau";
import SpVeTau from "../supplier/veTau/SpVeTau";
import SpDvKhac from "../supplier/dvKhac/SpDvKhac";
import AddDvKhac from "../supplier/dvKhac/addDvKhac/AddDvKhac";
import SpHdvTuDo from "../supplier/hdVienTudo/SpHdvTuDo";
import AddHvdTd from "../supplier/hdVienTudo/addHdvTuDo/AddHvdTd";
import TourSanPham from "../services/tourSanPham/TourSanPham";
import AddTourSp from "../services/tourSanPham/addTourSp/AddTourSp";
import ChieTinhTour from "../services/tourSanPham/chietTinhTour/ChietTinhTour";
import ThemMoiBaoGia from "../baoGia/addBaoGia/ThemMoiBaoGia";
import BaoGia from "../baoGia/BaoGia";
import Booking from "../booking/Booking";
import AddBooking from "../booking/addBooking/AddBooking";
import DieuHanh from "../dieuHanh/DieuHanh";
import DieuHanhDichVu from "../dieuHanh/dieuHanhDichVu/DieuHanhDichVu";
import User from "../user/User";
// import ThongTinChung from "../booking/addBooking/thongtinchung/ThongTinChung";

const AppRouter = ({}) => {
  const dataRender = () => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    let roles = auth.roles;
    let routes = [];
    if (roles != null) {
      roles.forEach((role) => {
        const roleMenu = role.authority;
        if (roleMenu === "MANAGER") {
          routes.push(
            <>
              <Route exact path="/dm/ti-gia" element={<ExchangeRate />} />
              <Route
                exact
                path="/dm/loai-hinh-dl"
                element={<TypeOfTourism />}
              />
              <Route exact path="/dm/loai-tour" element={<TypeTour />} />
              <Route exact path="/dm/dia-diem" element={<Location />} />

              <Route path="ncc" element={<Ncc />}>
                <Route path=":id" element={<SpHotel />} />
                <Route path=":id" element={<SpRestaurant />} />
                <Route path=":id" element={<SpOto />} />
                <Route path=":id" element={<SpVisa />} />
                <Route path=":id" element={<SpDuThuyen />} />
                <Route path=":id" element={<SpPlane />} />
                <Route path=":id" element={<SpThangCanh />} />
                <Route path=":id" element={<SpVeThuyen />} />
                <Route path=":id" element={<SpVeBus />} />
                <Route path=":id" element={<SpVeTau />} />
                <Route path=":id" element={<SpDvKhac />} />
                <Route path=":id" element={<SpHdvTuDo />} />
              </Route>
              <Route path="/ncc/khach-san/:id" element={<AddHotel />} />
              <Route path="/ncc/nha-hang/:id" element={<AddRestaurant />} />
              <Route path="/ncc/oto/:id" element={<AddOto />} />
              <Route path="/ncc/visa/:id" element={<AddVisa />} />
              <Route path="/ncc/du-thuyen/:id" element={<AddDuThuyen />} />
              <Route path="/ncc/may-bay/:id" element={<AddPlane />} />
              <Route path="/ncc/ve-thuyen/:id" element={<AddVeThuyen />} />
              <Route path="/ncc/ve-bus/:id" element={<AddVeBus />} />
              <Route path="/ncc/ve-thang-canh/:id" element={<AddThangCanh />} />
              <Route path="/ncc/ve-tau/:id" element={<AddVeTau />} />
              <Route path="/ncc/dich-vu-khac/:id" element={<AddDvKhac />} />
              <Route
                path="/ncc/huong-dan-vien-tu-do/:id"
                element={<AddHvdTd />}
              />
              <Route path="dich-vu" element={null}>
                <Route path=":id" element={<TourSanPham />} />
              </Route>
              <Route
                path="/dich-vu/tour-san-pham/:id"
                element={<AddTourSp />}
              />
              <Route
                path="/dich-vu/tour-san-pham/chiet-tinh-tour/:id"
                element={<ChieTinhTour />}
              />
              <Route path="/bao-gia" element={<BaoGia />} />
              <Route path="/bao-gia/:id" element={<ThemMoiBaoGia />} />

              <Route path="dat-dich-vu" element={<Booking />} />
              <Route path="dat-dich-vu/:id*" element={<AddBooking />}></Route>
              <Route path="dieu-hanh" element={<DieuHanh />} />
              <Route path="dieu-hanh/:id*" element={<DieuHanhDichVu />} />
              <Route path="ql-nguoi-dung" element={<User />} />
            </>
          );
        } else if (roleMenu === "SYSTEM_MANAGER") {
          routes.push(
            <>
              <Route exact path="/dm/ti-gia" element={<ExchangeRate />} />
              <Route
                exact
                path="/dm/loai-hinh-dl"
                element={<TypeOfTourism />}
              />
              <Route exact path="/dm/loai-tour" element={<TypeTour />} />
              <Route exact path="/dm/dia-diem" element={<Location />} />
            </>
          );
        } else if (roleMenu === "OPERATOR" || roleMenu === "EXCUTIVE_MANAGER") {
          routes.push(
            <>
              <Route path="ncc" element={<Ncc />}>
                <Route path=":id" element={<SpHotel />} />
                <Route path=":id" element={<SpRestaurant />} />
                <Route path=":id" element={<SpOto />} />
                <Route path=":id" element={<SpVisa />} />
                <Route path=":id" element={<SpDuThuyen />} />
                <Route path=":id" element={<SpPlane />} />
                <Route path=":id" element={<SpThangCanh />} />
                <Route path=":id" element={<SpVeThuyen />} />
                <Route path=":id" element={<SpVeBus />} />
                <Route path=":id" element={<SpVeTau />} />
                <Route path=":id" element={<SpDvKhac />} />
                <Route path=":id" element={<SpHdvTuDo />} />
              </Route>
              <Route path="/ncc/khach-san/:id" element={<AddHotel />} />
              <Route path="/ncc/nha-hang/:id" element={<AddRestaurant />} />
              <Route path="/ncc/oto/:id" element={<AddOto />} />
              <Route path="/ncc/visa/:id" element={<AddVisa />} />
              <Route path="/ncc/du-thuyen/:id" element={<AddDuThuyen />} />
              <Route path="/ncc/may-bay/:id" element={<AddPlane />} />
              <Route path="/ncc/ve-thuyen/:id" element={<AddVeThuyen />} />
              <Route path="/ncc/ve-bus/:id" element={<AddVeBus />} />
              <Route path="/ncc/ve-thang-canh/:id" element={<AddThangCanh />} />
              <Route path="/ncc/ve-tau/:id" element={<AddVeTau />} />
              <Route path="/ncc/dich-vu-khac/:id" element={<AddDvKhac />} />
              <Route
                path="/ncc/huong-dan-vien-tu-do/:id"
                element={<AddHvdTd />}
              />

              <Route path="dieu-hanh" element={<DieuHanh />} />
              <Route path="dieu-hanh/:id*" element={<DieuHanhDichVu />} />
            </>
          );
        }
        if (roleMenu === "ACCOUNTANT") {
        }
      });
    }
    return routes;
  };
  return (
    <Routes>
      <Route exact path="/dm/ti-gia" element={<ExchangeRate />} />
      <Route exact path="/dm/loai-hinh-dl" element={<TypeOfTourism />} />
      <Route exact path="/dm/loai-tour" element={<TypeTour />} />
      <Route exact path="/dm/dia-diem" element={<Location />} />

      <Route path="ncc" element={<Ncc />}>
        <Route path=":id" element={<SpHotel />} />
        <Route path=":id" element={<SpRestaurant />} />
        <Route path=":id" element={<SpOto />} />
        <Route path=":id" element={<SpVisa />} />
        <Route path=":id" element={<SpDuThuyen />} />
        <Route path=":id" element={<SpPlane />} />
        <Route path=":id" element={<SpThangCanh />} />
        <Route path=":id" element={<SpVeThuyen />} />
        <Route path=":id" element={<SpVeBus />} />
        <Route path=":id" element={<SpVeTau />} />
        <Route path=":id" element={<SpDvKhac />} />
        <Route path=":id" element={<SpHdvTuDo />} />
      </Route>
      <Route path="/ncc/khach-san/:id" element={<AddHotel />} />
      <Route path="/ncc/nha-hang/:id" element={<AddRestaurant />} />
      <Route path="/ncc/oto/:id" element={<AddOto />} />
      <Route path="/ncc/visa/:id" element={<AddVisa />} />
      <Route path="/ncc/du-thuyen/:id" element={<AddDuThuyen />} />
      <Route path="/ncc/may-bay/:id" element={<AddPlane />} />
      <Route path="/ncc/ve-thuyen/:id" element={<AddVeThuyen />} />
      <Route path="/ncc/ve-bus/:id" element={<AddVeBus />} />
      <Route path="/ncc/ve-thang-canh/:id" element={<AddThangCanh />} />
      <Route path="/ncc/ve-tau/:id" element={<AddVeTau />} />
      <Route path="/ncc/dich-vu-khac/:id" element={<AddDvKhac />} />
      <Route path="/ncc/huong-dan-vien-tu-do/:id" element={<AddHvdTd />} />
      <Route path="dich-vu" element={null}>
        <Route path=":id" element={<TourSanPham />} />
      </Route>
      <Route path="/dich-vu/tour-san-pham/:id" element={<AddTourSp />} />
      <Route
        path="/dich-vu/tour-san-pham/chiet-tinh-tour/:id"
        element={<ChieTinhTour />}
      />
      <Route path="/bao-gia" element={<BaoGia />} />
      <Route path="/bao-gia/:id" element={<ThemMoiBaoGia />} />

      <Route path="dat-dich-vu" element={<Booking />} />
      <Route path="dat-dich-vu/:id*" element={<AddBooking />}>
        {/*<Route path="thong-tin-chung" element={<ThongTinChung />} />*/}
      </Route>
      <Route path="dieu-hanh" element={<DieuHanh />} />
      <Route path="dieu-hanh/:id*" element={<DieuHanhDichVu />} />
      <Route path="ql-nguoi-dung" element={<User />} />
    </Routes>
  );
};
export default AppRouter;
