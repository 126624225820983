import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { Col, Divider, Modal, Row, Space, Switch, notification } from "antd";
import axios from "axios";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import { API_BASE_URL } from "../../../../constants";
import TableDetailThoiGianAD from "./TableDetailThoiGianAD";
import TableTotalThoiGianAD from "./TableTotalThoiGianAD";
const ModalCauHinhTyLe = ({ open, handleTyLe, baoGiaTongQuanId }) => {
    const { t } = useTranslation();
    const [thoiGianApDung, setThoiGianApDung] = useState(null);
    const [tenMau, setTenMau] = useState(null);
    const [dataThoiGianApDung, setdataThoiGianApDung] = useState([]);
    const [item, setItem] = useState([]);
    const [items, setItems] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [dataDichVu, setDataDichVu] = useState([]);
    const [reload, setReload] = useState();
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response getDmDichVu: ====", response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    id: data[i].id,
                                    value: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        loadData();
    }, [baoGiaTongQuanId]);
    useEffect(() => {
        if (reload) {
            loadData();
            setReload(false);
        }
    }, [reload]);
    const loadData = () => {
        console.log("Get data");
        let data = {
            baoGiaTongQuanId: baoGiaTongQuanId,
            loai: 0, // get total
        };
        baoGiaService
            .getCauHinhLoiNhuan(data)
            .then(function (response) {
                if (response.status === 200) {
                    let dataTT = [];
                    let data = response.data.data;
                    let values = data.loaiTien;
                    console.log("getCauHinhLoiNhuan121", response.data.data);
                    for (let i = 0; i < values.length; i++) {
                        for (let j = 0; j < values[i].details.length; j++) {
                            let element = values[i].details[j];
                            let markupType = element.kieuTinh === 1 ? t("tour.fixedAmount") : t("tour.percentage");
                            dataTT.push({
                                id: Math.random() * (100 - 1),
                                id_: values[i].bgCauHinhLaiId,
                                markupType: markupType,
                                giaTri: element.giaTri,
                                loaiTien: values[i].dmLoaiTienId,
                                baoGiaTongQuanId: data.baoGiaTongQuanId,
                                apDung: element.apDung,
                                kieuTinh: element.kieuTinh,
                                dmDichVuId: element.dmDichVuId,
                                tenDichVu: element.tenDichVu,
                            });
                        }
                        dataTT.push({
                            id: "NULL " + Math.random() * (100 - 1),
                        });
                    }
                    dataTT.push({ id: "SUBTOTAL" });
                    let dataDetail = {
                        baoGiaTongQuanId: baoGiaTongQuanId,
                        loai: 1, // get detail
                    };
                    baoGiaService
                        .getCauHinhLoiNhuan(dataDetail)
                        .then(function (response) {
                            if (response.status === 200) {
                                let dataDT = [];
                                let data = response.data.data;
                                let values = data.loaiTien;
                                console.log("getCauHinhLoiNhuan1 detail", response.data.data);
                                for (let i = 0; i < values.length; i++) {
                                    for (let j = 0; j < values[i].details.length; j++) {
                                        let element = values[i].details[j];
                                        let markupType =
                                            element.kieuTinh === 1 ? t("tour.fixedAmount") : t("tour.percentage");
                                        if (j % 3 === 0) {
                                            let det = [];
                                            for (let index = j; index < j + 3; index++) {
                                                det.push({
                                                    id_: values[i].bgCauHinhLaiId,
                                                    id: Math.random() * (100 - 1),
                                                    markupType: markupType,
                                                    giaTri: values[i].details[index].giaTri,
                                                    loaiTien: values[i].dmLoaiTienId,
                                                    baoGiaTongQuanId: data.baoGiaTongQuanId,
                                                    apDung: values[i].details[index].apDung,
                                                    dmDichVuId: values[i].details[index].dmDichVuId,
                                                    tenDichVu: values[i].details[index].tenDichVu,
                                                });
                                            }
                                            dataDT.push({
                                                id: Math.random() * (100 - 1),
                                                id_: values[i].bgCauHinhLaiId,
                                                markupType: markupType,
                                                giaTri: element.giaTri,
                                                loaiTien: values[i].dmLoaiTienId,
                                                baoGiaTongQuanId: data.baoGiaTongQuanId,
                                                apDung: element.apDung,
                                                kieuTinh: element.kieuTinh,
                                                dmDichVuId: element.dmDichVuId,
                                                tenDichVu: element.tenDichVu,
                                                details: det,
                                            });
                                        }
                                    }

                                    dataDT.push({
                                        id: "NULL " + Math.random() * (100 - 1),
                                    });
                                }
                                dataDT.push({ id: "SUBTOTAL" });

                                setItem({
                                    ...item,
                                    isShow: false,
                                    rowsDT: [...dataDT],
                                    rowsTT: [...dataTT],
                                    variantTT: "contained",
                                    variantDT: "outlined",
                                    isTableTotal: true,
                                });
                            }
                        })
                        .catch(function (response) {
                            console.log("Error response: ====" + response);
                            if (response.status === 401) {
                            }
                        });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    const onFinish = (e) => {
        e.preventDefault();
        console.log(JSON.stringify(item));
        let rowsDT = [];
        for (let i = 0; i < item.rowsDT.length; i++) {
            const element = item.rowsDT[i];
            if (
                item.rowsDT[i].id.toString().indexOf("NULL") === -1 &&
                item.rowsDT[i].id.toString().indexOf("SUBTOTAL") === -1
            ) {
                rowsDT.push(element);
            }
        }
        let errorDT = false;
        let loaiTien1 = [];
        for (let i = 0; i < rowsDT.length; i++) {
            for (let j = i + 1; j < rowsDT.length; j++) {
                const element = rowsDT[j];
                if (rowsDT[i].loaiTien === element.loaiTien && rowsDT[i].markupType === element.markupType) {
                    errorDT = true;
                    break;
                }
            }
            if (!errorDT) {
                if (i % 2 === 1) {
                    let details = [];
                    for (let k = 0; k < rowsDT[i].details.length; k++) {
                        const dt = rowsDT[i].details[k];
                        details.push({
                            dmDichVuId: dt.dmDichVuId,
                            kieuTinh: rowsDT[i].kieuTinh,
                            giaTri: dt.giaTri,
                            apDung: rowsDT[i].apDung,
                        });
                    }
                    for (let k = 0; k < rowsDT[i - 1].details.length; k++) {
                        const dt = rowsDT[i - 1].details[k];
                        details.push({
                            dmDichVuId: dt.dmDichVuId,
                            kieuTinh: rowsDT[i - 1].kieuTinh,
                            giaTri: dt.giaTri,
                            apDung: rowsDT[i - 1].apDung,
                        });
                    }
                    loaiTien1.push({
                        dmLoaiTienId: rowsDT[i].loaiTien,
                        bgCauHinhLaiId: rowsDT[i].id_.toString().indexOf("NEW") !== -1 ? 0 : rowsDT[i].id_,
                        details: details,
                    });
                }
            } else break;
        }
        if (errorDT) {
            api["error"]({
                message: t("stour"),
                description: t("messageErrorLoaiTien"),
                duration: 0,
            });
            return;
        }
        let errorTT = false;
        let rowsTT = [];
        for (let i = 0; i < item.rowsTT.length; i++) {
            const element = item.rowsTT[i];
            if (
                item.rowsTT[i].id.toString().indexOf("NULL") === -1 &&
                item.rowsTT[i].id.toString().indexOf("TITLE") === -1 &&
                item.rowsTT[i].id.toString().indexOf("SUBTOTAL") === -1
            ) {
                rowsTT.push(element);
            }
        }
        let loaiTien0 = [];
        for (let i = 0; i < rowsTT.length; i++) {
            let details = [];
            for (let j = i + 1; j < rowsTT.length; j++) {
                const element = rowsTT[j];
                if (rowsTT[i].loaiTien === element.loaiTien && rowsTT[i].id_ !== element.id_) {
                    errorTT = true;
                    break;
                }
            }
            if (errorTT) break;
            if (i % 2 === 0) {
                details.push({
                    dmDichVuId: rowsTT[i].dmDichVuId,
                    kieuTinh: rowsTT[i].kieuTinh,
                    giaTri: rowsTT[i].giaTri,
                    apDung: rowsTT[i].apDung,
                });
                details.push({
                    dmDichVuId: rowsTT[i + 1].dmDichVuId,
                    kieuTinh: rowsTT[i + 1].kieuTinh,
                    giaTri: rowsTT[i + 1].giaTri,
                    apDung: rowsTT[i + 1].apDung,
                });
                loaiTien0.push({
                    dmLoaiTienId: rowsTT[i].loaiTien,
                    bgCauHinhLaiId: rowsTT[i].id.toString().indexOf("NEW") !== -1 ? 0 : rowsTT[i].id_,
                    details: details,
                });
            }
        }
        if (errorTT) {
            api["error"]({
                message: t("stour"),
                description: t("messageErrorLoaiTien"),
                duration: 0,
            });
            return;
        }
        let data = { baoGiaTongQuanId: parseInt(baoGiaTongQuanId), loai: 0, loaiChietTinh: 0, loaiTien: loaiTien0 };
        baoGiaService
            .saveCauHinhLoiNhuan(data)
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
        let data1 = { baoGiaTongQuanId: parseInt(baoGiaTongQuanId), loai: 1, loaiChietTinh: 0, loaiTien: loaiTien1 };
        console.log(JSON.stringify(data1));
        baoGiaService
            .saveCauHinhLoiNhuan(data1)
            .then(function (response) {
                if (response.status === 200) {
                    setReload(true);
                    api["success"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                } else
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleCancels = (e) => {
        e.preventDefault();
        handleTyLe(false);
    };
    const handleClear = () => {};
    const handleRemoveField = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const handleAddField = () => {
        setItems([
            ...items,
            {
                isShow: false,
                id: 0,
                tuNgay: null,
                denNgay: null,
                values: [],
                tourChietTinhId: null,
                rowsTT: [{ id: "SUBTOTAL" }],
                rowsDT: [{ id: "SUBTOTAL" }],
                variantTT: "contained",
                variantDT: "outlined",
                isTableTotal: true,
            },
        ]);
    };
    const handleItemChange = (name, value) => {
        // console.log("name: " + name + " value: " + value);
        const newItems = { ...item };
        if (name === "variantDT") {
            newItems[name] = value;
            newItems["isTableTotal"] = false;
            newItems["variantTT"] = "outlined";
        }
        if (name === "variantTT") {
            newItems[name] = value;
            newItems["isTableTotal"] = true;
            newItems["variantDT"] = "outlined";
        } else {
            newItems[name] = value;
        }
        setItem(newItems);
    };
    const onChangeTrangThai = () => {};
    const handleSave = (item) => {
        setItems(
            items.map((it) => {
                if (it.id === item.id) return item;
                return it;
            })
        );
    };
    return (
        <>
            <Modal
                open={open}
                onCancel={() => handleTyLe(false)}
                footer={null}
                title={t("Configureprofitrate")}
                width={1000}
                height={700}
            >
                {contextHolder}
                <Divider />
                <Box component="form" noValidate>
                    <Row style={{ marginTop: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Button
                                variant={item.variantTT}
                                onClick={(e) => handleItemChange("variantTT", "contained")}
                            >
                                {t("tour.total")}
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Button
                                variant={item.variantDT}
                                onClick={(e) => handleItemChange("variantDT", "contained")}
                            >
                                {t("tour.chiTiet")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                            <Button>{t("tour.danhSachMau")}</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}></Row>
                    {item.isTableTotal ? (
                        <TableTotalThoiGianAD
                            baoGiaTongQuanId={baoGiaTongQuanId}
                            rowsTT={item.rowsTT}
                            handleSave={handleSave}
                            item={item}
                            setItem={setItem}
                        />
                    ) : (
                        <TableDetailThoiGianAD
                            baoGiaTongQuanId={baoGiaTongQuanId}
                            dataDichVu={dataDichVu}
                            dataSource={item.rowsDT}
                            item={item}
                            setItem={setItem}
                        />
                    )}
                    <Divider />
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                            <FormControlLabel
                                label={t("tour.luuMau")}
                                control={
                                    <Switch
                                        style={{ marginLeft: "10px" }}
                                        defaultChecked
                                        onChange={onChangeTrangThai}
                                    />
                                }
                                labelPlacement="start"
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                size="large"
                                label={t("tour.tenMau")}
                                multiline
                                rows={1}
                                value={tenMau}
                                onChange={(e) => setTenMau(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 6 }}>
                        <Col xs={5} sm={5} md={5} lg={5} xl={8}></Col>
                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Space>
                                <Button variant="contained" onClick={onFinish} startIcon={<SaveIcon />}>
                                    {t("action.ok")}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                    }}
                                    key="reset"
                                    startIcon={<RestartAltIcon />}
                                    onClick={(e) => handleClear(e)}
                                >
                                    {t("action.reset")}
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleCancels}
                                    key="clear"
                                >
                                    {t("action.cancel")}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Box>
            </Modal>
        </>
    );
};
export default ModalCauHinhTyLe;
