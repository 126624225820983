/* eslint-disable no-loop-func */
import {
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    PlusSquareFilled,
} from "@ant-design/icons";
import ReportIcon from "@mui/icons-material/Report";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import { MenuItem } from "@material-ui/core";
import { Box, IconButton, InputAdornment, Link, Select, TextField } from "@mui/material";
import { Col, Collapse, Row, Typography, notification } from "antd";
import CheckIcon from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import khachSanService from "../../../../api/bao_gia/sale_rate/khach_san/khachSanService";
import dm_do_tuoi from "../../../../api/category/dm_do_tuoi";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import otoService from "../../../../api/tour/oto/oto.service";
import otoServiceBG from "../../../../api/bao_gia/sale_rate/oto/otoService";
import { API_BASE_URL } from "../../../../constants";
import TongKet from "./TongKet";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import nhaHangService from "../../../../api/bao_gia/sale_rate/nha_hang/nhaHangService";
import ModalCapNhatKS from "./modalCapNhat/ModalCapNhatKS";
import ModalCapNhatOto from "./modalCapNhat/ModalCapNhatOto";
import ModalCapNhatNhaHang from "./modalCapNhat/ModalCapNhatNhaHang";
import bookingService from "../../../../api/booking/bookingService";
import Restore from "@mui/icons-material/Restore";

const ViewServies = ({
    dataDv,
    baoGiaTongQuanId,
    ngayKhoiHanh,
    setReload,
    dataNhomNgLon,
    dataNhomTreEm,
    soLuongNgLonBG,
    soLuongTreEmBG,
    soNgay,
    bgHanhTrinhs,
    tongGia,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [openNH, setOpenNh] = useState(false);
    const [openKs, setOpenKs] = useState(false);
    const [openOto, setOpenOto] = useState(false);
    const [dataTableTreEm, setDataTableTreEm] = useState([]);
    const [dataTableNguoiLon, setDataTableNguoiLon] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [kieuPhong, setKieuPhong] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [dmSoCho, setDmSoCho] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [nhomNguoiLons, setNhomNguoiLons] = useState([]);
    const [baoGiaHanhTrinhId, setBaoGiaHanhTrinhId] = useState(0);
    const [bgChietTinhSaleId, setBgChietTinhSaleId] = useState(0);
    const [bgMapCTSaleHtId, setBgMapCTSaleHtId] = useState(0);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [dmDichVuId, setDmDichVuId] = useState(0);
    const [loaiHienThi, setloaiHienThi] = useState(0);
    // let dataNhomTreEm = [];
    useEffect(() => {
        // baoGiaService.getBaoGiaTongQuanById({ id: 1 }).then(function (response) {
        //     if (response.status === 200 && response.data.code === "00") {
        //         let data = response.data.data;
        //         setNhomNguoiLons(data.nhomNguoiLons);
        //     }
        // });
        bookingService
            .layThongTinTourSaleRate({
                bkDichVuTourId: 3,
            })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    // setNhomNguoiLons(data.nhomNguoiLons);
                    setloaiHienThi(response.data.data.loaiHienThi);
                    if (response.data.data.loaiHienThi === 0) {
                        let dichVuNguoiLon = [];
                        let saleRateDichVuLoaiDichVuResponseNH = {
                            dmDichVuId: 3,
                            loaiDichVu: "Nhà hàng",
                            saleRateDichVuNcc: [],
                        };
                        let saleRateDichVuLoaiDichVuResponseKS = {
                            dmDichVuId: 1,
                            loaiDichVu: "Khách sạn",
                            saleRateDichVuNcc: [],
                        };
                        let saleRateDichVuLoaiDichVuResponseOT = {
                            dmDichVuId: 2,
                            loaiDichVu: "Xe ô tô",
                            saleRateDichVuNcc: [],
                        };
                        for (let i = 0; i < data.dichVuNguoiLon.length; i++) {
                            const element = data.dichVuNguoiLon[i].saleRateDichVuLoaiDichVuResponse;
                            element.forEach((dt) => {
                                dt.saleRateDichVuNcc.forEach((d) => {
                                    if (dt.dmDichVuId === 3) {
                                        saleRateDichVuLoaiDichVuResponseNH = handleDataBk(
                                            saleRateDichVuLoaiDichVuResponseNH,
                                            d
                                        );
                                    }
                                    if (dt.dmDichVuId === 1) {
                                        saleRateDichVuLoaiDichVuResponseKS = handleDataBk(
                                            saleRateDichVuLoaiDichVuResponseKS,
                                            d
                                        );
                                    }
                                    if (dt.dmDichVuId === 2) {
                                        saleRateDichVuLoaiDichVuResponseOT = handleDataBk(
                                            saleRateDichVuLoaiDichVuResponseOT,
                                            d
                                        );
                                    }
                                });
                            });
                        }
                        let saleRateDichVuLoaiDichVuResponse = [
                            saleRateDichVuLoaiDichVuResponseKS,
                            saleRateDichVuLoaiDichVuResponseNH,
                            saleRateDichVuLoaiDichVuResponseOT,
                        ];
                        dichVuNguoiLon = [...genderData(saleRateDichVuLoaiDichVuResponse)];
                        setDataTableNguoiLon(dichVuNguoiLon);
                        saleRateDichVuLoaiDichVuResponseNH = {
                            dmDichVuId: 3,
                            loaiDichVu: "Nhà hàng",
                            saleRateDichVuNccTreEmResponse: [],
                        };
                        saleRateDichVuLoaiDichVuResponseKS = {
                            dmDichVuId: 1,
                            loaiDichVu: "Khách sạn",
                            saleRateDichVuNccTreEmResponse: [],
                        };
                        saleRateDichVuLoaiDichVuResponseOT = {
                            dmDichVuId: 2,
                            loaiDichVu: "Xe ô tô",
                            saleRateDichVuNccTreEmResponse: [],
                        };
                        let dichVuTreEm = [];
                        for (let i = 0; i < data.dichVuTreEm.length; i++) {
                            const element = data.dichVuTreEm[i].saleRateDichVuLoaiDichVuTreEmResponse;
                            element.forEach((dt) => {
                                dt.saleRateDichVuNccTreEmResponse.forEach((d) => {
                                    if (dt.dmDichVuId === 3) {
                                        saleRateDichVuLoaiDichVuResponseNH = handleDataBkTE(
                                            saleRateDichVuLoaiDichVuResponseNH,
                                            d
                                        );
                                    }
                                    if (dt.dmDichVuId === 1) {
                                        saleRateDichVuLoaiDichVuResponseKS = handleDataBkTE(
                                            saleRateDichVuLoaiDichVuResponseKS,
                                            d
                                        );
                                    }
                                    if (dt.dmDichVuId === 2) {
                                        saleRateDichVuLoaiDichVuResponseOT = handleDataBkTE(
                                            saleRateDichVuLoaiDichVuResponseOT,
                                            d
                                        );
                                    }
                                });
                            });
                        }
                        saleRateDichVuLoaiDichVuResponse = [
                            saleRateDichVuLoaiDichVuResponseKS,
                            saleRateDichVuLoaiDichVuResponseNH,
                            saleRateDichVuLoaiDichVuResponseOT,
                        ];
                        dichVuTreEm = [...genderDataTE(saleRateDichVuLoaiDichVuResponse)];
                        setDataTableTreEm(dichVuTreEm);
                    } else {
                        let dichVuNguoiLon = [];
                        for (let i = 0; i < data.dichVuNguoiLon.length; i++) {
                            const element = data.dichVuNguoiLon[i].saleRateDichVuLoaiDichVuResponse;
                            dichVuNguoiLon = [...dichVuNguoiLon, ...genderData(element)];
                        }
                        setDataTableNguoiLon(dichVuNguoiLon);
                        let dichVuTreEm = [];
                        for (let i = 0; i < data.dichVuTreEm.length; i++) {
                            const element = data.dichVuTreEm[i].saleRateDichVuLoaiDichVuTreEmResponse;
                            dichVuTreEm = [...dichVuTreEm, ...genderDataTE(element)];
                        }
                        setDataTableTreEm(dichVuTreEm);
                    }
                    // genderDataTE(data.dichVuTreEm.saleRateDichVuLoaiDichVuTreEmResponse);
                }
            });
    }, []);
    const handleDataBk = (saleRateDichVuLoaiDichVuResponseNH, d) => {
        let len = saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNcc.length;
        if (len === 0) saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNcc.push(d);
        else {
            if (d.bkTranxDichVuId === saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNcc[len - 1].bkTranxDichVuId) {
                saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNcc[len - 1].saleRateDichVuResponse = [
                    ...saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNcc[len - 1].saleRateDichVuResponse,
                    ...d.saleRateDichVuResponse,
                ];
            } else {
                saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNcc.push(d);
            }
        }
        return saleRateDichVuLoaiDichVuResponseNH;
    };
    const handleDataBkTE = (saleRateDichVuLoaiDichVuResponseNH, d) => {
        let len = saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNccTreEmResponse.length;
        if (len === 0) saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNccTreEmResponse.push(d);
        else {
            if (
                d.bkTranxDichVuId ===
                saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNccTreEmResponse[len - 1].bkTranxDichVuId
            ) {
                saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNccTreEmResponse[
                    len - 1
                ].saleRateDichVuConTreEmResponse = [
                    ...saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNccTreEmResponse[len - 1]
                        .saleRateDichVuConTreEmResponse,
                    ...d.saleRateDichVuConTreEmResponse,
                ];
            } else {
                saleRateDichVuLoaiDichVuResponseNH.saleRateDichVuNccTreEmResponse.push(d);
            }
        }
        return saleRateDichVuLoaiDichVuResponseNH;
    };
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataBA = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataBA.push({
                                value: data[i].id,
                                label: data[i].ten_bua_an,
                            });
                        }
                    }
                    setDmBuaAn(dataBA);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("Response setDmVat: ====", response.data.data);
                    let vat = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc)
                            vat.push({
                                value: data[i].vat,
                                label: data[i].vat_str,
                                id: data[i].id,
                            });
                    }
                    console.log("Response vat: ====", vat);
                    setDmVat(vat);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    }, []);
    const genderData = (dataDv) => {
        if (dataDv?.length > 0) {
            setDataSource(dataDv);
            let data = dataDv;
            let dataNL = [];
            let dataTe = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.saleRateDichVuNcc.length > 0) {
                    let rowSpanDv = 0;
                    for (let j = 0; j < element.saleRateDichVuNcc.length; j++) {
                        const saleRateDichVuNcc = element.saleRateDichVuNcc[j];
                        // rowSpanDv += saleRateDichVuNcc.saleRateDichVuResponse.length;
                        let firstDv = 0;
                        let rowSpan = 0;
                        for (let k = 0; k < saleRateDichVuNcc.saleRateDichVuResponse.length; k++) {
                            const saleRateDichVuResponse = saleRateDichVuNcc.saleRateDichVuResponse[k];
                            console.log(saleRateDichVuResponse.length);
                            if (k === 0) {
                                firstDv = dataNL.length === 0 ? 0 : dataNL.length;
                            }
                            saleRateDichVuResponse.id = "API" + Math.random();
                            element.stt = i + 1;
                            let dataMaping = mapDataNL(saleRateDichVuResponse, saleRateDichVuNcc, element);
                            rowSpan += dataMaping.length;
                            dataMaping.forEach((map) => {
                                dataNL.push(map);
                            });
                        }
                        rowSpanDv += rowSpan;
                        dataNL[firstDv].rowSpan = rowSpan;
                    }

                    mapDataNL(null, { id: "NULL" + Math.random(), rowSpan: 0, rowSpanDV: 0 }, element).forEach(
                        (map) => {
                            dataNL.push(map);
                        }
                    );
                    let first = dataNL.findIndex((dt) => dt.loaiDichVu === element.loaiDichVu);
                    console.log("check");
                    console.log(rowSpanDv);
                    console.log(first);
                    dataNL[first].rowSpanDV = rowSpanDv + 1;
                } else {
                    mapDataNL(null, { id: "NULL" + Math.random(), rowSpan: 1, rowSpanDV: 1 }, element).forEach(
                        (map) => {
                            dataNL.push(map);
                        }
                    );
                }
            }
            // setDataTableNguoiLon(dataNL);
            return dataNL;
        } else {
            console.log("Fix data test");
            setDataTableNguoiLon([
                {
                    loaiDichVu: "Nhà hàng",
                    id: "NULL" + Math.random(),
                    rowSpan: 1,
                    rowSpanDV: 1,
                },
                {
                    loaiDichVu: "Khách sạn",
                    id: "NULL" + Math.random(),
                    rowSpan: 1,
                    rowSpanDV: 1,
                },
            ]);
        }
    };
    const genderDataTE = (dataDv) => {
        if (dataDv?.length > 0) {
            console.log("dataDv TE: ", dataDv);
            setDataSource(dataDv);
            let data = dataDv;
            let dataTe = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.saleRateDichVuNccTreEmResponse.length > 0) {
                    // let rowSpanDV = 0;
                    for (let j = 0; j < element.saleRateDichVuNccTreEmResponse.length; j++) {
                        const saleRateDichVuNccTreEmResponse = element.saleRateDichVuNccTreEmResponse;
                        if (saleRateDichVuNccTreEmResponse[j]) {
                            let saleRateDichVuConTreEmResponse =
                                saleRateDichVuNccTreEmResponse[j]?.saleRateDichVuConTreEmResponse;
                            let rowSpanLDV = saleRateDichVuNccTreEmResponse.reduce((num, item) => {
                                if (item.saleRateDichVuConTreEmResponse.length !== 0) {
                                    item.saleRateDichVuConTreEmResponse.forEach((it) => {
                                        it.saleRateDichVuDetailTreEmResponse.forEach(() => {
                                            num++;
                                        });
                                    });
                                } else num += 1;
                                return num;
                            }, 0);
                            if (dataNhomTreEm?.length === 0)
                                rowSpanLDV +=
                                    saleRateDichVuNccTreEmResponse.length *
                                    (saleRateDichVuConTreEmResponse.length || 1);
                            for (let h = 0; h < saleRateDichVuConTreEmResponse.length; h++) {
                                let saleRateDichVuDetailTreEmResponse =
                                    saleRateDichVuConTreEmResponse[h].saleRateDichVuDetailTreEmResponse;
                                for (let g = 0; g < saleRateDichVuDetailTreEmResponse.length; g++) {
                                    let rowSpanDV = 0;
                                    let rowSpanLoaiDV = 0;
                                    let rowSpanNcc = 0;
                                    let firtIndexLoaiDichVu = saleRateDichVuNccTreEmResponse.findIndex((dt) => {
                                        let check = false;
                                        dt.saleRateDichVuConTreEmResponse?.forEach((it) => {
                                            if (it?.saleRateDichVuDetailTreEmResponse?.length > 0) check = true;
                                        });
                                        return check;
                                    });
                                    let firtIndexTenNcc = saleRateDichVuConTreEmResponse.findIndex(
                                        (dt) => dt?.saleRateDichVuDetailTreEmResponse?.length > 0
                                    );
                                    if (dataNhomTreEm?.length > 0) {
                                        if (j === firtIndexLoaiDichVu && h === firtIndexTenNcc && g === 0)
                                            rowSpanLoaiDV = rowSpanLDV;
                                        if (h === firtIndexTenNcc && g === 0) {
                                            rowSpanNcc = saleRateDichVuConTreEmResponse.reduce((num, item) => {
                                                item.saleRateDichVuDetailTreEmResponse.forEach(() => {
                                                    num++;
                                                });
                                                return num;
                                            }, 0);
                                        }
                                    } else {
                                        if (j === 0 && h === 0 && g === 0) {
                                            rowSpanLoaiDV = rowSpanLDV;
                                        }
                                        if (h === 0 && g === 0) {
                                            rowSpanNcc = saleRateDichVuConTreEmResponse.reduce((num, item) => {
                                                item.saleRateDichVuDetailTreEmResponse.forEach(() => {
                                                    num++;
                                                });
                                                return num;
                                            }, 0);
                                            rowSpanNcc += saleRateDichVuConTreEmResponse.length;
                                        }
                                    }
                                    if (g === 0) {
                                        rowSpanDV = saleRateDichVuDetailTreEmResponse.length;
                                        if (dataNhomTreEm?.length === 0) rowSpanDV++;
                                    }
                                    const detail = saleRateDichVuDetailTreEmResponse[g];

                                    dataTe.push({
                                        id: Math.random() + "",
                                        ...detail,
                                        loaiDichVu: element.loaiDichVu,
                                        bgCTSaleId: element.bgCTSaleId,
                                        dmDichVuId: element.dmDichVuId,
                                        bgMapCTSaleHtId: saleRateDichVuNccTreEmResponse[j].bgMapCTSaleHtId,
                                        nccThongTinChungId: saleRateDichVuNccTreEmResponse[j].nccThongTinChungId,
                                        parentBgMapCTSaleHtId: saleRateDichVuNccTreEmResponse[j].parentBgMapCTSaleHtId,
                                        tenNcc: saleRateDichVuNccTreEmResponse[j].tenNcc,
                                        dmGiaApDungId: saleRateDichVuNccTreEmResponse[j].dmGiaApDungId,
                                        ngayApDungs: saleRateDichVuNccTreEmResponse[j].ngayApDungs,
                                        dmLoaiApDungId: saleRateDichVuNccTreEmResponse[j].dmLoaiApDungId,
                                        moTa: saleRateDichVuNccTreEmResponse[j].moTa,
                                        dieuKienFoc: saleRateDichVuNccTreEmResponse[j].dieuKienFoc,
                                        foc: saleRateDichVuNccTreEmResponse[j].foc,
                                        tenDichVu: saleRateDichVuConTreEmResponse[h].tenDichVu,
                                        mapBgChietTinhKsTreEmId:
                                            saleRateDichVuConTreEmResponse[h].mapBgChietTinhKsTreEmId,
                                        mapBgChietTinhNhTreEmId:
                                            saleRateDichVuConTreEmResponse[h].mapBgChietTinhNhTreEmId,
                                        mapBgChietTinhOtoTreEmId:
                                            saleRateDichVuConTreEmResponse[h].mapBgChietTinhOtoTreEmId,
                                        giaDichVu: saleRateDichVuConTreEmResponse[h].donGia,
                                        // bgNhomTreEmId: saleRateDichVuConTreEmResponse[h].bgNhomTreEmId,
                                        tranxBgChietTinhKsId: saleRateDichVuConTreEmResponse[h].tranxBgChietTinhKsId,
                                        tranxBgChietTinhNhId: saleRateDichVuConTreEmResponse[h].tranxBgChietTinhNhId,
                                        tranxBgChietTinhOtoId: saleRateDichVuConTreEmResponse[h].tranxBgChietTinhOtoId,
                                        rowSpanNcc: rowSpanNcc,
                                        rowSpanLoaiDV: rowSpanLoaiDV,
                                        rowSpanDV: rowSpanDV,
                                    });
                                }
                                if (dataNhomTreEm?.length === 0)
                                    dataTe.push({
                                        id: "NEW_ADD" + Math.random(),
                                        tenNcc: saleRateDichVuNccTreEmResponse[j].tenNcc,
                                        loaiDichVu: saleRateDichVuConTreEmResponse[h].loaiDichVu,
                                        giaDichVu: saleRateDichVuConTreEmResponse[h].donGia,
                                        bgCTSaleId: element.bgCTSaleId,
                                        dmDichVuId: element.dmDichVuId,
                                        tenDichVu: saleRateDichVuConTreEmResponse[h].tenDichVu,
                                        mapBgChietTinhKsTreEmId:
                                            saleRateDichVuConTreEmResponse[h].mapBgChietTinhKsTreEmId,
                                        mapBgChietTinhNhTreEmId:
                                            saleRateDichVuConTreEmResponse[h].mapBgChietTinhNhTreEmId,
                                        mapBgChietTinhOtoTreEmId:
                                            saleRateDichVuConTreEmResponse[h].mapBgChietTinhOtoTreEmId,
                                        tranxBgChietTinhKsId: saleRateDichVuConTreEmResponse[h].tranxBgChietTinhKsId,
                                        tranxBgChietTinhNhId: saleRateDichVuConTreEmResponse[h].tranxBgChietTinhNhId,
                                        tranxBgChietTinhOtoId: saleRateDichVuConTreEmResponse[h].tranxBgChietTinhOtoId,
                                        rowSpanLoaiDV:
                                            saleRateDichVuDetailTreEmResponse.length === 0 && j === 0 && h === 0
                                                ? rowSpanLDV
                                                : 0,
                                        rowSpanNcc: saleRateDichVuDetailTreEmResponse.length === 0 ? 1 : 0,
                                        rowSpanDV: saleRateDichVuDetailTreEmResponse.length === 0 ? 1 : 0,
                                    });
                            }
                        }
                    }
                }
            }
            console.log("dataTe: ", dataTe);

            return dataTe;
        }
    };

    const mapDataNL = (saleRateDichVuResponse, saleRateDichVuNcc, element) => {
        if (saleRateDichVuResponse) {
            if (saleRateDichVuResponse.saleRateDichVuDetailResponse.length > 0) {
                return saleRateDichVuResponse.saleRateDichVuDetailResponse.map((saleRateDichVuDetailResponse) => {
                    return {
                        ...saleRateDichVuDetailResponse,
                        rowSpan: saleRateDichVuResponse.rowSpan,
                        id: saleRateDichVuResponse.id,
                        tenDichVu: saleRateDichVuResponse.tenDichVu,
                        thayDoiDichVu: saleRateDichVuResponse.thayDoiDichVu,
                        trangThaiThayDoiId: saleRateDichVuResponse.trangThaiThayDoiId,
                        trangThaiDuyet: saleRateDichVuResponse.trangThaiDuyet,
                        trangThaiDuyetId: saleRateDichVuResponse.trangThaiDuyetId,
                        tenNcc: saleRateDichVuNcc.tenNcc,
                        nccThongTinChungId: saleRateDichVuNcc.nccThongTinChungId,
                        parentBgMapCTSaleHtId: saleRateDichVuNcc.parentBgMapCTSaleHtId,
                        dmGiaApDungId: saleRateDichVuNcc.dmGiaApDungId,
                        dmLoaiApDungId: saleRateDichVuNcc.dmLoaiApDungId,
                        moTa: saleRateDichVuNcc.moTa,
                        loaiDichVu: element.loaiDichVu,
                        bgChietTinhOPId: element.bgChietTinhOPId,
                        bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
                        dmDichVuId: element.dmDichVuId,
                        bgCTSaleId: element.bgCTSaleId,
                        stt: element.stt,
                    };
                });
            } else {
                return [
                    {
                        rowSpan: saleRateDichVuResponse.rowSpan,
                        id: saleRateDichVuResponse.id,
                        tenDichVu: saleRateDichVuResponse.tenDichVu,
                        thayDoiDichVu: saleRateDichVuResponse.thayDoiDichVu,
                        trangThaiThayDoiId: saleRateDichVuResponse.trangThaiThayDoiId,
                        trangThaiDuyet: saleRateDichVuResponse.trangThaiDuyet,
                        trangThaiDuyetId: saleRateDichVuResponse.trangThaiDuyetId,
                        tenNcc: saleRateDichVuNcc.tenNcc,
                        nccThongTinChungId: saleRateDichVuNcc.nccThongTinChungId,
                        parentBgMapCTSaleHtId: saleRateDichVuNcc.parentBgMapCTSaleHtId,
                        dmGiaApDungId: saleRateDichVuNcc.dmGiaApDungId,
                        dmLoaiApDungId: saleRateDichVuNcc.dmLoaiApDungId,
                        moTa: saleRateDichVuNcc.moTa,
                        loaiDichVu: element.loaiDichVu,
                        bgChietTinhOPId: element.bgChietTinhOPId,
                        bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
                        dmDichVuId: element.dmDichVuId,
                        bgCTSaleId: element.bgCTSaleId,
                        stt: element.stt,
                    },
                ];
            }
        }
        if (saleRateDichVuNcc)
            return [
                {
                    ...saleRateDichVuNcc,
                    loaiDichVu: element.loaiDichVu,
                    bgChietTinhOPId: element.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
                    dmDichVuId: element.dmDichVuId,
                    bgCTSaleId: element.bgCTSaleId,
                },
            ];
        if (element)
            return [
                {
                    ...element,
                },
            ];
    };

    const columns = [
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("supplier"),
        },
        {
            title: t("service"),
        },
        {
            title: t("luaChon"),
        },
        {
            title: t("hotel.roomClass.conditionFoc"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.intoMoney"),
        },
        {
            title: t("thuevat"),
        },
        {
            title: t("action"),
        },
    ];
    const columnsTe = [
        {
            title: "STT",
            visible: true,
        },
        {
            title: t("typeService"),
            visible: true,
        },
        {
            title: t("supplier"),
            visible: true,
        },
        {
            title: t("service"),
            visible: true,
        },
        {
            title: t("childrenGroup"),
            visible: dataNhomTreEm.length > 0,
        },
        {
            title: t("ageOfChild"),
            visible: true,
        },
        {
            title: t("tour.percentage"),
            visible: true,
        },
        {
            title: t("tour.donGia"),
            visible: true,
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            visible: true,
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            visible: true,
        },
        {
            title: t("thuevat"),
            visible: true,
        },
        {
            title: t("action"),
            visible: true,
        },
    ];
    const handleAddServices = (item) => {
        if (item.bgMapCTSaleHtId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setBgChietTinhSaleId(item.bgCTSaleId);
        setDmDichVuId(item.dmDichVuId);
        setBgMapCTSaleHtId(item.bgMapCTSaleHtId ? item.bgMapCTSaleHtId : 0);
        switch (item.loaiDichVu) {
            case "Khách sạn":
                return setOpenKs(true);
            case "Xe ô tô":
                return setOpenOto(true);
            case "Nhà hàng":
                return setOpenNh(true);
            default:
                return null;
        }
    };
    const handleCancelKS = () => {
        setOpenKs(false);
    };

    const handleCancelOto = () => {
        setOpenOto(false);
    };

    const handleCancelNh = () => {
        setOpenNh(false);
    };
    const handleAddRowServices = (index, item) => {
        let dt = [];
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpanDV !== 0 && it.loaiDichVu === item.loaiDichVu);
        setEditingId(crrFirstDV);
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirstDV) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: 1,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    rowSpanDV: rowSpanDV,
                    loaiDichVu: item.loaiDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    tenNcc: "",
                    dieuKienFoc: "",
                    foc: "",
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    tenDichVu: "",
                });

                element.rowSpanDV = 0;
            } else {
                // if (key === crrFirstDV) {
                //     element.rowSpanDV = rowSpanDV;
                // }
                // // if (key === crrFirst) {
                // //     element.rowSpan = rowSpan;
                // // }
                // if (key === index)
                //     dt.push({
                //         id: Math.random() + "NEW",
                //         rowSpan: 1,
                //         loaiDichVu: item.loaiDichVu,
                //         bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                //         nccThongTinChungId: item.nccThongTinChungId,
                //         parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                //         tenNcc: item.tenNcc,
                //         dmGiaApDungId: item.dmGiaApDungId,
                //         dmLoaiApDungId: item.dmLoaiApDungId,
                //         bgCTSaleId: item.bgCTSaleId,
                //         moTa: item.moTa,
                //         dieuKienFoc: item.dieuKienFoc,
                //         foc: item.foc,
                //         bgChietTinhOPId: item.bgChietTinhOPId,
                //         bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                //         dmDichVuId: item.dmDichVuId,
                //     });
            }
            dt.push(element);
        }
        setDataTableNguoiLon(dt);
    };
    const handleAddBySerivce = (dataTableNguoiLon, index, item) => {
        let dt = [];
        let crrFirst = dataTableNguoiLon.findIndex(
            (it) => it.rowSpan !== 0 && it.tenNcc === item.tenNcc && it.loaiDichVu === item.loaiDichVu
        );
        setEditingId(crrFirst);
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpan !== 0 && it.loaiDichVu === item.loaiDichVu);
        let rowSpan = dataTableNguoiLon[crrFirst].rowSpan + 1;
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirst) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    loaiDichVu: item.loaiDichVu,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    tenNcc: item.tenNcc,
                    bgCTSaleId: item.bgCTSaleId,
                    dieuKienFoc: item.dieuKienFoc,
                    foc: item.foc,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    rowSpanDV: rowSpanDV,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    tenDichVu: "",
                });
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }

            if (element.rowSpan !== 0 && element.tenNcc === item.tenNcc && element.loaiDichVu === item.tenDichV) {
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }
            dt.push(element);
        }
        setDataTableNguoiLon(dt);
    };
    const handleChangeTenNCC = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.tenNcc = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeDmDichVuId = (index, value, loaiDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index && loaiDichVu === "Khách sạn") item.luaChonId = value;
            if (key === index && loaiDichVu === "Nhà hàng") item.dmBuaAnId = value;
            if (key === index && loaiDichVu === "Xe ô tô") item.soXe = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeDieuKienFoc = (index, value, loaiDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.dieuKienFoc = value;
            }

            return item;
        });
        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeDonGia = (index, value, loaiDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.giaDichVu = value;
            }
            return item;
        });
        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeFoc = (index, value, loaiDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.foc = value;
            }
            return item;
        });

        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeThanhTien = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.thanhTien = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeVat = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.vat = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const tinhToanThanhTable = (data) => {
        let dtChange = [];
        dtChange = data.map((element) => {
            if (element.id.indexOf("NULL") === -1)
                if (element.loaiDichVu === "Nhà hàng") {
                    element.thanhTien = tinhThanhTienNH(
                        element.dieuKienFoc,
                        element.giaDichVu,
                        element.foc,
                        element.soLuong
                    );
                } else if (element.loaiDichVu === "Xe ô tô") {
                    element.thanhTien = element.soLuong * element.giaDichVu;
                } else {
                    let dataKS = data.filter(
                        (it) => it.loaiDichVu === element.loaiDichVu && it.tenNcc === element.tenNcc
                    );
                    let thanhTien = tinhThanhTienKS(
                        dataKS.map((it) => it.soLuong),
                        dataKS.map((it) => it.giaDichVu),
                        Math.floor(element.dieuKienFoc / element.foc)
                    );
                    for (let i = 0; i < dataKS.length; i++) {
                        if (dataKS[i].id === element.id) {
                            element.thanhTien = thanhTien[i];
                        }
                    }
                }
            return element;
        });
        return dtChange;
    };
    const handleChangeSoLuong = (index, value, loaiDichVu) => {
        setEditingId(index);
        let data = [];
        data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.soLuong = value;
            }
            return item;
        });

        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeDichVu = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.tenDichVu = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleReload = () => {
        setEditingId(null);
        setReload(true);
    };

    const handleSave = (index, value, loaiDichVu) => {
        let dichVus = [];
        let saleRateDichVuConTreEmResponse = [];

        if (dataTableNguoiLon.length > 0) {
            if (loaiDichVu === "Xe ô tô") {
                const dataTe = value.bgNhomNguoiLons?.flatMap((d) =>
                    dataNhomTreEm.filter((e) => e.nhomNguoiLonId === d)
                );

                saleRateDichVuConTreEmResponse = dataTe.map((e) => ({
                    bgNhomTreEmId: e.id,
                    saleRateDichVuDetailTreEmResponse: [
                        ...e.saleRateDichVuDetailTreEmResponse.map((d) => ({
                            ...d,
                            tranxNhaHangId: d.tranxNhaHangId || 0,
                        })),
                    ],
                }));
            } else {
                const element = dataTableNguoiLon.find((element) => element.loaiDichVu === loaiDichVu);

                if (element) {
                    saleRateDichVuConTreEmResponse = dataNhomTreEm
                        .filter((e) => e.nhomNguoiLonId === element.bgNhomNguoiLonId)
                        .map((e) => ({
                            bgNhomTreEmId: e.id,
                            saleRateDichVuDetailTreEmResponse: [
                                ...e.saleRateDichVuDetailTreEmResponse.map((d) => ({
                                    ...d,
                                    tranxNhaHangId: d.tranxNhaHangId || 0,
                                })),
                            ],
                        }));
                }
            }
        } else {
            saleRateDichVuConTreEmResponse = dataNhomTreEm.map((e) => ({
                bgNhomTreEmId: e.id,
                saleRateDichVuDetailTreEmResponse: [
                    ...e.saleRateDichVuDetailTreEmResponse.map((d) => ({
                        ...d,
                        tranxNhaHangId: d.tranxNhaHangId || 0,
                    })),
                ],
            }));
        }

        for (let i = dataTableNguoiLon.length - 1; i >= 0; i--) {
            const element = dataTableNguoiLon[i];
            if (
                value.bgMapCTSaleHtId === element.bgMapCTSaleHtId &&
                element.loaiDichVu === loaiDichVu &&
                element.id.indexOf("NULL") === -1
            ) {
                let dichVuData = {
                    dmVatId: dmVat.find((d) => element.vat === d.vat)?.id,
                    nccThongTinChungId: element.nccThongTinChungId,
                    tenDichVu: element.tenDichVu,
                    dmBuaAnId: element.dmBuaAnId,
                    luaChonId: element.luaChonId,
                    dmGiaApDungId: element.dmGiaApDungId,
                    tranxGiaPhongId: 0,
                    dmSoChoId: element.dmSoChoId,
                    bgNhomNguoiLonId: element.bgNhomNguoiLonId,
                    tranxNhaHangId: element.tranxNhaHangId || 0,
                    nccDichVuNhaHangId: element.nccDichVuNhaHangId || 0,
                    saleRateDichVuConTreEmResponse,
                };

                if (loaiDichVu === "Xe ô tô") {
                    dichVuData = {
                        ...dichVuData,
                        giaOtoNhapTay: element.donGia,
                        soLuong: element.soLuong,
                        bgNhomNguoiLons: value.bgNhomNguoiLons?.map((d) => ({ bgNhomNguoiLonId: d })) || [],
                        soXe: element.soXe,
                    };
                } else if (loaiDichVu === "Khách sạn") {
                    dichVuData = {
                        ...dichVuData,
                        donGiaNhapTay: element.donGia,
                        soLuongNhapTay: element.soLuong,
                    };
                } else if (loaiDichVu === "Nhà hàng") {
                    dichVuData = {
                        ...dichVuData,
                        giaNhaHangNhapTay: element.donGia,
                        soLuong: element.soLuong,
                        dieuKienFoc: value.dieuKienFoc,
                        foc: value.foc,
                    };
                }

                dichVus.push(dichVuData);
            }
        }

        const data = {
            baoGiaTongQuanId: baoGiaTongQuanId,
            bgMapCTSaleHtId: value.bgMapCTSaleHtId || 0,
            nccThongTinChungId: value.nccThongTinChungId || 0,
            bgCTSaleId: value.bgCTSaleId || 0,
            tenNcc: value.tenNcc,
            dmDichVuId: value.dmDichVuId,
            dmGiaApDungId: value.dmGiaApDungId,
            dieuKienFoc: loaiDichVu === "Nhà hàng" ? 0 : value.dieuKienFoc,
            foc: loaiDichVu === "Nhà hàng" ? 0 : value.foc,
            dichVus: dichVus,
        };

        console.log(JSON.stringify(data));

        const saveService = (service, saveFunction) => {
            saveFunction(service).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        };

        if (loaiDichVu === "Khách sạn") {
            saveService(data, khachSanService.saveCTSaleKhachSan);
        } else if (loaiDichVu === "Nhà hàng") {
            saveService(data, nhaHangService.saveChietTinhSaleNhaHang);
        } else if (loaiDichVu === "Xe ô tô") {
            saveService(data, otoServiceBG.saveCTSaleOto);
        }
    };
    const handleDelete = (key, value) => {
        khachSanService
            .deleteCTSaleDichVuKs({
                tranxBgChietTinhKsId: value.tranxBgChietTinhKsId,
            })
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
    };
    const handleClear = () => {
        handleReload();
    };
    const handleChangeNhomNLOto = (index, event) => {
        const {
            target: { value },
        } = event;
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.bgNhomNguoiLons = typeof value === "string" ? value.split(",") : value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeNhomNL = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.bgNhomNguoiLonId = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const tinhThanhTienNH = (numberOfRates, unitPrice, FOCConditions, countOfService) => {
        // Calculate FOC quantity
        const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

        // Calculate total service cost
        const totalServiceCost = countOfService * unitPrice;

        // Calculate service cost after applying FOC policy
        return totalServiceCost - FOCQuantity ? FOCQuantity : 0 * unitPrice;
    };
    const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
        //FOCConditions dk foc
        //numberOfRoomsBooked: số lượng
        //unitPrices đơn giá
        // Define variables
        const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce((acc, val) => acc + val, 0);
        // Calculate B5
        const lowestRoomCategoryPrice = Math.min(...unitPrices);
        const averageReducedPricePerRoom =
            (lowestRoomCategoryPrice * Math.floor(tongSoLuongPhongNCC / FOCConditions)) / tongSoLuongPhongNCC;

        // Calculate B6
        return numberOfRoomsBooked.map(
            (count, index) => count * unitPrices[index] - averageReducedPricePerRoom * count
        );
    };
    const table_nl = () => {
        return (
            <>
                <table>
                    <thead>
                        {columns.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dataTableNguoiLon?.map((item, key) => {
                            return (
                                <tr key={key}>
                                    {item.rowSpanDV && item.rowSpanDV !== 0 ? (
                                        <>
                                            <td rowSpan={item.rowSpanDV}>{item.stt}</td>
                                            <td rowSpan={item.rowSpanDV}>{item.loaiDichVu}</td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {item.id.indexOf("NULL") !== -1 ? (
                                        <>
                                            <>
                                                <td colSpan="100%">
                                                    <Link
                                                        onClick={() => handleAddRowServices(key, item)}
                                                        disabled={editingId === null ? false : editingId !== key}
                                                    >
                                                        {t("add") + " " + t("service").toLowerCase()}
                                                    </Link>
                                                </td>
                                            </>
                                        </>
                                    ) : (
                                        <>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        <TextField
                                                            disabled={editingId === null ? false : editingId !== key}
                                                            size="small"
                                                            value={item.tenNcc}
                                                            onChange={(e) => handleChangeTenNCC(key, e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton size="small">
                                                                            <EditFilled
                                                                                onClick={() =>
                                                                                    handleAddServices(
                                                                                        item
                                                                                        // baoGiaHanhTrinhId,
                                                                                        // ngay
                                                                                    )
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton size="small">
                                                                            <CloseCircleFilled />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {item.loaiDichVu === "Khách sạn" ? (
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleAddBySerivce(dataTableNguoiLon, key, item)
                                                                }
                                                            >
                                                                <PlusSquareFilled />
                                                            </IconButton>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Row>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <TextField
                                                            disabled={editingId === null ? false : editingId !== key}
                                                            size="small"
                                                            value={item.tenDichVu}
                                                            onChange={(e) => handleChangeDichVu(key, e.target.value)}
                                                            fullWidth
                                                            InputProps={
                                                                item.thayDoiDichVu && item.trangThaiDuyet ? (
                                                                    {
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton size="small" disabled>
                                                                                    <ReportIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                {item.thayDoiDichVu && item.trangThaiDuyet ? (
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Typography.Text
                                                                type={
                                                                    item.trangThaiDuyet === "Chờ duyệt"
                                                                        ? "danger"
                                                                        : item.trangThaiDuyet === "Đồng ý"
                                                                        ? "success"
                                                                        : "warning"
                                                                }
                                                            >
                                                                {item.thayDoiDichVu} / {item.trangThaiDuyet}
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                            <td>
                                                {item.loaiDichVu === "Khách sạn" ? (
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        select
                                                        value={item.luaChonId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(key, e.target.value, item.loaiDichVu)
                                                        }
                                                    >
                                                        {kieuPhong.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : item.loaiDichVu === "Nhà hàng" ? (
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        select
                                                        value={item.luaChonId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(key, e.target.value, item.loaiDichVu)
                                                        }
                                                    >
                                                        {dmBuaAn.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : (
                                                    <>
                                                        <TextField
                                                            disabled={editingId === null ? false : editingId !== key}
                                                            size="small"
                                                            value={item.luaChon}
                                                            onChange={(e) =>
                                                                handleChangeDmDichVuId(
                                                                    key,
                                                                    e.target.value,
                                                                    item.loaiDichVu
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        {item.loaiDichVu === "Xe ô tô" ? (
                                                            <>
                                                                <TextField size="small" />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TextField
                                                                    disabled={
                                                                        editingId === null ? false : editingId !== key
                                                                    }
                                                                    size="small"
                                                                    onChange={(e) =>
                                                                        handleChangeDieuKienFoc(
                                                                            key,
                                                                            e.target.value,
                                                                            item.loaiDichVu
                                                                        )
                                                                    }
                                                                    value={item.dieuKienFocNh}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {item.loaiDichVu === "Khách sạn"
                                                                                    ? t("room")
                                                                                    : t("set")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <TextField
                                                                    disabled={
                                                                        editingId === null ? false : editingId !== key
                                                                    }
                                                                    size="small"
                                                                    value={item.focNh}
                                                                    onChange={(e) =>
                                                                        handleChangeFoc(
                                                                            key,
                                                                            e.target.value,
                                                                            item.loaiDichVu
                                                                        )
                                                                    }
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {t("hotel.roomClass.foc")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        value={item.donGia}
                                                        onChange={(e) =>
                                                            handleChangeDonGia(key, e.target.value, item.loaiDichVu)
                                                        }
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        onChange={(e) =>
                                                            handleChangeSoLuong(key, e.target.value, item.loaiDichVu)
                                                        }
                                                        value={item.soLuong}
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled
                                                        size="small"
                                                        value={item.thanhTien}
                                                        onChange={(e) => handleChangeThanhTien(key, e.target.value)}
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <TextField
                                                    disabled={editingId === null ? false : editingId !== key}
                                                    size="small"
                                                    select
                                                    value={item.dmVatId}
                                                    onChange={(e) => handleChangeVat(key, e.target.value)}
                                                >
                                                    {dmVat.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </td>

                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    {item.trangThaiDuyet === "Chờ duyệt" ? (
                                                        <>
                                                            <IconButton
                                                                disabled={
                                                                    editingId === null ? false : editingId !== key
                                                                }
                                                                onClick={() => handleSave(key, item, item.loaiDichVu)}
                                                            >
                                                                <TurnRightIcon />
                                                            </IconButton>
                                                            {item.thayDoiDichVu === "Hủy" ? (
                                                                <>
                                                                    <IconButton
                                                                        disabled={
                                                                            editingId === null
                                                                                ? false
                                                                                : editingId !== key
                                                                        }
                                                                        onClick={() =>
                                                                            handleSave(key, item, item.loaiDichVu)
                                                                        }
                                                                    >
                                                                        <Restore />
                                                                    </IconButton>
                                                                </>
                                                            ) : (
                                                                <IconButton
                                                                    disabled={
                                                                        editingId === null ? false : editingId !== key
                                                                    }
                                                                    onClick={() =>
                                                                        handleSave(key, item, item.loaiDichVu)
                                                                    }
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            )}
                                                        </>
                                                    ) : item.trangThaiDuyet === "Từ chối" &&
                                                      item.thayDoiDichVu === "Hủy" ? (
                                                        <>
                                                            <IconButton
                                                                disabled={
                                                                    editingId === null ? false : editingId !== key
                                                                }
                                                                onClick={() => handleSave(key, item, item.loaiDichVu)}
                                                            >
                                                                <CheckOutlined />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleClear()}>
                                                                <CloseOutlined />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconButton
                                                                disabled={
                                                                    editingId === null ? false : editingId !== key
                                                                }
                                                                onClick={() => handleSave(key, item, item.loaiDichVu)}
                                                            >
                                                                <CheckOutlined />
                                                            </IconButton>
                                                            {editingId !== key ? (
                                                                <IconButton
                                                                    onClick={() => handleDelete(key, item)}
                                                                    disabled={
                                                                        editingId === null ? false : editingId !== key
                                                                    }
                                                                >
                                                                    <DeleteOutlined />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton onClick={() => handleClear()}>
                                                                    <CloseOutlined />
                                                                </IconButton>
                                                            )}
                                                        </>
                                                    )}
                                                </Box>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleAddRowServicesTE = (dataTableTreEm, index, item) => {
        let dt = [];
        let crrFirst = dataTableTreEm.findIndex(
            (it) =>
                it.rowSpan !== 0 &&
                it.loaiDichVu === item.loaiDichVu &&
                it.tenNcc === item.tenNcc &&
                it.saleRateDichVuConTreEmResponse === item.saleRateDichVuConTreEmResponse
        );
        let rowSpan = dataTableTreEm[crrFirst].rowSpan + 1;
        let crrFirstDV = dataTableTreEm.findIndex((it) => it.rowSpanDV !== 0 && it.loaiDichVu === item.loaiDichVu);
        let rowSpanDV = dataTableTreEm[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableTreEm.length; key++) {
            const element = dataTableTreEm[key];
            if (index === crrFirst && key === crrFirst && index === crrFirstDV) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    rowSpanDV: rowSpanDV,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    tenNcc: item.tenNcc,
                    dmGiaApDungId: item.dmGiaApDungId,
                    dmLoaiApDungId: item.dmLoaiApDungId,
                    moTa: item.moTa,
                    dieuKienFoc: item.dieuKienFoc,
                    foc: item.foc,
                    loaiDichVu: item.loaiDichVu,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                    dmDichVuId: item.dmDichVuId,
                });

                element.rowSpan = 0;
                element.rowSpanDV = 0;
            } else {
                if (key === crrFirstDV) {
                    element.rowSpanDV = rowSpanDV;
                }
                if (key === crrFirst) {
                    element.rowSpan = rowSpan;
                }
                if (key === index && key === crrFirst) {
                    element.rowSpan = 0;
                    dt.push({
                        id: Math.random() + "NEW",
                        bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                        nccThongTinChungId: item.nccThongTinChungId,
                        parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                        tenNcc: item.tenNcc,
                        dmGiaApDungId: item.dmGiaApDungId,
                        dmLoaiApDungId: item.dmLoaiApDungId,
                        moTa: item.moTa,
                        rowSpan: rowSpan,
                        dieuKienFoc: item.dieuKienFoc,
                        foc: item.foc,
                        loaiDichVu: item.loaiDichVu,
                        bgChietTinhOPId: item.bgChietTinhOPId,
                        bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                        dmDichVuId: item.dmDichVuId,
                    });
                } else if (key === index && key !== crrFirst) {
                    dt.push({
                        id: Math.random() + "NEW",
                        bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                        nccThongTinChungId: item.nccThongTinChungId,
                        parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                        tenNcc: item.tenNcc,
                        dmGiaApDungId: item.dmGiaApDungId,
                        dmLoaiApDungId: item.dmLoaiApDungId,
                        moTa: item.moTa,
                        dieuKienFoc: item.dieuKienFoc,
                        foc: item.foc,
                        loaiDichVu: item.loaiDichVu,
                        bgChietTinhOPId: item.bgChietTinhOPId,
                        bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                        dmDichVuId: item.dmDichVuId,
                        rowSpan: 0,
                    });
                }
            }
            dt.push(element);
        }
        setDataTableTreEm(dt);
    };

    const handleAddAge = (dataTE, row) => {
        let indexAdd = dataTE.findIndex((it) => it.id === row.id);
        let dataRowAdd = { ...dataTE[indexAdd] };
        dataTE[indexAdd].rowSpanLoaiDV = 0;
        dataTE[indexAdd].rowSpanNcc = 0;
        dataTE[indexAdd].rowSpanDV = 0;

        dataTE.splice(indexAdd, 0, {
            id: Math.random() + "",
            rowSpanLoaiDV: 0,
            rowSpanNcc: 0,
            rowSpanDV: 0,
            loaiDichVu: row.loaiDichVu,
            tenNcc: row.tenNcc,
            loaiDichVu: row.loaiDichVu,
            giaDichVu: row.giaDichVu,
            bgCTSaleId: row.bgCTSaleId,
            dmDichVuId: row.dmDichVuId,
            mapBgChietTinhKsTreEmId: row.mapBgChietTinhKsTreEmId,
            mapBgChietTinhNhTreEmId: row.mapBgChietTinhNhTreEmId,
            mapBgChietTinhOtoTreEmId: row.mapBgChietTinhOtoTreEmId,
            tranxBgChietTinhKsId: row.tranxBgChietTinhKsId,
            tranxBgChietTinhNhId: row.tranxBgChietTinhNhId,
            tranxBgChietTinhOtoId: row.tranxBgChietTinhOtoId,
            phanTram: 0,
            soLuong: 0,
            dmDoTuoiId: 1,
            dmVatId: 1,
        });

        let crrFirstLoaiDichVu = dataTE.findIndex((it) => it.loaiDichVu === row.loaiDichVu);
        let crrFirstNcc = dataTE.findIndex(
            (it) => it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNcc === row.tenNcc
        );
        let crrFirstDV = dataTE.findIndex(
            (it) =>
                it.rowSpanDV !== 0 &&
                it.loaiDichVu === row.loaiDichVu &&
                it.tenNcc === row.tenNcc &&
                it.loaiDichVu === row.loaiDichVu
        );
        if (crrFirstLoaiDichVu !== -1) {
            dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV += dataRowAdd.rowSpanLoaiDV + 1;
            // dataTE[crrFirstLoaiDichVu].stt = dataRowAdd.stt;
        }
        if (crrFirstNcc !== -1) {
            dataTE[crrFirstNcc].rowSpanNcc += 1;
        } else {
            dataTE[indexAdd].rowSpanNcc += dataRowAdd.rowSpanNcc + 1;
            // dataTE[indexAdd].rowSpanNcc += 2;
        }
        if (crrFirstDV !== -1) {
            dataTE[crrFirstDV].rowSpanDV += 1;
        } else {
            dataTE[indexAdd].rowSpanDV += 2;
        }

        setDataTableTreEm([...dataTE]);
    };

    const handleSaveCTDoTuoi = async (row) => {
        let action = "edit";
        let dataReq = {
            baoGiaTongQuanId,
            bgCTSaleId: row.bgCTSaleId,
            dmDoTuoiId: row.dmDoTuoiId,
            phanTram: row.phanTram,
            soLuong: row.soLuong,
            dmVatId: row.dmVatId,
            apDung: true,
        };
        let response = { status: 500 };
        if (row.loaiDichVu === "Khách sạn") {
            if (row.tranxBgCtKsSaleTreEmId) dataReq.tranxBgCtKsSaleTreEmId = row.tranxBgCtKsSaleTreEmId;
            else {
                dataReq.tranxBgCtKsSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgChietTinhKsTreEmId = row.mapBgChietTinhKsTreEmId;
            dataReq.tranxBgChietTinhKsId = row.tranxBgChietTinhKsId;
            response = await khachSanService.saveChietTinhDoTuoi(dataReq);
        } else if (row.loaiDichVu === "Nhà hàng") {
            if (row.tranxBgCtNhSaleTreEmId) dataReq.tranxBgCtNhSaleTreEmId = row.tranxBgCtNhSaleTreEmId;
            else {
                dataReq.tranxBgCtNhSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgCtNhSaleTreEmId = row.mapBgChietTinhNhTreEmId;
            dataReq.tranxBgChietTinhNhId = row.tranxBgChietTinhNhId;
            response = await nhaHangService.saveChietTinhDoTuoi(dataReq);
        } else {
            if (row.tranxBgCtNhSaleTreEmId) dataReq.tranxBgCtOtoSaleTreEmId = row.tranxBgCtOtoSaleTreEmId;
            else {
                dataReq.tranxBgCtOtoSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgCtOtoSaleTreEmId = row.mapBgChietTinhOtoTreEmId;
            dataReq.tranxBgChietTinhOtoId = row.tranxBgChietTinhOtoId;
            dataReq.giaNguoiLon = row.giaDichVu;
        }
        if (response.status === 200 && response.data.code === "00") {
            handleReload();
            showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("add") + " " + t("noti.error").toLowerCase());
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };

    const handleDeleteAge = async (dataTE, row) => {
        let isDeleted = false;
        let res = { status: 500 };
        if (row.loaiDichVu === "Khách sạn" && row.tranxBgCtKsSaleTreEmId) {
            res = await khachSanService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtKsSaleTreEmId: row.tranxBgCtKsSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === "Nhà hàng" && row.tranxBgCtNhSaleTreEmId) {
            res = await nhaHangService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtNhSaleTreEmId: row.tranxBgCtNhSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === "Xe ô tô" && row.tranxBgCtOtoSaleTreEmId) {
            res = await otoService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtKsSaleTreEmId: row.tranxBgChietTinhKsId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else {
            isDeleted = true;
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
        }
        if (isDeleted) {
            let indexDeleted = dataTE.findIndex((it) => it.id === row.id);
            let dataDeleted = dataTE[indexDeleted];
            dataTE.splice(indexDeleted, 1);
            let crrFirstLoaiDichVu = dataTE.findIndex(
                (it) => it.rowSpanLoaiDV !== 0 && it.loaiDichVu === row.loaiDichVu
            );
            let crrFirstNcc = dataTE.findIndex(
                (it) => it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNcc === row.tenNcc
            );
            let crrFirstDV = dataTE.findIndex(
                (it) =>
                    it.rowSpanDV !== 0 &&
                    it.loaiDichVu === row.loaiDichVu &&
                    it.tenNcc === row.tenNcc &&
                    it.loaiDichVu === row.loaiDichVu
            );
            if (crrFirstLoaiDichVu !== -1) {
                dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV -= 1;
            } else {
                dataTE[indexDeleted].rowSpanLoaiDV = dataDeleted.rowSpanLoaiDV - 1;
                dataTE[indexDeleted].stt = dataDeleted.stt;
            }
            if (crrFirstNcc !== -1) {
                dataTE[crrFirstNcc].rowSpanNcc -= 1;
            } else {
                if (dataDeleted.rowSpanNcc !== 0) dataTE[indexDeleted].rowSpanNcc = dataDeleted.rowSpanNcc - 1;
                else dataTE[indexDeleted].rowSpanNcc = 1;
            }
            if (crrFirstDV !== -1) {
                dataTE[crrFirstDV].rowSpanDV -= 1;
            } else {
                if (dataDeleted.rowSpanDV !== 0) dataTE[indexDeleted].rowSpanDV = dataDeleted.rowSpanDV - 1;
                else dataTE[indexDeleted].rowSpanDV = 1;
            }

            setDataTableTreEm([...dataTE]);
        }
    };

    const handleShowNotiDeleteTE = (res) => {
        if (res.status === 200 && res.data.code === "00") {
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
            return true;
        } else {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
            return false;
        }
    };

    const handleChangItemTE = (dataTE, row, e) => {
        let indexChange = dataTE.findIndex((it) => it.id === row.id);
        let name = e.target.name;
        let value = e.target.value;
        dataTE[indexChange][name] = value;
        if (name !== "dmDoTuoiId") {
            dataTE[indexChange].thanhTien =
                (dataTE[indexChange].phanTram / 100) * dataTE[indexChange].soLuong * dataTE[indexChange].giaDichVu;
        }
        setDataTableTreEm([...dataTE]);
    };

    const table_te = () => {
        let stt = 1;
        return (
            <>
                <table>
                    <thead>
                        {columnsTe
                            .filter((it) => it.visible)
                            .map((cl) => {
                                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                            })}
                    </thead>
                    <tbody>
                        {dataTableTreEm?.map((it, key) => {
                            return (
                                <tr key={it.id}>
                                    {it.rowSpanLoaiDV !== 0 ? (
                                        <>
                                            <td rowSpan={it.rowSpanLoaiDV}>{stt++}</td>
                                            <td rowSpan={it.rowSpanLoaiDV}>{it.loaiDichVu}</td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {it.rowSpanNcc !== 0 ? <td rowSpan={it.rowSpanNcc}>{it.tenNcc}</td> : null}
                                    {it.rowSpanDV !== 0 ? <td rowSpan={it.rowSpanDV}>{it.tenDichVu}</td> : null}
                                    {it.id.indexOf("NEW_ADD") !== -1 ? (
                                        <>
                                            <td colSpan="100%">
                                                <Link onClick={() => handleAddAge(dataTableTreEm, it)}>
                                                    {t("add") + " " + t("age").toLowerCase()}
                                                </Link>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            {dataNhomTreEm.length > 0 && (
                                                <td>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        <TextField value={it.tenNhomTreEm} size="small" disabled />
                                                    </Box>
                                                </td>
                                            )}
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={it.dmDoTuoiId}
                                                        size="small"
                                                        name="dmDoTuoiId"
                                                        select
                                                        disabled={dataNhomTreEm.length > 0}
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                    >
                                                        {dmDoTuoi.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.do_tuoi}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </td>
                                            <td width={"8%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        type="number"
                                                        value={it.phanTram}
                                                        size="small"
                                                        name="phanTram"
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                        InputProps={{
                                                            endAdornment: <>%</>,
                                                        }}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        value={it.giaDichVu}
                                                        size="small"
                                                        name="giaDichVu"
                                                        disabled={true}
                                                    ></TextField>
                                                </Box>
                                            </td>
                                            <td width={"8%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        type="number"
                                                        value={it.soLuong}
                                                        name="soLuong"
                                                        size="small"
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        value={it.giaDichVu * it.soLuong * (it.phanTram / 100)}
                                                        size="small"
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"9%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={it.dmVatId}
                                                        size="small"
                                                        name="dmVatId"
                                                        select
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                    >
                                                        {dmVat.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </td>
                                            <td width={"9%"}>
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <IconButton>
                                                            <CheckIcon onClick={() => handleSaveCTDoTuoi(it)} />
                                                        </IconButton>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <IconButton>
                                                            <Delete
                                                                onClick={() => handleDeleteAge(dataTableTreEm, it)}
                                                            />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };

    const item_date = [
        {
            key: "1",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("adultService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_nl()}</>,
        },
        {
            key: "2",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("childrenService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_te()}</>,
        },
    ];
    const children_dv = (
        <>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={"1"} items={item_date} />
                </Col>
            </Row>
        </>
    );
    const cauHinh = [
        {
            key: "1",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("serviceProvided")}
                </div>
            ),
            children: children_dv,
        },
        {
            key: "2",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("totalPrice")}
                </div>
            ),
            children: (
                <>
                    <TongKet baoGiaTongQuanId={baoGiaTongQuanId} tongGia={tongGia} setReload={setReload} />
                </>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Collapse defaultActiveKey={"1"} items={cauHinh} />
            <ModalCapNhatKS
                isOpen={openKs}
                onReset={handleReload}
                handleCancel={handleCancelKS}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={0}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
            />
            <ModalCapNhatOto
                isOpen={openOto}
                onReset={handleReload}
                handleCancel={handleCancelOto}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={0}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                soLuongNgLonBG={soLuongNgLonBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
            />
            <ModalCapNhatNhaHang
                isOpen={openNH}
                onReset={handleReload}
                handleCancel={handleCancelNh}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={0}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
            />
        </>
    );
};
export default ViewServies;
