import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";


const getDmDoTuoi= (data) => {
    return axios.post(API_BASE_URL + "category/getDmDoTuoi", data,  { headers: authHeader() });
};
export default {
    getDmDoTuoi
}