import React, { useState } from "react";
import { Modal, Input, Button, Row, Col, Form, Space, Divider, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

const ModalExRate = ({ isOpen, handleCancel, handleFinish }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const onFinish = (values) => {
        setIsModalOpen(false);
        handleFinish(isModalOpen, values);
        form.resetFields();
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal title={t("exchangeRate.add")} open={isOpen} onOk={onFinish} onCancel={handleCancel} footer={null}>
                <Divider />
                <Form
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                >
                    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                        <Row>
                            <Col span={18} push={6}>
                                <Form.Item
                                    name="ma_ngoai_te"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("validate") + " " + t("exchangeRate.currencyCode").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6} pull={18}>
                                {t("exchangeRate.currencyCode")}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={18} push={6}>
                                <Form.Item
                                    name="loai_tien"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("validate") + " " + t("exchangeRate.currency").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6} pull={18}>
                                {t("exchangeRate.currency")}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={18} push={6}>
                                <Form.Item
                                    name="ty_gia"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("validate") + " " + t("exchangeRate.rate").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} pull={18}>
                                {t("exchangeRate.rate")}
                            </Col>
                        </Row>
                    </Space>
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Space>
                            <Button type="primary" htmlType="submit">
                                {t("action.ok")}
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#898989",
                                    color: "white",
                                }}
                                key="clear"
                                onClick={(e) => handleClear(e)}
                            >
                                {t("action.reset")}
                            </Button>
                            <Button key="back" onClick={handleCancel}>
                                {t("action.cancel")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalExRate;
