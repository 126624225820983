import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";


const getDmQuocTich = (data) => {
    return axios.post(API_BASE_URL + "category/getDmQuocTich", data,  { headers: authHeader() });
};

export default {
    getDmQuocTich,

}