import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDmTyGiaHeThong = (data) => {
  return axios.post(API_BASE_URL + "category/getDmTyGiaHeThong", data, {
    headers: authHeader(),
  });
};
const getDmTyGiaQuyDoi = (data) => {
  return axios.post(API_BASE_URL + "category/getDmTyGiaQuyDoi", data, {
    headers: authHeader(),
  });
};
const getTyGiaQuyDoi = (data) => {
  return axios.post(
    API_BASE_URL + "category/getTyGiaQuyDoiByMaNgoaiTeIdVsMaQuyDoiId",
    data,
    {
      headers: authHeader(),
    }
  );
};
export default {
  getDmTyGiaHeThong,
  getDmTyGiaQuyDoi,
  getTyGiaQuyDoi,
};
