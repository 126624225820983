import {ACCESS_TOKEN} from '../constants';
import Axios from 'axios'

export function request(options) {
    const headers = new Headers({
        "Content-Type": 'application/json',
        'Postman-Token': 'a01922bc-5fb6-4227-8547-976e8669cb52'
    });

    if (getStorage(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + getStorage(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function requestFile(options) {
    const headers = new Headers({});

    if (getStorage(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + getStorage(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};


/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(name) {
    try {
        localStorage.removeItem(name);
        localStorage.removeItem(name + '_expiresIn');
        localStorage.clear();
    } catch (e) {
        console.log('removeStorage: Error removing key [' + name + '] from localStorage: ' + JSON.stringify(e));
        return false;
    }
    return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {

    var now = Date.now();  //epoch time, lets deal only with integer
    // set expiration for storage
    var expiresIn = localStorage.getItem(key + '_expiresIn');
    if (expiresIn === undefined || expiresIn === null) {
        expiresIn = 0;
    }
    if (localStorage.getItem(key)) {
        if (localStorage.getItem(key).length < 10) {
            removeStorage(key);
            localStorage.clear();
            return null;
        }
    }

    if (expiresIn < now) {// Expired
        removeStorage(key);
        return null;
    } else {
        try {
            var value = localStorage.getItem(key);
            return value;
        } catch (e) {
            console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
            return null;
        }
    }
}

/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {

    if (expires === undefined || expires === null) {
        expires = (1 * 60 * 60);  // default: seconds for 1 day
    } else {
        expires = Math.abs(expires); //make sure it's positive
    }

    let now = Date.now();  //millisecs since epoch time, lets deal only with integer
    let schedule = now + expires * 1000;
    try {
        localStorage.setItem(key, value);
        localStorage.setItem(key + '_expiresIn', schedule);
    } catch (e) {
        console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
        return false;
    }
    return true;
}

const axios = Axios.create()
const getRefreshedToken = () => {
    return axios.post('/refresh_endpoint')
}

const refreshToken = async () => {
    const newToken = await getRefreshedToken()
    setStorage(newToken)
}

axios.interceptors.request.use(async (req) => {

    await refreshToken()

})