import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";
const getSaleRate = (data) => {
    return axios.post(API_BASE_URL + "baogia/layThongTinChietTinhSale", data, {
        headers: authHeader(),
    });
};
export default {
    getSaleRate,
};