import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridActionsCellItem, GridPagination, enUS, viVN } from "@mui/x-data-grid";
import DieuHanhService from "../../../api/dieu_hanh/DieuHanhService";
import { Col, Row, Space } from "antd";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@mui/icons-material/Done";
import Search from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormTimKiem from "./FormTimKiem";
import PhanCongDieuHanh from "./PhanCongDieuHanh";

const TableDieuHanh = ({ data, activeKey, getDataSource, dataTrangThaiAll, dmTrangThaiId, setDmTrangThaiId }) => {
    const { t, i18n } = useTranslation();
    const [vsFooter, setVsFooter] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [dataSource, setDataSource] = useState(data);
    const navigate = useNavigate();

    const handleEditBooking = (param) => {
        navigate("/dieu-hanh/edit?id=" + param.id);
    };
    const columns = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            width: 50,
            type: "number",
        },
        {
            headerName: t("progress"),
            field: "tienDo",
            key: "tienDo",
            align: "center",
            width: 100,
            headerAlign: "center",
        },
        {
            headerName: "ID " + t("booking"),
            field: "maBooking",
            key: "maBooking",
            align: "center",
            width: 220,
            headerAlign: "center",
        },
        {
            headerName: t("name") + " " + t("booking"),
            field: "tenBooking",
            key: "tenBooking",
            align: "center",
            width: 240,
            headerAlign: "center",
        },
        {
            headerName: t("thongTinTour"),
            field: "thongTinTour",
            key: "thongTinTour",
            align: "center",
            width: 300,
            headerAlign: "center",
            renderCell: (params) => (
                <div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
                            {t("typeTour") + "/" + t("service") + ": "}
                        </span>
                        <span>{params.value?.loaiTour}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("soNgay") + ": "}</span>
                        <span>{params.value?.soNgay}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("timeTour") + ": "}</span>
                        <span>{params.value?.thoiGianTour}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("quantity") + ": "}</span>
                        <span>{params.value?.soLuong}</span>
                    </div>
                </div>
            ),
        },
        {
            headerName: t("perform"),
            field: "thucHien",
            key: "thucHien",
            align: "center",
            width: 260,
            headerAlign: "center",
            renderCell: (params) => (
                <div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("sale") + ": "}</span>
                        <span>{params.value?.saleName}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("Executive") + ": "}</span>
                        <span>{params.value?.tenNguoiDieuHanh}</span>
                    </div>
                </div>
            ),
        },
        {
            headerName: t("status"),
            field: "tenTrangThai",
            key: "tenTrangThai",
            align: "center",
            minWidth: 180,
            headerAlign: "center",
            renderCell: ({ value }) => {
                if (value === "Tạo mới Booking") return <Chip icon={<AddIcon />} label={value} color="primary" />;
                else if (value === "Yêu cầu Booking")
                    return <Chip color="warning" icon={<RequestPageIcon />} label={value} />;
                else if (value === "Đã hoàn thành") return <Chip color="success" icon={<DoneIcon />} label={value} />;
                else if (value === "Đã hủy") return <Chip icon={<CloseIcon />} label={value} color="error" />;
            },
        },
        {
            headerName: t("lanSuaCuoi"),
            field: "dateUpdate",
            key: "dateUpdate",
            align: "center",
            width: 180,
            headerAlign: "center",
        },
        {
            field: "actions",
            type: "actions",
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    label={t("tour.taiXuong")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem label={t("edit")} onClick={() => handleEditBooking(params)} showInMenu />,
                <GridActionsCellItem
                    label={t("delete")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem
                    label={t("nhanBan")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem
                    label={t("Executivetransfer")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem
                    label={t("Cancelquote")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem
                    label={t("viewHistory")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
            ],
        },
    ];
    const [isOpen, setIsOpen] = useState(false);
    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <>
            <PhanCongDieuHanh isOpen={isOpen} handleCancel={handleCancel} />
            <FormTimKiem
                activeKey={activeKey}
                dmTrangThaiId={dmTrangThaiId}
                setDmTrangThaiId={setDmTrangThaiId}
                getDataSource={getDataSource}
                dataTrangThaiAll={dataTrangThaiAll}
            />
            <Row style={{ marginTop: "1%" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <DataGrid
                        onRowDoubleClick={(record) => handleEditBooking(record.row)}
                        rows={dataSource}
                        columns={columns}
                        autoHeight
                        checkboxSelection={rowSelectionModel}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            newRowSelectionModel.length > 0 ? setVsFooter(true) : setVsFooter(false);
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowHeight={120}
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                backgroundColor: "#e2e2e2",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontWeight: "bold",
                            },
                        }}
                        slots={{
                            footer: () => (
                                <>
                                    <GridPagination />
                                    {vsFooter ? (
                                        <Box>
                                            <Row>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                    <ClearIcon onClick={() => setVsFooter(false)} />
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    {rowSelectionModel.length} selected
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                                <Space>
                                                    <Button variant="outlined">{t("settlementTour")}</Button>

                                                    <Button variant="outlined" fullWidth>
                                                        {t("completedExecutive")}
                                                    </Button>
                                                </Space>
                                            </Row>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ),
                        }}
                        localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                    />
                </Col>
            </Row>
        </>
    );
};
export default TableDieuHanh;
