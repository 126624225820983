import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Divider, Row, Space, notification } from "antd";
import dich_vu_le_service from "../../../../api/booking/dichVuLe/dich_vu_le_service";
import { Link, useNavigate } from "react-router-dom";
import { Box, IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import Buttons from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import {
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    PlusSquareFilled,
} from "@ant-design/icons";
import otoService from "../../../../api/tour/oto/oto.service";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import dm_do_tuoi from "../../../../api/category/dm_do_tuoi";
import ModalCapNhatNhaHang from "./modalCapNhap/ModalCapNhatNhaHang";
import ModalCapNhatKS from "./modalCapNhap/ModalCapNhatKS";
import ModalCapNhatOto from "./modalCapNhap/ModalCapNhatOto";

const DichVuLe = ({ bookingId, action }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [dataTable, setDataTable] = useState();
    const [editingId, setEditingId] = useState(null);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [kieuPhong, setKieuPhong] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [dmSoCho, setDmSoCho] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [dataDv, setDataDv] = useState();
    const [isOpenKs, setIsOpenKs] = useState(false);
    const [isOpenNh, setIsOpenNh] = useState(false);
    const [isOpenOto, setIsOpenOto] = useState(false);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [bkDichVuLeId, setBkDichVuLeId] = useState(bookingId);
    const [bkThongTinChungId, setBkThongTinChungId] = useState(2);
    const [dmDichVuId, setDmDichVuId] = useState(0);
    const [reload, setReload] = useState(false);
    const [dataTableChiPhi, setDataTableChiPhi] = useState();
    const [tongChiPhi, setTongChiPhi] = useState(0);
    const navigate = useNavigate();
    const [columns, setColumns] = useState([
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("supplier"),
        },
        {
            title: t("service"),
        },
        {
            title: t("luaChon"),
        },
        {
            title: t("hotel.roomClass.conditionFoc"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.intoMoney"),
        },
        {
            title: t("thuevat"),
        },
        {
            title: t("action"),
        },
    ]);
    const columnChiPhi = [
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("typeOfTourism.description"),
        },
        {
            title: t("unit"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.foc"),
        },
        {
            title: t("chietKhau"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tongTien"),
        },
    ];
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response getDmDichVu: ====", response.data.data);
                    let dataDv = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDv.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                    hieu_luc: data[i].hieu_luc,
                                });
                            }
                        }
                        setDataDv(dataDv);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataQMP = [];
                    let data = response.data.data;
                    console.log("getDmKieuPhong", data);
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataBA = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataBA.push({
                                value: data[i].id,
                                label: data[i].ten_bua_an,
                            });
                        }
                    }
                    setDmBuaAn(dataBA);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("Response setDmVat: ====", response.data.data);
                    let vat = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc)
                            vat.push({
                                value: data[i].vat,
                                label: data[i].vat_str,
                                id: data[i].id,
                            });
                    }
                    console.log("Response vat: ====", vat);
                    setDmVat(vat);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        if (bkDichVuLeId) fetchData(bkDichVuLeId);
    }, [reload, bkDichVuLeId]);
    async function fetchData(data) {
        try {
            let response = await dich_vu_le_service.layTTDichVuLeByBkThongTinChungId({
                bkThongTinChungId: bkThongTinChungId,
            });
            const dataArr = [];
            let chiPhiNH = [];
            let chiPhiKS = [];
            let chiPhiOT = [];
            let id = 0;
            let tongChiPhi = 0;
            if (response.data.code === "00") {
                for (let i = 0; i < response.data.data.length; i++) {
                    const element = response.data.data[i];
                    if (element.dmDichVuId === 1) {
                        //phòng đôi id 1, extra 2, đơn 3
                        let unitPrices = [];
                        let numberOfRoomsBooked = [];
                        let first = dataArr.length - 1;
                        let firstKS = chiPhiKS.length;
                        for (const tranxKhachSan of element.tranxKhachSans) {
                            let i = 0;
                            let slPhongDoi = 0;
                            let slPhongDon = 0;
                            let slPhongExtra = 0;
                            tranxKhachSan.tranxKsGiaDichVus.forEach((it) => {
                                if (it.dmKieuPhongId === 1 && it.soLuong !== 0) {
                                    slPhongDoi++;
                                }
                                if (it.dmKieuPhongId === 2 && it.soLuong !== 0) {
                                    slPhongExtra++;
                                }
                                if (it.dmKieuPhongId === 3 && it.soLuong !== 0) {
                                    slPhongDon++;
                                }
                                numberOfRoomsBooked.push(it.soLuong);
                                unitPrices.push(it.giaDichVu);
                            });
                            for (const tranxNhaHang of tranxKhachSan.tranxKsGiaDichVus) {
                                const {
                                    bkTranxKhachSanId,
                                    nccGiaPhongId,
                                    tenDichVu,
                                    tranxGiaPhongId,
                                    isChecked,
                                } = tranxKhachSan;

                                dataArr.push({
                                    id: id++ + "",
                                    bkDichVuLeId: element.bkDichVuLeId,
                                    bkThongTinChungId: element.bkThongTinChungId,
                                    dmDichVuId: 1,
                                    nccThongTinChungId: element.nccThongTinChungId,
                                    tenNcc: element.tenNcc,
                                    ngayBatDau: element.ngayBatDau,
                                    ngayKetThuc: element.ngayKetThuc,
                                    dmGiaApDungId: element.dmGiaApDungId,
                                    dmLoaiApDungId: data.dmLoaiApDungId,
                                    soNgaySuDung: element.soNgaySuDung,
                                    dmVatId: element.dmVatId,
                                    vatStr: element.vatStr,
                                    moTa: element.moTa,
                                    dieuKienFoc: element.dieuKienFoc,
                                    foc: element.foc,
                                    bkTranxKhachSanId,
                                    nccHangPhongId: tranxKhachSan.nccGiaPhongId,
                                    tenDichVu,
                                    nccGiaPhongId,
                                    tranxGiaPhongId,
                                    isChecked,
                                    rowSpan: i === 0 ? tranxKhachSan.tranxKsGiaDichVus.length : 0,
                                    rowSpanDV: 1,
                                    ...tranxNhaHang,
                                    isClearNcc: bkTranxKhachSanId ? true : false,
                                    thanhTien: tinhThanhTienKS(
                                        numberOfRoomsBooked,
                                        unitPrices,
                                        Math.floor(element.dieuKienFoc, element.foc)
                                    ),
                                });
                                // let tong = tinhThanhTienChiPhi(
                                //     tranxNhaHang.soLuong,
                                //     tranxNhaHang.foc,
                                //     tranxNhaHang.chietKhau,
                                //     tranxNhaHang.giaDichVu
                                // );
                                // tongChiPhi += tong;
                                chiPhiKS.push({
                                    ...tranxNhaHang,
                                    bkDichVuLeId: element.bkDichVuLeId,
                                    bkTranxKhachSanId,
                                    id: Math.random().toString(),
                                    stt: i,
                                    loaiDichVu: "Khách sạn",
                                    tenDichVu,
                                    maPhong: tranxNhaHang.maPhong,
                                    ngayBatDau: element.ngayBatDau,
                                    ngayKetThuc: element.ngayKetThuc,
                                    donViStr: tranxNhaHang.donViStr,
                                    soLuong: tranxNhaHang.soLuong,
                                    foc: tranxNhaHang.foc,
                                    chietKhau: tranxNhaHang.chietKhau,
                                    donGia: tranxNhaHang.giaDichVu,
                                    slPhongDoi,
                                    slPhongDon,
                                    slPhongExtra,
                                    dmDichVuId: element.dmDichVuId,
                                });
                                i++;
                            }
                        }
                        let thanhTien = tinhThanhTienKS(
                            numberOfRoomsBooked,
                            unitPrices,
                            Math.floor(element.dieuKienFoc, element.foc)
                        );
                        let i = 0;
                        for (let index = first; index < dataArr.length; index++) {
                            dataArr[index].thanhTien = thanhTien[i];
                            chiPhiKS[firstKS + i].thanhTien =
                                thanhTien[i] * (1 - chiPhiKS[firstKS + i].chietKhau / 100);
                            tongChiPhi += chiPhiKS[firstKS + i].thanhTien;
                        }
                    }

                    if (element.dmDichVuId === 3) {
                        for (const tranxNhaHangs of element.tranxNhaHangs) {
                            dataArr.push({
                                id: id++ + "",
                                bkDichVuLeId: element.bkDichVuLeId,
                                bkThongTinChungId: element.bkThongTinChungId,
                                dmDichVuId: 3,
                                nccThongTinChungId: element.nccThongTinChungId,
                                tenNcc: element.tenNcc,
                                ngayBatDau: element.ngayBatDau,
                                ngayKetThuc: element.ngayKetThuc,
                                dmGiaApDungId: element.dmGiaApDungId,
                                dmLoaiApDungId: element.dmLoaiApDungId,
                                soNgaySuDung: element.soNgaySuDung,
                                dmVatId: element.dmVatId,
                                vatStr: element.vatStr,
                                moTa: element.moTa,
                                dieuKienFoc: element.dieuKienFoc,
                                foc: element.foc,
                                rowSpan: 1,
                                rowSpanDV: 1,
                                ...tranxNhaHangs,
                                thanhTien: tinhThanhTienNH(
                                    element.dieuKienFoc,
                                    tranxNhaHangs.giaDichVu,
                                    tranxNhaHangs.foc,
                                    tranxNhaHangs.soLuong
                                ),
                                isClearNcc: tranxNhaHangs.nccDichVuNhaHangId ? true : false,
                            });
                            let tong = tinhThanhTienChiPhi(
                                tranxNhaHangs.soLuong,
                                tranxNhaHangs.foc,
                                tranxNhaHangs.chietKhau,
                                tranxNhaHangs.giaDichVu
                            );
                            chiPhiNH.push({
                                bkDichVuLeId: element.bkDichVuLeId,
                                ...tranxNhaHangs,
                                id: Math.random().toString(),
                                stt: i,
                                loaiDichVu: "Nhà hàng",
                                tenDichVu: tranxNhaHangs.tenDichVu,
                                ngayBatDau: element.ngayBatDau,
                                ngayKetThuc: element.ngayKetThuc,
                                donViStr: tranxNhaHangs.donViStr,
                                soLuong: tranxNhaHangs.soLuong,
                                foc: tranxNhaHangs.foc,
                                chietKhau: tranxNhaHangs.chietKhau,
                                donGia: tranxNhaHangs.giaDichVu,
                                thanhTien: tong,
                                dmDichVuId: element.dmDichVuId,
                            });
                            tongChiPhi += tong;
                        }
                    }
                    if (element.dmDichVuId === 2) {
                        for (const tranxOtos of element.tranxOtos) {
                            dataArr.push({
                                id: id++ + "",
                                bkDichVuLeId: element.bkDichVuLeId,
                                bkThongTinChungId: element.bkThongTinChungId,
                                dmDichVuId: 2,
                                nccThongTinChungId: element.nccThongTinChungId,
                                tenNcc: element.tenNcc,
                                ngayBatDau: element.ngayBatDau,
                                ngayKetThuc: element.ngayKetThuc,
                                dmGiaApDungId: element.dmGiaApDungId,
                                dmLoaiApDungId: element.dmLoaiApDungId,
                                soNgaySuDung: element.soNgaySuDung,
                                dmVatId: element.dmVatId,
                                vatStr: element.vatStr,
                                moTa: element.moTa,
                                dieuKienFoc: element.dieuKienFoc,
                                foc: element.foc,
                                rowSpan: 1,
                                rowSpanDV: 1,
                                ...tranxOtos,
                                isClearNcc: tranxOtos.nccDichVuOtoId ? true : false,
                            });
                            let tong = tinhThanhTienChiPhi(
                                tranxOtos.soLuong,
                                element.foc,
                                tranxOtos.chietKhau,
                                tranxOtos.giaDichVu
                            );
                            chiPhiOT.push({
                                bkDichVuLeId: element.bkDichVuLeId,
                                ...tranxOtos,
                                id: Math.random().toString(),
                                stt: i,
                                loaiDichVu: "Xe ô tô",
                                tenDichVu: tranxOtos.tenDichVu,
                                ngayBatDau: element.ngayBatDau,
                                ngayKetThuc: element.ngayKetThuc,
                                donViStr: tranxOtos.donViStr,
                                soLuong: tranxOtos.soLuong,
                                foc: element.foc,
                                chietKhau: tranxOtos.chietKhau,
                                donGia: tranxOtos.giaDichVu,
                                thanhTien: tong,
                                dmDichVuId: element.dmDichVuId,
                            });
                            tongChiPhi += tong;
                        }
                    }
                }
            }
            if (chiPhiKS[0]) {
                chiPhiKS[0].rowSpan = chiPhiKS.length;
                chiPhiKS[0].stt = 1;
            }
            if (chiPhiNH[0]) {
                chiPhiNH[0].rowSpan = chiPhiNH.length;
                chiPhiNH[0].stt = 2;
            }
            if (chiPhiOT[0]) {
                chiPhiOT[0].rowSpan = chiPhiOT.length;
                chiPhiOT[0].stt = 3;
            }
            setTongChiPhi(tongChiPhi);
            setDataTableChiPhi([...chiPhiKS, ...chiPhiNH, ...chiPhiOT]);
            dataArr.push({ id: "NULL", rowSpan: 1, rowSpanDV: 0 });
            setDataTable(dataArr);
            console.log(dataArr);
        } catch (error) {
            console.log(error);
        }
    }
    const handleAddRowServices = (item) => {
        let dataArr = [];
        let id = 0;
        for (let i = 0; i < dataTable.length; i++) {
            const element = dataTable[i];
            element.id = id++ + "";
            if (i === dataTable.length - 1) {
                setEditingId(id);
                dataArr.push({
                    stt: i,
                    id: id++ + "",
                    bkDichVuLeId: 0,
                    dmVatId: "",
                    dieuKienFoc: 0,
                    foc: 0,
                    dmKieuPhongId: 0,
                    maPhong: "",
                    dmTyGiaId: 0,
                    giaDichVu: 0,
                    soLuong: 0,
                    rowSpan: 1,
                    rowSpanDV: 1,
                    bkThongTinChungId: 2,
                });
                element.id = "NULL";
            }
            dataArr.push(element);
        }
        setDataTable(dataArr);
    };
    const handleChangeTenNCC = (item, value) => {
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.tenNcc = value;
            }
            return it;
        });
        setDataTable(data);
    };
    const handleAddServices = (item) => {
        console.log(item);
        console.log(dataDv);
        if (item.bkDichVuLeId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setBkDichVuLeId(item.bkDichVuLeId);
        setDmDichVuId(item.dmDichVuId);
        setBkThongTinChungId(item.bkThongTinChungId ? item.bkThongTinChungId : 0);
        switch (item.dmDichVuId) {
            case 1:
                return setIsOpenKs(true);
            case 2:
                return setIsOpenOto(true);
            case 3:
                return setIsOpenNh(true);
            default:
                return null;
        }
    };
    const handleAddBySerivce = (key, item) => {
        let first = dataTable.findIndex((it) => item.tenDichVu === it.tenDichVu && it.rowSpan !== 0);
        let rowSpan = dataTable[first].rowSpan + 1;
        let dataArr = [];
        let id = 0;
        for (let i = 0; i < dataTable.length; i++) {
            const element = dataTable[i];
            if (first === i) {
                dataArr.push({
                    stt: i,
                    id: id++ + "",
                    bkDichVuLeId: 0,
                    bkThongTinChungId: element.bkThongTinChungId,
                    dmDichVuId: element.dmDichVuId,
                    nccThongTinChungId: element.nccDichVuNhaHangId,
                    tenNcc: element.tenNcc,
                    ngayBatDau: element.ngayBatDau,
                    ngayKetThuc: element.ngayKetThuc,
                    dmGiaApDungId: element.dmGiaApDungId,
                    dmLoaiApDungId: element.dmLoaiApDungId,
                    soNgaySuDung: element.soNgaySuDung,
                    dmVatId: "",
                    dieuKienFoc: 0,
                    foc: 0,
                    bkTranxKhachSanId: element.bkTranxKhachSanId,
                    nccHangPhongId: element.nccHangPhongId,
                    tenDichVu: element.tenDichVu,
                    nccGiaPhongId: element.nccGiaPhongId,
                    tranxGiaPhongId: element.tranxGiaPhongId,
                    dmKieuPhongId: 0,
                    maPhong: "",
                    dmTyGiaId: 0,
                    giaKhachSanNhapTay: 0,
                    soLuong: 0,
                    rowSpan: rowSpan,
                    rowSpanDV: 1,
                });
                element.rowSpan = 0;
            } else {
            }
            if (i === dataTable.length - 1) {
            } else element.id = id++ + "";
            dataArr.push(element);
        }
        setDataTable(dataArr);
    };
    const handleChangeDichVu = (item, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.tenDichVu = value;
            }
            return it;
        });
        setDataTable(data);
    };
    const handleChangeDmDichVuId = (item, value, dmDichVuId) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                if (dmDichVuId === 1) it.dmKieuPhongId = value;
                if (dmDichVuId === 2) it.soXe = value;
                if (dmDichVuId === 3) it.dmBuaAnId = value;
            }
            return it;
        });
        setDataTable(data);
    };
    const handleChangeDieuKienFoc = (item, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.dieuKienFoc = value;
                if (item.dmDichVuId === 3) {
                    item.thanhTien = tinhThanhTienNH(item.dieuKienFoc, item.giaDichVu, item.foc, item.soLuong);
                }
            }
            return it;
        });
        setDataTable(data);
    };
    const handleChangeDonGia = (item, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.giaDichVu = value;
                if (item.dmDichVuId === 3) {
                    item.thanhTien = tinhThanhTienNH(item.dieuKienFoc, item.giaDichVu, item.foc, item.soLuong);
                }
            }
            return it;
        });
        setDataTable(data);
    };
    const handleChangeFoc = (item, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.foc = value;
                if (item.dmDichVuId === 3) {
                    item.thanhTien = tinhThanhTienNH(item.dieuKienFoc, item.giaDichVu, item.foc, item.soLuong);
                }
            }
            return it;
        });
        setDataTable(data);
    };
    const handleChangeSoLuong = (item, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.soLuong = value;
                if (item.dmDichVuId === 3) {
                    item.thanhTien = tinhThanhTienNH(item.dieuKienFoc, item.giaDichVu, item.foc, item.soLuong);
                }
            }
            return it;
        });
        setDataTable(data);
    };
    const handleChangeThanhTien = (params) => {};
    const handleChangeVat = (item, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.dmVatId = value;
            }
            return it;
        });
        setDataTable(data);
    };
    const handleSave = (item) => {
        let saveFunction;

        switch (item.dmDichVuId) {
            case 1:
                saveFunction = dich_vu_le_service.saveDichVuLeKhachSan;
                break;
            case 2:
                saveFunction = dich_vu_le_service.saveDichVuLeOto;
                break;
            case 3:
                saveFunction = dich_vu_le_service.saveDichVuLeNhaHang;
                break;
            default:
                return; // Invalid dmDichVuId
        }

        const filteredData = dataTable.filter(
            (it) => it.tenDichVu === item.tenDichVu && it.dmDichVuId === item.dmDichVuId
        );

        const serviceData = {};

        switch (item.dmDichVuId) {
            case 1:
                serviceData.dichVuKhachSans = [
                    {
                        nccHangPhongId: item.nccHangPhongId,
                        tenDichVu: item.tenDichVu,
                        nccGiaPhongId: item.nccGiaPhongId,
                        tranxGiaPhongId: item.tranxGiaPhongId,
                        giaDichVuKhachSans: filteredData.map((it) => ({
                            dmKieuPhongId: it.dmKieuPhongId,
                            maPhong: item.maPhong,
                            dmTyGiaId: it.dmTyGiaId,
                            giaKhachSanNhapTay: it.giaDichVu,
                            soLuong: it.soLuong,
                        })),
                    },
                ];
                break;
            case 2:
                serviceData.dichVuOtos = filteredData.map((it) => ({
                    nccDichVuOtoId: it.nccDichVuOtoId,
                    tranxOtoId: it.tranxOtoId,
                    tenDichVu: it.tenDichVu,
                    soXe: it.soXe,
                    giaOtoNhapTay: it.giaDichVu,
                    soLuong: it.soLuong,
                }));
                break;
            case 3:
                serviceData.dichVuNhaHangs = filteredData.map((it) => ({
                    tranxNhaHangId: 0,
                    nccDichVuNhaHangId: 0,
                    tenDichVu: it.tenDichVu,
                    dmBuaAnId: it.dmBuaAnId,
                    dieuKienFoc: it.dieuKienFoc,
                    foc: it.foc,
                    giaNhaHangNhapTay: it.giaDichVu,
                    soLuong: it.soLuong,
                }));
                break;
            default:
                break;
        }
        const data = {
            bkDichVuLeId: item.bkDichVuLeId,
            bkThongTinChungId: item.bkThongTinChungId,
            dmDichVuId: item.dmDichVuId,
            nccThongTinChungId: item.nccThongTinChungId ? item.nccThongTinChungId : 0,
            tenNcc: item.tenNcc,
            ngayBatDau: item.ngayBatDau,
            ngayKetThuc: item.ngayKetThuc,
            dmGiaApDungId: item.dmGiaApDungId,
            dmLoaiApDungId: item.dmLoaiApDungId,
            soNgaySuDung: item.soNgaySuDung,
            dmVatId: item.dmVatId,
            dieuKienFoc: item.dieuKienFoc,
            foc: item.foc,
            moTa: item.moTa,
            ...serviceData,
        };
        console.log(JSON.stringify(data));
        saveFunction(data).then((response) => {
            if (response.status === 200 && response.data.code === "00") {
                handleReload();
                api["success"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.success").toLowerCase(),
                    duration: 0,
                });
            } else {
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: 0,
                });
                setEditingId(null);
            }
        });
    };
    const handleDelete = (item) => {
        if (item.bkDichVuLeId === 0) {
            let data = dataTable.filter((it) => it.id === item.id);
            setEditingId(null);
            setDataTable(data);
        } else {
            setEditingId(null);
            let filteredData = [];

            const serviceData = {
                bkDichVuLeId: item.bkDichVuLeId,
                bkThongTinChungId: bkThongTinChungId,
                dichVuNhaHangs: [],
                dichVuOtos: [],
                dichVuKhachSans: [],
            };

            switch (item.dmDichVuId) {
                case 1:
                    filteredData = dataTable.filter(
                        (it) =>
                            it.tenDichVu === item.tenDichVu &&
                            it.dmDichVuId === item.dmDichVuId &&
                            it.bkTranxKhachSanId === item.bkTranxKhachSanId
                    );
                    serviceData.dichVuKhachSans = [
                        {
                            bkTranxKhachSanId: item.bkTranxKhachSanId,
                            giaDichVuKhachSans: filteredData.map((it) => ({
                                bkTranxKsGiaDichVuId: it.bkTranxKsGiaDichVuId,
                            })),
                        },
                    ];
                    break;
                case 2:
                    serviceData.dichVuOtos = [
                        {
                            bkTranxOtoId: item.bkTranxOtoId,
                        },
                    ];
                    break;
                case 3:
                    serviceData.dichVuNhaHangs = [{ bkTranxNhaHangId: item.bkTranxNhaHangId }];
                    break;
                default:
                    break;
            }
            console.log(JSON.stringify(serviceData));
            dich_vu_le_service.deleteDichVuLeById(serviceData).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        }
    };
    const handleReload = () => {
        setReload(!reload);
    };
    const handleChangeLoaiDV = (item, key, value) => {
        setEditingId(item.id);
        let data = dataTable.map((it) => {
            if (it.id === item.id) {
                it.dmDichVuId = value;
            }
            return it;
        });
        setDataTable(data);
    };
    const tinhThanhTienNH = (numberOfRates, unitPrice, FOCConditions, countOfService) => {
        // Calculate FOC quantity
        const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

        // Calculate total service cost
        const totalServiceCost = countOfService * unitPrice;

        // Calculate service cost after applying FOC policy
        return totalServiceCost - FOCQuantity * unitPrice;
    };
    const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
        //FOCConditions dk foc
        //numberOfRoomsBooked: số lượng
        //unitPrices đơn giá
        // Define variables
        const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce((acc, val) => acc + val, 0);
        // Calculate B5
        const lowestRoomCategoryPrice = Math.min(...unitPrices);
        const averageReducedPricePerRoom =
            (lowestRoomCategoryPrice * Math.floor(tongSoLuongPhongNCC / FOCConditions)) / tongSoLuongPhongNCC;

        // Calculate B6
        return numberOfRoomsBooked.map(
            (count, index) => count * unitPrices[index] - averageReducedPricePerRoom * count
        );
    };
    function formatDescription(serviceName, roomTypeCode, doubleRoomQuantity, extraQuantity, singleRoomQuantity) {
        let description = serviceName;

        if (doubleRoomQuantity && doubleRoomQuantity !== 0) {
            description += " (DBL)";
        }
        if (extraQuantity && extraQuantity !== 0) {
            description += " + (EX)";
        }
        if (singleRoomQuantity && singleRoomQuantity !== 0) {
            description += " + (SGL)";
        }
        return description;
    }
    const handleClear = (params) => {};

    const table_nl = () => {
        return (
            <>
                <table>
                    <thead>
                        {columns.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dataTable?.map((item, key) => {
                            return (
                                <tr key={key}>
                                    {item.rowSpanDV && item.rowSpanDV !== 0 ? (
                                        <>
                                            <td>{key + 1}</td>
                                            <td rowSpan={item.rowSpanDV}>
                                                <TextField
                                                    select
                                                    value={item.dmDichVuId}
                                                    onChange={(e) => handleChangeLoaiDV(item, key, e.target.value)}
                                                >
                                                    {dataDv.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {item.id.indexOf("NULL") !== -1 ? (
                                        <>
                                            <>
                                                <td colSpan="100%">
                                                    <Link
                                                        onClick={() => handleAddRowServices(item)}
                                                        disabled={editingId === null ? false : editingId !== item.id}
                                                    >
                                                        {t("add") + " " + t("service").toLowerCase()}
                                                    </Link>
                                                </td>
                                            </>
                                        </>
                                    ) : (
                                        <>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        <TextField
                                                            disabled={
                                                                editingId === null ? false : editingId !== item.id
                                                            }
                                                            size="small"
                                                            value={item.tenNcc}
                                                            onChange={(e) => handleChangeTenNCC(item, e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton size="small">
                                                                            <EditFilled
                                                                                onClick={() =>
                                                                                    handleAddServices(
                                                                                        item
                                                                                        // baoGiaHanhTrinhId,
                                                                                        // ngay
                                                                                    )
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            size="small"
                                                                            disabled={!item.isClearNcc}
                                                                        >
                                                                            <CloseCircleFilled />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {item.dmDichVuId === 1 && !item.isClearNcc ? (
                                                            <IconButton onClick={() => handleAddBySerivce(key, item)}>
                                                                <PlusSquareFilled />
                                                            </IconButton>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <TextField
                                                    disabled={editingId === null ? false : editingId !== item.id}
                                                    size="small"
                                                    value={item.tenDichVu}
                                                    onChange={(e) => handleChangeDichVu(item, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                {item.dmDichVuId === 1 ? (
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== item.id}
                                                        size="small"
                                                        select
                                                        value={item.dmKieuPhongId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(
                                                                item,
                                                                e.target.value,
                                                                item.dmDichVuId
                                                            )
                                                        }
                                                    >
                                                        {kieuPhong.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : item.dmDichVuId === 3 ? (
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== item.id}
                                                        size="small"
                                                        select
                                                        value={item.dmBuaAnId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(
                                                                item,
                                                                e.target.value,
                                                                item.dmDichVuId
                                                            )
                                                        }
                                                    >
                                                        {dmBuaAn.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : (
                                                    <>
                                                        <TextField
                                                            disabled={
                                                                editingId === null ? false : editingId !== item.id
                                                            }
                                                            size="small"
                                                            value={item.soXe}
                                                            onChange={(e) =>
                                                                handleChangeDmDichVuId(
                                                                    item,
                                                                    e.target.value,
                                                                    item.dmDichVuId
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        {item.dmDichVuId === 2 ? (
                                                            <>
                                                                <TextField size="small" disabled />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TextField
                                                                    disabled={
                                                                        editingId === null
                                                                            ? false
                                                                            : editingId !== item.id
                                                                    }
                                                                    size="small"
                                                                    onChange={(e) =>
                                                                        handleChangeDieuKienFoc(
                                                                            item,
                                                                            e.target.value,
                                                                            item.tenDichVu
                                                                        )
                                                                    }
                                                                    value={item.dieuKienFoc}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {item.dmDichVuId === 1
                                                                                    ? t("room")
                                                                                    : t("set")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <TextField
                                                                    disabled={
                                                                        editingId === null
                                                                            ? false
                                                                            : editingId !== item.id
                                                                    }
                                                                    size="small"
                                                                    value={item.foc}
                                                                    onChange={(e) =>
                                                                        handleChangeFoc(
                                                                            item,
                                                                            e.target.value,
                                                                            item.tenDichVu
                                                                        )
                                                                    }
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {t("hotel.roomClass.foc")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== item.id}
                                                        size="small"
                                                        value={item.giaDichVu}
                                                        onChange={(e) => handleChangeDonGia(item, e.target.value)}
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== item.id}
                                                        size="small"
                                                        onChange={(e) => handleChangeSoLuong(item, e.target.value)}
                                                        value={item.soLuong}
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled
                                                        size="small"
                                                        value={item.thanhTien}
                                                        // onChange={(e) => handleChangeThanhTien(item, e.target.value)}
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <TextField
                                                    disabled={editingId === null ? false : editingId !== item.id}
                                                    size="small"
                                                    select
                                                    value={item.dmVatId}
                                                    onChange={(e) => handleChangeVat(item, e.target.value)}
                                                >
                                                    {dmVat.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </td>

                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <IconButton
                                                        disabled={editingId === null ? false : editingId !== item.id}
                                                        onClick={() => handleSave(item)}
                                                    >
                                                        <CheckOutlined />
                                                    </IconButton>
                                                    {editingId !== key ? (
                                                        <IconButton
                                                            onClick={() => handleDelete(item)}
                                                            disabled={
                                                                editingId === null ? false : editingId !== item.id
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton onClick={() => handleClear()}>
                                                            <CloseOutlined />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleChangeChietKhau = (item, value) => {
        let thanhTien = 0;
        let data = dataTableChiPhi.map((it) => {
            if (it.id === item.id) {
                it.chietKhau = value;
                it.thanhTien = tinhThanhTienChiPhi(item.soLuong, it.foc, value, it.donGia);
            }
            if (it.thanhTien) thanhTien += it.thanhTien;
            return it;
        });
        setTongChiPhi(thanhTien);
        setDataTableChiPhi(data);
    };
    const tinhThanhTienChiPhi = (soLuong, foc, chietKhau, donGia) => {
        return ((soLuong - foc) * donGia * (100 - chietKhau)) / 100;
    };

    const table_chiPhi = () => {
        return (
            <>
                <table>
                    <thead>
                        {columnChiPhi.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dataTableChiPhi?.map((item, key) => {
                            return (
                                <tr>
                                    {item.rowSpan && item.rowSpan !== 0 ? (
                                        <>
                                            <td rowSpan={item.rowSpan}>{item.stt}</td>
                                            <td rowSpan={item.rowSpan}>{item.loaiDichVu}</td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <td>
                                        {formatDescription(
                                            item.tenDichVu,
                                            item.slPhongDoi,
                                            item.slPhongExtra,
                                            item.slPhongDon
                                        )}
                                        <br />(
                                        {item.ngayBatDau && item.ngayBatDau !== 0
                                            ? item.ngayBatDau + " - " + item.ngayKetThuc
                                            : ""}
                                        )
                                    </td>
                                    <td>{item.donViStr}</td>
                                    <td>{item.soLuong}</td>
                                    <td>{item.foc}</td>
                                    <td style={{ width: "10%" }}>
                                        <TextField
                                            size="small"
                                            type="number"
                                            value={item.chietKhau}
                                            onChange={(e) => handleChangeChietKhau(item, e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                        />
                                    </td>
                                    <td>{item.donGia}</td>
                                    <td>{item.thanhTien}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleSaveChiPhi = () => {
        let data = [];
        let dataMap = {}; // Use an object to map bkDichVuLeId to their respective index in data

        for (let i = 0; i < dataTableChiPhi.length; i++) {
            const element = dataTableChiPhi[i];
            const {
                bkDichVuLeId,
                dmDichVuId,
                bkTranxNhaHangId,
                bkTranxOtoId,
                bkTranxKhachSanId,
                bkTranxKsGiaDichVuId,
                chietKhau,
            } = element;

            if (!dataMap[bkDichVuLeId]) {
                dataMap[bkDichVuLeId] = data.length;
                let newItem = {
                    bkDichVuLeId,
                    bkThongTinChungId,
                    dichVuNhaHangs: [],
                    dichVuOtos: [],
                    dichVuKhachSans: [],
                };
                data.push(newItem);
            }

            let currentIndex = dataMap[bkDichVuLeId];
            let currentItem = data[currentIndex];

            if (dmDichVuId === 3) {
                currentItem.dichVuNhaHangs.push({ bkTranxNhaHangId, chietKhau });
            } else if (dmDichVuId === 2) {
                currentItem.dichVuOtos.push({ bkTranxOtoId, chietKhau });
            } else {
                let ksIndex = currentItem.dichVuKhachSans.findIndex(
                    (item) => item.bkTranxKhachSanId === bkTranxKhachSanId
                );
                if (ksIndex === -1) {
                    currentItem.dichVuKhachSans.push({
                        bkTranxKhachSanId,
                        giaDichVuKhachSans: [{ bkTranxKsGiaDichVuId, chietKhau }],
                    });
                } else {
                    currentItem.dichVuKhachSans[ksIndex].giaDichVuKhachSans.push({ bkTranxKsGiaDichVuId, chietKhau });
                }
            }
        }

        console.log(JSON.stringify(data));
        dich_vu_le_service.updateDichVuLe(data).then((response) => {
            if (response.status === 200 && response.data.code === "00") {
                // handleReload();
                if (action === "edit") {
                    navigate("/dat-dich-vu/tong-chi-phi/" + action + "?id=" + bookingId);
                } else {
                    navigate("/dat-dich-vu/tong-chi-phi/" + action);
                }
                api["success"]({
                    message: t("stour"),
                    description: t("update") + " " + t("noti.success").toLowerCase(),
                    duration: 0,
                });
            } else {
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: 0,
                });
            }
        });
    };

    return (
        <>
            {contextHolder}
            <Card title={<h3>{t("dichVuLe")}</h3>}>
                <Row>
                    <h4>{t("booking")}</h4>
                    {table_nl()}
                    <Divider />
                    <h4>{t("chiPhiDichVuLe")}</h4>
                    {table_chiPhi()}
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        {t("tongTien")} : {tongChiPhi}
                    </Col>
                </Row>
            </Card>

            <Row>
                <Col xs={24} sm={24} md={24} lg={2} xl={9}></Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={7}>
                    <div
                        style={{
                            textAlign: "center",
                            color: "#fff",
                            height: 60,
                            paddingInline: 50,
                            lineHeight: "60px",
                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                            borderRadius: "4px",
                        }}
                    >
                        <Space>
                            <Buttons
                                variant="contained"
                                type="submit"
                                startIcon={<SaveIcon />}
                                // onClick={(e) => handleSave(e, "luu")}
                            >
                                {t("reset")}
                            </Buttons>
                            <Buttons
                                variant="outlined"
                                // startIcon={<DeleteIcon />}
                                onClick={() => handleSaveChiPhi()}
                                key="continue"
                            >
                                {t("action.continue")}
                            </Buttons>
                        </Space>
                    </div>
                </Col>
            </Row>
            <ModalCapNhatOto
                isOpen={isOpenOto}
                onReset={handleReload}
                bkDichVuLeId={bkDichVuLeId}
                bkThongTinChungId={bkThongTinChungId}
                dmDichVuId={dmDichVuId}
                handleCancel={setIsOpenOto}
                hanhDong={hanhDong}
            />
            <ModalCapNhatKS
                isOpen={isOpenKs}
                onReset={handleReload}
                bkDichVuLeId={bkDichVuLeId}
                bkThongTinChungId={bkThongTinChungId}
                dmDichVuId={dmDichVuId}
                handleCancel={setIsOpenKs}
                hanhDong={hanhDong}
            />
            <ModalCapNhatNhaHang
                isOpen={isOpenNh}
                onReset={handleReload}
                bkDichVuLeId={bkDichVuLeId}
                bkThongTinChungId={bkThongTinChungId}
                dmDichVuId={dmDichVuId}
                handleCancel={setIsOpenNh}
                hanhDong={hanhDong}
            />
        </>
    );
};
export default DichVuLe;
