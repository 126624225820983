import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getTourSp = (data) => {
    return axios.post(API_BASE_URL + "tour/thongtinchung/getData", data, { headers: authHeader() });
};

const saveThongTinChung = (data) => {
    return axios.post(API_BASE_URL + "tour/thongtinchung/saveData", data, { headers: authHeader() });
};

const getDmGiaApDung = (data) => {
    return axios.post(API_BASE_URL + "category/getDmTyGia", data, { headers: authHeader() });
};
const getChuongTrinhTour = (data) => {
    return axios.post(API_BASE_URL + "tour/chuongtrinh/getData", data, { headers: authHeader() });
};
const saveChuongTrinhTour = (data) => {
    return axios.post(API_BASE_URL + "tour/chuongtrinh/saveData", data, { headers: authHeader() });
};
const getByIdChuongTrinhTour = (data) => {
    return axios.post(API_BASE_URL + "tour/chuongtrinh/getDataById", data, { headers: authHeader() });
};

const orderByNgay = (data) => {
    return axios.post(API_BASE_URL + "tour/chuongtrinh/orderByNgay", data, { headers: authHeader() });
};
const getThongTinTour = (data) => {
    return axios.post(API_BASE_URL + "tour/thongtin/getData", data, { headers: authHeader() });
};
const saveThongTinTour = (data) => {
    return axios.post(API_BASE_URL + "tour/thongtin/saveData", data, { headers: authHeader() });
};
const clearChuongtrinhTour = (data) => {
    return axios.post(API_BASE_URL + "tour/chuongtrinh/clearData", data, { headers: authHeader() });
};

export default {
    getTourSp,
    saveThongTinChung,
    getChuongTrinhTour,
    saveChuongTrinhTour,
    getByIdChuongTrinhTour,
    orderByNgay,
    getThongTinTour,
    saveThongTinTour,
    clearChuongtrinhTour,
    getDmGiaApDung,
};
