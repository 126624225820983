import React, { useEffect, useState } from "react";
import { Table, Typography, Switch, notification, Row, Col } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import FormEx from "./FormEx";
import { EditOutlined } from "@ant-design/icons";
import ModalExRateEdit from "./ModalExRateEdit";
import { API_BASE_URL, data_getDM } from "../../../constants";
import moment from "moment";
import authHeader from "../../../api/auth_header";
import { DataGrid, GridToolbarQuickFilter, enUS, viVN } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import i18n from "../../../i18n";

const ExchangeRate = () => {
    const [dataSource, setDataSource] = useState([]);
    const [dataSearch, setDataSearch] = useState();
    const [isShow, setIsShow] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    let formatter = new Intl.NumberFormat("en-US", {});
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i + 1,
                                id: data[i].id,
                                ma_ngoai_te: data[i].ma_ngoai_te,
                                loai_tien: data[i].loai_tien,
                                ty_gia: formatter.format(data[i].ty_gia),
                                date_updated: moment(data[i].date_updated).format("DD-MM-YYYY"),
                                hieu_luc: data[i].hieu_luc,
                            });
                            dataLstSearch.push({
                                value:
                                    data[i].id +
                                    " - " +
                                    data[i].ma_ngoai_te +
                                    " - " +
                                    data[i].loai_tien +
                                    " - " +
                                    data[i].ty_gia,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                    // setDataSource(response.data.data)
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");

    // const handleDelete = (key) => {
    //     const newData = dataSource.filter((item) => item.key !== key);
    //     setDataSource(newData);
    // };
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
    };
    const handleFinish = (isModalOpen, values) => {
        setIsShow(isModalOpen);

        apiEdit(values, "ty_gia");
    };

    const handleCancel = () => {
        setEditingRecord("");
        setIsShow(false);
    };
    const dataAdd = (data, dataS) => {
        api["success"]({
            message: t("stour"),
            description: t("add") + " " + t("noti.success").toLowerCase(),
            duration: 0,
        });
        setDataSource((oldArray) => [...oldArray, data]);
        setDataSearch((oldArray) => [...oldArray, dataS]);
    };
    const handleBorderChange = (record) => {
        let values = {
            id: record.id,
            hieu_luc: record.hieu_luc === true ? false : true,
        };
        apiEdit(values, "hieu_luc");
    };
    const apiEdit = (values, name_key) => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/editDmTyGia",
            data: values,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let updatedList = dataSource.map((item) => {
                    if (item.id === values.id) {
                        if (name_key === "ty_gia") {
                            return { ...item, ty_gia: values.ty_gia };
                        } else {
                            return { ...item, hieu_luc: values.hieu_luc };
                        }
                    }
                    return item; // else return unmodified item
                });
                setDataSource(updatedList);
                api["success"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: 0,
                });
            }
        });
    };
    const columns = [
        {
            headerName: t("exchangeRate.stt"),
            field: "stt",
            key: "stt",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("exchangeRate.currencyCode"),
            field: "ma_ngoai_te",
            flex: 1,
            headerAlign: "center",
            key: "ma_ngoai_te",
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loai_tien",
            key: "loai_tien",
        },
        {
            headerName: t("exchangeRate.rate") + "(VND)",
            field: "ty_gia",
            key: "ty_gia",
            flex: 1,
            headerAlign: "center",
            // sorter: (a, b) => a.ty_gia.length - b.ty_gia.length,
            // sortOrder: sortedInfo.columnKey === 'ty_gia' ? sortedInfo.order : null,
        },
        {
            headerName: t("date"),
            field: "date_updated",
            key: "date_updated",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("status"),
            field: "hieu_luc",
            key: "hieu_luc",
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                return <Switch defaultChecked={value} onChange={() => handleBorderChange(row)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            flex: 1,
            headerAlign: "center",
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                        <EditOutlined
                            style={{
                                fontSize: "20px",
                            }}
                        />
                    </Typography.Link>
                );
            },
            align: "center",
        },
    ];
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const [api, contextHolder] = notification.useNotification();
    const apiThayDoiTrangThai = () => {
        let data = [];
        let obj = null;
        if (selectedRowKeys.length > 0) {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                obj = {
                    id: selectedRowKeys[i],
                    hieu_luc: false,
                };
                data.push(obj);
            }
            axios({
                method: "post",
                url: API_BASE_URL + "category/thayDoiTrangThaiDm",
                data: data,
                headers: authHeader(),
            }).then(function (response) {
                let dataS = null;
                if (response.status === 200 && response.data.code === "00") {
                    for (let i = 0; i < selectedRowKeys.length; i++) {
                        let indx = dataSource.findIndex((v) => v.id === selectedRowKeys[i]);
                        dataS = dataSource.splice(indx, indx >= 0 ? 1 : 0);
                    }
                    setDataSource(dataS);
                    // const indx = dataSource.findIndex(v => v.id === "Michael");
                    // someArray4.splice(indx, indx >= 0 ? 1 : 0);
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            });
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handleSearch = (value) => {
        let valueSp = value.split(" - ");
        let data = dataSource.filter((el) => el.id === parseInt(valueSp[0]));
        setDataSource(data);
    };
    const hasSelected = selectedRowKeys.length > 0;

    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormEx
                            dataAdd={dataAdd}
                            deleteLst={apiThayDoiTrangThai}
                            handleSearch={handleSearch}
                            dataSearch={dataSearch}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                <ModalExRateEdit
                    isOpen={isShow}
                    handleFinish={handleFinish}
                    handleCancel={handleCancel}
                    record={editingRecord}
                />
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? t("selected") + ` ${selectedRowKeys.length} items` : ""}
                </span>
            </div>
            <DataGrid
                rows={dataSource}
                columns={columns}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                autoHeight
                {...dataSource}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
        </div>
    );
};
export default ExchangeRate;
