import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import Search from "@mui/icons-material/Search";
import {
    Button,
    Checkbox,
    DialogContent,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Slider,
    TextField,
} from "@mui/material";
import { GridFilterAltIcon } from "@mui/x-data-grid";
import { Col, Divider, Modal, Row, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import nhaHangService from "../../../../../../../api/tour/nhaHang/nhaHangService";

function valuetext(value) {
    return `${value} đ`;
}
const ModalThongTinNccNhaHang = ({ isOpen, action, onIsShow, onSaves, maNcc, lenNgLon }) => {
    const { t } = useTranslation();
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [quocGiaId, setQuocGiaId] = useState("");
    const [tinhId, setTinhId] = useState("");
    const [supplier, setSupplier] = useState("");
    const [dichVu, setDichVu] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [selectedRows, setSelectedRows] = useState([]);
    const [select, setSelect] = useState({
        selectedRowKeys: [],
        loading: false,
    });
    const { selectedRowKeys, loading } = select;
    const [dataNccNhaHang, setDataNccNhaHang] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState([20000, 7000000]);
    const open = Boolean(anchorEl);
    useEffect(() => {
        getDataQuocGia();
    }, []);

    useEffect(() => {
        getNccNhaHang({
            searchNcc: maNcc,
            searchDichVu: "",
            tuNgay: "04/03/2024",
            denNgay: "25/04/2024",
            quocGiaId: 0,
            tinhId: 0,
        });
    }, [maNcc]);

    const getDataQuocGia = () => {
        nhaHangService
            .getQuocGia()
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDataTinhTP = (idQuocGia) => {
        nhaHangService
            .getTinhTP({ dm_quoc_gia_id: idQuocGia })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataTinhTP = data.map((item) => {
                        if (item.hieu_luc === true) {
                            return {
                                value: item.id,
                                label: item.tinh_thanhpho,
                            };
                        }
                        return null;
                    });
                    setDataTinhTP(dataTinhTP);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getNccNhaHang = (data) => {
        nhaHangService
            .getNccNhaHang(data)
            .then(function (response) {
                if (response.status === 200) {
                    let dataNcc = response.data.data;
                    dataNcc = dataNcc.map((item, index) => {
                        item.key = index;
                        return item;
                    });
                    console.log("dataNccNhaHang: ", dataNcc);
                    setDataNccNhaHang(dataNcc);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleData = (value) => {
        let number = 0;
        if (value !== undefined) {
            number = parseInt(value);
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
            });
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
            setSelectedRows(selectedRows);
        },
    };
    const expandedRowRender = (record) => {
        const columns = [
            {
                title: t("res.code"),
                dataIndex: "maDichVu",
                key: "maDichVu",
                width: "10%",
                align: "center",
            },
            {
                title: t("res.serviceProvided"),
                dataIndex: "tenDichVu",
                key: "tenDichVu",
                width: "15%",
                align: "center",
            },
            {
                title: t("hotel.priceRoom.applicationTime"),
                dataIndex: "thoiGianAD",
                key: "thoiGianAD",
                width: "25%",
                align: "center",
            },
            {
                title: t("hotel.priceClass.giaNet") + ` (${t("priceWeekday")})`,
                key: "fitNgayThuongNet",
                dataIndex: "fitNgayThuongNet",
                width: "10%",
                align: "center",
                render: (_, record) => {
                    return handleData(record.fitNgayThuongNet);
                },
            },
            {
                title: t("hotel.priceClass.giaNet") + ` (${t("priceWeekend")})`,
                key: "fitCuoiTuanNet",
                dataIndex: "fitCuoiTuanNet",
                width: "10%",
                align: "center",
                render: (_, record) => {
                    return handleData(record.fitCuoiTuanNet);
                },
            },
            {
                title: t("hotel.roomClass.conditionFoc"),
                dataIndex: "dieuKienFoc",
                key: "dieuKienFoc",
                width: "10%",
                align: "center",
            },
            {
                title: t("exchangeRate.currency"),
                dataIndex: "maNgoaiTe",
                key: "maNgoaiTe",
                width: "10%",
                align: "center",
            },
            {
                title: t("tax") + " " + t("vat"),
                dataIndex: "vat",
                key: "vat",
                width: "10%",
                align: "center",
            },
        ];
        let dataDichVus = record?.dichVus?.map((item) => {
            return {
                ...item,
                key: item.tranxNhaHangId,
                thoiGianAD: `${item.tuNgay} - ${item.denNgay}`,
                vat: record.vat,
                dmVatId: record.dmVatId,
                tenNcc: record.tenNcc,
                maNcc: record.maNcc,
                isVat: record.isVat,
                nccThongTinChungId: record.nccThongTinChungId,
            };
        });
        return (
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataDichVus}
                // pagination={false}
                loading={loading}
            />
        );
    };
    const columns = [
        {
            title: t("maNcc"),
            dataIndex: "maNcc",
            key: "maNcc",
            width: "10%",
            align: "center",
        },
        {
            title: t("supplier"),
            dataIndex: "tenNcc",
            key: "tenNcc",
            width: "20%",
            align: "center",
        },
        {
            title: t("address"),
            dataIndex: "diaChi",
            key: "diaChi",
            width: "30%",
            align: "center",
        },
        {
            title: t("phoneNumber"),
            dataIndex: "sdt",
            key: "sdt",
            width: "20%",
            align: "center",
        },
        {
            title: t("email"),
            dataIndex: "email",
            key: "email",
            width: "20%",
            align: "center",
        },
    ];

    const handleCancels = () => {
        onIsShow(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeQG = (event) => {
        setQuocGiaId(event.target.value);
        getDataTinhTP(event.target.value);
    };

    const handleCancelChooseService = () => {
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        handleCancels();
    };

    const handleResetChooseService = () => {
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        setSupplier("");
        setDichVu("");
        setQuocGiaId("");
        setTinhId("");
    };

    const handleSearchChooseService = () => {
        let dataSearch = {
            searchNcc: maNcc ? maNcc : supplier,
            searchDichVu: dichVu ? dichVu : "",
            tuNgay: "04/03/2024",
            denNgay: "25/04/2024",
            quocGiaId: quocGiaId ? quocGiaId : 0,
            tinhId: tinhId ? tinhId : 0,
        };
        console.log(dataSearch);
        getNccNhaHang(dataSearch);
    };

    const handleSaveChooseService = () => {
        if (action === "UPDATE_NCC" || selectedRows.length === 1) {
            if (lenNgLon > 0 && selectedRows.length > lenNgLon) {
                showNoti("error", t("noti.errorMaxServices"));
            } else {
                onSaves(selectedRows);
                setSelect({ selectedRowKeys: [], loading: false });
                setSelectedRows([]);
                handleCancels();
            }
            // if (action === "ADD_SERVICE")
            //   showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
            // else
            //   showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("noti.selectServiceError"));
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={t("tour.nhaHang.chonDichVu")}
                open={isOpen}
                onOk={handleSaveChooseService}
                onCancel={handleCancels}
                footer={null}
                width={1300}
                height={700}
            >
                <Divider style={{ backgroundColor: "black" }} />
                <Row>
                    <Col span={18}>
                        <Row style={{ marginBottom: "20px", justifyContent: "space-between" }}>
                            <Col span={5}>
                                <OutlinedInput
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    }
                                    size="small"
                                    placeholder="Mã/Tên nhà cung cấp"
                                    value={supplier}
                                    onChange={(e) => setSupplier(e.target.value)}
                                />
                            </Col>
                            <Col span={5}>
                                <OutlinedInput
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    }
                                    size="small"
                                    placeholder="Mã dịch vụ cung cấp"
                                    value={dichVu}
                                    onChange={(e) => setDichVu(e.target.value)}
                                />
                            </Col>
                            <Col span={7}>
                                <div style={{ display: "flex" }}>
                                    <TextField
                                        label={t("location.country")}
                                        select
                                        fullWidth
                                        size="small"
                                        name="quocGiaId"
                                        onChange={(event) => handleChangeQG(event)}
                                        value={quocGiaId}
                                    >
                                        {dataQuocGia?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        label={t("location.city")}
                                        select
                                        fullWidth
                                        size="small"
                                        name="tinhId"
                                        onChange={(event) => setTinhId(event.target.value)}
                                        value={tinhId}
                                    >
                                        {dataTinhTP?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Col>
                            <Col span={3}>
                                <Button
                                    style={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                        textTransform: "none",
                                        width: "100%",
                                    }}
                                    key="filter"
                                    startIcon={<GridFilterAltIcon />}
                                    onClick={handleClick}
                                    aria-controls={"account-menu"}
                                    aria-haspopup="true"
                                    aria-expanded={"true"}
                                >
                                    Bộ lọc
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    // onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: "visible",
                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                            mt: 1.5,
                                            "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            "&::before": {
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform: "translateY(-50%) rotate(45deg)",
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                >
                                    <DialogContent>
                                        <Row
                                            style={{
                                                display: "flex",
                                                width: "400px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Col span={5}>
                                                <b>Loại tiền</b>
                                            </Col>
                                            <Col span={9}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    // name="thoiGianApDung"
                                                    // onChange={(e) => setThoiGianApDung(e.target.value)}
                                                    // value={thoiGianApDung}
                                                >
                                                    <MenuItem value={0}>VND</MenuItem>
                                                    <MenuItem value={1}>USD</MenuItem>
                                                </TextField>
                                            </Col>
                                        </Row>
                                        <Divider></Divider>
                                        <Row>
                                            <Col span={5}>
                                                <b>Mức giá</b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <FormGroup
                                                sx={{
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    width: "400px",
                                                }}
                                            >
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Label"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Required"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Disabled"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Label"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Required"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Disabled"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Label"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Required"
                                                />
                                                <FormControlLabel
                                                    sx={{ width: "120px" }}
                                                    control={<Checkbox />}
                                                    label="Disabled"
                                                />
                                            </FormGroup>
                                        </Row>
                                        <Divider />
                                        <Row>
                                            <Col span={5}>
                                                <b>Khoảng giá</b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} />
                                            <Col span={16}>
                                                <Slider
                                                    getAriaLabel={() => "Minimum distance"}
                                                    value={value}
                                                    onChange={handleChange}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={valuetext}
                                                    min={20000}
                                                    max={7000000}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} />
                                            <Col span={7}>
                                                <TextField
                                                    label={t("tour.oto.minValue")}
                                                    value={`${value[0]} đ`}
                                                    size="small"
                                                    disabled
                                                ></TextField>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={7}>
                                                <TextField
                                                    label={t("tour.oto.maxValue")}
                                                    value={`${value[1]} đ`}
                                                    size="small"
                                                    disabled
                                                ></TextField>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Row style={{ justifyContent: "flex-end" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textTransform: "none",
                                                    marginRight: "5px",
                                                    backgroundColor: "#898989",
                                                }}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button variant="contained" sx={{ textTransform: "none" }}>
                                                {t("action.apply")}
                                            </Button>
                                        </Row>
                                    </DialogContent>
                                </Menu>
                            </Col>
                            <Col span={3}>
                                <Button
                                    variant="contained"
                                    sx={{ textTransform: "none" }}
                                    onClick={handleSearchChooseService}
                                >
                                    {t("search")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender,
                            // defaultExpandedRowKeys: ["0"],
                        }}
                        dataSource={dataNccNhaHang}
                        style={{ width: "100%" }}
                    />
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={handleSaveChooseService}
                            startIcon={<SaveIcon />}
                        >
                            {t("action.ok")}
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                // textTransform: "none",
                                marginRight: "5px",
                                backgroundColor: "#898989",
                            }}
                            startIcon={<RestartAltIcon />}
                            onClick={handleResetChooseService}
                        >
                            {t("action.reset")}
                        </Button>
                        <Button
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            // sx={{ textTransform: "none" }}
                            onClick={handleCancelChooseService}
                        >
                            {t("action.cancel")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ModalThongTinNccNhaHang;
