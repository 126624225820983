import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTSaleRateOtoById = (data) => {
  return axios.post(API_BASE_URL + "booking/layTTSaleRateOtoById", data, {
    headers: authHeader(),
  });
};
const saveSaleRateOto = (data) => {
  return axios.post(API_BASE_URL + "booking/saveSaleRateOto", data, {
    headers: authHeader(),
  });
};
export default {
  layTTSaleRateOtoById,
  saveSaleRateOto,
};
