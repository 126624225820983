import { Box } from "@mui/material";
import { Card, Col, Row } from "antd";
import ViewService from "./ViewService";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import dichVuTourService from "../../../../api/booking/dichVuTour/dich_vu_tour.service";

const SaleRate = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState({});
  const [dataNhomNgLon, setDataNhomNgLon] = useState([]);
  const [dataNhomTreEm, setDataNhomTreEm] = useState([]);
  useEffect(() => {
    getDataNhomNgLonVaTreEm(1);
  }, []);
  const getDataNhomNgLonVaTreEm = (bkDichVuTourId) => {
    let dataReq = { bkDichVuTourId };
    dichVuTourService
      .getDataNhomNgLonVaTreEm(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          setDataNhomNgLon(res.data.data.nhomNguoiLons);
          setDataNhomTreEm(res.data.data.nhomTreEms);
        }
      })
      .catch((err) => console.log("GetDataNhomNgLonVaTreEm Error ..." + err));
  };
  return (
    <Box>
      <Card title={<h3>Sale Rate</h3>}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ViewService
              dichVuNguoiLons={dataSource.dichVuNguoiLons}
              dichVuTreEms={dataSource.dichVuTreEms}
              bgHanhTrinhs={dataSource.hanhTrinhs}
              soLuongTreEm={dataSource.soLuongTreEm}
              soLuongNgLon={dataSource.soLuongNguoiLon}
              soNgay={dataSource.soNgay}
              dataNhomNgLon={dataNhomNgLon}
              dataNhomTreEm={dataNhomTreEm}
              // onReload={handleReloadData}
            />
          </Col>
        </Row>
      </Card>
    </Box>
  );
};

export default SaleRate;
