import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { Col, Divider, Modal, Row, Space, Switch, notification } from "antd";
import axios from "axios";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import { API_BASE_URL } from "../../../../constants";
import TableDetailThoiGianAD from "./TableDetailThoiGianAD";
import TableTotalThoiGianAD from "./TableTotalThoiGianAD";
const ModalCauHinhTyLe = ({ open, handleTyLe, baoGiaTongQuanId, onReset }) => {
  const { t } = useTranslation();
  const [thoiGianApDung, setThoiGianApDung] = useState(null);
  const [tenMau, setTenMau] = useState(null);
  const [dataThoiGianApDung, setdataThoiGianApDung] = useState([]);
  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [dataDichVu, setDataDichVu] = useState([]);
  const [reload, setReload] = useState();
  useEffect(() => {
    axios
      .post(API_BASE_URL + "category/getDmDichVu", null, {
        headers: authHeader(),
      })
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response getDmDichVu: ====", response.data.data);
          const filteredData = response.data.data.filter(
            (item) => item.hieu_luc === true
          );
          const dataDV = filteredData.map((item) => ({
            id: item.id,
            value: item.ten_dich_vu,
          }));
          setDataDichVu(dataDV);
        }
      })
      .catch(function (error) {
        console.error("Error response: ====", error);
      });
  }, []);
  useEffect(() => {
    loadData();
    console.log("sale" + baoGiaTongQuanId);
  }, [baoGiaTongQuanId]);
  useEffect(() => {
    if (reload) {
      loadData();
      setReload(false);
    }
  }, [reload]);
  const loadData = async () => {
    try {
      console.log("Get data");

      // Lấy dữ liệu tổng quan
      const totalDataResponse = await baoGiaService.getCauHinhLoiNhuan({
        baoGiaTongQuanId: 1,
        loai: 0,
        loaiChietTinh: 1,
      });
      if (totalDataResponse.status === 200) {
        const totalData = totalDataResponse.data.data;
        const dataTT = [];
        if (totalData) {
          const totalValues = totalData.loaiTien;
          console.log("getCauHinhLoiNhuan121", totalData);
          for (let i = 0; i < totalValues.length; i++) {
            for (let j = 0; j < totalValues[i].details.length; j++) {
              const element = totalValues[i].details[j];
              const markupType =
                element.kieuTinh === 1
                  ? t("tour.fixedAmount")
                  : t("tour.percentage");
              dataTT.push({
                id: Math.random() * (100 - 1),
                id_: totalValues[i].bgCauHinhLaiId,
                markupType,
                giaTri: element.giaTri,
                loaiTien: totalValues[i].dmLoaiTienId,
                baoGiaTongQuanId,
                apDung: element.apDung,
                kieuTinh: element.kieuTinh,
                dmDichVuId: element.dmDichVuId,
                tenDichVu: element.tenDichVu,
              });
            }
            dataTT.push({ id: "NULL " + Math.random() * (100 - 1) });
          }
        }
        dataTT.push({ id: "SUBTOTAL" });

        // Lấy dữ liệu chi tiết
        const detailDataResponse = await baoGiaService.getCauHinhLoiNhuan({
          baoGiaTongQuanId,
          loai: 1,
          loaiChietTinh: 1,
        });
        if (detailDataResponse.status === 200) {
          const detailData = detailDataResponse.data.data;
          const dataDT = [];
          if (detailData) {
            const detailValues = detailData.loaiTien;
            console.log("getCauHinhLoiNhuan1 detail", detailData);
            for (let i = 0; i < detailValues.length; i++) {
              for (let j = 0; j < detailValues[i].details.length; j++) {
                const element = detailValues[i].details[j];
                const markupType =
                  element.kieuTinh === 1
                    ? t("tour.fixedAmount")
                    : t("tour.percentage");
                if (j % 3 === 0) {
                  const det = [];
                  for (let index = j; index < j + 3; index++) {
                    det.push({
                      id_: detailValues[i].bgCauHinhLaiId,
                      id: Math.random() * (100 - 1),
                      markupType,
                      giaTri: detailValues[i].details[index].giaTri,
                      loaiTien: detailValues[i].dmLoaiTienId,
                      baoGiaTongQuanId,
                      apDung: detailValues[i].details[index].apDung,
                      dmDichVuId: detailValues[i].details[index].dmDichVuId,
                      tenDichVu: detailValues[i].details[index].tenDichVu,
                    });
                  }
                  dataDT.push({
                    id: Math.random() * (100 - 1),
                    id_: detailValues[i].bgCauHinhLaiId,
                    markupType,
                    giaTri: element.giaTri,
                    loaiTien: detailValues[i].dmLoaiTienId,
                    baoGiaTongQuanId,
                    apDung: element.apDung,
                    kieuTinh: element.kieuTinh,
                    dmDichVuId: element.dmDichVuId,
                    tenDichVu: element.tenDichVu,
                    details: det,
                  });
                }
              }
              dataDT.push({ id: "NULL " + Math.random() * (100 - 1) });
            }
          }
          dataDT.push({ id: "SUBTOTAL" });

          // Cập nhật state
          setItem({
            ...item,
            isShow: false,
            rowsDT: [...dataDT],
            rowsTT: [...dataTT],
            variantTT: "contained",
            variantDT: "outlined",
            isTableTotal: true,
          });
        }
      }
    } catch (error) {
      console.log("Error response: ====" + error);
      if (error.status === 401) {
        // Handle unauthorized access
      }
    }
  };

  const onFinish = async (e) => {
    e.preventDefault();
    console.log(JSON.stringify(item));

    const filterRows = (rows, substringsToAvoid) => {
      return rows.filter((row) => {
        return substringsToAvoid.every(
          (substring) => !row.id.toString().includes(substring)
        );
      });
    };

    const validateAndPushLoaiTien = (
      sourceRows,
      targetArray,
      errorFlag,
      criteria,
      transform
    ) => {
      let error = false;
      for (let i = 0; i < sourceRows.length; i++) {
        for (let j = i + 1; j < sourceRows.length; j++) {
          if (criteria(sourceRows[i], sourceRows[j])) {
            error = true;
            break;
          }
        }
        if (!error) {
          if (i % 2 === 1) {
            let details = [];
            for (let k = 0; k < sourceRows[i].details.length; k++) {
              const dt = sourceRows[i].details[k];
              details.push(transform(dt, sourceRows[i]));
            }
            for (let k = 0; k < sourceRows[i - 1].details.length; k++) {
              const dt = sourceRows[i - 1].details[k];
              details.push(transform(dt, sourceRows[i - 1]));
            }
            targetArray.push({
              dmLoaiTienId: sourceRows[i].loaiTien,
              bgCauHinhLaiId:
                sourceRows[i].id_.toString().indexOf("NEW") !== -1
                  ? 0
                  : sourceRows[i].id_,
              details: details,
            });
          }
        } else break;
      }
      if (error) {
        api["error"]({
          message: t("stour"),
          description: t("messageErrorLoaiTien"),
          duration: 0,
        });
      }
      return !errorFlag || !error;
    };

    let rowsDT = filterRows(item.rowsDT, ["NULL", "SUBTOTAL"]);
    let loaiTien1 = [];
    let errorDT = validateAndPushLoaiTien(
      rowsDT,
      loaiTien1,
      true,
      (row1, row2) =>
        row1.loaiTien === row2.loaiTien && row1.markupType === row2.markupType,
      (dt, row) => ({
        dmDichVuId: dt.dmDichVuId,
        kieuTinh: row.kieuTinh,
        giaTri: dt.giaTri,
        apDung: row.apDung,
      })
    );

    if (!errorDT) return;

    let rowsTT = filterRows(item.rowsTT, ["NULL", "TITLE", "SUBTOTAL"]);
    let loaiTien0 = [];
    let errorTT = false;
    for (let i = 0; i < rowsTT.length; i++) {
      let details = [];
      for (let j = i + 1; j < rowsTT.length; j++) {
        if (
          rowsTT[i].loaiTien === rowsTT[j].loaiTien &&
          rowsTT[i].id_ !== rowsTT[j].id_
        ) {
          errorTT = true;
          break;
        }
      }
      if (errorTT) break;
      if (i % 2 === 0) {
        details.push({
          dmDichVuId: rowsTT[i].dmDichVuId,
          kieuTinh: rowsTT[i].kieuTinh,
          giaTri: rowsTT[i].giaTri,
          apDung: rowsTT[i].apDung,
        });
        details.push({
          dmDichVuId: rowsTT[i + 1].dmDichVuId,
          kieuTinh: rowsTT[i + 1].kieuTinh,
          giaTri: rowsTT[i + 1].giaTri,
          apDung: rowsTT[i + 1].apDung,
        });
        loaiTien0.push({
          dmLoaiTienId: rowsTT[i].loaiTien,
          bgCauHinhLaiId:
            rowsTT[i].id.toString().indexOf("NEW") !== -1 ? 0 : rowsTT[i].id_,
          details: details,
        });
      }
    }

    if (errorTT) {
      api["error"]({
        message: t("stour"),
        description: t("messageErrorLoaiTien"),
        duration: 0,
      });
      return;
    }

    try {
      let data = {
        baoGiaTongQuanId: parseInt(baoGiaTongQuanId),
        loai: 0,
        loaiChietTinh: 0,
        loaiTien: loaiTien0,
      };
      let response1 = await baoGiaService.saveCauHinhLoiNhuan(data);
      if (response1.status === 200) {
        api["success"]({
          message: t("stour"),
          description: response1.data.message,
          duration: 0,
        });
      }

      let data1 = {
        baoGiaTongQuanId: parseInt(baoGiaTongQuanId),
        loai: 1,
        loaiChietTinh: 0,
        loaiTien: loaiTien1,
      };
      let response2 = await baoGiaService.saveCauHinhLoiNhuan(data1);
      if (response2.status === 200) {
        setReload(true);
        onReset();
        api["success"]({
          message: t("stour"),
          description: response2.data.message,
          duration: 0,
        });
      } else {
        api["error"]({
          message: t("stour"),
          description: response2.data.message,
          duration: 0,
        });
      }
    } catch (error) {
      console.log("Error response: ====" + error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access
      }
    }
  };
  const handleCancels = (e) => {
    e.preventDefault();
    handleTyLe(false);
  };
  const handleClear = () => {};
  const handleRemoveField = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleAddField = () => {
    setItems([
      ...items,
      {
        isShow: false,
        id: 0,
        tuNgay: null,
        denNgay: null,
        values: [],
        tourChietTinhId: null,
        rowsTT: [{ id: "SUBTOTAL" }],
        rowsDT: [{ id: "SUBTOTAL" }],
        variantTT: "contained",
        variantDT: "outlined",
        isTableTotal: true,
      },
    ]);
  };
  const handleItemChange = (name, value) => {
    // console.log("name: " + name + " value: " + value);
    const newItems = { ...item };
    if (name === "variantDT") {
      newItems[name] = value;
      newItems["isTableTotal"] = false;
      newItems["variantTT"] = "outlined";
    }
    if (name === "variantTT") {
      newItems[name] = value;
      newItems["isTableTotal"] = true;
      newItems["variantDT"] = "outlined";
    } else {
      newItems[name] = value;
    }
    setItem(newItems);
  };
  const onChangeTrangThai = () => {};
  const handleSave = (item) => {
    setItems(
      items.map((it) => {
        if (it.id === item.id) return item;
        return it;
      })
    );
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={() => handleTyLe(false)}
        footer={null}
        title={t("Configureprofitrate")}
        width={1000}
        height={700}
      >
        {contextHolder}
        <Divider />
        <Box component="form" noValidate>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={4}>
              <Button
                variant={item.variantTT}
                onClick={(e) => handleItemChange("variantTT", "contained")}
              >
                {t("tour.total")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={4}>
              <Button
                variant={item.variantDT}
                onClick={(e) => handleItemChange("variantDT", "contained")}
              >
                {t("tour.chiTiet")}
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
              <Button>{t("tour.danhSachMau")}</Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}></Row>
          {item.isTableTotal ? (
            <TableTotalThoiGianAD
              baoGiaTongQuanId={baoGiaTongQuanId}
              rowsTT={item.rowsTT}
              handleSave={handleSave}
              item={item}
              setItem={setItem}
            />
          ) : (
            <TableDetailThoiGianAD
              baoGiaTongQuanId={baoGiaTongQuanId}
              dataDichVu={dataDichVu}
              dataSource={item.rowsDT}
              item={item}
              setItem={setItem}
            />
          )}
          <Divider />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <FormControlLabel
                label={t("tour.luuMau")}
                control={
                  <Switch
                    style={{ marginLeft: "10px" }}
                    defaultChecked
                    onChange={onChangeTrangThai}
                  />
                }
                labelPlacement="start"
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                size="large"
                label={t("tour.tenMau")}
                multiline
                rows={1}
                value={tenMau}
                onChange={(e) => setTenMau(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 6 }}>
            <Col xs={5} sm={5} md={5} lg={5} xl={8}></Col>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <Space>
                <Button
                  variant="contained"
                  onClick={onFinish}
                  startIcon={<SaveIcon />}
                >
                  {t("action.ok")}
                </Button>
                <Button
                  style={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleCancels}
                  key="clear"
                >
                  {t("action.cancel")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Box>
      </Modal>
    </>
  );
};
export default ModalCauHinhTyLe;
