import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const saveBkPhieuThu = (data) => {
    return axios.post(API_BASE_URL + "booking/saveBkPhieuThu", data, {
        headers: authHeader(),
    });
};
const deleteBkPhieuThuById = (data) => {
    return axios.post(API_BASE_URL + "booking/deleteBkPhieuThuById", data, {
        headers: authHeader(),
    });
};
const layTTThucThuByBkThongTinChungId = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTThucThuByBkThongTinChungId", data, {
        headers: authHeader(),
    });
};

const layTTBkPhieuThuById = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTBkPhieuThuById", data, {
        headers: authHeader(),
    });
};

const getDmLoaiBienNhan = (data) => {
    return axios.post(API_BASE_URL + "category/getDmLoaiBienNhan", data, {
        headers: authHeader(),
    });
};

export default {
    saveBkPhieuThu,
    deleteBkPhieuThuById,
    layTTThucThuByBkThongTinChungId,
    layTTBkPhieuThuById,
    getDmLoaiBienNhan,
};
