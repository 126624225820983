import { DeleteOutlined, EditFilled, PlusOutlined } from "@ant-design/icons";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Affix, Col, Popconfirm, Row, Space, notification } from "antd";
import Button from "@mui/material/Button";
import Search from "@mui/icons-material/Search";
import danhSachDoanService from "../../../../api/booking/DanhSachDoanService";
import moment from "moment";
import dm_booking from "../../../../api/category/dm_booking";
import "./CustomDatePicker.css";
import ModalCapNhatThanhVien from "./ModalCapNhatThanhVien";

const DanhSachDoan = ({ bookingId, action }) => {
  const [api, contextHolder] = notification.useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataQuocTich, setDataQuocTich] = useState([]);
  const [dataVaiTro, setDataVaiTro] = useState([]);
  const [dataGioiTinh, setDataGioiTinh] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [bkThongTinChungId, setBkThongTinChungId] = useState(0);
  const [bkDanhSachDoanId, setBkDanhSachDoanId] = useState(0);
  const [hanhDong, setHanhDong] = useState("ADD");
  const [reset, setReset] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    getDmQuocTich();
    getDmGioiTinh();
    getDmVaiTro();
    getDanhSachDoan({ bkThongTinChungId: bookingId, textSearch: "" });
  }, []);

  useEffect(() => {
    getDanhSachDoan({ bkThongTinChungId: bookingId, textSearch: "" });
  }, [reset]);

  const columns = [
    {
      title: "STT",
    },
    {
      title: t("sex"),
    },
    {
      title: t("fullName"),
    },
    {
      title: t("phoneNumber"),
    },
    {
      title: t("email"),
    },
    {
      title: t("dob"),
    },
    {
      title: t("quocTich"),
    },
    {
      title: t("passport"),
    },
    {
      title: t("expirationDate"),
    },
    {
      title: t("role"),
    },
    {
      title: t("notes"),
    },
    {
      title: t("action"),
    },
  ];

  const handleReset = () => {
    setReset(!reset);
  };

  const getDanhSachDoan = (dataReq) => {
    danhSachDoanService
      .getDanhSachDoan(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data;
          const danhSachDoan = data?.danhSachDoanDetails?.map((item, idx) => {
            return {
              ...item,
              stt: idx + 1,
              id: item.bkDanhSachDoanId + "",
            };
          });
          danhSachDoan.push({ id: "NULL" });
          setDataTable(danhSachDoan);
          setBkThongTinChungId(data.bkThongTinChungId);
        }
      })
      .catch((err) => console.log("getDataDanhSachDoan error " + err));
  };

  const getDmQuocTich = () => {
    dm_booking
      .getDmQuocTich()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return {
                value: it.id,
                label: it.quoc_tich,
              };
            });
          setDataQuocTich(data);
        }
      })
      .catch((err) => console.log("getDmQuocTich error " + err));
  };

  const getDmGioiTinh = () => {
    dm_booking
      .getDmGioiTinh()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return {
                value: it.id,
                label: it.gioi_tinh,
              };
            });
          setDataGioiTinh(data);
        }
      })
      .catch((err) => console.log("getDmGioiTinh error " + err));
  };

  const getDmVaiTro = () => {
    dm_booking
      .getDmVaiTro()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return {
                value: it.id,
                label: it.vai_tro,
              };
            });
          setDataVaiTro(data);
        }
      })
      .catch((err) => console.log("getDmVaiTro error " + err));
  };

  const handleCancelModal = () => {
    setIsOpen(false);
  };

  const handleDeleteService = (id) => {
    if (id)
      danhSachDoanService
        .deleteThanhVienDanhSachDoan({ bkDanhSachDoanId: id })
        .then((response) => {
          if (response.status === 200 && response.data.code === "00") {
            handleDelete(id);
          } else {
            api["error"]({
              message: t("stour"),
              description: response.data.message,
              duration: 0,
            });
          }
        });
    else handleDelete(id);
  };

  const handleDelete = (id) => {
    const newDataTable = [...dataTable];
    let idxDeleted = newDataTable.findIndex((it) => it.bkDanhSachDoanId === id);
    newDataTable.splice(idxDeleted, 1);
    newDataTable.forEach((it, idx) => (it.stt = idx + 1));
    setDataTable(newDataTable);
    api["success"]({
      message: t("stour"),
      description: t("delete") + " " + t("noti.success").toLowerCase(),
      duration: 0,
    });
  };

  const handleSaveBkDanhSachDoan = (act) => {
    let dataReq = {
      bkThongTinChungId: bookingId, // > 0 là update
      maxNguoiLon: 2,
      maxTreEm: 1,
      DanhSachDoanDetails: dataTable
        .filter((it) => it.id !== "NULL")
        .map((dt) => ({
          gioiTinhId: dt.gioiTinhId,
          hoVaTen: dt.hoVaTen,
          soDienThoai: dt.soDienThoai,
          email: dt.email,
          ngaySinh: dt.ngaySinh,
          dmQuocTichId: dt.dmQuocTichId,
          soHoChieu: dt.soHoChieu,
          ngayHetHan: dt.ngayHetHan,
          dmVaiTroId: dt.dmVaiTroId,
          ghiChu: dt.ghiChu,
        })),
    };
    console.log(dataReq);
    // danhSachDoanService
    //   .saveDanhSachDoan(dataReq)
    //   .then((res) => {
    //     if (res.status === 200 && res.data.code === "00") {
    //       api["success"]({
    //         message: t("stour"),
    //         description: t("add") + " " + t("noti.success").toLowerCase(),
    //         duration: 0,
    //       });
    //     } else {
    //       api["error"]({
    //         message: t("stour"),
    //         description: t("add") + " " + t("noti.error").toLowerCase(),
    //         duration: 0,
    //       });
    //     }
    //   })
    //   .catch((err) => console.log("SaveBkDanhSachDoan Error " + err));

    if (act === "end") {
      navigate("/dat-dich-vu");
    } else if (action === "edit") {
      navigate("/dat-dich-vu/thuc-thu/" + action + "?id=" + bookingId);
    } else {
      navigate("/dat-dich-vu/thuc-thu/" + action);
    }
  };

  const handleSearchDanhSachDoan = () => {
    let dataReq = { bkThongTinChungId: bookingId, textSearch: textSearch };
    getDanhSachDoan(dataReq);
  };

  const handleAddThanhVien = (item) => {
    // const newDataTable = [...dataTable];
    // let indexAdd = newDataTable.length - 1;
    // newDataTable[indexAdd] = {
    //   id: Math.random() + "",
    //   stt: newDataTable.length,
    //   gioiTinhId: 0,
    //   dmQuocTichId: 0,
    //   dmVaiTroId: 0,
    // };
    // newDataTable.push({ id: "NULL" });
    // setDataTable(newDataTable);

    setHanhDong("ADD");
    setBkDanhSachDoanId(0);
    setIsOpen(true);
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const handleChangeDataTable = (e, row) => {
    let name = e.target.name;
    let value = e.target.value;
    const newDataTable = [...dataTable];
    let index = newDataTable.findIndex((it) => it.id === row.id);
    if (name === "ngaySinh" || name === "ngayHetHan") {
      let day = moment(value).format("DD/MM/YYYY");
      newDataTable[index][name] = day;
    } else {
      newDataTable[index][name] = value;
    }
    setDataTable(newDataTable);
  };

  const handleChangeNgaySinh = (value, row) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataTable = [...dataTable];
    let index = newDataTable.findIndex((it) => it.id === row.id);
    newDataTable[index].ngaySinh = day;
    setDataTable(newDataTable);
  };

  const handleChangeNgayHetHan = (value, row) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataTable = [...dataTable];
    let index = newDataTable.findIndex((it) => it.id === row.id);
    newDataTable[index].ngayHetHan = day;
    setDataTable(newDataTable);
  };

  const handleAddOrUpdateThanhVien = (item) => {
    if (item.bkDanhSachDoanId) setHanhDong("EDIT");
    else setHanhDong("ADD");
    setBkDanhSachDoanId(item.bkDanhSachDoanId ? item.bkDanhSachDoanId : 0);
    setIsOpen(true);
  };

  return (
    <>
      {contextHolder}
      <ModalCapNhatThanhVien
        isOpen={isOpen}
        onReset={handleReset}
        handleCancel={handleCancelModal}
        bkDanhSachDoanId={bkDanhSachDoanId}
        bkThongTinChungId={bkThongTinChungId}
        hanhDong={hanhDong}
      />
      <Box>
        <Row style={{ marginTop: "1%" }}>
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Space size={20}>
              <OutlinedInput
                //   sx={{ height: "55px" }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                size="small"
                placeholder={t("search")}
                value={textSearch}
                onChange={(e) => setTextSearch(e.target.value)}
              />

              <Button
                // size="large"
                variant="contained"
                onClick={handleSearchDanhSachDoan}
                startIcon={<Search />}
                color="success"
              >
                {t("search")}
              </Button>
            </Space>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
          <Col
            xs={7}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Space size={20}>
              <Button variant="outlined" startIcon={<DownloadOutlined />}>
                Export
              </Button>
              <Button variant="outlined" startIcon={<UploadOutlined />}>
                Import
              </Button>
            </Space>
          </Col>
        </Row>
        <Row style={{ marginTop: "1%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <table>
                <thead>
                  {columns.map((cl) => {
                    return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                  })}
                </thead>
                <>
                  {dataTable.length > 0 ? (
                    dataTable.map((item) => {
                      return (
                        <tr>
                          {item.id.indexOf("NULL") !== -1 ? (
                            <>
                              <td colSpan="100%">
                                <Link onClick={() => handleAddThanhVien(item)}>
                                  <PlusOutlined />{" "}
                                  {t("add") + " " + t("member").toLowerCase()}
                                </Link>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>{item.stt}</td>
                              <td>
                                <TextField
                                  value={item.gioiTinhId}
                                  select
                                  variant="outlined"
                                  name="gioiTinhId"
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeDataTable(e, item)
                                  }
                                  fullWidth
                                  disabled
                                  // inputProps={{ style: { fontSize: "13px" } }}
                                  SelectProps={{
                                    style: { fontSize: "13px" },
                                    MenuProps: {
                                      style: { fontSize: "13px" },
                                    },
                                  }}
                                >
                                  {dataGioiTinh.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </td>
                              <td>{item.hoVaTen}</td>
                              <td>{item.soDienThoai}</td>
                              <td>{item.email}</td>
                              <td>{item.ngaySinh}</td>
                              <td>
                                <TextField
                                  value={item.dmQuocTichId}
                                  select
                                  variant="outlined"
                                  name="dmQuocTichId"
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeDataTable(e, item)
                                  }
                                  fullWidth
                                  disabled
                                  SelectProps={{
                                    style: { fontSize: "13px" },
                                    MenuProps: {
                                      style: { fontSize: "13px" },
                                    },
                                  }}
                                >
                                  {dataQuocTich.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </td>
                              <td>{item.soHoChieu}</td>
                              <td>{item.ngayHetHan}</td>
                              <td>
                                <TextField
                                  value={item.dmVaiTroId}
                                  select
                                  variant="outlined"
                                  name="dmVaiTroId"
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeDataTable(e, item)
                                  }
                                  fullWidth
                                  disabled
                                  SelectProps={{
                                    style: { fontSize: "13px" },
                                    MenuProps: {
                                      style: { fontSize: "13px" },
                                    },
                                  }}
                                >
                                  {dataVaiTro.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </td>
                              <td>{item.ghiChu}</td>
                              {item.rowSpan !== 0 ? (
                                <td rowSpan={item.rowSpan} width={"7%"}>
                                  <IconButton size="small">
                                    <EditFilled
                                      onClick={() =>
                                        handleAddOrUpdateThanhVien(item)
                                      }
                                    />
                                  </IconButton>
                                  <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() =>
                                      handleDeleteService(item.bkDanhSachDoanId)
                                    }
                                  >
                                    <IconButton style={{ fontSize: "20px" }}>
                                      <DeleteOutlined />
                                    </IconButton>
                                  </Popconfirm>
                                </td>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <td colSpan="100%">
                      <Link onClick={() => handleAddThanhVien()}>
                        <PlusOutlined />{" "}
                        {t("add") + " " + t("member").toLowerCase()}
                      </Link>
                    </td>
                  )}
                </>
              </table>
            </div>
          </Col>
        </Row>
      </Box>
      <Affix offsetBottom={50}>
        <Row>
          <Col span={12} />
          <Col span={12}>
            <div
              style={{
                textAlign: "center",
                color: "#fff",
                height: 60,
                paddingInline: 50,
                lineHeight: "60px",
                backgroundColor: "rgba(150, 150, 150, 0.2)",
                borderRadius: "4px",
              }}
            >
              <Space>
                <Button
                  sx={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  // startIcon={<RestartAltIcon />}
                  // onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSaveBkDanhSachDoan("end")}
                  type="end"
                  // startIcon={<SaveIcon />}
                >
                  {t("action.end")}
                </Button>
                <Button
                  variant="outlined"
                  // startIcon={<DeleteIcon />}
                  onClick={() => handleSaveBkDanhSachDoan("continue")}
                  key="continue"
                >
                  {t("action.continue")}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Affix>
    </>
  );
};
export default DanhSachDoan;
