import { DownloadOutlined, ImportOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Space, Upload, notification, theme } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import ModalPR from "./ModalPR";

const { useToken } = theme;
const { RangePicker } = DatePicker;

const FormPR = ({ dataAdd }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputMin, setInputMin] = useState(1);
    const [inputMax, setInputMax] = useState(100);
    const [api, contextHolder] = notification.useNotification();

    const props = {
        onChange({ file, fileList }) {
            if (file.status !== "uploading") {
                console.log(file, fileList);
            }
        },
        customRequest({ file, fileList }) {
            debugger;
            const formData = new FormData();
            formData.append("file", file);
            axios({
                method: "get",
                url: API_BASE_URL + "ncc/giaphong/importExcel/5",
                data: formData,
                headers: authHeader(),
            })
                .then(function (response) {
                    console.log("Response: ====" + response.status);
                    if (response.status === 200) {
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        },
        fileList: [],
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = (status, values) => {
        setIsModalOpen(false);
        let data = {
            ma_loai_hinh: values.ma_loai_hinh,
            loai_hinh_du_lich: values.loai_hinh_du_lich,
            mo_ta: values.mo_ta,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/saveDmLoaiHinhDuLich",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    dataAdd(response.data.data);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const onSearch = () => {};
    const handleFinish = (status, values) => {
        setIsModalOpen(status);
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        console.log("data =====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/vethangcanh/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({});
                            dataLstSearch.push({});
                        }
                        dataAdd(dataLst, dataLstSearch);
                    }
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.error").toLowerCase() + ". " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const items = [
        {
            key: "1",
            label: <h3>{t("pricingTime")}</h3>,
        },
    ];
    const { token } = useToken();

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        border: "4px solid #CFC8C8",
    };
    const handleClear = () => {
        form.resetFields();
    };
    const onFinish = (values) => {};
    const onChangeSlider = (newValue) => {
        setInputMin(newValue[0]);
        setInputMax(newValue[1]);
    };
    const onChangeIpMin = (newValue) => {
        setInputMin(newValue);
    };
    const onChangeIpMax = (newValue) => {
        setInputMax(newValue);
    };
    return (
        <Row>
            {contextHolder}
            <Col xs={10} sm={10} md={10} lg={10} xl={4}></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>

            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Space size="small">
                    <Button icon={<DownloadOutlined />}>{t("exportFile")}</Button>

                    <Upload {...props}>
                        <Button icon={<ImportOutlined />} type="dashed">
                            {t("importFile")}
                        </Button>
                    </Upload>
                    <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                        {t("add")}
                    </Button>
                    <ModalPR isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleFinish} />
                </Space>
            </Col>
        </Row>
    );
};
export default FormPR;
