import {
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    PlusSquareFilled,
    SendOutlined,
} from "@ant-design/icons";
import { MenuItem } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton, InputAdornment, Link, Select, TextField } from "@mui/material";
import { Col, Collapse, Row, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TongKet from "./TongKet";
import dm_do_tuoi from "../../../../../api/category/dm_do_tuoi";
import baoGiaService from "../../../../../api/bao_gia/baoGiaService";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
import khachSanService from "../../../../../api/tour/khachSan/khachSanService";
import nhaHangService from "../../../../../api/tour/nhaHang/nhaHangService";
import otoServiceBG from "../../../../../api/bao_gia/sale_rate/oto/otoService";
import otoService from "../../../../../api/tour/oto/oto.service";
import Check from "@mui/icons-material/Check";
import ModalCapNhatKS from "./modalCapNhat/ModalCapNhatKS";
import ModalCapNhatOto from "./modalCapNhat/ModalCapNhatOto";
import ModalCapNhatNhaHang from "./modalCapNhat/ModalCapNhatNhaHang";
import DieuHanhService from "../../../../../api/dieu_hanh/DieuHanhService";
// import { element } from "prop-types";

const NewService = ({
    dataDv,
    baoGiaTongQuanId,
    onReload,
    dichVuNguoiLons,
    dataNhomNgLon,
    dataNhomTreEm,
    soLuongNgLon,
    soLuongTreEm,
    soNgay,
    bgHanhTrinhs,
    tongGia,
    dichVuTreEms,
    opDichVuId,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [openNH, setOpenNh] = useState(false);
    const [openKs, setOpenKs] = useState(false);
    const [openOto, setOpenOto] = useState(false);
    const [dataTableTreEm, setDataTableTreEm] = useState([]);
    const [dataTableNguoiLon, setDataTableNguoiLon] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [kieuPhong, setKieuPhong] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [dmSoCho, setDmSoCho] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [nhomNguoiLons, setNhomNguoiLons] = useState([]);
    const [baoGiaHanhTrinhId, setBaoGiaHanhTrinhId] = useState(0);
    const [tranxOpSaleRateId, setTranxOpSaleRateId] = useState(0);
    const [tranxOpRateId, setTranxOpRateId] = useState(0);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [dmDichVuId, setDmDichVuId] = useState(0);
    useEffect(() => {
        // baoGiaService.getBaoGiaTongQuanById({ id: 1 }).then(function (response) {
        //     if (response.status === 200 && response.data.code === "00") {
        //         let data = response.data.data;
        //         setNhomNguoiLons(data.nhomNguoiLons);
        //     }
        // });
    }, []);
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataBA = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataBA.push({
                                value: data[i].id,
                                label: data[i].ten_bua_an,
                            });
                        }
                    }
                    setDmBuaAn(dataBA);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("Response setDmVat: ====", response.data.data);
                    let vat = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc)
                            vat.push({
                                value: data[i].vat,
                                label: data[i].vat_str,
                                id: data[i].id,
                            });
                    }
                    console.log("Response vat: ====", vat);
                    setDmVat(vat);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    }, []);

    const columns_date = [
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("supplier") + " (Sale)",
        },
        {
            title: t("service"),
        },
        {
            title: t("luaChon"),
        },
        {
            title: t("hotel.roomClass.conditionFoc"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.intoMoney"),
        },
        {
            title: t("thuevat"),
        },
        {
            title: t("supplier") + " (OP)",
        },
        {
            title: "OP Check",
        },
    ];
    const columns_table_te = [
        {
            title: "STT",
            visible: true,
        },
        {
            title: t("typeService"),
            visible: true,
        },
        {
            title: t("supplier") + " (Sale)",
            visible: true,
        },
        {
            title: t("service"),
            visible: true,
        },
        {
            title: t("age") + " " + t("children"),
            visible: true,
        },
        {
            title: t("tour.percentage"),
            visible: true,
        },
        {
            title: t("tour.donGia"),
            visible: true,
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            visible: true,
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            visible: true,
        },
        {
            title: t("thuevat"),
            visible: true,
        },
        {
            title: t("supplier") + " (OP)",
            visible: true,
        },
    ];
    const handleCancelKS = () => {
        setOpenKs(false);
    };

    const handleCancelOto = () => {
        setOpenOto(false);
    };

    const handleCancelNh = () => {
        setOpenNh(false);
    };
    const handleAddRowServices = (index, item) => {
        let dt = [];
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpanDV !== 0 && it.tenDichVu === item.tenDichVu);
        setEditingId(crrFirstDV);
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirstDV) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: 1,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    rowSpanDV: rowSpanDV,
                    tenDichVu: item.tenDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    tenNhaCungCap: "",
                    dieuKienFoc: "",
                    foc: "",
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });

                element.rowSpanDV = 0;
            } else {
                // if (key === crrFirstDV) {
                //     element.rowSpanDV = rowSpanDV;
                // }
                // // if (key === crrFirst) {
                // //     element.rowSpan = rowSpan;
                // // }
                // if (key === index)
                //     dt.push({
                //         id: Math.random() + "NEW",
                //         rowSpan: 1,
                //         tenDichVu: item.tenDichVu,
                //         bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                //         nccThongTinChungId: item.nccThongTinChungId,
                //         parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                //         tenNhaCungCap: item.tenNhaCungCap,
                //         dmGiaApDungId: item.dmGiaApDungId,
                //         dmLoaiApDungId: item.dmLoaiApDungId,
                //         bgCTSaleId: item.bgCTSaleId,
                //         moTa: item.moTa,
                //         dieuKienFoc: item.dieuKienFoc,
                //         foc: item.foc,
                //         bgChietTinhOPId: item.bgChietTinhOPId,
                //         bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                //         dmDichVuId: item.dmDichVuId,
                //     });
            }
            dt.push(element);
        }
        setDataTableNguoiLon(dt);
    };
    const handleAddBySerivce = (dataTableNguoiLon, index, item) => {
        let dt = [];
        let crrFirst = dataTableNguoiLon.findIndex(
            (it) => it.rowSpan !== 0 && it.tenNhaCungCap === item.tenNhaCungCap && it.tenDichVu === item.tenDichVu
        );
        setEditingId(crrFirst);
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpan !== 0 && it.tenDichVu === item.tenDichVu);
        let rowSpan = dataTableNguoiLon[crrFirst].rowSpan + 1;
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirst) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    tenDichVu: item.tenDichVu,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    tenNhaCungCap: item.tenNhaCungCap,
                    bgCTSaleId: item.bgCTSaleId,
                    dieuKienFoc: item.dieuKienFoc,
                    foc: item.foc,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    rowSpanDV: rowSpanDV,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }

            if (
                element.rowSpan !== 0 &&
                element.tenNhaCungCap === item.tenNhaCungCap &&
                element.tenDichVu === item.tenDichV
            ) {
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }
            dt.push(element);
        }
        setDataTableNguoiLon(dt);
    };
    const handleChangeTenNCC = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.tenNhaCungCap = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeDmDichVuId = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index && tenDichVu === "Khách sạn") item.dmKieuPhongId = value;
            if (key === index && tenDichVu === "Nhà hàng") item.dmBuaAnId = value;
            if (key === index && tenDichVu === "Xe ô tô") item.soXe = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeDieuKienFoc = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.dieuKienFoc = value;
            }

            return item;
        });
        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeDonGia = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.giaDichVu = value;
            }
            return item;
        });
        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeFoc = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.foc = value;
            }
            return item;
        });

        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeThanhTien = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.thanhTien = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeVat = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.vat = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const tinhToanThanhTable = (data) => {
        let dtChange = [];
        dtChange = data.map((element) => {
            if (element.id.indexOf("NULL") === -1)
                if (element.tenDichVu === "Nhà hàng") {
                    element.thanhTien = tinhThanhTienNH(
                        element.dieuKienFoc,
                        element.giaDichVu,
                        element.foc,
                        element.soLuong
                    );
                } else if (element.tenDichVu === "Xe ô tô") {
                    element.thanhTien = element.soLuong * element.giaDichVu;
                } else {
                    let dataKS = data.filter(
                        (it) => it.tenDichVu === element.tenDichVu && it.tenNhaCungCap === element.tenNhaCungCap
                    );
                    let thanhTien = tinhThanhTienKS(
                        dataKS.map((it) => it.soLuong),
                        dataKS.map((it) => it.giaDichVu),
                        Math.floor(element.dieuKienFoc / element.foc)
                    );
                    for (let i = 0; i < dataKS.length; i++) {
                        if (dataKS[i].id === element.id) {
                            element.thanhTien = thanhTien[i];
                        }
                    }
                }
            return element;
        });
        return dtChange;
    };
    const handleChangeSoLuong = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = [];
        data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.soLuong = value;
            }
            return item;
        });

        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeDichVu = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.dichVu = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleReload = () => {
        setEditingId(null);
        onReload();
    };

    const handleSave = (index, value, tenDichVu) => {
        let dichVus = [];
        let dichVuTreEms = [];

        if (dataTableNguoiLon.length > 0) {
            if (tenDichVu === "Xe ô tô") {
                const dataTe = value.bgNhomNguoiLons?.flatMap((d) =>
                    dataNhomTreEm.filter((e) => e.nhomNguoiLonId === d)
                );

                dichVuTreEms = dataTe.map((e) => ({
                    bgNhomTreEmId: e.id,
                    details: [
                        ...e.details.map((d) => ({
                            ...d,
                            tranxNhaHangId: d.tranxNhaHangId || 0,
                        })),
                    ],
                }));
            } else {
                const element = dataTableNguoiLon.find((element) => element.tenDichVu === tenDichVu);

                if (element) {
                    dichVuTreEms = dataNhomTreEm
                        .filter((e) => e.nhomNguoiLonId === element.bgNhomNguoiLonId)
                        .map((e) => ({
                            bgNhomTreEmId: e.id,
                            details: [
                                ...e.details.map((d) => ({
                                    ...d,
                                    tranxNhaHangId: d.tranxNhaHangId || 0,
                                })),
                            ],
                        }));
                }
            }
        } else {
            dichVuTreEms = dataNhomTreEm.map((e) => ({
                bgNhomTreEmId: e.id,
                details: [
                    ...e.details.map((d) => ({
                        ...d,
                        tranxNhaHangId: d.tranxNhaHangId || 0,
                    })),
                ],
            }));
        }

        for (let i = dataTableNguoiLon.length - 1; i >= 0; i--) {
            const element = dataTableNguoiLon[i];
            if (
                value.bgMapCTSaleHtId === element.bgMapCTSaleHtId &&
                element.tenDichVu === tenDichVu &&
                element.id.indexOf("NULL") === -1
            ) {
                let dichVuData = {
                    dmVatId: dmVat.find((d) => element.vat === d.vat)?.id,
                    nccThongTinChungId: element.nccThongTinChungId,
                    tenDichVu: element.dichVu,
                    dmBuaAnId: element.dmBuaAnId,
                    dmKieuPhongId: element.dmKieuPhongId,
                    dmGiaApDungId: element.dmGiaApDungId,
                    tranxGiaPhongId: 0,
                    dmSoChoId: element.dmSoChoId,
                    bgNhomNguoiLonId: element.bgNhomNguoiLonId,
                    tranxNhaHangId: element.tranxNhaHangId || 0,
                    nccDichVuNhaHangId: element.nccDichVuNhaHangId || 0,
                    dichVuTreEms,
                };

                if (tenDichVu === "Xe ô tô") {
                    dichVuData = {
                        ...dichVuData,
                        giaOtoNhapTay: element.donGia,
                        soLuong: element.soLuong,
                        bgNhomNguoiLons: value.bgNhomNguoiLons?.map((d) => ({ bgNhomNguoiLonId: d })) || [],
                        soXe: element.soXe,
                    };
                } else if (tenDichVu === "Khách sạn") {
                    dichVuData = {
                        ...dichVuData,
                        donGiaNhapTay: element.donGia,
                        soLuongNhapTay: element.soLuong,
                    };
                } else if (tenDichVu === "Nhà hàng") {
                    dichVuData = {
                        ...dichVuData,
                        giaNhaHangNhapTay: element.donGia,
                        soLuong: element.soLuong,
                        dieuKienFoc: value.dieuKienFoc,
                        foc: value.foc,
                    };
                }

                dichVus.push(dichVuData);
            }
        }

        const data = {
            baoGiaTongQuanId: baoGiaTongQuanId,
            bgMapCTSaleHtId: value.bgMapCTSaleHtId || 0,
            nccThongTinChungId: value.nccThongTinChungId || 0,
            bgCTSaleId: value.bgCTSaleId || 0,
            tenNcc: value.tenNhaCungCap,
            dmDichVuId: value.dmDichVuId,
            dmGiaApDungId: value.dmGiaApDungId,
            dieuKienFoc: tenDichVu === "Nhà hàng" ? 0 : value.dieuKienFoc,
            foc: tenDichVu === "Nhà hàng" ? 0 : value.foc,
            dichVus: dichVus,
        };

        console.log(JSON.stringify(data));

        const saveService = (service, saveFunction) => {
            saveFunction(service).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        };

        if (tenDichVu === "Khách sạn") {
            saveService(data, khachSanService.saveCTSaleKhachSan);
        } else if (tenDichVu === "Nhà hàng") {
            saveService(data, nhaHangService.saveChietTinhSaleNhaHang);
        } else if (tenDichVu === "Xe ô tô") {
            saveService(data, otoServiceBG.saveCTSaleOto);
        }
    };
    const handleDelete = (key, value) => {
        khachSanService
            .deleteCTSaleDichVuKs({
                tranxBgChietTinhKsId: value.tranxBgChietTinhKsId,
            })
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
    };
    const handleClear = () => {
        handleReload();
    };
    const handleChangeNhomNLOto = (index, event) => {
        const {
            target: { value },
        } = event;
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.bgNhomNguoiLons = typeof value === "string" ? value.split(",") : value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeNhomNL = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.bgNhomNguoiLonId = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const tinhThanhTienNH = (numberOfRates, unitPrice, FOCConditions, countOfService) => {
        debugger;
        // Calculate FOC quantity
        const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

        // Calculate total service cost
        const totalServiceCost = countOfService * unitPrice;

        // Calculate service cost after applying FOC policy
        return totalServiceCost - FOCQuantity ? FOCQuantity : 0 * unitPrice;
    };
    const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
        //FOCConditions dk foc
        //numberOfRoomsBooked: số lượng
        //unitPrices đơn giá
        // Define variables
        const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce((acc, val) => acc + val, 0);
        // Calculate B5
        const lowestRoomCategoryPrice = Math.min(...unitPrices);
        const averageReducedPricePerRoom =
            (lowestRoomCategoryPrice * Math.floor(tongSoLuongPhongNCC / FOCConditions)) / tongSoLuongPhongNCC;

        // Calculate B6
        return numberOfRoomsBooked.map(
            (count, index) => count * unitPrices[index] - averageReducedPricePerRoom * count
        );
    };

    const handleAddOrUpdateServiceOp = (item, loaiDichVuId, tranxOpSaleRateId) => {
        console.log(item);
        debugger;
        if (tranxOpSaleRateId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setTranxOpSaleRateId(tranxOpSaleRateId);
        // setTranxOpRateId(item.tranxOpRateId ? item.tranxOpRateId : 0);
        switch (loaiDichVuId) {
            case 1:
                return setOpenKs(true);
            case 2:
                return setOpenOto(true);
            case 3:
                return setOpenNh(true);
            default:
                return null;
        }
    };
    const handleClearNCC = (id) => {
        DieuHanhService.xoaNhaCungCap({
            tranxOpSaleRateId: id,
        })
            .then((response) => {
                if (response.data.status === "00") {
                    showNoti("success", response.data.message);
                } else {
                    showNoti("error", response.data.message);
                }
            })
            .catch((error) => {
                showNoti("error", error.message);
            });
    };
    const handleSaleCheck = (id, buttonCheck) => {
        DieuHanhService.saleCheck({
            tranxOpSaleRateId: id,
            buttonCheck: buttonCheck, // view_check: đã check, op_check: chuyển op
        })
            .then((response) => {
                console.log(response);
                if (response.data.code === "00") {
                    showNoti("success", response.data.message);
                } else {
                    showNoti("error", response.data.message);
                }
            })
            .catch((error) => {
                showNoti("error", error.message);
            });
    };
    let formatter = new Intl.NumberFormat("en-US", {});
    const table_nl = () => {
        return (
            <>
                <table>
                    <thead
                        style={{
                            backgroundColor: "#E2E2E2",
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        {columns_date.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dichVuNguoiLons?.map((item, keyNL) => {
                            let row = [];
                            let loaiDichVus = item.loaiDichVus;
                            row = loaiDichVus?.map((loaiDv, key) => {
                                if (loaiDv.dichVus.length === 0) {
                                    return null;
                                } else {
                                    return loaiDv.dichVus.map((dv, keyDv) => {
                                        let rowSpan = loaiDv.dichVus.reduce((sum, item) => {
                                            sum += item.dichVuDetails.length;
                                            return sum;
                                        }, 0);
                                        if (dv.dichVuDetails) {
                                            return dv.dichVuDetails.map((detail, index) => {
                                                return (
                                                    <>
                                                        <tr key={index + "" + key}>
                                                            {index === 0 && keyDv === 0 ? (
                                                                <>
                                                                    <td rowSpan={rowSpan}>{key + 1}</td>
                                                                    <td rowSpan={rowSpan}>{loaiDv.tenLoaiDichVu}</td>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {index === 0 ? (
                                                                <td rowSpan={dv.dichVuDetails.length}>{dv.tenNcc}</td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <td>{detail.tenDichVu}</td>
                                                            <td>{detail.luaChon}</td>
                                                            {index === 0 ? (
                                                                <td rowSpan={dv.dichVuDetails.length}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "flex-end",
                                                                        }}
                                                                    >
                                                                        {loaiDv.tenLoaiDichVu === "Xe ô tô" ? (
                                                                            <></>
                                                                        ) : (
                                                                            <>
                                                                                {detail.dieuKienFocNh}
                                                                                {loaiDv.tenLoaiDichVu === "Khách sạn"
                                                                                    ? " " + t("room")
                                                                                    : " " + t("set")}
                                                                                {"/ " + detail.focNh + " "}
                                                                                {t("hotel.roomClass.foc")}
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <td>{formatter.format(detail.donGia)}</td>
                                                            <td>{detail.soLuong}</td>
                                                            <td>{formatter.format(detail.thanhTien)}</td>
                                                            {index === 0 ? (
                                                                <td rowSpan={dv.dichVuDetails.length}>
                                                                    {detail.vatStr}
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {index === 0 ? (
                                                                <td rowSpan={dv.dichVuDetails.length}>
                                                                    <TextField
                                                                        disabled
                                                                        size="small"
                                                                        value={detail.opTenNcc}
                                                                        // onChange={(e) =>
                                                                        //     handleChangeThanhTien(dichVus, key, e.target.value, id)
                                                                        // }
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton size="small">
                                                                                        <EditFilled
                                                                                            onClick={() =>
                                                                                                handleAddOrUpdateServiceOp(
                                                                                                    detail,
                                                                                                    loaiDv.loaiDichVuId,
                                                                                                    dv.tranxOpSaleRateId
                                                                                                    //   baoGiaHanhTrinhId,
                                                                                                    //   ngay
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        // disabled={
                                                                                        //     !item.nccThongTinChungId
                                                                                        //         ? true
                                                                                        //         : false
                                                                                        // }
                                                                                        onClick={() =>
                                                                                            handleClearNCC(
                                                                                                dv.tranxOpSaleRateId
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <CloseCircleFilled />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {index === 0 ? (
                                                                <td rowSpan={dv.dichVuDetails.length}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "flex-end",
                                                                        }}
                                                                    >
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleSaleCheck(
                                                                                    dv.tranxOpSaleRateId,
                                                                                    "view_check"
                                                                                )
                                                                            }
                                                                        >
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleSaleCheck(
                                                                                    dv.tranxOpSaleRateId,
                                                                                    "op_check"
                                                                                )
                                                                            }
                                                                            disabled={detail.opCheck}
                                                                        >
                                                                            <Check />
                                                                        </IconButton>
                                                                    </Box>
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </tr>
                                                    </>
                                                );
                                            });
                                        } else {
                                            return <tr key={key}></tr>;
                                        }
                                    });
                                }
                            });
                            return row;
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleAddRowServicesTE = (dataTableTreEm, index, item) => {
        let dt = [];
        let crrFirst = dataTableTreEm.findIndex(
            (it) =>
                it.rowSpan !== 0 &&
                it.tenDichVu === item.tenDichVu &&
                it.tenNhaCungCap === item.tenNhaCungCap &&
                it.dichVuTreEms === item.dichVuTreEms
        );
        let rowSpan = dataTableTreEm[crrFirst].rowSpan + 1;
        let crrFirstDV = dataTableTreEm.findIndex((it) => it.rowSpanDV !== 0 && it.tenDichVu === item.tenDichVu);
        let rowSpanDV = dataTableTreEm[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableTreEm.length; key++) {
            const element = dataTableTreEm[key];
            if (index === crrFirst && key === crrFirst && index === crrFirstDV) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    rowSpanDV: rowSpanDV,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    tenNhaCungCap: item.tenNhaCungCap,
                    dmGiaApDungId: item.dmGiaApDungId,
                    dmLoaiApDungId: item.dmLoaiApDungId,
                    moTa: item.moTa,
                    dieuKienFoc: item.dieuKienFoc,
                    foc: item.foc,
                    tenDichVu: item.tenDichVu,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                    dmDichVuId: item.dmDichVuId,
                });

                element.rowSpan = 0;
                element.rowSpanDV = 0;
            } else {
                if (key === crrFirstDV) {
                    element.rowSpanDV = rowSpanDV;
                }
                if (key === crrFirst) {
                    element.rowSpan = rowSpan;
                }
                if (key === index && key === crrFirst) {
                    element.rowSpan = 0;
                    dt.push({
                        id: Math.random() + "NEW",
                        bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                        nccThongTinChungId: item.nccThongTinChungId,
                        parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                        tenNhaCungCap: item.tenNhaCungCap,
                        dmGiaApDungId: item.dmGiaApDungId,
                        dmLoaiApDungId: item.dmLoaiApDungId,
                        moTa: item.moTa,
                        rowSpan: rowSpan,
                        dieuKienFoc: item.dieuKienFoc,
                        foc: item.foc,
                        tenDichVu: item.tenDichVu,
                        bgChietTinhOPId: item.bgChietTinhOPId,
                        bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                        dmDichVuId: item.dmDichVuId,
                    });
                } else if (key === index && key !== crrFirst) {
                    dt.push({
                        id: Math.random() + "NEW",
                        bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                        nccThongTinChungId: item.nccThongTinChungId,
                        parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                        tenNhaCungCap: item.tenNhaCungCap,
                        dmGiaApDungId: item.dmGiaApDungId,
                        dmLoaiApDungId: item.dmLoaiApDungId,
                        moTa: item.moTa,
                        dieuKienFoc: item.dieuKienFoc,
                        foc: item.foc,
                        tenDichVu: item.tenDichVu,
                        bgChietTinhOPId: item.bgChietTinhOPId,
                        bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                        dmDichVuId: item.dmDichVuId,
                        rowSpan: 0,
                    });
                }
            }
            dt.push(element);
        }
        setDataTableTreEm(dt);
    };

    const handleAddAge = (dataTE, row) => {
        let indexAdd = dataTE.findIndex((it) => it.id === row.id);
        let dataRowAdd = { ...dataTE[indexAdd] };
        dataTE[indexAdd].rowSpanLoaiDV = 0;
        dataTE[indexAdd].rowSpanNcc = 0;
        dataTE[indexAdd].rowSpanDV = 0;

        dataTE.splice(indexAdd, 0, {
            id: Math.random() + "",
            rowSpanLoaiDV: 0,
            rowSpanNcc: 0,
            rowSpanDV: 0,
            loaiDichVu: row.loaiDichVu,
            tenNhaCungCap: row.tenNhaCungCap,
            tenDichVu: row.tenDichVu,
            giaDichVu: row.giaDichVu,
            bgCTSaleId: row.bgCTSaleId,
            dmDichVuId: row.dmDichVuId,
            mapBgChietTinhKsTreEmId: row.mapBgChietTinhKsTreEmId,
            mapBgChietTinhNhTreEmId: row.mapBgChietTinhNhTreEmId,
            mapBgChietTinhOtoTreEmId: row.mapBgChietTinhOtoTreEmId,
            tranxBgChietTinhKsId: row.tranxBgChietTinhKsId,
            tranxBgChietTinhNhId: row.tranxBgChietTinhNhId,
            tranxBgChietTinhOtoId: row.tranxBgChietTinhOtoId,
            phanTram: 0,
            soLuong: 0,
            dmDoTuoiId: 1,
            dmVatId: 1,
        });

        let crrFirstLoaiDichVu = dataTE.findIndex((it) => it.loaiDichVu === row.loaiDichVu);
        let crrFirstNcc = dataTE.findIndex(
            (it) => it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
        );
        let crrFirstDV = dataTE.findIndex(
            (it) =>
                it.rowSpanDV !== 0 &&
                it.loaiDichVu === row.loaiDichVu &&
                it.tenNhaCungCap === row.tenNhaCungCap &&
                it.tenDichVu === row.tenDichVu
        );
        if (crrFirstLoaiDichVu !== -1) {
            dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV += dataRowAdd.rowSpanLoaiDV + 1;
            // dataTE[crrFirstLoaiDichVu].stt = dataRowAdd.stt;
        }
        if (crrFirstNcc !== -1) {
            dataTE[crrFirstNcc].rowSpanNcc += 1;
        } else {
            dataTE[indexAdd].rowSpanNcc += dataRowAdd.rowSpanNcc + 1;
            // dataTE[indexAdd].rowSpanNcc += 2;
        }
        if (crrFirstDV !== -1) {
            dataTE[crrFirstDV].rowSpanDV += 1;
        } else {
            dataTE[indexAdd].rowSpanDV += 2;
        }

        setDataTableTreEm([...dataTE]);
    };

    const handleSaveCTDoTuoi = async (row) => {
        let dataReq = {
            dmDoTuoiId: row.dmDoTuoiId,
            phanTram: row.phanTram,
            soLuong: row.soLuong,
            dmVatId: row.dmVatId,
            apDung: true,
        };
        let response = { status: 500 };
        if (row.loaiDichVu === "Khách sạn") {
            dataReq.tranxBgCtKsSaleTreEmId = row.tranxBgCtKsSaleTreEmId ? row.tranxBgCtKsSaleTreEmId : 0;
            dataReq.mapBgChietTinhKsTreEmId = row.mapBgChietTinhKsTreEmId;
            dataReq.tranxBgChietTinhKsId = row.tranxBgChietTinhKsId;
            response = await khachSanService.saveChietTinhDoTuoi(dataReq);
        } else if (row.loaiDichVu === "Nhà hàng") {
            dataReq.mapBgCtNhSaleTreEmID = row.mapBgCtNhSaleTreEmId;
            response = await nhaHangService.saveChietTinhDoTuoi(dataReq);
        } else {
            // response = await otoService.saveChietTinhDoTuoi(dataReq);
        }
        if (response.status === 200 && response.data.code === "00") {
            handleReload();
            showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("add") + " " + t("noti.error").toLowerCase());
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };

    const handleDeleteAge = async (dataTE, row) => {
        let isDeleted = false;
        let res = { status: 500 };
        if (row.loaiDichVu === "Khách sạn" && row.tranxBgCtKsSaleTreEmId) {
            res = await khachSanService.deleteChietTinhDoTuoi({
                tranxBgCtKsSaleTreEmId: row.tranxBgCtKsSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === "Nhà hàng" && row.tranxBgCtNhSaleTreEmId) {
            res = await nhaHangService.deleteChietTinhDoTuoi({
                tranxBgCtNhSaleTreEmId: row.tranxBgCtNhSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === "Xe ô tô" && row.tranxBgCtOtoSaleTreEmId) {
            res = await otoService.deleteChietTinhDoTuoi({
                tranxBgCtKsSaleTreEmId: row.tranxBgChietTinhKsId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else {
            isDeleted = true;
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
        }
        if (isDeleted) {
            let indexDeleted = dataTE.findIndex((it) => it.id === row.id);
            let dataDeleted = dataTE[indexDeleted];
            dataTE.splice(indexDeleted, 1);
            let crrFirstLoaiDichVu = dataTE.findIndex(
                (it) => it.rowSpanLoaiDV !== 0 && it.loaiDichVu === row.loaiDichVu
            );
            let crrFirstNcc = dataTE.findIndex(
                (it) =>
                    it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
            );
            let crrFirstDV = dataTE.findIndex(
                (it) =>
                    it.rowSpanDV !== 0 &&
                    it.loaiDichVu === row.loaiDichVu &&
                    it.tenNhaCungCap === row.tenNhaCungCap &&
                    it.tenDichVu === row.tenDichVu
            );
            if (crrFirstLoaiDichVu !== -1) {
                dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV -= 1;
            } else {
                dataTE[indexDeleted].rowSpanLoaiDV = dataDeleted.rowSpanLoaiDV - 1;
                dataTE[indexDeleted].stt = dataDeleted.stt;
            }
            if (crrFirstNcc !== -1) {
                dataTE[crrFirstNcc].rowSpanNcc -= 1;
            } else {
                if (dataDeleted.rowSpanNcc !== 0) dataTE[indexDeleted].rowSpanNcc = dataDeleted.rowSpanNcc - 1;
                else dataTE[indexDeleted].rowSpanNcc = 1;
            }
            if (crrFirstDV !== -1) {
                dataTE[crrFirstDV].rowSpanDV -= 1;
            } else {
                if (dataDeleted.rowSpanDV !== 0) dataTE[indexDeleted].rowSpanDV = dataDeleted.rowSpanDV - 1;
                else dataTE[indexDeleted].rowSpanDV = 1;
            }

            setDataTableTreEm([...dataTE]);
        }
    };

    const handleShowNotiDeleteTE = (res) => {
        if (res.status === 200 && res.data.code === "00") {
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
            return true;
        } else {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
            return false;
        }
    };

    const handleChangItemTE = (dataTE, row, e) => {
        let indexChange = dataTE.findIndex((it) => it.id === row.id);
        let name = e.target.name;
        let value = e.target.value;
        dataTE[indexChange][name] = value;
        if (name !== "dmDoTuoiId") {
            dataTE[indexChange].thanhTien =
                (dataTE[indexChange].phanTram / 100) * dataTE[indexChange].soLuong * dataTE[indexChange].giaDichVu;
        }
        setDataTableTreEm([...dataTE]);
    };

    const table_te = () => {
        let stt = 1;
        return (
            <>
                <table>
                    <thead
                        style={{
                            backgroundColor: "#E2E2E2",
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        {columns_table_te
                            .filter((it) => it.visible)
                            .map((cl) => {
                                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                            })}
                    </thead>
                    <tbody>
                        {dichVuTreEms.map((iten) => {
                            let row = [];
                            let dataTE = iten.loaiDichVus;
                            row = dataTE?.map((loaiDv, key) => {
                                if (loaiDv.dichVuTreEms.length > 0) {
                                    let rowSpan = loaiDv.dichVuTreEms.reduce((sum, item) => {
                                        sum += item.dichVuDetails.length;
                                        return sum;
                                    }, 0);
                                    return loaiDv.dichVuTreEms.map((dv, dvKey) => {
                                        if (dv.dichVuDetails) {
                                            return dv.dichVuDetails.map((detail, index) => {
                                                return (
                                                    <>
                                                        <tr key={index + "" + key}>
                                                            {dvKey === 0 && index === 0 ? (
                                                                <>
                                                                    <td rowSpan={rowSpan}>{key + 1}</td>
                                                                    <td rowSpan={rowSpan}>{loaiDv.tenLoaiDichVu}</td>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {index === 0 ? (
                                                                <>
                                                                    <td rowSpan={dv.dichVuDetails.length}>
                                                                        {dv.tenNcc}
                                                                    </td>
                                                                    <td rowSpan={dv.dichVuDetails.length}>
                                                                        {dv.tenDichVu}
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <td>{detail.doTuoi}</td>
                                                            <td>{detail.phanTram}</td>
                                                            <td>{formatter.format(detail.donGia)}</td>
                                                            <td>{detail.soLuong}</td>
                                                            <td>{formatter.format(detail.thanhTien)}</td>{" "}
                                                            {index === 0 ? (
                                                                <>
                                                                    <td rowSpan={dv.dichVuDetails.length}>
                                                                        {detail.vatStr}
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <td>{detail.opTenNcc}</td>
                                                        </tr>
                                                    </>
                                                );
                                            });
                                        } else {
                                            return <tr key={key}></tr>;
                                        }
                                    });
                                } else return [];
                            });
                            return row;
                        })}
                    </tbody>
                </table>
            </>
        );
    };

    const item_date = [
        {
            key: "1",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("adultService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_nl()}</>,
        },
        {
            key: "2",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("childrenService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_te()}</>,
        },
    ];
    const children_dv = (
        <>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={"1"} items={item_date} />
                </Col>
            </Row>
        </>
    );
    // const cauHinh = [
    //     {
    //         key: "1",
    //         label: (
    //             <div
    //                 style={{
    //                     textTransform: "uppercase",
    //                     fontSize: "13px",
    //                     fontWeight: "bold",
    //                 }}
    //             >
    //                 {t("serviceProvided")}
    //             </div>
    //         ),
    //         children: children_dv,
    //     },
    //     {
    //         key: "2",
    //         label: (
    //             <div
    //                 style={{
    //                     textTransform: "uppercase",
    //                     fontSize: "13px",
    //                     fontWeight: "bold",
    //                 }}
    //             >
    //                 {t("totalPrice")}
    //             </div>
    //         ),
    //         children: (
    //             <>
    //                 <TongKet baoGiaTongQuanId={baoGiaTongQuanId} tongGia={tongGia} onReload={onReload} />
    //             </>
    //         ),
    //     },
    // ];

    return (
        <>
            {contextHolder}
            {children_dv}
            <ModalCapNhatKS
                isOpen={openKs}
                onReset={handleReload}
                handleCancel={handleCancelKS}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                tranxOpSaleRateId={tranxOpSaleRateId}
                tranxOpRateId={tranxOpRateId}
                soLuongTreEmBG={soLuongTreEm}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
                opDichVuId={opDichVuId}
            />
            <ModalCapNhatOto
                isOpen={openOto}
                onReset={handleReload}
                handleCancel={handleCancelOto}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                tranxOpSaleRateId={tranxOpSaleRateId}
                tranxOpRateId={tranxOpRateId}
                soLuongTreEmBG={soLuongTreEm}
                soLuongNgLonBG={soLuongNgLon}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
                opDichVuId={opDichVuId}
            />
            <ModalCapNhatNhaHang
                isOpen={openNH}
                onReset={handleReload}
                handleCancel={handleCancelNh}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                tranxOpSaleRateId={tranxOpSaleRateId}
                tranxOpRateId={tranxOpRateId}
                soLuongTreEmBG={soLuongTreEm}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
                opDichVuId={opDichVuId}
            />
        </>
    );
};
export default NewService;
