import { CloseCircleFilled, EditFilled } from "@ant-design/icons";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { Collapse, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import dm_do_tuoi from "../../../../../api/category/dm_do_tuoi";
import otoService from "../../../../../api/tour/oto/oto.service";
import Check from "@mui/icons-material/Check";
import Visibility from "@mui/icons-material/Visibility";
import DieuHanhService from "../../../../../api/dieu_hanh/DieuHanhService";
import ModalCapNhatNhaHang from "./modalCapNhat/ModalCapNhatNhaHang";
import ModalCapNhatOto from "./modalCapNhat/ModalCapNhatOto";
import ModalCapNhatKS from "./modalCapNhat/ModalCapNhatKS";

const style = {
    h4: { fontSize: "16px", fontWeight: "bold" },
};
const ViewDate = ({
    dataSource,
    dataNhomNgLon,
    dataNhomTreEm,
    soLuongTreEm,
    soLuongNgLon,
    bgHanhTrinhs,
    opDichVuId,
    onReload,
}) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [dataCT, setDataCT] = useState([]);
    const [openNH, setOpenNh] = useState(false);
    const [openKs, setOpenKs] = useState(false);
    const [openOto, setOpenOto] = useState(false);
    const [dataDichVu, setDataDichVu] = useState([]);
    const [ngay, setNgay] = useState(0);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [tranxOpSaleRateId, setTranxOpSaleRateId] = useState(0);
    const [tuNgay, setTuNgay] = useState("");
    const [denNgay, setDenNgay] = useState("");
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [dataDoTuoi, setDataDoTuoi] = useState([]);
    const [kieuPhong, setKieuPhong] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [dmSoCho, setDmSoCho] = useState([]);
    const [soNgay, setSoNgay] = useState(0);
    useEffect(() => {
        if (dataSource) setDataCT(dataSource);
        if (dataSource.dichVuNguoiLons?.length > 0) setSoNgay(dataSource.dichVuNguoiLons?.length);
    }, [dataSource]);
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataDotTuoi = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataDotTuoi.push({
                                value: data[i].id,
                                label: data[i].do_tuoi,
                            });
                        }
                    }
                    setDataDoTuoi(dataDotTuoi);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataBA = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataBA.push({
                                value: data[i].id,
                                label: data[i].ten_bua_an,
                            });
                        }
                    }
                    setDmBuaAn(dataBA);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function async(response) {
                if (response.status === 200) {
                    let dataDV = [];
                    let data = response.data.data;
                    if (data?.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    id: data[i].id,
                                    value: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        getDmDoTuoi();
        getDmVat();
    }, []);
    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toLocaleDateString("en-GB");
    };

    const handleSetTuNgayAndDenNgay = (dateArr, days) => {
        let date = dateArr[1] + "/" + dateArr[0] + "/" + dateArr[2];
        let denNgay = addDays(date, days);
        // setTuNgay(ngayKhoiHanh);
        setDenNgay(denNgay);
    };

    const getDmVat = () => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmDoTuoi = () => {
        dm_gia_ap_dung
            .getDmDoTuoi()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };
    const columns_date = [
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("supplier") + " (Sale)",
        },
        {
            title: t("service"),
        },
        {
            title: t("luaChon"),
        },
        {
            title: t("hotel.roomClass.conditionFoc"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.intoMoney"),
        },
        {
            title: t("thuevat"),
        },
        {
            title: t("supplier") + " (OP)",
        },
        {
            title: "OP Check",
        },
    ];
    const handleAddRowServices = (dataS, index, id, item) => {
        let dt = [];
        let crrFirstDV = dataS.findIndex((it) => it.rowSpanDV !== 0 && it.loaiDichVu === item.loaiDichVu);
        setEditingId(crrFirstDV + "" + id);
        let rowSpanDV = dataS[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataS.length; key++) {
            // debugger;
            const element = dataS[key];
            if (key === crrFirstDV) {
                dt.push({
                    id: Math.random() + "",
                    rowSpan: 1,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    rowSpanDV: rowSpanDV,
                    loaiDichVu: item.loaiDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    tenNhaCungCap: "",
                    dieuKienFocNh: "",
                    focNh: "",
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });
                element.rowSpanDV = 0;
            } else {
            }
            dt.push(element);
        }
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = dt;
                }
                return ct;
            })
        );
    };
    const handleAddBySerivce = (dataTableNguoiLon, index, id, item) => {
        let dt = [];
        let crrFirst = dataTableNguoiLon.findIndex(
            (it) => it.rowSpan !== 0 && it.tenNhaCungCap === item.tenNhaCungCap && it.loaiDichVu === item.loaiDichVu
        );
        setEditingId(crrFirst + "" + id);
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpan !== 0 && it.loaiDichVu === item.loaiDichVu);
        let rowSpan = dataTableNguoiLon[crrFirst].rowSpan + 1;
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirst) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    loaiDichVu: item.loaiDichVu,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    tenNhaCungCap: item.tenNhaCungCap,
                    bgCTSaleId: item.bgCTSaleId,
                    dieuKienFocNh: item.dieuKienFocNh,
                    focNh: item.focNh,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    rowSpanDV: 0,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }

            if (
                element.rowSpan !== 0 &&
                element.tenNhaCungCap === item.tenNhaCungCap &&
                element.loaiDichVu === item.tenDichV &&
                key > crrFirst
            ) {
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }
            dt.push(element);
        }
        dt[crrFirstDV].rowSpanDV = rowSpanDV;
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = dt;
                }
                return ct;
            })
        );
    };
    const handleChangedieuKienFocNh = (dataS, index, value, id, loaiDichVu) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) item.dieuKienFocNh = value;
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeTenNCC = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index) item.tenNhaCungCap = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleClearNCC = (id) => {
        DieuHanhService.xoaNhaCungCap({
            tranxOpSaleRateId: id,
        })
            .then((response) => {
                if (response.data.code === "00") {
                    showNoti("success", response.data.message);
                } else {
                    showNoti("error", response.data.message);
                }
            })
            .catch((error) => {
                showNoti("error", error.message);
            });
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };
    const handleChangeDichVu = (dataS, index, value, id, loaiDichVu) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (loaiDichVu === "Xe ô tô" && key === index) item.soXe = value;
            if (!loaiDichVu && key === index) item.dichVu = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };

    const handleClear = () => {
        handleReload();
    };
    const handleDelete = (key, value) => {};
    const tinhToanThanhTable = (data) => {
        // Create a map to store the calculated values for each service provider
        const calculatedValuesMap = new Map();

        // Loop through the data to calculate and store the values
        data.forEach((element) => {
            // Check if the ID contains "NULL"
            if (element.id.indexOf("NULL") === -1) {
                let thanhTien;
                // Check the service type
                switch (element.loaiDichVu) {
                    case "Nhà hàng":
                        // Calculate the value using the provided function
                        thanhTien = tinhThanhTienNH(
                            element.dieuKienFocNh,
                            element.donGia,
                            element.focNh,
                            element.soLuong
                        );
                        break;
                    case "Xe ô tô":
                        // Calculate the value directly
                        thanhTien = element.soLuong * element.donGia;
                        break;
                    default:
                        let dataKS;
                        // Check if the value has already been calculated for the service provider
                        if (!calculatedValuesMap.has(element.tenNhaCungCap)) {
                            // If not calculated, filter the data for the same service provider and service type
                            dataKS = data.filter(
                                (it) =>
                                    it.loaiDichVu === element.loaiDichVu && it.tenNhaCungCap === element.tenNhaCungCap
                            );
                            // Calculate the values using the provided function
                            const thanhTienKS = tinhThanhTienKS(
                                dataKS.map((it) => it.soLuong),
                                dataKS.map((it) => it.donGia),
                                Math.floor(element.dieuKienFocNh / element.focNh)
                            );
                            // Store the calculated values in the map
                            calculatedValuesMap.set(element.tenNhaCungCap, thanhTienKS);
                        }
                        // Check if dataKS is defined before accessing its properties
                        if (dataKS) {
                            // Retrieve the calculated values from the map
                            thanhTien = calculatedValuesMap.get(element.tenNhaCungCap)[
                                dataKS.findIndex((it) => it.id === element.id)
                            ];
                        }
                        break;
                }
                // Assign the calculated value to the element
                element.thanhTien = thanhTien;
            }
        });
        return data;
    };
    const tinhThanhTienNH = (numberOfRates, unitPrice, FOCConditions, countOfService) => {
        // debugger;
        // Calculate FOC quantity
        const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

        // Calculate total service cost
        const totalServiceCost = countOfService * unitPrice;

        // Calculate service cost after applying FOC policy
        return totalServiceCost - FOCQuantity ? FOCQuantity : 0 * unitPrice;
    };
    const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
        //FOCConditions dk focNh
        //numberOfRoomsBooked: số lượng
        //unitPrices đơn giá
        // Define variables
        const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce((acc, val) => acc + val, 0);
        // Calculate B5
        const lowestRoomCategoryPrice = Math.min(...unitPrices);
        const averageReducedPricePerRoom =
            (lowestRoomCategoryPrice * Math.floor(tongSoLuongPhongNCC / FOCConditions)) / tongSoLuongPhongNCC;

        // Calculate B6
        return numberOfRoomsBooked.map(
            (count, index) => count * unitPrices[index] - averageReducedPricePerRoom * count
        );
    };
    const handleChangeDmDichVuId = (dataS, index, value, id, loaiDichVu) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index && loaiDichVu === "Khách sạn") item.dmKieuPhongId = value;
            if (key === index && loaiDichVu === "Nhà hàng") item.dmBuaAnId = value;
            if (key === index && loaiDichVu === "Xe ô tô") item.dmSoChoId = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeVat = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index) item.vat = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeSoLuong = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) {
                    item.soLuong = value;
                }
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeFoc = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) item.focNh = value;
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeDonGia = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) {
                    item.donGia = value;
                }
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleSave = (dataTableNguoiLon, index, value, loaiDichVu) => {};
    const handleReload = () => {
        setEditingId(null);
        // setReload(true);
    };
    const columns_table_te = [
        {
            title: "STT",
            visible: true,
        },
        {
            title: t("typeService"),
            visible: true,
        },
        {
            title: t("supplier") + " (Sale)",
            visible: true,
        },
        {
            title: t("service"),
            visible: true,
        },
        {
            title: t("age") + " " + t("children"),
            visible: true,
        },
        {
            title: t("tour.percentage"),
            visible: true,
        },
        {
            title: t("tour.donGia"),
            visible: true,
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            visible: true,
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            visible: true,
        },
        {
            title: t("thuevat"),
            visible: true,
        },
        {
            title: t("supplier") + " (OP)",
            visible: true,
        },
    ];
    const handleAddAge = (dataTE, index, id, row) => {
        let indexAdd = dataTE.findIndex((it) => it.id === row.id);
        let dataRowAdd = { ...dataTE[indexAdd] };
        dataTE[indexAdd].rowSpanLoaiDV = 0;
        dataTE[indexAdd].rowSpanNcc = 0;
        dataTE[indexAdd].rowSpanDV = 0;

        dataTE.splice(indexAdd, 0, {
            id: Math.random() + "",
            rowSpanLoaiDV: 0,
            rowSpanNcc: 0,
            rowSpanDV: 0,
            loaiDichVu: row.loaiDichVu,
            tenNhaCungCap: row.tenNhaCungCap,
            // loaiDichVu: row.loaiDichVu,
            donGia: row.donGia,
            bgCTSaleId: row.bgCTSaleId,
            dmDichVuId: row.dmDichVuId,
            mapBgChietTinhKsTreEmId: row.mapBgChietTinhKsTreEmId,
            mapBgChietTinhNhTreEmId: row.mapBgChietTinhNhTreEmId,
            mapBgChietTinhOtoTreEmId: row.mapBgChietTinhOtoTreEmId,
            tranxBgChietTinhKsId: row.tranxBgChietTinhKsId,
            tranxBgChietTinhNhId: row.tranxBgChietTinhNhId,
            tranxBgChietTinhOtoId: row.tranxBgChietTinhOtoId,
            phanTram: 0,
            soLuong: 0,
            dmDoTuoiId: 1,
            dmVatId: 1,
        });

        let crrFirstLoaiDichVu = dataTE.findIndex((it) => it.loaiDichVu === row.loaiDichVu);
        let crrFirstNcc = dataTE.findIndex(
            (it) => it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
        );
        let crrFirstDV = dataTE.findIndex(
            (it) =>
                it.rowSpanDV !== 0 &&
                it.loaiDichVu === row.loaiDichVu &&
                it.tenNhaCungCap === row.tenNhaCungCap &&
                it.loaiDichVu === row.loaiDichVu
        );
        if (crrFirstLoaiDichVu !== -1) {
            dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV += dataRowAdd.rowSpanLoaiDV + 1;
            // dataTE[crrFirstLoaiDichVu].stt = dataRowAdd.stt;
        }
        if (crrFirstNcc !== -1) {
            dataTE[crrFirstNcc].rowSpanNcc += 1;
        } else {
            dataTE[indexAdd].rowSpanNcc += dataRowAdd.rowSpanNcc + 1;
            // dataTE[indexAdd].rowSpanNcc += 2;
        }
        if (crrFirstDV !== -1) {
            dataTE[crrFirstDV].rowSpanDV += 1;
        } else {
            dataTE[indexAdd].rowSpanDV += 2;
        }

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableTE = dataTE;
                }
                return ct;
            })
        );
    };

    const handleChangItemTE = (dataTE, id, row, e) => {
        let indexChange = dataTE.findIndex((it) => it.id === row.id);
        let name = e.target.name;
        let value = e.target.value;
        dataTE[indexChange][name] = value;
        if (name !== "dmDoTuoiId") {
            dataTE[indexChange].thanhTien =
                (dataTE[indexChange].phanTram / 100) * dataTE[indexChange].soLuong * dataTE[indexChange].donGia;
        }
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableTE = dataTE;
                }
                return ct;
            })
        );
    };
    const table_html = () => {
        return (
            <>
                {dataCT?.dichVuNguoiLons?.map((item) => {
                    return (
                        <>
                            <Collapse
                                defaultActiveKey="1"
                                items={[
                                    {
                                        key: "1",
                                        label: <div style={style.h4}>{item.titleNgay}</div>,
                                        children: children(item.loaiDichVus, item.ngayOrder),
                                    },
                                ]}
                            />
                        </>
                    );
                })}
            </>
        );
    };
    const handleSaleCheck = (id, buttonCheck) => {
        DieuHanhService.saleCheck({
            tranxOpSaleRateId: id,
            buttonCheck: buttonCheck, // view_check: đã check, op_check: chuyển op
        })
            .then((response) => {
                console.log(response);
                if (response.data.code === "00") {
                    showNoti("success", response.data.message);
                } else {
                    showNoti("error", response.data.message);
                }
            })
            .catch((error) => {
                showNoti("error", error.message);
            });
    };
    let formatter = new Intl.NumberFormat("en-US", {});
    const children = (loaiDichVus, ngay) => {
        debugger;
        return (
            <>
                <table>
                    <thead
                        style={{
                            backgroundColor: "#E2E2E2",
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        {columns_date.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {loaiDichVus?.map((loaiDv, key) => {
                            let row = [];
                            if (loaiDv.dichVus.length > 0)
                                row = loaiDv.dichVus.map((dv) => {
                                    if (dv.dichVuDetails) {
                                        return dv.dichVuDetails.map((detail, index) => {
                                            return (
                                                <>
                                                    <tr key={index + "" + key}>
                                                        {index === 0 ? (
                                                            <>
                                                                <td rowSpan={dv.dichVuDetails.length}>{key + 1}</td>
                                                                <td rowSpan={dv.dichVuDetails.length}>
                                                                    {loaiDv.tenLoaiDichVu}
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <td>{dv.tenNcc}</td>
                                                        <td>{detail.tenDichVu}</td>
                                                        <td>{detail.luaChon}</td>
                                                        <td>
                                                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                                {loaiDv.tenLoaiDichVu === "Xe ô tô" ? (
                                                                    <></>
                                                                ) : (
                                                                    <>
                                                                        {detail.dieuKienFocNh}
                                                                        {loaiDv.tenLoaiDichVu === "Khách sạn"
                                                                            ? " " + t("room")
                                                                            : " " + t("set")}
                                                                        {"/ " + detail.focNh}
                                                                        {t("hotel.roomClass.foc")}
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </td>
                                                        <td>{formatter.format(detail.donGia)}</td>
                                                        <td>{detail.soLuong}</td>
                                                        <td>{formatter.format(detail.thanhTien)}</td>
                                                        <td>{detail.vatStr}</td>
                                                        <td>
                                                            <TextField
                                                                disabled
                                                                size="small"
                                                                value={detail.opTenNcc}
                                                                // onChange={(e) =>
                                                                //     handleChangeThanhTien(dichVus, key, e.target.value, id)
                                                                // }
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton size="small">
                                                                                <EditFilled
                                                                                    onClick={() =>
                                                                                        handleAddServicesDv(
                                                                                            dv,
                                                                                            loaiDv.loaiDichVuId,
                                                                                            ngay
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                size="small"
                                                                                // disabled={
                                                                                //     !item.nccThongTinChungId
                                                                                //         ? true
                                                                                //         : false
                                                                                // }
                                                                                onClick={() =>
                                                                                    handleClearNCC(dv.tranxOpSaleRateId)
                                                                                }
                                                                            >
                                                                                <CloseCircleFilled />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleSaleCheck(
                                                                            dv.tranxOpSaleRateId,
                                                                            "view_check"
                                                                        )
                                                                    }
                                                                >
                                                                    <Visibility />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleSaleCheck(
                                                                            dv.tranxOpSaleRateId,
                                                                            "view_check"
                                                                        )
                                                                    }
                                                                    disabled={detail.opCheck}
                                                                >
                                                                    <Check />
                                                                </IconButton>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        });
                                    } else {
                                        return <tr key={key}></tr>;
                                    }
                                });

                            return row;
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleAddServicesDv = (item, loaiDichVuId, ngay) => {
        if (item.tranxOpSaleRateId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setNgay(ngay);
        setTranxOpSaleRateId(item.tranxOpSaleRateId);
        switch (loaiDichVuId) {
            case 1:
                setOpenKs(true);
                break;
            case 2:
                setOpenOto(true);
                break;
            case 3:
                setOpenNh(true);
                break;
            default:
                return null;
        }
    };
    const table_html_te = () => {
        return (
            <>
                {dataCT?.dichVuTreEms?.map((item) => {
                    return (
                        <Collapse
                            defaultActiveKey={item.baoGiaHanhTrinhId}
                            items={[
                                {
                                    key: "1",
                                    label: <div style={style.h4}>{item.titleNgay}</div>,
                                    children: childrenTE(
                                        item.loaiDichVus,
                                        item.id,
                                        item.baoGiaHanhTrinhId,
                                        item.ngayId
                                    ),
                                },
                            ]}
                        />
                    );
                })}
            </>
        );
    };

    const childrenTE = (dataTE, id, baoGiaHanhTrinhId, ngay) => {
        let stt = 1;
        return (
            <>
                <table>
                    <thead
                        style={{
                            backgroundColor: "#E2E2E2",
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        {columns_table_te
                            .filter((it) => it.visible)
                            .map((cl) => {
                                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                            })}
                    </thead>
                    <tbody>
                        {dataTE?.map((loaiDv, key) => {
                            let row = [];
                            if (loaiDv.dichVuTreEms.length > 0) {
                                let rowSpan = loaiDv.dichVuTreEms.reduce((sum, item) => {
                                    sum += item.dichVuDetails.length;
                                    return sum;
                                }, 0);
                                row = loaiDv.dichVuTreEms.map((dv, dvKey) => {
                                    if (dv.dichVuDetails) {
                                        return dv.dichVuDetails.map((detail, index) => {
                                            return (
                                                <>
                                                    <tr key={index + "" + key}>
                                                        {dvKey === 0 && index === 0 ? (
                                                            <>
                                                                <td rowSpan={rowSpan}>{key + 1}</td>
                                                                <td rowSpan={rowSpan}>{loaiDv.tenLoaiDichVu}</td>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {index === 0 ? (
                                                            <>
                                                                <td rowSpan={dv.dichVuDetails.length}>{dv.tenNcc}</td>
                                                                <td rowSpan={dv.dichVuDetails.length}>
                                                                    {dv.tenDichVu}
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <td>{detail.doTuoi}</td>
                                                        <td>{detail.phanTram}</td>
                                                        <td>{formatter.format(detail.donGia)}</td>
                                                        <td>{detail.soLuong}</td>
                                                        <td>{formatter.format(detail.thanhTien)}</td>
                                                        <td>{detail.vatStr}</td>
                                                        <td>{detail.opTenNcc}</td>
                                                    </tr>
                                                </>
                                            );
                                        });
                                    } else {
                                        return <tr key={key}></tr>;
                                    }
                                });
                            }
                            return row;
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleCancelKS = () => {
        setOpenKs(false);
    };

    const handleCancelOto = () => {
        setOpenOto(false);
    };

    const handleCancelNh = () => {
        setOpenNh(false);
    };
    return (
        <>
            <Row>
                <Collapse
                    defaultActiveKey="1"
                    items={[
                        {
                            key: "1",
                            label: <div style={style.h4}>{t("adultService")}</div>,
                            children: table_html(),
                        },
                    ]}
                />
            </Row>
            <Row>
                <Collapse
                    defaultActiveKey="1"
                    items={[
                        {
                            key: "1",
                            label: <div style={style.h4}>{t("childrenService")}</div>,
                            children: table_html_te(),
                        },
                    ]}
                />
            </Row>
            <ModalCapNhatKS
                isOpen={openKs}
                onReset={handleReload}
                handleCancel={handleCancelKS}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                soLuongTreEmBG={soLuongTreEm}
                tranxOpSaleRateId={tranxOpSaleRateId}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={ngay}
                bgHanhTrinhs={bgHanhTrinhs}
                opDichVuId={opDichVuId}
            />
            <ModalCapNhatOto
                isOpen={openOto}
                onReset={handleReload}
                handleCancel={handleCancelOto}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                soLuongTreEmBG={soLuongTreEm}
                soLuongNgLonBG={soLuongNgLon}
                tranxOpSaleRateId={tranxOpSaleRateId}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={ngay}
                bgHanhTrinhs={bgHanhTrinhs}
                opDichVuId={opDichVuId}
            />
            <ModalCapNhatNhaHang
                isOpen={openNH}
                onReset={handleReload}
                handleCancel={handleCancelNh}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                tranxOpSaleRateId={tranxOpSaleRateId}
                soLuongTreEmBG={soLuongTreEm}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={ngay}
                bgHanhTrinhs={bgHanhTrinhs}
                opDichVuId={opDichVuId}
            />
        </>
    );
};
export default ViewDate;
