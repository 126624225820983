import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import Buttons from "@mui/material/Button";
import {
  Col,
  Divider,
  Modal,
  Row,
  Space,
  notification,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import khachSanService from "../../../../api/bao_gia/chiet_tinh_op/ks/khach_san_service";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccKhachSan from "./ModalThongTinNccKhachSan";
import chiet_tinh_op from "../../../../api/bao_gia/chiet_tinh_op/chiet_tinh_op";

const ModalCapNhatKS = ({
  isOpen,
  onReset,
  handleCancel,
  dataNhomNgLon,
  dataNhomTreEm,
  baoGiaHanhTrinhId,
  baoGiaTongQuanId,
  bgChietTinhOPId,
  dmDichVuId,
  bgMapChietTinhOpHtId,
  soLuongTreEmBG,
  hanhDong,
  soNgay,
  ngay,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [dmGia, setDmGia] = useState([]);
  const [isShowNccKS, setIsShowNccKS] = useState(false);
  const { t } = useTranslation();
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [dataSource, setDataSource] = useState({
    tenNcc: "",
    dmGiaApDungId: 0,
    dmVatId: 1,
    dieuKienFoc: 0,
    foc: 0,
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    tenNcc: "",
    dmGiaApDungId: 0,
    dmVatId: 1,
    dieuKienFoc: 0,
    foc: 0,
  });
  const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
  const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
  const [dichVuTreEms, setDichVuTreEms] = useState([]);
  const [dichVuTreEmsInit, setDichVuTreEmsInit] = useState([]);
  const [action, setAction] = useState("");
  const [idxEdit, setIdxEdit] = useState(0);
  const [dmVat, setDmVat] = useState([]);
  const [dmDoTuoi, setDmDoTuoi] = useState([]);
  const [countDichVu, setCountDichVu] = useState(0);
  const [bgNhomNguoiLonId, setBgNhomNguoiLonId] = useState(0);
  const [bgNhomNguoiLonIdInit, setBgNhomNguoiLonIdInit] = useState(0);
  const [reset, setReset] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [listNgay, setListNgay] = useState([]);
  const [maNcc, setMaNcc] = useState("");
  const prevCountDichVu = useRef();
  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmVat();
    getDmDoTuoi();
  }, []);

  useEffect(() => {
    genNgay(soNgay);
  }, [soNgay]);

  useEffect(() => {
    prevCountDichVu.current = countDichVu;
  }, [countDichVu]);

  useEffect(() => {
    if (hanhDong === "EDIT") {
      getKhachSanOp(bgMapChietTinhOpHtId);
    }
  }, [hanhDong, bgMapChietTinhOpHtId]);

  useEffect(() => {
    if (hanhDong === "ADD") {
      setDichVus([{ id: "NULL" }]);
      setDichVusInit([{ id: "NULL" }]);
      setDataSource({
        tenNcc: "",
        dmGiaApDungId: 0,
        dmVatId: 1,
        dieuKienFoc: 0,
        foc: 0,
        moTa: "",
      });
      setDataSourceInit({
        tenNcc: "",
        dmGiaApDungId: 0,
        dmVatId: 1,
        dieuKienFoc: 0,
        foc: 0,
        moTa: "",
      });
      setDichVuTreEms([]);
      setDichVuTreEmsInit([]);
      setBgNhomNguoiLonId(0);
      setBgNhomNguoiLonIdInit(0);
    }
  }, [isOpen]);

  const genNgay = (soNgay) => {
    let lstNgay = [];
    for (let index = 0; index < soNgay; index++) {
      lstNgay.push({ ngay: index + 1 });
    }
    setListNgay(lstNgay);
  };

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const chiPhiTreEm = dichVuTreEms.reduce((sum, item) => {
    if (item.id !== "NULL") sum += parseFloat(item.thanhTien);
    return sum;
  }, 0);

  const chiPhiNguoiLon = dichVus.reduce((sum, item) => {
    if (item.id !== "NULL") sum += parseFloat(item.tong);
    return sum;
  }, 0);

  const tongChiPhi = chiPhiTreEm + chiPhiNguoiLon;

  const getKhachSanOp = (bgMapChietTinhOpHtId) => {
    let data_api = {
      bgMapChietTinhOpHtId: bgMapChietTinhOpHtId,
    };
    khachSanService
      .getKhachSanOp(data_api)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let ds = {
            nccThongTinChungId: data.nccThongTinChungId,
            tenNcc: data.tenNhaCungCap,
            dmGiaApDungId: data.dmGiaApDungId,
            dmLoaiApDung: data.dmLoaiApDungId,
            dmVatId: data.dmVatId,
            dieuKienFoc: data.dieuKienFoc,
            foc: data.foc,
            moTa: data.moTa,
          };
          let lstDichVu = data.dichVus.map((it, idx) => {
            it.id = idx + 1;
            it.giaNet = it.giaDichVuPhongDoi;
            it.tong =
              it.giaNet * (it.soLuongPhongDoi - data.dieuKienFoc) +
              it.giaExtra * it.soLuongExtra +
              it.giaPhongDon * it.soLuongPhongDon;
            return it;
          });
          setBgNhomNguoiLonId(data?.dichVus[0].bgNhomNguoiLonId);
          setBgNhomNguoiLonIdInit(data?.dichVus[0].bgNhomNguoiLonId);
          lstDichVu.push({ id: "NULL" });
          let groupDichVu = Object.groupBy(
            data.dichVus,
            ({ tranxGiaPhongId }) => tranxGiaPhongId
          );
          let keyGroupDichVu = Object.keys(groupDichVu);
          if (
            data.dichVuTreEms &&
            data.dichVuTreEms.length ===
              data.dichVuTreEms.filter((it) => it.apDung).length
          )
            setCheckAll(true);
          else setCheckAll(false);

          let idx = 0;
          let dichVuTEs = [];
          let idxCheckRowSpanDV = 1;
          let idxCheckRowSpanTE = 1;
          //Loop by dich vu
          for (let index = 0; index < keyGroupDichVu.length; index++) {
            let tranxGiaPhongId = keyGroupDichVu[index];
            let lstTreEm = data.dichVuTreEms.filter(
              (it) => it.tranxGiaPhongId === parseInt(tranxGiaPhongId)
            );
            let groupDichVuTreEm = Object.groupBy(
              lstTreEm,
              ({ bgNhomTreEmId }) => bgNhomTreEmId
            );
            let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
            // loop by group tre em
            for (let k = 0; k < keyGroupDichVuTreEm.length; k++) {
              let bgNhomTreEmId = keyGroupDichVuTreEm[k];
              let dichVuTEByGroup = lstTreEm.filter(
                (it) => it.bgNhomTreEmId === parseInt(bgNhomTreEmId)
              );
              let data = dichVuTEByGroup.map((it) => {
                idx++;
                it.id = idx;
                it.tenNhomTE = it.tenNhomTreEm;
                it.giaNet = it.giaDichVuTreEm;
                it.thanhTien = (it.phanTram / 100) * it.soLuong * it.giaNet;
                it.rowSpanDV =
                  idx === idxCheckRowSpanDV
                    ? lstTreEm[0].bgNhomTreEmId !== 0
                      ? lstTreEm.length
                      : lstTreEm.length + 1
                    : 0;
                it.rowSpanTE =
                  idx === idxCheckRowSpanTE
                    ? lstTreEm[0].bgNhomTreEmId !== 0
                      ? dichVuTEByGroup.length
                      : 0
                    : 0;
                return it;
              });
              idxCheckRowSpanTE += dichVuTEByGroup.length;

              dichVuTEs = [...dichVuTEs, ...data];
            }
            if (!lstTreEm[0] || lstTreEm[0]?.bgNhomTreEmId === 0) {
              dichVuTEs.push({
                id: "addNew",
                tenDichVu: groupDichVu[tranxGiaPhongId][0].tenDichVu,
                giaNet: groupDichVu[tranxGiaPhongId][0].giaDichVu,
                nccHangPhongId: groupDichVu[tranxGiaPhongId][0].nccHangPhongId,
                tranxGiaPhongId: tranxGiaPhongId,
                rowSpanDV: !lstTreEm[0] ? 1 : 0,
              });
            }
            idxCheckRowSpanDV += dichVuTEs.length;
          }

          setCountDichVu(keyGroupDichVu.length);
          setDichVus(lstDichVu);
          setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
          setDataSource(ds);
          setDataSourceInit({ ...ds });
          setDichVuTreEms(dichVuTEs);
          setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTEs)));
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };
  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmVat = () => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmDoTuoi = () => {
    dm_gia_ap_dung
      .getDmDoTuoi()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmDoTuoi(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleChangeValueDataSoure = (e) => {
    let name = e.target.value;
    if (e.target.value < 0 && (name === "foc" || name === "dieuKienFoc"))
      dataSource[name] = e.target.value;
    else dataSource[e.target.name] = e.target.value;
    setReset(!reset);
  };

  const handleChangeEleDichVu = (id, event) => {
    let index = dichVus.findIndex((item) => item.id === id);
    let name = event.target.name;
    dichVus[index][name] = event.target.value;
    if (event.target.value < 0) dichVus[index][name] = 0;
    dichVus[index].tong =
      dichVus[index].giaNet *
        (dichVus[index].soLuongPhongDoi - dataSource.dieuKienFoc) +
      dichVus[index].giaExtra * dichVus[index].soLuongExtra +
      dichVus[index].giaPhongDon * dichVus[index].soLuongPhongDon;
    setDichVus([...dichVus]);
  };

  const handleChangeEleDichVuTreEm = (id, event, type) => {
    let dichVus = [];
    let dichVuApDung = [];
    if (type === "ALL") {
      dichVuApDung = dichVuTreEms.filter(
        (item) => item.id !== "addNew" && item.apDung
      );
      dichVus = dichVuTreEms.filter((item) => item.id !== "addNew");
      if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length) {
        dichVus = dichVuTreEms.map((it) => {
          if (it.id !== "addNew") it.apDung = false;
          return it;
        });
        setCheckAll(false);
      } else {
        dichVus = dichVuTreEms.map((it) => {
          if (it.id !== "addNew") it.apDung = true;
          return it;
        });
        setCheckAll(true);
      }
      setDichVuTreEms(dichVus);
    } else {
      let index = dichVuTreEms.findIndex((item) => item.id === id);
      if (event.target.name === "apDung") {
        dichVuTreEms[index][event.target.name] = event.target.checked;
      } else {
        dichVuTreEms[index][event.target.name] = event.target.value;
        if (
          event.target.name === "soLuong" ||
          event.target.name === "phanTram"
        ) {
          if (event.target.value < 0) {
            dichVuTreEms[index][event.target.name] = 0;
          }
          dichVuTreEms[index].thanhTien =
            dichVuTreEms[index].giaNet *
            dichVuTreEms[index].soLuong *
            (dichVuTreEms[index].phanTram / 100);
        }
      }
      dichVuApDung = dichVuTreEms.filter(
        (item) => item.id !== "addNew" && item.apDung
      );
      dichVus = dichVuTreEms.filter((item) => item.id !== "addNew");
      if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length)
        setCheckAll(true);
      else setCheckAll(false);
      setDichVuTreEms([...dichVuTreEms]);
    }
  };

  const handleChangeDataServiceKS = (dataSelected) => {
    // console.log(dataSelected);
    let lstDichVu = [];
    let soTien = 0;
    let foc = 0;
    let dieuKienFoc = 0;
    if (action === "UPDATE_NCC") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = "";
      lstDichVu = dataSelected?.map((item, idx) => {
        if (idx === 0) {
          soTien = dataSelected[idx].fitNgayThuongNet;
          foc = dataSelected[idx].foc;
          dieuKienFoc = dataSelected[idx].dieuKienFoc;
        } else if (dataSelected[idx].fitNgayThuongNet < soTien) {
          soTien = dataSelected[idx].fitNgayThuongNet;
          foc = dataSelected[idx].foc;
          dieuKienFoc = dataSelected[idx].foc;
        }
        item.id = idx + 1;
        item.tenDichVu = dataSelected[idx].hangPhong;
        item.giaNet = dataSelected[idx].fitNgayThuongNet;
        item.soLuongPhongDoi = 0;
        item.giaExtra = dataSelected[idx].extraNgayThuongNet;
        item.soLuongExtra = 0;
        item.giaPhongDon =
          parseInt(dataSelected[idx].fitNgayThuongNet) -
          parseInt(dataSelected[idx].fitNgayThuongNet) /
            parseInt(dataSelected[idx].soKhachToiDa);
        item.soLuongPhongDon = 0;
        item.tong = 0;
        item.bgNhomNgLonId = 0;
        return item;
      });
      lstDichVu.push({ id: "NULL" });
    } else if (action === "ADD_SERVICE") {
      if (dichVus.length === 1) {
        dataSource.tenNcc = dataSelected[0].tenNcc;
        dataSource.dmGiaApDungId = 1;
        dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
        dataSource.dmVatId = dataSelected[0].dmVatId
          ? dataSelected[0].dmVatId
          : 1;
        dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
        dataSource.moTa = "";
        dataSource.tenNcc = dataSelected[0].tenNcc;
        foc = dataSelected[0].foc;
        dieuKienFoc = dataSelected[0].dieuKienFoc;
      }
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu.splice(dichVus.length - 1, 1);
      lstDichVu.push({
        id: dichVus.length,
        nccGiaPhongId: dataSelected[0].nccGiaPhongId,
        nccHangPhongId: dataSelected[0].nccHangPhongId,
        tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
        bgNhomNgLonId: 0,
        kieuPhong: dataSelected[0].kieuPhong,
        tenDichVu: dataSelected[0].hangPhong,
        giaNet: dataSelected[0].fitNgayThuongNet,
        soLuongPhongDoi: 0,
        giaExtra: dataSelected[0].extraNgayThuongNet,
        soLuongExtra: 0,
        giaPhongDon:
          parseInt(dataSelected[0].fitNgayThuongNet) -
          parseInt(dataSelected[0].fitNgayThuongNet) /
            parseInt(dataSelected[0].soKhachToiDa),
        soLuongPhongDon: 0,
        tong: 0,
      });
      lstDichVu.push({ id: "NULL" });
    } else {
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu[idxEdit] = {
        ...dataSelected[0],
        id: idxEdit + 1,
        nccGiaPhongId: dataSelected[0].nccGiaPhongId,
        nccHangPhongId: dataSelected[0].nccHangPhongId,
        tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
        bgNhomNgLonId: 0,
        kieuPhong: dataSelected[0].kieuPhong,
        tenDichVu: dataSelected[0].hangPhong,
        giaNet: dataSelected[0].fitNgayThuongNet,
        soLuongPhongDoi: 0,
        giaExtra: dataSelected[0].extraNgayThuongNet,
        soLuongExtra: 0,
        giaPhongDon:
          parseInt(dataSelected[0].fitNgayThuongNet) -
          parseInt(dataSelected[0].fitNgayThuongNet) /
            parseInt(dataSelected[0].soKhachToiDa),
        soLuongPhongDon: 0,
        tong: 0,
      };
    }
    dataSource.dieuKienFoc = dieuKienFoc;
    dataSource.foc = foc;

    let groupDichVu = Object.groupBy(lstDichVu, ({ tenDichVu }) => tenDichVu);
    let keyGroupDichVu = Object.keys(groupDichVu);
    let nhomTreEms = JSON.parse(JSON.stringify(dataNhomTreEm));
    if (bgNhomNguoiLonId !== 0) {
      nhomTreEms = nhomTreEms.filter(
        (item) => item.nhomNguoiLonId === bgNhomNguoiLonId
      );
    }
    let lenDataTE = nhomTreEms.reduce((num, item) => {
      item.details.forEach(() => {
        num++;
      });
      return num;
    }, 0);

    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    let idx = 0;
    let idxCheckRowSpanTE = 1;
    if (action === "UPDATE_NCC") {
      // setBgNhomNguoiLonId(0);
      lstDichVuTreEm = [];
      let idxCheckRowSpanDV = 1;
      for (let index = 0; index < keyGroupDichVu.length - 1; index++) {
        let key = keyGroupDichVu[index];
        if (dataNhomTreEm.length === 0) {
          lstDichVuTreEm = [
            ...lstDichVuTreEm,
            {
              id: "addNew",
              tenDichVu: key,
              giaNet: dataSelected[0].fitNgayThuongNet,
              nccHangPhongId: dataSelected[0].nccHangPhongId,
              tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
            },
          ];
        } else if (!(bgNhomNguoiLonId === 0 && dataNhomNgLon.length > 0)) {
          for (let index = 0; index < nhomTreEms.length; index++) {
            let element = nhomTreEms[index];
            let dichVuTEByGroup = element.details?.map((item) => {
              idx++;
              return {
                id: idx,
                tenDichVu: key,
                tenNhomTE: element.tenNhom,
                giaNet: groupDichVu[key][0].fitNgayThuongNet,
                soLuong: item.soLuong,
                thanhTien: 0,
                nccHangPhongId: groupDichVu[key][0].nccHangPhongId,
                tranxGiaPhongId: groupDichVu[key][0].tranxGiaPhongId,
                bgNhomTreEmId: element.id,
                dmDoTuoiId: item.dmDoTuoiId,
                phanTram: 0,
                apDung: false,
                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                rowSpanTE:
                  idx === idxCheckRowSpanTE ? element.details.length : 0,
              };
            });
            idxCheckRowSpanTE += element.details.length;
            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
          }
          idxCheckRowSpanDV += lenDataTE;
        }
      }
    } else if (keyGroupDichVu.length > prevCountDichVu.current) {
      if (dataNhomTreEm.length === 0) {
        lstDichVuTreEm = [
          ...lstDichVuTreEm,
          {
            id: "addNew",
            tenDichVu: dataSelected[0].hangPhong,
            giaNet: dataSelected[0].fitNgayThuongNet,
            nccHangPhongId: dataSelected[0].nccHangPhongId,
            tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
          },
        ];
      } else if (!(bgNhomNguoiLonId === 0 && dataNhomNgLon.length > 0)) {
        idx = lenDataTE * (prevCountDichVu.current - 1);
        idxCheckRowSpanTE = lenDataTE * (prevCountDichVu.current - 1) + 1;
        for (let index = 0; index < nhomTreEms.length; index++) {
          let element = nhomTreEms[index];
          let dichVuTEByGroup = element.details?.map((item) => {
            idx++;
            return {
              id: idx,
              tenDichVu: dataSelected[0].hangPhong,
              tenNhomTE: element.tenNhom,
              giaNet: dataSelected[0].fitNgayThuongNet,
              soLuong: item.soLuong,
              thanhTien: 0,
              nccHangPhongId: dataSelected[0].nccHangPhongId,
              tranxGiaPhongId: dataSelected[0].tranxGiaPhongId,
              bgNhomTreEmId: element.id,
              dmDoTuoiId: item.dmDoTuoiId,
              phanTram: 0,
              rowSpanDV:
                idx === lenDataTE * (prevCountDichVu.current - 1) + 1
                  ? lenDataTE
                  : 0,
              rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
            };
          });
          idxCheckRowSpanTE += element.details.length;
          lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
        }
      }
    } else if (keyGroupDichVu.length < prevCountDichVu.current) {
      lstDichVuTreEm = lstDichVuTreEm.filter(
        (item) => item.tenDichVu !== dichVus[idxEdit].tenDichVu
      );
    } else {
      if (
        dataNhomTreEm.length > 0 &&
        !(bgNhomNguoiLonId === 0 && dataNhomNgLon.length > 0)
      ) {
        lstDichVuTreEm = [];
        let idxCheckRowSpanDV = 1;
        for (let index = 0; index < keyGroupDichVu.length - 1; index++) {
          let key = keyGroupDichVu[index];
          for (let index = 0; index < nhomTreEms.length; index++) {
            let element = nhomTreEms[index];
            let dichVuTEByGroup = element.details?.map((item) => {
              idx++;
              return {
                id: idx,
                tenDichVu: key,
                tenNhomTE: element.tenNhom,
                giaNet: groupDichVu[key][0].fitNgayThuongNet,
                soLuong: item.soLuong,
                thanhTien: 0,
                nccHangPhongId: groupDichVu[key][0].nccHangPhongId,
                tranxGiaPhongId: groupDichVu[key][0].tranxGiaPhongId,
                bgNhomTreEmId: element.id,
                dmDoTuoiId: item.dmDoTuoiId,
                phanTram: 0,
                apDung: false,
                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                rowSpanTE:
                  idx === idxCheckRowSpanTE ? element.details.length : 0,
              };
            });
            idxCheckRowSpanTE += element.details.length;
            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
          }
          idxCheckRowSpanDV += lenDataTE;
        }
      }
    }
    if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
    setDichVuTreEms(lstDichVuTreEm);
    setDichVus(lstDichVu);
    setCountDichVu(keyGroupDichVu.length);
    setDataSource(dataSource);
    console.log(dichVuTreEms);
  };

  const handleUpdateNCC = () => {
    setMaNcc("");
    setIsShowNccKS(true);
    setAction("UPDATE_NCC");
  };

  const handleAddService = () => {
    if (dichVus.length === 1) setMaNcc("");
    setIsShowNccKS(true);
    setAction("ADD_SERVICE");
  };

  const handleUpdateService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    setIsShowNccKS(true);
    setAction("UPDATE_SERVICE");
    setIdxEdit(index);
  };

  const handleDeleteServiceKS = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    lstDichVu.splice(index, 1);
    let groupDichVu = Object.groupBy(lstDichVu, ({ tenDichVu }) => tenDichVu);
    let keyGroupDichVu = Object.keys(groupDichVu);

    let lstDichVuTreEm = [];
    if (keyGroupDichVu.length < prevCountDichVu.current) {
      lstDichVuTreEm = dichVuTreEms.filter(
        (item) => item.tenDichVu !== dichVus[index].tenDichVu
      );
    } else {
      lstDichVuTreEm = dichVuTreEms;
    }
    setDichVuTreEms(lstDichVuTreEm);
    setDichVus(lstDichVu);
    setCountDichVu(keyGroupDichVu.length);
  };

  const handleChangeNhomNgLon = (id) => {
    if (dataNhomTreEm.length > 0) {
      let nhomTreEms = dataNhomTreEm.filter(
        (item) => item.nhomNguoiLonId === id
      );
      let lenDataTE = nhomTreEms.reduce((num, item) => {
        item.details.forEach(() => {
          num++;
        });
        return num;
      }, 0);
      let groupDichVu = Object.groupBy(dichVus, ({ tenDichVu }) => tenDichVu);
      let keyGroupDichVu = Object.keys(groupDichVu);
      let idx = 0;
      let idxCheckRowSpanTE = 1;
      let lstDichVuTreEm = [];
      let idxCheckRowSpanDV = 1;
      for (let index = 0; index < keyGroupDichVu.length - 1; index++) {
        let key = keyGroupDichVu[index];
        for (let index = 0; index < nhomTreEms.length; index++) {
          let element = nhomTreEms[index];
          let dichVuTEByGroup = element.details?.map((item) => {
            idx++;
            return {
              id: idx,
              tenDichVu: key,
              tenNhomTE: element.tenNhom,
              giaNet: groupDichVu[key][0].fitNgayThuongNet,
              soLuong: item.soLuong,
              thanhTien: 0,
              nccHangPhongId: groupDichVu[key][0].nccHangPhongId,
              tranxGiaPhongId: groupDichVu[key][0].tranxGiaPhongId,
              bgNhomTreEmId: element.id,
              dmDoTuoiId: item.dmDoTuoiId,
              phanTram: 0,
              apDung: false,
              rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
              rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
            };
          });
          idxCheckRowSpanTE += element.details.length;
          lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
        }
        idxCheckRowSpanDV += lenDataTE;
      }
      setDichVuTreEms(lstDichVuTreEm);
    }
    setBgNhomNguoiLonId(id);
  };

  const handleAddServiceTreEm = (row) => {
    let data = [];
    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    let lstTreEmByGroup = lstDichVuTreEm.filter(
      (item) => item.tenDichVu === row.tenDichVu
    );
    lstDichVuTreEm.forEach((dt, idx) => {
      if (row.tenDichVu === dt.tenDichVu && dt.id === "addNew") {
        dt.id = idx;
        dt.phanTram = 0;
        dt.soLuong = 0;
        dt.thanhTien = 0;
        dt.bgNhomTreEmId = 0;
        dt.dmDoTuoiId = 1;
        dt.apDung = false;
        dt.rowSpanDV = lstTreEmByGroup.length === 1 ? 2 : 0;
        dt.rowSpanTE = 0;
        dt.apDung = false;
        data.push(dt);
        data.push({
          id: "addNew",
          tenDichVu: row.tenDichVu,
          giaNet: row.giaNet,
          nccHangPhongId: row.nccHangPhongId,
          tranxGiaPhongId: row.tranxGiaPhongId,
          rowSpanDV: 0,
        });
      } else {
        if (
          dt.id === lstTreEmByGroup[0].id &&
          dt.tenDichVu === lstTreEmByGroup[0].tenDichVu
        )
          dt.rowSpanDV = lstTreEmByGroup.length + 1;
        data.push(dt);
      }
    });
    setDichVuTreEms(data);
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = getDataReq();
      console.log(dataReq);
      console.log(JSON.stringify(dataReq));
      //   chiet_tinh_op
      //     .saveChietTinhOpKS(dataReq)
      //     .then((response) => {
      //       let action = "add";
      //       if (bgMapChietTinhOpHtId !== 0) action = "edit";
      //       if (response.status === 200 && response.data.code === "00") {
      //         showNoti(
      //           "success",
      //           t(action) + " " + t("noti.success").toLowerCase()
      //         );
      //         setDataSourceInit({ ...dataSource });
      //         setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
      //         setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTreEms)));
      //         setBgNhomNguoiLonIdInit(bgNhomNguoiLonId);
      //         handleCancel();
      //         onReset();
      //       } else {
      //         showNoti("error", response.data.message);
      //       }
      //     })
      //     .catch(() => {
      //       showNoti("error", t("system.error"));
      //     });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const getDataReq = () => {
    let dataReq = {
      ngay: ngay,
      baoGiaHanhTrinhId: baoGiaHanhTrinhId,
      baoGiaTongQuanId: baoGiaTongQuanId,
      dmDichVuId: dmDichVuId,
      bgChietTinhOpId: bgChietTinhOPId,
      bgMapChietTinhOpHtId: bgMapChietTinhOpHtId ? bgMapChietTinhOpHtId : 0, //thêm mới, >0 chỉnh sửa
      nccThongTinChungId: dataSource.nccThongTinChungId,
      dmGiaApDungId: dataSource.dmGiaApDungId,
      dmVatId: dataSource.dmVatId,
      dmTyGiaId: dataSource.dmTyGiaId,
      dieuKienFoc: dataSource.dieuKienFoc,
      foc: dataSource.foc,
      moTa: dataSource.moTa,
      dichVus: dichVus
        .filter((dt) => dt.id !== "NULL")
        .map((it) => {
          handleGroupDichVuTreEm(it.nccHangPhongId, it.tranxGiaPhongId);
          return {
            nccHangPhongId: it.nccHangPhongId,
            nccGiaPhongId: it.nccGiaPhongId,
            tranxGiaPhongId: it.tranxGiaPhongId,
            bgNhomNguoiLonId: bgNhomNguoiLonId,
            soLuongPhongDoi: it.soLuongPhongDoi,
            soLuongExtra: it.soLuongExtra,
            soLuongPhongDon: it.soLuongPhongDon,
          };
        }),
      dichVuTreEms: dichVuTreEms
        .filter((dt) => dt.id !== "addNew")
        .map((it) => {
          return {
            nccHangPhongId: it.nccHangPhongId,
            tranxGiaPhongId: it.tranxGiaPhongId,
            bgNhomTreEmId: it.bgNhomTreEmId,
            dmDoTuoiId: it.dmDoTuoiId,
            phanTram: it.phanTram,
            soLuong: it.soLuong,
            apDung: it.apDung,
          };
        }),
    };
    return dataReq;
  };

  const handleGroupDichVuTreEm = (nccHangPhongId, tranxGiaPhongId) => {
    let lstDichVuTreEm = dichVuTreEms.filter(
      (it) =>
        it.id !== "addNew" &&
        it.nccHangPhongId === nccHangPhongId &&
        it.tranxGiaPhongId === tranxGiaPhongId
    );
    let groupDichVuTreEm = Object.groupBy(
      lstDichVuTreEm,
      ({ bgNhomTreEmId }) => bgNhomTreEmId
    );
    let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
    let dichVuTEs = keyGroupDichVuTreEm.map((key) => {
      return {
        bgNhomTreEmId: key,
        details: groupDichVuTreEm[key]?.map((it) => {
          return {
            dmDoTuoiId: it.dmDoTuoiId,
            phanTram: it.phanTram,
            donGiaNhapTay: 0,
            soLuong: it.soLuong,
            dmVatId: dataSource.dmVatId,
            apDung: it.apDung,
          };
        }),
      };
    });
    console.log(groupDichVuTreEm);
    console.log(dichVuTEs);
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    let soLuongTE = dichVuTreEms.reduce((sum, item) => {
      if (item.id !== "addNew") sum += parseInt(item.soLuong);
      return sum;
    }, 0);
    if (soLuongTE > soLuongTreEmBG) {
      result.check = false;
      result.message = t("noti.errorSoLuongTE");
    } else if (dataNhomNgLon.length > 0 && bgNhomNguoiLonId === 0) {
      result.check = false;
      result.message = t("noti.errorNhomNgLon");
    }
    return result;
  };

  const handleClear = () => {
    // setBgNhomNguoiLonId(0);
    setMaNcc("");
    setDataSource({ ...dataSourceInit });
    setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
    setDichVuTreEms(JSON.parse(JSON.stringify(dichVuTreEmsInit)));
    setBgNhomNguoiLonId(bgNhomNguoiLonIdInit);
  };

  const handleCloseModal = () => {
    handleCancel();
    handleClear();
  };

  const onIsShow = () => {
    setIsShowNccKS(false);
  };

  const columnDichVus = [
    {
      title: t("service"),
      dataIndex: "tenDichVu",
      key: "tenDichVu",
      width: "15%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link onClick={() => handleAddService()} underline="none">
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 8,
            },
          };
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={record.tenDichVu}
            InputProps={{
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateService(record)}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleDeleteServiceKS(record)}
                  />
                </>
              ),
            }}
          />
        );
      },
    },
    {
      title: t("hotel.priceClass.typeRoom"),
      dataIndex: "kieuPhong",
      align: "center",
      width: "15%",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: (
            <TextField
              variant="outlined"
              value={record.kieuPhong}
              size="small"
              fullWidth
              disabled={true}
            ></TextField>
          ),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("tour.phongDoi"),
      children: [
        {
          title: t("hotel.priceClass.giaNet"),
          dataIndex: "giaNet",
          key: "giaNet",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: handleData(text),
              props: {
                rowSpan: 1,
              },
            };
            // return handleData(record.fitCuoiTuanNet);
          },
        },
        {
          title: t("hotel.roomClass.numberOfRoom"),
          dataIndex: "soLuongPhongDoi",
          key: "soLuongPhongDoi",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuongPhongDoi"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                fullWidth
                disabled={
                  record.kieuPhong === "Single Room" ||
                  record.kieuPhong === "Phòng đơn"
                }
              ></TextField>
            );
          },
        },
      ],
      visible: true,
      align: "center",
    },
    {
      title: t("phuPhi"),
      children: [
        {
          title: t("giaExtra"),
          dataIndex: "giaExtra",
          key: "giaExtra",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: handleData(text),
              props: {
                rowSpan: 1,
              },
            };
            // return handleData(record.fitCuoiTuanNet);
          },
        },
        {
          title: t("soLuongExtra"),
          dataIndex: "soLuongExtra",
          key: "soLuongExtra",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuongExtra"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                disabled={
                  record.kieuPhong === "Single Room" ||
                  record.kieuPhong === "Phòng đơn"
                }
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      // width: "25%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.phongDon"),
      children: [
        {
          title: t("giaPhongDon"),
          dataIndex: "giaPhongDon",
          key: "giaPhongDon",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: handleData(text),
              props: {
                rowSpan: 1,
              },
            };
            // return handleData(record.fitCuoiTuanNet);
          },
        },
        {
          title: t("soLuongPhongDon"),
          dataIndex: "soLuongPhongDon",
          key: "soLuongPhongDon",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuongPhongDon"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      // width: "25%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.tongChiPhi"),
      dataIndex: "tong",
      key: "tong",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: handleData(text),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("adultGroup"),
      dataIndex: "nhomNgLon",
      key: "nhomNgLon",
      width: "15%",
      align: "center",
      visible: dataNhomNgLon.length > 0,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else if (record.id === 1) {
          return {
            children: (
              <TextField
                value={bgNhomNguoiLonId}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleChangeNhomNgLon(e.target.value)}
                select
              >
                {dataNhomNgLon.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.tenNhom}
                  </MenuItem>
                ))}
              </TextField>
            ),
            props: {
              colSpan: 1,
              rowSpan: dichVus.length - 1,
            },
          };
        } else {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
      },
    },
  ];

  const columnsTE = [
    {
      title: t("service"),
      dataIndex: "tenDichVu",
      align: "center",
      width: "15%",
      visible: true,
      render: (text, row) => {
        return {
          children: text,
          props: {
            rowSpan: row.rowSpanDV,
          },
        };
      },
    },
    {
      title: t("childrenGroup"),
      dataIndex: "tenNhomTE",
      align: "center",
      width: "15%",
      visible: dataNhomTreEm.length > 0,
      render: (text, row) => {
        return {
          children: (
            <TextField
              variant="outlined"
              value={text}
              size="small"
              fullWidth
              disabled={true}
            ></TextField>
          ),
          props: {
            rowSpan: row.rowSpanTE,
          },
        };
      },
    },
    {
      title: t("age") + " " + t("children"),
      dataIndex: "dmDoTuoiId",
      align: "center",
      width: "15%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link
                  onClick={() => handleAddServiceTreEm(row)}
                  underline="none"
                >
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 6,
            },
          };
        return (
          <>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                <TextField
                  variant="outlined"
                  value={text}
                  size="small"
                  name="dmDoTuoiId"
                  fullWidth
                  select
                  disabled={dataNhomTreEm.length > 0}
                  onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                >
                  {dmDoTuoi.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.do_tuoi}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                {dataNhomNgLon.length === 0 && (
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t("tour.percentage"),
      dataIndex: "phanTram",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: (
            <TextField
              variant="outlined"
              value={text}
              size="small"
              name="phanTram"
              fullWidth
              type="number"
              onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
            />
          ),
          props: {},
        };
      },
    },
    {
      title: t("hotel.priceClass.giaNet"),
      dataIndex: "giaNet",
      align: "center",
      width: "12%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        if (text)
          return {
            children: handleData(text),
            props: {},
          };
      },
    },
    {
      title: t("tour.vanChuyen.soLuong"),
      dataIndex: "soLuong",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };

        return {
          children: (
            <TextField
              value={text}
              variant="outlined"
              name="soLuong"
              size="small"
              type="number"
              fullWidth
              onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
            ></TextField>
          ),
          props: {},
        };
      },
    },
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
      width: "15%",
      align: "center",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: handleData(text),
          props: {},
        };
      },
    },
    {
      title: () => {
        return (
          <>
            {t("action.apply")}{" "}
            <Checkbox
              checked={checkAll}
              onChange={(e) => handleChangeEleDichVuTreEm(e, "", "ALL")}
            ></Checkbox>
          </>
        );
      },
      dataIndex: "apDung",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        return {
          children: (
            <Checkbox
              name="apDung"
              checked={row.apDung}
              onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
            />
          ),
          props: {},
        };
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Modal
        width={1300}
        title={t("tour.khachSan.capNhatDichVu")}
        footer={null}
        open={isOpen}
        onCancel={handleCloseModal}
      >
        <Row style={{ marginTop: "20px" }}>
          <TextField
            variant="outlined"
            fullWidth
            label={t("supplier")}
            value={dataSource.tenNcc}
            required
            InputProps={{
              style: { height: "50px" },
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateNCC()}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClear()}
                    fontSize="small"
                  />
                </>
              ),
            }}
          ></TextField>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              dataSource={dichVus}
              columns={columnDichVus.filter((item) => item.visible)}
              bordered
              pagination={false}
              summary={(pageData) => {
                // console.log(pageData);
                let totalPhongDoi = 0;
                let totalExtra = 0;
                let totelPhongDon = 0;
                let totalChiPhi = 0;

                pageData.forEach(
                  ({
                    soLuongPhongDoi,
                    soLuongExtra,
                    soLuongPhongDon,
                    tong,
                    id,
                  }) => {
                    if (id !== "NULL") {
                      totalPhongDoi += parseInt(soLuongPhongDoi);
                      totalExtra += parseInt(soLuongExtra);
                      totelPhongDon += parseInt(soLuongPhongDon);
                      totalChiPhi += parseFloat(tong);
                    }
                  }
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={1}>
                        {t("tour.tongKet")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={2} align="center">
                        <Tooltip title={t("totalRooms")}>
                          {totalPhongDoi}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} colSpan={2} align="center">
                        <Tooltip title={t("totalSurcharges")}>
                          {totalExtra}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} colSpan={2} align="center">
                        <Tooltip title={t("totalSingleRooms")}>
                          {totelPhongDon}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1} align="center">
                        <Tooltip title={t("totalCost")}>
                          {handleData(totalChiPhi)}
                        </Tooltip>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              id="dmGiaApDungId"
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("priceApply")}
              value={dataSource.dmGiaApDungId}
              name="dmGiaApDungId"
              onChange={(e) => handleChangeValueDataSoure(e)}
            >
              {dmGia?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.gia_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <FormControl disabled={true} style={{ width: "100%" }}>
              <Row>
                {listNgay?.map((element, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={
                            (dataSource.dmGiaApDungId === 1 &&
                              parseInt(element.ngay) >= ngay) ||
                            (dataSource.dmGiaApDungId === 2 &&
                              parseInt(element.ngay) === ngay)
                          }
                        />
                      }
                      label={"Ngày " + element.ngay}
                    />
                  );
                })}
              </Row>
            </FormControl>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeOfApply")}
              value={1}
              disabled
            >
              {dmLoaiApDung?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.loai_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("action.apply") + " " + t("thuevat")}
              value={dataSource.dmVatId}
              disabled
            >
              {dmVat?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.vat}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeTour.description")}
              value={dataSource.moTa}
              name="moTa"
              onChange={(e) => handleChangeValueDataSoure(e)}
              //   disabled
            ></TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <TextField
              fullWidth
              label={t("hotel.roomClass.conditionFoc")}
              name="dieuKienFoc"
              value={dataSource.dieuKienFoc}
              type="number"
              onChange={(e) => handleChangeValueDataSoure(e)}
              InputProps={{
                style: { height: "50px" },
                endAdornment: <>{t("room")}</>,
              }}
            ></TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={1}></Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <TextField
              fullWidth
              label="FOC"
              name="foc"
              type="number"
              value={dataSource.foc}
              onChange={(e) => handleChangeValueDataSoure(e)}
              InputProps={{
                style: { height: "50px" },
                endAdornment: <>FOC</>,
              }}
            ></TextField>
          </Col>
        </Row>
        <Divider />
        <Row>
          <h3>{t("hotel.table.title")}</h3>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              dataSource={dichVuTreEms}
              columns={columnsTE.filter((item) => item.visible)}
              pagination={false}
            />
          </Col>
        </Row>
        <Row>
          <h3>{t("tour.tongKet")}</h3>
        </Row>
        <Row>
          <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
            <Row>
              <Col
                xs={11}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {t("tour.tongChiPhi") + " " + t("adult")}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {handleData(chiPhiNguoiLon)}
              </Col>
            </Row>
            <Row>
              <Col
                xs={11}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {t("tour.tongChiPhi") + " " + t("children")}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {handleData(chiPhiTreEm)}
              </Col>
            </Row>
            {/* <Row>
              <Col
                xs={11}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                FOC
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                1.000.000 đ
              </Col>
            </Row> */}
            <Row>
              <Col
                xs={11}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {handleData(tongChiPhi)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginTop: 6 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={10}>
            <Space>
              <Buttons
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
                onClick={(e) => handleSave(e)}
              >
                {t("action.ok")}
              </Buttons>
              <Buttons
                style={{
                  backgroundColor: "#898989",
                  color: "white",
                }}
                key="reset"
                startIcon={<RestartAltIcon />}
                onClick={(e) => handleClear(e)}
              >
                {t("action.reset")}
              </Buttons>
              <Buttons
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => handleCloseModal()}
                key="clear"
              >
                {t("action.cancel")}
              </Buttons>
            </Space>
          </Col>
        </Row>
        <ModalThongTinNccKhachSan
          isOpen={isShowNccKS}
          onIsShow={onIsShow}
          onSaves={handleChangeDataServiceKS}
          action={action}
          maNcc={maNcc}
        />
      </Modal>
    </>
  );
};
export default ModalCapNhatKS;
