import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridActionsCellItem,
  GridPagination,
  enUS,
  viVN,
} from "@mui/x-data-grid";
import { Col, Row, Space } from "antd";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@mui/icons-material/Done";
import DanhSachBookingService from "../../../../api/dieu_hanh/danh_sach_booking/DanhSachBookingService";
import Search from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ActionsMenu = ({ params }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorCTTour, setSubMenuAnchorCTTour] = useState(null);

  const handleClick = (event) => {
    debugger;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleSubMenuOpen = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };
  const handleSubMenuCTTourOpen = (event) => {
    setSubMenuAnchorCTTour(event.currentTarget);
  };
  const handleSubMenuCTTourClose = () => {
    setSubMenuAnchorCTTour(null);
  };
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onMouseEnter={handleSubMenuOpen}
          onMouseLeave={handleSubMenuClose}
        >
          {t("tour.taiXuong")}
          <Menu
            anchorEl={subMenuAnchorEl}
            open={Boolean(subMenuAnchorEl)}
            onClose={handleSubMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            MenuListProps={{ onMouseLeave: handleSubMenuClose }}
          >
            <MenuItem
              //   onMouseEnter={handleSubMenuCTTourOpen}
              onMouseLeave={handleSubMenuCTTourClose}
              onClick={handleSubMenuCTTourOpen}
            >
              {t("chuongTrinhTour")}
              <Menu
                anchorEl={subMenuAnchorCTTour}
                open={Boolean(subMenuAnchorCTTour)}
                onClose={handleSubMenuCTTourClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{ onMouseLeave: handleSubMenuCTTourClose }}
              >
                <MenuItem onClick={handleClose}>{t("exportFilePDF")}</MenuItem>
                <MenuItem onClick={handleClose}>
                  {t("exportFileExcel")}
                </MenuItem>
              </Menu>
            </MenuItem>
            <MenuItem onClick={handleClose}>{t("phieuDatDichVu")}</MenuItem>
          </Menu>
        </MenuItem>
      </Menu>
    </div>
  );
};

const DanhSachBooking = () => {
  const { t, i18n } = useTranslation();
  const [vsFooter, setVsFooter] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [danhSachBooking, setDanhSachBooking] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let dataReq = { opThongTinChungId: 3, search: "" };
    getDanhSachBooking(dataReq);
  }, []);

  const getDanhSachBooking = (dataReq) => {
    DanhSachBookingService.layTTDSBooking(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const danhSachBooking =
            res.data.data?.map((item, idx) => ({
              ...item,
              id: idx,
              stt: idx + 1,
            })) || [];
          setDanhSachBooking(danhSachBooking);
        }
      })
      .catch((err) => console.log("Lay danh sach booking error ..." + err));
  };
  const handleEditBooking = (param) => {
    navigate("/dat-dich-vu/edit?id=" + param.id);
  };

  const handleSearch = () => {
    getDanhSachBooking({
      opThongTinChungId: 3,
      search: textSearch,
    });
  };
  const columns = [
    {
      headerName: "STT",
      field: "stt",
      key: "stt",
      align: "center",
      width: 50,
      type: "number",
      headerAlign: "center",
    },
    {
      headerName: "ID " + t("booking"),
      field: "maBooking",
      key: "maBooking",
      align: "center",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <Link
          //   to={"/dat-dich-vu/edit?id=" + params.id}
          style={{ color: "#007cff" }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: t("name") + " " + t("booking"),
      field: "tenBooking",
      key: "tenBooking",
      align: "center",
      width: 210,
      headerAlign: "center",
    },
    {
      headerName: t("customerInfor"),
      field: "thongTinKhachHang",
      key: "thongTinKhachHang",
      align: "center",
      width: 240,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("loaiKH") + ": "}
            </span>
            <span>{params.value.loaiKhachHang}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("tenKH") + ": "}
            </span>
            <span>{params.value.tenKhachHang}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("phone") + ": "}
            </span>
            <span>{params.value.soDienThoai}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("email") + ": "}
            </span>
            <span>{params.value.email}</span>
          </div>
        </div>
      ),
    },
    {
      headerName: t("tourCost"),
      field: "chiPhiTour",
      key: "chiPhiTour",
      align: "center",
      width: 300,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("tongTien") + ": "}
            </span>
            <span>{params.value.tongTien}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("thucThu") + ": "}
            </span>
            <span>{params.value.thucThu}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("conLai") + ": "}
            </span>
            <span>{params.value.conLai}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("loiNhuan") + ": "}
            </span>
            <span>{params.value.loiNhuan}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("phanTramLoiNhuan") + ": "}
            </span>
            <span>{params.value.phanTramLoiNhuan}</span>
          </div>
        </div>
      ),
    },
    {
      headerName: t("perform"),
      field: "thucHien",
      key: "thucHien",
      align: "center",
      width: 220,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("nguoiTao") + ": "}
            </span>
            <span>{params.value.nguoiTao}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("sale") + ": "}
            </span>
            <span>{params.value.saleName}</span>
          </div>
          <div style={{ width: "200px" }}>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("Executive") + ": "}
            </span>
            <span style={{ whiteSpace: "normal" }}>
              {params.value.tenNguoiDieuHanh}
            </span>
          </div>
        </div>
      ),
    },
    {
      headerName: t("status"),
      field: "tenTrangThai",
      key: "tenTrangThai",
      align: "center",
      minWidth: 170,
      headerAlign: "center",
      renderCell: ({ value }) => {
        if (value === "Tạo mới Booking")
          return <Chip icon={<AddIcon />} label={value} color="primary" />;
        else if (value === "Yêu cầu Booking")
          return (
            <Chip color="warning" icon={<RequestPageIcon />} label={value} />
          );
        else if (value === "Đã hoàn thành")
          return <Chip color="success" icon={<DoneIcon />} label={value} />;
        else if (value === "Đã hủy")
          return <Chip icon={<CloseIcon />} label={value} color="error" />;
      },
    },
    {
      headerName: t("formOfPayment"),
      field: "hinhThucThanhToan",
      key: "hinhThucThanhToan",
      align: "center",
      // flex: 2,
      width: 170,
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      align: "center",
      width: 50,
      renderCell: ({ value }) => {
        return <ActionsMenu params={value} />;
      },
    },
  ];
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <TextField
            variant="outlined"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            fullWidth
            size="small"
            placeholder={t("search")}
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </Col>
        <Button
          variant="contained"
          color="success"
          onClick={handleSearch}
          style={{ marginLeft: "20px" }}
        >
          {t("search")}
        </Button>
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DataGrid
            //   onRowDoubleClick={(record) => handleEditBooking(record.row)}
            rows={danhSachBooking}
            columns={columns}
            checkboxSelection={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              newRowSelectionModel.length > 0
                ? setVsFooter(true)
                : setVsFooter(false);
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowHeight={120}
            sx={{
              "& .MuiDataGrid-cell": {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-row": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeader": {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                backgroundColor: "#e2e2e2",
              },
              "& .MuiDataGrid-columnHeaderTitle ": {
                fontWeight: "bold",
              },
            }}
            // slots={{
            //   footer: () => (
            //     <>
            //       <GridPagination />
            //       {vsFooter ? (
            //         <Box>
            //           <Row>
            //             <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
            //             <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            //               <ClearIcon onClick={() => setVsFooter(false)} />
            //             </Col>
            //             <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            //               {rowSelectionModel.length} selected
            //             </Col>
            //             <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
            //             <Space>
            //               <Button variant="outlined">
            //                 {t("Executivetransfer")}
            //               </Button>

            //               <Button variant="outlined" fullWidth>
            //                 {t("traLai")}
            //               </Button>

            //               <Button variant="outlined">
            //                 {t("CancelBooking")}
            //               </Button>

            //               <Button variant="outlined">{t("Replication")}</Button>

            //               <Button variant="contained" color="error">
            //                 {t("action.cancel")}
            //               </Button>
            //             </Space>
            //           </Row>
            //         </Box>
            //       ) : (
            //         <></>
            //       )}
            //     </>
            //   ),
            // }}
            localeText={
              i18n.language === "vi"
                ? viVN.components.MuiDataGrid.defaultProps.localeText
                : enUS
            }
          />
        </Col>
      </Row>
    </>
  );
};
export default DanhSachBooking;
