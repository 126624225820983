import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Affix, Card, Col, notification, Row, Space } from "antd";
import TongChiPhiService from "../../../../api/booking/TongChiPhiService";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router";

const TongChiPhi = ({ bookingId, action }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [tongChiPhi, setTongChiPhi] = useState({
    tongTienTruocThue: 0,
    vat: 0,
    tienVat: 0,
    chietKhau: 0,
    tongTien: 0,
    tamUng: 0,
    tongTienConLai: 0,
  });
  const [tongChiPhiInit, setTongChiPhiInit] = useState({
    tongTienTruocThue: 0,
    vat: 0,
    tienVat: 0,
    chietKhau: 0,
    tongTien: 0,
    tamUng: 0,
    tongTienConLai: 0,
  });
  let formatter = new Intl.NumberFormat("en-US", {});

  useEffect(() => {
    TongChiPhiService.getTTTongChiPhiByThongTinChungId({
      bkThongTinChungId: bookingId,
    })
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          let tongTien =
            res.data.data?.tongTienTruocThue +
            res.data.data?.tienVat -
            res.data.data?.chietKhau;
          const tongChiPhi = {
            tongTienTruocThue: res.data.data?.tongTienTruocThue
              ? res.data.data?.tongTienTruocThue
              : 0,
            vat: res.data.data?.vat ? res.data.data?.vat : 0,
            tienVat: res.data.data?.tienVat ? res.data.data?.tienVat : 0,
            chietKhau: res.data.data?.chietKhau ? res.data.data?.chietKhau : 0,
            tamUng: res.data.data?.tamUng ? res.data.data?.tamUng : 0,
            tongTien: tongTien ? tongTien : 0,
            tongTienConLai:
              tongTien - res.data.data?.tamUng
                ? tongTien - res.data.data?.tamUng
                : 0,
          };
          const data = res.data.data?.tongChiPhis?.map((it, idx) => {
            it.stt = idx + 1;
            it.donGia = formatter.format(it.donGia);
            it.tongTien = formatter.format(it.tongTien);
            return it;
          });
          setDataTable(data);
          setTongChiPhi(tongChiPhi);
          setTongChiPhiInit({ ...tongChiPhi });
        }
      })
      .catch((res) =>
        console.log("Error getTTTongChiPhiByThongTinChungId " + res)
      );
  }, [bookingId]);

  // const onChangeThueVat = (value) => {
  //   let chiPhi = { ...tongChiPhi };
  //   chiPhi.vat = value;
  //   chiPhi.tienVat = chiPhi.tongTienTruocThue * (value / 100);
  //   chiPhi.tongTien =
  //     chiPhi.tongTienTruocThue + chiPhi.tienVat - chiPhi.chietKhau;
  //   chiPhi.tongTienConLai = chiPhi.tongTien - chiPhi.tamUng;
  //   setTongChiPhi(chiPhi);
  // };

  const onChangeTamUng = (value) => {
    let chiPhi = { ...tongChiPhi };
    chiPhi.tamUng = value;
    chiPhi.tongTienConLai = chiPhi.tongTien - value;
    setTongChiPhi(chiPhi);
  };

  const onChangeChietKhau = (value) => {
    let chiPhi = { ...tongChiPhi };
    chiPhi.chietKhau = value;
    chiPhi.tongTien =
      chiPhi.tongTienTruocThue + chiPhi.tienVat - chiPhi.chietKhau;
    chiPhi.tongTienConLai = chiPhi.tongTien - chiPhi.tamUng;
    setTongChiPhi(chiPhi);
  };

  const handleContinue = (act) => {
    let dataReq = {
      bkThongTinChungId: bookingId,
      chietKhau: tongChiPhi.chietKhau,
      tongTien: tongChiPhi.tongTien,
      tamUng: tongChiPhi.tamUng,
    };
    // console.log(dataReq);
    TongChiPhiService.updateTongChiPhi(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          if (act === "end") {
            navigate("/dat-dich-vu");
          } else if (action === "edit") {
            navigate(
              "/dat-dich-vu/danh-sach-doan/" + action + "?id=" + bookingId
            );
          } else {
            navigate("/dat-dich-vu/danh-sach-doan/" + action);
          }
          api["success"]({
            message: t("stour"),
            description: t("edit") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: t("edit") + " " + t("noti.error").toLowerCase(),
            duration: 0,
          });
        }
      })
      .catch((err) => console.log("SaveBkDanhSachDoan Error " + err));
  };

  const handleClear = () => {
    setTongChiPhi({ ...tongChiPhiInit });
  };

  const columns = [
    {
      title: "STT",
    },
    {
      title: t("description"),
    },
    {
      title: t("unit"),
    },
    {
      title: t("quantity"),
    },
    {
      title: "FOC",
    },
    {
      title: t("chietKhau"),
    },
    {
      title: t("tour.donGia"),
    },
    {
      title: t("totalPrice"),
    },
  ];

  return (
    <Box>
      {contextHolder}
      <Card title={<h3>{t("tour.tongChiPhi")} </h3>}>
        <Row style={{ marginTop: "1%" }}>
          <div
            style={
              dataTable.length > 0
                ? { width: "100%", height: "400px", overflowY: "auto" }
                : { width: "100%" }
            }
          >
            <table style={{ width: "100%" }}>
              <thead>
                {columns.map((cl) => {
                  return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                })}
              </thead>
              <>
                {dataTable.map((item) => {
                  return (
                    <tr>
                      <td>{item.stt}</td>
                      <td>{item.moTa}</td>
                      <td>{item.donVi}</td>
                      <td>{item.soLuong}</td>
                      <td>{item.foc}</td>
                      <td>{item.chietKhau}</td>
                      <td>{item.donGia}</td>
                      <td>{item.tongTien}</td>
                    </tr>
                  );
                })}
              </>
            </table>
          </div>
        </Row>
        <Row>
          <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {t("tongTienTruocThue") + " :"}
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {formatter.format(tongChiPhi.tongTienTruocThue)}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {t("thuevat") + " " + tongChiPhi.vat + "% :"}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {formatter.format(tongChiPhi.tienVat)}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {t("chietKhau") + " :"}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <TextField
                  value={tongChiPhi.chietKhau}
                  variant="standard"
                  name="chietKhau"
                  size="small"
                  onChange={(e) => onChangeChietKhau(e.target.value)}
                  type="number"
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    style: {
                      textAlign: "right",
                      direction: "rtl",
                      fontSize: "14px",
                    },
                  }}
                ></TextField>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {t("tongTien") + " :"}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {formatter.format(tongChiPhi.tongTien)}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {t("tamUng") + " :"}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <TextField
                  value={tongChiPhi.tamUng}
                  variant="standard"
                  name="tamUng"
                  size="small"
                  onChange={(e) => onChangeTamUng(e.target.value)}
                  type="number"
                  inputProps={{
                    style: {
                      textAlign: "right",
                      direction: "rtl",
                      fontSize: "14px",
                    },
                  }}
                ></TextField>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {t("tongTien") + " " + t("conLai").toLocaleLowerCase() + " :"}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {formatter.format(tongChiPhi.tongTienConLai)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Affix offsetBottom={50}>
        <Row>
          <Col span={12} />
          <Col span={12}>
            <div
              style={{
                textAlign: "center",
                color: "#fff",
                height: 60,
                paddingInline: 50,
                lineHeight: "60px",
                backgroundColor: "rgba(150, 150, 150, 0.2)",
                borderRadius: "4px",
              }}
            >
              <Space>
                <Button
                  sx={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  // startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleContinue("end")}
                  type="end"
                  // startIcon={<SaveIcon />}
                >
                  {t("action.end")}
                </Button>
                <Button
                  variant="outlined"
                  // startIcon={<DeleteIcon />}
                  onClick={() => handleContinue("continue")}
                  key="continue"
                >
                  {t("action.continue")}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Affix>
    </Box>
  );
};
export default TongChiPhi;
