import DoneIcon from "@mui/icons-material/Done";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableDieuHanh from "./quanLyDieuHanh/TableDieuHanh";
import DieuHanhService from "../../api/dieu_hanh/DieuHanhService";

const DieuHanh = () => {
    const { t } = useTranslation();
    const [defaultActiveKey, setDefaultActiveKey] = useState("1");
    const [dataSource, setDataSource] = useState([]);
    const [dmTrangThaiId, setDmTrangThaiId] = useState(0);
    const [dataTrangThaiAll, setDataTrangThaiAll] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    useEffect(() => {
        DieuHanhService.layTrangThaiDieuHanh()
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDataTrangThaiAll(data);
                    setDmTrangThaiId(data[0]?.dmtrangThaiTourDetails[0]?.dmTrangThaiId);
                }
            })
            .catch((err) => {
                console.log("layTrangThaiDieuHanh erorrs " + err);
            });
    }, []);

    useEffect(() => {
        getDataSource({
            searchMaOrTen: "",  
            loaiThoiGian: 3, // 1: ưu tiên, 2 đi tour, 3 kết thúc, 0: không chọn
            thoiGianBatDau: "",
            thoiGianKetThuc: "",
            loaiTourId: 1,
            tienDo: 0,
            maBooking: "",
            tenBooking: "",
            thongTinTour: "",
            thucHien: "",
            dmTrangThaiId: 0,
            pageIndex: 1,
            pageSize: 10,
        });
    }, [dmTrangThaiId]);
    const getDataSource = (data) => {
        console.log(data);
        DieuHanhService.getDataDSTTDieuHanh(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    console.log("getDataDSTTDieuHanh: ", JSON.stringify(response.data.data, null, 2));
                    let data = response.data.data?.map((item, idx) => {
                        return {
                            id: idx,
                            ...item,
                            stt: idx + 1,
                            ...item.thongTinTour,
                            ...item.thucHien,
                        };
                    });
                    setDataSource(data);
                }
            })
            .catch((err) => {
                console.log("getDataDSTTDieuHanh erorrs " + err);
            });
    };
    const items = [
        {
            key: "1",
            label: t("tatCa"),
            children: (
                <TableDieuHanh
                    activeKey={defaultActiveKey}
                    data={dataSource}
                    getDataSource={getDataSource}
                    dataTrangThaiAll={dataTrangThaiAll}
                    dmTrangThaiId={dmTrangThaiId}
                    setDmTrangThaiId={setDmTrangThaiId}
                />
            ),
            icon: <SelectAllIcon fontSize="small" />,
        },
        {
            key: "2",
            label: t("choXuLy"),
            children: (
                <TableDieuHanh
                    activeKey={defaultActiveKey}
                    data={dataSource}
                    getDataSource={getDataSource}
                    dataTrangThaiAll={dataTrangThaiAll}
                    dmTrangThaiId={dmTrangThaiId}
                    setDmTrangThaiId={setDmTrangThaiId}
                />
            ),
            icon: <HourglassBottomIcon fontSize="small" />,
        },
        {
            key: "3",
            label: t("dangXuLy"),
            children: (
                <TableDieuHanh
                    activeKey={defaultActiveKey}
                    data={dataSource}
                    getDataSource={getDataSource}
                    dataTrangThaiAll={dataTrangThaiAll}
                    dmTrangThaiId={dmTrangThaiId}
                    setDmTrangThaiId={setDmTrangThaiId}
                />
            ),
            icon: <RunningWithErrorsIcon fontSize="small" />,
        },
        {
            key: "4",
            label: t("daHoanThanh"),
            children: (
                <TableDieuHanh
                    activeKey={defaultActiveKey}
                    data={dataSource}
                    getDataSource={getDataSource}
                    dataTrangThaiAll={dataTrangThaiAll}
                    dmTrangThaiId={dmTrangThaiId}
                    setDmTrangThaiId={setDmTrangThaiId}
                />
            ),
            icon: <DoneIcon fontSize="small" />,
        },
    ];
    return (
        <>
            <Tabs activeKey={defaultActiveKey} items={items} onChange={(key) => setDefaultActiveKey(key)} />
        </>
    );
};
export default DieuHanh;
