import {
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  EditFilled,
  PlusSquareFilled,
} from "@ant-design/icons";
import { MenuItem } from "@material-ui/core";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Select,
  TextField,
} from "@mui/material";
import { Col, Collapse, Row, notification } from "antd";
import CheckIcon from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../api/auth_header";
import khachSanService from "../../../api/bao_gia/sale_rate/khach_san/khachSanService";
import dm_do_tuoi from "../../../api/category/dm_do_tuoi";
import dm_gia_ap_dung from "../../../api/category/dm_gia_ap_dung";
import otoService from "../../../api/tour/oto/oto.service";
import otoServiceBG from "../../../api/bao_gia/sale_rate/oto/otoService";
import { API_BASE_URL } from "../../../constants";
import TongKet from "./TongKet";
import baoGiaService from "../../../api/bao_gia/baoGiaService";
import nhaHangService from "../../../api/bao_gia/sale_rate/nha_hang/nhaHangService";
import ModalCapNhatKS from "./modalCapNhat/ModalCapNhatKS";
import ModalCapNhatOto from "./modalCapNhat/ModalCapNhatOto";
import ModalCapNhatNhaHang from "./modalCapNhat/ModalCapNhatNhaHang";
import { element } from "prop-types";

const ViewServies = ({
  dataDv,
  baoGiaTongQuanId,
  ngayKhoiHanh,
  setReload,
  dataNhomNgLon,
  dataNhomTreEm,
  soLuongNgLonBG,
  soLuongTreEmBG,
  soNgay,
  bgHanhTrinhs,
  tongGia,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [openNH, setOpenNh] = useState(false);
  const [openKs, setOpenKs] = useState(false);
  const [openOto, setOpenOto] = useState(false);
  const [dataTableTreEm, setDataTableTreEm] = useState([]);
  const [dataTableNguoiLon, setDataTableNguoiLon] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [dmDoTuoi, setDmDoTuoi] = useState([]);
  const [kieuPhong, setKieuPhong] = useState([]);
  const [dmBuaAn, setDmBuaAn] = useState([]);
  const [dmSoCho, setDmSoCho] = useState([]);
  const [dmVat, setDmVat] = useState([]);
  const [nhomNguoiLons, setNhomNguoiLons] = useState([]);
  const [baoGiaHanhTrinhId, setBaoGiaHanhTrinhId] = useState(0);
  const [bgChietTinhSaleId, setBgChietTinhSaleId] = useState(0);
  const [bgMapCTSaleHtId, setBgMapCTSaleHtId] = useState(0);
  const [hanhDong, setHanhDong] = useState("ADD");
  const [dmDichVuId, setDmDichVuId] = useState(0);
  // let dataNhomTreEm = [];
  useEffect(() => {
    baoGiaService.getBaoGiaTongQuanById({ id: 1 }).then(function (response) {
      if (response.status === 200 && response.data.code === "00") {
        let data = response.data.data;
        setNhomNguoiLons(data.nhomNguoiLons);
      }
    });
  }, []);
  useEffect(() => {
    let data = {};
    dm_do_tuoi
      .getDmDoTuoi(data)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmDoTuoi(data);
        }
      })
      .catch(function (response) {
        if (response.status === 401) {
        }
      });
    axios({
      method: "post",
      url: API_BASE_URL + "category/getDmKieuPhong",
      headers: authHeader(),
    })
      .then(function (response) {
        if (response.status === 200) {
          let dataQMP = [];
          let data = response.data.data;
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].hieu_luc === true) {
                dataQMP.push({
                  value: data[i].id,
                  label: data[i].kieu_phong,
                });
              }
            }
            setKieuPhong(dataQMP);
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
    dm_gia_ap_dung
      .getDmBuaAn()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let dataBA = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].hieu_luc === true) {
              dataBA.push({
                value: data[i].id,
                label: data[i].ten_bua_an,
              });
            }
          }
          setDmBuaAn(dataBA);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          console.log("Response setDmVat: ====", response.data.data);
          let vat = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].hieu_luc)
              vat.push({
                value: data[i].vat,
                label: data[i].vat_str,
                id: data[i].id,
              });
          }
          console.log("Response vat: ====", vat);
          setDmVat(vat);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
    otoService
      .getDmSoCho()
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data;
          let dmSoCho = [];
          data.forEach((item) => {
            if (item.hieu_luc) {
              dmSoCho.push({
                value: item.id,
                label: item.so_cho_str,
              });
            }
          });
          setDmSoCho(dmSoCho);
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  }, []);

  useEffect(() => {
    if (dataDv?.length > 0) {
      console.log("dataDv: ", JSON.stringify(dataDv));
      setDataSource(dataDv);
      let data = dataDv;
      let dataNL = [];
      let dataTe = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.mapCTSaleNguoiLon.length > 0) {
          let rowSpanDv = 0;
          for (let j = 0; j < element.mapCTSaleNguoiLon.length; j++) {
            const mapCTSaleNguoiLon = element.mapCTSaleNguoiLon[j];
            let idKS = dataNL.length;
            if (element.tenDichVu !== "Khách sạn") {
              rowSpanDv += mapCTSaleNguoiLon.dichVuNguoiLons.length;
            }

            for (let k = 0; k < mapCTSaleNguoiLon.dichVuNguoiLons.length; k++) {
              const dichVuNguoiLons = mapCTSaleNguoiLon.dichVuNguoiLons[k];
              console.log(dichVuNguoiLons.length);
              if (element.tenDichVu === "Khách sạn") {
                rowSpanDv += dichVuNguoiLons.giaDichVuKhachSans.length;
                for (
                  let z = 0;
                  z < dichVuNguoiLons.giaDichVuKhachSans.length;
                  z++
                ) {
                  const elementGiaKS = dichVuNguoiLons.giaDichVuKhachSans[z];
                  if (z === 0)
                    dichVuNguoiLons.rowSpan =
                      dichVuNguoiLons.giaDichVuKhachSans.length;
                  else dichVuNguoiLons.rowSpan = 0;
                  let dvNL = {
                    tranxBgChietTinhKsId: dichVuNguoiLons.tranxBgChietTinhKsId,
                    dichVu: dichVuNguoiLons.dichVu,
                    chiPhiTreEm: dichVuNguoiLons.chiPhiTreEm,
                    vat: dichVuNguoiLons.vat,
                    bgNhomNguoiLonId: dichVuNguoiLons.bgNhomNguoiLonId,
                    tenNhomNguoiLon: dichVuNguoiLons.tenNhomNguoiLon,
                    soLuongNguoiLon: dichVuNguoiLons.soLuongNguoiLon,
                    dieuKienFoc: dichVuNguoiLons.dieuKienFoc,
                    foc: dichVuNguoiLons.foc,
                    rowSpan: dichVuNguoiLons.rowSpan,
                    soTienDuocFoc: dichVuNguoiLons.soTienDuocFoc,
                    id: "API" + Math.random(),
                    ...elementGiaKS,
                  };
                  dataNL.push(mapDataNL(dvNL, mapCTSaleNguoiLon, element));
                }
              } else {
                dichVuNguoiLons.rowSpan = 1;
                dichVuNguoiLons.id = "API" + Math.random();
                dichVuNguoiLons.giaDichVu = dichVuNguoiLons.donGia;
                dataNL.push(
                  mapDataNL(dichVuNguoiLons, mapCTSaleNguoiLon, element)
                );
              }
            }
          }

          dataNL.push(
            mapDataNL(
              null,
              { id: "NULL" + Math.random(), rowSpan: 0, rowSpanDV: 0 },
              element
            )
          );
          let first = dataNL.findIndex(
            (dt) => dt.tenDichVu === element.tenDichVu
          );
          console.log("check");
          console.log(rowSpanDv);
          console.log(first);
          dataNL[first].rowSpanDV = rowSpanDv + 1;
        } else {
          dataNL.push(
            mapDataNL(
              null,
              { id: "NULL" + Math.random(), rowSpan: 1, rowSpanDV: 1 },
              element
            )
          );
        }
        if (element.mapCTSaleTreEm.length > 0) {
          // let rowSpanDV = 0;
          for (let j = 0; j < element.mapCTSaleTreEm.length; j++) {
            const mapCTSaleTreEm = element.mapCTSaleTreEm;
            if (mapCTSaleTreEm[j]) {
              let dichVuTreEms = mapCTSaleTreEm[j]?.dichVuTreEms;
              let rowSpanLDV = mapCTSaleTreEm.reduce((num, item) => {
                item.dichVuTreEms.forEach((it) => {
                  it.details.forEach(() => {
                    num++;
                  });
                });
                return num;
              }, 0);
              if (dataNhomTreEm?.length === 0)
                rowSpanLDV += mapCTSaleTreEm.length * dichVuTreEms.length;
              for (let h = 0; h < dichVuTreEms.length; h++) {
                let details = dichVuTreEms[h].details;
                for (let g = 0; g < details.length; g++) {
                  let rowSpanDV = 0;
                  let rowSpanLoaiDV = 0;
                  let rowSpanNcc = 0;
                  let firtIndexLoaiDichVu = mapCTSaleTreEm.findIndex((dt) => {
                    let check = false;
                    dt.dichVuTreEms?.forEach((it) => {
                      if (it?.details?.length > 0) check = true;
                    });
                    return check;
                  });
                  let firtIndexTenNcc = dichVuTreEms.findIndex(
                    (dt) => dt?.details?.length > 0
                  );
                  if (dataNhomTreEm?.length > 0) {
                    if (
                      j === firtIndexLoaiDichVu &&
                      h === firtIndexTenNcc &&
                      g === 0
                    )
                      rowSpanLoaiDV = rowSpanLDV;
                    if (h === firtIndexTenNcc && g === 0) {
                      rowSpanNcc = dichVuTreEms.reduce((num, item) => {
                        item.details.forEach(() => {
                          num++;
                        });
                        return num;
                      }, 0);
                    }
                  } else {
                    if (j === 0 && h === 0 && g === 0) {
                      rowSpanLoaiDV = rowSpanLDV;
                    }
                    if (h === 0 && g === 0) {
                      rowSpanNcc = dichVuTreEms.reduce((num, item) => {
                        item.details.forEach(() => {
                          num++;
                        });
                        return num;
                      }, 0);
                      rowSpanNcc += dichVuTreEms.length;
                    }
                  }
                  if (g === 0) {
                    rowSpanDV = details.length;
                    if (dataNhomTreEm?.length === 0) rowSpanDV++;
                  }
                  const detail = details[g];
                  dataTe.push({
                    id: Math.random() + "",
                    ...detail,
                    loaiDichVu: element.tenDichVu,
                    bgCTSaleId: element.bgCTSaleId,
                    dmDichVuId: element.dmDichVuId,
                    bgMapCTSaleHtId: mapCTSaleTreEm[j].bgMapCTSaleHtId,
                    nccThongTinChungId: mapCTSaleTreEm[j].nccThongTinChungId,
                    parentBgMapCTSaleHtId:
                      mapCTSaleTreEm[j].parentBgMapCTSaleHtId,
                    tenNhaCungCap: mapCTSaleTreEm[j].tenNhaCungCap,
                    dmGiaApDungId: mapCTSaleTreEm[j].dmGiaApDungId,
                    ngayApDungs: mapCTSaleTreEm[j].ngayApDungs,
                    dmLoaiApDungId: mapCTSaleTreEm[j].dmLoaiApDungId,
                    moTa: mapCTSaleTreEm[j].moTa,
                    dieuKienFoc: mapCTSaleTreEm[j].dieuKienFoc,
                    foc: mapCTSaleTreEm[j].foc,
                    mapBgChietTinhKsTreEmId:
                      dichVuTreEms[h].mapBgChietTinhKsTreEmId,
                    mapBgChietTinhNhTreEmId:
                      dichVuTreEms[h].mapBgChietTinhNhTreEmId,
                    mapBgChietTinhOtoTreEmId:
                      dichVuTreEms[h].mapBgChietTinhOtoTreEmId,
                    tenDichVu: dichVuTreEms[h].tenDichVu,
                    giaDichVu: dichVuTreEms[h].donGia,
                    // bgNhomTreEmId: dichVuTreEms[h].bgNhomTreEmId,
                    tranxBgChietTinhKsId: dichVuTreEms[h].tranxBgChietTinhKsId,
                    tranxBgChietTinhNhId: dichVuTreEms[h].tranxBgChietTinhNhId,
                    tranxBgChietTinhOtoId:
                      dichVuTreEms[h].tranxBgChietTinhOtoId,
                    rowSpanNcc: rowSpanNcc,
                    rowSpanLoaiDV: rowSpanLoaiDV,
                    rowSpanDV: rowSpanDV,
                  });
                }
                if (dataNhomTreEm?.length === 0)
                  dataTe.push({
                    id: "NEW_ADD" + Math.random(),
                    loaiDichVu: element.tenDichVu,
                    tenNhaCungCap: mapCTSaleTreEm[j].tenNhaCungCap,
                    tenDichVu: dichVuTreEms[h].tenDichVu,
                    giaDichVu: dichVuTreEms[h].donGia,
                    bgCTSaleId: element.bgCTSaleId,
                    dmDichVuId: element.dmDichVuId,
                    mapBgChietTinhKsTreEmId:
                      dichVuTreEms[h].mapBgChietTinhKsTreEmId,
                    mapBgChietTinhNhTreEmId:
                      dichVuTreEms[h].mapBgChietTinhNhTreEmId,
                    mapBgChietTinhOtoTreEmId:
                      dichVuTreEms[h].mapBgChietTinhOtoTreEmId,
                    tranxBgChietTinhKsId: dichVuTreEms[h].tranxBgChietTinhKsId,
                    tranxBgChietTinhNhId: dichVuTreEms[h].tranxBgChietTinhNhId,
                    tranxBgChietTinhOtoId:
                      dichVuTreEms[h].tranxBgChietTinhOtoId,
                    rowSpanLoaiDV:
                      details.length === 0 && j === 0 && h === 0
                        ? rowSpanLDV
                        : 0,
                    rowSpanNcc: details.length === 0 ? 1 : 0,
                    rowSpanDV: details.length === 0 ? 1 : 0,
                  });
              }
            }
          }
        }
        // else
        //   dataTe.push(
        //     mapDataTe(
        //       null,
        //       null,
        //       { id: "NULL" + Math.random(), rowSpan: 1, rowSpanDV: 1 },
        //       element
        //     )
        //   );
      }
      setDataTableNguoiLon(tinhToanThanhTable(dataNL));
      setDataTableTreEm(dataTe);
    } else {
      console.log("Fix data test");
      setDataTableNguoiLon([
        {
          tenDichVu: "Nhà hàng",
          id: "NULL" + Math.random(),
          rowSpan: 1,
          rowSpanDV: 1,
        },
        {
          tenDichVu: "Khách sạn",
          id: "NULL" + Math.random(),
          rowSpan: 1,
          rowSpanDV: 1,
        },
      ]);
    }
  }, [dataDv]);
  // const mapDataTe = (details, dichVuTreEms, mapCTSaleTreEm, element) => {
  //   if (details) {
  //     return {
  //       ...details,
  //       mapBgChietTinhKsTreEmId: dichVuTreEms.mapBgChietTinhKsTreEmId,
  //       mapBgChietTinhNhTreEmId: dichVuTreEms.mapBgChietTinhNhTreEmId,
  //       tenDichVuTreEm: dichVuTreEms.tenDichVu,
  //       bgNhomTreEmId: dichVuTreEms.bgNhomTreEmId,
  //       tranxBgChietTinhKsId: dichVuTreEms.tranxBgChietTinhKsId,
  //       tranxBgChietTinhNhId: dichVuTreEms.tranxBgChietTinhNhId,
  //       tranxBgChietTinhOtoId: dichVuTreEms.tranxBgChietTinhOtoId,
  //       bgMapCTSaleHtId: mapCTSaleTreEm.bgMapCTSaleHtId,
  //       nccThongTinChungId: mapCTSaleTreEm.nccThongTinChungId,
  //       parentBgMapCTSaleHtId: mapCTSaleTreEm.parentBgMapCTSaleHtId,
  //       tenNhaCungCap: mapCTSaleTreEm.tenNhaCungCap,
  //       dmGiaApDungId: mapCTSaleTreEm.dmGiaApDungId,
  //       dmLoaiApDungId: mapCTSaleTreEm.dmLoaiApDungId,
  //       moTa: mapCTSaleTreEm.moTa,
  //       dieuKienFoc: mapCTSaleTreEm.dieuKienFoc,
  //       foc: mapCTSaleTreEm.foc,
  //       tenDichVu: element.tenDichVu,
  //       bgChietTinhOPId: element.bgChietTinhOPId,
  //       bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
  //       dmDichVuId: element.dmDichVuId,
  //     };
  //   }
  //   if (dichVuTreEms) {
  //     return {
  //       ...dichVuTreEms,
  //       tenDichVuTreEm: dichVuTreEms.tenDichVu,
  //       bgMapCTSaleHtId: mapCTSaleTreEm.bgMapCTSaleHtId,
  //       nccThongTinChungId: mapCTSaleTreEm.nccThongTinChungId,
  //       parentBgMapCTSaleHtId: mapCTSaleTreEm.parentBgMapCTSaleHtId,
  //       tenNhaCungCap: mapCTSaleTreEm.tenNhaCungCap,
  //       dmGiaApDungId: mapCTSaleTreEm.dmGiaApDungId,
  //       dmLoaiApDungId: mapCTSaleTreEm.dmLoaiApDungId,
  //       moTa: mapCTSaleTreEm.moTa,
  //       dieuKienFoc: mapCTSaleTreEm.dieuKienFoc,
  //       foc: mapCTSaleTreEm.foc,
  //       tenDichVu: element.tenDichVu,
  //       bgChietTinhOPId: element.bgChietTinhOPId,
  //       bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
  //       dmDichVuId: element.dmDichVuId,
  //     };
  //   }
  //   if (mapCTSaleTreEm) {
  //     return {
  //       ...mapCTSaleTreEm,
  //       tenDichVu: element.tenDichVu,
  //       bgChietTinhOPId: element.bgChietTinhOPId,
  //       bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
  //       dmDichVuId: element.dmDichVuId,
  //     };
  //   }
  //   if (element)
  //     return {
  //       ...element,
  //     };
  // };

  const mapDataNL = (dichVuNguoiLons, mapCTSaleNguoiLon, element) => {
    if (dichVuNguoiLons) {
      if (element.tenDichVu === "Khách sạn")
        return {
          ...dichVuNguoiLons,
          bgMapCTSaleHtId: mapCTSaleNguoiLon.bgMapCTSaleHtId,
          nccThongTinChungId: mapCTSaleNguoiLon.nccThongTinChungId,
          parentBgMapCTSaleHtId: mapCTSaleNguoiLon.parentBgMapCTSaleHtId,
          tenNhaCungCap: mapCTSaleNguoiLon.tenNhaCungCap,
          dmGiaApDungId: mapCTSaleNguoiLon.dmGiaApDungId,
          dmLoaiApDungId: mapCTSaleNguoiLon.dmLoaiApDungId,
          moTa: mapCTSaleNguoiLon.moTa,
          dieuKienFoc: mapCTSaleNguoiLon.dieuKienFoc,
          foc: mapCTSaleNguoiLon.foc,
          tenDichVu: element.tenDichVu,
          bgChietTinhOPId: element.bgChietTinhOPId,
          bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
          dmDichVuId: element.dmDichVuId,
          bgCTSaleId: element.bgCTSaleId,
        };
      else {
        return {
          ...dichVuNguoiLons,
          bgMapCTSaleHtId: mapCTSaleNguoiLon.bgMapCTSaleHtId,
          nccThongTinChungId: mapCTSaleNguoiLon.nccThongTinChungId,
          parentBgMapCTSaleHtId: mapCTSaleNguoiLon.parentBgMapCTSaleHtId,
          tenNhaCungCap: mapCTSaleNguoiLon.tenNhaCungCap,
          dmGiaApDungId: mapCTSaleNguoiLon.dmGiaApDungId,
          dmLoaiApDungId: mapCTSaleNguoiLon.dmLoaiApDungId,
          moTa: mapCTSaleNguoiLon.moTa,
          tenDichVu: element.tenDichVu,
          bgChietTinhOPId: element.bgChietTinhOPId,
          bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
          dmDichVuId: element.dmDichVuId,
          bgCTSaleId: element.bgCTSaleId,
        };
      }
    }
    if (mapCTSaleNguoiLon)
      return {
        ...mapCTSaleNguoiLon,
        tenDichVu: element.tenDichVu,
        bgChietTinhOPId: element.bgChietTinhOPId,
        bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
        dmDichVuId: element.dmDichVuId,
        bgCTSaleId: element.bgCTSaleId,
      };
    if (element)
      return {
        ...element,
      };
  };

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (nhomNguoiLons && nhomNguoiLons.length > 0) {
      setColumns([
        {
          title: "STT",
        },
        {
          title: t("typeService"),
        },
        {
          title: t("supplier"),
        },
        {
          title: t("service"),
        },
        {
          title: t("luaChon"),
        },
        {
          title: t("hotel.roomClass.conditionFoc"),
        },
        {
          title: t("tour.donGia"),
        },
        {
          title: t("tour.vanChuyen.soLuong"),
        },
        {
          title: t("hotel.roomClass.intoMoney"),
        },
        {
          title: t("thuevat"),
        },
        {
          title: t("adultGroup"),
        },
        {
          title: t("action"),
        },
      ]);
    } else {
      setColumns([
        {
          title: "STT",
        },
        {
          title: t("typeService"),
        },
        {
          title: t("supplier"),
        },
        {
          title: t("service"),
        },
        {
          title: t("luaChon"),
        },
        {
          title: t("hotel.roomClass.conditionFoc"),
        },
        {
          title: t("tour.donGia"),
        },
        {
          title: t("tour.vanChuyen.soLuong"),
        },
        {
          title: t("hotel.roomClass.intoMoney"),
        },
        {
          title: t("thuevat"),
        },
        {
          title: t("action"),
        },
      ]);
    }
  }, [nhomNguoiLons]);
  const columnsTe = [
    {
      title: "STT",
      visible: true,
    },
    {
      title: t("typeService"),
      visible: true,
    },
    {
      title: t("supplier"),
      visible: true,
    },
    {
      title: t("service"),
      visible: true,
    },
    {
      title: t("childrenGroup"),
      visible: dataNhomTreEm.length > 0,
    },
    {
      title: t("ageOfChild"),
      visible: true,
    },
    {
      title: t("tour.percentage"),
      visible: true,
    },
    {
      title: t("tour.donGia"),
      visible: true,
    },
    {
      title: t("tour.vanChuyen.soLuong"),
      visible: true,
    },
    {
      title: t("hotel.roomClass.intoMoney"),
      visible: true,
    },
    {
      title: t("thuevat"),
      visible: true,
    },
    {
      title: t("action"),
      visible: true,
    },
  ];
  const handleAddServices = (item) => {
    if (item.bgMapCTSaleHtId) setHanhDong("EDIT");
    else setHanhDong("ADD");
    setBgChietTinhSaleId(item.bgCTSaleId);
    setDmDichVuId(item.dmDichVuId);
    setBgMapCTSaleHtId(item.bgMapCTSaleHtId ? item.bgMapCTSaleHtId : 0);
    switch (item.tenDichVu) {
      case "Khách sạn":
        return setOpenKs(true);
      case "Xe ô tô":
        return setOpenOto(true);
      case "Nhà hàng":
        return setOpenNh(true);
      default:
        return null;
    }
  };
  const handleCancelKS = () => {
    setOpenKs(false);
  };

  const handleCancelOto = () => {
    setOpenOto(false);
  };

  const handleCancelNh = () => {
    setOpenNh(false);
  };
  const handleAddRowServices = (index, item) => {
    let dt = [];
    let crrFirstDV = dataTableNguoiLon.findIndex(
      (it) => it.rowSpanDV !== 0 && it.tenDichVu === item.tenDichVu
    );
    setEditingId(crrFirstDV);
    let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
    for (let key = 0; key < dataTableNguoiLon.length; key++) {
      const element = dataTableNguoiLon[key];
      if (key === crrFirstDV) {
        dt.push({
          id: Math.random() + "NEW",
          rowSpan: 1,
          parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
          bgChietTinhOPId: item.bgChietTinhOPId,
          bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
          rowSpanDV: rowSpanDV,
          tenDichVu: item.tenDichVu,
          bgCTSaleId: item.bgCTSaleId,
          bgMapCTSaleHtId: item.bgMapCTSaleHtId,
          tenNhaCungCap: "",
          dieuKienFoc: "",
          foc: "",
          donGia: "",
          soLuong: "",
          thueVat: "",
          thanhTien: "",
          dichVu: "",
        });

        element.rowSpanDV = 0;
      } else {
        // if (key === crrFirstDV) {
        //     element.rowSpanDV = rowSpanDV;
        // }
        // // if (key === crrFirst) {
        // //     element.rowSpan = rowSpan;
        // // }
        // if (key === index)
        //     dt.push({
        //         id: Math.random() + "NEW",
        //         rowSpan: 1,
        //         tenDichVu: item.tenDichVu,
        //         bgMapCTSaleHtId: item.bgMapCTSaleHtId,
        //         nccThongTinChungId: item.nccThongTinChungId,
        //         parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
        //         tenNhaCungCap: item.tenNhaCungCap,
        //         dmGiaApDungId: item.dmGiaApDungId,
        //         dmLoaiApDungId: item.dmLoaiApDungId,
        //         bgCTSaleId: item.bgCTSaleId,
        //         moTa: item.moTa,
        //         dieuKienFoc: item.dieuKienFoc,
        //         foc: item.foc,
        //         bgChietTinhOPId: item.bgChietTinhOPId,
        //         bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
        //         dmDichVuId: item.dmDichVuId,
        //     });
      }
      dt.push(element);
    }
    setDataTableNguoiLon(dt);
  };
  const handleAddBySerivce = (dataTableNguoiLon, index, item) => {
    let dt = [];
    let crrFirst = dataTableNguoiLon.findIndex(
      (it) =>
        it.rowSpan !== 0 &&
        it.tenNhaCungCap === item.tenNhaCungCap &&
        it.tenDichVu === item.tenDichVu
    );
    setEditingId(crrFirst);
    let crrFirstDV = dataTableNguoiLon.findIndex(
      (it) => it.rowSpan !== 0 && it.tenDichVu === item.tenDichVu
    );
    let rowSpan = dataTableNguoiLon[crrFirst].rowSpan + 1;
    let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
    for (let key = 0; key < dataTableNguoiLon.length; key++) {
      const element = dataTableNguoiLon[key];
      if (key === crrFirst) {
        dt.push({
          id: Math.random() + "NEW",
          rowSpan: rowSpan,
          tenDichVu: item.tenDichVu,
          bgMapCTSaleHtId: item.bgMapCTSaleHtId,
          nccThongTinChungId: item.nccThongTinChungId,
          tenNhaCungCap: item.tenNhaCungCap,
          bgCTSaleId: item.bgCTSaleId,
          dieuKienFoc: item.dieuKienFoc,
          foc: item.foc,
          bgChietTinhOPId: item.bgChietTinhOPId,
          rowSpanDV: rowSpanDV,
          parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
          bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
          donGia: "",
          soLuong: "",
          thueVat: "",
          thanhTien: "",
          dichVu: "",
        });
        element.rowSpanDV = 0;
        element.rowSpan = 0;
      }

      if (
        element.rowSpan !== 0 &&
        element.tenNhaCungCap === item.tenNhaCungCap &&
        element.tenDichVu === item.tenDichV
      ) {
        element.rowSpanDV = 0;
        element.rowSpan = 0;
      }
      dt.push(element);
    }
    setDataTableNguoiLon(dt);
  };
  const handleChangeTenNCC = (index, value) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) item.tenNhaCungCap = value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const handleChangeDmDichVuId = (index, value, tenDichVu) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index && tenDichVu === "Khách sạn")
        item.dmKieuPhongId = value;
      if (key === index && tenDichVu === "Nhà hàng") item.dmBuaAnId = value;
      if (key === index && tenDichVu === "Xe ô tô") item.soXe = value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const handleChangeDieuKienFoc = (index, value, tenDichVu) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) {
        item.dieuKienFoc = value;
      }

      return item;
    });
    data = tinhToanThanhTable(data);
    setDataTableNguoiLon(data);
  };
  const handleChangeDonGia = (index, value, tenDichVu) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) {
        item.giaDichVu = value;
      }
      return item;
    });
    data = tinhToanThanhTable(data);
    setDataTableNguoiLon(data);
  };
  const handleChangeFoc = (index, value, tenDichVu) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) {
        item.foc = value;
      }
      return item;
    });

    data = tinhToanThanhTable(data);
    setDataTableNguoiLon(data);
  };
  const handleChangeThanhTien = (index, value) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) item.thanhTien = value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const handleChangeVat = (index, value) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) item.vat = value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const tinhToanThanhTable = (data) => {
    let dtChange = [];
    dtChange = data.map((element) => {
      if (element.id.indexOf("NULL") === -1)
        if (element.tenDichVu === "Nhà hàng") {
          element.thanhTien = tinhThanhTienNH(
            element.dieuKienFoc,
            element.giaDichVu,
            element.foc,
            element.soLuong
          );
        } else if (element.tenDichVu === "Xe ô tô") {
          element.thanhTien = element.soLuong * element.giaDichVu;
        } else {
          let dataKS = data.filter(
            (it) =>
              it.tenDichVu === element.tenDichVu &&
              it.tenNhaCungCap === element.tenNhaCungCap
          );
          let thanhTien = tinhThanhTienKS(
            dataKS.map((it) => it.soLuong),
            dataKS.map((it) => it.giaDichVu),
            Math.floor(element.dieuKienFoc / element.foc)
          );
          for (let i = 0; i < dataKS.length; i++) {
            if (dataKS[i].id === element.id) {
              element.thanhTien = thanhTien[i];
            }
          }
        }
      return element;
    });
    return dtChange;
  };
  const handleChangeSoLuong = (index, value, tenDichVu) => {
    setEditingId(index);
    let data = [];
    data = dataTableNguoiLon.map((item, key) => {
      if (key === index) {
        item.soLuong = value;
      }
      return item;
    });

    data = tinhToanThanhTable(data);
    setDataTableNguoiLon(data);
  };
  const handleChangeDichVu = (index, value) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) item.dichVu = value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const handleReload = () => {
    setEditingId(null);
    setReload(true);
  };

  const handleSave = (index, value, tenDichVu) => {
    let dichVus = [];
    let dichVuTreEms = [];

    if (dataTableNguoiLon.length > 0) {
      if (tenDichVu === "Xe ô tô") {
        const dataTe = value.bgNhomNguoiLons?.flatMap((d) =>
          dataNhomTreEm.filter((e) => e.nhomNguoiLonId === d)
        );

        dichVuTreEms = dataTe.map((e) => ({
          bgNhomTreEmId: e.id,
          details: [
            ...e.details.map((d) => ({
              ...d,
              tranxNhaHangId: d.tranxNhaHangId || 0,
            })),
          ],
        }));
      } else {
        const element = dataTableNguoiLon.find(
          (element) => element.tenDichVu === tenDichVu
        );

        if (element) {
          dichVuTreEms = dataNhomTreEm
            .filter((e) => e.nhomNguoiLonId === element.bgNhomNguoiLonId)
            .map((e) => ({
              bgNhomTreEmId: e.id,
              details: [
                ...e.details.map((d) => ({
                  ...d,
                  tranxNhaHangId: d.tranxNhaHangId || 0,
                })),
              ],
            }));
        }
      }
    } else {
      dichVuTreEms = dataNhomTreEm.map((e) => ({
        bgNhomTreEmId: e.id,
        details: [
          ...e.details.map((d) => ({
            ...d,
            tranxNhaHangId: d.tranxNhaHangId || 0,
          })),
        ],
      }));
    }

    for (let i = dataTableNguoiLon.length - 1; i >= 0; i--) {
      const element = dataTableNguoiLon[i];
      if (
        value.bgMapCTSaleHtId === element.bgMapCTSaleHtId &&
        element.tenDichVu === tenDichVu &&
        element.id.indexOf("NULL") === -1
      ) {
        let dichVuData = {
          dmVatId: dmVat.find((d) => element.vat === d.vat)?.id,
          nccThongTinChungId: element.nccThongTinChungId,
          tenDichVu: element.dichVu,
          dmBuaAnId: element.dmBuaAnId,
          dmKieuPhongId: element.dmKieuPhongId,
          dmGiaApDungId: element.dmGiaApDungId,
          tranxGiaPhongId: 0,
          dmSoChoId: element.dmSoChoId,
          bgNhomNguoiLonId: element.bgNhomNguoiLonId,
          tranxNhaHangId: element.tranxNhaHangId || 0,
          nccDichVuNhaHangId: element.nccDichVuNhaHangId || 0,
          dichVuTreEms,
        };

        if (tenDichVu === "Xe ô tô") {
          dichVuData = {
            ...dichVuData,
            giaOtoNhapTay: element.donGia,
            soLuong: element.soLuong,
            bgNhomNguoiLons:
              value.bgNhomNguoiLons?.map((d) => ({ bgNhomNguoiLonId: d })) ||
              [],
            soXe: element.soXe,
          };
        } else if (tenDichVu === "Khách sạn") {
          dichVuData = {
            ...dichVuData,
            donGiaNhapTay: element.donGia,
            soLuongNhapTay: element.soLuong,
          };
        } else if (tenDichVu === "Nhà hàng") {
          dichVuData = {
            ...dichVuData,
            giaNhaHangNhapTay: element.donGia,
            soLuong: element.soLuong,
            dieuKienFoc: value.dieuKienFoc,
            foc: value.foc,
          };
        }

        dichVus.push(dichVuData);
      }
    }

    const data = {
      baoGiaTongQuanId: baoGiaTongQuanId,
      bgMapCTSaleHtId: value.bgMapCTSaleHtId || 0,
      nccThongTinChungId: value.nccThongTinChungId || 0,
      bgCTSaleId: value.bgCTSaleId || 0,
      tenNcc: value.tenNhaCungCap,
      dmDichVuId: value.dmDichVuId,
      dmGiaApDungId: value.dmGiaApDungId,
      dieuKienFoc: tenDichVu === "Nhà hàng" ? 0 : value.dieuKienFoc,
      foc: tenDichVu === "Nhà hàng" ? 0 : value.foc,
      dichVus: dichVus,
    };

    console.log(JSON.stringify(data));

    const saveService = (service, saveFunction) => {
      saveFunction(service).then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          handleReload();
          api["success"]({
            message: t("stour"),
            description: t("add") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: response.data.message,
            duration: 0,
          });
        }
      });
    };

    if (tenDichVu === "Khách sạn") {
      saveService(data, khachSanService.saveCTSaleKhachSan);
    } else if (tenDichVu === "Nhà hàng") {
      saveService(data, nhaHangService.saveChietTinhSaleNhaHang);
    } else if (tenDichVu === "Xe ô tô") {
      saveService(data, otoServiceBG.saveCTSaleOto);
    }
  };
  const handleDelete = (key, value) => {
    khachSanService
      .deleteCTSaleDichVuKs({
        tranxBgChietTinhKsId: value.tranxBgChietTinhKsId,
      })
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          handleReload();
          api["success"]({
            message: t("stour"),
            description: t("add") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: response.data.message,
            duration: 0,
          });
        }
      });
  };
  const handleClear = () => {
    handleReload();
  };
  const handleChangeNhomNLOto = (index, event) => {
    const {
      target: { value },
    } = event;
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index)
        item.bgNhomNguoiLons =
          typeof value === "string" ? value.split(",") : value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const handleChangeNhomNL = (index, value) => {
    setEditingId(index);
    let data = dataTableNguoiLon.map((item, key) => {
      if (key === index) item.bgNhomNguoiLonId = value;
      return item;
    });

    setDataTableNguoiLon(data);
  };
  const tinhThanhTienNH = (
    numberOfRates,
    unitPrice,
    FOCConditions,
    countOfService
  ) => {
    debugger;
    // Calculate FOC quantity
    const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

    // Calculate total service cost
    const totalServiceCost = countOfService * unitPrice;

    // Calculate service cost after applying FOC policy
    return totalServiceCost - FOCQuantity ? FOCQuantity : 0 * unitPrice;
  };
  const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
    //FOCConditions dk foc
    //numberOfRoomsBooked: số lượng
    //unitPrices đơn giá
    // Define variables
    const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce(
      (acc, val) => acc + val,
      0
    );
    // Calculate B5
    const lowestRoomCategoryPrice = Math.min(...unitPrices);
    const averageReducedPricePerRoom =
      (lowestRoomCategoryPrice *
        Math.floor(tongSoLuongPhongNCC / FOCConditions)) /
      tongSoLuongPhongNCC;

    // Calculate B6
    return numberOfRoomsBooked.map(
      (count, index) =>
        count * unitPrices[index] - averageReducedPricePerRoom * count
    );
  };
  const table_nl = () => {
    return (
      <>
        <table>
          <thead>
            {columns.map((cl) => {
              return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
            })}
          </thead>
          <tbody>
            {dataTableNguoiLon?.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  {item.rowSpanDV && item.rowSpanDV !== 0 ? (
                    <td rowSpan={item.rowSpanDV}>{item.tenDichVu}</td>
                  ) : (
                    <></>
                  )}
                  {item.id.indexOf("NULL") !== -1 ? (
                    <>
                      <>
                        <td colSpan="100%">
                          <Link
                            onClick={() => handleAddRowServices(key, item)}
                            disabled={
                              editingId === null ? false : editingId !== key
                            }
                          >
                            {t("add") + " " + t("service").toLowerCase()}
                          </Link>
                        </td>
                      </>
                    </>
                  ) : (
                    <>
                      {item.rowSpan && item.rowSpan !== 0 ? (
                        <td rowSpan={item.rowSpan}>
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              disabled={
                                editingId === null ? false : editingId !== key
                              }
                              size="small"
                              value={item.tenNhaCungCap}
                              onChange={(e) =>
                                handleChangeTenNCC(key, e.target.value)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton size="small">
                                      <EditFilled
                                        onClick={() =>
                                          handleAddServices(
                                            item
                                            // baoGiaHanhTrinhId,
                                            // ngay
                                          )
                                        }
                                      />
                                    </IconButton>
                                    <IconButton size="small">
                                      <CloseCircleFilled />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {item.tenDichVu === "Khách sạn" ? (
                              <IconButton
                                onClick={() =>
                                  handleAddBySerivce(
                                    dataTableNguoiLon,
                                    key,
                                    item
                                  )
                                }
                              >
                                <PlusSquareFilled />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </td>
                      ) : (
                        <></>
                      )}
                      <td>
                        <TextField
                          disabled={
                            editingId === null ? false : editingId !== key
                          }
                          size="small"
                          value={item.dichVu}
                          onChange={(e) =>
                            handleChangeDichVu(key, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        {item.tenDichVu === "Khách sạn" ? (
                          <TextField
                            disabled={
                              editingId === null ? false : editingId !== key
                            }
                            size="small"
                            select
                            value={item.dmKieuPhongId}
                            onChange={(e) =>
                              handleChangeDmDichVuId(
                                key,
                                e.target.value,
                                item.tenDichVu
                              )
                            }
                          >
                            {kieuPhong.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : item.tenDichVu === "Nhà hàng" ? (
                          <TextField
                            disabled={
                              editingId === null ? false : editingId !== key
                            }
                            size="small"
                            select
                            value={item.dmBuaAnId}
                            onChange={(e) =>
                              handleChangeDmDichVuId(
                                key,
                                e.target.value,
                                item.tenDichVu
                              )
                            }
                          >
                            {dmBuaAn.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <>
                            <TextField
                              disabled={
                                editingId === null ? false : editingId !== key
                              }
                              size="small"
                              value={item.dmDichVuId}
                              onChange={(e) =>
                                handleChangeDmDichVuId(
                                  key,
                                  e.target.value,
                                  item.tenDichVu
                                )
                              }
                            />
                          </>
                        )}
                      </td>
                      {item.rowSpan && item.rowSpan !== 0 ? (
                        <td rowSpan={item.rowSpan}>
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            {item.tenDichVu === "Xe ô tô" ? (
                              <>
                                <TextField size="small" />
                              </>
                            ) : (
                              <>
                                <TextField
                                  disabled={
                                    editingId === null
                                      ? false
                                      : editingId !== key
                                  }
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeDieuKienFoc(
                                      key,
                                      e.target.value,
                                      item.tenDichVu
                                    )
                                  }
                                  value={item.dieuKienFoc}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {item.tenDichVu === "Khách sạn"
                                          ? t("room")
                                          : t("set")}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <TextField
                                  disabled={
                                    editingId === null
                                      ? false
                                      : editingId !== key
                                  }
                                  size="small"
                                  value={item.foc}
                                  onChange={(e) =>
                                    handleChangeFoc(
                                      key,
                                      e.target.value,
                                      item.tenDichVu
                                    )
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {t("hotel.roomClass.foc")}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </>
                            )}
                          </Box>
                        </td>
                      ) : (
                        <></>
                      )}
                      <td>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            disabled={
                              editingId === null ? false : editingId !== key
                            }
                            size="small"
                            value={item.giaDichVu}
                            onChange={(e) =>
                              handleChangeDonGia(
                                key,
                                e.target.value,
                                item.tenDichVu
                              )
                            }
                            type="number"
                          />
                        </Box>
                      </td>
                      <td>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            disabled={
                              editingId === null ? false : editingId !== key
                            }
                            size="small"
                            onChange={(e) =>
                              handleChangeSoLuong(
                                key,
                                e.target.value,
                                item.tenDichVu
                              )
                            }
                            value={item.soLuong}
                            type="number"
                          />
                        </Box>
                      </td>
                      <td>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            disabled
                            size="small"
                            value={item.thanhTien}
                            onChange={(e) =>
                              handleChangeThanhTien(key, e.target.value)
                            }
                          />
                        </Box>
                      </td>
                      <td>
                        <TextField
                          disabled={
                            editingId === null ? false : editingId !== key
                          }
                          size="small"
                          select
                          value={item.vat}
                          onChange={(e) => handleChangeVat(key, e.target.value)}
                        >
                          {dmVat.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </td>
                      {nhomNguoiLons && nhomNguoiLons.length > 0 ? (
                        <>
                          {item.tenDichVu === "Xe ô tô" ? (
                            <td>
                              <Select
                                disabled={
                                  editingId === null ? false : editingId !== key
                                }
                                size="small"
                                multiple
                                value={
                                  item.bgNhomNguoiLons
                                    ? item.bgNhomNguoiLons
                                    : []
                                }
                                onChange={(e) => handleChangeNhomNLOto(key, e)}
                              >
                                {nhomNguoiLons.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.tenNhom}
                                  </MenuItem>
                                ))}
                              </Select>
                            </td>
                          ) : (
                            <td>
                              <TextField
                                disabled={
                                  editingId === null ? false : editingId !== key
                                }
                                size="small"
                                select
                                value={item.bgNhomNguoiLonId}
                                onChange={(e) =>
                                  handleChangeNhomNL(key, e.target.value)
                                }
                              >
                                {nhomNguoiLons.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.tenNhom}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </td>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <td>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <IconButton
                            disabled={
                              editingId === null ? false : editingId !== key
                            }
                            onClick={() =>
                              handleSave(key, item, item.tenDichVu)
                            }
                          >
                            <CheckOutlined />
                          </IconButton>
                          {editingId !== key ? (
                            <IconButton
                              onClick={() => handleDelete(key, item)}
                              disabled={
                                editingId === null ? false : editingId !== key
                              }
                            >
                              <DeleteOutlined />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleClear()}>
                              <CloseOutlined />
                            </IconButton>
                          )}
                        </Box>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };
  const handleAddRowServicesTE = (dataTableTreEm, index, item) => {
    let dt = [];
    let crrFirst = dataTableTreEm.findIndex(
      (it) =>
        it.rowSpan !== 0 &&
        it.tenDichVu === item.tenDichVu &&
        it.tenNhaCungCap === item.tenNhaCungCap &&
        it.dichVuTreEms === item.dichVuTreEms
    );
    let rowSpan = dataTableTreEm[crrFirst].rowSpan + 1;
    let crrFirstDV = dataTableTreEm.findIndex(
      (it) => it.rowSpanDV !== 0 && it.tenDichVu === item.tenDichVu
    );
    let rowSpanDV = dataTableTreEm[crrFirstDV].rowSpanDV + 1;
    for (let key = 0; key < dataTableTreEm.length; key++) {
      const element = dataTableTreEm[key];
      if (index === crrFirst && key === crrFirst && index === crrFirstDV) {
        dt.push({
          id: Math.random() + "NEW",
          rowSpan: rowSpan,
          rowSpanDV: rowSpanDV,
          bgMapCTSaleHtId: item.bgMapCTSaleHtId,
          nccThongTinChungId: item.nccThongTinChungId,
          parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
          tenNhaCungCap: item.tenNhaCungCap,
          dmGiaApDungId: item.dmGiaApDungId,
          dmLoaiApDungId: item.dmLoaiApDungId,
          moTa: item.moTa,
          dieuKienFoc: item.dieuKienFoc,
          foc: item.foc,
          tenDichVu: item.tenDichVu,
          bgChietTinhOPId: item.bgChietTinhOPId,
          bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
          dmDichVuId: item.dmDichVuId,
        });

        element.rowSpan = 0;
        element.rowSpanDV = 0;
      } else {
        if (key === crrFirstDV) {
          element.rowSpanDV = rowSpanDV;
        }
        if (key === crrFirst) {
          element.rowSpan = rowSpan;
        }
        if (key === index && key === crrFirst) {
          element.rowSpan = 0;
          dt.push({
            id: Math.random() + "NEW",
            bgMapCTSaleHtId: item.bgMapCTSaleHtId,
            nccThongTinChungId: item.nccThongTinChungId,
            parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
            tenNhaCungCap: item.tenNhaCungCap,
            dmGiaApDungId: item.dmGiaApDungId,
            dmLoaiApDungId: item.dmLoaiApDungId,
            moTa: item.moTa,
            rowSpan: rowSpan,
            dieuKienFoc: item.dieuKienFoc,
            foc: item.foc,
            tenDichVu: item.tenDichVu,
            bgChietTinhOPId: item.bgChietTinhOPId,
            bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
            dmDichVuId: item.dmDichVuId,
          });
        } else if (key === index && key !== crrFirst) {
          dt.push({
            id: Math.random() + "NEW",
            bgMapCTSaleHtId: item.bgMapCTSaleHtId,
            nccThongTinChungId: item.nccThongTinChungId,
            parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
            tenNhaCungCap: item.tenNhaCungCap,
            dmGiaApDungId: item.dmGiaApDungId,
            dmLoaiApDungId: item.dmLoaiApDungId,
            moTa: item.moTa,
            dieuKienFoc: item.dieuKienFoc,
            foc: item.foc,
            tenDichVu: item.tenDichVu,
            bgChietTinhOPId: item.bgChietTinhOPId,
            bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
            dmDichVuId: item.dmDichVuId,
            rowSpan: 0,
          });
        }
      }
      dt.push(element);
    }
    setDataTableTreEm(dt);
  };

  const handleAddAge = (dataTE, row) => {
    let indexAdd = dataTE.findIndex((it) => it.id === row.id);
    let dataRowAdd = { ...dataTE[indexAdd] };
    dataTE[indexAdd].rowSpanLoaiDV = 0;
    dataTE[indexAdd].rowSpanNcc = 0;
    dataTE[indexAdd].rowSpanDV = 0;

    dataTE.splice(indexAdd, 0, {
      id: Math.random() + "",
      rowSpanLoaiDV: 0,
      rowSpanNcc: 0,
      rowSpanDV: 0,
      loaiDichVu: row.loaiDichVu,
      tenNhaCungCap: row.tenNhaCungCap,
      tenDichVu: row.tenDichVu,
      giaDichVu: row.giaDichVu,
      bgCTSaleId: row.bgCTSaleId,
      dmDichVuId: row.dmDichVuId,
      mapBgChietTinhKsTreEmId: row.mapBgChietTinhKsTreEmId,
      mapBgChietTinhNhTreEmId: row.mapBgChietTinhNhTreEmId,
      mapBgChietTinhOtoTreEmId: row.mapBgChietTinhOtoTreEmId,
      tranxBgChietTinhKsId: row.tranxBgChietTinhKsId,
      tranxBgChietTinhNhId: row.tranxBgChietTinhNhId,
      tranxBgChietTinhOtoId: row.tranxBgChietTinhOtoId,
      phanTram: 0,
      soLuong: 0,
      dmDoTuoiId: 1,
      dmVatId: 1,
    });

    let crrFirstLoaiDichVu = dataTE.findIndex(
      (it) => it.loaiDichVu === row.loaiDichVu
    );
    let crrFirstNcc = dataTE.findIndex(
      (it) =>
        it.rowSpanNcc !== 0 &&
        it.loaiDichVu === row.loaiDichVu &&
        it.tenNhaCungCap === row.tenNhaCungCap
    );
    let crrFirstDV = dataTE.findIndex(
      (it) =>
        it.rowSpanDV !== 0 &&
        it.loaiDichVu === row.loaiDichVu &&
        it.tenNhaCungCap === row.tenNhaCungCap &&
        it.tenDichVu === row.tenDichVu
    );
    if (crrFirstLoaiDichVu !== -1) {
      dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV += dataRowAdd.rowSpanLoaiDV + 1;
      // dataTE[crrFirstLoaiDichVu].stt = dataRowAdd.stt;
    }
    if (crrFirstNcc !== -1) {
      dataTE[crrFirstNcc].rowSpanNcc += 1;
    } else {
      dataTE[indexAdd].rowSpanNcc += dataRowAdd.rowSpanNcc + 1;
      // dataTE[indexAdd].rowSpanNcc += 2;
    }
    if (crrFirstDV !== -1) {
      dataTE[crrFirstDV].rowSpanDV += 1;
    } else {
      dataTE[indexAdd].rowSpanDV += 2;
    }

    setDataTableTreEm([...dataTE]);
  };

  const handleSaveCTDoTuoi = async (row) => {
    let action = "edit";
    let dataReq = {
      baoGiaTongQuanId,
      bgCTSaleId: row.bgCTSaleId,
      dmDoTuoiId: row.dmDoTuoiId,
      phanTram: row.phanTram,
      soLuong: row.soLuong,
      dmVatId: row.dmVatId,
      apDung: true,
    };
    let response = { status: 500 };
    if (row.loaiDichVu === "Khách sạn") {
      if (row.tranxBgCtKsSaleTreEmId)
        dataReq.tranxBgCtKsSaleTreEmId = row.tranxBgCtKsSaleTreEmId;
      else {
        dataReq.tranxBgCtKsSaleTreEmId = 0;
        action = "add";
      }
      dataReq.mapBgChietTinhKsTreEmId = row.mapBgChietTinhKsTreEmId;
      dataReq.tranxBgChietTinhKsId = row.tranxBgChietTinhKsId;
      response = await khachSanService.saveChietTinhDoTuoi(dataReq);
    } else if (row.loaiDichVu === "Nhà hàng") {
      if (row.tranxBgCtNhSaleTreEmId)
        dataReq.tranxBgCtNhSaleTreEmId = row.tranxBgCtNhSaleTreEmId;
      else {
        dataReq.tranxBgCtNhSaleTreEmId = 0;
        action = "add";
      }
      dataReq.mapBgCtNhSaleTreEmId = row.mapBgChietTinhNhTreEmId;
      dataReq.tranxBgChietTinhNhId = row.tranxBgChietTinhNhId;
      response = await nhaHangService.saveChietTinhDoTuoi(dataReq);
    } else {
      if (row.tranxBgCtNhSaleTreEmId)
        dataReq.tranxBgCtOtoSaleTreEmId = row.tranxBgCtOtoSaleTreEmId;
      else {
        dataReq.tranxBgCtOtoSaleTreEmId = 0;
        action = "add";
      }
      dataReq.mapBgCtOtoSaleTreEmId = row.mapBgChietTinhOtoTreEmId;
      dataReq.tranxBgChietTinhOtoId = row.tranxBgChietTinhOtoId;
      dataReq.giaNguoiLon = row.giaDichVu;
    }
    if (response.status === 200 && response.data.code === "00") {
      handleReload();
      showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
    } else {
      showNoti("error", t("add") + " " + t("noti.error").toLowerCase());
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const handleDeleteAge = async (dataTE, row) => {
    let isDeleted = false;
    let res = { status: 500 };
    if (row.loaiDichVu === "Khách sạn" && row.tranxBgCtKsSaleTreEmId) {
      res = await khachSanService.deleteChietTinhDoTuoi({
        baoGiaTongQuanId,
        bgCTSaleId: row.bgCTSaleId,
        tranxBgCtKsSaleTreEmId: row.tranxBgCtKsSaleTreEmId,
      });
      handleShowNotiDeleteTE(res);
      handleReload();
    } else if (row.loaiDichVu === "Nhà hàng" && row.tranxBgCtNhSaleTreEmId) {
      res = await nhaHangService.deleteChietTinhDoTuoi({
        baoGiaTongQuanId,
        bgCTSaleId: row.bgCTSaleId,
        tranxBgCtNhSaleTreEmId: row.tranxBgCtNhSaleTreEmId,
      });
      handleShowNotiDeleteTE(res);
      handleReload();
    } else if (row.loaiDichVu === "Xe ô tô" && row.tranxBgCtOtoSaleTreEmId) {
      res = await otoService.deleteChietTinhDoTuoi({
        baoGiaTongQuanId,
        bgCTSaleId: row.bgCTSaleId,
        tranxBgCtKsSaleTreEmId: row.tranxBgChietTinhKsId,
      });
      handleShowNotiDeleteTE(res);
      handleReload();
    } else {
      isDeleted = true;
      showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
    }
    if (isDeleted) {
      let indexDeleted = dataTE.findIndex((it) => it.id === row.id);
      let dataDeleted = dataTE[indexDeleted];
      dataTE.splice(indexDeleted, 1);
      let crrFirstLoaiDichVu = dataTE.findIndex(
        (it) => it.rowSpanLoaiDV !== 0 && it.loaiDichVu === row.loaiDichVu
      );
      let crrFirstNcc = dataTE.findIndex(
        (it) =>
          it.rowSpanNcc !== 0 &&
          it.loaiDichVu === row.loaiDichVu &&
          it.tenNhaCungCap === row.tenNhaCungCap
      );
      let crrFirstDV = dataTE.findIndex(
        (it) =>
          it.rowSpanDV !== 0 &&
          it.loaiDichVu === row.loaiDichVu &&
          it.tenNhaCungCap === row.tenNhaCungCap &&
          it.tenDichVu === row.tenDichVu
      );
      if (crrFirstLoaiDichVu !== -1) {
        dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV -= 1;
      } else {
        dataTE[indexDeleted].rowSpanLoaiDV = dataDeleted.rowSpanLoaiDV - 1;
        dataTE[indexDeleted].stt = dataDeleted.stt;
      }
      if (crrFirstNcc !== -1) {
        dataTE[crrFirstNcc].rowSpanNcc -= 1;
      } else {
        if (dataDeleted.rowSpanNcc !== 0)
          dataTE[indexDeleted].rowSpanNcc = dataDeleted.rowSpanNcc - 1;
        else dataTE[indexDeleted].rowSpanNcc = 1;
      }
      if (crrFirstDV !== -1) {
        dataTE[crrFirstDV].rowSpanDV -= 1;
      } else {
        if (dataDeleted.rowSpanDV !== 0)
          dataTE[indexDeleted].rowSpanDV = dataDeleted.rowSpanDV - 1;
        else dataTE[indexDeleted].rowSpanDV = 1;
      }

      setDataTableTreEm([...dataTE]);
    }
  };

  const handleShowNotiDeleteTE = (res) => {
    if (res.status === 200 && res.data.code === "00") {
      showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
      return true;
    } else {
      showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
      return false;
    }
  };

  const handleChangItemTE = (dataTE, row, e) => {
    let indexChange = dataTE.findIndex((it) => it.id === row.id);
    let name = e.target.name;
    let value = e.target.value;
    dataTE[indexChange][name] = value;
    if (name !== "dmDoTuoiId") {
      dataTE[indexChange].thanhTien =
        (dataTE[indexChange].phanTram / 100) *
        dataTE[indexChange].soLuong *
        dataTE[indexChange].giaDichVu;
    }
    setDataTableTreEm([...dataTE]);
  };

  const table_te = () => {
    let stt = 1;
    return (
      <>
        <table>
          <thead>
            {columnsTe
              .filter((it) => it.visible)
              .map((cl) => {
                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
              })}
          </thead>
          <tbody>
            {dataTableTreEm?.map((it, key) => {
              return (
                <tr key={it.id}>
                  {it.rowSpanLoaiDV !== 0 ? (
                    <>
                      <td rowSpan={it.rowSpanLoaiDV}>{stt++}</td>
                      <td rowSpan={it.rowSpanLoaiDV}>{it.loaiDichVu}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {it.rowSpanNcc !== 0 ? (
                    <td rowSpan={it.rowSpanNcc}>{it.tenNhaCungCap}</td>
                  ) : null}
                  {it.rowSpanDV !== 0 ? (
                    <td rowSpan={it.rowSpanDV}>{it.tenDichVu}</td>
                  ) : null}
                  {it.id.indexOf("NEW_ADD") !== -1 ? (
                    <>
                      <td colSpan="100%">
                        <Link onClick={() => handleAddAge(dataTableTreEm, it)}>
                          {t("add") + " " + t("age").toLowerCase()}
                        </Link>
                      </td>
                    </>
                  ) : (
                    <>
                      {dataNhomTreEm.length > 0 && (
                        <td>
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              value={it.tenNhomTreEm}
                              size="small"
                              disabled
                            />
                          </Box>
                        </td>
                      )}
                      <td width={"10%"}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            variant="outlined"
                            value={it.dmDoTuoiId}
                            size="small"
                            name="dmDoTuoiId"
                            select
                            disabled={dataNhomTreEm.length > 0}
                            onChange={(e) =>
                              handleChangItemTE(dataTableTreEm, it, e)
                            }
                          >
                            {dmDoTuoi.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.do_tuoi}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      </td>
                      <td width={"8%"}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            type="number"
                            value={it.phanTram}
                            size="small"
                            name="phanTram"
                            onChange={(e) =>
                              handleChangItemTE(dataTableTreEm, it, e)
                            }
                            InputProps={{
                              endAdornment: <>%</>,
                            }}
                          />
                        </Box>
                      </td>
                      <td width={"10%"}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            value={it.giaDichVu}
                            size="small"
                            name="giaDichVu"
                            disabled={true}
                          ></TextField>
                        </Box>
                      </td>
                      <td width={"8%"}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            type="number"
                            value={it.soLuong}
                            name="soLuong"
                            size="small"
                            onChange={(e) =>
                              handleChangItemTE(dataTableTreEm, it, e)
                            }
                          />
                        </Box>
                      </td>
                      <td width={"10%"}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            value={
                              it.giaDichVu * it.soLuong * (it.phanTram / 100)
                            }
                            size="small"
                            disabled={true}
                          />
                        </Box>
                      </td>
                      <td width={"9%"}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={it.dmVatId}
                            size="small"
                            name="dmVatId"
                            select
                            onChange={(e) =>
                              handleChangItemTE(dataTableTreEm, it, e)
                            }
                          >
                            {dmVat.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      </td>
                      <td width={"9%"}>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <IconButton>
                              <CheckIcon
                                onClick={() => handleSaveCTDoTuoi(it)}
                              />
                            </IconButton>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <IconButton>
                              <Delete
                                onClick={() =>
                                  handleDeleteAge(dataTableTreEm, it)
                                }
                              />
                            </IconButton>
                          </Col>
                        </Row>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  const item_date = [
    {
      key: "1",
      label: (
        <Row>
          <Col span={20}>
            <div
              style={{
                textTransform: "none",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              {t("adultService")}
            </div>
          </Col>
        </Row>
      ),
      children: <>{table_nl()}</>,
    },
    {
      key: "2",
      label: (
        <Row>
          <Col span={20}>
            <div
              style={{
                textTransform: "none",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              {t("childrenService")}
            </div>
          </Col>
        </Row>
      ),
      children: <>{table_te()}</>,
    },
  ];
  const children_dv = (
    <>
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={"1"} items={item_date} />
        </Col>
      </Row>
    </>
  );
  const cauHinh = [
    {
      key: "1",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("serviceProvided")}
        </div>
      ),
      children: children_dv,
    },
    {
      key: "2",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("totalPrice")}
        </div>
      ),
      children: (
        <>
          <TongKet
            baoGiaTongQuanId={baoGiaTongQuanId}
            tongGia={tongGia}
            setReload={setReload}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Collapse defaultActiveKey={"1"} items={cauHinh} />
      <ModalCapNhatKS
        isOpen={openKs}
        onReset={handleReload}
        handleCancel={handleCancelKS}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataNhomTreEm}
        baoGiaHanhTrinhId={0}
        baoGiaTongQuanId={baoGiaTongQuanId}
        bgChietTinhSaleId={bgChietTinhSaleId}
        dmDichVuId={dmDichVuId}
        bgMapCTSaleHtId={bgMapCTSaleHtId}
        soLuongTreEmBG={soLuongTreEmBG}
        hanhDong={hanhDong}
        soNgay={soNgay}
        ngay={0}
        bgHanhTrinhs={bgHanhTrinhs}
      />
      <ModalCapNhatOto
        isOpen={openOto}
        onReset={handleReload}
        handleCancel={handleCancelOto}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataNhomTreEm}
        baoGiaHanhTrinhId={0}
        baoGiaTongQuanId={baoGiaTongQuanId}
        bgChietTinhSaleId={bgChietTinhSaleId}
        dmDichVuId={dmDichVuId}
        bgMapCTSaleHtId={bgMapCTSaleHtId}
        soLuongTreEmBG={soLuongTreEmBG}
        soLuongNgLonBG={soLuongNgLonBG}
        hanhDong={hanhDong}
        soNgay={soNgay}
        ngay={0}
        bgHanhTrinhs={bgHanhTrinhs}
      />
      <ModalCapNhatNhaHang
        isOpen={openNH}
        onReset={handleReload}
        handleCancel={handleCancelNh}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataNhomTreEm}
        baoGiaHanhTrinhId={0}
        baoGiaTongQuanId={baoGiaTongQuanId}
        bgChietTinhSaleId={bgChietTinhSaleId}
        dmDichVuId={dmDichVuId}
        bgMapCTSaleHtId={bgMapCTSaleHtId}
        soLuongTreEmBG={soLuongTreEmBG}
        hanhDong={hanhDong}
        soNgay={soNgay}
        ngay={0}
        bgHanhTrinhs={bgHanhTrinhs}
      />
    </>
  );
};
export default ViewServies;
