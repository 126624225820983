import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";
const deleteDichVuTour = (data) => {
  return axios.post(API_BASE_URL + "booking/deleteDichVuTour", data, {
    headers: authHeader(),
  });
};
const updateDichVuTour = (data) => {
  return axios.post(API_BASE_URL + "booking/updateDichVuTour", data, {
    headers: authHeader(),
  });
};
const layTTDichVuTourByBkThongTinChungId = (data) => {
  return axios.post(
    API_BASE_URL + "booking/layTTDichVuTourByBkThongTinChungId",
    data,
    { headers: authHeader() }
  );
};
const layTTBkThongTinTourById = (data) => {
  return axios.post(API_BASE_URL + "booking/layTTBkThongTinTourById", data, {
    headers: authHeader(),
  });
};
const saveBkThongTinTour = (data) => {
  return axios.post(API_BASE_URL + "booking/saveBkThongTinTour", data, {
    headers: authHeader(),
  });
};

const getDataNhomNgLonVaTreEm = (data) => {
  return axios.post(API_BASE_URL + "booking/layDataNhomNguoiLonBooking", data, {
    headers: authHeader(),
  });
};
export default {
  deleteDichVuTour,
  updateDichVuTour,
  layTTDichVuTourByBkThongTinChungId,
  layTTBkThongTinTourById,
  saveBkThongTinTour,
  getDataNhomNgLonVaTreEm,
};
