import axios from "axios";
import authHeader from "../../../auth_header";
import { API_BASE_URL } from "../../../../constants";

const getChietTinhSaleKS = (data) => {
    return axios.post(API_BASE_URL + "baogia/layTTCTSaleKhachSanById", data, {
        headers: authHeader(),
    });
};
const saveChietTinhSaleKS = (data) => {
    return axios.post(API_BASE_URL + "baogia/saveCTSaleKhachSan", data, {
        headers: authHeader(),
    });
};
const saveCTSaleKhachSan = (data) => {
    return axios.post(API_BASE_URL + "baogia/saveCTSaleKhachSan", data, {
        headers: authHeader(),
    });
};
const deleteCTSaleKhachSan = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteCTSaleKhachSan", data, {
        headers: authHeader(),
    });
};
const deleteCTSaleDichVuKs = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteCTSaleDichVuKs", data, {
        headers: authHeader(),
    });
};

const saveChietTinhDoTuoi = (data) => {
    return axios.post(API_BASE_URL + "baogia/saveCTDoTuoiSaleKhachSan", data, {
        headers: authHeader(),
    });
};

const deleteChietTinhDoTuoi = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteCTDoTuoiSaleKhachSan", data, {
        headers: authHeader(),
    });
};

const deleteCTSaleNhaHang = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteCTSaleNhaHang", data, {
        headers: authHeader(),
    });
};
export default {
    getChietTinhSaleKS,
    saveChietTinhSaleKS,
    saveCTSaleKhachSan,
    deleteCTSaleKhachSan,
    deleteCTSaleDichVuKs,
    saveChietTinhDoTuoi,
    deleteChietTinhDoTuoi,
};
