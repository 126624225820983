import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarQuickFilter, enUS, viVN } from "@mui/x-data-grid";
import { Button, Col, Input, Popconfirm, Row, Space, Typography, notification } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import i18n from "../../../../../i18n";
import FormLp from "./FormLp";
import ModalLpAction from "./ModalLpAction";
const RoomType = () => {
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation();
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [dataSearch, setDataSearch] = useState();
    const [action, setAction] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
    };
    const [api, contextHolder] = notification.useNotification();
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc == true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        setDataSource(newData);
    };
    useEffect(() => {
        let data = {
            nccThongTinChungId: 5,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i + 1,
                                id: data[i].id,
                                nccThongTinChungId: data[i].nccThongTinChungId,
                                loaiCabin: data[i].loaiCabin,
                                soLuong: data[i].soLuong,
                                soKhach: data[i].soKhach,
                                kichThuoc: data[i].kichThuoc,
                                foc: data[i].foc,
                                dieuKienFoc: data[i].foc + " " + t("room") + "/ " + data[i].poc + " FOC",
                                poc: data[i].poc,
                                ghiChu: data[i].ghiChu,
                            });
                            dataLstSearch.push({
                                value:
                                    data[i].id +
                                    " - " +
                                    data[i].nccThongTinChungId +
                                    " - " +
                                    data[i].loaiCabin +
                                    " - " +
                                    data[i].soLuong +
                                    " - " +
                                    data[i].soKhach +
                                    " - " +
                                    data[i].kichThuoc +
                                    " - " +
                                    data[i].foc +
                                    " - " +
                                    data[i].poc +
                                    " - " +
                                    data[i].ghiChu,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [t]);
    const onSearch = (value) => {
        let data = [];
        for (let i in dataSearch) {
            if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
                let item = dataSearch[i].value.split(" - ");
                data.push({
                    stt: i,
                    id: item[0],
                    nccThongTinChungId: item[1],
                    loaiCabin: item[2],
                    soLuong: item[3],
                    soKhach: item[4],
                    kichThuoc: item[5],
                    foc: item[6],
                    poc: item[7],
                    ghiChu: item[8],
                });
            }
        }
        setDataSource(data);
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    const getColumnSearchProps = (field) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, field)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === field ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("roomType"),
            field: "loaiCabin",
            key: "loaiCabin",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.roomClass.numberOfRoom"),
            field: "soLuong",
            key: "soLuong",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.roomClass.numberOfGuests1Room"),
            field: "soKhach",
            key: "soKhach",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.roomClass.conditionFoc"),
            field: "dieuKienFoc",
            key: "dieuKienFoc",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("notes"),
            field: "ghiChu",
            key: "ghiChu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space size="large">
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)} />
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                headerName={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            align: "center",
        },
    ];
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
        console.log(values);
        let data = {
            ...values,
            id: values.id,
            nccThongTinChungId: 5,
            kichThuocPhong: values.kichThuocPhong !== undefined ? values.kichThuocPhong : "",
            foc: values.foc !== undefined ? values.foc : "",
            poc: values.poc !== undefined ? values.poc : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
        };
        console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let updatedList = dataSource.map((item) => {
                        if (item.id === values.id) {
                            return {
                                stt: item.stt,
                                id: values.id,
                                nccThongTinChungId: values.nccThongTinChungId,
                                loaiCabin: values.loaiCabin,
                                soLuong: values.soLuong,
                                soKhach: values.soKhach,
                                kichThuoc: values.kichThuoc,
                                foc: values.foc,
                                dieuKienFoc: values.foc + " " + t("room") + "/ " + values.poc + " FOC",
                                poc: values.poc,
                                ghiChu: values.ghiChu,
                            };
                        }
                        return item;
                    });
                    setDataSource(updatedList);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormLp handleSearch={onSearch} />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <DataGrid
                onRowClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                autoHeight
                {...dataSource}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
            <ModalLpAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default RoomType;
