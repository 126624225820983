import React, { useEffect, useState, useRef } from "react";
import { Table, Typography, Space, Input, Button, Popconfirm, Row, Col } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import Highlighter from "react-highlight-words";
import moment from "moment";
import FormTk from "./FormTK";
import ModalTkAction from "./ModalTkAction";
import { DataGrid, enUS, viVN, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import i18n from "../../../../../i18n";
const AccountBanking = ({ dvName, dvId, isView }) => {
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation();
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [dataSearch, setDataSearch] = useState();
    const [action, setAction] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
    };
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };
    useEffect(() => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i,
                                id: data[i].id,
                                tenNganHang: data[i].tenNganHang,
                                dmNganHangId: data[i].dmNganHangId,
                                soTaiKhoan: data[i].soTaiKhoan,
                                chuSoHuu: data[i].chuSoHuu,
                                chiNhanh: data[i].chiNhanh,
                                maSwift: data[i].maSwift,
                                ghiChu: data[i].ghiChu,
                                capNhatCuoi: moment(data[i].capNhatCuoi).format("DD-MM-YYYY"),
                                qrPath: data[i].qrPath,
                            });
                            dataLstSearch.push({
                                value:
                                    data[i].id +
                                    " - " +
                                    data[i].tenNganHang +
                                    " - " +
                                    data[i].soTaiKhoan +
                                    " - " +
                                    data[i].chuSoHuu +
                                    " - " +
                                    data[i].chiNhanh +
                                    " - " +
                                    data[i].maSwift +
                                    " - " +
                                    data[i].ghiChu +
                                    " - " +
                                    data[i].capNhatCuoi,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [dvId]);
    const onSearch = (value) => {
        let data = [];
        for (let i in dataSearch) {
            if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
                let item = dataSearch[i].value.split(" - ");
                data.push({
                    stt: i,
                    id: item[0],
                    tenNganHang: item[1],
                    soTaiKhoan: item[2],
                    chuSoHuu: item[3],
                    chiNhanh: item[4],
                    maSwift: item[5],
                    ghiChu: item[6],
                    capNhatCuoi: item[7],
                });
            }
        }
        setDataSource(data);
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    const getColumnSearchProps = (field) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, field)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === field ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.bank"),
            field: "tenNganHang",
            key: "tenNganHang",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.accountNumber"),
            field: "soTaiKhoan",
            key: "soTaiKhoan",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.accountOwner"),
            field: "chuSoHuu",
            key: "chuSoHuu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.branch"),
            field: "chiNhanh",
            key: "chiNhanh",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.swiftCode"),
            field: "maSwift",
            key: "maSwift",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("date"),
            field: "capNhatCuoi",
            key: "capNhatCuoi",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("notes"),
            field: "ghiChu",
            key: "ghiChu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            flex: 1,
            headerAlign: "center",
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space size="large">
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)} />
                        </Typography.Link>
                        {isView ? (
                            ""
                        ) : (
                            <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                                <EditOutlined style={{}} />
                            </Typography.Link>
                        )}
                        {isView ? (
                            ""
                        ) : (
                            <Typography.Link>
                                <Popconfirm
                                    headerName={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDelete(record.row.key)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        )}
                    </Space>
                );
            },
            align: "center",
        },
    ];
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
    };
    const direct = (record, status) => {
        edit(record);
    };
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormTk handleSearch={onSearch} isView={isView} />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            <DataGrid
                onRowClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                pageSizeOptions={[10]}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />

            <ModalTkAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default AccountBanking;
