import { DeleteOutlined } from "@ant-design/icons";
import AddIcon from "@mui/icons-material/Add";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import { Popconfirm, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";

const TableTotalThoiGianAD = ({ rowsTT, item, setItem, baoGiaTongQuanId }) => {
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState(rowsTT ? rowsTT : []);
    const [, setLoaiTien] = useState([]);
    const [dataTyGia, setDataTyGia] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        let data = {};
        dm_ty_gia
            .getDmTyGiaHeThong(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataTyGia(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);
    const columns = [
        {
            field: "stt",
            headerName: t("stt"),
            width: 10,
            editable: true,
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 7;
                }
                if (row.id.toString().indexOf("NULL") !== -1) {
                    return 7;
                }
                return undefined;
            },
            renderCell: ({ value, row }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRow()}>
                            <AddIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <span style={{ marginBottom: "5px", fontSize: "15px" }}>{t("tour.themLoaiTien")}</span>
                        </Box>
                    );
                }
                return value;
            },
        },
        {
            field: "markupType",
            headerName: t("tour.markupType"),
            width: 200,
            editable: true,
        },
        {
            field: "giaTri",
            headerName: "Markup Value",
            width: 150,
            editable: true,
        },
        {
            field: "loaiTien",
            headerName: t("exchangeRate.currency"),
            type: "number",
            width: 110,
            renderCell: ({ value, row }) => {
                if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                    return (
                        <TextField
                            select
                            disabled={row.markupType !== t("tour.percentage") ? false : true}
                            fullWidth
                            name="loaiTien"
                            onChange={(e) => {
                                var rowTT = rows.map((i) => {
                                    if (i.id_ === row.id_) {
                                        i.loaiTien = e.target.value;
                                    }
                                    return i;
                                });
                                setRows(rowTT);
                                var itNew = {
                                    ...item,
                                    rowsTT: rowTT,
                                };
                                setItem(itNew);
                                setLoaiTien(e.target.value);
                            }}
                            value={value}
                        >
                            {dataTyGia?.map((option) => (
                                <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                    {option.maNgoaiTe}
                                </MenuItem>
                            ))}
                        </TextField>
                    );
                }
            },
        },
        {
            field: "apDung",
            headerName: t("action.apply"),
            description: t("action.apply"),
            sortable: false,
            width: 160,
            renderCell: ({ value, row }) => {
                if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                    return (
                        <Radio
                            checked={value}
                            onChange={(e) => handleChangeRadio(row, e.target.value)}
                            value={true}
                            name={"radio-buttons" + row.id}
                            inputProps={{ "aria-label": "A" }}
                        />
                    );
                }
            },
        },
        {
            field: "action",
            headerName: t("action"),
            description: t("action"),
            sortable: false,
            width: 100,
            renderCell: ({ value, row }) => {
                if (row.id !== undefined) {
                    if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                        return (
                            <Typography.Link>
                                <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDeleteRow(row.id_)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        );
                    }
                }
            },
        },
    ];
    const handleChangeRadio = (row, value) => {
        console.log(row);

        setRows(
            rows.map((r) => {
                if (r.id_ === row.id_ && r.id === row.id) {
                    r.apDung = value === "true";
                }
                if (r.id_ === row.id_ && r.id !== row.id) r.apDung = false;
                return r;
            })
        );
    };
    const handleDeleteRow = (id) => {
        let data = { baoGiaTongQuanId: parseInt(baoGiaTongQuanId), bgCauHinhLaiId: id };
        console.log(JSON.stringify(data));
        baoGiaService
            .deleteCauHinhLoiNhuan(data)
            .then(function (response) {
                if (response.status === 200) {
                    var rowTT = rows.filter((row) => row.id_ !== id);
                    setRows(rows.filter((row) => row.id_ !== id));
                    var itNew = {
                        ...item,
                        rowsTT: rowTT,
                    };
                    setItem(itNew);
                    api["success"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const processRowUpdate = (updatedRow, originalRow) => {
        console.log(updatedRow);
        var rowTT = rows.map((tt) => {
            if (tt.id === updatedRow.id) return updatedRow;
            return tt;
        });
        setRows(rowTT);
        var itNew = {
            ...item,
            rowsTT: rowTT,
        };
        setItem(itNew);
    };
    const handleAddRow = () => {
        var addRows = [];
        for (let i = 0; i < rows.length; i++) {
            const element = rows[i];
            if (element.id === "SUBTOTAL") {
                if (i !== 3) {
                    addRows.push({
                        id: "NULL " + Math.random() * (1000 - 1),
                    });
                }
                addRows.push({
                    id: "NEW" + Math.random() * (1000 - 1),
                    markupType: t("tour.percentage"),
                    loaiTien: 1,
                    giaTri: 0,
                    kieuTinh: 0,
                    apDung: false,
                    id_: rows.length,
                });
                addRows.push({
                    id: "NEW" + Math.random() * (1000 - 1),
                    markupType: t("tour.fixedAmount"),
                    apDung: true,
                    id_: rows.length,
                    kieuTinh: 1,
                    loaiTien: 1,
                    giaTri: 0,
                });
                addRows.push(element);
            } else addRows.push(element);
        }
        setRows(addRows);
    };
    return (
        <>
            {contextHolder}
            <DataGrid
                autoHeight
                processRowUpdate={processRowUpdate}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
        </>
    );
};
export default TableTotalThoiGianAD;
