import { Chip } from "@material-ui/core";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Search from "@mui/icons-material/Search";
import { Button, Icon, IconButton, InputAdornment, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ClearIcon, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Badge, Col, Collapse, Row, Space, Checkbox, Tag, Dropdown } from "antd";
// import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dat_dich_vu_service from "../../../../api/dieu_hanh/dat_dich_vu/dat_dich_vu_service";
import { ReactComponent as BFIcon } from "../../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../../assets/img/chiettinh/L.svg";
import ModalXacThucDV from "./ModalXacThucDV";
const DatDichVu = ({ dataTQ }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState("0");
    const [danhSachDichVuTourRespones, setDanhSachDichVuTourRespones] = useState([]);
    const [danhSachDichVuLeRespones, setDanhSachDichVuLeRespones] = useState([]);
    const [checkDvTour, setCheckDvTour] = useState([]);
    const [isOpenXacThuc, setIsOpenXacThuc] = useState(false);
    const [countNoti, setCountNoti] = useState({
        tatCa: 0,
        canXuLy: 0,
        daDat: 0,
        daHuy: 0,
    });
    useEffect(() => {
        getData({
            opThongTinChungId: 3,
            trangThai: parseInt(status), // 0: Tat ca, 1: can xu ly, 2: da dat, 3: da huy
            searchTenNccAndTenDichVu: "",
            thoiGianBatDau: "",
            thoiGianKetThuc: "",
            dmDichVuId: 0, // 1: KS, 3: Nh, 2: Oto
            tenNcc: "",
            tenDichVu: "",
            dmDonViId: 0, // 1: Pax, 2: Phòng, 3: Way
            soLuong: 0,
            foc: 0,
        });
    }, [status]);

    const columns_date = [
        {
            title: <></>,
        },
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("supplier"),
        },
        {
            title: t("service"),
        },
        {
            title: t("unit"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.foc"),
        },
        {
            title: t("status"),
        },
        {
            title: t("thanhToan"),
        },
        {
            title: t("Executive"),
        },
    ];
    const getData = async (data) => {
        var response = await dat_dich_vu_service.layTTDanhSachDatDichVu(data);
        if (response.data.code === "00") {
            setDanhSachDichVuLeRespones(response.data.data.danhSachDichVuLeRespones);
            setDanhSachDichVuTourRespones(response.data.data.danhSachDichVuTourRespones);
            setCountNoti({
                tatCa: response.data.data.tatCa,
                canXuLy: response.data.data.canXuLy,
                daDat: response.data.data.daDat,
                daHuy: response.data.data.daHuy,
            });
        }
    };
    const [viewBtnBookin, setViewBtnBookin] = useState(true);
    const checkChecked = (danhSachDichVuTourRespones) => {
        let checkDv = [];
        let viewBtnBookin = true;
        danhSachDichVuTourRespones = danhSachDichVuTourRespones.map((element, key) => {
            let checkAll = true;
            element.danhSachDichVuTourByLoaiDichVuRespones.forEach((ele, keyel) => {
                ele.danhSachDichVuTourByNccRespones.forEach((e) => {
                    if (!e.check) checkAll = false;
                    else {
                        if (
                            (ele.dmDichVuId === 1 &&
                                checkDv[checkDv.length - 1] !== e.nccThongTinChungId &&
                                checkDv > 0) ||
                            ele.dmDichVuId !== 1
                        ) {
                            if (checkDv.findIndex((dt) => dt === e.nccThongTinChungId) === -1 && checkDv.length > 0) {
                                viewBtnBookin = false;
                            }
                            checkDv.push(e.nccThongTinChungId);
                        }
                    }
                });
            });
            element.check = checkAll;
            return element;
        });
        setCheckDvTour(checkDv);
        setViewBtnBookin(viewBtnBookin);
        setDanhSachDichVuTourRespones(danhSachDichVuTourRespones);
    };

    const checkCheckedLe = (danhSachDichVuTourRespones) => {
        danhSachDichVuTourRespones = danhSachDichVuTourRespones.map((element, key) => {
            let checkAll = true;
            element.danhSachDichVuTourByLoaiDichVuRespones.forEach((ele, keyel) => {
                ele.danhSachDichVuTourByNccRespones.forEach((e) => {
                    if (!e.check) checkAll = false;
                });
            });
            element.check = checkAll;
            return element;
        });
        setDanhSachDichVuLeRespones(danhSachDichVuTourRespones);
    };
    const handleDv = (value) => {
        if (!value) return;
        if (value.indexOf("Bữa ăn sáng") !== -1) {
            return (
                <div style={{ display: "flex", justifyItems: "center" }}>
                    <BFIcon className="css-class" />
                    &nbsp; &nbsp; {value}
                </div>
            );
        }
        if (value.indexOf("Bữa ăn trưa") !== -1) {
            return (
                <div style={{ display: "flex", justifyItems: "center" }}>
                    <LIcon className="css-class" />
                    &nbsp; &nbsp; {value}
                </div>
            );
        }
        if (value.indexOf("Bữa tối") !== -1) {
            return (
                <div style={{ display: "flex", justifyItems: "center" }}>
                    <DIcon className="css-class" />
                    &nbsp; &nbsp; {value}
                </div>
            );
        }
        if (value.indexOf("Gala dinner") !== -1) {
            return (
                <div style={{ display: "flex", justifyItems: "center" }}>
                    <GDIcon className="css-class" />
                    &nbsp; &nbsp; {value}
                </div>
            );
        }
        return value;
    };
    const items = [
        {
            key: "1",
            label: (
                <Button
                    onClick={() => {
                        setIsOpenXacThuc(true);
                    }}
                >
                    {t("xacNhanThuCong")}
                </Button>
            ),
        },
        {
            key: "2",
            label: <Button>{t("huyDichVu")}</Button>,
        },
        {
            key: "3",
            label: <Button>{t("lichSu")}</Button>,
        },
    ];
    const renderAction = (params) => {
        return (
            <Dropdown
                menu={{
                    items,
                }}
            >
                <Button>
                    <MoreVertIcon />
                </Button>
            </Dropdown>
        );
    };
    const handleCheckLe = (key, value) => {
        let list = danhSachDichVuLeRespones;
        debugger;
        list.map((element, keyLst) => {
            if (key === keyLst) {
                element.danhSachDichVuTourByLoaiDichVuRespones.map((ele, keyel) => {
                    let danhSachDichVuTourByNccRespones = ele.danhSachDichVuTourByNccRespones.map((e) => ({
                        ...e,
                        check: value,
                    }));
                    ele.danhSachDichVuTourByNccRespones = danhSachDichVuTourByNccRespones;
                    return ele;
                });
                element.check = value;
                return element;
            } else return element;
        });
        checkCheckedLe(list);
    };
    const handleCheckElementLe = (keyD, keyLoai, keyDv, value) => {
        let list = danhSachDichVuLeRespones;
        list.map((element, keyLst) => {
            if (keyD === keyLst) {
                element.danhSachDichVuTourByLoaiDichVuRespones.map((ele, keyel) => {
                    if (keyLoai === keyel) {
                        let danhSachDichVuTourByNccRespones = ele.danhSachDichVuTourByNccRespones.map((e, keyE) => ({
                            ...e,
                            check: keyE === keyDv ? value : e.check,
                        }));
                        ele.danhSachDichVuTourByNccRespones = danhSachDichVuTourByNccRespones;
                    }
                    return ele;
                });
                return element;
            } else return element;
        });
        checkCheckedLe(list);
    };
    const handleClearCheck = () => {
        let list = danhSachDichVuTourRespones;
        list.map((element, keyLst) => {
            let danhSachDichVuTourByLoaiDichVuRespones = element.danhSachDichVuTourByLoaiDichVuRespones.map(
                (ele, keyel) => {
                    let danhSachDichVuTourByNccRespones = ele.danhSachDichVuTourByNccRespones.map((e, keyE) => ({
                        ...e,
                        check: false,
                    }));
                    ele.danhSachDichVuTourByNccRespones = danhSachDichVuTourByNccRespones;
                    return ele;
                }
            );
            element.danhSachDichVuTourByNccRespones = danhSachDichVuTourByLoaiDichVuRespones;
            element.check = false;
            return element;
        });
        checkChecked(list);
    };

    const handleCheck = (key, value) => {
        let list = danhSachDichVuTourRespones;
        debugger;
        list.map((element, keyLst) => {
            if (key === keyLst) {
                element.danhSachDichVuTourByLoaiDichVuRespones.map((ele, keyel) => {
                    let danhSachDichVuTourByNccRespones = ele.danhSachDichVuTourByNccRespones.map((e) => ({
                        ...e,
                        check: value,
                    }));
                    ele.danhSachDichVuTourByNccRespones = danhSachDichVuTourByNccRespones;
                    return ele;
                });
                element.check = value;
                return element;
            } else return element;
        });
        checkChecked(list);
    };
    const handleCheckElement = (keyD, keyLoai, keyDv, value) => {
        let list = danhSachDichVuTourRespones;
        list.map((element, keyLst) => {
            if (keyD === keyLst) {
                element.danhSachDichVuTourByLoaiDichVuRespones.map((ele, keyel) => {
                    if (keyLoai === keyel) {
                        let danhSachDichVuTourByNccRespones = ele.danhSachDichVuTourByNccRespones.map((e, keyE) => ({
                            ...e,
                            check: keyE === keyDv ? value : e.check,
                        }));
                        ele.danhSachDichVuTourByNccRespones = danhSachDichVuTourByNccRespones;
                    }
                    return ele;
                });
                return element;
            } else return element;
        });
        checkChecked(list);
    };
    const handleTinhTrang = (value) => {
        let color = "";
        if (value === "Yêu cầu điều hành") {
            return <Tag color="magenta">{value}</Tag>;
        } else if (value === "") {
            return <Tag color="Đã hủy">{value}</Tag>;
        } else if (value === "Chờ hủy") {
            return <Tag color="volcano">{value}</Tag>;
        } else if (value === "Đã đặt") {
            return <Tag color="orange">{value}</Tag>;
        } else if (value === "Chờ phản hồi") {
            return <Tag color="gold">{value}</Tag>;
        } else if (value === "Chưa đặt") {
            return <Tag color="lime">{value}</Tag>;
        } else if (value === "") {
            return <Tag color="green">{value}</Tag>;
        } else if (value === "Tạm dừng") {
            return <Tag color="cyan">{value}</Tag>;
        } else if (value === "Đã thanh toán") {
            return <Tag color="blue">{value}</Tag>;
        } else if (value === "Nháp") {
            return <Tag color="geekblue">{value}</Tag>;
        } else if (value === "Chưa thanh toán") {
            return <Tag color="purple">{value}</Tag>;
        } else if (value === "Chưa tạo phiếu") {
            return <Tag color="blue-inverse">{value}</Tag>;
        }
        return <Tag>{value}</Tag>;
    };

    const cauHinh = [
        {
            key: "1",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("tourService")}
                </div>
            ),
            children: (
                <>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {danhSachDichVuTourRespones.map((ds, keyD) => {
                                let stt = 1;
                                return (
                                    <Collapse defaultActiveKey={"1"} key={keyD}>
                                        <Collapse.Panel
                                            key={"1"}
                                            header={
                                                <Row>
                                                    <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                                        <div
                                                            style={{
                                                                textTransform: "uppercase",
                                                                fontSize: "15px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {ds.ngaySuDung}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <table>
                                                <thead
                                                    style={{
                                                        backgroundColor: "#E2E2E2",
                                                        color: "black",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {columns_date.map((cl, k) => {
                                                        if (k === 0)
                                                            return (
                                                                <th style={{ fontWeight: "normal" }}>
                                                                    <Checkbox
                                                                        checked={ds.check}
                                                                        onChange={(e) =>
                                                                            handleCheck(keyD, e.target.checked)
                                                                        }
                                                                    />
                                                                </th>
                                                            );
                                                        return <th>{cl.title}</th>;
                                                    })}
                                                </thead>
                                                <tbody>
                                                    {ds.danhSachDichVuTourByLoaiDichVuRespones.map(
                                                        (dsLoai, keyLoai) => {
                                                            let rowSpan = 0;
                                                            if (dsLoai.dmDichVuId === 1) {
                                                                dsLoai.danhSachDichVuTourByNccRespones.map(
                                                                    (dsTour, keyTour) => {
                                                                        let liss = [];
                                                                        liss =
                                                                            dsTour.danhSachDichVuTourKsDetailRespones;
                                                                        liss.map((lst, keyList) => {
                                                                            return lst.danhSachDichVuTourKsDetailRespones.map(
                                                                                (dt) => {
                                                                                    rowSpan++;
                                                                                    return <></>;
                                                                                }
                                                                            );
                                                                        });
                                                                    }
                                                                );
                                                            }
                                                            let nccThongTinChungIdOld =
                                                                dsLoai.danhSachDichVuTourByNccRespones[0];
                                                            return dsLoai.danhSachDichVuTourByNccRespones.map(
                                                                (dsTour, keyTour) => {
                                                                    let liss = [];
                                                                    debugger;
                                                                    if (dsLoai.dmDichVuId === 1) {
                                                                        liss =
                                                                            dsTour.danhSachDichVuTourKsDetailRespones;
                                                                        let checkOld =
                                                                            nccThongTinChungIdOld ===
                                                                            dsTour.nccThongTinChungId;
                                                                        nccThongTinChungIdOld =
                                                                            dsTour.nccThongTinChungId;
                                                                        var rowSpanDv = 0;
                                                                        var listDvTour = dsLoai.danhSachDichVuTourByNccRespones.filter(
                                                                            (li) =>
                                                                                li.nccThongTinChungId ===
                                                                                dsTour.nccThongTinChungId
                                                                        );
                                                                        listDvTour?.map((li) => {
                                                                            li.danhSachDichVuTourKsDetailRespones.map(
                                                                                (lst, keyList) => {
                                                                                    return lst.danhSachDichVuTourKsDetailRespones.map(
                                                                                        (dt) => {
                                                                                            rowSpanDv++;
                                                                                            return <></>;
                                                                                        }
                                                                                    );
                                                                                }
                                                                            );
                                                                        });

                                                                        return (
                                                                            <>
                                                                                {liss.map((lst, keyList) => {
                                                                                    return lst.danhSachDichVuTourKsDetailRespones.map(
                                                                                        (lstDv, keyLDV) => {
                                                                                            return (
                                                                                                <tr key={keyLDV}>
                                                                                                    {keyList === 0 &&
                                                                                                    keyLDV === 0 &&
                                                                                                    !checkOld ? (
                                                                                                        <>
                                                                                                            <td
                                                                                                                rowSpan={
                                                                                                                    rowSpanDv
                                                                                                                }
                                                                                                                style={{
                                                                                                                    textAlign:
                                                                                                                        "center",
                                                                                                                }}
                                                                                                            >
                                                                                                                <Checkbox
                                                                                                                    checked={
                                                                                                                        dsTour.check
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        handleCheckElement(
                                                                                                                            keyD,
                                                                                                                            keyLoai,
                                                                                                                            keyTour,
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .checked
                                                                                                                        )
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                    {keyTour === 0 &&
                                                                                                    keyList === 0 &&
                                                                                                    keyLDV === 0 ? (
                                                                                                        <>
                                                                                                            <td
                                                                                                                rowSpan={
                                                                                                                    rowSpan
                                                                                                                }
                                                                                                            >
                                                                                                                {stt++}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                rowSpan={
                                                                                                                    rowSpan
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    dsLoai.tenLoaiDichVu
                                                                                                                }
                                                                                                            </td>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                    {keyList === 0 &&
                                                                                                    keyLDV === 0 &&
                                                                                                    !checkOld ? (
                                                                                                        <>
                                                                                                            <td
                                                                                                                rowSpan={
                                                                                                                    rowSpanDv
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    dsTour.tenNcc
                                                                                                                }
                                                                                                            </td>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                    <td>
                                                                                                        <Row>
                                                                                                            <Col
                                                                                                                xs={16}
                                                                                                                sm={16}
                                                                                                                md={16}
                                                                                                                lg={16}
                                                                                                                xl={16}
                                                                                                            >
                                                                                                                {handleDv(
                                                                                                                    lstDv.tenDichVuCore
                                                                                                                )}
                                                                                                            </Col>
                                                                                                            <Col
                                                                                                                xs={8}
                                                                                                                sm={8}
                                                                                                                md={8}
                                                                                                                lg={8}
                                                                                                                xl={8}
                                                                                                                style={{
                                                                                                                    textAlign:
                                                                                                                        "right",
                                                                                                                }}
                                                                                                            >
                                                                                                                {lstDv.loaiSoLuong ===
                                                                                                                0 ? (
                                                                                                                    <>
                                                                                                                        <Chip
                                                                                                                            label="A"
                                                                                                                            color="primary"
                                                                                                                        />
                                                                                                                    </>
                                                                                                                ) : lstDv.loaiSoLuong ===
                                                                                                                  1 ? (
                                                                                                                    <>
                                                                                                                        <Chip
                                                                                                                            label="C"
                                                                                                                            color="primary"
                                                                                                                        />
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <Chip
                                                                                                                            label="A C"
                                                                                                                            color="primary"
                                                                                                                        />
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {lstDv.donVi}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {lstDv.soLuong}
                                                                                                    </td>
                                                                                                    <td>{lstDv.foc}</td>
                                                                                                    <td>
                                                                                                        {handleTinhTrang(
                                                                                                            lstDv.tinhTrang
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {handleTinhTrang(
                                                                                                            lstDv.thanhToan
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {lstDv.dieuHanh}
                                                                                                        {renderAction()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        liss =
                                                                            dsTour.danhSachDichVuTourNhOrOtoDetailRespones;
                                                                        rowSpan = liss.length;
                                                                        debugger;
                                                                        return (
                                                                            <>
                                                                                {liss.map((lst, keyList) => {
                                                                                    return (
                                                                                        <tr key={keyList}>
                                                                                            {keyTour === 0 &&
                                                                                            keyList === 0 ? (
                                                                                                <>
                                                                                                    <td
                                                                                                        rowSpan={
                                                                                                            rowSpan
                                                                                                        }
                                                                                                        style={{
                                                                                                            textAlign:
                                                                                                                "center",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Checkbox
                                                                                                            checked={
                                                                                                                dsTour.check
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleCheckElement(
                                                                                                                    keyD,
                                                                                                                    keyLoai,
                                                                                                                    keyTour,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .checked
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td
                                                                                                        rowSpan={
                                                                                                            rowSpan
                                                                                                        }
                                                                                                    >
                                                                                                        {stt++}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        rowSpan={
                                                                                                            rowSpan
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            dsLoai.tenLoaiDichVu
                                                                                                        }
                                                                                                    </td>
                                                                                                </>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                            <td>{dsTour.tenNcc}</td>
                                                                                            <td>
                                                                                                <Row>
                                                                                                    <Col
                                                                                                        xs={16}
                                                                                                        sm={20}
                                                                                                        md={20}
                                                                                                        lg={20}
                                                                                                        xl={20}
                                                                                                    >
                                                                                                        {handleDv(
                                                                                                            lst.tenDichVuCore
                                                                                                        )}
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        xs={4}
                                                                                                        sm={4}
                                                                                                        md={4}
                                                                                                        lg={4}
                                                                                                        xl={4}
                                                                                                        style={{
                                                                                                            textAlign:
                                                                                                                "right",
                                                                                                        }}
                                                                                                    >
                                                                                                        {dsTour.loaiSoLuong ===
                                                                                                        0 ? (
                                                                                                            <>
                                                                                                                <Chip
                                                                                                                    label="A"
                                                                                                                    color="primary"
                                                                                                                />
                                                                                                            </>
                                                                                                        ) : dsTour.loaiSoLuong ===
                                                                                                          1 ? (
                                                                                                            <>
                                                                                                                <Chip
                                                                                                                    label="C"
                                                                                                                    color="primary"
                                                                                                                />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <Chip
                                                                                                                    label="A C"
                                                                                                                    color="primary"
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </td>
                                                                                            <td>{lst.donVi}</td>
                                                                                            <td>{lst.soLuong}</td>
                                                                                            <td>{lst.foc}</td>
                                                                                            <td>
                                                                                                {handleTinhTrang(
                                                                                                    lst.tinhTrang
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {handleTinhTrang(
                                                                                                    lst.thanhToan
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {lst.dieuHanh}
                                                                                                {renderAction()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapse.Panel>
                                    </Collapse>
                                );
                            })}
                        </Col>
                    </Row>
                    {checkDvTour.length > 0 ? (
                        <Row>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <IconButton onClick={() => handleClearCheck()}>
                                    <ClearIcon />
                                </IconButton>
                                {checkDvTour.length} Row selected
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {viewBtnBookin ? <Button>{t("booking")}</Button> : <></>}
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Button>{t("huyDichVu")}</Button>
                            </Col>
                        </Row>
                    ) : null}
                </>
            ),
        },
        {
            key: "2",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("dichVuLe")}
                </div>
            ),
            children: (
                <>
                    {danhSachDichVuLeRespones.map((ds, keyD) => {
                        let stt = 1;
                        return (
                            <Collapse defaultActiveKey={"1"} key={keyD}>
                                <Collapse.Panel
                                    key={"1"}
                                    header={
                                        <Row>
                                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                                <div
                                                    style={{
                                                        textTransform: "uppercase",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {ds.ngaySuDung}
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                >
                                    <table>
                                        <thead
                                            style={{
                                                backgroundColor: "#E2E2E2",
                                                color: "black",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {columns_date.map((cl, key) => {
                                                if (key === 0)
                                                    return (
                                                        <th style={{ fontWeight: "normal" }}>
                                                            <Checkbox
                                                                checked={ds.check}
                                                                onChange={(e) => handleCheckLe(keyD, e.target.checked)}
                                                            />
                                                        </th>
                                                    );
                                                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                                            })}
                                        </thead>
                                        <tbody>
                                            {ds.danhSachDichVuTourByLoaiDichVuRespones.map((dsLoai, keyLoai) => {
                                                let rowSpan = 0;
                                                if (dsLoai.dmDichVuId === 1) {
                                                    dsLoai.danhSachDichVuTourByNccRespones.map((dsTour, keyTour) => {
                                                        let liss = [];
                                                        liss = dsTour.danhSachDichVuTourKsDetailRespones;
                                                        liss.map((lst, keyList) => {
                                                            return lst.danhSachDichVuTourKsDetailRespones.map((dt) => {
                                                                rowSpan++;
                                                                return <></>;
                                                            });
                                                        });
                                                    });
                                                }
                                                let nccThongTinChungIdOld = dsLoai.danhSachDichVuTourByNccRespones[0];
                                                return dsLoai.danhSachDichVuTourByNccRespones.map((dsTour, keyTour) => {
                                                    let liss = [];
                                                    if (dsLoai.dmDichVuId === 1) {
                                                        liss = dsTour.danhSachDichVuTourKsDetailRespones;
                                                        let checkOld =
                                                            nccThongTinChungIdOld === dsTour.nccThongTinChungId;
                                                        nccThongTinChungIdOld = dsTour.nccThongTinChungId;
                                                        var rowSpanDv = 0;
                                                        var listDvTour = dsLoai.danhSachDichVuTourByNccRespones.filter(
                                                            (li) => li.nccThongTinChungId === dsTour.nccThongTinChungId
                                                        );
                                                        listDvTour?.map((li) => {
                                                            li.danhSachDichVuTourKsDetailRespones.map(
                                                                (lst, keyList) => {
                                                                    return lst.danhSachDichVuTourKsDetailRespones.map(
                                                                        (dt) => {
                                                                            rowSpanDv++;
                                                                            return <></>;
                                                                        }
                                                                    );
                                                                }
                                                            );
                                                        });

                                                        return (
                                                            <>
                                                                {liss.map((lst, keyList) => {
                                                                    return lst.danhSachDichVuTourKsDetailRespones.map(
                                                                        (lstDv, keyLDV) => {
                                                                            return (
                                                                                <tr key={keyLDV}>
                                                                                    {keyList === 0 &&
                                                                                    keyLDV === 0 &&
                                                                                    !checkOld ? (
                                                                                        <>
                                                                                            <td
                                                                                                rowSpan={rowSpanDv}
                                                                                                style={{
                                                                                                    textAlign: "center",
                                                                                                }}
                                                                                            >
                                                                                                <Checkbox
                                                                                                    checked={
                                                                                                        dsTour.check
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleCheckElementLe(
                                                                                                            keyD,
                                                                                                            keyLoai,
                                                                                                            keyTour,
                                                                                                            e.target
                                                                                                                .checked
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </td>
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                    {keyTour === 0 &&
                                                                                    keyList === 0 &&
                                                                                    keyLDV === 0 ? (
                                                                                        <>
                                                                                            <td rowSpan={rowSpan}>
                                                                                                {stt++}
                                                                                            </td>
                                                                                            <td rowSpan={rowSpan}>
                                                                                                {dsLoai.tenLoaiDichVu}
                                                                                            </td>
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                    {keyList === 0 &&
                                                                                    keyLDV === 0 &&
                                                                                    !checkOld ? (
                                                                                        <>
                                                                                            <td rowSpan={rowSpanDv}>
                                                                                                {dsTour.tenNcc}
                                                                                            </td>
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                    <td>
                                                                                        <Row>
                                                                                            <Col
                                                                                                xs={16}
                                                                                                sm={16}
                                                                                                md={16}
                                                                                                lg={16}
                                                                                                xl={16}
                                                                                            >
                                                                                                {handleDv(
                                                                                                    lstDv.tenDichVu
                                                                                                )}
                                                                                            </Col>
                                                                                            <Col
                                                                                                xs={8}
                                                                                                sm={8}
                                                                                                md={8}
                                                                                                lg={8}
                                                                                                xl={8}
                                                                                                style={{
                                                                                                    textAlign: "right",
                                                                                                }}
                                                                                            >
                                                                                                {lstDv.loaiSoLuong ===
                                                                                                0 ? (
                                                                                                    <>
                                                                                                        <Chip
                                                                                                            label="A"
                                                                                                            color="primary"
                                                                                                        />
                                                                                                    </>
                                                                                                ) : lstDv.loaiSoLuong ===
                                                                                                  1 ? (
                                                                                                    <>
                                                                                                        <Chip
                                                                                                            label="C"
                                                                                                            color="primary"
                                                                                                        />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <Chip
                                                                                                            label="A C"
                                                                                                            color="primary"
                                                                                                        />
                                                                                                    </>
                                                                                                )}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </td>
                                                                                    <td>{lstDv.donVi}</td>
                                                                                    <td>{lstDv.soLuong}</td>
                                                                                    <td>{lstDv.foc}</td>
                                                                                    <td>
                                                                                        {handleTinhTrang(
                                                                                            lstDv.tinhTrang
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {handleTinhTrang(
                                                                                            lstDv.thanhToan
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {lstDv.dieuHanh}
                                                                                        {renderAction()}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    );
                                                                })}
                                                            </>
                                                        );
                                                    } else {
                                                        liss = dsTour.danhSachDichVuTourNhOrOtoDetailRespones;
                                                        rowSpan = liss.length;
                                                        return (
                                                            <>
                                                                {liss.map((lst, keyList) => {
                                                                    return (
                                                                        <tr key={keyList}>
                                                                            {keyTour === 0 && keyList === 0 ? (
                                                                                <>
                                                                                    <td
                                                                                        rowSpan={rowSpan}
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                        }}
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={dsTour.check}
                                                                                            onChange={(e) =>
                                                                                                handleCheckElementLe(
                                                                                                    keyD,
                                                                                                    keyLoai,
                                                                                                    keyTour,
                                                                                                    e.target.checked
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td rowSpan={rowSpan}>{stt++}</td>
                                                                                    <td rowSpan={rowSpan}>
                                                                                        {dsLoai.tenLoaiDichVu}
                                                                                    </td>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {keyList === 0 ? (
                                                                                <>
                                                                                    <td rowSpan={liss.length}>
                                                                                        {dsTour.tenNcc}
                                                                                    </td>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            <td>
                                                                                <Row>
                                                                                    <Col
                                                                                        xs={16}
                                                                                        sm={20}
                                                                                        md={20}
                                                                                        lg={20}
                                                                                        xl={20}
                                                                                    >
                                                                                        {handleDv(lst.tenDichVu)}
                                                                                    </Col>
                                                                                    <Col
                                                                                        xs={4}
                                                                                        sm={4}
                                                                                        md={4}
                                                                                        lg={4}
                                                                                        xl={4}
                                                                                        style={{ textAlign: "right" }}
                                                                                    >
                                                                                        {dsTour.loaiSoLuong === 0 ? (
                                                                                            <>
                                                                                                <Chip
                                                                                                    label="A"
                                                                                                    color="primary"
                                                                                                />
                                                                                            </>
                                                                                        ) : dsTour.loaiSoLuong === 1 ? (
                                                                                            <>
                                                                                                <Chip
                                                                                                    label="C"
                                                                                                    color="primary"
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <Chip
                                                                                                    label="A C"
                                                                                                    color="primary"
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    </Col>
                                                                                </Row>
                                                                            </td>
                                                                            <td>{lst.donVi}</td>
                                                                            <td>{lst.soLuong}</td>
                                                                            <td>{lst.foc}</td>
                                                                            <td>{handleTinhTrang(lst.tinhTrang)}</td>
                                                                            <td>{handleTinhTrang(lst.thanhToan)}</td>
                                                                            <td>
                                                                                {lst.dieuHanh}
                                                                                {renderAction()}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </>
                                                        );
                                                    }
                                                });
                                            })}
                                        </tbody>
                                    </table>
                                </Collapse.Panel>
                            </Collapse>
                        );
                    })}
                </>
            ),
        },
    ];
    const handleSearch = () => {
        setIsOpen(true);
    };
    const handleCancelsXacThuc = () => {
        setIsOpenXacThuc(false);
    };

    return (
        <>
            <ModalXacThucDV isOpen={isOpenXacThuc} handleCancels={handleCancelsXacThuc} />
            <Row>
                <Col
                    span={24}
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "10px",
                        justifyContent: "space-around",
                        display: "flex",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        textAlign: "center",
                    }}
                >
                    <div style={{ fontWeight: "bold" }}>
                        <div style={{ fontSize: "20px" }}>{t("totalService")}</div>
                        <div style={{ fontSize: "50px", alignSelf: "flex-end" }}>{dataTQ?.totalDv}</div>
                    </div>
                    <div
                        style={{
                            justifyContent: "space-around",
                            alignItems: "center",
                            display: "flex",
                            width: "70%",
                        }}
                    >
                        <div>
                            <h4>{t("serviceBooked")}</h4>
                            <div>{dataTQ?.dvTour}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("serviceNotBooked")}</h4>
                            <div>{dataTQ?.dvLe}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("choPhanHoi")}</h4>
                            <div>{dataTQ?.checked}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("huyDichVu")}</h4>
                            <div>{dataTQ?.unchecked}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("paid")}</h4>
                            <div>{dataTQ?.unchecked}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("Unpaid")}</h4>
                            <div>{dataTQ?.unchecked}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Space size={20}>
                    <Badge count={countNoti.tatCa} key={0}>
                        <Button disabled={status === "0"} variant="outlined" onClick={() => setStatus("0")}>
                            {t("tatCa")}
                        </Button>
                    </Badge>
                    <Badge count={countNoti.canXuLy} key={1}>
                        <Button disabled={status === "1"} variant="outlined" onClick={() => setStatus("1")}>
                            {t("choXuLy")}
                        </Button>
                    </Badge>
                    <Badge count={countNoti.daDat} key={1}>
                        <Button disabled={status === "2"} variant="outlined" onClick={() => setStatus("2")}>
                            {t("booked")}
                        </Button>
                    </Badge>
                    <Badge count={countNoti.daHuy} key={1}>
                        <Button disabled={status === "3"} variant="outlined" onClick={() => setStatus("3")}>
                            {t("daHuy")}
                        </Button>
                    </Badge>
                </Space>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextField
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                        fullWidth
                        size="small"
                        placeholder={t("search")}
                        // value={textSearch}
                        // onChange={(e) => setTextSearch(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker sx={{}} label={t("toDate")} slotProps={{ textField: { size: "small" } }} />
                    </LocalizationProvider>
                </Col>
                <HorizontalRuleIcon sx={{ marginTop: "10px" }} />
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label={t("fromDate")} slotProps={{ textField: { size: "small" } }} />
                    </LocalizationProvider>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <Button variant="contained" color="success" onClick={handleSearch}>
                        {t("search")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse defaultActiveKey={"1"} items={cauHinh} />
                </Col>
            </Row>
        </>
    );
};
export default DatDichVu;
