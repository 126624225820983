import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDmLoaiKhachHang = (data) => {
  return axios.post(API_BASE_URL + "category/getDmLoaiKhachHang", data, {
    headers: authHeader(),
  });
};

const getUserAssign = (data) => {
  return axios.post(API_BASE_URL + "user/getUserList", data, {
    headers: authHeader(),
  });
};

const saveTongQuan = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveTongQuan", data, {
    headers: authHeader(),
  });
};
const saveHanhTrinh = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveHanhTrinh", data, {
    headers: authHeader(),
  });
};

const updateTongQuan = (data) => {
  return axios.post(API_BASE_URL + "baogia/updateTongQuan", data, {
    headers: authHeader(),
  });
};

const getBaoGiaTongQuanById = (data) => {
  return axios.post(API_BASE_URL + "baogia/getTongQuanById", data, {
    headers: authHeader(),
  });
};

const getDanhSachBaoGia = (data) => {
  return axios.post(API_BASE_URL + "baogia/getData", data, {
    headers: authHeader(),
  });
};

const getThongTinTour = (data) => {
  return axios.post(API_BASE_URL + "baogia/getThongTinTourByTongQuanId", data, {
    headers: authHeader(),
  });
};

const saveThongTinTour = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveThongTinTour", data, {
    headers: authHeader(),
  });
};
const getHanhTrinhByTongQuanId = async (data) => {
  return await axios.post(
    API_BASE_URL + "baogia/getHanhTrinhByTongQuanId",
    data,
    {
      headers: authHeader(),
    }
  );
};
const getCauHinhLoiNhuan = async (data) => {
  return await axios.post(API_BASE_URL + "baogia/getCauHinhLoiNhuan", data, {
    headers: authHeader(),
  });
};
const saveCauHinhLoiNhuan = async (data) => {
  return await axios.post(API_BASE_URL + "baogia/saveCauHinhLoiNhuan", data, {
    headers: authHeader(),
  });
};
const getThongTinTourByTongQuanId = async (data) => {
  return await axios.post(
    API_BASE_URL + "baogia/getThongTinTourByTongQuanId",
    data,
    {
      headers: authHeader(),
    }
  );
};
const deleteCauHinhLoiNhuan = async (data) => {
  return await axios.post(API_BASE_URL + "baogia/deleteCauHinhLoiNhuan", data, {
    headers: authHeader(),
  });
};
const layTrangThai = async (data) => {
  return await axios.post(API_BASE_URL + "baogia/layTrangThai", data, {
    headers: authHeader(),
  });
};
const transferBooking = async (data) => {
  return await axios.post(API_BASE_URL + "baogia/transferBooking", data, {
    headers: authHeader(),
  });
};

const saveThueGTGT = async (data) => {
  return await axios.post(API_BASE_URL + "baogia/saveThueGTGT", data, {
    headers: authHeader(),
  });
};

export default {
  getDmLoaiKhachHang,
  getUserAssign,
  saveTongQuan,
  updateTongQuan,
  getBaoGiaTongQuanById,
  getHanhTrinhByTongQuanId,
  saveHanhTrinh,
  getDanhSachBaoGia,
  getThongTinTour,
  saveThongTinTour,
  getCauHinhLoiNhuan,
  saveCauHinhLoiNhuan,
  getThongTinTourByTongQuanId,
  deleteCauHinhLoiNhuan,
  layTrangThai,
  transferBooking,
  saveThueGTGT,
};
