import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Table} from "antd";

const TableDvLe=({columns, dataSource})=>{
    return (
        <Table
            style={{ width: "100%" }}
            bordered
            dataSource={ dataSource}
            columns={columns}
            pagination={false}
        />
    )
}
export default TableDvLe