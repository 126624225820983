import axios from "axios";
import authHeader from "../../../auth_header";
import { API_BASE_URL } from "../../../../constants";
const getKhachSanOp = (data) => {
    return axios.post(API_BASE_URL + "baogia/layTTCTOPKhachSanById", data, {
        headers: authHeader(),
    });
};
const deleteCTKhachSanOp = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteChietTinhOPKhachSan", data, {
        headers: authHeader(),
    });
};
const deleteDichVuKhachSanOp = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteChietTinhOPDichVuKs", data, {
        headers: authHeader(),
    });
};
const saveKhachSanOp = (data) => {
    return axios.post(API_BASE_URL + "baogia/saveChietTinhOPKhachSan", data, {
        headers: authHeader(),
    });
};
export default {
    getKhachSanOp,
    deleteCTKhachSanOp,
    deleteDichVuKhachSanOp,
    saveKhachSanOp
};