import React from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import ModalTk from "./ModalTk";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

const FormTk = ({ isOpen, handleCancel, onShow, handleFinish }) => {
  const { t } = useTranslation();
  const showModal = () => {
    onShow(true);
  };
  return (
    <Row>
        <Col
            xs={10} sm={10} md={10} lg={10} xl={8}
        ></Col>
        <Col
            xs={10} sm={10} md={10} lg={10} xl={8}
        ></Col>
        <Col
            xs={10} sm={10} md={10} lg={10} xl={8}
        >
        <Space size="large">
          {/*<Button onClick={showModal} icon={<PlusOutlined />} type="primary" disabled={isView ? true : false}>*/}
          {/*    {t("add")}*/}
          {/*</Button>*/}
          <Button
            sx={{ marginTop: "5px" }}
            variant="outlined"
            onClick={showModal}
            startIcon={<AddIcon />}
            color="warning"
          >
            {t("add")}
          </Button>

          <ModalTk
            isOpen={isOpen}
            handleCancel={handleCancel}
            handleFinish={handleFinish}
          />
        </Space>
      </Col>
    </Row>
  );
};
export default FormTk;
