import React from "react";
import Login from "../components/login/Login";
import DashBoard from "./DashBoard";
import { useSelector } from "react-redux";
import { isEmpty } from "../constants/utils";
import { Route, Routes } from "react-router-dom";

const Main = () => {
    const user = useSelector((state) => state.authReducer.user);
    let checkAuth = isEmpty(user);
    let html;
    if (!checkAuth) {
        html = (
            <Routes>
                <Route path="*" element={<DashBoard />} />
            </Routes>
        );
    } else {
        html = <Login />;
    }
    return html;
};

export default Main;
