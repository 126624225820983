import { Button, MenuItem, TextField } from "@mui/material";
import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserService from "../../../api/user/UserService";
import SaveIcon from "@material-ui/icons/Save";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const UserForm = ({ user, onSave, isOpen, onCancel, title }) => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [userChange, setUserChange] = useState({
    username: "",
    fullName: "",
    email: "",
    phone: "",
    role: [],
  });
  const [errorUserName, setErrorUserName] = useState(false);
  const [helperTextUserName, setHelperTextUserName] = useState("");
  const [errorFullName, setErrorFullName] = useState(false);
  const [helperTextFullName, setHelperTextFullName] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [helperTextPhone, setHelperTextPhone] = useState("");
  const [errorRole, setErrorRole] = useState(false);
  const [helperTextRole, setHelperTextRole] = useState("");

  useEffect(() => {
    if (user) {
      let newUser = {
        ...user,
        role: user.roles?.map((dt) => dt.id) || [],
      };
      setUserChange(newUser);
    } else
      setUserChange({
        username: "",
        fullName: "",
        email: "",
        phone: "",
        role: [],
      });
  }, [user.id]);

  useEffect(() => {
    UserService.getRoles()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          let data = res.data?.data || [];
          setRoles(data);
        }
      })
      .catch((err) => console.log("Get Roles Error ..." + err));
  }, []);

  const handleChangeUserInfo = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const newUser = { ...userChange };
    newUser[name] = value;
    setUserChange(newUser);
  };

  const handleSave = () => {
    if (!handleValidateInfo()) {
      let dataReq = {
        userName: userChange.username,
        fullName: userChange.fullName,
        email: userChange.email,
        phone: userChange.phone,
        role: userChange?.role.map((dt) => ({ id: dt })) || [],
      };
      console.log(dataReq);
      onSave(dataReq);
    }
  };

  const handleValidateInfo = () => {
    debugger;
    var isVal = false;
    if (!userChange.username) {
      isVal = true;
      setErrorUserName(true);
      setHelperTextUserName(t("username") + " " + t("isRequired"));
    } else {
      setErrorUserName(false);
      setHelperTextUserName("");
    }
    if (!userChange.fullName) {
      isVal = true;
      setErrorFullName(true);
      setHelperTextFullName(t("fullName") + " " + t("isRequired"));
    } else {
      setErrorFullName(false);
      setHelperTextFullName("");
    }
    if (!userChange.email) {
      isVal = true;
      setErrorEmail(true);
      setHelperTextEmail(t("email") + " " + t("isRequired"));
    } else if (!validateEmail(userChange.email)) {
      isVal = true;
      setErrorEmail(true);
      setHelperTextEmail(t("invalEmail"));
    } else {
      setErrorEmail(false);
      setHelperTextEmail("");
    }
    if (!userChange.phone) {
      isVal = true;
      setErrorPhone(true);
      setHelperTextPhone(t("phone") + " " + t("isRequired"));
    } else {
      setErrorPhone(false);
      setHelperTextPhone("");
    }
    if (!(userChange.role?.length > 0)) {
      isVal = true;
      setErrorRole(true);
      setHelperTextRole(t("role") + " " + t("isRequired"));
    } else {
      setErrorRole(false);
      setHelperTextRole("");
    }

    return isVal;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleCancel = () => {
    handleClearData();
    onCancel();
  };

  const handleClearData = () => {
    setErrorEmail(false);
    setHelperTextEmail("");
    setErrorUserName(false);
    setHelperTextUserName("");
    setErrorFullName(false);
    setHelperTextFullName("");
    setErrorPhone(false);
    setHelperTextPhone("");
    setErrorRole(false);
    setHelperTextRole("");
    setUserChange({
      username: "",
      fullName: "",
      email: "",
      phone: "",
      role: [],
    });
  };

  return (
    <Modal
      width={800}
      title={title}
      footer={null}
      open={isOpen}
      onCancel={() => handleCancel()}
    >
      <div style={{ display: "flex", margin: "25px 0 25px 0" }}>
        <label style={{ width: "30%", alignContent: "center" }}>
          {t("username") + " :"}
        </label>
        <TextField
          value={userChange.username ? userChange.username : ""}
          variant="outlined"
          name="username"
          size="small"
          error={errorUserName}
          helperText={helperTextUserName}
          onChange={(e) => handleChangeUserInfo(e)}
          fullWidth
        ></TextField>
      </div>
      <div style={{ display: "flex", marginBottom: "25px" }}>
        <label style={{ width: "30%", alignContent: "center" }}>
          {t("fullName") + " :"}
        </label>
        <TextField
          value={userChange.fullName ? userChange.fullName : ""}
          variant="outlined"
          name="fullName"
          size="small"
          error={errorFullName}
          helperText={helperTextFullName}
          onChange={(e) => handleChangeUserInfo(e)}
          fullWidth
        ></TextField>
      </div>
      <div style={{ display: "flex", marginBottom: "25px" }}>
        <label style={{ width: "30%", alignContent: "center" }}>
          {t("email") + " :"}
        </label>
        <TextField
          value={userChange.email ? userChange.email : ""}
          variant="outlined"
          name="email"
          size="small"
          error={errorEmail}
          helperText={helperTextEmail}
          onChange={(e) => handleChangeUserInfo(e)}
          fullWidth
        ></TextField>
      </div>
      <div style={{ display: "flex", marginBottom: "25px" }}>
        <label style={{ width: "30%", alignContent: "center" }}>
          {t("phone") + " :"}
        </label>
        <TextField
          value={userChange.phone ? userChange.phone : ""}
          variant="outlined"
          name="phone"
          size="small"
          type="number"
          error={errorPhone}
          helperText={helperTextPhone}
          onChange={(e) => handleChangeUserInfo(e)}
          fullWidth
        ></TextField>
      </div>
      <div style={{ display: "flex", marginBottom: "25px" }}>
        <label style={{ width: "30%", alignContent: "center" }}>
          {t("role") + " :"}
        </label>
        <TextField
          name="role"
          fullWidth
          select
          error={errorRole}
          helperText={helperTextRole}
          SelectProps={{
            multiple: true,
            value: userChange.role,
            onChange: (e) => handleChangeUserInfo(e),
          }}
        >
          {roles.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name_descriptions}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          color="success"
          style={{ marginRight: "5px" }}
        >
          {t("action.ok")}
        </Button>
        <Button
          variant="contained"
          onClick={handleCancel}
          startIcon={<ExitToAppIcon />}
          color="warning"
        >
          {t("exit")}
        </Button>
      </div>
    </Modal>
  );
};

export default UserForm;
