import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";
const getChietTinhOp = (data) => {
  return axios.post(API_BASE_URL + "baogia/layThongTinChietTinhOP", data, {
    headers: authHeader(),
  });
};

const saveChietTinhOPNhaHang = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveChietTinhOPNhaHang", data, {
    headers: authHeader(),
  });
};

const getChietTinhOPNhaHang = (data) => {
  return axios.post(API_BASE_URL + "baogia/layTTCTOPNhaHangById", data, {
    headers: authHeader(),
  });
};

const deleteChietTinhDichVuNhaHang = (data) => {
  return axios.post(API_BASE_URL + "baogia/deleteChietTinhOPDichVuNh", data, {
    headers: authHeader(),
  });
};

const saveChietTinhOpOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveChietTinhOPOto", data, {
    headers: authHeader(),
  });
};

const getChietTinhOpOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/layTTCTOPOtoById", data, {
    headers: authHeader(),
  });
};

const deleteChietTinhDichVuOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/deleteChietTinhOPDichVuOto", data, {
    headers: authHeader(),
  });
};

const saveChietTinhOpKS = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveChietTinhOPKhachSan", data, {
    headers: authHeader(),
  });
};

const getChietTinhOpKS = (data) => {
  return axios.post(API_BASE_URL + "baogia/layTTCTOPKhachSanById", data, {
    headers: authHeader(),
  });
};

const deleteChietTinhDichVuKS = (data) => {
  return axios.post(API_BASE_URL + "baogia/deleteChietTinhOPDichVuKs", data, {
    headers: authHeader(),
  });
};

export default {
  getChietTinhOp,
  saveChietTinhOPNhaHang,
  getChietTinhOPNhaHang,
  deleteChietTinhDichVuNhaHang,
  saveChietTinhOpOto,
  getChietTinhOpOto,
  deleteChietTinhDichVuOto,
  saveChietTinhOpKS,
  getChietTinhOpKS,
  deleteChietTinhDichVuKS,
};
