// authReducer.js
import CryptoJS from "crypto-js";
import {key_crypt} from "../constants";

let userPlay = null
let isAuthen = false
let  auth = localStorage.getItem("auth")
if (auth !== null) {
    // eslint-disable-next-line no-unused-vars
    userPlay = JSON.parse(CryptoJS.AES.decrypt(auth, key_crypt).toString(CryptoJS.enc.Utf8));
    // eslint-disable-next-line no-unused-vars
    isAuthen= true;
 }
const initialState = {
    isAuthenticated: isAuthen,
    user: userPlay,
    error: null
};


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                error: null
            };
        case 'LOGIN_FAIL':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: action.payload
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: null
            };
        default:
            return state;
    }
};

export default authReducer;
