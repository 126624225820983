import React, { useEffect, useState, useContext, useRef } from "react";
import { Space, Input, Modal, Divider, Form, Row, Col, Select, DatePicker, Card, Switch, Affix } from "antd";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import moment from "moment";
import dayjs from "dayjs";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import { Button } from "@mui/material";

const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ModalPrAction = ({ isOpen, handleCancel, handleFinish, record, action }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [dataSourceTable, setDataSourceTable] = useState([]);
    const [idTable, setIdTable] = useState([]);
    const [tienId, setTienId] = useState();
    const [id, setId] = useState("");
    const [nccThongTinChungId, setNccThongTinChungId] = useState("");
    const [maDichVu, setMaDichVu] = useState("");
    const [tenDichVu, setTenDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState("");
    const [dmHinhThucGiaId, setDmHinhThucGiaId] = useState("");
    const [hinhThucGia, setHinhThucGia] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [dmSoChoId, setDmSoChoId] = useState("");
    const [soCho, setSoCho] = useState("");
    const [dmLoaiXeId, setDmLoaiXeId] = useState("");
    const [tenLoaiXe, setTenLoaiXe] = useState("");
    const [maNgoaiTe, setMaNgoaiTe] = useState("");
    const [loaiTien, setLoaiTien] = useState("");
    useEffect(() => {
        let apiData = {
            id: record.id,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvVisa/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    console.log("Response: ====" + data);
                    form.setFieldsValue({
                        id: data.id,
                        nccThongTinChungId: data.nccThongTinChungId,
                        maDichVu: data.maDichVu,
                        tenDichVu: data.tenDichVu,
                        dmTyGiaId: data.dmTyGiaId,
                        dmHinhThucGiaId: data.dmHinhThucGiaId,
                        hinhThucGia: data.hinhThucGia,
                        ghiChu: data.ghiChu,
                        dmSoChoId: data.dmSoChoId,
                        soCho: data.soCho,
                        dmLoaiXeId: data.dmLoaiXeId,
                        tenLoaiXe: data.tenLoaiXe,
                        maNgoaiTe: data.maNgoaiTe,
                        loaiTien: data.loaiTien,
                    });
                    setTienId(data.maNgoaiTe);
                    if (data.tranxDvOtoTimeRequests.length > 0) {
                        let dataFit = data.tranxDvOtoTimeRequests;
                        for (let k = 0; k < dataFit.length; k++) {
                            dataArr.push({
                                id: dataFit[k].id,
                                nccDichVuOtoId: dataFit[k].nccDichVuOtoId,
                                rangeTime: [
                                    dayjs(new Date(moment(dataFit[k].tuNgay, "YYYY-MM-DD").format("YYYY-MM-DD"))),
                                    dayjs(new Date(moment(dataFit[k].denNgay, "YYYY-MM-DD").format("YYYY-MM-DD"))),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                giaNet: dataFit[k].giaNet,
                                giaBan: dataFit[k].giaBan,
                            });
                        }
                        setDataSourceTable(dataArr);
                        setIdTable(data.id);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [form, record.id]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const onFinish = (values) => {
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };

    const tableChildren = (dataArr) => {
        let children = [];
        for (let j = 0; j < dataArr.length; j++) {
            children.push(
                <div key={j}>
                    <Row gutter={24}>
                        <Card size="large" key={dataArr[j].id} style={{ width: "100%" }}>
                            <Row>
                                <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                    <Form.Item
                                        name="rangeTime"
                                        label={t("hotel.priceRoom.applicationTime")}
                                        {...formItemLayoutDate}
                                        rules={[
                                            {
                                                required: false,
                                                message:
                                                    t("validate") +
                                                    " " +
                                                    t("hotel.priceRoom.applicationTime").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            placeholder={[t("toDate"), t("fromDate")]}
                                            disabledDate={(current) => {
                                                let customDate = moment().format("DD-MM-YYYY");
                                                return current && current < moment(customDate, "DD-MM-YYYY");
                                            }}
                                            defaultValue={dataArr[j].rangeTime}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <Form.Item
                                        name="trangThaiBooking"
                                        label={t("status") + " " + "booking"}
                                        rules={[
                                            {
                                                required: false,
                                                message:
                                                    t("validate") +
                                                    " " +
                                                    t("hotel.priceRoom.applicationTime").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Switch defaultChecked={dataArr[j].trangThaiBooking} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item
                                                name="giaNet"
                                                label={t("hotel.priceClass.giaNet")}
                                                {...formItemLayout}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            t("validate") +
                                                            " " +
                                                            t("hotel.priceClass.giaNet").toLowerCase(),
                                                    },
                                                ]}
                                            >
                                                <Input defaultValue={dataArr[j].giaNet} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row></Row>
                                </Col>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item
                                                name="giaBan"
                                                label={t("hotel.priceClass.giaBan")}
                                                {...formItemLayout}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            t("validate") +
                                                            " " +
                                                            t("hotel.priceClass.giaBan").toLowerCase(),
                                                    },
                                                ]}
                                            >
                                                <Input defaultValue={dataArr[j].giaBan} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </div>
            );
        }
        return children;
    };

    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <div className="info-price">
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="maDichVu"
                                        label={t("res.code")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("res.code").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="tenDichVu"
                                        label={t("res.serviceProvided")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("res.serviceProvided").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="dmTyGiaId"
                                        label={t("exchangeRate.currency")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: false,
                                                message: t("validate") + " " + t("exchangeRate.currency").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                                            }
                                            defaultValue={tiGia[tienId]}
                                            options={tiGia}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {tableChildren(dataSourceTable)}

                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map((field) => (
                                            <Card
                                                size="large"
                                                key={field.key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            name="rangeTime"
                                                            label={t("hotel.priceRoom.applicationTime")}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        <Form.Item
                                                            name="trangThaiBooking"
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <Switch defaultChecked={true} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    name="giaNet"
                                                                    label={t("hotel.priceClass.giaNet")}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    name="giaBan"
                                                                    label={t("hotel.priceClass.giaBan")}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {fields.length > 0 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                ) : null}
                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="ghiChu"
                                label={t("notes")}
                                {...formItemLayoutNotes}
                                rules={[
                                    {
                                        required: false,
                                        message: t("validate") + " " + t("notes").toLowerCase(),
                                    },
                                ]}
                            >
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutMax = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 13,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("informationRoomClass").toLowerCase() : t("informationRoomClass")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                bodyStyle={{ height: 700, background: "#F5F5F5" }}
            >
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    {...formItemLayout}
                    // initialValues={{
                    //     items: [{}],
                    // }}
                >
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        height: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button
                                            variant="contained"
                                            onClick={onFinish}
                                            type="submit"
                                            startIcon={<Save />}
                                        >
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAlt />}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Delete />}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                    </Space>
                                </div>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPrAction;
