import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Typography,
  Space,
  Input,
  Button,
  Popconfirm,
  notification,
  Col,
  Row,
} from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import FormContact from "./FormContact";
import Highlighter from "react-highlight-words";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalContactAction from "./ModalContactAction";
import i18n from "../../../../../i18n";
import { DataGrid, GridToolbarQuickFilter, enUS, viVN } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const Contact = ({ dvName, dvId, isView }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [editingRecord, setEditingRecord] = useState({
    nccThongTinChungId: dvId,
  });
  const [isShow, setIsShow] = useState(false);
  const [action, setAction] = useState(false);
  const [reload, setReload] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [dataSearch, setDataSearch] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  useEffect(() => {
    getData();
  }, [dvId, reload]);
  const getData = () => {
    let data = {
      nccThongTinChungId: dvId,
    };
    axios({
      method: "post",
      url: API_BASE_URL + "ncc/lienhe/getData",
      data: data,
      headers: authHeader(),
    })
      .then(function (response) {
        console.log("Response getData: ====", response.status);
        if (response.status === 200) {
          console.log(response.data.data);
          let data = response.data.data;
          let dataLst = [];
          let dataLstSearch = [];
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              dataLst.push({
                stt: i + 1,
                id: data[i].id,
                hoVaTen: data[i].hoVaTen,
                phongBan: data[i].phongBan,
                chucVu: data[i].chucVu,
                sdt: data[i].sdt,
                email: data[i].email,
              });
              dataLstSearch.push({
                value:
                  data[i].id +
                  " - " +
                  data[i].hoVaTen +
                  " - " +
                  data[i].phongBan +
                  " - " +
                  data[i].chucVu +
                  " - " +
                  data[i].sdt +
                  " - " +
                  data[i].email,
              });
            }
            setDataSearch(dataLstSearch);
            setDataSource(dataLst);
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
        if (response.status === 401) {
        }
      });
  };
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    setEditingRecord(record);
    setIsShow(true);
    setAction(true);
  };
  const viewRecord = (record) => {
    setEditingRecord(record);
    setIsShow(true);
  };
  const handleSearch = (selectedKeys, confirm, field) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(field);
  };
  const getColumnSearchProps = (field) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, field)}
            icon={<SearchOutlined />}
            size="small"
          >
            {t("search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            {t("action.reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t("close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[field].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === field ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const onSearch = (value) => {
    let data = [];
    for (let i in dataSearch) {
      if (
        dataSearch[i].value
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        let item = dataSearch[i].value.split(" - ");
        data.push({
          stt: i,
          id: item[0],
          hoVaTen: item[1],
          phongBan: item[2],
          chucVu: item[3],
          sdt: item[4],
          email: item[5],
        });
      }
    }
    setDataSource(data);
  };
  const handleDelete = (record) => {
    let apiData = {
      id: record.id,
      hieuLuc: false, //record.hieuLuc == true ? false : true,
    };
    axios({
      method: "post",
      url: API_BASE_URL + "ncc/lienhe/updateStatus",
      data: apiData,
      headers: authHeader(),
    })
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let newData = dataSource.filter((item) => item.id !== record.id);
          setDataSource(newData);
          api["success"]({
            message: t("stour"),
            description: t("delete") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description:
              t("delete") +
              " " +
              t("noti.error").toLowerCase() +
              " " +
              response.data.message,
            duration: 0,
          });
        }
      })
      .catch((err) =>
        api["error"]({
          message: t("stour"),
          description: t("delete") + " " + t("noti.error").toLowerCase(),
          duration: 0,
        })
      );
  };
  const columns = [
    {
      headerName: t("stt"),
      field: "stt",
      key: "stt",
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: t("fullName"),
      field: "hoVaTen",
      key: "hoVaTen",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: t("departments"),
      field: "phongBan",
      key: "phongBan",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: t("position"),
      field: "chucVu",
      key: "chucVu",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: t("phone"),
      field: "sdt",
      key: "sdt",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: t("email"),
      field: "email",
      key: "email",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: t("action"),
      field: "action",
      key: "x",
      responsive: ["md"],
      renderCell: (record) => {
        const editable = isEditing(record.row);
        return (
          <Space size="large">
            <Typography.Link>
              <EyeOutlined onClick={() => viewRecord(record.row)} />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record.row)}
            >
              <EditOutlined style={{}} />
            </Typography.Link>
            <Typography.Link>
              <Popconfirm
                headerName={t("deleteTheTask")}
                description={t("areYouConfirm")}
                okText={t("delete")}
                cancelText={t("huy")}
                onConfirm={() => handleDelete(record.row)}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Typography.Link>
          </Space>
        );
      },
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
  ];
  const dataAdd = (data, dataS) => {
    // api["success"]({
    //     message: t("stour"),
    //     description:
    //         t("add")+  " " +t("noti.success").toLowerCase(),
    //     duration: 0,
    // });
    setDataSource((oldArray) => [...oldArray, data]);
    setDataSearch((oldArray) => [...oldArray, dataS]);
  };

  const handleCancel = () => {
    setIsShow(false);
  };
  const handleFinish = (status, values) => {
    let data = {
      ...values,
      id: values.id ? values.id : 0,
      nccThongTinChungId: dvId,
      hoVaTen: values.hoVaTen !== undefined ? values.hoVaTen : "",
      ngaySinh: values.ngaySinh !== undefined ? values.ngaySinh : "",
      gioiTinh: values.gioiTinh !== undefined ? values.gioiTinh : "",
      cccd: values.cccd !== undefined ? values.cccd : "",
      noiCap: values.noiCap !== undefined ? values.noiCap : "",
      chucVu: values.chucVu !== undefined ? values.chucVu : "",
      phongBan: values.phongBan !== undefined ? values.phongBan : "",
      email: values.email !== undefined ? values.email : "",
      sdt: values.sdt !== undefined ? values.sdt : "",
      quocGiaId: values.quocGiaId !== undefined ? values.quocGiaId : 0,
      tinhId: values.tinhId !== undefined ? values.tinhId : 0,
      huyenId: values.huyenId !== undefined ? values.huyenId : 0,
      xaId: values.xaId !== undefined ? values.xaId : 0,
      diaChi: values.diaChi !== undefined ? values.diaChi : "",
      diaChiCuThe: values.diaChiCuThe !== undefined ? values.diaChiCuThe : "",
      website: values.website !== undefined ? values.website : "",
      ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
    };
    axios({
      method: "post",
      url: API_BASE_URL + "ncc/lienhe/saveData",
      data: data,
      headers: authHeader(),
    })
      .then(function (response) {
        console.log("response=====", response);
        if (response.status === 200 && response.data.code === "00") {
          setReload(!reload);
          setIsShow(false);
          api["success"]({
            message: t("stour"),
            description:
              (values.id ? t("edit") : t("add")) +
              " " +
              t("noti.success").toLowerCase(),
            duration: 0,
          });
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
        api["error"]({
          message: t("stour"),
          description:
            (values.id ? t("edit") : t("add")) +
            " " +
            t("noti.error").toLowerCase(),
          duration: 0,
        });
      });
  };
  const QuickSearchToolbar = () => {
    return (
      <Box>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={5}>
            <GridToolbarQuickFilter style={{ width: "100%" }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormContact
              dataAdd={dataAdd}
              handleSearch={onSearch}
              editRecord={editingRecord}
              isView={isView}
              dvId={dvId}
              setReload={setReload}
              handleFinish={handleFinish}
            />
          </Col>
        </Row>
      </Box>
    );
  };
  return (
    <div>
      {contextHolder}
      <DataGrid
        onRowDoubleClick={(record) => edit(record.row)}
        rows={dataSource}
        columns={columns}
        slots={{
          toolbar: QuickSearchToolbar,
        }}
        autoHeight
        experimentalFeatures={{ columnGrouping: true }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        localeText={
          i18n.language === "vi"
            ? viVN.components.MuiDataGrid.defaultProps.localeText
            : enUS
        }
      />
      <ModalContactAction
        isOpen={isShow}
        handleCancel={handleCancel}
        handleFinish={handleFinish}
        record={editingRecord}
        action={action}
      />
    </div>
  );
};
export default Contact;
