import { Chip, IconButton } from "@mui/material";
import LockIcon from "@material-ui/icons/Lock";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import UserService from "../../../api/user/UserService";
import { EditFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import UserForm from "./UserForm";

const UserList = ({ users, onEdit }) => {
  const { t } = useTranslation();

  const columns = [
    {
      headerName: "STT",
      field: "stt",
      key: "stt",
      align: "center",
      flex: 0.5,
      type: "number",
      headerAlign: "center",
    },
    {
      headerName: t("username"),
      field: "username",
      key: "username",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      headerName: t("fullName"),
      field: "fullName",
      key: "fullName",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      headerName: t("email"),
      field: "email",
      key: "email",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      headerName: t("phone"),
      field: "phone",
      key: "phone",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      headerName: t("role"),
      field: "role",
      key: "role",
      // align: "center",
      flex: 2,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {params?.value}
        </div>
      ),
    },
    {
      headerName: t("status"),
      field: "active",
      key: "active",
      align: "center",
      flex: 2,
      headerAlign: "center",
      renderCell: ({ value, row }) => {
        if (value) return <Chip label={t("active")} color="primary" />;
        else if (value === "Đã hủy")
          return <Chip label={t("deactivate")} color="error" />;
      },
    },
    {
      headerName: t("action"),
      field: "actions",
      type: "actions",
      flex: 1.5,
      getActions: (params) => [],
      renderCell: (params) => (
        <div>
          <Tooltip title={t("updateInfor")}>
            <IconButton size="small">
              <EditFilled onClick={() => handleEditUser(params.row)} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("updatePassword")}>
            <IconButton size="small">
              <LockIcon
              // onClick={() =>
              //   handleAddServicesDv(
              //     item,
              //     baoGiaHanhTrinhId,
              //     ngay
              //   )
              // }
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleEditUser = (user) => {
    console.log(user);
    onEdit(user);
  };

  return (
    <div style={{ margin: "20px 0" }}>
      <DataGrid
        rows={users}
        columns={columns}
        // getRowHeight={getRowHeight}
        sx={{
          "& .MuiDataGrid-cell": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-row": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            backgroundColor: "#e2e2e2",
          },
          "& .MuiDataGrid-columnHeaderTitle ": {
            fontWeight: "bold",
          },
        }}
        localeText={
          i18n.language === "vi"
            ? viVN.components.MuiDataGrid.defaultProps.localeText
            : enUS
        }
      />
    </div>
  );
};

export default UserList;
