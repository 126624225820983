import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Col, Popconfirm, Row, Tooltip, Typography, notification} from "antd";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import {DV_KS, soChoPax} from "../../../../constants";
import {useTranslation} from "react-i18next";
import ModalChietTinhCommon from "./modal/ModalChietTinhCommon";
import khachSanService from "../../../../api/tour/khachSan/khachSanService";

const {Link} = Typography;

export default function KhachSanNew({
                                        dataSource,
                                        tongSoNgay,
                                        danhSachKhoangKhach,
                                        tongChietTinhKhachSanResponse,
                                        tyGia,
                                        onReset,
                                        tourChuongTrinh,
                                        activeKey,
                                        tourThongTinChungId,
                                        tourThoiGianApDungId,
                                        tourChietTinhId,
                                        tabChietTinhId,
                                        loaiTong,
                                        tourQuanLyKhoangKhachId,
                                        thoiGianApDung
                                    }) {
    const {t} = useTranslation();
    const [tyGiaTab, setTyGiaTab] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    // const [nhaCC, setNhaCC] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [tourMapChietTinhChuongTrinhId, setTourMapChietTinhChuongTrinhId] =
        useState(0);
    const [tranxKhachSanId, setTranxKhachSanId] = useState(0);
    const [isAdd, setIsAdd] = useState(false);
    const [tongGiaDichVu, setTongGiaDichVu] = useState({});
    const [day, setDay] = useState();
    const [tourChuongTrinhId, setTourChuongTrinhId] = useState();
    const [checkThemMoi, setcheckThemMoi] = useState(true)
    const [thoiGianAD, setThoiGianAD] = useState(null)
    const columns = [
        {
            headerName: t("day"),
        },
        {
            headerName: t("supplier"),
        },
        {
            headerName: t("service"),
        },
        {
            headerName: t("tour.donGia"),
        },
        {
            headerName: t("tour.chiPhiTreEm"),
        },
    ];
    const [items, setItems] = useState([]);
    const [columnTb, setColumnTb] = useState([]);
    const [sums, setSums] = useState({
        id: "SUBTOTAL",
        label: t("tour.ks.note"),
        taxRate: t("tour.tong"),
        donGia: 0,
        chiPhiTreEm: 0,
    });
    const [sumAll, setSumAll] = useState({
        id: "SUM",
        taxRate: t("tour.phongDon"),
        tongChiPhiPhongDon: 0,
    });
    const [idDsKhoangKhach, setIdDsKhoangKhach] = useState([]);
    const sum = (obj) => {
        let sum = 0;
        for (let el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    };
    const dvExists =(arr, tenDichVu)=> {
        return arr.some(function(el) {
            return el.tenDichVu === tenDichVu;
        });
    }
    useEffect(() => {
        if (tourQuanLyKhoangKhachId !== 0 && tourThoiGianApDungId !== 0) {
            setcheckThemMoi(false)
        } else {
            setcheckThemMoi(true)
        }
        let days = [];
        setTyGiaTab(tyGia);
        let column = [];
        if (tongSoNgay !== undefined) {
            if (tourThoiGianApDungId !== 0 && thoiGianApDung !== null && thoiGianApDung.length > 0){
                let tg = thoiGianApDung.filter(item => item.id === tourThoiGianApDungId)
                setThoiGianAD({
                    id: tg[0].id,
                    tourChietTinhId: tg[0].tourChietTinhId,
                    tuNgay: tg[0].tuNgay,
                    denNgay: tg[0].denNgay
                });
            }
            for (let i = 1; i <= tongSoNgay; i++) {
                let tourCT = tourChuongTrinh.find((ct) => parseInt(ct.ngay) === i);
                if (dvExists(tourCT.dmDichVus, DV_KS)) {
                    let source = dataSource?.find((dt) => parseInt(dt.ngay) === i);
                    let item = [];
                    let id = days.length + i;
                    if (source && source.nhaCungCaps) {
                        for (let k = 0; k < source.nhaCungCaps.length; k++) {
                            const element = source.nhaCungCaps[k];
                            if (k === 0)
                                item.push({
                                    ...element,
                                    id: id,
                                    item: i,
                                    tourChuongTrinhId: tourCT.id,
                                    rowSpan: source.nhaCungCaps.length + 1,
                                });
                            else
                                item.push({
                                    ...element,
                                    id: id,
                                    item: i,
                                    tourChuongTrinhId: tourCT.id,
                                    rowSpan: 0,
                                });
                            id++;
                        }
                        item.push({
                            id: "ADD_NEW" + id,
                            item: i,
                            tourChuongTrinhId: tourCT.id,
                        });
                        id++;
                    } else {
                        item.push({
                            id: "ADD_NEW" + id,
                            item: i,
                            tourChuongTrinhId: tourCT.id,
                            rowSpan: 1,
                        });
                        id++;
                    }
                    days = [...days, ...item];
                }
            }
        }
        if (tongChietTinhKhachSanResponse !== undefined && tongChietTinhKhachSanResponse !== null) {
            setSums({
                id: "SUBTOTAL",
                label: t("tour.ks.note"),
                taxRate: t("tour.tong"),
                donGia: 0,
                chiPhiTreEm: tongChietTinhKhachSanResponse.chiPhiTreEm,
                tongGiaDichVu: tongChietTinhKhachSanResponse.tongGiaDichVu,
            });
            setSumAll({
                id: "SUM",
                taxRate: t("tour.phongDon"),
                tongChiPhiPhongDon: tongChietTinhKhachSanResponse.tongChiPhiPhongDon,
            });
        }

        if (danhSachKhoangKhach !== undefined && danhSachKhoangKhach.length > 0) {
            let idKhoangKhach = [];
            let tongGiaDichVuInit = {};
            for (let j = 0; j < danhSachKhoangKhach.length; j++) {
                let checkVal = column.findIndex(
                    (c) => c.field === "giaDichVu" + danhSachKhoangKhach[j].id
                );
                if (checkVal === -1)
                    column.push({
                        headerName: danhSachKhoangKhach[j].dmLoaiKhachId === 1 ? danhSachKhoangKhach[j].soKhachToiThieu +"Pax"
                            : danhSachKhoangKhach[j].soKhachToiThieu + '-' +
                            danhSachKhoangKhach[j].soKhachToiDa + "Pax",
                    });
                idKhoangKhach.push(danhSachKhoangKhach[j].id);
                tongGiaDichVuInit[danhSachKhoangKhach[j].id] = 0;
            }
            setColumnTb([...columns, ...column]);
            setIdDsKhoangKhach(idKhoangKhach);
            setTongGiaDichVu(tongGiaDichVuInit);
        }
        setItems(days);
    }, [
        tyGia,
        tyGiaTab,
        tourChuongTrinh,
        tourQuanLyKhoangKhachId,
    ]);
    const isNumeric = (str) => {
        if (typeof str != "string") return false; // we only process strings!
        return (
            !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str))
        ); // ...and ensure strings of whitespace fail
    };
    const handleData = (value) => {
        // console.log("===12===", activeKey);
        let number = 0;
        if (value !== undefined && value !== null) {
            number = parseInt(value);
            if (isNumeric(tyGiaTab)) {
                number = parseInt(value) * parseInt(tyGiaTab);
            }
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };
    const rows = [...items, sums, sumAll];
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }
        return true;
    };
    const showModalAll = () => {
    };

    const onSave = () => {
        setIsOpen(false);
        onReset();
    };

    const handleEditServices = (data) => {
        console.log(data);
        setIsAdd(false);
        setTourMapChietTinhChuongTrinhId(data.tourMapChietTinhChuongTrinhId);
        setTranxKhachSanId(data.tranxKhachSanId);
        setTourChuongTrinhId(data.tourChuongTrinhId);
        setDay(data.item);
        setIsOpen(true);
    };

    const handleAddServices = (data) => {
        setTourChuongTrinhId(data.tourChuongTrinhId);
        setDay(data.item);
        setIsAdd(true);
        setIsOpen(true);
    };

    const handleCancelModal = () => {
        setIsOpen(false);
    };

    const handleDeleteServices = (data) => {
        let dataReq = {
            tourMapChietTinhChuongTrinhId: data.tourMapChietTinhChuongTrinhId,
        };
        khachSanService
            .xoaCauHinhKhachSan(dataReq)
            .then(function (response) {
                if (response.status === 200) {
                    let isCheck = true;
                    let sum = rows.find((item) => item.id === "SUBTOTAL");
                    let lstNcc = items.filter(
                        (item) =>
                            item.id !== data.id &&
                            item.item === data.item &&
                            item.tourMapChietTinhChuongTrinhId
                    );
                    let lstItems = items.filter((item) => item.id !== data.id);
                    let tongGiaDV = {...tongGiaDichVu};
                    let donGia = 0;
                    let chiPhiTreEm = 0;
                    for (let i = 0; i < lstItems.length; i++) {
                        if (
                            isCheck &&
                            (lstItems[i].tourMapChietTinhChuongTrinhId ||
                                (lstNcc.length === 0 && lstItems[i].item === data.item))
                        ) {
                            lstItems[i].rowSpan = lstNcc.length + 1;
                            isCheck = false;
                        }
                        if (lstItems[i].donGia) donGia += lstItems[i].donGia;
                        if (lstItems[i].tongChiPhiTreEm)
                            chiPhiTreEm += lstItems[i].chiPhiTreEm;
                        if (lstItems[i]?.values?.length > 0 && idDsKhoangKhach.length > 0) {
                            idDsKhoangKhach.forEach((item) => {
                                lstItems[i].values.forEach((data) => {
                                    if (data.tourQuanLyKhoangKhachId === item)
                                        tongGiaDV[item] += data.giaDichVu;
                                });
                            });
                        }
                    }
                    sum.tongGiaDichVu = tongGiaDV;
                    sum.donGia = donGia;
                    sum.chiPhiTreEm = chiPhiTreEm;
                    setSums(sum);
                    setItems(lstItems);
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    return (
        <Box
            sx={{
                width: "100%",
                "& .bold": {
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.6)",
                },
                "& .header-name": {
                    backgroundColor: "#f7f7f7",
                    fontWeight: 600,
                },
            }}
        >
            {contextHolder}
            <ModalChietTinhCommon
                isOpen={isOpen}
                onSave={onSave}
                isAdd={isAdd}
                onCancelKhachSan={handleCancelModal}
                chietTinh={"khachSan"}
                tourMapChietTinh={tourMapChietTinhChuongTrinhId}
                tranxChietTinh={tranxKhachSanId}
                tourChuongTrinhId={tourChuongTrinhId}
                tourChuongTrinh={tourChuongTrinh}
                day={day}
                tourThongTinChungId={tourThongTinChungId}
                tourThoiGianApDungId={tourThoiGianApDungId}
                tourChietTinhId={tourChietTinhId}
                tabChietTinhId={tabChietTinhId}
                loaiTong={loaiTong}
                thoiGianAD={thoiGianAD}
            />
            <table>
                <thead>
                <th style={{width: "50px"}}>2</th>
                <th colSpan="100%" style={{textAlign: "left", textIndent: "20px"}}>
                    {t("hotel")}
                </th>
                </thead>
                <thead>
                {columnTb.map((cl) => {
                    return <th>{cl.headerName}</th>;
                })}
                </thead>
                <tbody>
                {rows.map((it) => {
                    return (
                        <>
                            <tr>
                                {it.id === "SUM" ? (
                                    <>
                                        <td>{it.taxRate}</td>
                                        <td colSpan="100%" style={{textAlign: "center"}}>
                                            {handleData(it.tongChiPhiPhongDon)}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        {it.id === "SUBTOTAL" ? (
                                            <>
                                                <td colSpan={3} rowSpan={2}>
                                                    {it.label}
                                                </td>
                                                <td>{it.taxRate}</td>
                                                {/* <td>{handleData(it.donGia)}</td> */}
                                                <td>{handleData(it.chiPhiTreEm)}</td>
                                                {idDsKhoangKhach.length > 0 ? (
                                                    <>
                                                        {idDsKhoangKhach.map((item) => {
                                                            return it.tongGiaDichVu !== undefined ? (
                                                                <td>{handleData(it.tongGiaDichVu[item])}</td>
                                                            ) : (
                                                                <td>0.00</td>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {it.rowSpan ? (
                                                    <td rowSpan={it.rowSpan}>{it.item}</td>
                                                ) : (
                                                    <></>
                                                )}
                                                {it.maNcc ? (
                                                    <>
                                                        {it.values && it.values.length > 0 ? (
                                                            <>
                                                                <td>
                                                                    <Tooltip
                                                                        placement="topLeft"
                                                                        title={it.maNcc}
                                                                    >
                                                                        {it.tenNcc}
                                                                    </Tooltip>
                                                                </td>
                                                                <td>
                                                                    <Row>
                                                                        <Col
                                                                            xs={21}
                                                                            sm={21}
                                                                            md={21}
                                                                            lg={21}
                                                                            xl={18}
                                                                        >
                                                                            {it.dichVu}
                                                                        </Col>
                                                                        <Col>
                                                                            <Edit
                                                                                fontSize="small"
                                                                                onClick={() => handleEditServices(it)}
                                                                            />
                                                                            <Popconfirm
                                                                                title={t("deleteTheTask")}
                                                                                description={t("areYouConfirm")}
                                                                                okText={t("delete")}
                                                                                cancelText={t("huy")}
                                                                                onConfirm={() =>
                                                                                    handleDeleteServices(it)
                                                                                }
                                                                            >
                                                                                <Delete fontSize="small"/>
                                                                            </Popconfirm>
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td>{handleData(it.donGia)}</td>
                                                                <td>{handleData(it.tongChiPhiTreEm)}</td>
                                                                {it.values.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <td>{handleData(item.giaDichVu)}</td>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <td colSpan={8}>
                                                                <>
                                                                    <Link onClick={() => handleAddServices(it)} disabled={checkThemMoi}>
                                                                        {t("add") +
                                                                            " " +
                                                                            t("service").toLowerCase()}
                                                                    </Link>
                                                                </>
                                                            </td>
                                                        )}
                                                    </>
                                                ) : (
                                                    <td colSpan={8}>
                                                        <>
                                                            <Link onClick={() => handleAddServices(it)} disabled={checkThemMoi}>
                                                                {t("add") + " " + t("service").toLowerCase()}
                                                            </Link>
                                                        </>
                                                    </td>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </tr>
                        </>
                    );
                })}
                </tbody>
            </table>
        </Box>
    );
}
