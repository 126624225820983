import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Table} from "antd";

const TableDvTour=({columns, dataSource})=>{
    const {t, i18n} = useTranslation();

    return (

            <Table
                style={{ width: "100%" }}
                bordered
                dataSource={ dataSource}
                columns={columns}
                pagination={false}
            />

    )
}
export default TableDvTour