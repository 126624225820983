import React, { useState } from "react";
import {  Row, notification } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import ModalContact from "./ModalContact";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

const FormContact = ({ handleFinish, editingRecord, isView }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Row>
      {contextHolder}
        {/*<Col*/}
        {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
        {/*></Col>*/}
        {/*<Col*/}
        {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
        {/*></Col>*/}
        {/*<Col*/}
        {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
        {/*>*/}
        {/*<Space size="large">*/}
        {/*  {isView ? (*/}
        {/*    ""*/}
        {/*  ) : (*/}
        {/*    // <Button onClick={showModal} icon={<PlusOutlined />} type="primary">*/}
        {/*    //   {t("add")}*/}
        {/*    // </Button>*/}
            <Button
              sx={{ marginTop: "5px" }}
              variant="outlined"
              onClick={showModal}
              startIcon={<AddIcon />}
              color="warning"
            >
              {t("add")}
            </Button>
          {/*)}*/}
          <ModalContact
            isOpen={isOpen}
            handleCancel={handleCancel}
            handleFinish={handleFinish}
            record={editingRecord}
          />
        {/*</Space>*/}
      {/*</Col>*/}
    </Row>
  );
};
export default FormContact;
