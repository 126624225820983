import { Chip } from "@material-ui/core";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Search from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Badge,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Space,
  Tag,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChiPhiThucTeService from "../../../../api/dieu_hanh/chi_phi_thuc_te/ChiPhiThucTeService";
import { ReactComponent as BFIcon } from "../../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../../assets/img/chiettinh/L.svg";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/BorderColor";
import ModalPhieuChi from "./ModalPhieuChi";

const ChiPhiThucTe = ({ dataTQ }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [danhSachDichVuTourRespones, setDanhSachDichVuTourRespones] = useState(
    []
  );
  const [danhSachDichVuLeRespones, setDanhSachDichVuLeRespones] = useState([]);
  const [chiPhiThucTeTongQuan, setChiPhiThucTeTongQuan] = useState({
    totalDv: 0,
    daThanhToan: 0,
    chuaThanhToan: 0,
    tongChi: 0,
    daChi: 0,
    conLai: 0,
  });
  const [countNoti, setCountNoti] = useState({
    tatCa: 0,
    chuaThanhToan: 0,
    daThanhToan: 0,
  });
  const [reset, setReset] = useState(false);
  const [action, setAction] = useState("add");
  const [tranxOpRateId, setTranxOpRateId] = useState(0);
  const [opPhieuChiId, setOpPhieuChiId] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [thoiGianBatDau, setThoiGianBatDau] = useState("");
  const [thoiGianKetThuc, setThoiGianKetThuc] = useState("");
  useEffect(() => {
    getData({
      opThongTinChungId: 3,
      trangThai: status, //0: all, 1: chuathanhtoan, 2: dathanhtoan
      search: "",
      thoiGianBatDau: "",
      thoiGianKetThuc: "",
      dmDichVuId: 0,
      tenNcc: "",
      tenDichVu: "",
      dmDonViId: 0,
      phieuChiId: 0,
      tongChi: 0.0,
      daChi: 0.0,
      conLai: 0.0,
    });
  }, [status, reset]);

  let formatter = new Intl.NumberFormat("en-US", {});
  const columns_date = [
    {
      title: "STT",
    },
    {
      title: t("typeService"),
    },
    {
      title: t("supplier"),
    },
    {
      title: t("service"),
    },
    {
      title: t("totalSpending"),
    },
    {
      title: t("spent"),
    },
    {
      title: t("conLai"),
    },
    {
      title: t("thanhToan"),
    },
    {
      title: t("nguoiThanhToan"),
    },
    {
      title: t("phieuCho"),
    },
    {
      title: t("action"),
    },
  ];
  const theadStyle = {
    backgroundColor: "#f5f5f5",
  };
  const getData = async (data) => {
    var response = await ChiPhiThucTeService.layTTDanhSachChiPhiThucTe(data);
    if (response.data.code === "00") {
      const data = response.data.data;
      setDanhSachDichVuLeRespones(data.danhSachDichVuLeRespones);
      setDanhSachDichVuTourRespones(data.danhSachDichVuTourRespones);
      setChiPhiThucTeTongQuan(data.chiPhiThucTeTongQuanResponse);
      setCountNoti({
        tatCa: data.tatCa,
        chuaThanhToan: data.chuaThanhToan,
        daThanhToan: data.daThanhToan,
      });
    }
  };

  const handleDv = (value) => {
    if (!value) return;
    if (value.indexOf("Bữa ăn sáng") !== -1) {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <BFIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value.indexOf("Bữa ăn trưa") !== -1) {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <LIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value.indexOf("Bữa tối") !== -1) {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <DIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value.indexOf("Gala dinner") !== -1) {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <GDIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    return value;
  };

  const handleCreatePhieuChi = (tranxOpRateId) => {
    setTranxOpRateId(tranxOpRateId);
    setOpPhieuChiId(0);
    setIsOpen(true);
    setAction("add");
  };

  const handleUpdatePhieuChi = (tranxOpRateId, opPhieuChiId) => {
    setTranxOpRateId(tranxOpRateId);
    setOpPhieuChiId(opPhieuChiId);
    setIsOpen(true);
    setAction("edit");
  };

  const handleDeletePhieuChi = (opPhieuChiId) => {
    let req = { opPhieuChiId };
    ChiPhiThucTeService.delTTPhieuChiById(req)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          setReset(!reset);
          api["success"]({
            message: t("stour"),
            description: t("delete") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: t("delete") + " " + t("noti.error").toLowerCase(),
            duration: 0,
          });
        }
      })
      .catch((err) => {
        api["error"]({
          message: t("stour"),
          description: t("delete") + " " + t("noti.error").toLowerCase(),
          duration: 0,
        });
      });
  };

  const handleTinhTrang = (value) => {
    if (value === "Đã thanh toán") {
      return (
        <Chip
          label={value}
          style={{ backgroundColor: "green", color: "white" }}
        />
      );
    } else if (value === "Nháp") {
      return (
        <Chip
          label={value}
          style={{ backgroundColor: "gray", color: "white" }}
        />
      );
    } else if (value === "Chờ thanh toán") {
      return (
        <Chip
          label={value}
          style={{ backgroundColor: "orange", color: "white" }}
        />
      );
    } else if (value === "Chưa tạo phiếu") {
      return (
        <Chip
          label={value}
          style={{ backgroundColor: "chocolate", color: "white" }}
        />
      );
    }
    return <Chip label={value} />;
  };

  const cauHinh = [
    {
      key: "1",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("tourService")}
        </div>
      ),
      children: (
        <>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {danhSachDichVuTourRespones.map((ds, keyD) => {
                let stt = 1;
                return (
                  <Collapse defaultActiveKey={"1"} key={keyD}>
                    <Collapse.Panel
                      key={"1"}
                      header={
                        <Row>
                          <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                            <div
                              style={{
                                textTransform: "uppercase",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {ds.ngaySuDung}
                            </div>
                          </Col>
                        </Row>
                      }
                    >
                      <table>
                        <thead style={theadStyle}>
                          {columns_date.map((cl, k) => {
                            return (
                              <th style={{ fontWeight: "normal" }}>
                                {cl.title}
                              </th>
                            );
                          })}
                        </thead>
                        <tbody>
                          {ds.danhSachDichVuTourByLoaiDichVuRespones.map(
                            (dsLoai, keyLoai) => {
                              let rowSpan = 0;
                              if (dsLoai.dmDichVuId === 1) {
                                rowSpan =
                                  dsLoai.danhSachDichVuTourByNccRespones?.reduce(
                                    (sum, item) => {
                                      item?.danhSachDichVuTourKsActionDetailRespones.forEach(
                                        (ks) => {
                                          ks.danhSachDichVuTourKsPhongDoiAndPhuPhiDetailRespones.forEach(
                                            (d) => sum++
                                          );
                                        }
                                      );
                                      return sum;
                                    },
                                    0
                                  );
                              }
                              return dsLoai.danhSachDichVuTourByNccRespones.map(
                                (dsTour, keyTour) => {
                                  let liss = [];
                                  if (dsLoai.dmDichVuId === 1) {
                                    let rowSpanDv =
                                      dsTour.danhSachDichVuTourKsActionDetailRespones.reduce(
                                        (sum, item) => {
                                          item.danhSachDichVuTourKsPhongDoiAndPhuPhiDetailRespones.forEach(
                                            () => sum++
                                          );
                                          return sum;
                                        },
                                        0
                                      );

                                    return (
                                      <>
                                        {dsTour.danhSachDichVuTourKsActionDetailRespones?.map(
                                          (lst, keyList) => {
                                            return lst.danhSachDichVuTourKsPhongDoiAndPhuPhiDetailRespones.map(
                                              (lstDv, keyLDV) => {
                                                return (
                                                  <tr
                                                    key={
                                                      keyTour +
                                                      "" +
                                                      keyList +
                                                      keyLDV
                                                    }
                                                  >
                                                    {keyTour === 0 &&
                                                    keyList === 0 &&
                                                    keyLDV === 0 ? (
                                                      <>
                                                        <td rowSpan={rowSpan}>
                                                          {stt++}
                                                        </td>
                                                        <td rowSpan={rowSpan}>
                                                          {dsLoai.tenLoaiDichVu}
                                                        </td>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {keyList === 0 &&
                                                    keyLDV === 0 ? (
                                                      <>
                                                        <td rowSpan={rowSpanDv}>
                                                          {dsTour.tenNhaCungCap}
                                                        </td>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <td>
                                                      <Row>
                                                        <Col
                                                          xs={16}
                                                          sm={16}
                                                          md={16}
                                                          lg={16}
                                                          xl={16}
                                                        >
                                                          {handleDv(
                                                            lstDv.tenDichVu
                                                          )}
                                                        </Col>
                                                        <Col
                                                          xs={8}
                                                          sm={8}
                                                          md={8}
                                                          lg={8}
                                                          xl={8}
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <Chip
                                                            label={lstDv.nguoi}
                                                            style={{
                                                              color: "white",
                                                              backgroundColor:
                                                                "green",
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>
                                                    </td>
                                                    <td>
                                                      {formatter.format(
                                                        dsTour.tongChi
                                                      )}
                                                    </td>
                                                    <td>
                                                      {formatter.format(
                                                        dsTour.daChi
                                                      )}
                                                    </td>
                                                    <td>
                                                      {formatter.format(
                                                        dsTour.conLai
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {handleTinhTrang(
                                                        dsTour.thanhToan
                                                      )}
                                                    </td>
                                                    <td>
                                                      {dsTour.nguoiThanhToan}
                                                    </td>
                                                    <td>{dsTour.phieuCho}</td>
                                                    <td>
                                                      <Box
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <IconButton
                                                          disabled={
                                                            !(
                                                              dsTour.thanhToan ===
                                                              "Chưa tạo phiếu"
                                                            )
                                                          }
                                                          onClick={() =>
                                                            handleCreatePhieuChi(
                                                              dsTour.tranxOpRateId
                                                            )
                                                          }
                                                        >
                                                          <NoteAddIcon />
                                                        </IconButton>
                                                        <IconButton
                                                          disabled={
                                                            !(
                                                              dsTour.thanhToan ===
                                                                "Chờ thanh toán" ||
                                                              dsTour.thanhToan ===
                                                                "Nháp"
                                                            )
                                                          }
                                                          onClick={() =>
                                                            handleUpdatePhieuChi(
                                                              dsTour.tranxOpRateId,
                                                              dsTour.opPhieuChiId
                                                            )
                                                          }
                                                        >
                                                          <EditIcon />
                                                        </IconButton>

                                                        <IconButton
                                                          disabled={
                                                            !(
                                                              dsTour.thanhToan ===
                                                                "Chờ thanh toán" ||
                                                              dsTour.thanhToan ===
                                                                "Nháp"
                                                            )
                                                          }
                                                        >
                                                          <Popconfirm
                                                            title={t(
                                                              "deleteTheTask"
                                                            )}
                                                            description={t(
                                                              "areYouConfirm"
                                                            )}
                                                            okText={t("delete")}
                                                            cancelText={t(
                                                              "huy"
                                                            )}
                                                            onConfirm={() =>
                                                              handleDeletePhieuChi(
                                                                dsTour.opPhieuChiId
                                                              )
                                                            }
                                                          >
                                                            <DeleteIcon />
                                                          </Popconfirm>
                                                        </IconButton>
                                                      </Box>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            );
                                          }
                                        )}
                                      </>
                                    );
                                  } else {
                                    liss =
                                      dsTour.danhSachDichVuTourNhOrOtoDetailRespones;
                                    rowSpan = liss.length;
                                    return (
                                      <>
                                        {liss.map((lst, keyList) => {
                                          return (
                                            <tr key={keyTour + "" + keyList}>
                                              {keyTour === 0 &&
                                              keyList === 0 ? (
                                                <>
                                                  <td rowSpan={rowSpan}>
                                                    {stt++}
                                                  </td>
                                                  <td rowSpan={rowSpan}>
                                                    {dsLoai.tenLoaiDichVu}
                                                  </td>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <td>{dsTour.tenNhaCungCap}</td>
                                              <td>
                                                <Row>
                                                  <Col
                                                    xs={16}
                                                    sm={20}
                                                    md={20}
                                                    lg={20}
                                                    xl={20}
                                                  >
                                                    {handleDv(lst.tenDichVu)}
                                                  </Col>
                                                  <Col
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    style={{
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <Chip
                                                      label={lst.nguoi}
                                                      style={{
                                                        color: "white",
                                                        backgroundColor:
                                                          "green",
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                              </td>
                                              <td>
                                                {formatter.format(
                                                  dsTour.tongChi
                                                )}
                                              </td>
                                              <td>
                                                {formatter.format(dsTour.daChi)}
                                              </td>
                                              <td>
                                                {formatter.format(
                                                  dsTour.conLai
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {handleTinhTrang(
                                                  dsTour.thanhToan
                                                )}
                                              </td>
                                              <td>{dsTour.nguoiThanhToan}</td>
                                              <td>{dsTour.phieuCho}</td>
                                              <td>
                                                <Box
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                >
                                                  <IconButton
                                                    disabled={
                                                      !(
                                                        dsTour.thanhToan ===
                                                        "Chưa tạo phiếu"
                                                      )
                                                    }
                                                    onClick={() =>
                                                      handleCreatePhieuChi(
                                                        dsTour.tranxOpRateId
                                                      )
                                                    }
                                                  >
                                                    <NoteAddIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    disabled={
                                                      !(
                                                        dsTour.thanhToan ===
                                                          "Chờ thanh toán" ||
                                                        dsTour.thanhToan ===
                                                          "Nháp"
                                                      )
                                                    }
                                                    onClick={() =>
                                                      handleUpdatePhieuChi(
                                                        dsTour.tranxOpRateId,
                                                        dsTour.opPhieuChiId
                                                      )
                                                    }
                                                  >
                                                    <EditIcon />
                                                  </IconButton>

                                                  <IconButton
                                                    disabled={
                                                      !(
                                                        dsTour.thanhToan ===
                                                          "Chờ thanh toán" ||
                                                        dsTour.thanhToan ===
                                                          "Nháp"
                                                      )
                                                    }
                                                  >
                                                    <Popconfirm
                                                      title={t("deleteTheTask")}
                                                      description={t(
                                                        "areYouConfirm"
                                                      )}
                                                      okText={t("delete")}
                                                      cancelText={t("huy")}
                                                      onConfirm={() =>
                                                        handleDeletePhieuChi(
                                                          dsTour.opPhieuChiId
                                                        )
                                                      }
                                                    >
                                                      <DeleteIcon />
                                                    </Popconfirm>
                                                  </IconButton>
                                                </Box>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                }
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </Collapse.Panel>
                  </Collapse>
                );
              })}
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("dichVuLe")}
        </div>
      ),
      children: (
        <>
          {danhSachDichVuLeRespones.map((ds, keyD) => {
            let stt = 1;
            return (
              <Collapse defaultActiveKey={"1"} key={keyD}>
                <Collapse.Panel
                  key={"1"}
                  header={
                    <Row>
                      <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                        <div
                          style={{
                            textTransform: "uppercase",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          {ds.ngaySuDung}
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  <table>
                    <thead style={theadStyle}>
                      {columns_date.map((cl) => {
                        return (
                          <th style={{ fontWeight: "normal" }}>{cl.title}</th>
                        );
                      })}
                    </thead>
                    <tbody>
                      {ds.danhSachDichVuTourByLoaiDichVuRespones.map(
                        (dsLoai, keyLoai) => {
                          let rowSpan = 0;
                          if (dsLoai.dmDichVuId === 1) {
                            rowSpan =
                              dsLoai.danhSachDichVuTourByNccRespones?.reduce(
                                (sum, item) => {
                                  item?.danhSachDichVuTourKsActionDetailRespones.forEach(
                                    (ks) => {
                                      ks.danhSachDichVuTourKsPhongDoiAndPhuPhiDetailRespones.forEach(
                                        () => sum++
                                      );
                                    }
                                  );
                                  return sum;
                                },
                                0
                              );
                          }

                          return dsLoai.danhSachDichVuTourByNccRespones.map(
                            (dsTour, keyTour) => {
                              let liss = [];
                              if (dsLoai.dmDichVuId === 1) {
                                liss =
                                  dsTour.danhSachDichVuTourKsActionDetailRespones;
                                var rowSpanDv = 0;
                                rowSpanDv =
                                  dsTour.danhSachDichVuTourKsActionDetailRespones.reduce(
                                    (sum, item) => {
                                      item.danhSachDichVuTourKsPhongDoiAndPhuPhiDetailRespones.forEach(
                                        () => sum++
                                      );
                                      return sum;
                                    },
                                    0
                                  );

                                return (
                                  <>
                                    {liss.map((lst, keyList) => {
                                      return lst.danhSachDichVuTourKsPhongDoiAndPhuPhiDetailRespones.map(
                                        (lstDv, keyLDV) => {
                                          return (
                                            <tr key={keyLDV}>
                                              {keyTour === 0 &&
                                              keyList === 0 &&
                                              keyLDV === 0 ? (
                                                <>
                                                  <td rowSpan={rowSpan}>
                                                    {stt++}
                                                  </td>
                                                  <td rowSpan={rowSpan}>
                                                    {dsLoai.tenLoaiDichVu}
                                                  </td>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {keyList === 0 && keyLDV === 0 ? (
                                                <>
                                                  <td rowSpan={rowSpanDv}>
                                                    {dsTour.tenNhaCungCap}
                                                  </td>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <td>
                                                <Row>
                                                  <Col
                                                    xs={16}
                                                    sm={16}
                                                    md={16}
                                                    lg={16}
                                                    xl={16}
                                                  >
                                                    {handleDv(lstDv.tenDichVu)}
                                                  </Col>
                                                  <Col
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    style={{
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <Chip
                                                      label={lstDv.nguoi}
                                                      style={{
                                                        color: "white",
                                                        backgroundColor:
                                                          "green",
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                              </td>
                                              <td>
                                                {formatter.format(
                                                  dsTour.tongChi
                                                )}
                                              </td>
                                              <td>
                                                {formatter.format(dsTour.daChi)}
                                              </td>
                                              <td>
                                                {formatter.format(
                                                  dsTour.conLai
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {handleTinhTrang(
                                                  dsTour.thanhToan
                                                )}
                                              </td>
                                              <td>{dsTour.nguoiThanhToan}</td>
                                              <td>{dsTour.phieuCho}</td>
                                              <td>
                                                <Box
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                >
                                                  <IconButton
                                                    disabled={
                                                      !(
                                                        dsTour.thanhToan ===
                                                        "Chưa tạo phiếu"
                                                      )
                                                    }
                                                    onClick={() =>
                                                      handleCreatePhieuChi(
                                                        dsTour.tranxOpRateId
                                                      )
                                                    }
                                                  >
                                                    <NoteAddIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    disabled={
                                                      !(
                                                        dsTour.thanhToan ===
                                                          "Chờ thanh toán" ||
                                                        dsTour.thanhToan ===
                                                          "Nháp"
                                                      )
                                                    }
                                                    onClick={() =>
                                                      handleUpdatePhieuChi(
                                                        dsTour.tranxOpRateId,
                                                        dsTour.opPhieuChiId
                                                      )
                                                    }
                                                  >
                                                    <EditIcon />
                                                  </IconButton>

                                                  <IconButton
                                                    disabled={
                                                      !(
                                                        dsTour.thanhToan ===
                                                          "Chờ thanh toán" ||
                                                        dsTour.thanhToan ===
                                                          "Nháp"
                                                      )
                                                    }
                                                  >
                                                    <Popconfirm
                                                      title={t("deleteTheTask")}
                                                      description={t(
                                                        "areYouConfirm"
                                                      )}
                                                      okText={t("delete")}
                                                      cancelText={t("huy")}
                                                      onConfirm={() =>
                                                        handleDeletePhieuChi(
                                                          dsTour.opPhieuChiId
                                                        )
                                                      }
                                                    >
                                                      <DeleteIcon />
                                                    </Popconfirm>
                                                  </IconButton>
                                                </Box>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      );
                                    })}
                                  </>
                                );
                              } else {
                                liss =
                                  dsTour.danhSachDichVuTourNhOrOtoDetailRespones;
                                rowSpan = liss.length;
                                return (
                                  <>
                                    {liss.map((lst, keyList) => {
                                      return (
                                        <tr key={keyList}>
                                          {keyTour === 0 && keyList === 0 ? (
                                            <>
                                              <td rowSpan={rowSpan}>{stt++}</td>
                                              <td rowSpan={rowSpan}>
                                                {dsLoai.tenLoaiDichVu}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {keyList === 0 ? (
                                            <>
                                              <td rowSpan={liss.length}>
                                                {dsTour.tenNhaCungCap}
                                              </td>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          <td>
                                            <Row>
                                              <Col
                                                xs={16}
                                                sm={20}
                                                md={20}
                                                lg={20}
                                                xl={20}
                                              >
                                                {handleDv(lst.tenDichVu)}
                                              </Col>
                                              <Col
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                style={{ textAlign: "right" }}
                                              >
                                                <Chip
                                                  label={lst.nguoi}
                                                  style={{
                                                    color: "white",
                                                    backgroundColor: "green",
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </td>
                                          <td>{dsTour.tongChi}</td>
                                          <td>{dsTour.daChi}</td>
                                          <td>{dsTour.conLai}</td>
                                          <td
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {handleTinhTrang(dsTour.thanhToan)}
                                          </td>
                                          <td>{dsTour.nguoiThanhToan}</td>
                                          <td>{dsTour.phieuCho}</td>
                                          <td>
                                            <Box
                                              style={{
                                                display: "flex",
                                              }}
                                            >
                                              <IconButton
                                                disabled={
                                                  !(
                                                    dsTour.thanhToan ===
                                                    "Chưa tạo phiếu"
                                                  )
                                                }
                                                onClick={() =>
                                                  handleCreatePhieuChi(
                                                    dsTour.tranxOpRateId
                                                  )
                                                }
                                              >
                                                <NoteAddIcon />
                                              </IconButton>
                                              <IconButton
                                                disabled={
                                                  !(
                                                    dsTour.thanhToan ===
                                                      "Chờ thanh toán" ||
                                                    dsTour.thanhToan === "Nháp"
                                                  )
                                                }
                                                onClick={() =>
                                                  handleUpdatePhieuChi(
                                                    dsTour.tranxOpRateId,
                                                    dsTour.opPhieuChiId
                                                  )
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>

                                              <IconButton
                                                disabled={
                                                  !(
                                                    dsTour.thanhToan ===
                                                      "Chờ thanh toán" ||
                                                    dsTour.thanhToan === "Nháp"
                                                  )
                                                }
                                              >
                                                <Popconfirm
                                                  title={t("deleteTheTask")}
                                                  description={t(
                                                    "areYouConfirm"
                                                  )}
                                                  okText={t("delete")}
                                                  cancelText={t("huy")}
                                                  onConfirm={() =>
                                                    handleDeletePhieuChi(
                                                      dsTour.opPhieuChiId
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </Popconfirm>
                                              </IconButton>
                                            </Box>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                );
                              }
                            }
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </Collapse.Panel>
              </Collapse>
            );
          })}
        </>
      ),
    },
  ];
  const handleSearch = () => {
    getData({
      opThongTinChungId: 3,
      trangThai: status, //0: all, 1: chuathanhtoan, 2: dathanhtoan
      search: textSearch,
      thoiGianBatDau: thoiGianBatDau
        ? thoiGianKetThuc.format("DD/MM/YYYY")
        : "",
      thoiGianKetThuc: thoiGianKetThuc
        ? thoiGianKetThuc.format("DD/MM/YYYY")
        : "",
      dmDichVuId: 0,
      tenNcc: "",
      tenDichVu: "",
      dmDonViId: 0,
      phieuChiId: 0,
      tongChi: 0.0,
      daChi: 0.0,
      conLai: 0.0,
    });
  };

  const handleClear = () => {
    setTextSearch("");
    setThoiGianBatDau("");
    setThoiGianKetThuc("");
    setReset(!reset);
  };

  const handleHidden = () => {
    setIsOpen(false);
  };
  return (
    <>
      {contextHolder}
      <ModalPhieuChi
        isOpen={isOpen}
        action={action}
        onHidden={handleHidden}
        opThongTinChungId={3}
        opPhieuChiId={opPhieuChiId}
        tranxOpRateId={tranxOpRateId}
      />
      <Row>
        <Col
          span={24}
          style={{
            backgroundColor: "#f5f5f5",
            borderRadius: "10px",
            justifyContent: "space-around",
            display: "flex",
            paddingBottom: "20px",
            paddingTop: "20px",
            textAlign: "center",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            <div style={{ fontSize: "20px" }}>{t("totalService")}</div>
            <div style={{ fontSize: "50px", alignSelf: "flex-end" }}>
              {chiPhiThucTeTongQuan?.totalDv}
            </div>
          </div>
          <div
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              width: "70%",
            }}
          >
            <div>
              <h4>{t("daThanhToan")}</h4>
              <div>{chiPhiThucTeTongQuan?.daThanhToan}</div>
            </div>
            <span
              style={{
                borderLeft: "1px solid black",
                height: "50%",
                alignSelf: "flex-end",
                marginBottom: "1%",
              }}
            />
            <div>
              <h4>{t("chuaThanhToan")}</h4>
              <div>{chiPhiThucTeTongQuan?.chuaThanhToan}</div>
            </div>
            <span
              style={{
                borderLeft: "1px solid black",
                height: "50%",
                alignSelf: "flex-end",
                marginBottom: "1%",
              }}
            />
            <div>
              <h4>{t("totalSpending")}</h4>
              <div>{formatter.format(chiPhiThucTeTongQuan.tongChi)}</div>
            </div>
            <span
              style={{
                borderLeft: "1px solid black",
                height: "50%",
                alignSelf: "flex-end",
                marginBottom: "1%",
              }}
            />
            <div>
              <h4>{t("spent")}</h4>
              <div>{formatter.format(chiPhiThucTeTongQuan?.daChi)}</div>
            </div>
            <span
              style={{
                borderLeft: "1px solid black",
                height: "50%",
                alignSelf: "flex-end",
                marginBottom: "1%",
              }}
            />
            <div>
              <h4>{t("conLai")}</h4>
              <div>{formatter.format(chiPhiThucTeTongQuan?.conLai)}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Space size={20}>
          <Badge count={countNoti.tatCa} key={0}>
            <Button
              disabled={status === "0"}
              variant="outlined"
              onClick={() => setStatus("0")}
            >
              {t("tatCa")}
            </Button>
          </Badge>
          <Badge count={countNoti.chuaThanhToan} key={1}>
            <Button
              disabled={status === "1"}
              variant="outlined"
              onClick={() => setStatus("1")}
            >
              {t("chuaThanhToan")}
            </Button>
          </Badge>
          <Badge count={countNoti.daThanhToan} key={1}>
            <Button
              disabled={status === "2"}
              variant="outlined"
              onClick={() => setStatus("2")}
            >
              {t("daThanhToan")}
            </Button>
          </Badge>
        </Space>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <TextField
            variant="outlined"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            fullWidth
            size="small"
            placeholder={t("search")}
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{}}
              label={t("toDate")}
              slotProps={{ textField: { size: "small" } }}
              value={thoiGianBatDau ? thoiGianBatDau : null}
              onChange={(newValue) => setThoiGianBatDau(newValue)}
            />
          </LocalizationProvider>
        </Col>
        <HorizontalRuleIcon sx={{ marginTop: "10px" }} />
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("fromDate")}
              slotProps={{ textField: { size: "small" } }}
              value={thoiGianKetThuc ? thoiGianKetThuc : null}
              onChange={(newValue) => setThoiGianKetThuc(newValue)}
            />
          </LocalizationProvider>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSearch}
              style={{ marginRight: "20px" }}
            >
              {t("search")}
            </Button>
            <Button variant="contained" color="primary" onClick={handleClear}>
              {t("action.reset")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Collapse defaultActiveKey={"1"} items={cauHinh} />
        </Col>
      </Row>
    </>
  );
};
export default ChiPhiThucTe;
