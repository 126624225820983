import { Box, Button, IconButton } from "@mui/material";
import { Badge, Col, Collapse, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DichVuTourService from "../../../../../api/dieu_hanh/op_rate/DichVuTourService";
import TongKet from "./TongKet";

import {
  CloseCircleFilled,
  EditFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ReactComponent as BFIcon } from "../../../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../../../assets/img/chiettinh/L.svg";
import ModalCapNhatKS from "./modalCapNhatDV/ModalCapNhatKS";
import ModalCapNhatOto from "./modalCapNhatDV/ModalCapNhatOto";
import ModalCapNhatNhaHang from "./modalCapNhatDV/ModalCapNhatNhaHang";

const DichVuTour = ({ opDichVuId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("0");
  const [danhSachDichVuTourRespones, setDanhSachDichVuTourRespones] = useState(
    []
  );
  const [dataSource, setDataSource] = useState({});
  const [tongGia, setTongGia] = useState([]);
  const [countNoti, setCountNoti] = useState({
    tatCa: 0,
    daHuy: 0,
  });
  const [usingVat, setUsingVat] = useState(false);
  const [vat, setVat] = useState(0);
  const [tranxOpSaleRateId, setTranxOpSaleRateId] = useState(0);
  const [hanhDong, setHanhDong] = useState("ADD");
  const [openNH, setOpenNh] = useState(false);
  const [openKs, setOpenKs] = useState(false);
  const [openOto, setOpenOto] = useState(false);
  const [reset, setReset] = useState(false);
  const [dataNhomNgLon, setDataNhomNgLon] = useState([]);
  const [dataNhomTreEm, setDataNhomTreEm] = useState([]);
  useEffect(() => {
    let data = {
      opThongTinChungId: "3",
      trangThai: status, // 0: Tất cả, 1: Đã check, 2: Chưa check
      opDichVuId: "19", // 0 là mặc định mới vào màn. nếu vào rồi mà chọn dịch vụ tour thì sẽ > 0
    };
    getData(data);
    getDataNhomNgLonVaTreEm(opDichVuId);
  }, [reset, opDichVuId]);
  let formatter = new Intl.NumberFormat("en-US", {});
  const getData = async (data) => {
    let response = await DichVuTourService.layTTDanhSachRateDichVuTour(data);
    if (response.status === 200 && response.data.code === "00") {
      // console.log(JSON.stringify(response.data.data));
      var danhSachDichVuTourRespones = [];
      for (
        let i = 0;
        i < response.data.data.danhSachDichVuTourRespones.length;
        i++
      ) {
        danhSachDichVuTourRespones.push(
          response.data.data.danhSachDichVuTourRespones[i]
        );
        danhSachDichVuTourRespones.push({
          id: "NEW",
          dmDichVuId:
            response.data.data.danhSachDichVuTourRespones[i]?.dmDichVuId,
          // tranxOpSaleRateId:
          //   response.data.data.danhSachDichVuTourRespones[i]?.tranxOpSaleRateId,
        });
      }
      setDataSource(response.data.data ? response.data.data : {});
      setDanhSachDichVuTourRespones(danhSachDichVuTourRespones);
      setVat(response.data.data.vat);
      setUsingVat(response.data.data.ususingVat);
      setCountNoti({
        tatCa: response.data.data.tatCa,
        daHuy: response.data.data.daHuy,
      });
      setTongGia(response.data.data.tongGias);
    }
  };

  const getDataNhomNgLonVaTreEm = (opDichVuId) => {
    let dataReq = { opDichVuId };
    DichVuTourService.getDataNhomNgLonVaTreEm(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          setDataNhomNgLon(res.data.data.nhomNguoiLons);
          setDataNhomTreEm(res.data.data.nhomTreEms);
        }
      })
      .catch((err) => console.log("GetDataNhomNgLonVaTreEm Error ..." + err));
  };

  const handleReset = () => {
    setReset(!reset);
  };

  const handleCancelKS = () => {
    setOpenKs(false);
  };

  const handleCancelOto = () => {
    setOpenOto(false);
  };

  const handleCancelNh = () => {
    setOpenNh(false);
  };

  const handleAddOrUpdateServiceOp = (loaiDichVuId, tranxOpSaleRateId) => {
    debugger;
    if (tranxOpSaleRateId) setHanhDong("EDIT");
    else setHanhDong("ADD");
    setTranxOpSaleRateId(tranxOpSaleRateId);
    switch (loaiDichVuId) {
      case 1:
        return setOpenKs(true);
      case 2:
        return setOpenOto(true);
      case 3:
        return setOpenNh(true);
      default:
        return null;
    }
  };
  const columns_date = [
    {
      title: "STT",
    },
    {
      title: t("typeService"),
    },
    {
      title: t("supplier"),
    },
    {
      title: t("service"),
    },
    {
      title: t("tour.donGia"),
    },
    {
      title: t("tour.vanChuyen.soLuong"),
    },
    {
      title: t("hotel.roomClass.conditionFoc"),
    },
    {
      title: t("hotel.roomClass.intoMoney"),
    },
    {
      title: t("tour.chiPhiTreEm"),
    },
    {
      title: t("thuevat"),
    },
  ];
  const columnsTG = [
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
    },
    {
      title: t("totalPriceNet/Group"),
      dataIndex: "tongGiaNet",
    },
    {
      title: t("totalPriceSell/Group"),
      dataIndex: "tongGiaBan",
    },
    {
      title: t("loiNhuan"),
      dataIndex: "loiNhuan",
    },
  ];

  const handleDv = (value) => {
    if (value === "Bữa ăn sáng") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <BFIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Bữa ăn trưa") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <LIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Bữa tối") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <DIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Gala dinner") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <GDIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    return value;
  };
  const cauHinh = [
    {
      key: "1",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("listService")}
        </div>
      ),
      children: (
        <>
          <table>
            <thead>
              {columns_date.map((cl) => {
                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
              })}
            </thead>
            <tbody>
              {danhSachDichVuTourRespones?.map((ds, key) => {
                if (ds.id === "NEW") {
                  return (
                    <tr key={Math.random()}>
                      <td colSpan={10}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <Link
                            underline="none"
                            onClick={() =>
                              handleAddOrUpdateServiceOp(ds.dmDichVuId, 0)
                            }
                          >
                            {t("addService")}
                          </Link>
                        </Box>
                      </td>
                    </tr>
                  );
                }
                let liss =
                  ds.dmDichVuId === 1
                    ? ds.loaiDichVuDetailsKsList
                    : ds.loaiDichVuDetailsNhOrOtoList;
                let rowSpan = liss.reduce((sum, item) => {
                  sum += item.thongTinDichVu.length;
                  return sum;
                }, 0);
                console.log("rowSpan: " + rowSpan);
                return liss.map((loai, keyL) => {
                  return (
                    <>
                      {loai.thongTinDichVu.map((thongtin, keyT) => {
                        return (
                          <>
                            <tr key={Math.random()}>
                              {keyL === 0 && keyT === 0 ? (
                                <>
                                  <td rowSpan={rowSpan + 1}>{key + 1}</td>
                                  <td rowSpan={rowSpan + 1}>{ds.loaiDichVu}</td>
                                </>
                              ) : (
                                <></>
                              )}
                              {keyT === 0 ? (
                                <>
                                  <td rowSpan={loai.thongTinDichVu.length}>
                                    {loai.nhaCungCap} <InfoCircleOutlined />
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              <td>
                                <Space
                                  direction="horizontal"
                                  size="small"
                                  style={{ display: "flex" }}
                                >
                                  {handleDv(thongtin.dichVu)}
                                  <IconButton size="small">
                                    <EditFilled
                                      onClick={() =>
                                        handleAddOrUpdateServiceOp(
                                          ds.dmDichVuId,
                                          loai.tranxOpSaleRateId
                                        )
                                      }
                                    />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    // disabled={
                                    //     !item.nccThongTinChungId ? true : false
                                    // }
                                    // onClick={() =>
                                    //     handleClearNCC(dataS, key, id)
                                    // }
                                  >
                                    <CloseCircleFilled />
                                  </IconButton>
                                </Space>
                              </td>
                              <td>{thongtin.donGia}</td>
                              <td>{thongtin.soLuong}</td>

                              {ds.dmDichVuId === 2 ? (
                                <td></td>
                              ) : ds.dmDichVuId === 1 &&
                                keyL === 0 &&
                                keyT === 0 ? (
                                <td rowSpan={rowSpan}>
                                  {t("hotel.roomClass.conditionFoc")}:{" "}
                                  {loai.foc.dieuKienFOC}
                                  <br />
                                  {t("tongsofoc")}: {loai.foc.duocFOC}
                                  <br />
                                  {t("tongTienGiam")}:{" "}
                                  {formatter.format(loai.foc.tongTienGiam)}
                                  <br />
                                </td>
                              ) : ds.dmDichVuId === 3 ? (
                                <td>
                                  {t("hotel.roomClass.conditionFoc")}:{" "}
                                  {thongtin.foc.dieuKienFOC}
                                  <br />
                                  {t("tongsofoc")}: {thongtin.foc.duocFOC}
                                  <br />
                                  {t("tongTienGiam")}:{" "}
                                  {formatter.format(thongtin.foc.tongTienGiam)}
                                  <br />
                                </td>
                              ) : (
                                <></>
                              )}
                              <td>
                                {thongtin.thanhTien.tongChiPhiThanhTien ? (
                                  <>
                                    {t("tour.tongChiPhi")}:{" "}
                                    {formatter.format(
                                      thongtin.thanhTien.tongChiPhiThanhTien
                                    )}
                                    <br />
                                    {t("soTienGiamThanhTien")}:{" "}
                                    {formatter.format(
                                      thongtin.thanhTien.soTienGiamThanhTien
                                    )}
                                    <br />
                                    {t("hotel.roomClass.intoMoney")}:{" "}
                                    {formatter.format(
                                      thongtin.thanhTien.thanhTien
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {formatter.format(
                                      thongtin.thanhTien.thanhTien
                                    )}
                                  </>
                                )}
                              </td>
                              <td>
                                {thongtin.chiPhiTreEm
                                  ? formatter.format(thongtin.chiPhiTreEm)
                                  : ""}
                              </td>
                              <td>{thongtin.thueVAT}</td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  );
                });
              })}
            </tbody>
          </table>
        </>
      ), //childrenDichVuCungCap,
    },
    {
      key: "2",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("totalPrice")}
        </div>
      ),
      children: (
        <>
          <TongKet />
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Space size={20}>
          <Badge count={countNoti.tatCa} key={0}>
            <Button
              disabled={status === "0"}
              variant="outlined"
              onClick={() => setStatus("0")}
            >
              {t("tatCa")}
            </Button>
          </Badge>
          <Badge count={countNoti.daHuy} key={1}>
            <Button
              disabled={status === "1"}
              variant="outlined"
              onClick={() => setStatus("1")}
            >
              {t("daHuy")}
            </Button>
          </Badge>
        </Space>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Collapse defaultActiveKey={"2"} items={cauHinh} />
        </Col>
      </Row>
      <ModalCapNhatKS
        isOpen={openKs}
        onReset={handleReset}
        handleCancel={handleCancelKS}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataNhomTreEm}
        tranxOpSaleRateId={tranxOpSaleRateId}
        soLuongTreEmBG={dataSource.soLuongTreEm}
        hanhDong={hanhDong}
        soNgay={dataSource.soNgay}
        ngay={0}
        bgHanhTrinhs={dataSource.hanhTrinhs}
        opDichVuId={opDichVuId}
      />
      <ModalCapNhatOto
        isOpen={openOto}
        onReset={handleReset}
        handleCancel={handleCancelOto}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataNhomTreEm}
        tranxOpSaleRateId={tranxOpSaleRateId}
        soLuongTreEmBG={dataSource.soLuongTreEm}
        soLuongNgLonBG={dataSource.soLuongNguoiLon}
        hanhDong={hanhDong}
        soNgay={dataSource.soNgay}
        ngay={0}
        bgHanhTrinhs={dataSource.hanhTrinhs}
        opDichVuId={opDichVuId}
      />
      <ModalCapNhatNhaHang
        isOpen={openNH}
        onReset={handleReset}
        handleCancel={handleCancelNh}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataNhomTreEm}
        tranxOpSaleRateId={tranxOpSaleRateId}
        soLuongTreEmBG={dataSource.soLuongTreEm}
        hanhDong={hanhDong}
        soNgay={dataSource.soNgay}
        ngay={0}
        bgHanhTrinhs={dataSource.hanhTrinhs}
        opDichVuId={opDichVuId}
      />
    </>
  );
};
export default DichVuTour;
