import { PlusOutlined } from "@ant-design/icons";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Affix, Col, Divider, Form, Modal, Row, Space, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import Save from "@mui/icons-material/Save";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ModalTkAction = ({ isOpen, handleCancel, handleFinish, record, action }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [open, setOpen] = useState(isOpen);
    const [id, setId] = useState("");
    const [dmNganHangId, setDmNganHangId] = useState("");
    const [soTaiKhoan, setSoTaiKhoan] = useState("");
    const [chuSoHuu, setChuSoHuu] = useState("");
    const [chiNhanh, setChiNhanh] = useState("");
    const [maSwift, setMaSwift] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [qrPath, setQrPath] = useState("");
    const [base64Value, setBase64Value] = useState("");
    const [dataNHID, setDataNHID] = useState("");
    const [fileImg, setFileImg] = useState("");
    const [idError, setIdError] = useState("");
    const [tenNganHangError, setTenNganHangError] = useState("");
    const [soTaiKhoanError, setSoTaiKhoanError] = useState("");
    const [chuSoHuuError, setChuSoHuuError] = useState("");
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tenNH, setTenNH] = useState([]);
    useEffect(() => {
        let dataNHID = 0;
        let fileImg = [];
        if (!isEmpty(record)) {
            let apiData = {
                id: record.id,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "ncc/tknh/getDataById",
                data: apiData,
                headers: authHeader(),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        console.log("Response: ====" + data);
                        setId(data.id);
                        setDmNganHangId(data.dmNganHangId);
                        setSoTaiKhoan(data.soTaiKhoan);
                        setChuSoHuu(data.chuSoHuu);
                        setChiNhanh(data.chiNhanh);
                        setMaSwift(data.maSwift);
                        setQrPath(data.qrPath);
                        setBase64Value(data.base64Value);
                        dataNHID = data.dmNganHangId;
                        setDataNHID(dataNHID);
                        fileImg.push({
                            uid: "-1",
                            name: data.soTaiKhoan + ".png",
                            status: "done",
                            thumbUrl: "data:image/png;base64," + data.base64Value,
                        });
                        setFileImg(fileImg);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [record.id]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNganHang",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].name,
                                });
                            }
                        }
                        setTenNH(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const onFinish = (e) => {
        e.preventDefault();
        setTenNganHangError(false);
        setChuSoHuuError(false);
        setSoTaiKhoanError(false);
        var error = false;
        if (!dmNganHangId) {
            setTenNganHangError(true);
            error = true;
        }
        if (!chuSoHuu) {
            setChuSoHuuError(true);
            error = true;
        }
        if (!soTaiKhoan) {
            setSoTaiKhoanError(true);
            error = true;
        }
        if (error) return;
        var values = {
            id: id,
            dmNganHangId: dmNganHangId,
            tenNganHang: tenNH[dmNganHangId],
            soTaiKhoan: soTaiKhoan,
            chuSoHuu: chuSoHuu,
            chiNhanh: chiNhanh,
            maSwift: maSwift,
            qrPath: qrPath,
            qrCodeImg: {
                base64Value: previewImage,
            },
        };
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        setId("");
        setDmNganHangId("");
        setSoTaiKhoan("");
        setChuSoHuu("");
        setChiNhanh("");
        setMaSwift("");
        setGhiChu("");
        handleCancel();
    };
    const [fileList, setFileList] = useState([]);
    const handleCancelImg = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const dataRender = () => {
        let html;

        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col xs={17} sm={17} md={17} lg={17} xl={17}>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.bank")}
                                    onChange={(e) => setDmNganHangId(e.target.value)}
                                    value={dmNganHangId}
                                    error={tenNganHangError}
                                    helperText={
                                        tenNganHangError
                                            ? t("validate") + " " + t("hotel.accountBanking.bank").toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                    select
                                >
                                    {tenNH.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                <TextField
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.accountNumber")}
                                    onChange={(e) => setSoTaiKhoan(e.target.value)}
                                    value={soTaiKhoan}
                                    error={soTaiKhoanError}
                                    helperText={
                                        soTaiKhoanError
                                            ? t("validate") +
                                              " " +
                                              t("hotel.accountBanking.accountNumber").toLowerCase()
                                            : ""
                                    }
                                    required
                                    type="number"
                                    fullWidth
                                ></TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.accountOwner")}
                                    onChange={(e) => setChuSoHuu(e.target.value)}
                                    value={chuSoHuu}
                                    error={chuSoHuuError}
                                    helperText={
                                        chuSoHuuError
                                            ? t("validate") + " " + t("hotel.accountBanking.accountOwner").toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.branch")}
                                    onChange={(e) => setChiNhanh(e.target.value)}
                                    value={chiNhanh}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.swiftCode")}
                                    onChange={(e) => setMaSwift(e.target.value)}
                                    value={maSwift}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("notes")}
                                    onChange={(e) => setGhiChu(e.target.value)}
                                    value={ghiChu}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileImg.length > 0 ? fileImg : fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelImg}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const handleGenQrCode = () => {};
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutMax = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 13,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutBank = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 5,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 19,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("hotel.accountBanking").toLowerCase() : t("hotel.accountBanking")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
                bodyStyle={{}}
            >
                <Divider />
                <Box component="form" noValidate>
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 10,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                color: "#fff",
                                                height: 60,
                                                paddingInline: 50,
                                                lineHeight: "60px",
                                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <Space>
                                                <Button type="primary" htmlType="submit">
                                                    {t("action.ok")}
                                                </Button>
                                                <Button
                                                    style={{
                                                        backgroundColor: "#898989",
                                                        color: "white",
                                                    }}
                                                    key="clear"
                                                    onClick={(e) => handleClear(e)}
                                                >
                                                    {t("action.reset")}
                                                </Button>
                                                <Button key="back" onClick={handleCancels}>
                                                    {t("action.cancel")}
                                                </Button>
                                                <Button onClick={handleGenQrCode} type="dashed">
                                                    {t("hotel.accountBanking.genQrCode")}
                                                </Button>
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>
        </div>
    );
};
export default ModalTkAction;
