import React from "react";
import {Menu} from "antd";
import {
    HomeOutlined, PlusCircleOutlined,

} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const SideBarBooking = ({keyOnClick, idBooking}) => {
    const {t} = useTranslation();
    const items = [
        getItem(t("service"), "service", <HomeOutlined/>, [
                getItem(
                    <Link to={"/dat-dich-vu/thong-tin-chung/" + idBooking} rel="noopener noreferrer">
                        {t("hotel.generalInfo")}
                    </Link>,
                    "1a"
                ),
                getItem(
                    <Link to={"/dat-dich-vu/dich-vu-tour/"+ idBooking} rel="noopener noreferrer">
                        {t("service") + " " + "Tour"}
                    </Link>,
                    "2a"
                ),
                getItem(
                    <Link to={"/dat-dich-vu/dich-vu-le/" + idBooking} rel="noopener noreferrer">
                        {t("dichVuLe")}
                    </Link>,
                    "3a"
                ),
                getItem(
                    <Link to={"/dat-dich-vu/tong-chi-phi/"+ idBooking} rel="noopener noreferrer">
                        {t("tour.tongChiPhi")}
                    </Link>,
                    "4a"
                ),
                getItem(
                    <Link to={"/dat-dich-vu/danh-sach-doan/"+ idBooking} rel="noopener noreferrer">
                        {t("danhSachDoan")}
                    </Link>,
                    "5a"
                ),
            ],
        ),
        getItem(t("themThongTin"), "themThongTin", <PlusCircleOutlined/>, [
            getItem(
                <Link to={"/dat-dich-vu/thuc-thu/" + idBooking} rel="noopener noreferrer">
                    {t("thucThu")}
                </Link>,
                "6a"
            ),
            getItem(
                <Link to={"/dat-dich-vu/chi-phi-uoc-tinh/"+ idBooking} rel="noopener noreferrer">
                    {t("chiPhiUocTinh")}
                </Link>,
                "7a"
            ),
            getItem(
                <Link to="/dat-dich-vu/chi-phi-khac" rel="noopener noreferrer">
                    {t("chiPhiKhac")}
                </Link>,
                "8a"
            ),
            getItem(
                <Link to="/dat-dich-vu/lich-su" rel="noopener noreferrer">
                    {t("lichSu")}
                </Link>,
                "9a"
            ),
            getItem(
                <Link to="/dat-dich-vu/ghi-chu" rel="noopener noreferrer">
                    {t("notes")}
                </Link>,
                "10a"
            ),
            getItem(
                <Link to="/dat-dich-vu/chuong-trinh-tour" rel="noopener noreferrer">
                    {t("chuongTrinhTour")}
                </Link>,
                "11a"
            ),
        ])
    ]
    const onClick = (e) => {
        keyOnClick(e.keyPath);
    };
    return (
        <>
            <Menu
                defaultSelectedKeys={["1s"]}
                defaultOpenKeys={["1s"]}
                mode="inline"
                theme="light"
                items={items}
                onClick={onClick}
            />
        </>
    )
}
export default SideBarBooking;
