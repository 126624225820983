import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDataDSTTDieuHanh = (data) => {
    return axios.post(API_BASE_URL + "op/getDataDSTTDieuHanh", data, {
        headers: authHeader(),
    });
};

const layTrangThaiDieuHanh = (data) => {
    return axios.post(API_BASE_URL + "op/layTrangThaiDieuHanh", data, {
        headers: authHeader(),
    });
};
const getDataDSTTPhanCongDieuHanh = (data) => {
    return axios.post(API_BASE_URL + "op/getDataDSTTPhanCongDieuHanh", data, {
        headers: authHeader(),
    });
};
const savePhanCongDieuHanh = (data) => {
    return axios.post(API_BASE_URL + "op/savePhanCongDieuHanh", data, {
        headers: authHeader(),
    });
};
const getDataTongQuanOPThongTinChung = (data) => {
    return axios.post(API_BASE_URL + "op/getDataTongQuanOPThongTinChung?id=" + data, null, {
        headers: authHeader(),
    });
};
const layTTDSDichVu = (data) => {
    return axios.post(API_BASE_URL + "op/layTTDSDichVu", data, {
        headers: authHeader(),
    });
};
const getDataTongQuanDichVu = (data) => {
    return axios.post(API_BASE_URL + "op/getDataTongQuanDichVu?id=" + data, null, {
        headers: authHeader(),
    });
};

const xoaNhaCungCap = (data) => {
    return axios.post(API_BASE_URL + "op/xoaNhaCungCap", data, {
        headers: authHeader(),
    });
};
const saleCheck = (data) => {
    return axios.post(API_BASE_URL + "op/saleCheck", data, {
        headers: authHeader(),
    });
};
export default {
    getDataDSTTDieuHanh,
    layTrangThaiDieuHanh,
    getDataDSTTPhanCongDieuHanh,
    savePhanCongDieuHanh,
    getDataTongQuanOPThongTinChung,
    getDataTongQuanDichVu,
    layTTDSDichVu,
    xoaNhaCungCap,
    saleCheck,
};
