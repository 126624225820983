import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Link, MenuItem, TextField, Box } from "@mui/material";
import Buttons from "@mui/material/Button";
import {
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccOto from "../../../modalNccDvLe/ModalThongTinNccOto";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DichVuLeService from "../../../../../../api/dieu_hanh/sale_rate/DichVuLeService";
import moment from "moment";
import dayjs from "dayjs";

const ModalCapNhatOto = ({
  isOpen,
  onReset,
  handleCancel,
  tranxOpSaleRateId,
  hanhDong,
}) => {
  const [dmGia, setDmGia] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { t, i18n } = useTranslation();
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [isOpenTT, setIsOpenTT] = useState(false);
  const [dataSource, setDataSource] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
    soNgaySuDung: 0,
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
    soNgaySuDung: 0,
  });
  const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
  const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
  const [action, setAction] = useState("");
  const [idxEdit, setIdxEdit] = useState(0);
  const [dmVat, setDmVat] = useState([]);
  const [reset, setReset] = useState(false);
  const [maNcc, setMaNcc] = useState("");

  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmVat();
  }, []);

  useEffect(() => {
    if (hanhDong === "EDIT" && isOpen) {
      getOtoDetails(tranxOpSaleRateId);
    }
  }, [hanhDong, tranxOpSaleRateId, isOpen]);

  useEffect(() => {
    if (hanhDong === "ADD") {
      setDichVus([{ id: "NULL" }]);
      setDichVusInit([{ id: "NULL" }]);
      setDataSource({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        soNgaySuDung: 0,
      });
      setDataSourceInit({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        soNgaySuDung: 0,
      });
    }
  }, [isOpen]);

  let formatter = new Intl.NumberFormat("en-US", {});

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const tongChiPhi = useMemo(() => {
    let tong = dichVus.reduce((sum, item) => {
      if (item.id !== "NULL" && !isNaN(item.tong)) sum += parseFloat(item.tong);
      return sum;
    }, 0);
    return tong * dataSource.soNgaySuDung;
  }, [dichVus, dataSource.soNgaySuDung]);

  const getOtoDetails = (tranxOpSaleRateId) => {
    let dataReq = {
      tranxOpSaleRateId: tranxOpSaleRateId,
    };
    DichVuLeService.layDetailsOpOto(dataReq)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let ds = {
            opDichVuId: data.opDichVuId,
            nccThongTinChungId: data.nccThongTinChungId,
            opRateHanhTrinhId: data.opRateHanhTrinhId,
            tenNcc: data.tenNhaCungCap,
            // tranxOpRateId: data.tranxOpRateId,
            ngayBatDau: data.ngayBatDau,
            ngayKetThuc: data.ngayKetThuc,
            dmGiaApDungId: data.dmGiaApDungId,
            dmLoaiApDung: data.dmLoaiApDungId,
            soNgaySuDung: data.soNgaySuDung,
            dmVatId: data.dmVatId,
            moTa: data.moTa,
          };

          let lstDichVu = data?.dichVus.map((it, idx) => {
            it.id = idx + 1;
            it.giaNet = it.giaBan;
            it.soCho = it.dmSoCho;
            it.loaiXe = it.dmLoaiXe;
            it.hinhThucGia = it.dmHinhThucGia;
            it.tong =
              it.dmHinhThucGiaId === 2
                ? it.giaBan * it.soLuong * it.tongSoKm
                : it.giaBan * it.soLuong;
            return it;
          });
          lstDichVu.push({ id: "NULL" });

          setDichVus(lstDichVu);
          setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
          setDataSource(ds);
          setDataSourceInit({ ...ds });
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmVat = () => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleChangeValueDataSoure = (e) => {
    dataSource[e.target.name] = e.target.value;
    if (e.target.name === "dmGiaApDungId") dataSource.ngayApDungs = [];
    setReset(!reset);
  };

  const handleUpdateService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    setIsOpenTT(true);
    setAction("UPDATE_SERVICE");
    setIdxEdit(index);
  };

  const handleUpdateNCC = () => {
    setMaNcc("");
    setIsOpenTT(true);
    setAction("UPDATE_NCC");
  };

  const handleAddService = () => {
    if (dichVus.length === 1) setMaNcc("");
    setIsOpenTT(true);
    setAction("ADD_SERVICE");
  };

  const handleDeleteService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    lstDichVu.splice(index, 1);
    setDichVus(lstDichVu);
    // setReset(!reset);
  };

  const handleChangeDataServiceOto = (dataSelected) => {
    console.log(dataSelected);
    let lstDichVu = [];
    if (action === "UPDATE_NCC") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      // dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = "";
      lstDichVu = dataSelected?.map((item, idx) => {
        item.id = idx + 1;
        item.dmHinhThucGiaId = item.hinhThucGiaId;
        item.soLuong = 0;
        item.tong = 0;
        item.tongSoKm = 0;
        item.donGia = item.giaNet;
        return item;
      });
      lstDichVu.push({ id: "NULL" });
    } else if (action === "ADD_SERVICE") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      // dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = "";
      dataSource.tenNcc = dataSelected[0].tenNcc;
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu.splice(dichVus.length - 1, 1);
      lstDichVu.push({
        ...dataSelected[0],
        id: dichVus.length,
        dmHinhThucGiaId: dataSelected[0].hinhThucGiaId,
        soLuong: 0,
        tong: 0,
        tongSoKm: 0,
        donGia: dataSelected[0].giaNet,
      });
      lstDichVu.push({ id: "NULL" });
    } else {
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu[idxEdit] = {
        ...dataSelected[0],
        id: idxEdit + 1,
        dmHinhThucGiaId: dataSelected[0].hinhThucGiaId,
        soLuong: 0,
        tong: 0,
        tongSoKm: 0,
        donGia: dataSelected[0].giaNet,
      };
    }

    if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
    setDichVus(lstDichVu);
    setDataSource(dataSource);
    // if (action === "ADD_SERVICE")
    //   showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
    // else showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
  };

  const handleChangeEleDichVu = (id, event) => {
    debugger;
    let index = dichVus.findIndex((item) => item.id === id);
    let name = event.target.name;
    // dichVus[index][name] = event.target.value;
    let inputValue = event.target.value.replace(/[^\d.]/g, "");
    if (name === "giaNet") {
      // Loại bỏ các dấu chấm thừa
      const parts = inputValue.split(".");
      if (parts.length > 2) {
        inputValue = `${parts[0]}.${parts.slice(1).join("")}`;
      }
      // Định dạng số với dấu phẩy
      const [integerPart, decimalPart] = inputValue.split(".");
      const formattedInteger = formatter.format(integerPart);
      const formattedValue =
        decimalPart !== undefined
          ? `${formattedInteger}.${decimalPart}`
          : formattedInteger;
      dichVus[index][name] = formattedValue;
    } else dichVus[index][name] = event.target.value;
    if (name === "soLuong" || name === "tongSoKm" || name === "giaNet") {
      if (event.target.value < 0) dichVus[index][name] = 0;
      let giaNet =
        typeof dichVus[index].giaNet === "string"
          ? dichVus[index].giaNet.replace(/,/g, "")
          : dichVus[index].giaNet;
      dichVus[index].tong =
        dichVus[index].dmHinhThucGiaId === 2
          ? parseFloat(giaNet) *
            dichVus[index].soLuong *
            dichVus[index].tongSoKm
          : parseFloat(giaNet) * dichVus[index].soLuong;
    }
    setDichVus([...dichVus]);
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = getDataReq();
      console.log(dataReq);
      console.log(JSON.stringify(dataReq));
      DichVuLeService.saveOpOto(dataReq)
        .then((response) => {
          // let action = "add";
          // if (tranxOpSaleRateId !== 0) action = "edit";
          let action = dataSource.opRateHanhTrinhId ? "edit" : "add";
          if (response.status === 200 && response.data.code === "00") {
            showNoti(
              "success",
              t(action) + " " + t("noti.success").toLowerCase()
            );
            setDataSourceInit({ ...dataSource });
            setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
            handleCancel(false);
            onReset();
          } else {
            showNoti("error", response.data.message);
          }
        })
        .catch(() => {
          showNoti("error", t("system.error"));
        });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const getDataReq = () => {
    let dataReq = {
      opDichVuId: dataSource.opDichVuId,
      opRateHanhTrinhId: dataSource.opRateHanhTrinhId
        ? dataSource.opRateHanhTrinhId
        : 0,
      tranxOpSaleRateId: tranxOpSaleRateId,
      nccThongTinChungId: dataSource.nccThongTinChungId, //0 trong th khong chon nha cc.
      ngayBatDau: dataSource.ngayBatDau,
      ngayKetThuc: dataSource.ngayKetThuc,
      dmGiaApDungId: dataSource.dmGiaApDungId,
      dmLoaiApDungId: 1, //dataSource.dmLoaiApDungId,
      soNgaySuDung: dataSource.soNgaySuDung,
      dmVatId: dataSource.dmVatId,
      moTa: dataSource.moTa,
      dichVus: dichVus
        .filter((dt) => dt.id !== "NULL")
        .map((it) => {
          return {
            nccDichVuOtoId: it.nccDichVuOtoId,
            // tranxOtoId: it.tranxOtoId,
            tenDichVu: it.tenDichVu,
            soLuong: it.soLuong,
            dmSoChoId: it.dmSoChoId,
            dmHinhThucGiaId: it.dmHinhThucGiaId,
            dmLoaiXeId: it.dmLoaiXeId,
            tongSoKm: it.tongSoKm,
            donGia: it.donGia,
            giaBan:
              typeof it.giaNet === "string"
                ? it.giaNet.replace(/,/g, "")
                : it.giaNet,
          };
        }),
    };
    return dataReq;
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    // if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNgayApDungs");
    // }
    return result;
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const handleChangeNgayBatDau = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    if (newDataSource.ngayKetThuc) {
      if (
        moment(newDataSource.ngayKetThuc, "DD/MM/YYYY").isSameOrAfter(value.$d)
      ) {
        let khoangCachMilliseconds = Math.abs(
          new Date(formatStringDateView(newDataSource.ngayKetThuc)).getTime() -
            value.$d.getTime()
        );
        let khoangCachNgay = Math.ceil(
          khoangCachMilliseconds / (1000 * 3600 * 24)
        );
        newDataSource.ngayBatDau = day;
        newDataSource.soNgaySuDung = khoangCachNgay === 0 ? 1 : khoangCachNgay;
        // setSelectedStartDate(value.$d);
      } else {
        newDataSource.ngayBatDau = "";
        newDataSource.soNgaySuDung = 0;
        // setSelectedStartDate(null);
        showNoti("error", t("noti.errorSelectedDate"));
        return;
      }
    } else {
      newDataSource.ngayBatDau = day;
      // setSelectedStartDate(value.$d);
    }
    setDataSource(newDataSource);
  };

  const handleChangeNgayKetThuc = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    if (newDataSource.ngayBatDau) {
      if (
        moment(newDataSource.ngayBatDau, "DD/MM/YYYY").isSameOrBefore(value.$d)
      ) {
        let khoangCachMilliseconds = Math.abs(
          value.$d.getTime() -
            new Date(formatStringDateView(newDataSource.ngayBatDau)).getTime()
        );
        let khoangCachNgay = Math.ceil(
          khoangCachMilliseconds / (1000 * 3600 * 24)
        );
        newDataSource.ngayKetThuc = day;
        newDataSource.soNgaySuDung = khoangCachNgay === 0 ? 1 : khoangCachNgay;
        // setSelectedEndDate(value.$d);
      } else {
        newDataSource.ngayKetThuc = "";
        newDataSource.soNgaySuDung = 0;
        // setSelectedEndDate(null);
        showNoti("error", t("noti.errorSelectedDate"));
      }
    } else {
      newDataSource.ngayKetThuc = day;
      // setSelectedEndDate(value.$d);
    }
    setDataSource(newDataSource);
  };

  const columnDichVus = [
    {
      title: t("service") + "/" + t("trip"),
      dataIndex: "tenDichVu",
      key: "tenDichVu",
      width: "15%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link onClick={() => handleAddService()} underline="none">
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 8,
            },
          };
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={record.tenDichVu}
            InputProps={{
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateService(record)}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleDeleteService(record)}
                  />
                </>
              ),
            }}
          />
        );
      },
    },
    {
      title: t("number"),
      dataIndex: "soCho",
      key: "soCho",
      width: "8%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return (
          <TextField
            value={record.soCho}
            name="soCho"
            variant="outlined"
            size="small"
            fullWidth
            // onChange={(e) => onChangeEleDichVu(record.id, e)}
            disabled
          ></TextField>
        );
      },
    },
    {
      title: t("formOfPricing"),
      dataIndex: "hinhThucGia",
      key: "hinhThucGia",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: text,
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("vehicleType"),
      dataIndex: "loaiXe",
      key: "loaiXe",
      width: "8%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: text,
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("numberOfKm"),
      dataIndex: "tongSoKm",
      key: "tongSoKm",
      width: "10%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return (
          <TextField
            value={record.tongSoKm}
            name="tongSoKm"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) => handleChangeEleDichVu(record.id, e)}
          ></TextField>
        );
      },
    },
    {
      title: t("tour.donGia"),
      children: [
        {
          title: t("hotel.priceClass.giaNet"),
          dataIndex: "giaNet",
          key: "giaNet",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            // return {
            //   children: handleData(text),
            //   props: {
            //     rowSpan: 1,
            //   },
            // };
            return {
              children: (
                <TextField
                  variant="outlined"
                  name="giaNet"
                  value={record.giaNet}
                  size="small"
                  fullWidth
                  onChange={(e) => handleChangeEleDichVu(record.id, e)}
                ></TextField>
              ),
              props: {
                rowSpan: 1,
              },
            };
          },
        },
        {
          title: t("tour.vanChuyen.soLuong"),
          dataIndex: "soLuong",
          key: "soLuong",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuong"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      // width: "25%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.tongChiPhi"),
      dataIndex: "tong",
      key: "tong",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: formatter.format(text),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
  ];

  const handleClear = () => {
    setMaNcc("");
    setDataSource({ ...dataSourceInit });
    setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
  };

  const handleCloseModal = () => {
    handleCancel(false);
    handleClear();
  };

  return (
    <>
      <Modal
        width={1300}
        title={t("tour.oto.capNhatDichVu")}
        footer={null}
        open={isOpen}
        onCancel={handleCloseModal}
      >
        {contextHolder}
        <ModalThongTinNccOto
          isOpen={isOpenTT}
          onIsShow={setIsOpenTT}
          onSaves={handleChangeDataServiceOto}
          action={action}
          maNcc={maNcc}
        />
        <Divider />
        <Row>
          <h3>{t("usedTime")}</h3>
        </Row>
        <Row>
          <Col span={11}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("startTime")}
                value={
                  dataSource.ngayBatDau
                    ? dayjs(formatStringDateView(dataSource.ngayBatDau))
                    : null
                }
                onChange={(date) => handleChangeNgayBatDau(date)}
                name="ngayBatDau"
                sx={{ width: "100%" }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("endTime")}
                value={
                  dataSource.ngayKetThuc
                    ? dayjs(formatStringDateView(dataSource.ngayKetThuc))
                    : null
                }
                onChange={(date) => handleChangeNgayKetThuc(date)}
                name="ngayKetThuc"
                sx={{ width: "100%" }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Col>
        </Row>
        <Divider />
        <Row>
          <h3>{t("res.serviceProvided")}</h3>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <TextField
            variant="outlined"
            fullWidth
            label={t("supplier")}
            value={dataSource.tenNcc}
            required
            InputProps={{
              style: { height: "50px" },
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateNCC()}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleClear()}
                  />
                </>
              ),
            }}
          ></TextField>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Table
              dataSource={dichVus}
              columns={columnDichVus.filter((item) => item.visible)}
              bordered
              pagination={false}
              summary={(pageData) => {
                // console.log(pageData);
                let totalDonGia = 0;
                let totalChiPhi = 0;

                pageData.forEach(({ soLuong, tong, id }) => {
                  if (id !== "NULL") {
                    totalDonGia += parseInt(soLuong);
                    totalChiPhi += parseFloat(tong);
                  }
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5}>
                        {t("tour.tongKet")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={2} align="center">
                        <Tooltip title={t("totalVehicles")}>
                          {totalDonGia}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} colSpan={1} align="center">
                        <Tooltip title={t("totalCost")}>
                          {formatter.format(totalChiPhi)}
                        </Tooltip>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              id="dmGiaApDungId"
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("priceApply")}
              value={dataSource.dmGiaApDungId}
              name="dmGiaApDungId"
              onChange={(e) => handleChangeValueDataSoure(e)}
            >
              {dmGia?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.gia_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("numberDayUse")}
              value={dataSource.soNgaySuDung}
              name="soNgaySuDung"
              // onChange={(e) => handleChangeValueDataSoure(e)}
              disabled
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeOfApply")}
              value={1}
              disabled
            >
              {dmLoaiApDung?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.loai_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("action.apply") + " " + t("thuevat")}
              value={dataSource.dmVatId}
              disabled
            >
              {dmVat?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.vat}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeTour.description")}
              value={dataSource.moTa}
              name="moTa"
              onChange={(e) => handleChangeValueDataSoure(e)}
              //   disabled
            ></TextField>
          </Col>
        </Row>
        <Divider />
        <Row>
          <h3>{t("tour.tongKet")}</h3>
        </Row>
        <Row>
          <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
            <Row>
              <Col
                span={11}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {t("tour.tongChiPhi") + " :"}
              </Col>

              <Col span={3}></Col>
              <Col
                span={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {formatter.format(tongChiPhi)}
              </Col>
            </Row>
            <Row>
              <Col
                span={11}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {formatter.format(tongChiPhi)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginTop: 6 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={10}>
            <Space>
              <Buttons
                variant="contained"
                // type="submit"
                startIcon={<SaveIcon />}
                onClick={(e) => handleSave(e)}
              >
                {t("action.ok")}
              </Buttons>
              <Buttons
                style={{
                  backgroundColor: "#898989",
                  color: "white",
                }}
                key="reset"
                startIcon={<RestartAltIcon />}
                onClick={(e) => handleClear(e)}
              >
                {t("action.reset")}
              </Buttons>
              <Buttons
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={handleCloseModal}
                key="clear"
              >
                {t("action.cancel")}
              </Buttons>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ModalCapNhatOto;
