import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTDanhSachChiPhiThucTe = (data) => {
  return axios.post(API_BASE_URL + "op/getTTDSOpChiPhiThucTe", data, {
    headers: authHeader(),
  });
};

const layTTPhieuChi = (data) => {
  return axios.post(API_BASE_URL + "op/layTTOpPhieuChiId", data, {
    headers: authHeader(),
  });
};
const saveTTPhieuChi = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpPhieuChi", data, {
    headers: authHeader(),
  });
};
const delTTPhieuChiById = (data) => {
  return axios.post(API_BASE_URL + "op/deleteOpPhieuChiById", data, {
    headers: authHeader(),
  });
};
const getDmLoaiThanhToan = (data) => {
  return axios.post(API_BASE_URL + "category/getDmLoaiThanhToan", data, {
    headers: authHeader(),
  });
};
const getDsNguoiNhan = (data) => {
  return axios.post(API_BASE_URL + "ncc/lienhe/getDataByTranxOpRateId", data, {
    headers: authHeader(),
  });
};
export default {
  layTTDanhSachChiPhiThucTe,
  layTTPhieuChi,
  saveTTPhieuChi,
  delTTPhieuChiById,
  getDmLoaiThanhToan,
  getDsNguoiNhan,
};
