import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL } from "../../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TourIcon from "@mui/icons-material/Tour";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FormTourSp from "./FormTourSp";
import { useNavigate } from "react-router-dom";
import authHeader from "../../../api/auth_header";
import {
  DataGrid,
  GridToolbar,
  viVN,
  enUS,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import TourService from "../../../api/tour/tour.service";
import Box from "@mui/material/Box";
import authHeader_download from "../../../api/auth_header_download";
import chiettinhService from "../../../api/tour/chiettinh.service";
import ModalChonLoaiTien from "./addTourSp/tabChuongTrinhTour/ModalChonLoaiTien";

const TourSanPham = ({ keySide }) => {
  const { t, i18n } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [editingKey, setEditingKey] = useState("");
  const [editingRecord, setEditingRecord] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [action, setAction] = useState(false);
  const isEditing = (record) => record.key === editingKey;
  const [filteredInfo, setFilteredInfo] = useState({});
  const [dataSearch, setDataSearch] = useState();
  const [isOpenLoaiTien, setIsOpenLoaiTien] = useState(false);
  const [tourId, setTourId] = useState(0);

  const navigate = useNavigate();
  const edit = (record) => {
    setEditingRecord(record);
    setIsShow(true);
    setAction(true);
    direct(record, "edit");
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  useEffect(() => {
    let data = {
      loaiHinhDuLich: null,
      maTour: null,
      tenTour: null,
      soNgay: null,
      loaiTour: null,
      booking: null,
      hangTour: null,
      trangThai: null,
      nguoiTao: null,
      pageIndex: 1,
      pageSize: 10000,
    };
    TourService.getTourSp(data)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          let dataArr = [];
          let dataLstSearch = [];
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              dataArr.push({
                stt: i + 1,
                id: data[i].id,
                maTour: data[i].maTour,
                loaiHinhDuLich: data[i].loaiHinhDuLich,
                tenTour: data[i].tenTour,
                soNgay: data[i].soNgay,
                soDem: data[i].soDem,
                dmLoaiTourId: data[i].dmLoaiTourId,
                loaiTour: data[i].loaiTour,
                booking: data[i].booking,
                hangTour: data[i].hangTour,
                trangThai: data[i].trangThai,
                nguoiTao: data[i].nguoiTao,
                lanSuaCuoi: data[i].lanSuaCuoi,
              });
              dataLstSearch.push({
                value:
                  data[i].id + " - " + data[i].maTour + " - " + data[i].tenTour,
              });
            }
          }
          setDataSearch(dataLstSearch);
          setDataSource(dataArr);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
        if (response.status === 401) {
        }
      });
  }, []);
  const direct = (record, status) => {
    return navigate("/dich-vu/tour-san-pham/" + status + "?id=" + record.id);
  };
  const viewRecord = (record) => {
    setEditingRecord(record);
    setIsShow(true);
    direct(record, "view");
  };

  const downloadTemplate = (record) => {
    axios({
      method: "get",
      url: API_BASE_URL + "tour/thongtinchung/downloadFiles",
      headers: authHeader_download(),
    })
      .then(function (response) {
        console.log(" response: ====", response);
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Template_Tour_San_Pham.xlsx";
        link.click();
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleQuanLyHanhTrinh = (record) => {
    return navigate("/dich-vu/tour-san-pham/ql-hanh-trinh?id=" + record.id);
  };

  const handleViewChietTinhTour = (record) => {
    redirectChietTinh(record.id);
  };

  const redirectChietTinh = (id) => {
    let data = {
      typeData: 0,
      tourThongTinChungId: id,
    };
    chiettinhService
      .getChietTinh(data)
      .then(function (response) {
        if (response.data.code === "03") {
          setIsOpenLoaiTien(true);
          setTourId(id);
        } else {
          let data = response.data.data;
          return navigate(
            "/dich-vu/tour-san-pham/chiet-tinh-tour/tour-id?=" +
              id +
              "&ty-gia-goc?=" +
              data.tyGiaGoc.id
          );
        }
      })
      .catch(function (response) {
        if (response.status === 401) {
        }
      });
  };

  const handleCancelLoaiTien = () => {
    setIsOpenLoaiTien(false);
  };

  const handleFinishLoaiTien = (loaiTien) => {
    setIsOpenLoaiTien(false);
    chietTinhTour(loaiTien, tourId);
  };

  const chietTinhTour = (loaiTien, id) => {
    return navigate(
      "/dich-vu/tour-san-pham/chiet-tinh-tour/tour-id?=" +
        id +
        "&ty-gia-goc?=" +
        loaiTien
    );
  };

  const columns = [
    {
      headerName: t("stt"),
      field: "stt",
      key: "stt",
      width: 60,
    },
    {
      headerName: t("typeTourism"),
      field: "loaiHinhDuLich",
      key: "loaiHinhDuLich",
      align: "center",
      width: 235,
    },
    {
      headerName: t("maTour"),
      field: "maTour",
      key: "maTour",
      align: "center",
    },
    {
      headerName: t("tenTour"),
      field: "tenTour",
      key: "tenTour",
      align: "center",
      width: 400,
    },
    {
      headerName: t("soNgay"),
      field: "soNgay",
      key: "soNgay",
      align: "center",
    },
    {
      headerName: t("typeTour"),
      field: "loaiTour",
      key: "loaiTour",
      align: "center",
      renderCell: ({ value, row }) => (
        <strong>
          <Button type="primary" size="small" style={{ marginLeft: 16 }}>
            {value}
          </Button>
        </strong>
      ),
    },
    {
      headerName: t("booking"),
      field: "booking",
      key: "booking",
      align: "center",
    },
    {
      headerName: t("hangTour"),
      field: "hangTour",
      key: "hangTour",
      align: "center",
    },
    {
      headerName: t("status"),
      field: "trangThai",
      key: "trangThai",
      align: "center",
      renderCell: ({ value, row }) => {
        return (
          <Switch
            defaultChecked={value}
            onChange={() => handleBorderChange(row)}
          />
        );
      },
    },
    {
      headerName: t("nguoiTao"),
      field: "nguoiTao",
      key: "nguoiTao",
      align: "center",
    },
    {
      headerName: t("lanSuaCuoi"),
      field: "lanSuaCuoi",
      key: "lanSuaCuoi",
      align: "center",
      width: 160,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FileDownloadIcon />}
          label={t("tour.taiXuong")}
          //   onClick={() => downloadTemplate(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label={t("edit")}
          onClick={() => viewRecord(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("delete")}
          //   onClick={() => handleDelete(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<AddIcon />}
          label={t("addBooking")}
          // onClick={handleDelete(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<HistoryIcon />}
          label={t("viewHistory")}
          // onClick={handleDelete(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={t("nhanBan")}
          // onClick={handleDelete(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<TourIcon />}
          label={t("quanLyHanhTrinh")}
          onClick={() => handleQuanLyHanhTrinh(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<TheaterComedyIcon />}
          label={t("chietTinhTour")}
          onClick={() => handleViewChietTinhTour(params)}
          showInMenu
        />,
      ],
    },
  ];
  const handleBorderChange = (record) => {
    let values = {
      id: record.id,
      hieu_luc: record.hieu_luc === true ? false : true,
      trangThai: record.trangThai === true ? false : true,
    };
    apiEdit(values, "hieu_luc");
  };
  const apiEdit = (values, name_key) => {
    axios({
      method: "post",
      url: API_BASE_URL + "tour/thongtinchung/updateStatus",
      data: values,
      headers: authHeader(),
    }).then(function (response) {
      if (response.status === 200 && response.data.code === "00") {
        let updatedList = dataSource.map((item) => {
          if (item.id === values.id) {
            if (name_key === "trangThai") {
              return { ...item, trangThai: values.trangThai };
            } else {
              return { ...item, hieu_luc: values.hieu_luc };
            }
          }
          return item; // else return unmodified item
        });
        setDataSource(updatedList);
        api["success"]({
          message: t("stour"),
          description: t("edit") + " " + t("noti.success").toLowerCase(),
          duration: 0,
        });
      } else {
        api["error"]({
          message: t("stour"),
          description: response.data.message,
          duration: 0,
        });
      }
    });
  };
  const handleDelete = (record) => {
    const newData = dataSource.filter((item) => item.key !== record.key);
    let apiData = {
      id: record.id,
      trangThai: record.trangThai,
      hieuLuc: record.hieuLuc ? false : true,
    };
    axios({
      method: "post",
      url: API_BASE_URL + "/nnc/thongtinchung/updateStatus",
      data: apiData,
      headers: authHeader(),
    })
      .then(function (response) {
        if (response.status === 200) {
          api["success"]({
            message: t("stour"),
            description: t("delete") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description:
              t("delete") +
              " " +
              t("noti.error").toLowerCase() +
              " " +
              response.data.message,
            duration: 0,
          });
        }
      })
      .catch(
        api["error"]({
          message: t("stour"),
          description: t("add") + " " + t("noti.error").toLowerCase(),
          duration: 0,
        })
      );
    setDataSource(newData);
  };
  const onSearch = (value) => {
    let data = [];
    for (let i in dataSearch) {
      if (
        dataSearch[i].value
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        let item = dataSearch[i].value.split(" - ");
        data.push({
          stt: i,
          id: item[0],
          maNcc: item[1],
          tenNcc: item[2],
          email: item[3],
          sdt: item[4],
          diaChi: item[5],
          trangThai: item[6],
          tongSoPhong: item[7],
        });
      }
    }
    setDataSource(data);
  };
  const QuickSearchToolbar = () => {
    return (
      <Box>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={5}>
            <GridToolbarQuickFilter style={{ width: "100%" }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <FormTourSp
              dataAdd={dataSource}
              keySide={keySide}
              handleSearch={onSearch}
            />
          </Col>
        </Row>
      </Box>
    );
  };
  return (
    <div>
      {contextHolder}
      {/*<div*/}
      {/*    style={{*/}
      {/*        marginBottom: 16,*/}
      {/*    }}*/}
      {/*>*/}
      {/*    <FormTourSp dataAdd={dataSource} keySide={keySide} handleSearch={onSearch} />*/}
      {/*</div>*/}
      <DataGrid
        sx={{
          "& .MuiDataGrid-cell": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-row": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            backgroundColor: "#e2e2e2",
            marginTop: "10px"
          },
          "& .MuiDataGrid-columnHeaderTitle ": {
            fontWeight: "bold",
          },
        }}
        rows={dataSource}
        columns={columns}
        experimentalFeatures={{ columnGrouping: false }}
        onRowDoubleClick={edit}
        autoHeight
        {...dataSource}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        {...dataSource}
        pageSizeOptions={[5, 10, 25]}
        // slotProps={{
        //     toolbar: {
        //         showQuickFilter: true,
        //     },
        // }}
        slots={{
          toolbar: QuickSearchToolbar,
        }}
        localeText={
          i18n.language === "vi"
            ? viVN.components.MuiDataGrid.defaultProps.localeText
            : enUS
        }
      />
      <ModalChonLoaiTien
        isOpen={isOpenLoaiTien}
        onCancel={handleCancelLoaiTien}
        onSave={handleFinishLoaiTien}
      />
    </div>
  );
};
export default TourSanPham;
