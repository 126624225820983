import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import bookingService from "../../../../api/booking/bookingService";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import i18n from "../../../../i18n";
import { Affix, Col, Row, Space } from "antd";
import { Button, InputAdornment, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";

const ChuongTrinhTour = ({ bookingId }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [value, setValue] = useState("");
    useEffect(() => {
        getData();
    }, []);
    const getData = async (value) => {
        const res = await bookingService.layTTChuongTrinhTourByBkThongTinChungId({
            bkThongTinChungId: 67,
            textSearch: value || "",
        });
        console.log(res.data.data);
        let data = res.data.data?.map((dt, key) => {
            dt.id = key + 1;
            return dt;
        });
        setDataSource(data);
        return data;
    };
    const handleSearch = () => {
        getData(value);
    };

    const columns = [
        { headerName: t("stt"), field: "id", key: "id", headerAlign: "center", align: "center" },
        { headerName: t("maTour"), field: "maTour", key: "maTour", flex: 1, headerAlign: "center", align: "center" },
        { headerName: t("tenTour"), field: "tenTour", key: "tenTour", flex: 1, headerAlign: "center", align: "center" },
        { headerName: t("soNgay"), field: "soNgay", key: "soNgay", flex: 1, headerAlign: "center", align: "center" },
        {
            headerName: t("tour.chiTiet") + " Tour",
            field: "ChiTiet",
            key: "ChiTiet",

            headerAlign: "center",
            renderCell: (record) => {
                return (
                    <Link to={"/dich-vu/tour-san-pham/edit?id=" + record.row.id} target="_blank" rel="noreferrer">
                        {t("watchHere")}
                    </Link>
                );
            },
        },
    ];
    return (
        <>
            <Row style={{ marginTop: "1%" }}>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Space size={20}>
                        <OutlinedInput
                            //   sx={{ height: "55px" }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            }
                            size="small"
                            placeholder={t("search")}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />

                        <Button
                            // size="large"
                            variant="contained"
                            onClick={handleSearch}
                            startIcon={<Search />}
                            color="success"
                        >
                            {t("search")}
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginTop: "1%" }}>
                <DataGrid
                    rows={dataSource}
                    columns={columns}
                    pageSizeOptions={[5, 10]}
                    autoHeight
                    localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                />
            </Row>
            <Affix offsetBottom={50}>
                <Row>
                    <Col span={12} />
                    <Col span={12}>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                height: 60,
                                paddingInline: 50,
                                lineHeight: "60px",
                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                borderRadius: "4px",
                            }}
                        >
                            <Space>
                                <Button
                                    variant="contained"
                                    // onClick={() => handleSaveBkDanhSachDoan("end")}
                                    type="end"
                                    // startIcon={<SaveIcon />}
                                >
                                    {t("action.end")}
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Affix>
        </>
    );
};

export default ChuongTrinhTour;
