import { DeleteOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Add from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, Popconfirm, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

function Row({ row, haveSaveData, deleteRow, addRow, dataDichVu }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [, contextHolder] = notification.useNotification();
  const [dataTyGia, setDataTyGia] = useState([]);
  useEffect(() => {}, []);
  useEffect(() => {
    let data = {};
    dm_ty_gia
      .getDmTyGiaHeThong(data)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDataTyGia(data);
        }
      })
      .catch(function (response) {
        if (response.status === 401) {
        }
      });
  }, []);
  const handleChangeRadio = (value) => {
    var rowNew = {
      ...row,
      apDung: value,
    };
    haveSaveData(rowNew, "apDung");
  };
  const handleDeleteRow = (id) => {
    deleteRow(id);
  };
  const handleAddRow = () => {
    addRow();
  };
  const handleChangeKMarkupVal = (value, key) => {
    row.details[key].giaTri = parseFloat(value);
    var rowNew = {
      ...row,
    };
    haveSaveData(rowNew, "");
  };
  const onChangeTyGia = (value) => {
    var rowNew = { ...row, loaiTien: value };
    haveSaveData(rowNew, "loaiTien");
  };
  return (
    <React.Fragment>
      {contextHolder}
      {row.id === "SUBTOTAL" ? (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell colSpan={2}>
              <Box
                sx={{ display: "flex", alignItems: "flex-end" }}
                onClick={() => handleAddRow()}
              >
                <Add sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <span style={{ marginBottom: "5px", fontSize: "15px" }}>
                  {t("tour.themLoaiTien")}
                </span>
              </Box>
            </TableCell>
          </TableRow>
        </>
      ) : row.id.toString().indexOf("NULL") !== -1 ? (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell colSpan={7}></TableCell>
        </TableRow>
      ) : (
        <>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {row.markupType}
            </TableCell>
            <TableCell style={{ visibility: "hidden" }}></TableCell>
            <TableCell>
              <TextField
                select
                fullWidth
                disabled={
                  row.markupType !== t("tour.fixedAmount") ? true : false
                }
                onChange={(e) => onChangeTyGia(e.target.value)}
                value={row.loaiTien}
              >
                {dataTyGia?.map((option) => (
                  <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                    {option.maNgoaiTe}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
            <TableCell>
              <Checkbox
                checked={row.apDung}
                onChange={(e) => handleChangeRadio(e.target.checked)}
                name={"radio-buttons" + row.id}
                inputProps={{ "aria-label": "A" }}
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          {row?.details?.map((rw, key) => {
            return (
              <TableRow style={{ display: open ? "" : "none" }} key={key}>
                <TableCell></TableCell>
                <TableCell>
                  <TextField
                    disabled
                    defaultValue={
                      dataDichVu.find((dv) => dv.id === rw.dmDichVuId).value
                    }
                  ></TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    onBlur={(e) => handleChangeKMarkupVal(e.target.value, key)}
                    fullWidth
                    defaultValue={rw.giaTri}
                    InputProps={
                      row.markupType !== t("tour.fixedAmount") ? (
                        {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }
                      ) : (
                        <></>
                      )
                    }
                  ></TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    disabled
                    value={
                      dataTyGia?.find((dt) => dt.maNgoaiTeId === row.loaiTien)
                        ?.maNgoaiTe
                    }
                  ></TextField>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.apDung}
                    onChange={(e) => handleChangeRadio(e.target.checked)}
                    name={"radio-buttons" + rw.id}
                    inputProps={{ "aria-label": "A" }}
                  />
                </TableCell>
                <TableCell rowSpan={1}>
                  <Typography.Link>
                    <Popconfirm
                      title={t("deleteTheTask")}
                      description={t("areYouConfirm")}
                      okText={t("delete")}
                      cancelText={t("huy")}
                      onConfirm={() => handleDeleteRow(rw.id_)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Typography.Link>
                </TableCell>
              </TableRow>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
}

export default function TableDetailThoiGianAD({
  dataDichVu,
  dataSource,
  item,
  setItem,
  baoGiaTongQuanId,
}) {
  const { t } = useTranslation();
  const [dataTable, setDataTable] = useState(dataSource);
  const [api, contextHolder] = notification.useNotification();
  const haveSaveData = (row, type) => {
    var data = [];
    if (type === "loaiTien") {
      data = dataTable.map((dt) => {
        if (dt.id_ === row.id_) return { ...dt, loaiTien: row.loaiTien };
        return dt;
      });
    } else if (type === "apDung") {
      data = dataTable.map((dt) => {
        debugger;
        if (dt.id_ === row.id_ && dt.markupType === row.markupType) {
          return { ...row, apDung: row.apDung };
        }
        if (dt.id_ === row.id_ && dt.markupType !== row.markupType) {
          return { ...dt, apDung: !row.apDung };
        }
        return dt;
      });
    } else {
      data = dataTable.map((dt) => {
        if (dt.id === row.id) return { ...row };
        return dt;
      });
    }
    setDataTable(data);
    var itNew = {
      ...item,
      rowsDT: data,
    };
    setItem(itNew);
  };
  const deleteRow = (id) => {
    baoGiaService
      .deleteCauHinhLoiNhuan({
        baoGiaTongQuanId: parseInt(baoGiaTongQuanId),
        bgCauHinhLaiId: id,
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < dataTable.length; i++) {
            const element = dataTable[i];
            if (element.id_ !== id) data.push(element);
          }
          setDataTable(data);
          var itNew = {
            ...item,
            rowsDT: data,
          };
          setItem(itNew);
          api["success"]({
            message: t("stour"),
            description: response.data.message,
            duration: 0,
          });
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
        if (response.status === 401) {
        }
      });
  };
  const addRow = () => {
    let data = [];
    for (let index = 0; index < dataTable.length; index++) {
      if (index === dataTable.length - 1) {
        let detFixedAmount = [];
        let detePrcentage = [];
        for (let i = 1; i <= 3; i++) {
          detFixedAmount.push({
            id_: "NEW" + index,
            id: Math.random() * (100 - 1),
            markupType: t("tour.fixedAmount"),
            giaTri: 0,
            apDung: true,
            dmDichVuId: i,
          });
          detePrcentage.push({
            id_: "NEW" + index,
            id: Math.random() * (100 - 1),
            markupType: t("tour.percentage"),
            giaTri: 0,
            apDung: true,
            dmDichVuId: i,
          });
        }

        data.push({
          id: "TITLE" + Math.random() * (100 - 1),
          id_: "NEW" + index,
          markupType: t("tour.fixedAmount"),
          apDung: true,
          kieuTinh: 1,
          details: detFixedAmount,
          loaiTien: 1,
        });
        data.push({
          id: "TITLE" + Math.random() * (100 - 1),
          id_: "NEW" + index,
          markupType: t("tour.percentage"),
          kieuTinh: 0,
          details: detePrcentage,
          loaiTien: 1,
        });
        data.push({
          id: "NULL " + Math.random() * (100 - 1),
        });
      } else {
        data.push(dataTable[index]);
      }
    }
    setDataTable(data);
  };

  return (
    <TableContainer component={Paper}>
      {contextHolder}
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("tour.markupType")}</TableCell>
            <TableCell>Markup Value</TableCell>
            <TableCell>{t("exchangeRate.currency")}</TableCell>
            <TableCell>{t("action.apply")}</TableCell>
            <TableCell>{t("action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable?.map((row) => (
            <Row
              key={row.id}
              row={row}
              haveSaveData={haveSaveData}
              deleteRow={deleteRow}
              addRow={addRow}
              dataDichVu={dataDichVu}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
