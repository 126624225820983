import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";
const layTTSaleRateBaoGia = (data) => {
  return axios.post(API_BASE_URL + "op/layTTSaleRateBaoGia", data, {
    headers: authHeader(),
  });
};
const layTTSaleRateTourSanPham = (data) => {
  return axios.post(API_BASE_URL + "op/layTTSaleRateTourSanPham", data, {
    headers: authHeader(),
  });
};

const layDsOpKhachSan = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpKhachSan", data, {
    headers: authHeader(),
  });
};

const saveOpKhachSan = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpKhachSan", data, {
    headers: authHeader(),
  });
};

const getDataNhomNgLonVaTreEm = (data) => {
  return axios.post(
    API_BASE_URL + "op/layTTDSNhomNguoiLonAndTreEmCuaDichVuTour",
    data,
    {
      headers: authHeader(),
    }
  );
};

export default {
  layTTSaleRateBaoGia,
  layTTSaleRateTourSanPham,
  layDsOpKhachSan,
  saveOpKhachSan,
  getDataNhomNgLonVaTreEm,
};
