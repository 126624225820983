import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Affix, Cascader, Col, notification, Radio, Row, Space } from "antd";
import Buttons from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { Divider } from "antd/lib";
import { MenuItem } from "@mui/material";
import ThongTinChungService from "../../../../api/booking/thongtinchung/thong_tin_chung.service";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import DmQuocTich from "../../../../api/category/dm_quoc_tich";
import DmQuocGia from "../../../../api/category/dm_quoc_gia";
import DmNHKeToan from "../../../../api/category/dm_nh_ke_toan";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";
import thong_tin_chungService from "../../../../api/booking/thongtinchung/thong_tin_chung.service";

const ThongTinChung = ({ bookingId }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [bg_tong_quan_id, setBg_tong_quan_id] = useState(0);
    const [maBooking, setMaBooking] = useState("");
    const [idBookingErrorText, setIdBookingErrorText] = useState("");
    const [tenBooking, setTenBooking] = useState("");
    const [tenBookingErrorText, setTenBookingErrorText] = useState("");
    const [loaiKh, setLoaiKh] = useState("");
    const [dmLoaiKhachHang, setDmLoaiKhachHang] = useState([]);
    const [loaiKhErrorText, setLoaiKhErrorText] = useState("");
    const [tenKh, setTenKh] = useState("");
    const [tenKhErrorText, setTenKhErrorText] = useState("");
    const [sdt, setSdt] = useState("");
    const [sdtErrorText, setSdtErrorText] = useState("");
    const [email, setEmail] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [dataQuocTich, setDataQuocTich] = useState([]);
    const [quocTichId, setQuocTichId] = useState(0);
    const [quocGiaId, setQuocGiaId] = useState(0);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [diaChi, setDiaChi] = useState([]);
    const [diaChiCuThe, setDiaChiCuThe] = useState("");
    const [thueVat, setThueVat] = useState("");
    const [dataThueVat, setDataThueVat] = useState([]);
    const [loaiTien, setLoaiTien] = useState("");
    const [dataLoaiTien, setDataLoaiTien] = useState([]);
    const [userAssign, setUserAssign] = useState("");
    const [dataUserAssign, setDataUserAssign] = useState([]);
    const [valueRadio, setValueRadio] = useState(true);
    const [ghiChu, setGhiChu] = useState("");
    const [dmNHKeToan, setDmNHKeToan] = useState([]);
    const [nhKeToan, setNhKeToan] = useState("");
    const [tinhId, setTinhId] = useState();
    const [huyenId, setHuyenId] = useState();
    const [xaId, setXaId] = useState();
    const [vatErrorText, setVatErrorText] = useState(false);
    const [loaiTienErrorText, setLoaiTienErrorText] = useState(false);
    const onFinish = (e) => {
        e.preventDefault();
        // if (!maBooking) {
        //     setIdBookingErrorText(t("validate") + " ID " + t("booking").toLowerCase() + "!");
        // } else {
        //     setIdBookingErrorText("");
        // }
        if (!tenBooking) {
            setTenBookingErrorText(t("validate") + " ID " + t("booking").toLowerCase() + "!");
        } else {
            setTenBookingErrorText("");
        }
        if (!loaiKh) {
            setLoaiKhErrorText(t("validate") + " " + t("loaiKH").toLowerCase() + "!");
        } else {
            setLoaiKhErrorText("");
        }
        if (!tenKh) {
            setTenKhErrorText(t("validate") + " " + t("tenKH").toLowerCase() + "!");
        } else {
            setTenKhErrorText("");
        }
        if (!sdt) {
            setSdtErrorText(t("validate") + " " + t("phoneNumber").toLowerCase() + "!");
        } else {
            setSdtErrorText("");
        }
        if (!email) {
            setEmailErrorText(t("validate") + " " + t("email").toLowerCase() + "!");
        } else {
            setEmailErrorText("");
        }
        if (!thueVat) {
            setVatErrorText(t("validate") + " " + t("thuevat").toLowerCase() + "!");
        } else {
            setVatErrorText("");
        }
        if (!loaiTien) {
            setLoaiTienErrorText(t("validate") + " " + t("exchangeRate.currency").toLowerCase() + "!");
        } else {
            setLoaiTienErrorText("");
        }
        let data = {
            bkThongTinChungId: bookingId === "new" ? 0 : bookingId,
            bgTongQuanId: bg_tong_quan_id, //th: id = 0 thì bgTongQuanId = 0, id > 0 thì bgTongQuanId sẽ > 0
            maBooking: maBooking.length > 0 ? maBooking : "", //th: id = 0 thì là "", th id>0 idBooking sẽ là id cũ. idBooking: ngày tháng năm + sô lần booking trong ngày
            tenBooking: tenBooking,
            loaiKhachHangId: loaiKh,
            tenKhachHang: tenKh,
            soDienThoai: sdt,
            email: email,
            quocTichId: quocTichId,
            quocGiaId: quocGiaId,
            tinhId: tinhId,
            huyenId: huyenId,
            xaId: xaId,
            diaDiem: diaChi,
            diaChiCuThe: diaChiCuThe,
            thueVATId: thueVat,
            loaiTienMacDinhId: loaiTien,
            userAssignId: userAssign,
            nganHangKeToanId: nhKeToan,
            thanhToanTrucTiep: true,
            ghiChu: ghiChu,
        };
        console.log(JSON.stringify(data));
        thong_tin_chungService
            .saveBkThongTinChung(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log(response);
            });
    };
    useEffect(() => {
        if (bookingId !== undefined && bookingId.length > 0) {
            let data = {
                bookingId: bookingId,
            };
            getThongTinChung(data);
        }
        getDmLoaiKhachHang();
        getUserAssign();
        getDmQuocTich();
        getDmNHKeToan();
        getDmQuocGia();
        getDmVat();
        getDmLoaiTien();
    }, []);

    const getThongTinChung = (data) => {
        ThongTinChungService.layTTBkThongTinChungById(data)
            .then(function (response) {
                console.log("Response: ====" + response.data);
                if (response.status === 200) {
                    let data = response.data.data;
                    setTenKh(data.tenKhachHang);
                    setBg_tong_quan_id(data.bgTongQuanId);
                    setTenBooking(data.tenBooking);
                    setMaBooking(data.maBooking);
                    setSdt(data.soDienThoai);
                    setEmail(data.email);
                    setThueVat(data.thueVATId);
                    setLoaiTien(data.loaiTienMacDinhId);
                    setUserAssign(data.userAssignId);
                    setGhiChu(data.ghiChu);
                    setLoaiKh(data.loaiKhachHangId);
                    setQuocTichId(data.quocTichId);
                    setQuocGiaId(data.quocGiaId);
                    setTinhId(data.tinhId);
                    setHuyenId(data.huyenId);
                    setXaId(data.xaId);
                    setDiaChi(data.diaDiem);
                    setDiaChiCuThe(data.diaChiCuThe);
                    setValueRadio(data.thanhToanTrucTiep);
                    setNhKeToan(data.nganHangKeToanId);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const getDmLoaiKhachHang = () => {
        baoGiaService
            .getDmLoaiKhachHang()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmLoaiKhachHang(data);
                }
            })
            .catch((rs) => console.log("Error getDmKhachHang: " + rs));
    };
    const getUserAssign = () => {
        baoGiaService
            .getUserAssign()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDataUserAssign(data);
                }
            })
            .catch((rs) => console.log("Error getUserAssign: " + rs));
    };
    const getDmQuocTich = () => {
        DmQuocTich.getDmQuocTich()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDataQuocTich(data);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const getDmQuocGia = () => {
        DmQuocGia.getDanhSachQuocGia()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataQuocGia = data
                        .filter((item) => item.hieu_luc === true)
                        .map((item) => ({ value: item.id, label: item.quoc_gia }));
                    setDataQuocGia(dataQuocGia);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };

    const getDmNHKeToan = () => {
        DmNHKeToan.getDmNganHangKeToan()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmNHKeToan(data);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const getDmVat = () => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDataThueVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleChangeQT = (value) => {
        setQuocTichId(value);
    };
    const handleChangeQG = (value) => {
        setQuocGiaId(value);
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        if (data.length > 0) {
                            const result = data.reduce((acc, item) => {
                                const tinhId = item.dm_tinh_id;
                                const cur = acc[tinhId] || {
                                    value: item.tinh_thanhpho,
                                    label: item.tinh_thanhpho,
                                    id: item.dm_tinh_id,
                                    children: [],
                                };
                                const huyenId = item.dm_huyen_id;
                                const curHuyen = cur.children.find((child) => child.id === huyenId);
                                if (!curHuyen) {
                                    const newHuyen = {
                                        value: item.quan_huyen,
                                        label: item.quan_huyen,
                                        id: item.dm_huyen_id,
                                        children: [],
                                    };
                                    cur.children.push(newHuyen);
                                }
                                const curPhuongXa = {
                                    value: item.phuong_xa,
                                    label: item.phuong_xa,
                                    id: item.dm_xa_id,
                                };
                                cur.children.find((child) => child.id === huyenId).children.push(curPhuongXa);
                                acc[tinhId] = cur;
                                return acc;
                            }, {});

                            const formattedResult = Object.values(result).map((tinhTP) => {
                                tinhTP.children = tinhTP.children.map((huyen) => {
                                    huyen.children = huyen.children.map((phuongXa) => ({
                                        value: phuongXa.value,
                                        label: phuongXa.label,
                                        id: phuongXa.id,
                                    }));
                                    return {
                                        value: huyen.value,
                                        label: huyen.label,
                                        id: huyen.id,
                                        children: huyen.children,
                                    };
                                });
                                return tinhTP;
                            });

                            setDataTinhTP(formattedResult);
                        }
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const getDmLoaiTien = () => {
        dm_ty_gia
            .getDmTyGiaHeThong()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDataLoaiTien(data);
                }
            })
            .catch((rs) => console.log("Error getDmLoaiTien: " + rs));
    };

    const onChangeCascader = (value, selectedOptions) => {
        setDiaChi(value ? value.join(",") : "");
        console.log(value, selectedOptions);
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    setTinhId(selectedOptions[i].id);
                }
                if (i === 1) {
                    setHuyenId(selectedOptions[i].id);
                }
                if (i === 2) {
                    setXaId(selectedOptions[i].id);
                }
            }
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    const handleChangeThueVat = (value) => {
        setThueVat(value);
    };
    const handleNHKToan = (value) => {
        setNhKeToan(value);
    };
    const handleChangeLoaiTien = (value) => {
        setLoaiTien(value);
    };
    const options = [
        {
            label: t("hinhThucThanhToan") + " " + t("chuyenKhoan"),
            value: false,
        },
        {
            label: t("hinhThucThanhToan") + " " + t("trucTiep"),
            value: true,
        },
    ];
    const onChangeRadio = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadio(e.target.value);
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            name="maBooking"
                            id="maBooking"
                            fullWidth
                            required
                            label={"ID Booking"}
                            value={maBooking}
                            onChange={(e) => setMaBooking(e.target.value)}
                            error={!!idBookingErrorText}
                            helperText={idBookingErrorText}
                            disabled
                        />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            name="tenBooking"
                            id="tenBooking"
                            fullWidth
                            required
                            label={t("name") + " Booking"}
                            value={tenBooking}
                            onChange={(e) => setTenBooking(e.target.value)}
                            error={!!tenBookingErrorText}
                            helperText={tenBookingErrorText}
                        />
                    </Col>
                </Row>
                <Divider />
                <h5>{t("contactInfo")}</h5>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            label={t("loaiKH")}
                            select
                            required
                            fullWidth
                            name="loaiKh"
                            onChange={(e) => setLoaiKh(e.target.value)}
                            value={loaiKh}
                            error={!!loaiKhErrorText}
                            helperText={loaiKhErrorText}
                        >
                            {dmLoaiKhachHang?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.loai_khach_hang}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            name="tenKh"
                            id="tenKh"
                            fullWidth
                            required
                            label={t("tenKH")}
                            value={tenKh}
                            onChange={(e) => setTenKh(e.target.value)}
                            error={!!tenKhErrorText}
                            helperText={tenKhErrorText}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            type="number"
                            name="sdt"
                            id="sdt"
                            fullWidth
                            required
                            label={t("phoneNumber")}
                            value={sdt}
                            onChange={(e) => setSdt(e.target.value)}
                            error={!!sdtErrorText}
                            helperText={sdtErrorText}
                        />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            name="email"
                            id="email"
                            fullWidth
                            required
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!emailErrorText}
                            helperText={emailErrorText}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                        <TextField
                            label={t("quocTich")}
                            select
                            fullWidth
                            name="quocTichId"
                            onChange={(e) => handleChangeQT(e.target.value)}
                            value={quocTichId}
                        >
                            {dataQuocTich.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.quoc_tich}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                        <TextField
                            label={t("location.country")}
                            select
                            fullWidth
                            name="quocGiaId"
                            onChange={(e) => handleChangeQG(e.target.value)}
                            value={quocGiaId}
                        >
                            {dataQuocGia.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <Cascader
                            style={{
                                width: "100%",
                                borderRadius: "4px",
                            }}
                            size="large"
                            options={dataTinhTP}
                            onChange={onChangeCascader}
                            showSearch={{
                                filterCascader,
                            }}
                            placeholder={
                                t("location.city") + " - " + t("location.district") + " - " + t("location.wards")
                            }
                            onSearch={(value) => console.log(value)}
                            value={diaChi.length > 0 ? diaChi.split(",") : []}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("addressDetail")}
                            onChange={(e) => setDiaChiCuThe(e.target.value)}
                            value={diaChiCuThe}
                        />
                    </Col>
                </Row>
                <Divider />
                <h5>{t("thongTinThanhToan")}</h5>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                        <TextField
                            label={t("thuevat")}
                            select
                            fullWidth
                            required
                            name="thueVat"
                            onChange={(e) => handleChangeThueVat(e.target.value)}
                            value={thueVat}
                            error={!!vatErrorText}
                            helperText={vatErrorText}
                        >
                            {dataThueVat?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.vat}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                        <TextField
                            label={t("loaiTienMacDinh")}
                            select
                            fullWidth
                            name="loaiTien"
                            onChange={(e) => handleChangeLoaiTien(e.target.value)}
                            value={loaiTien}
                            required
                            error={!!loaiTienErrorText}
                            helperText={loaiTienErrorText}
                        >
                            {dataLoaiTien.map((option) => (
                                <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                    {option.maNgoaiTe}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            label="User Assign"
                            select
                            fullWidth
                            name="userAssign"
                            onChange={(e) => setUserAssign(e.target.value)}
                            value={userAssign}
                        >
                            {dataUserAssign?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.fullName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1%" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <Radio.Group
                            style={{ marginTop: "10px" }}
                            options={options}
                            onChange={onChangeRadio}
                            value={valueRadio}
                        />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <TextField
                            label={
                                t("hotel.accountBanking.bank") +
                                "/" +
                                t("hotel.accountBanking.accountNumber") +
                                "/" +
                                t("tenTaiKhoan")
                            }
                            select
                            fullWidth
                            name="nhKeToan"
                            onChange={(e) => handleNHKToan(e.target.value)}
                            value={nhKeToan}
                        >
                            {dmNHKeToan.map((option) => (
                                <MenuItem key={option.dmNganHangKeToanId} value={option.dmNganHangKeToanId}>
                                    {option.shortName + "/" + option.soTaiKhoan + "/" + option.tenTaiKhoan}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                </Row>
                <Divider />
                <h5>{t("notes")}</h5>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                        <TextField
                            label={t("notes")}
                            fullWidth
                            name="ghiChu"
                            onChange={(e) => setGhiChu(e.target.value)}
                            value={ghiChu}
                        ></TextField>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const handleCancels = () => {};
    const handleClear = () => {};
    return (
        <Box component="form" noValidate sx={{ marginLeft: "20px", marginTop: "20px" }}>
            {contextHolder}
            {dataRender()}
            <Affix offsetBottom={50}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                height: 60,
                                paddingInline: 50,
                                lineHeight: "60px",
                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                borderRadius: "4px",
                            }}
                        >
                            <Space>
                                <Buttons variant="contained" onClick={onFinish} type="submit" startIcon={<SaveIcon />}>
                                    {t("action.ok")}
                                </Buttons>
                                <Buttons
                                    sx={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                    }}
                                    key="reset"
                                    startIcon={<RestartAltIcon />}
                                    onClick={(e) => handleClear(e)}
                                >
                                    {t("action.reset")}
                                </Buttons>
                                <Buttons
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleCancels}
                                    key="clear"
                                >
                                    {t("action.cancel")}
                                </Buttons>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Affix>
        </Box>
    );
};
export default ThongTinChung;
