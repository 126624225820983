import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Link, MenuItem, TextField, Button } from "@mui/material";
// import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import {
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccNhaHang from "./modalNcc/ModalThongTinNccNhaHang";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import dich_vu_le_service from "../../../../../api/booking/dichVuLe/dich_vu_le_service";
const ModalCapNhatNhaHang = ({
  isOpen,
  onReset,
  handleCancel,
  bkDichVuLeId,
  bkThongTinChungId,
  dmDichVuId,
  hanhDong,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [dmGia, setDmGia] = useState([]);
  const { t } = useTranslation();
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [dmVat, setDmVat] = useState([]);
  const [dmBuaAn, setDmBuaAn] = useState([]);
  const [isShowNccNH, setIsShowNccNH] = useState(false);
  const [dataSource, setDataSource] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
    soNgaySuDung: 0,
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
    soNgaySuDung: 0,
  });
  const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
  const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
  const [action, setAction] = useState("");
  const [idxEdit, setIdxEdit] = useState(0);
  const [reset, setReset] = useState(false);
  const [maNcc, setMaNcc] = useState("");

  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmVat();
    getDmBuaAn();
  }, []);

  useEffect(() => {
    if (hanhDong === "EDIT" && isOpen) {
      getBookingNhaHang(bkDichVuLeId);
    }
  }, [hanhDong, bkDichVuLeId, isOpen]);

  useEffect(() => {
    if (hanhDong === "ADD") {
      setDichVus([{ id: "NULL" }]);
      setDichVusInit([{ id: "NULL" }]);
      setDataSource({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        soNgaySuDung: 0,
      });
      setDataSourceInit({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        soNgaySuDung: 0,
      });
    }
  }, [isOpen]);

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const tongChiPhi = useMemo(() => {
    let tong = dichVus.reduce((sum, item) => {
      if (item.id !== "NULL" && !isNaN(item.tong)) sum += parseFloat(item.tong);
      return sum;
    }, 0);
    return tong * dataSource.soNgaySuDung;
  }, [dichVus, dataSource.soNgaySuDung]);

  const foc = useMemo(() => {
    let foc = dichVus.reduce((sum, item) => {
      if (item.id !== "NULL" && item.soLuong / item.suat >= 1)
        sum += parseInt(item.soLuong / item.suat) * item.giaNet;
      return sum;
    }, 0);
    return foc * dataSource.soNgaySuDung;
  }, [dichVus, dataSource.soNgaySuDung]);

  const tongThanhTien = tongChiPhi - foc;

  const getBookingNhaHang = (bkDichVuLeId) => {
    let dataReq = { bkDichVuLeId: bkDichVuLeId };
    dich_vu_le_service
      .layTTDichVuLeNhaHangById(dataReq)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let ds = {
            nccThongTinChungId: data.nccThongTinChungId,
            tenNcc: data.tenNcc,
            dmGiaApDungId: data.dmGiaApDungId,
            dmLoaiApDung: data.dmLoaiApDungId,
            // dmTyGiaId: data[0].dmTyGiaId,
            dmVatId: data.dmVatId,
            ngayBatDau: data.ngayBatDau,
            ngayKetThuc: data.ngayKetThuc,
            soNgaySuDung: data.soNgaySuDung,
            moTa: data.moTa,
          };
          let lstDichVu = data?.tranxNhaHangs.map((it, idx) => {
            return {
              id: idx + 1,
              bkTranxNhaHangId: it.bkTranxNhaHangId,
              nccDichVuNhaHangId: it.nccDichVuNhaHangId,
              tranxNhaHangId: it.tranxNhaHangId,
              tenDichVu: it.tenDichVu,
              dmBuaAnId: it.dmBuaAnId,
              suat: it.dieuKienFoc,
              foc: it.foc,
              giaNet: it.giaDichVu,
              soLuong: it.soLuong,
              tong: it.giaDichVu * it.soLuong,
              dmTyGiaId: it.dmTyGiaId,
            };
          });
          lstDichVu.push({ id: "NULL" });

          setDichVus(lstDichVu);
          setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
          setDataSource(ds);
          setDataSourceInit({ ...ds });
        }
      })
      .catch(function (response) {
        console.log("Error response getBookingNhaHang: ====" + response);
      });
  };

  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmVat = () => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmBuaAn = () => {
    dm_gia_ap_dung
      .getDmBuaAn()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmBuaAn(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleUpdateService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    setIsShowNccNH(true);
    setAction("UPDATE_SERVICE");
    setIdxEdit(index);
  };

  const handleUpdateNCC = () => {
    setMaNcc("");
    setIsShowNccNH(true);
    setAction("UPDATE_NCC");
  };

  const handleAddService = () => {
    if (dichVus.length === 1) setMaNcc("");
    setIsShowNccNH(true);
    setAction("ADD_SERVICE");
  };

  const onChangeEleDichVu = (id, event) => {
    let index = dichVus.findIndex((item) => item.id === id);
    let name = event.target.name;
    dichVus[index][name] = event.target.value;
    if (
      event.target.value < 0 &&
      (name === "soLuong" || name === "suat" || name === "foc")
    )
      dichVus[index][name] = 0;
    if (event.target.name === "soLuong")
      dichVus[index].tong = dichVus[index].giaNet * dichVus[index].soLuong;
    setDichVus([...dichVus]);
    // (dichVus[index].soLuong -
    //   parseInt(dichVus[index].soLuong / dichVus[index].suat) *
    //     dichVus[index].foc);
  };

  const handleChangeValueDataSoure = (e) => {
    dataSource[e.target.name] = e.target.value;
    if (e.target.name === "dmGiaApDungId") dataSource.ngayApDungs = [];
    setReset(!reset);
  };

  const handleDeleteService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    lstDichVu.splice(index, 1);
    setDichVus(lstDichVu);
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = getDataReq();
      console.log(dataReq);
      console.log(JSON.stringify(dataReq));
      dich_vu_le_service
        .saveDichVuLeNhaHang(dataReq)
        .then((response) => {
          let action = "add";
          if (bkDichVuLeId !== 0) action = "edit";
          if (response.status === 200 && response.data.code === "00") {
            showNoti(
              "success",
              t(action) + " " + t("noti.success").toLowerCase()
            );
            setDataSourceInit({ ...dataSource });
            setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
            handleCancel(false);
            onReset();
          } else {
            showNoti("error", response.data.message);
          }
        })
        .catch(() => {
          showNoti("error", t("system.error"));
        });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const getDataReq = () => {
    let dataReq = {
      bkDichVuLeId: bkDichVuLeId,
      bkThongTinChungId: bkThongTinChungId,
      dmDichVuId: dmDichVuId,
      nccThongTinChungId: dataSource.nccThongTinChungId,
      tenNcc: dataSource.tenNcc,
      ngayBatDau: dataSource.ngayBatDau,
      ngayKetThuc: dataSource.ngayKetThuc,
      dmGiaApDungId: dataSource.dmGiaApDungId,
      dmLoaiApDungId: 1, //dataSource.dmLoaiApDungId,
      soNgaySuDung: dataSource.soNgaySuDung,
      dmVatId: dataSource.dmVatId,
      moTa: dataSource.moTa,
      dichVuNhaHangs: dichVus
        .filter((dt) => dt.id !== "NULL")
        .map((it) => {
          return {
            tranxNhaHangId: it.tranxNhaHangId,
            nccDichVuNhaHangId: it.nccDichVuNhaHangId,
            tenDichVu: it.tenDichVu,
            dmBuaAnId: it.dmBuaAnId,
            dieuKienFoc: it.suat,
            foc: it.foc,
            giaNhaHangNhapTay: 0,
            soLuong: it.soLuong,
            dmTyGiaId: it.dmTyGiaId,
            isChecked: true,
          };
        }),
    };
    return dataReq;
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    // if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNgayApDungs");
    // }
    // else if (dataNhomNgLon.length > 0 && bgNhomNguoiLonId === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNhomNgLon");
    // }
    return result;
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const columnServices = [
    {
      title: t("service"),
      dataIndex: "tenDichVu",
      key: "tenDichVu",
      width: "15%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link onClick={() => handleAddService()} underline="none">
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 8,
            },
          };
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={record.tenDichVu}
            InputProps={{
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateService(record)}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleDeleteService(record)}
                  />
                </>
              ),
            }}
          />
        );
      },
    },
    {
      title: t("eatTime"),
      dataIndex: "dmBuaAnId",
      key: "dmBuaAnId",
      width: "10%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return (
          <TextField
            value={record.dmBuaAnId}
            name="dmBuaAnId"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => onChangeEleDichVu(record.id, e)}
            select
          >
            {dmBuaAn.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.ten_bua_an}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
    {
      title: t("hotel.roomClass.conditionFoc"),
      children: [
        {
          title: t("set"),
          dataIndex: "suat",
          key: "suat",
          width: "10%",
          align: "center",
          render: (_, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={record.suat}
                name="suat"
                variant="outlined"
                onChange={(e) => onChangeEleDichVu(record.id, e)}
                size="small"
                type="number"
                fullWidth
              ></TextField>
            );
            // return handleData(record.fitNgayThuongNet);
          },
        },
        {
          title: t("hotel.roomClass.foc"),
          dataIndex: "foc",
          key: "foc",
          width: "10%",
          align: "center",
          render: (_, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={record.foc}
                variant="outlined"
                name="foc"
                size="small"
                type="number"
                onChange={(e) => onChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
            // return handleData(record.extraNgayThuongNet);
          },
        },
      ],
      width: "20%",
      align: "center",
      visible: true,
    },
    {
      title: t("tour.donGia"),
      children: [
        {
          title: t("hotel.priceClass.giaNet"),
          dataIndex: "giaNet",
          key: "giaNet",
          width: "12%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: handleData(text),
              props: {
                rowSpan: 1,
              },
            };
          },
        },
        {
          title: t("tour.vanChuyen.soLuong"),
          dataIndex: "soLuong",
          key: "soLuong",
          width: "12%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuong"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => onChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
            // return handleData(record.extraCuoiTuanNet);
          },
        },
      ],
      // width: "25%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.tongChiPhi"),
      dataIndex: "tong",
      key: "tong",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: handleData(text),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
  ];

  const handleClear = () => {
    setMaNcc("");
    setDataSource({ ...dataSourceInit });
    setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
  };

  const handleCloseModal = () => {
    handleCancel();
    handleClear();
  };

  const handleChangeDataServiceNhaHang = (dataSelected) => {
    // console.log(dataSelected);
    let lstDichVu = [];
    if (action === "UPDATE_NCC") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      // dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = dataSelected[0].moTa;
      lstDichVu = dataSelected?.map((item, idx) => {
        return {
          id: idx + 1,
          nccDichVuNhaHangId: item.nccDvNhaHangId,
          tranxNhaHangId: item.tranxNhaHangId,
          tenDichVu: item.tenDichVu,
          dmBuaAnId: 1,
          dmTyGiaId: item.dmTyGiaId,
          suat: item.dieuKienFoc,
          foc: item.foc,
          giaNet: item.fitNgayThuongNet,
          soLuong: 0,
          tong: 0,
        };
      });
      lstDichVu.push({ id: "NULL" });
    } else if (action === "ADD_SERVICE") {
      if (dichVus.length === 1) {
        dataSource.tenNcc = dataSelected[0].tenNcc;
        dataSource.dmGiaApDungId = 1;
        dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
        dataSource.dmVatId = dataSelected[0].dmVatId
          ? dataSelected[0].dmVatId
          : 1;
        // dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
        dataSource.moTa = dataSelected[0].moTa;
      }
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu.splice(dichVus.length - 1, 1);
      lstDichVu.push({
        key: dichVus.length,
        id: dichVus.length,
        nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
        tranxNhaHangId: dataSelected[0].tranxNhaHangId,
        tenDichVu: dataSelected[0].tenDichVu,
        dmBuaAnId: 1,
        dmTyGiaId: dataSelected[0].dmTyGiaId,
        suat: dataSelected[0].dieuKienFoc,
        foc: dataSelected[0].foc,
        giaNet: dataSelected[0].fitNgayThuongNet,
        soLuong: 0,
        tong: 0,
      });
      lstDichVu.push({ id: "NULL" });
    } else {
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu[idxEdit] = {
        id: idxEdit + 1,
        nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
        tranxNhaHangId: dataSelected[0].tranxNhaHangId,
        tenDichVu: dataSelected[0].tenDichVu,
        dmBuaAnId: 1,
        dmTyGiaId: dataSelected[0].dmTyGiaId,
        suat: dataSelected[0].dieuKienFoc,
        foc: dataSelected[0].foc,
        giaNet: dataSelected[0].fitNgayThuongNet,
        soLuong: 0,
        tong: 0,
      };
    }

    if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
    setDichVus(lstDichVu);
    setDataSource({ ...dataSource });
  };

  const handleChangeNgayBatDau = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    if (newDataSource.ngayKetThuc) {
      if (
        moment(newDataSource.ngayKetThuc, "DD/MM/YYYY").isSameOrAfter(value.$d)
      ) {
        let khoangCachMilliseconds = Math.abs(
          new Date(formatStringDateView(newDataSource.ngayKetThuc)).getTime() -
            value.$d.getTime()
        );
        let khoangCachNgay = Math.ceil(
          khoangCachMilliseconds / (1000 * 3600 * 24)
        );
        newDataSource.ngayBatDau = day;
        newDataSource.soNgaySuDung = khoangCachNgay === 0 ? 1 : khoangCachNgay;
        // setSelectedStartDate(value.$d);
      } else {
        newDataSource.ngayBatDau = "";
        newDataSource.soNgaySuDung = 0;
        // setSelectedStartDate(null);
        showNoti("error", t("noti.errorSelectedDate"));
        return;
      }
    } else {
      newDataSource.ngayBatDau = day;
      // setSelectedStartDate(value.$d);
    }
    setDataSource(newDataSource);
  };

  const handleChangeNgayKetThuc = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    if (newDataSource.ngayBatDau) {
      if (
        moment(newDataSource.ngayBatDau, "DD/MM/YYYY").isSameOrBefore(value.$d)
      ) {
        let khoangCachMilliseconds = Math.abs(
          value.$d.getTime() -
            new Date(formatStringDateView(newDataSource.ngayBatDau)).getTime()
        );
        let khoangCachNgay = Math.ceil(
          khoangCachMilliseconds / (1000 * 3600 * 24)
        );
        newDataSource.ngayKetThuc = day;
        newDataSource.soNgaySuDung = khoangCachNgay === 0 ? 1 : khoangCachNgay;
        // setSelectedEndDate(value.$d);
      } else {
        newDataSource.ngayKetThuc = "";
        newDataSource.soNgaySuDung = 0;
        // setSelectedEndDate(null);
        showNoti("error", t("noti.errorSelectedDate"));
      }
    } else {
      newDataSource.ngayKetThuc = day;
      // setSelectedEndDate(value.$d);
    }
    setDataSource(newDataSource);
  };

  return (
    <>
      <Modal
        width={1300}
        title={t("tour.nhaHang.capNhatDichVu")}
        footer={null}
        open={isOpen}
        onCancel={() => handleCloseModal()}
      >
        {contextHolder}
        <Divider style={{ background: "#898989" }} />
        <Box component="form" noValidate>
          <Row>
            <h3>{t("usedTime")}</h3>
          </Row>
          <Row>
            <Col span={11}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("startTime")}
                  value={
                    dataSource.ngayBatDau
                      ? dayjs(formatStringDateView(dataSource.ngayBatDau))
                      : null
                  }
                  onChange={(date) => handleChangeNgayBatDau(date)}
                  name="ngayBatDau"
                  sx={{ width: "100%" }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("endTime")}
                  value={
                    dataSource.ngayKetThuc
                      ? dayjs(formatStringDateView(dataSource.ngayKetThuc))
                      : null
                  }
                  onChange={(date) => handleChangeNgayKetThuc(date)}
                  name="ngayKetThuc"
                  sx={{ width: "100%" }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <Divider />
          <Row>
            <h3>{t("res.serviceProvided")}</h3>
          </Row>
          <Row>
            <TextField
              variant="outlined"
              fullWidth
              label={t("supplier")}
              required
              value={dataSource.tenNcc}
              InputProps={{
                style: { height: "50px" },
                endAdornment: (
                  <>
                    <BorderColorOutlined
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => handleUpdateNCC()}
                    />
                    <CloseCircleFilled
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => handleClear()}
                    />
                  </>
                ),
              }}
            ></TextField>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Table
                dataSource={dichVus}
                columns={columnServices.filter((item) => item.visible)}
                bordered
                pagination={false}
                summary={(pageData) => {
                  // console.log(pageData);
                  let totalDonGia = 0;
                  let totalChiPhi = 0;

                  pageData.forEach(({ soLuong, tong, id }) => {
                    if (id !== "NULL") {
                      totalDonGia += parseInt(soLuong);
                      totalChiPhi += parseInt(tong);
                    }
                  });
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          {t("tour.tongKet")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          colSpan={2}
                          align="center"
                        >
                          <Tooltip title={t("totalSet")}>{totalDonGia}</Tooltip>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={2}
                          colSpan={2}
                          align="center"
                        >
                          <Tooltip title={t("totalCost")}>
                            {handleData(totalChiPhi)}
                          </Tooltip>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                id="dmGiaApDungId"
                select
                InputProps={{
                  style: { height: "50px" },
                }}
                fullWidth
                label={t("priceApply")}
                value={dataSource.dmGiaApDungId}
                name="dmGiaApDungId"
                onChange={(e) => handleChangeValueDataSoure(e)}
              >
                {dmGia?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.gia_ap_dung}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                sx={{
                  marginBottom: 2,
                }}
                fullWidth
                label={t("numberDayUse")}
                value={dataSource.soNgaySuDung}
                name="soNgaySuDung"
                // onChange={(e) => handleChangeValueDataSoure(e)}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                select
                fullWidth
                label={t("typeOfApply")}
                value={1}
                disabled
                InputProps={{
                  style: { height: "50px" },
                }}
              >
                {dmLoaiApDung?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.loai_ap_dung}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                select
                fullWidth
                label={t("action.apply") + " " + t("thuevat")}
                value={dataSource.dmVatId}
                disabled
                InputProps={{
                  style: { minHeight: "50px" },
                }}
              >
                {dmVat?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.vat_str}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={11}>
              <TextField
                fullWidth
                label={t("typeTour.description")}
                value={dataSource.moTa}
                name="moTa"
                onChange={(e) => handleChangeValueDataSoure(e)}
                // multiline
                maxRows={3}
              ></TextField>
            </Col>
          </Row>
          <Divider />
          <Row>
            <h3>{t("tour.tongKet")}</h3>
          </Row>
          <Row>
            <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              style={{ fontSize: "18px" }}
            >
              <Row>
                <Col
                  span={11}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {t("tour.tongChiPhi") + ": "}
                </Col>

                <Col span={3}></Col>
                <Col
                  span={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {handleData(tongChiPhi)}
                </Col>
              </Row>
              <Row>
                <Col
                  span={11}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  FOC:
                </Col>

                <Col span={3}></Col>
                <Col
                  span={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {handleData(foc)}
                </Col>
              </Row>
              <Row>
                <Col
                  span={11}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
                </Col>

                <Col span={3}></Col>
                <Col
                  span={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  {handleData(tongThanhTien)}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row style={{ marginTop: 6 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={10}>
              <Space>
                <Button
                  variant="contained"
                  key="save"
                  startIcon={<SaveIcon />}
                  onClick={(e) => handleSave(e)}
                >
                  {t("action.ok")}
                </Button>
                <Button
                  style={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleCloseModal()}
                  key="clear"
                >
                  {t("action.cancel")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Box>
        <ModalThongTinNccNhaHang
          isOpen={isShowNccNH}
          action={action}
          onIsShow={setIsShowNccNH}
          onSaves={handleChangeDataServiceNhaHang}
          maNcc={maNcc}
        />
      </Modal>
    </>
  );
};
export default ModalCapNhatNhaHang;
