export default function authHeader_import() {
    const user = JSON.parse(localStorage.getItem("auth"));
    console.log("====", user)
    if (user && user.accessToken) {
        // For Spring Boot back-end
        return {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user.accessToken };

    } else {
        return {};
    }
}


