import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhNhaHangTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhNhaHangTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhNhaHangTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhNhaHangTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhNhaHang = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhNhaHang", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getTinhTP = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
    headers: authHeader(),
  });
};

const getDanhMucBuaAn = (data) => {
  return axios.post(API_BASE_URL + "category/getDmBuaAn", data, {
    headers: authHeader(),
  });
};

const getNccNhaHang = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccNhaHang", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhNhaHang = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhNhaHang", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhNhaHangTheoMap,
  updateCauHinhNhaHangTheoMap,
  saveCauHinhNhaHang,
  getQuocGia,
  getTinhTP,
  getDanhMucBuaAn,
  getNccNhaHang,
  xoaCauHinhNhaHang,
};
