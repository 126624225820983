import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Modal, Row, Typography, Upload } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader_download from "../../../../../api/auth_header_download";
import authHeader_import from "../../../../../api/auth_header_import";
import { API_BASE_URL } from "../../../../../constants";
const { Link } = Typography;

function ModalImportFilePR({ isOpen, handleCancel, handleFinish }) {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [file, setFile] = useState();
    const uploadFile = () => {
        debugger;
        const fmData = new FormData();
        fmData.append("file", file);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/importExcel/5",
            data: fmData,
            headers: authHeader_import(),
        })
            .then(function (response) {
                console.log(" response: ====", response);
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };
    const props = {
        onChange({ file, fileList }) {
            if (file.status !== "uploading") {
                console.log(file, fileList);
                setFile(file);
            }
        },
        defaultFileList: [],
    };
    const onFinish = () => {
        setOpen(false);
        handleFinish();
    };
    const handleCancels = () => {
        setOpen(false);
        handleCancel();
    };

    const downloadTemplate = () => {
        axios({
            method: "get",
            url: API_BASE_URL + "ncc/dvOto/downloadTemplate/5",
            headers: authHeader_download(),
        })
            .then(function (response) {
                console.log(" response: ====", response);
                const type = response.headers["content-type"];
                const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "Template_PR.xlsx";
                link.click();
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };
    return (
        <div>
            <Modal
                title={t("add") + " " + t("informationPriceRoomFromExcel")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={800}
                bodyStyle={{ height: 400, background: "#F5F5F5" }}
            >
                <Row>
                    <Col>
                        <b>{t("note") + ": "}</b>
                    </Col>
                    <Col>
                        <ul>
                            <li>{t("noteFile1")}</li>
                            <li>{t("noteFile2")}</li>
                            <li>{t("noteFile3")}</li>
                            <li>{t("noteFile4")}</li>
                            <li>
                                {t("noteFile5") + " "}
                                <Link onClick={downloadTemplate} target="_blank">
                                    {t("here")}
                                </Link>
                            </li>
                            <li>{t("noteFile6")}</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Divider>
                        <Upload {...props} customRequest={uploadFile}>
                            <Button style={{ width: "700px", height: "80px" }} icon={<CloudUploadOutlined />}>
                                {t("buttonYploadTitle")}
                            </Button>
                        </Upload>
                    </Divider>
                </Row>
                <Row>
                    <Divider>
                        <Button type="primary">{t("action.ok")}</Button>
                        <Button type="primary">{t("action.cancel")}</Button>
                    </Divider>
                </Row>
            </Modal>
        </div>
    );
}

export default ModalImportFilePR;
