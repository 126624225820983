import { MenuItem, TextField } from "@mui/material";
import { Button, Col, Radio, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import baoGiaService from "../../../../../api/bao_gia/baoGiaService";
import dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
import ModalCauHinhTyLe from "./modalCapNhat/ModalCauHinhTyLe";
export default function TongKet({ baoGiaTongQuanId, tongGia, setReload, usingVat, vat }) {
    const { t } = useTranslation();
    const [valueRadio, setValueRadio] = useState(usingVat ? "vat" : "noVat");
    const [isOpenCH, setIsOpenCH] = useState(false);
    const [dataSource, setDataSource] = useState([{ thanhTien: "Người lớn (10 Pax)" }, { thanhTien: "Trẻ em" }]);
    const [dmVat, setDmVat] = useState([]);

    const onChangeRadio = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadio(e.target.value);
        if (e.target.value === "vat") {
            handleSaveThueGTGT(vat, true);
        } else {
            // setVat(0);
            handleSaveThueGTGT(0, false);
        }
    };
    const [items, setItems] = useState([]);
    useEffect(() => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data
                        ?.filter((it) => it.hieu_luc)
                        .map((it) => {
                            return { value: it.vat, label: it.vat_str, id: it.id };
                        });
                    setDmVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error getDmVat response: ====" + response);
            });
    }, []);
    const rows = [...items];
    const options = [
        {
            label: t("khongApDungThue"),
            value: "noVat",
        },
        {
            label: t("apDungThue"),
            value: "vat",
        },
    ];
    const isNumeric = (str) => {
        if (typeof str != "string") return false; // we only process strings!
        return (
            !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ); // ...and ensure strings of whitespace fail
    };
    const handleData = (value) => {
        let number = 0;
        if (value !== undefined) {
            number = parseInt(value);
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };
    const columnsTT = [
        {
            title: t("hotel.roomClass.intoMoney"),
            dataIndex: "thanh_tien",
        },
        {
            title: t("totalPriceNet/Group"),
            dataIndex: "tong_gia_net_nhom",
        },
        {
            title: t("totalPriceSell/Group"),
            dataIndex: "tong_gia_ban_nhom",
        },
        {
            title: t("priceNet/Persion"),
            dataIndex: "gia_net_pax",
        },
        {
            title: t("priceSell/Person"),
            dataIndex: "gia_ban_pax",
        },
    ];
    const handleTyLe = (params) => {
        setIsOpenCH(params);
    };

    const handleChangeVat = (value) => {
        // setVat(value);
        handleSaveThueGTGT(value, true);
    };

    const handleSaveThueGTGT = (thue, isApDung) => {
        let dataReq = {
            baoGiaTongQuanId: baoGiaTongQuanId,
            apDungThueGTGT: isApDung,
            thueGTGT: thue,
        };
        baoGiaService
            .saveThueGTGT(dataReq)
            .then((res) => {
                setReload(true);
            })
            .catch((err) => console.log("Save Thue GTGT Error: " + err));
    };

    const onReset = () => {
        setReload(true);
    };

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Radio.Group
                        style={{ marginTop: "10px" }}
                        options={options}
                        onChange={onChangeRadio}
                        value={valueRadio}
                        disabled
                    />
                    {valueRadio === "vat" ? (
                        <TextField
                            select
                            value={vat}
                            variant="standard"
                            style={{ width: "60px" }}
                            onChange={(e) => handleChangeVat(e.target.value)}
                        >
                            {dmVat.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : null}
                </Col>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}></Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Button onClick={() => handleTyLe(true)}>Markup All</Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
                <Col span={24}>
                    <Table columns={columnsTT} dataSource={tongGia} />
                </Col>
            </Row>
            <ModalCauHinhTyLe
                open={isOpenCH}
                handleTyLe={handleTyLe}
                baoGiaTongQuanId={baoGiaTongQuanId}
                onReset={onReset}
            />
        </>
    );
}
