import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Affix, Col, Divider, Form, Modal, Row, Space, notification, Radio, Upload, message, Cascader } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import dm_quoc_gia from "../../../../api/category/dm_quoc_gia";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import { Link } from "react-router-dom";
import ChiPhiThucTeService from "../../../../api/dieu_hanh/chi_phi_thuc_te/ChiPhiThucTeService";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";
import dm_nh_ke_toan from "../../../../api/category/dm_nh_ke_toan";
const { Dragger } = Upload;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ModalThucChi = ({ isOpen, onHidden, tranxOpRateId, opThongTinChungId, opPhieuChiId }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [fileList, setFileList] = useState([]);
    const [fileListInit, setFileListInit] = useState([]);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dmLoaiThanhToan, setDmLoaiThanhToan] = useState([]);
    const [dmLoaiKhachHang, setDmLoaiKhachHang] = useState([]);
    const [dmLoaiTien, setDmLoaiTien] = useState([]);
    const [dmNHKeToan, setDmNHKeToan] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [dsNguoiNhan, setDsNguoiNhan] = useState([]);
    const [chiTietThanhToan, setChiTietThanhToan] = useState([{ id: "NULL" }]);
    const [chiTietThanhToanInit, setChiTietThanhToanInit] = useState([{ id: "NULL" }]);
    const [dataSource, setDataSource] = useState({
        ngayTaoPhieu: null,
        ngayThanhToan: null,
        soPhieu: "",
        loaiThanhToanId: 0,
        maBooking: "",
        tenBooking: "",
        loaiKhachHangId: 0,
        loaiKhachHang: "",
        khachHang: "",
        quocGiaId: 0,
        quocGia: "",
        diaChi: "",
        nguoiNhanId: 0,
        nguoiNhan: "",
        nguoiThucHienId: 0,
        nguoiThucHien: "",
        loaiTienMacDinhId: 0,
        loaiTienMacDinh: "",
        lyDo: "",
        hinhThucThanhToan: true,
        nganHangId: 0,
        checkPermissionUser: 1,
        total: {
            tongChi: 0,
            daChi: 0,
            conLai: 0,
        },
    });
    const [dataSourceInit, setDataSourceInit] = useState({
        ngayTaoPhieu: null,
        ngayThanhToan: null,
        soPhieu: "",
        loaiThanhToanId: 0,
        maBooking: "",
        tenBooking: "",
        loaiKhachHangId: 0,
        loaiKhachHang: "",
        khachHang: "",
        quocGiaId: 0,
        quocGia: "",
        diaChi: "",
        nguoiNhanId: 0,
        nguoiNhan: "",
        nguoiThucHienId: 0,
        nguoiThucHien: "",
        loaiTienMacDinhId: 0,
        loaiTienMacDinh: "",
        lyDo: "",
        hinhThucThanhToan: true,
        nganHangId: 0,
        checkPermissionUser: 1,
        total: {
            tongChi: 0,
            daChi: 0,
            conLai: 0,
        },
    });
    const props = {
        name: "file",
        multiple: false,
        maxCount: 1,
        // disabled: fileList.length >= 5? true: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isImg =
                file.type === "image/png" ||
                file.type === "image/jpg" ||
                file.type === "image/jpeg" ||
                file.type === "image/giff";
            if (!isImg) {
                message.error(`${file.name}` + " " + t("errorUploadImg"));
            }
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                message.error("Image must smaller than 3MB!");
            }
            return isImg && isLt3M ? true : Upload.LIST_IGNORE;
        },
        onChange(info) {
            let newFileList = [...info.fileList];
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.status = "done";
                }
                return file;
            });
            debugger;
            setFileList(newFileList);
        },
        fileList,
    };
    const options = [
        {
            label: t("hinhThucThanhToan") + " " + t("chuyenKhoan"),
            value: false,
        },
        {
            label: t("hinhThucThanhToan") + " " + t("trucTiep"),
            value: true,
        },
    ];

    useEffect(() => {
        getDmQuocGia();
        getDmLoaiThanhToan();
        getDmLoaiKhachHang();
        getDmLoaiTien();
        getDmNHKeToan();
    }, []);
    useEffect(() => {
        getDsNguoiNhan({ tranxOpRateId });
    }, [tranxOpRateId]);
    useEffect(() => {
        if (opPhieuChiId)
            handleGetTTPhieuChi({
                tranxOpRateId: tranxOpRateId, // null: là màn thực chi, value: màn chi phí thực tế
                opThongTinChungId: opThongTinChungId,
                opPhieuChiId: opPhieuChiId,
            });
        else handleInitDataAdd();
    }, [tranxOpRateId, opPhieuChiId, opThongTinChungId]);
    const columns = [
        {
            title: "",
        },
        {
            title: t("noiDung"),
        },
        {
            title: t("booking.soTien"),
        },
    ];
    let formatter = new Intl.NumberFormat("en-US", {});
    const handleGetTTPhieuChi = (dataReq) => {
        ChiPhiThucTeService.layTTPhieuChi(dataReq)
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    const data = res.data.data;
                    const chiTietTT = data.chiTietThanhToan.map((item, idx) => ({
                        ...item,
                        stt: idx + 1,
                    }));
                    chiTietTT.push({ id: "NULL" });
                    setChiTietThanhToan(chiTietTT);
                    setDataSource(data);
                    setChiTietThanhToanInit([...chiTietTT]);
                    setDataSourceInit({ ...data });
                    setFileList([
                        {
                            id: data.fileDinhKem ? data.fileDinhKem.id : 0,
                            uid: "-1",
                            name: data.fileDinhKem ? data.fileDinhKem.tenFile : "",
                            status: "done",
                            thumbUrl: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                            preview: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                        },
                    ]);
                    setFileListInit([
                        {
                            id: data.fileDinhKem ? data.fileDinhKem.id : 0,
                            uid: "-1",
                            name: data.fileDinhKem ? data.fileDinhKem.tenFile : "",
                            status: "done",
                            thumbUrl: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                            preview: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                        },
                    ]);
                }
            })
            .catch((err) => console.log("Lay TT phieu chi error ..." + err));
    };
    const handleInitDataAdd = () => {
        setChiTietThanhToan([{ id: "NULL" }]);
        setDataSource({
            ngayTaoPhieu: null,
            ngayThanhToan: null,
            soPhieu: "",
            loaiThanhToanId: 0,
            maBooking: "",
            tenBooking: "",
            loaiKhachHangId: 0,
            loaiKhachHang: "",
            khachHang: "",
            quocGiaId: 0,
            quocGia: "",
            diaChi: "",
            nguoiNhanId: 0,
            nguoiNhan: "",
            nguoiThucHienId: 0,
            nguoiThucHien: "",
            loaiTienMacDinhId: 0,
            loaiTienMacDinh: "",
            lyDo: "",
            hinhThucThanhToan: true,
            nganHangId: 0,
            checkPermissionUser: 1,
            total: {
                tongChi: 0,
                daChi: 0,
                conLai: 0,
            },
        });
        setChiTietThanhToanInit([{ id: "NULL" }]);
        setDataSourceInit({
            ngayTaoPhieu: null,
            ngayThanhToan: null,
            soPhieu: "",
            loaiThanhToanId: 0,
            maBooking: "",
            tenBooking: "",
            loaiKhachHangId: 0,
            loaiKhachHang: "",
            khachHang: "",
            quocGiaId: 0,
            quocGia: "",
            diaChi: "",
            nguoiNhanId: 0,
            nguoiNhan: "",
            nguoiThucHienId: 0,
            nguoiThucHien: "",
            loaiTienMacDinhId: 0,
            loaiTienMacDinh: "",
            lyDo: "",
            hinhThucThanhToan: true,
            nganHangId: 0,
            checkPermissionUser: 1,
            total: {
                tongChi: 0,
                daChi: 0,
                conLai: 0,
            },
        });
        setFileList([]);
        setFileListInit([]);
    };
    const getDmQuocGia = () => {
        dm_quoc_gia
            .getDanhSachQuocGia()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataQuocGia = data
                        .filter((item) => item.hieu_luc === true)
                        .map((item) => ({ value: item.id, label: item.quoc_gia }));
                    setDataQuocGia(dataQuocGia);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const getDmLoaiThanhToan = () => {
        ChiPhiThucTeService.getDmLoaiThanhToan()
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dmLoaiThanhToans = data
                        .filter((item) => item.hieu_luc === true)
                        .map((item) => ({ value: item.id, label: item.loai_thanh_toan }));
                    setDmLoaiThanhToan(dmLoaiThanhToans);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const getDmLoaiKhachHang = () => {
        baoGiaService
            .getDmLoaiKhachHang()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmLoaiKhachHang(data);
                }
            })
            .catch((rs) => console.log("Error getDmKhachHang: " + rs));
    };
    const getDmLoaiTien = () => {
        dm_ty_gia
            .getDmTyGiaHeThong()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmLoaiTien(data);
                }
            })
            .catch((rs) => console.log("Error getDmLoaiTien: " + rs));
    };
    const getDmNHKeToan = () => {
        dm_nh_ke_toan
            .getDmNganHangKeToan()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmNHKeToan(data);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const getDsNguoiNhan = (dataReq) => {
        ChiPhiThucTeService.getDsNguoiNhan(dataReq)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDsNguoiNhan(data);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const handleChangeQG = (value) => {
        setDataSource({ ...dataSource, quocGiaId: value });
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        if (data.length > 0) {
                            const result = data.reduce((acc, item) => {
                                const tinhId = item.dm_tinh_id;
                                const cur = acc[tinhId] || {
                                    value: item.tinh_thanhpho,
                                    label: item.tinh_thanhpho,
                                    id: item.dm_tinh_id,
                                    children: [],
                                };
                                const huyenId = item.dm_huyen_id;
                                const curHuyen = cur.children.find((child) => child.id === huyenId);
                                if (!curHuyen) {
                                    const newHuyen = {
                                        value: item.quan_huyen,
                                        label: item.quan_huyen,
                                        id: item.dm_huyen_id,
                                        children: [],
                                    };
                                    cur.children.push(newHuyen);
                                }
                                const curPhuongXa = {
                                    value: item.phuong_xa,
                                    label: item.phuong_xa,
                                    id: item.dm_xa_id,
                                };
                                cur.children.find((child) => child.id === huyenId).children.push(curPhuongXa);
                                acc[tinhId] = cur;
                                return acc;
                            }, {});

                            const formattedResult = Object.values(result).map((tinhTP) => {
                                tinhTP.children = tinhTP.children.map((huyen) => {
                                    huyen.children = huyen.children.map((phuongXa) => ({
                                        value: phuongXa.value,
                                        label: phuongXa.label,
                                        id: phuongXa.id,
                                    }));
                                    return {
                                        value: huyen.value,
                                        label: huyen.label,
                                        id: huyen.id,
                                        children: huyen.children,
                                    };
                                });
                                return tinhTP;
                            });

                            setDataTinhTP(formattedResult);
                        }
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };
    // const handleChangeHinhThucTT = (e) => {
    //   console.log("radio checked", e.target.value);
    //   setThanhToanTrucTiep(e.target.value);
    // };

    const onChangeCascader = (value, selectedOptions) => {
        const newDataSource = { ...dataSource };
        newDataSource.diaChi = value ? value.join(",") : "";
        console.log(value, selectedOptions);
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    newDataSource.tinhId = selectedOptions[i].id;
                }
                if (i === 1) {
                    newDataSource.huyenId = selectedOptions[i].id;
                }
                if (i === 2) {
                    newDataSource.xaId = selectedOptions[i].id;
                }
            }
            setDataSource(newDataSource);
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };

    const handleAddChiTietThanhToan = () => {
        const newChiTietThanhToan = [...chiTietThanhToan];
        let indexAdd = newChiTietThanhToan.length - 1;
        newChiTietThanhToan[indexAdd] = {
            id: Math.random() + "NULL",
            stt: newChiTietThanhToan.length,
            noiDung: "",
            soTien: 0,
        };
        newChiTietThanhToan.push({ id: "NULL" });
        setChiTietThanhToan(newChiTietThanhToan);
    };

    const handleChangeChiTietThanhToan = (event, id) => {
        const newChiTietThanhToan = [...chiTietThanhToan];
        let idxEdit = newChiTietThanhToan.findIndex((dt) => dt.id === id);
        let name = event.target.name;
        let value = event.target.value;
        if (name === "soTien" && value < 0) newChiTietThanhToan[idxEdit][name] = 0;
        else newChiTietThanhToan[idxEdit][name] = value;
        setChiTietThanhToan(newChiTietThanhToan);
    };

    const handleChangeDataSource = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const newDataSource = { ...dataSource };
        newDataSource[name] = value;
        setDataSource(newDataSource);
    };

    const formatStringDateView = (strDate) => {
        let date = strDate.split("/");
        return date[1] + "/" + date[0] + "/" + date[2];
    };

    const handleChangeNgayTaoPhieu = (value) => {
        let day = moment(value.$d).format("DD/MM/YYYY");
        const newDataSource = { ...dataSource };
        newDataSource.ngayTaoPhieu = day;
        setDataSource(newDataSource);
    };

    const handleChangeNgayThanhToan = (value) => {
        let day = moment(value.$d).format("DD/MM/YYYY");
        const newDataSource = { ...dataSource };
        newDataSource.ngayThanhToan = day;
        setDataSource(newDataSource);
    };

    const handleClear = () => {
        setDataSource({ ...dataSourceInit });
        setChiTietThanhToan([...chiTietThanhToanInit]);
        setFileList([...fileListInit]);
    };

    const onFinish = async (e) => {
        e.preventDefault();
        debugger;
        let base64 = "";
        if (fileList[0] && fileList[0].originFileObj) base64 = await getBase64(fileList[0].originFileObj);
        let dataReq = {
            tranxOpRateId: tranxOpRateId, // truyen id khi phieu chi di theo dich vu, co the null doi voi TH tao theo OpThongTinChung
            opThongTinChungId: opThongTinChungId, // always
            opPhieuChiId: opPhieuChiId, // truyen id khi update
            dmLoaiThanhToanId: dataSource.loaiThanhToanId,
            nccLienHeId: dataSource.nguoiNhanId,
            dmLoaiKhachHangId: dataSource.loaiKhachHangId, // doi voi TH theo OpThongTinChung
            tenKhachHang: dataSource.khachHang, // doi voi TH theo OpThongTinChung
            dmQuocGiaId: dataSource.quocGiaId, // doi voi TH theo OpThongTinChung
            dmTinhId: dataSource.tinhId, // doi voi TH theo OpThongTinChung
            dmHuyenId: dataSource.huyenId, // doi voi TH theo OpThongTinChung
            dmXaId: dataSource.xaId, // doi voi TH theo OpThongTinChung
            lyDo: dataSource.lyDo,
            thanhToanTrucTiep: dataSource.hinhThucThanhToan,
            nccTaiKhoanNganHangId: dataSource.nganHangId,
            fileRequest: {
                id: fileListInit[0] ? fileListInit[0].id : 0,
                tenFile: fileList[0].name,
                base64Value: base64
                    ? base64.substring(base64.indexOf("base64") + 7)
                    : fileList[0].preview?.substring(fileList[0].preview?.indexOf("base64") + 7),
            },
            chiTietThanhToanRequest:
                chiTietThanhToan
                    .filter((it) => it.id !== "NULL")
                    .map((item) => ({
                        id: item.id.toString()?.indexOf("NULL") === -1 ? item.id : 0,
                        noiDung: item.noiDung,
                        soTien: item.soTien,
                    })) || [],
        };
        console.log(dataReq);
        console.log(JSON.stringify(dataReq));
        // ChiPhiThucTeService.saveTTPhieuChi(dataReq)
        //   .then((response) => {
        //     if (response.status === 200 && response.data.code === "00") {
        //       api["success"]({
        //         message: t("stour"),
        //         description: t("add") + " " + t("noti.success").toLowerCase(),
        //         duration: 0,
        //       });
        //     } else {
        //       api["error"]({
        //         message: t("stour"),
        //         description: response.data.message,
        //         duration: 0,
        //       });
        //     }
        //   })
        //   .catch(function (response) {
        //     console.log(response);
        //     api["error"]({
        //       message: t("stour"),
        //       description: t("system.error"),
        //       duration: 0,
        //     });
        //   });
    };

    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Row>
                    <Col span={19}>
                        <h3>{t("booking.thongTinBienNhan")}</h3>
                        <Row style={{ marginTop: "20px" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={t("booking.ngayTaoPhieu")}
                                        value={
                                            dataSource.ngayTaoPhieu
                                                ? dayjs(formatStringDateView(dataSource.ngayTaoPhieu))
                                                : null
                                        }
                                        onChange={(date) => handleChangeNgayTaoPhieu(date)}
                                        name="ngayTaoPhieu"
                                        format="DD/MM/YYYY"
                                        sx={{ width: "100%" }}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col span={11}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={t("booking.ngayThanhToan")}
                                        value={
                                            dataSource.ngayThanhToan
                                                ? dayjs(formatStringDateView(dataSource.ngayThanhToan))
                                                : null
                                        }
                                        onChange={(date) => handleChangeNgayThanhToan(date)}
                                        name="ngayThanhToan"
                                        sx={{ width: "100%" }}
                                        format="DD/MM/YYYY"
                                    />
                                </LocalizationProvider>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="soPhieu"
                                    id="soPhieu"
                                    fullWidth
                                    required
                                    label={t("booking.soPhieu")}
                                    value={dataSource.soPhieu}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("booking.loaiThanhToan")}
                                    select
                                    required
                                    fullWidth
                                    name="loaiThanhToanId"
                                    value={dataSource.loaiThanhToanId}
                                >
                                    {dmLoaiThanhToan?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Divider />
                        <h3>{t("booking.thongTinChung")}</h3>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="maBooking"
                                    fullWidth
                                    required
                                    label={t("maBooking")}
                                    value={dataSource.maBooking}
                                    onChange={(e) => handleChangeDataSource(e)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="tenBooking"
                                    fullWidth
                                    required
                                    label={t("tenBooking")}
                                    value={dataSource.tenBooking}
                                    onChange={(e) => handleChangeDataSource(e)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    select
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="loaiKhachHang"
                                    fullWidth
                                    required
                                    label={t("loaiKH")}
                                    value={dataSource.loaiKhachHangId}
                                    onChange={(e) => handleChangeDataSource(e)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                >
                                    {dmLoaiKhachHang?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.loai_khach_hang}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="khachHang"
                                    fullWidth
                                    required
                                    label={t("client")}
                                    value={dataSource.khachHang}
                                    onChange={(e) => handleChangeDataSource(e)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("location.country")}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    name="quocGiaId"
                                    onChange={(e) => handleChangeQG(e.target.value)}
                                    value={dataSource.quocGiaId}
                                >
                                    {dataQuocGia.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                    }}
                                    size="large"
                                    options={dataTinhTP}
                                    onChange={onChangeCascader}
                                    showSearch={{
                                        filterCascader,
                                    }}
                                    placeholder={
                                        t("location.city") +
                                        " - " +
                                        t("location.district") +
                                        " - " +
                                        t("location.wards")
                                    }
                                    onSearch={(value) => console.log(value)}
                                    value={dataSource.diaChi ? dataSource.diaChi.split(",") : []}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    select
                                    sx={{
                                        marginTop: 2,
                                    }}
                                    name="nguoiNhanId"
                                    fullWidth
                                    required
                                    label={t("receiver")}
                                    value={dataSource.nguoiNhanId}
                                    onChange={(e) => handleChangeDataSource(e)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                >
                                    {dsNguoiNhan.map((option) => (
                                        <MenuItem key={option.nguoiNhanId} value={option.nguoiNhanId}>
                                            {option.hoTen}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginTop: 2,
                                    }}
                                    name="nguoiThucHien"
                                    fullWidth
                                    required
                                    label={t("implementer")}
                                    value={dataSource.nguoiThucHien}
                                    //   onChange={(e) => setTenKh(e.target.value)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    select
                                    sx={{
                                        marginTop: 2,
                                    }}
                                    name="loaiTienMacDinhId"
                                    fullWidth
                                    required
                                    label={t("loaiTienMacDinh")}
                                    value={dataSource.loaiTienMacDinhId}
                                    //   onChange={(e) => setTenKh(e.target.value)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                >
                                    {dmLoaiTien.map((option) => (
                                        <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                            {option.maNgoaiTe}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginTop: 2,
                                    }}
                                    name="lyDo"
                                    fullWidth
                                    label={t("booking.lyDo")}
                                    value={dataSource.lyDo}
                                    onChange={(e) => handleChangeDataSource(e)}
                                    //   error={!!tenKhErrorText}
                                    //   helperText={tenKhErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <Divider />
                            </Col>
                        </Row>

                        <h3>{t("formOfPayment")}</h3>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Radio.Group
                                    style={{ marginTop: "10px" }}
                                    options={options}
                                    name="hinhThucThanhToan"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.hinhThucThanhToan}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={
                                        t("hotel.accountBanking.bank") +
                                        "/" +
                                        t("hotel.accountBanking.accountNumber") +
                                        "/" +
                                        t("tenTaiKhoan")
                                    }
                                    select
                                    fullWidth
                                    name="nganHangId"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.nganHangId}
                                >
                                    {dmNHKeToan.map((option) => (
                                        <MenuItem key={option.dmNganHangKeToanId} value={option.dmNganHangKeToanId}>
                                            {option.shortName + "/" + option.soTaiKhoan + "/" + option.tenTaiKhoan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <Divider />
                            </Col>
                        </Row>
                        <h3>{t("paymentDetails")}</h3>
                        <Row style={{ marginBottom: "1%" }}>
                            <table>
                                <thead style={{ backgroundColor: "#f5f5f5" }}>
                                    {columns.map((cl, k) => {
                                        return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                                    })}
                                </thead>
                                <tbody>
                                    {chiTietThanhToan.map((item, idx) => {
                                        return (
                                            <tr>
                                                {item.id === "NULL" ? (
                                                    <td colSpan="100%">
                                                        <Link onClick={() => handleAddChiTietThanhToan()}>
                                                            <PlusOutlined /> {t("add")}
                                                        </Link>
                                                    </td>
                                                ) : (
                                                    <>
                                                        <td>{item.stt}</td>
                                                        <td style={{ width: "75%" }}>
                                                            <TextField
                                                                name="noiDung"
                                                                fullWidth
                                                                required
                                                                value={item.noiDung}
                                                                onChange={(e) =>
                                                                    handleChangeChiTietThanhToan(e, item.id)
                                                                }
                                                            />
                                                        </td>
                                                        <td style={{ width: "20%" }}>
                                                            <TextField
                                                                type="number"
                                                                name="soTien"
                                                                fullWidth
                                                                required
                                                                value={item.soTien}
                                                                onChange={(e) =>
                                                                    handleChangeChiTietThanhToan(e, item.id)
                                                                }
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Row>
                        <Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <div>Tổng chi:</div>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    {formatter.format(dataSource.total?.tongChi)}
                                </div>
                            </div>
                        </Row>
                        <Divider />
                        <Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <div>Đã chi:</div>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    {formatter.format(dataSource.total?.daChi)}
                                </div>
                            </div>
                        </Row>
                        <Divider />
                        <Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <div>Còn lại:</div>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    {formatter.format(dataSource.total?.conLai)}
                                </div>
                            </div>
                        </Row>
                        <Divider style={{ paddingBottom: "20px" }} />
                    </Col>
                    <Col span={5} style={{ marginTop: "10%" }}>
                        <div>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{t("ClickorDag")}</p>
                                <p className="ant-upload-hint">{t("validateUploadImg")}</p>
                            </Dragger>
                        </div>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={<h2>{t("phieuChi")}</h2>}
                open={isOpen}
                onOk={onFinish}
                onCancel={onHidden}
                footer={null}
                width={1300}
                // bodyStyle={{ height: 800 }}
            >
                <Divider style={{ margin: "0px 0px", backgroundColor: "black" }} />
                <Box component="form" noValidate>
                    {dataRender()}
                    <Affix offsetBottom={50}>
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <Row>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            color: "#fff",
                                            height: 60,
                                            paddingInline: 50,
                                            lineHeight: "60px",
                                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Space>
                                            <Button
                                                variant="contained"
                                                onClick={onFinish}
                                                type="submit"
                                                startIcon={<Save />}
                                            >
                                                {t("action.ok")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#898989",
                                                    color: "white",
                                                }}
                                                key="reset"
                                                startIcon={<RestartAlt />}
                                                onClick={(e) => handleClear(e)}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<Delete />}
                                                onClick={onHidden}
                                                key="clear"
                                            >
                                                {t("action.cancel")}
                                            </Button>
                                        </Space>
                                    </div>
                                </Row>
                            </Affix>
                        </Form.Item>
                    </Affix>
                </Box>
            </Modal>
        </>
    );
};
export default ModalThucChi;
