import { Badge, Col, Divider, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import DichVuLeService from "../../../../../api/dieu_hanh/sale_rate/DichVuLeService";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CheckOutlined, CloseCircleFilled, EditFilled } from "@ant-design/icons";
import ModalCapNhatOto from "./modalCapNhatDV/ModalCapNhatOto";
import ModalCapNhatKS from "./modalCapNhatDV/ModalCapNhatKS";
import ModalCapNhatNhaHang from "./modalCapNhatDV/ModalCapNhatNhaHang";

const DichVuLe = () => {
    const { t } = useTranslation();
    const [status, setStatus] = useState(0);
    const [countNoti, setCountNoti] = useState({
        tatCa: 0,
        daCheck: 0,
        chuaCheck: 0,
    });
    const [chiPhiDichVuLes, setChiPhiDichVuLes] = useState([]);
    const [dataDichVuLe, setDataDichVuLe] = useState([]);
    const [tongKet, setTongKet] = useState(0);
    const [columnsDV, setColumnsDV] = useState([
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("supplier"),
        },
        {
            title: t("service"),
        },
        {
            title: t("luaChon"),
        },
        {
            title: t("hotel.roomClass.conditionFoc"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tour.vanChuyen.soLuong"),
        },
        {
            title: t("hotel.roomClass.intoMoney"),
        },
        {
            title: t("thuevat"),
        },
        {
            title: t("supplier") + "(OP)",
        },
        {
            title: "OP Check",
        },
    ]);
    const [columnsCP, setColumnsCP] = useState([
        {
            title: "STT",
        },
        {
            title: t("typeService"),
        },
        {
            title: t("description"),
        },
        {
            title: t("unit"),
        },
        {
            title: t("quantity"),
        },
        {
            title: "FOC",
        },
        {
            title: t("chietKhau"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("tongTien"),
        },
    ]);
    const [isOpenOto, setIsOpenOto] = useState(false);
    const [isOpenKs, setIsOpenKs] = useState(false);
    const [isOpenNh, setIsOpenNh] = useState(false);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [tranxOpSaleRateId, setTranxOpSaleRateId] = useState(0);
    const [reset, setReset] = useState(false);

    let formatter = new Intl.NumberFormat("en-US", {});

    useEffect(() => {
        let dataReq = {
            opThongTinChungId: "3",
            trangThai: status, // 0: Tất cả, 1: Đã check, 2: Chưa check
        };
        DichVuLeService.layTTSaleRateDichVuLe(dataReq)
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    const data = res.data.data;
                    let chiPhiDV =
                        data.chiPhiDichVuLes?.map((item, idx) => ({
                            stt: idx + 1,
                            ...item,
                        })) || [];
                    let lstDichVu =
                        data.dichVuLes?.flatMap(
                            (it) =>
                                it.dichVuLeDetails.map((item, idx) => ({
                                    rowSpanLDV: idx === 0 ? it.dichVuLeDetails.length : 0,
                                    loaiDichVu: it.loaiDichVu,
                                    dmDichVuId: it.dmDichVuId,
                                    tranxOpSaleRateId: it.tranxOpSaleRateId,
                                    checkPermissionUser: it.checkPermissionUser,
                                    tenNcc: it.tenNcc,
                                    opTenNcc: it.opTenNcc,
                                    opCheck: it.opCheck,
                                    ...item,
                                })) || []
                        ) || [];
                    setDataDichVuLe(lstDichVu);
                    setChiPhiDichVuLes(chiPhiDV);
                    setCountNoti({
                        tatCa: data.tatCa,
                        daCheck: data.daCheck,
                        chuaCheck: data.chuaCheck,
                    });
                    setTongKet(data.tongKet);
                }
            })
            .catch((err) => {
                console.log("layTTSaleRateDichVuLe Error ..." + err);
            });
    }, [status, reset]);

    const theadStyle = {
        backgroundColor: "#E2E2E2",
        color: "black",
        fontWeight: "bold",
    };

    const thStyle = {
        padding: "10px",
        // border: "1px solid #dee2e6",
        // textAlign: "left",
        // color: "white",
        fontWeight: "normal",
    };

    const tdStyle = {
        padding: "10px",
        // border: "1px solid #dee2e6",
        textAlign: "center",
    };

    const table_dv = () => {
        let stt = 1;
        return (
            <div style={{ width: "100%", height: "400px", overflow: "auto" }}>
                <table style={{ width: "100%" }}>
                    <thead style={theadStyle}>
                        {columnsDV.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dataDichVuLe?.map((item, key) => {
                            return (
                                <tr key={key}>
                                    {item.rowSpanLDV && item.rowSpanLDV !== 0 ? (
                                        <>
                                            <td rowSpan={item.rowSpanLDV} style={{ width: "2%" }}>
                                                {stt++}
                                            </td>
                                            <td rowSpan={item.rowSpanLDV} style={tdStyle}>
                                                {item.loaiDichVu}
                                            </td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <td style={tdStyle}>{item.tenNcc}</td>
                                    <td style={tdStyle}>{item.dichVu}</td>
                                    <td style={tdStyle}>{item.luaChon}</td>
                                    <td style={tdStyle}>
                                        {item.loaiDichVu.indexOf("oto") !== -1 ? (
                                            <></>
                                        ) : (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <div>
                                                    {item.dieuKienFocNh}
                                                    {item.loaiDichVu.indexOf("khách sạn") !== -1
                                                        ? " " + t("room")
                                                        : " " + t("set")}
                                                </div>
                                                <div>
                                                    {item.focNh + " "} {t("hotel.roomClass.foc")}
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td style={tdStyle}>{formatter.format(item.donGia)}</td>
                                    <td style={tdStyle}>{item.soLuong}</td>
                                    <td style={tdStyle}>{formatter.format(item.thanhTien)}</td>
                                    <td style={tdStyle}>{item.thueVat}</td>
                                    <td style={{ width: "15%" }}>
                                        <TextField
                                            fullWidth
                                            // disabled={item.opCheck}
                                            placeholder={t("supplier")}
                                            size="small"
                                            value={item.opTenNcc}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton size="small">
                                                            <EditFilled
                                                                onClick={() => handleAddOrUpdateServices(item)}
                                                            />
                                                        </IconButton>
                                                        <IconButton size="small" disabled>
                                                            <CloseCircleFilled />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </td>
                                    <td style={tdStyle}>
                                        <Box style={{ display: "flex" }}>
                                            <IconButton disabled={item.opCheck}>
                                                <VisibilityIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={!item.checkPermissionUser}
                                                // onClick={() => handleSave(item)}
                                            >
                                                <CheckOutlined />
                                            </IconButton>
                                        </Box>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const table_cp = () => {
        return (
            <div
                style={
                    dataDichVuLe.length > 0 ? { width: "100%", height: "400px", overflow: "auto" } : { width: "100%" }
                }
            >
                <table>
                    <thead style={theadStyle}>
                        {columnsCP.map((cl) => {
                            return <th style={thStyle}>{cl.title}</th>;
                        })}
                    </thead>
                    <>
                        {chiPhiDichVuLes.map((item) => {
                            return (
                                <tr>
                                    <td style={tdStyle}>{item.stt}</td>
                                    <td style={tdStyle}>{item.loaiDichVu}</td>
                                    <td style={tdStyle}>{item.moTa}</td>
                                    <td style={tdStyle}>{item.donVi}</td>
                                    <td style={tdStyle}>{item.soLuong}</td>
                                    <td style={tdStyle}>{item.foc}</td>
                                    <td style={tdStyle}>{item.chietKhau + " %"}</td>
                                    <td style={tdStyle}>{formatter.format(item.donGia)}</td>
                                    <td style={tdStyle}>{formatter.format(item.tongTien)}</td>
                                </tr>
                            );
                        })}
                    </>
                </table>
            </div>
        );
    };

    const handleAddOrUpdateServices = (item) => {
        console.log(item);
        // console.log(dataDv);
        if (item.tranxOpSaleRateId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setTranxOpSaleRateId(item.tranxOpSaleRateId);
        switch (item.dmDichVuId) {
            case 1:
                return setIsOpenKs(true);
            case 2:
                return setIsOpenOto(true);
            case 3:
                return setIsOpenNh(true);
            default:
                return null;
        }
    };

    const handleReset = () => {
        setReset(!reset);
    };

    return (
        <>
            <Row>
                <Space size={20}>
                    <Badge count={countNoti.tatCa} key={0}>
                        <Button disabled={status === 0} variant="outlined" onClick={() => setStatus(0)}>
                            {t("tatCa")}
                        </Button>
                    </Badge>
                    <Badge count={countNoti.daCheck} key={1}>
                        <Button disabled={status === 1} variant="outlined" onClick={() => setStatus(1)}>
                            {t("checked")}
                        </Button>
                    </Badge>
                    <Badge count={countNoti.chuaCheck} key={2}>
                        <Button disabled={status === 2} variant="outlined" onClick={() => setStatus(2)}>
                            {t("unchecked")}
                        </Button>
                    </Badge>
                </Space>
            </Row>
            <Row>
                <h3>{t("bookRetailService")}</h3>
                {table_dv()}
            </Row>
            <Divider style={{ backgroundColor: "black" }} />
            <Row>
                <h3>{t("retailServiceCost")}</h3>
                {table_cp()}
            </Row>
            <Row style={{ marginTop: "10px" }}>
                <Col
                    span={21}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {t("tour.tongKet") + " :"}
                </Col>
                <Col
                    span={3}
                    style={{
                        paddingLeft: "5px",
                    }}
                >
                    {formatter.format(tongKet)}
                </Col>
            </Row>
            <ModalCapNhatNhaHang
                isOpen={isOpenNh}
                handleCancel={setIsOpenNh}
                hanhDong={hanhDong}
                tranxOpSaleRateId={tranxOpSaleRateId}
                onReset={handleReset}
            />
            <ModalCapNhatKS
                isOpen={isOpenKs}
                handleCancel={setIsOpenKs}
                hanhDong={hanhDong}
                tranxOpSaleRateId={tranxOpSaleRateId}
                onReset={handleReset}
            />
            <ModalCapNhatOto
                isOpen={isOpenOto}
                handleCancel={setIsOpenOto}
                hanhDong={hanhDong}
                tranxOpSaleRateId={tranxOpSaleRateId}
                onReset={handleReset}
            />
        </>
    );
};
export default DichVuLe;
