import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Col, Divider, notification, Popconfirm, Row, Space, Tag, Typography } from "antd";
import { InputAdornment, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FormPhieuThu from "./FormPhieuThu";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import ThucThuSevices from "../../../../api/booking/thucThu/thuc_thu_services";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import thuc_thu_services from "../../../../api/booking/thucThu/thuc_thu_services";
import FormPhieuThuAction from "./FormPhieuThuAction";

const ThucThu = ({ bookingId }) => {
    const { t, i18n } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAction, setIsOpenAction] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [editingKey, setEditingKey] = useState("");
    const [tongThucThu, setTongThucThu] = useState();
    const [tongThuTT, setTongThuTT] = useState(0);
    const [daThu, setDaThu] = useState();
    const [isReload, setIsReload] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        setEditingRecord(record);
        setIsOpenAction(true);
    };
    useEffect(() => {
        getThongTinThucThu();
    }, []);

    useEffect(() => {
        if (isReload) {
            getThongTinThucThu(textSearch);
            setIsReload(false);
        }
    }, [isReload]);
    const handleSearch = () => {
        setIsReload(true);
    };
    const handleAdd = () => {
        setIsOpen(true);
    };
    const handleCloseModal = () => {
        setIsOpen(false);
        setIsOpenAction(false);
        setEditingRecord(null);
    };
    const handleFinish = async (data) => {
        try {
            console.log(JSON.stringify(data));
            const response = await thuc_thu_services.saveBkPhieuThu(data);
            if (response.status === 200 && response.data.code === "00") {
                setIsReload(true);
                setIsOpen(false);
                setIsOpenAction(false);
                setEditingRecord(null);
                api["success"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: 0,
                });
            } else {
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: 0,
                });
            }
        } catch (error) {
            console.log(" errors " + error);
            api["error"]({
                message: t("stour"),
                description: t("system.error"),
                duration: 0,
            });
        }
    };
    const getThongTinThucThu = async (value) => {
        try {
            const data = {
                bkThongTinChungId: bookingId,
                textSearch: value || "",
            };
            const response = await ThucThuSevices.layTTThucThuByBkThongTinChungId(data);

            if (response.status === 200 && response.data.code === "00") {
                console.log("layTTThucThuByBkThongTinChungId: ", response.data.data);

                const newData = response.data.data?.map((item, idx) => ({
                    ...item,
                    stt: idx + 1,
                    id: item.bkPhieuThuId,
                }));

                if (newData) {
                    const tongThucThu = newData.reduce(
                        (total, item) =>
                            item.trangThai === "Đã chuyển kế toán" || item.trangThai === "Chưa chuyển kế toán"
                                ? total + item.soTien
                                : total,
                        0
                    );

                    const tongThuTT = newData.reduce(
                        (total, item) =>
                            item.trangThai === "Đã thanh toán" || item.trangThai === "Chưa thanh toán"
                                ? total + item.soTien
                                : total,
                        0
                    );

                    setTongThucThu(tongThucThu);
                    setTongThuTT(tongThuTT);
                    setDaThu(
                        newData.reduce(
                            (total, item) => (item.trangThai === "Đã thanh toán" ? total + item.soTien : total),
                            0
                        )
                    );
                    setDataSource(newData);
                }
            }
        } catch (error) {
            console.log("Get data bao gia errors " + error);
        }
    };

    const handleDeleteRow = (id) => {
        let data = {
            bkPhieuThuId: id,
        };
        ThucThuSevices.deleteBkPhieuThuById(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    setIsReload(true);
                    api["success"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch((err) => {
                console.log("Get data bao gia erorrs " + err);
            });
    };

    const columns = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            width: 50,
            type: "number",
        },
        {
            headerName: t("booking.soPhieu"),
            field: "soPhieu",
            key: "soPhieu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("booking.ngayTaoPhieu"),
            field: "ngayTaoPhieu",
            key: "ngayTaoPhieu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("booking.ngayThanhToan"),
            field: "ngayThanhToan",
            key: "ngayThanhToan",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("booking.loaiBienNhan"),
            field: "loaiBienNhan",
            key: "loaiBienNhan",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("booking.ngNopTien"),
            field: "tenKhachHang",
            key: "tenKhachHang",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hinhThucThanhToan"),
            field: "hinhThucThanhToan",
            key: "hinhThucThanhToan",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("booking.soTien"),
            field: "soTien",
            key: "soTien",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("notes"),
            field: "lyDo",
            key: "lyDo",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("status"),
            field: "trangThai",
            key: "trangThai",
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                if (row.id) {
                    // +
                    return value === "Đã huỷ" ? (
                        <Tag color="red">{value}</Tag>
                    ) : value === "Đã hoàn thành" ? (
                        <Tag color="green">{value}</Tag>
                    ) : value === "Đã chuyển kế toán" ? (
                        <Tag color="geekblue">{value}</Tag>
                    ) : value === "Chưa chuyển kế toán" ? (
                        <Tag color="purple">{value}</Tag>
                    ) : value === "Chưa thanh toán" ? (
                        <Tag color="magenta">{value}</Tag>
                    ) : (
                        <Tag>{value}</Tag>
                    );
                }
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "action",
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                if (row.id !== undefined) {
                    return (
                        <Space>
                            <Typography.Link disabled={editingKey !== ""} onClick={() => edit(row)}>
                                <EditOutlined />
                            </Typography.Link>
                            <Typography.Link>
                                <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDeleteRow(row.id)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        </Space>
                    );
                }
            },
        },
    ];

    return (
        <Box>
            {contextHolder}
            <FormPhieuThu isOpen={isOpen} onCancels={handleCloseModal} handleFinish={handleFinish} />
            <FormPhieuThuAction
                isOpen={isOpenAction}
                onCancels={handleCloseModal}
                handleFinish={handleFinish}
                editingRecord={editingRecord}
            />
            <Row style={{ marginTop: "1%" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Space size={20}>
                        <OutlinedInput
                            //   sx={{ height: "55px" }}
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            }
                            size="small"
                            placeholder={t("search")}
                            value={textSearch}
                            onChange={(e) => setTextSearch(e.target.value)}
                        />

                        <Button variant="contained" onClick={handleSearch} startIcon={<Search />} color="success">
                            {t("search")}
                        </Button>
                    </Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <Button variant="outlined" onClick={handleAdd} startIcon={<AddIcon />} color="warning">
                        {t("add")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "1%" }}>
                <DataGrid
                    rows={dataSource}
                    columns={columns}
                    localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                />
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={14}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {t("tongThuDuToan")} :
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {tongThucThu}
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={14}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {t("tongThuTT")} :
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {tongThuTT}
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={14}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {t("daThu")} :
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {daThu}
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={14}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {t("conLai")} :
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    {tongThuTT - daThu}
                </Col>
            </Row>
        </Box>
    );
};
export default ThucThu;
