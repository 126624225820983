import { LeftOutlined, PlusCircleFilled } from "@ant-design/icons";
import AddBox from "@mui/icons-material/AddBox";
import Delete from "@mui/icons-material/Delete";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button, Link, MenuItem, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Cascader, Col, Collapse, Input, notification, Row, Space, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import dm_do_tuoi from "../../../../api/category/dm_do_tuoi";
import { API_BASE_URL } from "../../../../constants";
import dich_vu_tourService from "../../../../api/booking/dichVuTour/dich_vu_tour.service";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Save from "@mui/icons-material/Save";
import { useNavigate } from "react-router";
const ThongTinTour = ({ bkDichVuTourId, setBkDichVuTourId, setView, action, bookingId }) => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [tenTour, setTenTour] = useState("");
    const [tenTourErrorText, setTenTourErrorText] = useState("");
    const [soNgay, setSoNgay] = useState(0);
    const [soNgayErrorText, setSoNgayErrorText] = useState("");
    const [soDemErrorText, setSoDemErrorText] = useState("");
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [quocGiaId, setQuocGiaId] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [soDem, setSoDem] = useState("");
    const [items, setItems] = useState([
        {
            quocGiaId: 0,
            tinhId: 0,
            huyenId: 0,
            xaId: 0,
            diaDiem: "",
            diaDiemCuThe: "",
        },
    ]);
    const [dataDoTuoi, setDataDoTuoi] = useState([]);
    const [optionNhomTreEm, setOptionNhomTreEm] = useState([]);
    const { t } = useTranslation();
    const [bkThongTinChungId, setBkThongTinChungId] = useState();
    const [tourThongTinChungId, setTourThongTinChungId] = useState();
    const [ngayKhoiHanh, setngayKhoiHanh] = useState();
    const [ngayKetThuc, setngayKetThuc] = useState();
    const [dmHangTourId, setDmHangTourId] = useState(0);
    const [soLuongNguoiLon, setSoLuongNguoiLon] = useState(0);
    const [soLuongTreEm, setSoLuongTreEm] = useState(0);
    const [foc, setFoc] = useState(0);
    const [loaiTienTourMauId, setLoaiTienTourMauId] = useState("");
    const [maNgoaiTe, setMaNgoaiTe] = useState(0);
    const [tyGia, setTyGia] = useState(0);
    const [dmDonViId, setDmDonViId] = useState(0);
    const [nhomNguoiLons, setNhomNguoiLons] = useState([]);
    const [nhomTreEms, setNhomTreEms] = useState([]);
    const [chiPhiTours, setChiPhiTours] = useState([]);
    const [loaiTienMacDinh, setLoaiTienMacDinh] = useState("");
    useEffect(() => {
        getData(5);
    }, []);
    const getData = async (data) => {
        let response = await dich_vu_tourService.layTTBkThongTinTourById({
            bkDichVuTourId: data,
        });
        if (response.data.code === "00") {
            setBkThongTinChungId(response.data.data.bkThongTinChungId);
            setTourThongTinChungId(response.data.data.tourThongTinChungId);
            setTenTour(response.data.data.tenTour);
            setngayKhoiHanh(response.data.data.ngayKhoiHanh);
            setngayKetThuc(response.data.data.ngayKetThuc);
            setSoDem(response.data.data.soDem);
            setSoNgay(response.data.data.soNgay);
            setDmHangTourId(response.data.data.dmHangTourId);
            setSoLuongNguoiLon(response.data.data.soLuongNguoiLon);
            setSoLuongTreEm(response.data.data.soLuongTreEm);
            setFoc(response.data.data.foc);
            setLoaiTienTourMauId(response.data.data.loaiTienTourMauId);
            setMaNgoaiTe(response.data.data.maNgoaiTe);
            setTyGia(response.data.data.tyGia);
            setDmDonViId(response.data.data.dmDonViId);
            setItems(response.data.data.tranxDiaDiems);
            setLoaiTienMacDinh(response.data.data.loaiTienMacDinh);
            let dataTableNhomTreEms = [];
            let optionTreEms = [];
            let count = 0;
            response.data.data?.nhomTreEms?.forEach((item, idx) => {
                item.stt = idx + 1;
                optionTreEms.push({
                    maNhom: item.bkNhomTreEmId + "",
                    tenNhom: item.tenNhom,
                });
                let treEms = item.doTuois?.map((dt, index) => {
                    count++;
                    return {
                        id: count,
                        stt: idx + 1,
                        nhomTreEm: item.tenNhom,
                        soLuong: dt.soLuong,
                        dmDoTuoiId: dt.dmDoTuoiId,
                        bkNhomNguoiLonId: item.bkNhomNguoiLonId,
                        rowSpan: index === 0 ? item.doTuois.length : 0,
                        doTuoi: dt.doTuoi,
                    };
                });
                dataTableNhomTreEms.push(...treEms);
            });
            dataTableNhomTreEms.push({ id: "SUBTOTAL" });
            let dataTableNhomNguoiLons = response.data.data?.nhomNguoiLons?.map((item, idx) => {
                return {
                    id: item.bkNhomNguoiLonId,
                    stt: idx + 1,
                    nhomNguoiLon: item.tenNhom,
                    soLuong: item.soLuong,
                    foc: item.foc,
                    maNhomTreEm: item?.nhomTreEmsIn?.map((it) => {
                        return it.bkNhomTreEmId + "";
                    }),
                    rowSpan: 1,
                };
            });
            dataTableNhomNguoiLons.push({ id: "SUBTOTAL" });
            debugger;
            setOptionNhomTreEm(optionTreEms);
            setDataTableTreEm(dataTableNhomTreEms);
            setDataTableNguoiLon(dataTableNhomNguoiLons);

            setChiPhiTours(
                response.data.data.chiPhiTours.map((ct, index) => {
                    return { id: index, ...ct };
                })
            );
        }
    };

    const handleAddField = () => {
        setItems([
            ...items,
            {
                quocGiaId: 0,
                tinhId: 0,
                huyenId: 0,
                xaId: 0,
                diaDiem: "",
                diaDiemCuThe: "",
            },
        ]);
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    console.log(data);
                    setDataDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    console.log(data);
                    setDataDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);
    const handleChangeQG_DiemDi = (e) => {
        setQuocGiaId(e.target.value);
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {
                                        value: o.quan_huyen,
                                        label: o.quan_huyen,
                                        id: o.dm_huyen_id,
                                        children: [],
                                    };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const handleItemChange = (name, index, value) => {
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
    };
    const onChangeCascader_item = (value, selectedOptions, index) => {
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    handleItemChange("tinhId", index, selectedOptions[i].id);
                }
                if (i === 1) {
                    handleItemChange("huyenId", index, selectedOptions[i].id);
                }
                if (i === 2) {
                    handleItemChange("xaId", index, selectedOptions[i].id);
                }
                handleItemChange("diaDiem", index, value.join(","));
            }
        }
    };

    const handleRemoveField = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };
    const handleChaneDiemDen = (value, index) => {
        setItems(
            items.map((it, ind) => {
                if (ind === index)
                    return {
                        ...it,
                        diaDiemCuThe: value,
                    };
                else return it;
            })
        );
    };
    const style = {
        h4: { fontSize: "14px", fontWeight: "bold", marginTop: "10px" },
        rowTop: { marginTop: "10px" },
        InputLabelProps: { color: "#858383", fontSize: "22px", fontWeight: 600 },
    };
    const handleAddRow = (row) => {
        let data = [];
        let stt = 0;
        dataTableTreEm.forEach((dt) => {
            if ("SUBTOTAL" === dt.id) {
                data.push({
                    id: "NEW" + new Date().getTime(),
                    stt: stt + 1,
                    nhomTreEm: "",
                    soLuong: 1,
                    dmDoTuoiId: 1,
                    rowSpan: 1,
                });
                setOptionNhomTreEm([...optionNhomTreEm, { maNhom: stt + 1 + "", tenNhom: "" }]);
            } else stt = dt.stt;
            data.push(dt);
        });
        setDataTableTreEm(data);
    };
    const handleChangeDataTreEm = (e, row) => {
        let name = e.target.name;
        let index = dataTableTreEm.findIndex((it) => it.id === row.id);
        if (name === "nhomTreEm") {
            let indexEdit = optionNhomTreEm.findIndex((item) => +item.maNhom === row.stt);
            if (indexEdit !== -1) {
                optionNhomTreEm[indexEdit].tenNhom = e.target.value;
                setOptionNhomTreEm([...optionNhomTreEm]);
            }
        }
        dataTableTreEm[index][name] = e.target.value;
        setDataTableTreEm([...dataTableTreEm]);
    };

    const handleAddSub = (row) => {
        let data = [];
        let lstTreEmByGroup = dataTableTreEm.filter((item) => item.stt === row.stt);
        dataTableTreEm.forEach((dt) => {
            if (row.id === dt.id) {
                dt.rowSpan = lstTreEmByGroup.length + 1;
                data.push(dt);
                data.push({
                    id: "NEW" + new Date().getTime(),
                    stt: dt.stt,
                    nhomTreEm: "",
                    soLuong: 1,
                    dmDoTuoiId: 1,
                    rowSpan: 0,
                });
            } else data.push(dt);
        });
        setDataTableTreEm(data);
    };

    const columnsTreEm = [
        {
            title: t("stt"),
            dataIndex: "stt",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRow(row)}>
                                <Link underline="none">{t("addChildrenGroup")}</Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 2,
                        },
                    };
                }
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpan,
                    },
                };
            },
        },
        {
            title: t("childrenGroup"),
            dataIndex: "nhomTreEm",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                if (row.rowSpan > 0) {
                    return {
                        children: (
                            <>
                                <Row>
                                    <Col xs={21} sm={21} md={21} lg={20}>
                                        {row.id.toString().indexOf("NEW") !== -1 ? (
                                            <TextField
                                                name="nhomTreEm"
                                                onChange={(e) => handleChangeDataTreEm(e, row)}
                                                value={row.nhomTreEm}
                                                size="small"
                                            ></TextField>
                                        ) : (
                                            text
                                        )}
                                    </Col>
                                    <Col xs={1} sm={1} md={1} lg={1}>
                                        <PlusCircleFilled onClick={() => handleAddSub(row)} />
                                    </Col>
                                </Row>
                            </>
                        ),
                    };
                }
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <TextField
                            name="soLuong"
                            type="number"
                            size="small"
                            value={text}
                            onChange={(e) => handleChangeDataTreEm(e, row)}
                        ></TextField>
                    </>
                );
            },
        },
        {
            title: t("age"),
            dataIndex: "dmDoTuoiId",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <Row>
                            <Col xs={21} sm={21} md={21} lg={20}>
                                <TextField
                                    name="dmDoTuoiId"
                                    fullWidth
                                    select
                                    size="small"
                                    value={text}
                                    onChange={(e) => handleChangeDataTreEm(e, row)}
                                >
                                    {dataDoTuoi.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.do_tuoi}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1}>
                                <Delete onClick={() => handleDeleteTreEm(row)} />
                            </Col>
                        </Row>
                    </>
                );
            },
        },
    ];
    const handleDeleteTreEm = (row) => {
        let data = dataTableTreEm.filter((item) => item.id !== row.id);
        let lstTreEmByGroup = data.filter((item) => item.stt === row.stt);
        let dataFirst = data.find((item) => item.stt === row.stt);
        if (!dataFirst) {
            let indexRemove = optionNhomTreEm.findIndex((item) => +item.maNhom === row.stt);
            optionNhomTreEm.splice(indexRemove, 1);
            let optionTE = optionNhomTreEm.map((dt, index) => {
                return { ...dt, maNhom: index + 1 + "" };
            });
            setOptionNhomTreEm(optionTE);
            let dataNL = dataTableNguoiLon.map((dt) => {
                return { ...dt, maNhomTreEm: [] };
            });
            setDataTableNguoiLon(dataNL);
        }
        data?.forEach((dt) => {
            if (dataFirst) {
                if (dataFirst.id === dt.id) {
                    dt.rowSpan = lstTreEmByGroup.length;
                }
            } else {
                if (dt.stt > row.stt) {
                    dt.stt = dt.stt - 1;
                }
            }
        });
        setDataTableTreEm(data);
    };
    const [dataTableNguoiLon, setDataTableNguoiLon] = useState([
        // {
        //   id: 1,
        //   stt: 1,
        //   nhomNguoiLon: "Người lớn 1",
        //   soLuong: 2,
        //   dmDoTuoiId: 2,
        //   maNhomTreEm: [],
        //   rowSpan: 1,
        // },
        { id: "SUBTOTAL" },
    ]);
    const [dataTableTreEm, setDataTableTreEm] = useState([
        // {
        //   id: 1,
        //   stt: 1,
        //   nhomTreEm: "Trẻ em 1",
        //   soLuong: 2,
        //   dmDoTuoiId: 2,
        //   rowSpan: 1,
        // },
        { id: "SUBTOTAL" },
    ]);
    const handleAddRowNL = (row) => {
        let data = [];
        let stt = 0;
        dataTableNguoiLon.forEach((dt) => {
            if ("SUBTOTAL" === dt.id) {
                data.push({
                    id: "NEW" + new Date().getTime(),
                    stt: stt + 1,
                    nhomNguoiLon: "",
                    soLuong: 1,
                    dmDoTuoiId: 1,
                    maNhomTreEm: [],
                });
            } else stt = dt.stt;
            data.push(dt);
        });
        setDataTableNguoiLon(data);
    };

    const handleChangeDataNL = (e, row) => {
        let name = e.target.name;
        let index = dataTableNguoiLon.findIndex((it) => it.id === row.id);
        dataTableNguoiLon[index][name] = e.target.value;
        setDataTableNguoiLon([...dataTableNguoiLon]);
    };

    const handleDeleteNL = (row) => {
        let data = dataTableNguoiLon.filter((item) => item.id !== row.id);
        data?.forEach((dt) => {
            if (dt.stt > row.stt) {
                dt.stt = dt.stt - 1;
            }
        });
        setDataTableNguoiLon(data);
    };
    const columnsNguoiLon = [
        {
            title: t("stt"),
            dataIndex: "stt",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRowNL(row)}>
                                <Link underline="none">{t("addAdultGroup")}</Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 2,
                        },
                    };
                }
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpan,
                    },
                };
            },
        },
        {
            title: t("adultGroup"),
            dataIndex: "nhomNguoiLon",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        {row.id.toString().indexOf("NEW") !== -1 ? (
                            <TextField
                                name="nhomNguoiLon"
                                size="small"
                                onChange={(e) => handleChangeDataNL(e, row)}
                                value={text}
                            ></TextField>
                        ) : (
                            text
                        )}
                    </>
                );
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <TextField
                            name="soLuong"
                            fullWidth
                            type="number"
                            value={text}
                            size="small"
                            onChange={(e) => handleChangeDataNL(e, row)}
                        ></TextField>
                    </>
                );
            },
        },
        {
            title: t("hotel.roomClass.foc"),
            dataIndex: "foc",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <TextField
                            name="foc"
                            fullWidth
                            type="number"
                            value={text}
                            size="small"
                            onChange={(e) => handleChangeDataNL(e, row)}
                        ></TextField>
                    </>
                );
            },
        },
        {
            title: t("quanLyNhomTreEm"),
            dataIndex: "dmDoTuoiId",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <Row>
                            <Col xs={21} sm={21} md={21} lg={20}>
                                <TextField
                                    name="maNhomTreEm"
                                    fullWidth
                                    select
                                    size="small"
                                    // value={text}
                                    SelectProps={{
                                        multiple: true,
                                        value: row.maNhomTreEm,
                                        onChange: (e) => handleChangeDataNL(e, row),
                                    }}
                                >
                                    {optionNhomTreEm.map((option) => (
                                        <MenuItem key={option.maNhom} value={option.maNhom}>
                                            {option.tenNhom}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1}>
                                <Delete onClick={() => handleDeleteNL(row)} />
                            </Col>
                        </Row>
                    </>
                );
            },
        },
    ];
    const children = (
        <>
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                        <div style={style.h4}>{t("childrenGroup")}</div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={9}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                        <div style={style.h4}>{t("adultGroup")}</div>
                    </Col>
                </Row>
                <Row style={style.rowTop}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <Table bordered columns={columnsTreEm} dataSource={dataTableTreEm} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Table bordered columns={columnsNguoiLon} dataSource={dataTableNguoiLon} />
                    </Col>
                </Row>
            </Box>
        </>
    );
    const columns = [
        {
            headerName: t("khoangKhach"),
            flex: 1,
            editable: true,
            field: "khoangKhach",
            key: "khoangKhach",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("tour.giaNet"),
            flex: 1,
            editable: true,
            field: "giaNet",
            key: "giaNet",
            align: "center",
            headerAlign: "center",
        },
        {
            flex: 1,
            editable: true,
            headerName: t("tour.giaBan"),
            field: "giaBan",
            key: "giaBan",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("quantity"),
            flex: 1,
            editable: true,
            field: "soLuong",
            key: "soLuong",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("hotel.roomClass.foc"),
            flex: 1,
            editable: true,
            field: "foc",
            key: "foc",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("hotel.roomClass.intoMoney"),
            flex: 1,
            editable: true,
            field: "intoMoney",
            key: "intoMoney",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("chietKhau"),
            field: "chietKhau",
            flex: 1,
            editable: true,
            key: "chietKhau",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("tongTien"),
            flex: 1,
            editable: true,
            field: "tongTien",
            key: "tongTien",
            align: "center",
            headerAlign: "center",
        },
    ];
    const handleSave = (e) => {
        e.preventDefault();
        let nhomTreEms = [];
        let groupTreEm = Object.groupBy(dataTableTreEm, ({ stt }) => stt);
        let keyGroupTreEm = Object.keys(groupTreEm);
        for (let index = 0; index < keyGroupTreEm.length - 1; index++) {
            let element = keyGroupTreEm[index];
            let listChiPhiTour = [];
            groupTreEm[element]?.forEach((item) => {
                listChiPhiTour.push(chiPhiTours.find((itemC) => itemC.bkDoTuoiId === item.bkDoTuoiId));
            });
            nhomTreEms.push({
                tenNhom: groupTreEm[element][0].nhomTreEm,
                listDoTuoi: groupTreEm[element]?.map((item) => {
                    return {
                        soLuong: item.soLuong,
                        dmDoTuoiId: item.dmDoTuoiId,
                        doTuoi: item.doTuoi,
                    };
                }),
                listChiPhiTour: listChiPhiTour,
            });
        }
        let nhomNguoiLons = [];
        dataTableNguoiLon.forEach((item, index) => {
            if (index !== dataTableNguoiLon.length - 1)
                nhomNguoiLons.push({
                    maNhomTreEm: item.maNhomTreEm,
                    tenNhom: item.nhomNguoiLon,
                    soLuong: item.soLuong,
                    foc: item.foc,
                });
        });
        let data = {
            bkDichVuTourId: bkDichVuTourId, //0: Thêm mới, >0 update
            bkThongTinChungId: bkThongTinChungId,
            tourThongTinChungId: tourThongTinChungId, //0: là tour từ báo giá, >0 thì là tour sản phẩm sẽ có id
            tenTour: tenTour,
            ngayKhoiHanh: ngayKhoiHanh,
            ngayKetThuc: ngayKetThuc,
            soNgay: soNgay,
            soDem: soDem,
            hangTourId: dmHangTourId, //0: báo giá không có hạng tour. >0 là tour sản phẩm
            soLuongNguoiLon: soLuongNguoiLon,
            soLuongTreEm: soLuongTreEm,
            foc: foc,
            loaiTienTourMau: loaiTienMacDinh,
            tyGia: tyGia,
            listDiaDiem: items.map((it) => {
                return { ...it, bkDichVuTourId: bkDichVuTourId };
            }),
            // chi phí tour cho số lượng người lớn
            chiPhiTour: dataTableNguoiLon,
            listNhomTreEm: nhomTreEms,
        };
        console.log(data);
        dich_vu_tourService
            .saveTongQuan(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    // handleResetDataTQ();
                    // setId(response.data.data);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                console.log("Save baoGiaTongQuanError ...");
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const handleChangeService = () => {
        // setView("SALE_RATE");
        navigate("/dat-dich-vu/dich-vu-tour/thong-tin-tour/sale-rate/" + action + "?id=" + bookingId);
    };

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                    <Link>
                        <LeftOutlined /> {tenTour}
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                    <TextField
                        name="tenTour"
                        id="tenTour"
                        sx={{
                            marginBottom: 2,
                            marginTop: 2,
                        }}
                        required
                        fullWidth
                        label={t("tenTour")}
                        value={tenTour}
                        onChange={(e) => setTenTour(e.target.value)}
                        error={!!tenTourErrorText}
                        helperText={tenTourErrorText}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("ngayKhoiHanh")}
                            value={ngayKhoiHanh}
                            // onChange={(e) => setMaTour(e.target.value)}
                            // error={!!maTourErrorText}
                            // helperText={maTourErrorText}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("ngayKetThuc")}
                            value={ngayKetThuc}
                            // onChange={(e) => setMaTour(e.target.value)}
                            // error={!!maTourErrorText}
                            // helperText={maTourErrorText}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="soNgay"
                        id="soNgay"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        type="number"
                        label={t("soNgay")}
                        value={soNgay}
                        onChange={(e) => setSoNgay(e.target.value)}
                        error={!!soNgayErrorText}
                        helperText={soNgayErrorText}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="soDem"
                        id="soDem"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        type="number"
                        label={t("soDem")}
                        value={soDem}
                        onChange={(e) => setSoDem(e.target.value)}
                        error={!!soDemErrorText}
                        helperText={soDemErrorText}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="hangTour"
                        id="hangTour"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        select
                        label={t("hangTour")}
                        value={dmHangTourId}
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    ></TextField>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="soLuongNgLon"
                        id="soLuongNgLon"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        select
                        label={t("soLuongNgLon")}
                        value={soLuongNguoiLon}
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    ></TextField>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="soLuongTreEm"
                        id="soLuongTreEm"
                        sx={{
                            marginBottom: 2,
                        }}
                        fullWidth
                        label={t("soLuongTreEm")}
                        value={soLuongTreEm}
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="foc"
                        id="foc"
                        sx={{
                            marginBottom: 2,
                        }}
                        fullWidth
                        label={t("hotel.roomClass.foc")}
                        value={foc}
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="loaiTienMau"
                        id="loaiTienMau"
                        sx={{
                            marginBottom: 2,
                        }}
                        fullWidth
                        label={t("tour.loaiTienMau")}
                        value={loaiTienMacDinh}
                        disabled
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="loaiTienBaoGia"
                        id="loaiTienBaoGia"
                        sx={{
                            marginBottom: 2,
                        }}
                        fullWidth
                        label={t("tour.loaiTienBaoGia")}
                        value={loaiTienTourMauId}
                        disabled
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="exchangeRate"
                        id="exchangeRate"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        label={t("exchangeRate")}
                        value={tyGia}
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                    <TextField
                        name="ChuyenBay"
                        id="ChuyenBay"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        label={t("ChuyenBay")}
                        value={soNgay}
                        // onChange={(e) => setSoNgay(e.target.value)}
                        // error={!!soNgayErrorText}
                        // helperText={soNgayErrorText}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                    {items.map((item, index) => (
                        <>
                            <Row key={index} style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                    <Space.Compact
                                        size="large"
                                        style={{
                                            width: "100%",
                                            marginBottom: 15,
                                            height: "56px",
                                        }}
                                        className=""
                                    >
                                        <Input
                                            style={{
                                                width: "25%",
                                                borderRadius: "4px",
                                            }}
                                            readOnly
                                            defaultValue={t("destination")}
                                        />
                                        <TextField
                                            xs={{
                                                width: "75%",
                                                borderRadius: "4px",
                                            }}
                                            label={t("location.country")}
                                            select
                                            fullWidth
                                            name="quocGiaId"
                                            // onChange={(event) => handleChangeQG_item(event, index)}
                                            value={item.dmQuocGiaId}
                                        >
                                            {dataQuocGia.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Space.Compact>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Cascader
                                        style={{
                                            width: "100%",
                                            borderRadius: "4px",
                                        }}
                                        size="large"
                                        options={dataTinhTP}
                                        onChange={(value, selectedOptions) =>
                                            onChangeCascader_item(value, selectedOptions, index)
                                        }
                                        showSearch={{
                                            filterCascader,
                                        }}
                                        placeholder={
                                            t("location.city") +
                                            " - " +
                                            t("location.district") +
                                            " - " +
                                            t("location.wards")
                                        }
                                        onSearch={(value) => console.log(value)}
                                        value={item.diaDiem.length > 0 ? item.diaDiem.split(",") : []}
                                    />
                                </Col>
                                {items.length > 1 ? (
                                    <RemoveCircleOutline
                                        style={{ marginTop: "15px", marginLeft: "10px" }}
                                        onClick={() => handleRemoveField()}
                                    />
                                ) : null}
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                    <TextField
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                        fullWidth
                                        label={t("diemDenCuThe")}
                                        onChange={(e) => handleChaneDiemDen(e.target.value, index)}
                                        value={item.diaDiemCuThe}
                                    />
                                </Col>
                            </Row>
                        </>
                    ))}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                    <AddBox onClick={() => handleAddField()} style={{ marginTop: "15px" }} />
                </Col>
            </Row>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse
                        style={style.rowTop}
                        defaultActiveKey={"1"}
                        items={[
                            {
                                key: "1",
                                label: (
                                    <div
                                        style={{
                                            textTransform: "uppercase",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("quanLyNhomKhach")}
                                    </div>
                                ),
                                children: children,
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        margin: "20px 0",
                    }}
                >
                    <div style={style.h4}>{t("tourCost")}</div>
                    <button style={{ marginRight: "20px" }} onClick={() => handleChangeService()}>
                        {t("serviceChanges")}
                    </button>
                </div>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <DataGrid columns={columns} rows={chiPhiTours} />
                </Col>
            </Row>
            <Row>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}></Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    {t("tour.tongKet") + " : "}{" "}
                    {chiPhiTours.reduce((sum, item) => {
                        if (item.id !== "NULL") sum += parseFloat(item.tong);
                        return sum;
                    }, 0)}
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={2} xl={5}></Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                    <div
                        style={{
                            textAlign: "center",
                            color: "#fff",
                            height: 60,
                            paddingInline: 50,
                            lineHeight: "60px",
                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                            borderRadius: "4px",
                        }}
                    >
                        <Space>
                            <Button
                                variant="contained"
                                // key="cancel"
                                // startIcon={<Save />}
                                // onClick={(e) => handleSave(e, "luu")}
                            >
                                {t("action.cancel")}
                            </Button>
                            <Button
                                variant="outlined"
                                // startIcon={<Delete />}
                                onClick={(e) => handleSave(e)}
                                key="ok"
                            >
                                {t("action.ok")}
                            </Button>
                        </Space>
                    </div>
                </Col>
            </Row>
            {contextHolder}
        </>
    );
};

export default ThongTinTour;
