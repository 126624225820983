import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhOtoTheoMap = (data) => {
    return axios.post(API_BASE_URL + "tour/chiettinh/layCauHinhOtoTheoMapCTCT", data, {
        headers: authHeader(),
    });
};

const updateCauHinhOtoTheoMap = (data) => {
    return axios.post(API_BASE_URL + "tour/chiettinh/updateCauHinhOtoTheoMapCTCT", data, {
        headers: authHeader(),
    });
};

const saveCauHinhOto = (data) => {
    return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhOTo", data, {
        headers: authHeader(),
    });
};

const getNccOto = (data) => {
    return axios.post(API_BASE_URL + "tour/chiettinh/getNccOto", data, {
        headers: authHeader(),
    });
};
const getQuocGia = (data) => {
    return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
        headers: authHeader(),
    });
};

const getTinhTP = (data) => {
    return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
        headers: authHeader(),
    });
};

const getDmSoCho = (data) => {
    return axios.post(API_BASE_URL + "category/getDmSoCho", data, {
        headers: authHeader(),
    });
};

const getDmLoaiXe = (data) => {
    return axios.post(API_BASE_URL + "category/getDmLoaiXe", data, {
        headers: authHeader(),
    });
};

const getDmHinhThucGia = (data) => {
    return axios.post(API_BASE_URL + "category/getDmHinhThucGia", data, {
        headers: authHeader(),
    });
};

const xoaCauHinhOto = (data) => {
    return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhOTo", data, {
        headers: authHeader(),
    });
};

export default {
    getCauHinhOtoTheoMap,
    updateCauHinhOtoTheoMap,
    saveCauHinhOto,
    getNccOto,
    getQuocGia,
    getTinhTP,
    getDmSoCho,
    getDmLoaiXe,
    getDmHinhThucGia,
    xoaCauHinhOto,
};
