import {ACCESS_TOKEN} from "./index";

export const isEmpty=(obj) =>{
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}
export const headers=()=>{
    if(localStorage.getItem(ACCESS_TOKEN)){
        return {

        }
    }
}