import React from 'react';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Checkbox, Form, Input} from 'antd';
import '../../assets/css/auth/login.css'
import {Col, Row} from 'antd';
import {useTranslation} from "react-i18next";

const LoginForm = ({sendLogin}) => {
    const onFinish = (values) => {
        sendLogin(values)
    };
    const {t} = useTranslation();
    return (
        <div className="login">
            <Row style={{backdropColor: '#ffff'}}>
                <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 2}}>

                </Col>
                <Col xs={{span: 11, offset: 1}} lg={{span: 5, offset: 2}}>

                    <Row style={{marginTop: '30%'}}>
                        <Col span={6}></Col>
                        <Col span={10} style={{marginLeft: '7%'}}><h3>SMART TOUR</h3></Col>
                        <Col span={6}></Col>
                    </Row>

                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <h3>{t("login.title")}
                        </h3>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: t("login.username"),
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: t("login.password"),
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>{t("login.remember")}</Checkbox>
                            </Form.Item>

                            <Button type="primary" htmlType="submit" className="login-form-button">
                                {t("login")}
                            </Button>

                        </Form.Item>

                        <Form.Item>
                            <a className="login-form-forgot" href="/forgot">
                                {t("login.forgot")}
                            </a>

                        </Form.Item>
                        <Form.Item>
                            <a href="/register">{t("login.register")}</a>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={{span: 5, offset: 1}} lg={{span: 7, offset: 2}}>

                </Col>
            </Row>
        </div>
    );
};

export default LoginForm;