import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, MenuItem, TextField } from "@mui/material";
import { Affix, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Switch, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/oto/dvCc/modal.css";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ModalPR = ({ isOpen, handleCancel, handleFinish }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [id, setId] = useState("");
    const [nccThongTinChungId, setNccThongTinChungId] = useState("");
    const [maDichVu, setMaDichVu] = useState("");
    const [tenDichVu, setTenDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState("");
    const [loaiTien, setLoaiTien] = useState("");
    const [diaDiem, setDiaDiem] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [maNgoaiTe, setMaNgoaiTe] = useState("");

    const [timeErr, setTimetErr] = useState(false);
    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const onFinish = (values) => {
        setTimetErr(false);
        setMaDichVuErr(false);
        setTenDichVuErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        var timeErr = [];
        form.resetFields();
        setOpen(false);
        var tranxVeXeBusRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                var time = element.rangeTime ? false : true;
                var giaBan = element.giaBan ? false : true;
                var giaNet = element.giaNet ? false : true;
                timeErr.push({
                    time: time,
                    giaBan: giaBan,
                    giaNet: giaNet,
                });
                if (!time && !giaBan && !giaNet) {
                    tranxVeXeBusRequests.push({
                        nccDichVuNhId: element.nccDichVuNhId,
                        id: element.id,
                        tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                        denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                        giaNet: element.giaNet,
                        giaBan: element.giaBan,
                        trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                        foc: element.foc,
                        ve: element.ve,
                    });
                } else error = true;
            }
        }
        setTimetErr(timeErr);
        if (error) return;
        let data = {
            id: values.id,
            nccThongTinChungId: values.nccThongTinChungId,
            maDichVu: values.maDichVu,
            tenDichVu: values.tenDichVu,
            dmTyGiaId: values.dmTyGiaId ? values.dmTyGiaId : 1,
            loaiTien: tiGia[values.dmTyGiaId] ? tiGia[values.dmTyGiaId].label : "",
            ghiChu: values.ghiChu ? values.ghiChu : "",
            tranxVeXeBusRequests: tranxVeXeBusRequests,
        };
        handleFinish(open, data);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <div className="">
                    <Form.Item hidden name="id">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name="nccThongTinChungId">
                        <Input />
                    </Form.Item>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        label={t("res.code") + " *"}
                                        value={maDichVu ? maDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setMaDichVu(e.target.value)}
                                        error={maDichVuErr}
                                        helperText={
                                            maDichVuErr ? t("validate") + " " + t("res.code").toLowerCase() : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        label={t("res.serviceProvided") + " *"}
                                        value={tenDichVu ? tenDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setTenDichVu(e.target.value)}
                                        error={tenDichVuErr}
                                        helperText={
                                            tenDichVuErr
                                                ? t("validate") + " " + t("res.serviceProvided").toLowerCase()
                                                : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmTyGiaId}
                                        fullWidth
                                        select
                                        onChange={(e) => setDmTyGiaId(e.target.value)}
                                        label={t("exchangeRate.currency")}
                                    >
                                        {tiGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {/* {tableChildren(dataSourceTable)} */}

                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            label={t("hotel.priceRoom.applicationTime") + " *"}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "trangThaiBooking"]}
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaNet"]}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 2,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaNet") + " *"}
                                                                        fullWidth
                                                                        error={
                                                                            timeErr && timeErr[key].giaNet
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaBan"]}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 2,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaBan") + " *"}
                                                                        error={
                                                                            timeErr && timeErr[key].giaBan
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        fullWidth
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "foc"]}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.roomClass.conditionFoc"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("hotel.roomClass.conditionFoc")}
                                                                        defaultValue={0}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={15} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "ve"]}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t("ticket").toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("ticket")}
                                                                        defaultValue={0}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                        {t("hotel.roomClass.foc")}
                                                    </Col>
                                                </Row>
                                                {fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(name)}
                                                    />
                                                ) : null}
                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextField
                                label={t("notes")}
                                sx={{
                                    marginTop: 2,
                                }}
                                value={ghiChu ? ghiChu : ""}
                                fullWidth
                                multiline
                                rows={5}
                                onChange={(e) => e.target.value}
                            ></TextField>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutPoc = {
        labelCol: {
            xs: {
                span: 19,
            },
            sm: {
                span: 16,
            },
        },
        wrapperCol: {
            xs: {
                span: 5,
            },
            sm: {
                span: 5,
            },
        },
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setTimetErr(false);
        setMaDichVuErr(false);
        setTenDichVuErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        var timeErr = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                var time = element.rangeTime ? false : true;
                var giaBan = element.giaBan ? false : true;
                var giaNet = element.giaNet ? false : true;
                timeErr.push({
                    time: time,
                    giaBan: giaBan,
                    giaNet: giaNet,
                });
            }
        }
        setTimetErr(timeErr);
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") + " " + t("service").toLowerCase()
                        : t("add") + " " + t("service").toLowerCase()
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                bodyStyle={{ background: "#F5F5F5" }}
            >
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    onFinishFailed={onFinishFailed}
                    noValidate
                    layout="vertical"
                    initialValues={{
                        items: [{}],
                    }}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 12,
                        }}
                    >
                        <Affix offsetBottom={50}>
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    height: 60,
                                    paddingInline: 50,
                                    lineHeight: "60px",
                                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                                    borderRadius: "4px",
                                }}
                            >
                                <Space>
                                    <Button variant="contained" onClick={onFinish} type="submit" startIcon={<Save />}>
                                        {t("action.ok")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAlt />}
                                        onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Delete />}
                                        onClick={handleCancels}
                                        key="clear"
                                    >
                                        {t("action.cancel")}
                                    </Button>
                                </Space>
                            </div>
                        </Affix>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPR;
