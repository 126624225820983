import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTSaleRateDichVuLe = (data) => {
  return axios.post(API_BASE_URL + "op/layTTSaleRateDichVuLe", data, {
    headers: authHeader(),
  });
};
const layDetailsOpKhachSan = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpKhachSan", data, {
    headers: authHeader(),
  });
};

const saveOpKhachSan = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpKhachSan", data, {
    headers: authHeader(),
  });
};

const layDetailsOpNhaHang = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpNhaHang", data, {
    headers: authHeader(),
  });
};

const saveOpNhaHang = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpNhaHang", data, {
    headers: authHeader(),
  });
};

const layDetailsOpOto = (data) => {
  return axios.post(API_BASE_URL + "op/LayDsOpOto", data, {
    headers: authHeader(),
  });
};

const saveOpOto = (data) => {
  return axios.post(API_BASE_URL + "op/saveOpOto", data, {
    headers: authHeader(),
  });
};
export default {
  layTTSaleRateDichVuLe,
  layDetailsOpKhachSan,
  saveOpKhachSan,
  layDetailsOpNhaHang,
  saveOpNhaHang,
  layDetailsOpOto,
  saveOpOto,
};
