import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Col, Popconfirm, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import dich_vu_tourService from "../../../../api/booking/dichVuTour/dich_vu_tour.service";
import ModalTourSanPham from "./ModalTourSanPham";
import ThongTinTour from "./ThongTinTour";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/BorderColor";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import SaleRate from "./SaleRate";

const DichVuTour = ({ bookingId, action }) => {
  const [api, contextHolder] = notification.useNotification();
  const [isOpenTour, setIsOpenTour] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [tongTien, settongTien] = useState(0);
  const [reload, setReload] = useState(true);
  const [bkDichVuTourId, setBkDichVuTourId] = useState(0);
  const [view, setView] = useState("");
  useEffect(() => {
    if (reload)
      dich_vu_tourService
        .layTTDichVuTourByBkThongTinChungId({ bkThongTinChungId: bookingId })
        .then(function (response) {
          if (response.status === 200 && response.data.code === "00") {
            setReload(false);
            const data = response.data.data;
            const dataArr = [];
            let stt = 1;
            let tong = 0;
            for (const item of data) {
              let i = 0;
              for (const detail of item.detailDichVuTours) {
                dataArr.push({
                  ...detail,
                  bkDichVuTourId: item.bkDichVuTourId,
                  stt: stt,
                  id: Math.random() + "",
                  rowSpan: i === 0 ? item.detailDichVuTours.length : 0,
                });
                i++;
                tong += detail.tongTien;
              }
              stt++;
            }
            settongTien(tong);
            dataArr.push({ id: "NULL" });
            setDataTable(dataArr);
          }
        })
        .catch(function (error) {
          console.log("Error response: ====" + error);
          api["error"]({
            message: t("stour"),
            description: t("add") + " " + t("noti.error").toLowerCase(),
            duration: 0,
          });
        });
  }, [reload, bookingId]);
  const columns = [
    {
      title: "STT",
    },
    {
      title: t("typeOfTourism.description"),
    },
    {
      title: t("unit"),
    },
    {
      title: t("tour.vanChuyen.soLuong"),
    },
    {
      title: t("hotel.roomClass.foc"),
    },
    {
      title: t("chietKhau"),
    },
    {
      title: t("tour.donGia"),
    },
    {
      title: t("hotel.roomClass.intoMoney"),
    },
    {
      title: "",
    },
  ];

  const handleOpen = (visible) => {
    setIsOpenTour(visible);
  };
  const handleCancelModal = () => {
    handleOpen(false);
  };
  const handleFinishModal = (value) => {
    handleOpen(false);
    setBkDichVuTourId(value);
    // let data = {
    //     bkDichVuTourId: 46,
    //     chiPhiTours: [
    //         {
    //             bkChiPhiTourId: 135,
    //             chietKhau: 10,
    //         },
    //         {
    //             bkChiPhiTourId: 136,
    //             chietKhau: 20,
    //         },
    //         {
    //             bkChiPhiTourId: 137,
    //             chietKhau: 30,
    //         },
    //         {
    //             bkChiPhiTourId: 138,
    //             chietKhau: 0,
    //         },
    //         {
    //             bkChiPhiTourId: 139,
    //             chietKhau: 40,
    //         },
    //     ],
    // };
    // dich_vu_tourService.updateDichVuTour(data).then((response) => {
    //     if (response.status === 200 && response.data.code === "00") {
    //         api["success"]({
    //             message: t("stour"),
    //             description: t("update") + " " + t("noti.success").toLowerCase(),
    //             duration: 0,
    //         });
    //     } else {
    //         api["error"]({
    //             message: t("stour"),
    //             description: response.data.message,
    //             duration: 0,
    //         });
    //     }
    // });
  };
  const handleDelete = (id) => {
    dich_vu_tourService
      .deleteDichVuTour({ bkDichVuTourId: id })
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          api["success"]({
            message: t("stour"),
            description: t("delete") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: response.data.message,
            duration: 0,
          });
        }
      });
  };
  const handlePrice = (id) => {
    setView("SALE_RATE");
    setBkDichVuTourId(id);
    navigate(
      "/dat-dich-vu/dich-vu-tour/sale-rate/" + action + "?id=" + bookingId
    );
  };
  const handleEdit = (id) => {
    setView("THONG_TIN_TOUR");
    setBkDichVuTourId(id);
    navigate(
      "/dat-dich-vu/dich-vu-tour/thong-tin-tour/" + action + "?id=" + bookingId
    );
  };
  const handleChangeChieuKhau = (item, value, index) => {
    // let data = {
    //     bkDichVuTourId: item.bkDichVuTourId,
    //     chiPhiTours: [
    //         {
    //             bkChiPhiTourId: item.bkChiPhiTourId,
    //             chietKhau: value,
    //             donVi: "avc",
    //         },
    //     ],
    // };
    // let dataTb = dataTable.map((dt) => {
    //     if (dt.id === item.id) return { ...dt, chietKhau: value };
    //     else return dt;
    // });
    // setDataTable(dataTb);
    // console.log(JSON.stringify(data));
    // dich_vu_tourService.updateDichVuTour(data).then((response) => {
    //     if (response.status === 200 && response.data.code === "00") {
    //         // setReload(true);
    //         // api["success"]({
    //         //     message: t("stour"),
    //         //     description: t("update") + " " + t("noti.success").toLowerCase(),
    //         //     duration: 0,
    //         // });
    //     } else {
    //         api["error"]({
    //             message: t("stour"),
    //             description: response.data.message,
    //             duration: 0,
    //         });
    //     }
    // });
  };
  const renderTable = (item, index) => {
    return (
      <>
        <tr key={index}>
          {item.id.indexOf("NULL") !== -1 ? (
            <>
              <td colSpan="100%">
                <Link onClick={() => handleOpen(true)}>
                  <PlusOutlined />{" "}
                  {t("add") + " " + t("service").toLowerCase() + " Tour"}
                </Link>
              </td>
            </>
          ) : (
            <>
              {item.rowSpan !== 0 ? (
                <td rowSpan={item.rowSpan}>{item.stt}</td>
              ) : (
                <></>
              )}
              <td>{item.moTa}</td>
              <td>{item.donVi}</td>
              <td>{item.soLuong}</td>
              <td>{item.focNguoiLon}</td>
              <td>{item.chietKhau} %</td>
              <td>{item.giaBan}</td>
              <td>{item.tongTien}</td>
              {item.rowSpan !== 0 ? (
                <td rowSpan={item.rowSpan} style={{ textAlign: "center" }}>
                  <IconButton onClick={() => handlePrice(item.bkDichVuTourId)}>
                    <RequestPageIcon />
                  </IconButton>
                  <IconButton onClick={() => handleEdit(item.bkDichVuTourId)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton>
                    <Popconfirm
                      title={t("deleteTheTask")}
                      description={t("areYouConfirm")}
                      okText={t("delete")}
                      cancelText={t("huy")}
                      onConfirm={() => handleDelete(item.bkDichVuTourId)}
                    >
                      <DeleteIcon size="small" />
                    </Popconfirm>
                  </IconButton>
                </td>
              ) : (
                <></>
              )}
            </>
          )}
        </tr>
      </>
    );
  };

  return (
    <>
      {contextHolder}
      <ModalTourSanPham
        isOpen={isOpenTour}
        handleCancel={handleCancelModal}
        handleFinish={handleFinishModal}
      />
      {view === "THONG_TIN_TOUR" ? (
        <ThongTinTour
          bkDichVuTourId={bkDichVuTourId}
          setBkDichVuTourId={setBkDichVuTourId}
          setView={setView}
        />
      ) : view === "SALE_RATE" ? (
        <SaleRate />
      ) : (
        <>
          <Row>
            <table>
              <thead style={{ backgroundColor: "#f5f5f5" }}>
                {columns.map((cl) => {
                  return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                })}
              </thead>
              {dataTable.map((item, index) => {
                return renderTable(item, index);
              })}
            </table>
          </Row>
          <Row>
            <Col xs={18} sm={18} md={18} lg={18} xl={18}></Col>
            <Col xs={2} sm={5} md={2} lg={2} xl={2}>
              {t("tongTien")} :
            </Col>
            <Col xs={2} sm={5} md={2} lg={2} xl={2}>
              {tongTien}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default DichVuTour;
