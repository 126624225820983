import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTSaleRateNhaHangById = (data) => {
  return axios.post(API_BASE_URL + "booking/layTTSaleRateNhaHangById", data, {
    headers: authHeader(),
  });
};
const saveSaleRateNhaHang = (data) => {
  return axios.post(API_BASE_URL + "booking/saveSaleRateNhaHang", data, {
    headers: authHeader(),
  });
};
export default {
  layTTSaleRateNhaHangById,
  saveSaleRateNhaHang,
};
