import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarQuickFilter, enUS, viVN } from "@mui/x-data-grid";
import { Button, Col, Input, Popconfirm, Row, Space, Switch, Typography, notification } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL } from "../../../../../constants";
import FormPR from "./FormPR";
import ModalPrAction from "./ModalPrAction";

const PriceRoom = () => {
    const { t, i18n } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record?.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        let data = {
            nccThongTinChungId: 5,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            let giaPhongDetail = data[i].tranxTimeApDungRequests;
                            if (giaPhongDetail.length > 0) {
                                for (let k = 0; k < giaPhongDetail.length; k++) {
                                    dataLst.push({
                                        stt: i + 1,
                                        rowKey: 1 + Math.random() * (100 - 1),
                                        id: data[i].id,
                                        nccThongTinChungId: data[i].nccThongTinChungId,
                                        nccLoaiCabinId: data[i].nccLoaiCabinId,
                                        maDichVu: data[i].maDichVu,
                                        tenDichVu: data[i].tenDichVu,
                                        dmTyGiaId: data[i].dmTyGiaId,
                                        maNgoaiTe: data[i].maNgoaiTe,
                                        loaiTien: data[i].loaiTien,
                                        loaiCabin: data[i].loaiCabin,
                                        ghiChu: data[i].ghiChu,
                                        tranxTimeApDungRequests: data[i].tranxTimeApDungRequests.length,
                                        nccGiaCabinId: giaPhongDetail[k].nccGiaCabinId,
                                        idGp: giaPhongDetail[k].id,
                                        thoiGianApDung: giaPhongDetail[k].tuNgay + " - " + giaPhongDetail[k].denNgay,
                                        tuNgay: giaPhongDetail[k].tuNgay,
                                        denNgay: giaPhongDetail[k].denNgay,
                                        fitNgayThuongNet: giaPhongDetail[k].fitNgayThuongNet,
                                        fitNgayThuongGiaBan: giaPhongDetail[k].fitNgayThuongGiaBan,
                                        fitCuoiTuanNet: giaPhongDetail[k].fitCuoiTuanNet,
                                        fitCuoiTuanGiaBan: giaPhongDetail[k].fitCuoiTuanGiaBan,
                                        extraNgayThuongNet: giaPhongDetail[k].extraNgayThuongNet,
                                        extraNgayThuongGiaBan: giaPhongDetail[k].extraNgayThuongGiaBan,
                                        extraCuoiTuanNet: giaPhongDetail[k].extraCuoiTuanNet,
                                        extraCuoiTuanGiaBan: giaPhongDetail[k].extraCuoiTuanGiaBan,
                                        trangThaiBooking: giaPhongDetail[k].trangThaiBooking,
                                        hieuLuc: giaPhongDetail[k].hieuLuc,
                                    });
                                }
                            } else {
                                dataLst.push({
                                    stt: i,
                                    rowKey: i++,
                                    id: data[i].id,
                                    nccThongTinChungId: data[i].nccThongTinChungId,
                                    nccLoaiCabinId: data[i].nccLoaiCabinId,
                                    maDichVu: data[i].maDichVu,
                                    tenDichVu: data[i].tenDichVu,
                                    dmTyGiaId: data[i].dmTyGiaId,
                                    maNgoaiTe: data[i].maNgoaiTe,
                                    loaiTien: data[i].loaiTien,
                                    loaiCabin: data[i].loaiCabin,
                                    ghiChu: data[i].ghiChu,
                                });
                            }
                            dataLstSearch.push({
                                value: data[i].id - data[i].maDichVu - data[i].tenDichVu,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
    };
    const handleBorderChange = (record) => {
        let values = {
            id: record.idGp,
            trangThaiBooking: record.trangThaiBooking === true ? false : true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setAction(false);
        setIsShow(true);
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc == true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
        console.log(JSON.stringify(values));
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let updatedList = dataSource.map((item) => {
                        values.tranxTimeApDungRequests.forEach((element) => {
                            if (element.id === item.idGp) {
                                return (item = {
                                    ...item,
                                    nccLoaiCabinId: values.nccLoaiCabinId,
                                    maDichVu: values.maDichVu,
                                    tenDichVu: values.tenDichVu,
                                    loaiTien: values.loaiTien,
                                    loaiCabin: values.loaiCabin,
                                    ghiChu: values.ghiChu,
                                    tranxTimeApDungRequests: values.tranxTimeApDungRequests.length,
                                    nccGiaCabinId: element.nccGiaCabinId,
                                    idGp: element.id,
                                    thoiGianApDung: element.tuNgay + " - " + element.denNgay,
                                    tuNgay: element.tuNgay,
                                    denNgay: element.denNgay,
                                    fitNgayThuongNet: element.fitNgayThuongNet,
                                    fitNgayThuongGiaBan: element.fitNgayThuongGiaBan,
                                    fitCuoiTuanNet: element.fitCuoiTuanNet,
                                    fitCuoiTuanGiaBan: element.fitCuoiTuanGiaBan,
                                    extraNgayThuongNet: element.extraNgayThuongNet,
                                    extraNgayThuongGiaBan: element.extraNgayThuongGiaBan,
                                    extraCuoiTuanNet: element.extraCuoiTuanNet,
                                    extraCuoiTuanGiaBan: element.extraCuoiTuanGiaBan,
                                    trangThaiBooking: element.trangThaiBooking,
                                });
                            }
                            return item;
                        });
                        return item;
                    });
                    setDataSource(updatedList);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            width: 60,
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("roomType"),
            field: "loaiCabin",
            key: "loaiCabin",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "extraNgayThuongNet",
            key: "extraNgayThuongNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "extraNgayThuongGiaBan",
            key: "extraNgayThuongGiaBan",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "extraCuoiTuanNet",
            key: "extraCuoiTuanNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "extraCuoiTuanGiaBan",
            key: "extraCuoiTuanGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            align: "center",
            renderCell: ({ value, row }) => {
                return <Switch defaultChecked={value} onChange={() => handleBorderChange(row)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)} />
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            align: "center",
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),
            children: [
                {
                    groupId: "Fit",
                    children: [{ field: "fitNgayThuongNet" }, { field: "fitNgayThuongGiaBan" }],
                },
                {
                    groupId: "Extra",
                    children: [{ field: "extraNgayThuongNet" }, { field: "extraNgayThuongGiaBan" }],
                },
            ],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),
            children: [
                {
                    groupId: "Fit",
                    children: [{ field: "fitCuoiTuanNet" }, { field: "fitCuoiTuanGiaBan" }],
                },
                {
                    groupId: "Extra",
                    children: [{ field: "extraCuoiTuanNet" }, { field: "extraCuoiTuanGiaBan" }],
                },
            ],
        },
    ];
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    const QuickSearchToolbar = () => {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                        <FormPR dataAdd={dataSource} />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <DataGrid
                onRowClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                autoHeight
                {...dataSource}
                pageSizeOptions={[10]}
                columnGroupingModel={columnGroupingModel}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default PriceRoom;
