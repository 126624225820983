import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Button, theme } from "antd";
import MenuSide from "./Sidebar";
import Contents from "./Content";
import TopBar from "./TopBar";
import { useTranslation } from "react-i18next";

const { Header, Sider, Content, Footer } = Layout;
const DashBoard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [keySide, setKeySide] = useState(1);
  const [keyPath, setKeyPath] = useState("");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { t } = useTranslation();
  const SendKeySide = (key, keyPath) => {
    setKeySide(key);
    setKeyPath(keyPath);
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
        padding: 0,
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="xl"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
      >
        <MenuSide SendKeySide={SendKeySide} />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </div>
            <TopBar />
          </div>
        </Header>
        <Content
          className="site-layout"
          style={{
            padding: "0 20px",
            backgroundColor: "#e2e2e2",
          }}
        >
          <Contents keySide={keySide} keyPath={keyPath} />
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          {t("footer")}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default DashBoard;
