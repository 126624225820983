import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import FormThemMoi from "./FormThemMoi";
import QuanLyNhomKhach from "./QuanLyNhomKhach";

export default function ThemMoiBaoGia() {
    const { t } = useTranslation();

    return (
        <div>
            <Divider />
            <FormThemMoi />
            <QuanLyNhomKhach />
        </div>
    );
}
