import { Box, Link, TextField } from "@mui/material";
import {
  Row,
  Collapse,
  Radio,
  Table,
  theme,
  Col,
  Button,
  Popconfirm,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import ModalCapNhatNhaHang from "./modal/ModalCapNhatNhaHang";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import ModalCapNhatKS from "./modal/ModalCapNhatKS";
import ModalCapNhatOto from "./modal/ModalCapNhatOto";
import ChietTinhOp from "../../../api/bao_gia/chiet_tinh_op/chiet_tinh_op";
import { useLocation } from "react-router";
import baoGiaService from "../../../api/bao_gia/baoGiaService";
import axios from "axios";
import authHeader from "../../../api/auth_header";
import { API_BASE_URL } from "../../../constants";
import ModalCauHinhTyLe from "./modal/ModalCauHinhTyLe";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { ReactComponent as BFIcon } from "../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../assets/img/chiettinh/L.svg";
import chiet_tinh_op from "../../../api/bao_gia/chiet_tinh_op/chiet_tinh_op";

const ChietTinhOP = ({ dvId, baoGiaTongQuanId, ngayKhoiHanh }) => {
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [openNH, setOpenNh] = useState(false);
  const [openKs, setOpenKs] = useState(false);
  const [openOto, setOpenOto] = useState(false);
  const [valueRadio, setValueRadio] = useState(1);
  const [dataCT, setDataCT] = useState([]);
  const [tableDataCT, setTableDataCT] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(0);
  const [soNgay, setSoNgay] = useState(0);
  const [dataDichVu, setDataDichVu] = useState([]);
  const [openTyLe, setOpenTyLe] = useState(false);
  const [baoGiaHanhTrinhId, setBaoGiaHanhTrinhId] = useState(0);
  const [baoGiaChietTinhOPId, setBaoGiaChietTinhOPId] = useState(0);
  const [bgMapChietTinhOpHtId, setBgMapChietTinhOpHtId] = useState(0);
  const [ngay, setNgay] = useState(0);
  const [dmDichVuId, setDmDichVuId] = useState(0);
  const [dataNhomNgLon, setDataNhomNgLon] = useState([]);
  const [dataTreEm, setDataTreEm] = useState([]);
  const [soLuongTreEmBG, setSoLuongTreEmBG] = useState(0);
  const [hanhDong, setHanhDong] = useState("ADD");
  const [tuNgay, setTuNgay] = useState("");
  const [denNgay, setDenNgay] = useState("");
  const [reset, setReset] = useState(false);
  useEffect(() => {
    axios({
      method: "post",
      url: API_BASE_URL + "category/getDmDichVu",
      headers: authHeader(),
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response: ====" + response.data.data);
          let dataDV = [];
          let data = response.data.data;
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].hieu_luc === true) {
                dataDV.push({
                  id: data[i].id,
                  value: data[i].ten_dich_vu,
                });
              }
            }
            setDataDichVu(dataDV);
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  }, []);

  useEffect(() => {
    let id = getDvId();
    if (id !== 0) {
      setId(id);
      setIsEdit(true);
      getBaoGiaTongQuanById(id);
    }
  }, []);

  useEffect(() => {
    if (baoGiaTongQuanId && ngayKhoiHanh) apiGetChietTinhOp();
  }, [baoGiaTongQuanId, ngayKhoiHanh, reset]);

  const style = {
    h4: { fontSize: "16px", fontWeight: "bold" },
  };

  const handleReset = () => {
    setReset(!reset);
  };

  const handleDv = (value) => {
    if (value === "Bữa ăn sáng") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <BFIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Bữa ăn trưa") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <LIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Bữa tối") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <DIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Gala dinner") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <GDIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
  };

  const formatDate = (date) => {
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toLocaleDateString("en-GB");
  };

  const handleSetTuNgayAndDenNgay = (dateArr, days) => {
    let date = dateArr[1] + "/" + dateArr[0] + "/" + dateArr[2];
    let denNgay = addDays(date, days);
    setTuNgay(ngayKhoiHanh);
    setDenNgay(denNgay);
  };

  const getDvId = (value) => {
    let Id = 0;
    if (value === undefined) {
      if (location.search.length > 0) {
        let id = location.search.split("=");
        Id = id[1];
      }
    } else {
      Id = value;
    }
    return Id;
  };
  const getBaoGiaTongQuanById = (id) => {
    baoGiaService
      .getBaoGiaTongQuanById({ id: id })
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          handleSetDataTongQuan(data);
        }
      })
      .catch((rs) => console.log("Error getBaoGiaTongQuanById: " + rs));
  };
  const handleSetDataTongQuan = (data) => {
    console.log("data bao gia:  ", data);
    setDataTreEm(data.nhomTreEms);
    setDataNhomNgLon(data.nhomNguoiLons);
    setSoNgay(data.soNgay);
    setSoLuongTreEmBG(data.soLuongTreEm);
  };

  const apiGetChietTinhOp = () => {
    let dataApi = {
      baoGiaTongQuanId: baoGiaTongQuanId,
    };
    let dateArr = ngayKhoiHanh?.split("/");
    ChietTinhOp.getChietTinhOp(dataApi)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDataCT(data);
          if (data.length > 0) {
            let newArr = [...data];
            if (dateArr) {
              handleSetTuNgayAndDenNgay(dateArr, data.length);
            }
            for (let i = 0; i < data.length; i++) {
              let dataTable = [];
              for (let j = 0; j < data[i].dichVusResponses.length; j++) {
                if (
                  !data[i].dichVusResponses[j].mapChietTinhOPHT ||
                  data[i].dichVusResponses[j].mapChietTinhOPHT.length === 0
                ) {
                  dataTable.push({
                    id: "NEW_ADD" + Math.random(),
                    stt: j + 1,
                    tenDichVu: data[i].dichVusResponses[j].tenDichVu,
                    bgChietTinhOPId:
                      data[i].dichVusResponses[j].bgChietTinhOPId,
                    dmDichVuId: data[i].dichVusResponses[j].dmDichVuId,
                    rowSpan: 1,
                  });
                }
                for (
                  let k = 0;
                  k < data[i].dichVusResponses[j].mapChietTinhOPHT.length;
                  k++
                ) {
                  for (
                    let index = 0;
                    index <
                    data[i].dichVusResponses[j].mapChietTinhOPHT[k].details
                      .length;
                    index++
                  ) {
                    let rowSpan = 0;
                    if (index === 0) {
                      rowSpan =
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k].details
                          .length + 1;
                    }
                    const element =
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k].details[
                        index
                      ];

                    dataTable.push({
                      id: Math.random() + "",
                      ...element,
                      stt: j + 1,
                      tenDichVu: data[i].dichVusResponses[j].tenDichVu,
                      bgChietTinhOPId:
                        data[i].dichVusResponses[j].bgChietTinhOPId,
                      dmDichVuId: data[i].dichVusResponses[j].dmDichVuId,
                      bgMapChietTinhOpHtId:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .bgMapChietTinhOpHtId,
                      nccThongTinChungId:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .nccThongTinChungId,
                      parentBgMapChietTinhOpHtId:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .parentBgMapChietTinhOpHtId,
                      tenNhaCungCap:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .tenNhaCungCap,
                      dmGiaApDungId:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .dmGiaApDungId,
                      dmLoaiApDungId:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .dmLoaiApDungId,
                      dmVatId:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k].dmVatId,
                      moTa: data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .moTa,
                      dieuKienFoc:
                        data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                          .dieuKienFoc,
                      foc: data[i].dichVusResponses[j].mapChietTinhOPHT[k].foc,
                      rowSpan: rowSpan,
                    });
                  }
                  dataTable.push({
                    id: "NEW_ADD" + Math.random(),
                    stt: j + 1,
                    tenDichVu: data[i].dichVusResponses[j].tenDichVu,
                    bgChietTinhOPId:
                      data[i].dichVusResponses[j].bgChietTinhOPId,
                    dmDichVuId: data[i].dichVusResponses[j].dmDichVuId,
                    bgMapChietTinhOpHtId:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .bgMapChietTinhOpHtId,
                    nccThongTinChungId:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .nccThongTinChungId,
                    parentBgMapChietTinhOpHtId:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .parentBgMapChietTinhOpHtId,
                    tenNhaCungCap:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .tenNhaCungCap,
                    dmGiaApDungId:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .dmGiaApDungId,
                    dmLoaiApDungId:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .dmLoaiApDungId,
                    dmVatId:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k].dmVatId,
                    moTa: data[i].dichVusResponses[j].mapChietTinhOPHT[k].moTa,
                    dieuKienFoc:
                      data[i].dichVusResponses[j].mapChietTinhOPHT[k]
                        .dieuKienFoc,
                    foc: data[i].dichVusResponses[j].mapChietTinhOPHT[k].foc,
                    rowSpan: 0,
                  });
                }
              }
              newArr[i].dataTable = dataTable;
            }
            console.log("data1234311 ", newArr);
            setTableDataCT(newArr);
          } else {
            baoGiaService
              .getHanhTrinhByTongQuanId({ baoGiaTongQuanId: baoGiaTongQuanId })
              .then(function (response) {
                if (response.status === 200) {
                  let data = response.data.data;
                  console.log("getChuongTrinhTour1234");
                  let newArr = [];
                  let dateArr = ngayKhoiHanh?.split("/");
                  if (dateArr) {
                    handleSetTuNgayAndDenNgay(dateArr, data.length);
                  }
                  data = data.sort((a, b) => (a.ngay > b.ngay ? 1 : -1));
                  for (let i = 0; i < data.length; i++) {
                    let date = new Date(
                      dateArr[2],
                      dateArr[1],
                      parseInt(dateArr[0]) + i
                    );
                    let thu = date.getDay() % 6;
                    let thuVN = "";
                    switch (thu) {
                      case 0:
                        thuVN = "Thứ Hai";
                        break;
                      case 1:
                        thuVN = "Thứ Ba";
                        break;
                      case 2:
                        thuVN = "Thứ Tư";
                        break;
                      case 3:
                        thuVN = "Thứ Năm";
                        break;
                      case 4:
                        thuVN = "Thứ Sáu";
                        break;
                      case 5:
                        thuVN = "Thứ Bảy";
                        break;
                      case 6:
                        thuVN = "Chủ Nhật";
                        break;
                      default:
                        break;
                    }
                    let dataTable = [];
                    for (
                      let index = 0;
                      index < data[i].dmDichVuIds.length;
                      index++
                    ) {
                      const element = data[i].dmDichVuIds[index];
                      let tenDichVu = dataDichVu.find(
                        (d) => d.id === element
                      )?.value;
                      if (tenDichVu) {
                        dataTable.push({
                          dmDichVuId: element,
                          id: "NEW_ADD" + Math.random(),
                          stt: i + 1,
                          tenDichVu: dataDichVu.find((d) => d.id === element)
                            ?.value,
                        });
                      }
                    }
                    newArr.push({
                      id: Math.random() + "",
                      ngay:
                        "Ngày " +
                        data[i].ngay +
                        " (" +
                        thuVN +
                        ", " +
                        date.toLocaleDateString("en-GB") +
                        ")",
                      dataTable: dataTable,
                    });
                  }
                }
              });
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };
  const columnCT = [
    {
      headerName: t("stt"),
    },
    {
      headerName: t("typeService"),
    },
    {
      headerName: t("supplier"),
    },
    {
      headerName: t("service"),
    },
    {
      headerName: t("tour.donGia"),
    },
    {
      headerName: t("tour.vanChuyen.soLuong"),
    },
    {
      headerName: t("hotel.roomClass.intoMoney"),
    },
    {
      headerName: t("tour.chiPhiTreEm"),
    },
    {
      headerName: t("thuevat"),
    },
  ];
  const handleAddServices = (item, baoGiaHanhTrinhId, ngay) => {
    setHanhDong("ADD");
    setNgay(ngay);
    setBaoGiaHanhTrinhId(baoGiaHanhTrinhId);
    setBaoGiaChietTinhOPId(item.bgChietTinhOPId);
    setDmDichVuId(item.dmDichVuId);
    setBgMapChietTinhOpHtId(0);
    // setBgMapChietTinhOpHtId(9);
    switch (item.tenDichVu) {
      case "Khách sạn":
        return setOpenKs(true);
      case "Xe ô tô":
        setOpenOto(true);
        break;
      case "Nhà hàng":
        setOpenNh(true);
        break;
      default:
        return null;
    }
  };

  const handleEditServices = (item, baoGiaHanhTrinhId, ngay) => {
    setHanhDong("EDIT");
    setNgay(ngay);
    setBaoGiaHanhTrinhId(baoGiaHanhTrinhId);
    setBaoGiaChietTinhOPId(item.bgChietTinhOPId);
    setDmDichVuId(item.dmDichVuId);
    setBgMapChietTinhOpHtId(item.bgMapChietTinhOpHtId);
    // setBgMapChietTinhOpHtId(9);
    switch (item.tenDichVu) {
      case "Khách sạn":
        return setOpenKs(true);
      case "Xe ô tô":
        setOpenOto(true);
        break;
      case "Nhà hàng":
        setOpenNh(true);
        break;
      default:
        return null;
    }
  };

  const handleDeleteService = async (item) => {
    let response = null;
    let dataReq = {};
    try {
      switch (item.tenDichVu) {
        case "Khách sạn":
          dataReq = { tranxBgChietTinhKsId: item.tranxBgChietTinhKsId };
          response = await chiet_tinh_op.deleteChietTinhDichVuKS(dataReq);
          break;
        case "Xe ô tô":
          dataReq = { tranxBgChietTinhOtoId: item.tranxBgChietTinhOtoId };
          response = await chiet_tinh_op.deleteChietTinhDichVuOto(dataReq);
          break;
        case "Nhà hàng":
          dataReq = { tranxBgChietTinhNhId: item.tranxBgChietTinhNhId };
          response = await chiet_tinh_op.deleteChietTinhDichVuNhaHang(dataReq);
          break;
        default:
          return null;
      }
      if (response && response.status === 200 && response.data.code === "00") {
        setReset(!reset);
        showNoti(
          "success",
          t("delete") + " " + t("noti.success").toLowerCase()
        );
      } else {
        showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
      }
    } catch {
      showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const tableCT = (dataT, baoGiaHanhTrinhId, ngay) => {
    // console.log(dataT);
    return (
      <>
        <table>
          <thead>
            {columnCT.map((cl) => {
              return <th>{cl.headerName}</th>;
            })}
          </thead>
          <tbody>
            {dataT.map((it, key) => {
              return (
                <tr key={key}>
                  {it.rowSpan !== 0 ? (
                    <>
                      <td rowSpan={it.rowSpan}>{it.stt}</td>
                      <td rowSpan={it.rowSpan}>{it.tenDichVu}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {it.id.indexOf("NEW_ADD") !== -1 ? (
                    <>
                      <td colSpan={7}>
                        <Link
                          onClick={() =>
                            handleAddServices(it, baoGiaHanhTrinhId, ngay)
                          }
                        >
                          {t("add") + " " + t("service").toLowerCase()}
                        </Link>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{it.tenNhaCungCap}</td>
                      <td width={"25%"}>
                        <Row>
                          <Col span={!it.parentBgMapChietTinhOpHtId ? 20 : 24}>
                            {/* {handleDv(it.dichVu)} */}
                            {it.tenDichVu === "Nhà hàng"
                              ? handleDv(it.dichVu)
                              : it.dichVu}
                          </Col>
                          {!it.parentBgMapChietTinhOpHtId ? (
                            <Col span={4}>
                              <Edit
                                fontSize="small"
                                onClick={() =>
                                  handleEditServices(
                                    it,
                                    baoGiaHanhTrinhId,
                                    ngay
                                  )
                                }
                              />
                              <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDeleteService(it)}
                              >
                                <Delete fontSize="small" />
                              </Popconfirm>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </td>
                      <td>{it.donGia}</td>
                      <td>{it.soLuong}</td>
                      <td>{it.thanhtien}</td>
                      <td>{it.chiPhiTreEm}</td>
                      <td>{it.vat}</td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
  };
  const children = (
    <>
      {dataCT !== null
        ? dataCT.map((item) => (
            <Collapse
              defaultActiveKey={item.baoGiaHanhTrinhId}
              style={{
                background: token.colorBgContainer,
              }}
              items={[
                {
                  key: item.baoGiaHanhTrinhId,
                  label: <div style={style.h4}>{item.ngay}</div>,
                  children: tableCT(
                    item.dataTable,
                    item.baoGiaHanhTrinhId,
                    item.ngayId
                  ),
                  style: panelStyle,
                },
              ]}
            />
          ))
        : ""}
    </>
  );
  const columnsTT = [
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
    },
    {
      title: "Total Sales",
      dataIndex: "totalSales",
    },
    {
      title: "T.Pax Cost",
      dataIndex: "paxCost",
    },
    {
      title: "T.Pax Sales",
      dataIndex: "paxSale",
    },
  ];
  const dataSourceTT = [
    { thanhTien: "Người lớn (Nhóm người lớn 1)" },
    { thanhTien: "Người lớn (Nhóm người lớn 2)" },
  ];
  const handleCancelNH = () => {
    setOpenNh(false);
  };
  const handleCancelKS = () => {
    setOpenKs(false);
  };
  const handleCancelOto = () => {
    setOpenOto(false);
  };
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValueRadio(e.target.value);
  };
  const handleTyLe = (params) => {
    setOpenTyLe(params);
  };
  return (
    <>
      {contextHolder}
      <Collapse
        defaultActiveKey={"1"}
        items={[
          {
            key: "1",
            label: <div style={style.h4}>{t("listService")}</div>,
            children: children,
          },
        ]}
      />
      <Collapse
        defaultActiveKey={"1"}
        items={[
          {
            key: "1",
            label: <div style={style.h4}>{t("totalPrice")}</div>,
            children: (
              <>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={valueRadio}
                      onChange={onChangeRadio}
                    >
                      <FormControlLabel
                        sx={{ marginLeft: "20px" }}
                        value={1}
                        control={<Radio />}
                        label={t("vat0%")}
                      />

                      <FormControlLabel
                        sx={{ marginLeft: "20px" }}
                        value={2}
                        control={<Radio />}
                        label={t("thuevat")}
                      ></FormControlLabel>
                    </RadioGroup>
                    <TextField
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Col>

                  <Col xs={10} sm={10} md={10} lg={10} xl={10}></Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Button onClick={() => handleTyLe(true)}>Markup All</Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Table columns={columnsTT} dataSource={dataSourceTT} />
                </Row>
              </>
            ),
          },
        ]}
      />
      <ModalCapNhatNhaHang
        isOpen={openNH}
        onReset={handleReset}
        handleCancel={handleCancelNH}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataTreEm}
        baoGiaHanhTrinhId={baoGiaHanhTrinhId}
        baoGiaTongQuanId={baoGiaTongQuanId}
        bgChietTinhOPId={baoGiaChietTinhOPId}
        dmDichVuId={dmDichVuId}
        bgMapChietTinhOpHtId={bgMapChietTinhOpHtId}
        soLuongTreEmBG={soLuongTreEmBG}
        hanhDong={hanhDong}
        soNgay={soNgay}
        ngay={ngay}
        tuNgay={tuNgay}
        denNgay={denNgay}
      />
      <ModalCapNhatKS
        isOpen={openKs}
        onReset={handleReset}
        handleCancel={handleCancelKS}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataTreEm}
        baoGiaHanhTrinhId={baoGiaHanhTrinhId}
        baoGiaTongQuanId={baoGiaTongQuanId}
        bgChietTinhOPId={baoGiaChietTinhOPId}
        dmDichVuId={dmDichVuId}
        bgMapChietTinhOpHtId={bgMapChietTinhOpHtId}
        soLuongTreEmBG={soLuongTreEmBG}
        hanhDong={hanhDong}
        soNgay={soNgay}
        ngay={ngay}
        tuNgay={tuNgay}
        denNgay={denNgay}
      />
      <ModalCapNhatOto
        isOpen={openOto}
        onReset={handleReset}
        handleCancel={handleCancelOto}
        dataNhomNgLon={dataNhomNgLon}
        dataNhomTreEm={dataTreEm}
        baoGiaHanhTrinhId={baoGiaHanhTrinhId}
        baoGiaTongQuanId={baoGiaTongQuanId}
        bgChietTinhOPId={baoGiaChietTinhOPId}
        dmDichVuId={dmDichVuId}
        bgMapChietTinhOpHtId={bgMapChietTinhOpHtId}
        soLuongTreEmBG={soLuongTreEmBG}
        hanhDong={hanhDong}
        soNgay={soNgay}
        ngay={ngay}
        tuNgay={tuNgay}
        denNgay={denNgay}
      />
      <ModalCauHinhTyLe
        baoGiaTongQuanId={baoGiaTongQuanId}
        open={openTyLe}
        handleTyLe={handleTyLe}
      />
    </>
  );
};
export default ChietTinhOP;
