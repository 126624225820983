import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Col, Modal, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";

const ModalRenameTab = ({
  isOpen,
  onSave,
  onCancel,
  idTabEdit,
  tabNameEdit,
  items,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [tabName, setTabName] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  useEffect(() => {
    if (isOpen) setTabName(tabNameEdit);
  }, [isOpen]);
  const onFinish = (e) => {
    e.preventDefault();
    console.log(items);
    let item = items.find(
      (item) =>
        item.key !== idTabEdit &&
        item.label.trim().toLowerCase() === tabName.trim().toLowerCase()
    );
    console.log(item);
    if (item) {
      api["error"]({
        message: t("stour"),
        description: t("noti.sameTourClassName").toLowerCase(),
        duration: 0,
      });
    } else {
      onSave(tabName);
    }
  };
  const handleCancels = (e) => {
    e.preventDefault();
    onCancel();
  };
  // const handleClear = (e) => {
  //     setTabName("")
  // };
  const changeTenTour = (value) => {
    if (value.length > 0) {
      setIsDisable(false);
    }
    setTabName(value);
  };
  return (
    <Modal
      title={t("thayDoiTenTab")}
      open={isOpen}
      onOk={onFinish}
      onCancel={handleCancels}
      footer={null}
    >
      {contextHolder}
      <Box component="form" noValidate>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={22}>
            <TextField
              label={t("tenHangTour")}
              fullWidth
              name="tabName"
              onChange={(e) => changeTenTour(e.target.value)}
              value={tabName}
            ></TextField>
          </Col>
        </Row>
        <Row style={{ marginTop: 6 }}>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={isDisable}
              onClick={(e) => onFinish(e)}
            >
              {t("action.ok")}
            </Button>
            {/*<Button*/}
            {/*    style={{*/}
            {/*        backgroundColor: "#898989",*/}
            {/*        color: "white",*/}
            {/*    }}*/}
            {/*    key="reset"*/}
            {/*    startIcon={<RestartAltIcon/>}*/}
            {/*    onClick={(e) => handleClear(e)}*/}
            {/*>*/}
            {/*    {t("action.reset")}*/}
            {/*</Button>*/}
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleCancels}
              key="clear"
            >
              {t("action.cancel")}
            </Button>
          </Space>
        </Row>
      </Box>
    </Modal>
  );
};
export default ModalRenameTab;
