import React, { useState, useEffect } from "react";
import { notification, Space, Table, Typography, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import FormTT from "./FormTT";

import { API_BASE_URL, data_getDM } from "../../../constants";
import axios from "axios";
import ModalTtEdit from "./ModalTtEdit";
import authHeader from "../../../api/auth_header";

import { DataGrid, GridToolbarQuickFilter, enUS, viVN } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import i18n from "../../../i18n";

const TypeTour = () => {
    const [dataSource, setDataSource] = useState([]);
    const [editRecord, setEditRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [dataSearch, setDataSearch] = useState();
    useEffect(() => {});
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmLoaiTour",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response);
                if (response.status === 200) {
                    console.log(response.data.data);
                    // setDataSource(response.data.data)
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i + 1,
                                id: data[i].id,
                                loai_tour: data[i].loai_tour,
                                mo_ta: data[i].mo_ta,
                                hieu_luc: data[i].hieu_luc,
                            });
                            dataLstSearch.push({
                                value: data[i].id + "- " + data[i].loai_tour + " " + data[i].mo_ta,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const [editingKey, setEditingKey] = useState("");
    // const handleDelete = (key) => {
    //     const newData = dataSource.filter((item) => item.key !== key);
    //     setDataSource(newData);
    // };
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        setEditRecord(record);
        setIsShow(true);
    };
    const handleFinish = (isModalOpen, values) => {
        setIsShow(isModalOpen);
        apiEditT(values, "mo_ta");
    };
    const apiEditT = (values, name_key) => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/editDmLoaiTour",
            data: values,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let updatedList = dataSource.map((item) => {
                    if (item.id === values.id) {
                        if (name_key === "mo_ta") {
                            return { ...item, mo_ta: values.mo_ta };
                        } else {
                            return { ...item, hieu_luc: values.hieu_luc };
                        }
                    }
                    return item; // else return unmodified item
                });
                setDataSource(updatedList);
                api["success"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: 0,
                });
            }
        });
    };

    const handleCancel = () => {
        setEditRecord("");
        setIsShow(false);
    };
    const handleBorderChange = (record) => {
        let values = {
            id: record.id,
            hieu_luc: record.hieu_luc === true ? false : true,
        };
        apiEditT(values, "hieu_luc");
    };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("typeTour"),
            field: "loai_tour",
            key: "loai_tour",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("typeTour.description"),
            field: "mo_ta",
            key: "mo_ta",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "hieu_luc",
            key: "hieu_luc",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value, row }) => {
                return <Switch defaultChecked={value} onChange={() => handleBorderChange(row)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "key",
            align: "center",
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space size="large">
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined
                                style={{
                                    fontSize: "20px",
                                }}
                            />
                        </Typography.Link>
                        {/*<Popconfirm headerName={t("confirmDelete")} okText="OK"*/}
                        {/*            cancelText={t("action.huy")} onConfirm={() => handleDelete(record.key)}>*/}
                        {/*    <DeleteOutlined style={{color: 'red'}}/>*/}
                        {/*</Popconfirm>*/}
                    </Space>
                );
            },
        },
    ];
    const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const apiThayDoiTrangThai = () => {
        let data = [];
        let obj = null;
        if (selectedRowKeys.length > 0) {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                obj = {
                    id: selectedRowKeys[i],
                    hieu_luc: false,
                };
                data.push(obj);
            }
            axios({
                method: "post",
                url: API_BASE_URL + "category/thayDoiTrangThaiDmLoaiTour",
                data: data,
                headers: authHeader(),
            })
                .then(function (response) {
                    let dataS = null;
                    if (response.status === 200 && response.data.code === "00") {
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            let indx = dataSource.findIndex((v) => v.id === selectedRowKeys[i]);
                            dataS = dataSource.splice(indx, indx >= 0 ? 1 : 0);
                        }
                        setDataSource(dataS);
                        api["success"]({
                            message: t("stour"),
                            description: t("delete") + " " + t("noti.success").toLowerCase(),
                            duration: 0,
                        });
                    }
                })
                .catch({});
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const dataAdd = (data, dataS) => {
        setDataSource((oldArray) => [...oldArray, data]);
        setDataSearch((oldArray) => [...oldArray, dataS]);
        api["success"]({
            message: t("stour"),
            description: t("add") + " " + t("noti.success").toLowerCase(),
            duration: 0,
        });
    };
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
    };
    const handleSearch = (value) => {
        let valueSp = value.split(" - ");
        let data = dataSource.filter((el) => el.id === parseInt(valueSp[0]));
        setDataSource(data);
    };
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormTT
                            dataAdd={dataAdd}
                            deleteLst={apiThayDoiTrangThai}
                            handleSearch={handleSearch}
                            dataSearch={dataSearch}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                <ModalTtEdit
                    isOpen={isShow}
                    handleFinish={handleFinish}
                    handleCancel={handleCancel}
                    record={editRecord}
                />
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
                </span>
            </div>
            <DataGrid
                rows={dataSource}
                columns={columns}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                autoHeight
                {...dataSource}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
        </div>
    );
};
export default TypeTour;
