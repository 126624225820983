// src/App.js
import React, { useEffect, useState } from "react";
import UserList from "./userManagement/UserList";
import UserForm from "./userManagement/UserForm";
import { useTranslation } from "react-i18next";
import { Space, notification } from "antd";
import { Button, InputAdornment, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";
import SaveIcon from "@material-ui/icons/Save";
import UserService from "../../api/user/UserService";

const User = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState({
    username: "",
    fullName: "",
    email: "",
    phone: "",
    role: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("add");
  const [reset, setReset] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    UserService.getUserList()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          let data = res.data?.data.map((it, idx) => ({
            ...it,
            stt: idx + 1,
            role: it.roles.map((it) => it.name_descriptions)?.join(", ") || "",
          }));
          setUsers(data);
        }
      })
      .catch((err) => console.log("Get UserList Error ..." + err));
  }, [reset]);

  const handleSave = (dataReq) => {
    console.log(JSON.stringify(dataReq));
    if (action === "add") handleSaveInfoUser(dataReq);
    else handleUpdateInfoUser(dataReq);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleAddUser = () => {
    setAction("add");
    setEditingUser({
      username: "",
      fullName: "",
      email: "",
      phone: "",
      role: [],
    });
    setIsOpen(true);
  };

  const handleEditUser = (user) => {
    console.log(user);
    setAction("edit");
    setEditingUser(user);
    setIsOpen(true);
  };

  const handleSaveInfoUser = (user) => {
    UserService.addUser(user)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          showNoti(
            "success",
            t(action) + " " + t("noti.success").toLowerCase()
          );
          setIsOpen(false);
          setReset(!reset);
        } else {
          showNoti("error", t(action) + " " + t("noti.error").toLowerCase());
        }
      })
      .catch(() => {
        showNoti("error", t("system.error"));
      });
  };

  const handleUpdateInfoUser = (user) => {
    UserService.editUser(user)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          showNoti(
            "success",
            t(action) + " " + t("noti.success").toLowerCase()
          );
          setIsOpen(false);
          setReset(!reset);
        } else
          showNoti("error", t(action) + " " + t("noti.error").toLowerCase());
      })
      .catch(() => {
        showNoti("error", t("system.error"));
      });
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        {t("userManagement")}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Space size={20}>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              size="small"
              placeholder={t("search")}
              // value={textSearch}
              // onChange={(e) => setTextSearch(e.target.value)}
            />

            <Button
              variant="contained"
              // onClick={handleSearchDanhSachDoan}
              startIcon={<Search />}
              color="success"
            >
              {t("search")}
            </Button>
          </Space>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => handleAddUser()}
            startIcon={<SaveIcon />}
            color="success"
          >
            {t("add")}
          </Button>
        </div>
      </div>
      <UserList users={users} onEdit={handleEditUser} />
      <UserForm
        user={editingUser}
        onSave={handleSave}
        isOpen={isOpen}
        action={action}
        title={
          action === "add"
            ? t("add") + " " + t("user").toLowerCase()
            : t("edit") + " " + t("user").toLowerCase()
        }
        onCancel={handleCancel}
      />
    </>
  );
};

export default User;
