import React, { useState } from "react";
import { Button, Col, Row, Space, Input, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalContact from "./ModalContact";
const { Search } = Input;

const FormContact = ({ dataAdd, handleSearch, editingRecord, isView }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [isOpen, setIsOpen] = useState(false);
    const showModal = () => {
        setIsOpen(true);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };
    const handleFinish = (status, values) => {
        setIsOpen(status);
        let data = {
            ...values,
            id: 0,
            nccThongTinChungId: 5,
            ngaySinh: values.ngaySinh !== undefined ? values.ngaySinh : "",
            gioiTinh: values.gioiTinh !== undefined ? values.gioiTinh : "",
            cccd: values.cccd !== undefined ? values.cccd : "",
            noiCap: values.noiCap !== undefined ? values.noiCap : "",
            quocGiaId: values.quocGiaId !== undefined ? values.quocGiaId : 0,
            tinhId: values.tinhId !== undefined ? values.tinhId : 0,
            huyenId: values.huyenId !== undefined ? values.huyenId : 0,
            xaId: values.xaId !== undefined ? values.xaId : 0,
            diaChi: values.diaChi !== undefined ? values.diaChi : "",
            diaChiCuThe: values.diaChiCuThe !== undefined ? values.diaChiCuThe : "",
            website: values.website !== undefined ? values.website : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
        };
        console.log("data=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/lienhe/saveData",
            data: JSON.stringify(data),
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("==== data ", data);
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({});
                            dataLstSearch.push({
                                value: data[i].id + " - " + data[i].ma_loai_hinh + " - " + data[i].loai_hinh_du_lich,
                            });
                        }
                        dataAdd(dataLst, dataLstSearch);
                    }
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };

    const onSearch = (value) => {
        handleSearch(value);
    };
    return (
        <Row>
            {contextHolder}
            <Col
                xs={{
                    span: 6,
                }}
                lg={{
                    span: 6,
                }}
            ></Col>
            <Col
                xs={{
                    span: 11,
                    offset: 1,
                }}
                lg={{
                    span: 6,
                    offset: 2,
                }}
            ></Col>
            <Col
                xs={{
                    span: 5,
                    offset: 1,
                }}
                lg={{
                    span: 6,
                    offset: 2,
                }}
            >
                <Space size="large">
                    {isView ? (
                        ""
                    ) : (
                        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                            {t("add")}
                        </Button>
                    )}
                    <ModalContact
                        isOpen={isOpen}
                        handleCancel={handleCancel}
                        handleFinish={handleFinish}
                        record={editingRecord}
                    />
                </Space>
            </Col>
        </Row>
    );
};
export default FormContact;
