import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhKhachSanTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhKhachSanTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhKhachSanTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhKhachSanTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhKhachSan = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhKhachSan", data, {
    headers: authHeader(),
  });
};

const getNccKhachSan = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccKhachSan", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getTinhTP = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhKhachSan = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhKhachSan", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhKhachSanTheoMap,
  updateCauHinhKhachSanTheoMap,
  saveCauHinhKhachSan,
  getNccKhachSan,
  getQuocGia,
  getTinhTP,
  xoaCauHinhKhachSan,
};
