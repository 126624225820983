import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import {
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccNhaHang from "./ModalThongTinNccNhaHang";
import chiet_tinh_op from "../../../../api/bao_gia/chiet_tinh_op/chiet_tinh_op";
const ModalCapNhatNhaHang = ({
  isOpen,
  onReset,
  handleCancel,
  dataNhomNgLon,
  dataNhomTreEm,
  baoGiaHanhTrinhId,
  baoGiaTongQuanId,
  bgChietTinhOPId,
  dmDichVuId,
  bgMapChietTinhOpHtId,
  soLuongTreEmBG,
  hanhDong,
  soNgay,
  ngay,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [dmGia, setDmGia] = useState([]);
  const { t } = useTranslation();
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [dmVat, setDmVat] = useState([]);
  const [dmDoTuoi, setDmDoTuoi] = useState([]);
  const [dmBuaAn, setDmBuaAn] = useState([]);
  const [isShowNccNH, setIsShowNccNH] = useState(false);
  const [dataSource, setDataSource] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
  });
  const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
  const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
  const [dichVuTreEms, setDichVuTreEms] = useState([]);
  const [dichVuTreEmsInit, setDichVuTreEmsInit] = useState([]);
  const [action, setAction] = useState("");
  const [idxEdit, setIdxEdit] = useState(0);
  const [countDichVu, setCountDichVu] = useState(0);
  const [bgNhomNguoiLonId, setBgNhomNguoiLonId] = useState(0);
  const [bgNhomNguoiLonIdInit, setBgNhomNguoiLonIdInit] = useState(0);
  const [reset, setReset] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [listNgay, setListNgay] = useState([]);
  const [maNcc, setMaNcc] = useState("");
  const prevCountDichVu = useRef();
  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmVat();
    getDmDoTuoi();
    getDmBuaAn();
  }, []);
  useEffect(() => {
    prevCountDichVu.current = countDichVu;
  }, [countDichVu]);

  useEffect(() => {
    genNgay(soNgay);
  }, [soNgay]);

  useEffect(() => {
    if (hanhDong === "EDIT") {
      getChietTinhOPNhaHang({ bgMapChietTinhOpHtId: bgMapChietTinhOpHtId });
    }
  }, [hanhDong, bgMapChietTinhOpHtId]);

  useEffect(() => {
    if (hanhDong === "ADD") {
      setDichVus([{ id: "NULL" }]);
      setDichVusInit([{ id: "NULL" }]);
      setDataSource({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
      });
      setDataSourceInit({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
      });
      setDichVuTreEms([]);
      setDichVuTreEmsInit([]);
      setBgNhomNguoiLonId(0);
      setBgNhomNguoiLonIdInit(0);
    }
  }, [isOpen]);

  const genNgay = (soNgay) => {
    let lstNgay = [];
    for (let index = 0; index < soNgay; index++) {
      lstNgay.push({ ngay: index + 1 });
    }
    setListNgay(lstNgay);
  };

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const chiPhiTreEm = dichVuTreEms.reduce((sum, item) => {
    if (item.id !== "NULL") sum += parseFloat(item.thanhTien);
    return sum;
  }, 0);

  const chiPhiNguoiLon = dichVus.reduce((sum, item) => {
    if (item.id !== "NULL") sum += parseFloat(item.tong);
    return sum;
  }, 0);

  const tongChiPhi = chiPhiTreEm + chiPhiNguoiLon;

  const getChietTinhOPNhaHang = (id) => {
    chiet_tinh_op
      .getChietTinhOPNhaHang(id)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let ds = {
            nccThongTinChungId: data.nccThongTinChungId,
            tenNcc: data.tenNhaCungCap,
            dmGiaApDungId: data.dmGiaApDungId,
            dmLoaiApDung: data.dmLoaiApDungId,
            dmVatId: data.dmVatId,
            moTa: data.moTa,
          };
          let lstDichVu = data.dichVus.map((it, idx) => {
            it.id = idx + 1;
            it.giaNet = it.giaDichVu;
            it.suat = it.dieuKienFoc;
            it.dichVu = it.tenDichVu;
            it.tong = it.giaNet * it.soLuong;
            it.dmTimeId = it.dmBuaAnId;
            return it;
          });
          lstDichVu.push({ id: "NULL" });
          setBgNhomNguoiLonId(data?.dichVus[0].bgNhomNguoiLonId);
          setBgNhomNguoiLonIdInit(data?.dichVus[0].bgNhomNguoiLonId);
          let groupDichVu = Object.groupBy(
            data.dichVus,
            ({ tranxNhaHangId }) => tranxNhaHangId
          );
          let keyGroupDichVu = Object.keys(groupDichVu);
          if (
            data.dichVuTreEms &&
            data.dichVuTreEms.length ===
              data.dichVuTreEms.filter((it) => it.apDung).length
          )
            setCheckAll(true);
          else setCheckAll(false);

          let idx = 0;
          let dichVuTEs = [];
          let idxCheckRowSpanDV = 1;
          let idxCheckRowSpanTE = 1;
          //Loop by dich vu
          for (let index = 0; index < keyGroupDichVu.length; index++) {
            let tranxNhaHangId = keyGroupDichVu[index];
            let lstTreEm = data.dichVuTreEms.filter(
              (it) => it.tranxNhaHangId === parseInt(tranxNhaHangId)
            );
            let groupDichVuTreEm = Object.groupBy(
              lstTreEm,
              ({ bgNhomTreEmId }) => bgNhomTreEmId
            );
            let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
            // loop by group tre em
            for (let k = 0; k < keyGroupDichVuTreEm.length; k++) {
              let bgNhomTreEmId = keyGroupDichVuTreEm[k];
              let dichVuTEByGroup = lstTreEm.filter(
                (it) => it.bgNhomTreEmId === parseInt(bgNhomTreEmId)
              );
              let data = dichVuTEByGroup.map((it) => {
                idx++;
                it.id = idx;
                it.dichVu = it.tenDichVu;
                it.tenNhomTE = it.nhomTreEm;
                it.giaNet = it.giaDichVuTreEm;
                it.thanhTien = (it.phanTram / 100) * it.soLuong * it.giaNet;
                it.rowSpanDV =
                  idx === idxCheckRowSpanDV
                    ? lstTreEm[0].bgNhomTreEmId !== 0
                      ? lstTreEm.length
                      : lstTreEm.length + 1
                    : 0;
                it.rowSpanTE =
                  idx === idxCheckRowSpanTE
                    ? lstTreEm[0].bgNhomTreEmId !== 0
                      ? dichVuTEByGroup.length
                      : 0
                    : 0;
                return it;
              });
              // if (lstTreEm[0].bgNhomTreEmId !== 0) {
              idxCheckRowSpanTE += dichVuTEByGroup.length;
              // } else {
              //   idxCheckRowSpanTE += dichVuTEByGroup.length + 1;
              // }

              dichVuTEs = [...dichVuTEs, ...data];
            }
            if (!lstTreEm[0] || lstTreEm[0]?.bgNhomTreEmId === 0) {
              dichVuTEs.push({
                id: "addNew",
                dichVu: groupDichVu[tranxNhaHangId][0].tenDichVu,
                giaNet: groupDichVu[tranxNhaHangId][0].giaDichVu,
                nccDichVuNhaHangId:
                  groupDichVu[tranxNhaHangId][0].nccDvNhaHangId,
                tranxNhaHangId: tranxNhaHangId,
                rowSpanDV: !lstTreEm[0] ? 1 : 0,
              });
            }
            idxCheckRowSpanDV += dichVuTEs.length;
          }

          setCountDichVu(keyGroupDichVu.length);
          setDichVus(lstDichVu);
          setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
          setDataSource(ds);
          setDataSourceInit({ ...ds });
          setDichVuTreEms(dichVuTEs);
          setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTEs)));
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmVat = () => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmDoTuoi = () => {
    dm_gia_ap_dung
      .getDmDoTuoi()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmDoTuoi(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmBuaAn = () => {
    dm_gia_ap_dung
      .getDmBuaAn()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmBuaAn(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleUpdateService = (record) => {
    setIsShowNccNH(true);
    setAction("UPDATE_SERVICE");
    setIdxEdit(record.id - 1);
  };

  const handleUpdateNCC = () => {
    setMaNcc("");
    setIsShowNccNH(true);
    setAction("UPDATE_NCC");
  };

  const handleAddService = () => {
    if (dichVus.length === 1) setMaNcc("");
    setIsShowNccNH(true);
    setAction("ADD_SERVICE");
  };

  const onChangeEleDichVu = (id, event) => {
    let index = dichVus.findIndex((item) => item.id === id);
    let name = event.target.name;
    dichVus[index][name] = event.target.value;
    if (
      event.target.value < 0 &&
      (name === "soLuong" || name === "suat" || name === "foc")
    )
      dichVus[index][name] = 0;
    if (event.target.name === "soLuong")
      dichVus[index].tong = dichVus[index].giaNet * dichVus[index].soLuong;
    setDichVus([...dichVus]);
  };

  const onChangeEleDichVuTreEm = (id, event, type) => {
    let dichVus = [];
    let dichVuApDung = [];
    if (type === "ALL") {
      dichVuApDung = dichVuTreEms.filter(
        (item) => item.id !== "addNew" && item.apDung
      );
      dichVus = dichVuTreEms.filter((item) => item.id !== "addNew");
      if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length) {
        dichVus = dichVuTreEms.map((it) => {
          if (it.id !== "addNew") it.apDung = false;
          return it;
        });
        setCheckAll(false);
      } else {
        dichVus = dichVuTreEms.map((it) => {
          if (it.id !== "addNew") it.apDung = true;
          return it;
        });
        setCheckAll(true);
      }
      setDichVuTreEms(dichVus);
    } else {
      let index = dichVuTreEms.findIndex((item) => item.id === id);
      if (event.target.name === "apDung") {
        dichVuTreEms[index][event.target.name] = event.target.checked;
      } else {
        dichVuTreEms[index][event.target.name] = event.target.value;
        if (
          event.target.name === "soLuong" ||
          event.target.name === "phanTram"
        ) {
          if (event.target.value < 0) {
            dichVuTreEms[index][event.target.name] = 0;
          }
          dichVuTreEms[index].thanhTien =
            dichVuTreEms[index].giaNet *
            dichVuTreEms[index].soLuong *
            (dichVuTreEms[index].phanTram / 100);
        }
      }
      dichVuApDung = dichVuTreEms.filter(
        (item) => item.id !== "addNew" && item.apDung
      );
      dichVus = dichVuTreEms.filter((item) => item.id !== "addNew");
      if (dichVuApDung.length > 0 && dichVuApDung.length === dichVus.length)
        setCheckAll(true);
      else setCheckAll(false);
      setDichVuTreEms([...dichVuTreEms]);
    }
  };

  const handleChangeValueDataSoure = (e) => {
    dataSource[e.target.name] = e.target.value;
    // setIsUpdate(true);
    setReset(!reset);
  };

  const handleDeleteService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    lstDichVu.splice(index, 1);
    let groupDichVu = Object.groupBy(lstDichVu, ({ dichVu }) => dichVu);
    let keyGroupDichVu = Object.keys(groupDichVu);

    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    if (keyGroupDichVu.length < prevCountDichVu.current) {
      lstDichVuTreEm = lstDichVuTreEm.filter(
        (item) => item.dichVu !== dichVus[index].dichVu
      );
    }
    setDichVuTreEms(lstDichVuTreEm);
    setDichVus(lstDichVu);
    setCountDichVu(keyGroupDichVu.length);
    // setReset(!reset);
  };

  const handleChangeNhomNgLon = (id) => {
    if (dataNhomTreEm.length > 0) {
      let nhomTreEms = dataNhomTreEm.filter(
        (item) => item.nhomNguoiLonId === id
      );
      let lenDataTE = nhomTreEms.reduce((num, item) => {
        item.details.forEach(() => {
          num++;
        });
        return num;
      }, 0);
      let groupDichVu = Object.groupBy(dichVus, ({ dichVu }) => dichVu);
      let keyGroupDichVu = Object.keys(groupDichVu);
      let idx = 0;
      let idxCheckRowSpanTE = 1;
      let lstDichVuTreEm = [];
      let idxCheckRowSpanDV = 1;
      for (let index = 0; index < keyGroupDichVu.length - 1; index++) {
        let key = keyGroupDichVu[index];
        for (let index = 0; index < nhomTreEms.length; index++) {
          let element = nhomTreEms[index];
          let dichVuTEByGroup = element.details?.map((item) => {
            idx++;
            return {
              id: idx,
              dichVu: key,
              tenNhomTE: element.tenNhom,
              phanTram: 0,
              giaNet: groupDichVu[key][0].giaNet,
              soLuong: item.soLuong,
              thanhTien: 0,
              nccDichVuNhaHangId: groupDichVu[key][0].nccDichVuNhaHangId,
              tranxNhaHangId: groupDichVu[key][0].tranxNhaHangId,
              bgNhomTreEmId: element.id,
              dmDoTuoiId: item.dmDoTuoiId,
              phanTram: 0,
              apDung: false,
              rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
              rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
            };
          });
          idxCheckRowSpanTE += element.details.length;
          lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
        }
        idxCheckRowSpanDV += lenDataTE;
      }
      setDichVuTreEms(lstDichVuTreEm);
    }
    setBgNhomNguoiLonId(id);
  };

  const handleAddServiceTreEm = (row) => {
    let data = [];
    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    let lstTreEmByGroup = lstDichVuTreEm.filter(
      (item) => item.dichVu === row.dichVu
    );
    lstDichVuTreEm.forEach((dt, idx) => {
      if (row.dichVu === dt.dichVu && dt.id === "addNew") {
        dt.id = idx;
        dt.phanTram = 0;
        dt.soLuong = 0;
        dt.thanhTien = 0;
        dt.bgNhomTreEmId = 0;
        dt.dmDoTuoiId = 1;
        dt.apDung = false;
        dt.rowSpanDV = lstTreEmByGroup.length === 1 ? 2 : 0;
        dt.rowSpanTE = 0;
        data.push(dt);
        data.push({
          id: "addNew",
          dichVu: row.dichVu,
          giaNet: row.giaNet,
          nccDichVuNhaHangId: row.nccDichVuNhaHangId,
          tranxNhaHangId: row.tranxNhaHangId,
          rowSpanDV: 0,
        });
      } else {
        if (
          dt.id === lstTreEmByGroup[0].id &&
          dt.dichVu === lstTreEmByGroup[0].dichVu
        )
          dt.rowSpanDV = lstTreEmByGroup.length + 1;
        data.push(dt);
      }
    });
    setDichVuTreEms(data);
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = getDataReq();
      console.log(dataReq);
      console.log(JSON.stringify(dataReq));
      chiet_tinh_op
        .saveChietTinhOPNhaHang(dataReq)
        .then((response) => {
          let action = "add";
          if (bgMapChietTinhOpHtId !== 0) action = "edit";
          if (response.status === 200 && response.data.code === "00") {
            showNoti(
              "success",
              t(action) + " " + t("noti.success").toLowerCase()
            );
            setDataSourceInit({ ...dataSource });
            setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
            setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTreEms)));
            setBgNhomNguoiLonIdInit(bgNhomNguoiLonId);
            handleCancel();
            onReset();
          } else {
            showNoti("error", response.data.message);
          }
        })
        .catch(() => {
          showNoti("error", t("system.error"));
        });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const getDataReq = () => {
    let dataReq = {
      ngay: ngay,
      baoGiaHanhTrinhId: baoGiaHanhTrinhId,
      baoGiaTongQuanId: baoGiaTongQuanId,
      dmDichVuId: dmDichVuId,
      bgChietTinhOpId: bgChietTinhOPId,
      bgMapChietTinhOpHtId: bgMapChietTinhOpHtId ? bgMapChietTinhOpHtId : 0, //thêm mới, >0 chỉnh sửa
      nccThongTinChungId: dataSource.nccThongTinChungId,
      dmGiaApDungId: dataSource.dmGiaApDungId,
      dmVatId: dataSource.dmVatId,
      dmTyGiaId: dataSource.dmTyGiaId,
      moTa: dataSource.moTa,
      dichVus: dichVus
        .filter((dt) => dt.id !== "NULL")
        .map((it) => {
          return {
            nccDichVuNhaHangId: it.nccDichVuNhaHangId,
            tranxNhaHangId: it.tranxNhaHangId,
            dmBuaAnId: it.dmTimeId,
            bgNhomNguoiLonId: bgNhomNguoiLonId,
            soLuong: it.soLuong,
            dieuKienFoc: it.suat,
            foc: it.foc,
          };
        }),
      dichVuTreEms: dichVuTreEms
        .filter((dt) => dt.id !== "addNew")
        .map((it) => {
          return {
            nccDichVuNhaHangId: it.nccDichVuNhaHangId,
            tranxNhaHangId: it.tranxNhaHangId,
            bgNhomTreEmId: it.bgNhomTreEmId,
            dmDoTuoiId: it.dmDoTuoiId,
            phanTram: it.phanTram,
            soLuong: it.soLuong,
            apDung: it.apDung,
          };
        }),
    };
    return dataReq;
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    let soLuongTE = dichVuTreEms.reduce((sum, item) => {
      if (item.id !== "addNew") sum += parseInt(item.soLuong);
      return sum;
    }, 0);
    if (soLuongTE > soLuongTreEmBG) {
      result.check = false;
      result.message = t("noti.errorSoLuongTE");
    } else if (dataNhomNgLon.length > 0 && bgNhomNguoiLonId === 0) {
      result.check = false;
      result.message = t("noti.errorNhomNgLon");
    }
    return result;
  };

  const columnServices = [
    {
      title: t("service"),
      dataIndex: "dichVu",
      key: "dichVu",
      width: "15%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link onClick={() => handleAddService()} underline="none">
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 8,
            },
          };
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={record.dichVu}
            InputProps={{
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateService(record)}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleDeleteService(record)}
                  />
                </>
              ),
            }}
          />
        );
      },
    },
    {
      title: t("eatTime"),
      dataIndex: "dmTimeId",
      key: "dmTimeId",
      width: "10%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return (
          <TextField
            value={record.dmTimeId}
            name="dmTimeId"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => onChangeEleDichVu(record.id, e)}
            select
          >
            {dmBuaAn.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.ten_bua_an}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
    {
      title: t("hotel.roomClass.conditionFoc"),
      children: [
        {
          title: t("set"),
          dataIndex: "suat",
          key: "suat",
          width: "10%",
          align: "center",
          render: (_, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={record.suat}
                name="suat"
                variant="outlined"
                onChange={(e) => onChangeEleDichVu(record.id, e)}
                size="small"
                type="number"
                fullWidth
              ></TextField>
            );
            // return handleData(record.fitNgayThuongNet);
          },
        },
        {
          title: t("hotel.roomClass.foc"),
          dataIndex: "foc",
          key: "foc",
          width: "10%",
          align: "center",
          render: (_, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={record.foc}
                variant="outlined"
                name="foc"
                size="small"
                type="number"
                onChange={(e) => onChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
            // return handleData(record.extraNgayThuongNet);
          },
        },
      ],
      width: "20%",
      align: "center",
      visible: true,
    },
    {
      title: t("tour.donGia"),
      children: [
        {
          title: t("hotel.priceClass.giaNet"),
          dataIndex: "giaNet",
          key: "giaNet",
          width: "12%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: handleData(text),
              props: {
                rowSpan: 1,
              },
            };
          },
        },
        {
          title: t("tour.vanChuyen.soLuong"),
          dataIndex: "soLuong",
          key: "soLuong",
          width: "12%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuong"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => onChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
            // return handleData(record.extraCuoiTuanNet);
          },
        },
      ],
      // width: "25%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.tongChiPhi"),
      dataIndex: "tong",
      key: "tong",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: handleData(text),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("adultGroup"),
      dataIndex: "nhomNgLon",
      key: "nhomNgLon",
      width: "15%",
      align: "center",
      visible: dataNhomNgLon.length > 0,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else if (record.id === 1) {
          return {
            children: (
              <TextField
                value={bgNhomNguoiLonId}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleChangeNhomNgLon(e.target.value)}
                select
              >
                {dataNhomNgLon.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.tenNhom}
                  </MenuItem>
                ))}
              </TextField>
            ),
            props: {
              colSpan: 1,
              rowSpan: dichVus.length - 1,
            },
          };
        } else {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
      },
    },
  ];

  const columnsTE = [
    {
      title: t("service"),
      dataIndex: "dichVu",
      align: "center",
      width: "15%",
      visible: true,
      render: (text, row) => {
        return {
          children: text,
          props: {
            rowSpan: row.rowSpanDV,
          },
        };
      },
    },
    {
      title: t("childrenGroup"),
      dataIndex: "tenNhomTE",
      align: "center",
      width: "15%",
      visible: dataNhomTreEm.length > 0,
      render: (text, row) => {
        return {
          children: (
            <TextField
              variant="outlined"
              value={text}
              size="small"
              fullWidth
              disabled={true}
            ></TextField>
          ),
          props: {
            rowSpan: row.rowSpanTE,
          },
        };
      },
    },
    {
      title: t("age") + " " + t("children"),
      dataIndex: "dmDoTuoiId",
      align: "center",
      width: "15%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link
                  onClick={() => handleAddServiceTreEm(row)}
                  underline="none"
                >
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 6,
            },
          };
        return (
          <>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                <TextField
                  variant="outlined"
                  value={text}
                  size="small"
                  name="dmDoTuoiId"
                  fullWidth
                  select
                  disabled={dataNhomTreEm.length > 0}
                  onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
                >
                  {dmDoTuoi.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.do_tuoi}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                {dataNhomNgLon.length === 0 && (
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t("tour.percentage"),
      dataIndex: "phanTram",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: (
            <TextField
              variant="outlined"
              value={text}
              size="small"
              name="phanTram"
              fullWidth
              type="number"
              onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
            />
          ),
          props: {},
        };
      },
    },
    {
      title: t("hotel.priceClass.giaNet"),
      dataIndex: "giaNet",
      align: "center",
      width: "12%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        if (text)
          return {
            children: handleData(text),
            props: {},
          };
      },
    },
    {
      title: t("tour.vanChuyen.soLuong"),
      dataIndex: "soLuong",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };

        return {
          children: (
            <TextField
              value={text}
              variant="outlined"
              name="soLuong"
              size="small"
              type="number"
              fullWidth
              onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
            ></TextField>
          ),
          props: {},
        };
      },
    },
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
      width: "15%",
      align: "center",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: handleData(text),
          props: {},
        };
      },
    },
    {
      title: () => {
        return (
          <>
            {t("action.apply")}{" "}
            <Checkbox
              checked={checkAll}
              onChange={(e) => onChangeEleDichVuTreEm(e, "", "ALL")}
            ></Checkbox>
          </>
        );
      },
      dataIndex: "apDung",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        return {
          children: (
            <Checkbox
              name="apDung"
              checked={row.apDung}
              onChange={(e) => onChangeEleDichVuTreEm(row.id, e)}
            />
          ),
          props: {},
        };
      },
    },
  ];
  const handleClear = () => {
    // setBgNhomNguoiLonId(0);
    setMaNcc("");
    setDataSource({ ...dataSourceInit });
    setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
    setDichVuTreEms(JSON.parse(JSON.stringify(dichVuTreEmsInit)));
    setBgNhomNguoiLonId(bgNhomNguoiLonIdInit);
  };

  const handleCloseModal = () => {
    handleCancel();
    handleClear();
  };

  const handleChangeDataServiceNhaHang = (dataSelected) => {
    // console.log(dataSelected);
    let lstDichVu = [];
    if (action === "UPDATE_NCC") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = dataSelected[0].moTa;
      lstDichVu = dataSelected?.map((item, idx) => {
        return {
          key: idx + 1,
          id: idx + 1,
          nccDichVuNhaHangId: item.nccDvNhaHangId,
          tranxNhaHangId: item.tranxNhaHangId,
          bgNhomNgLonId: 0,
          dichVu: item.tenDichVu,
          dmTimeId: 1,
          suat: item.dieuKienFoc,
          foc: item.foc,
          giaNet: item.fitNgayThuongNet,
          soLuong: 0,
          tong: 0,
        };
      });
      lstDichVu.push({ id: "NULL" });
    } else if (action === "ADD_SERVICE") {
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu.splice(dichVus.length - 1, 1);
      lstDichVu.push({
        key: dichVus.length,
        id: dichVus.length,
        nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
        tranxNhaHangId: dataSelected[0].tranxNhaHangId,
        bgNhomNgLonId: 0,
        dichVu: dataSelected[0].tenDichVu,
        dmTimeId: 1,
        suat: dataSelected[0].dieuKienFoc,
        foc: dataSelected[0].foc,
        giaNet: dataSelected[0].fitNgayThuongNet,
        soLuong: 0,
        tong: 0,
      });
      lstDichVu.push({ id: "NULL" });
    } else {
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu[idxEdit] = {
        key: idxEdit + 1,
        id: idxEdit + 1,
        nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
        tranxNhaHangId: dataSelected[0].tranxNhaHangId,
        bgNhomNgLonId: 0,
        dichVu: dataSelected[0].tenDichVu,
        dmTimeId: 1,
        suat: dataSelected[0].dieuKienFoc,
        foc: dataSelected[0].foc,
        giaNet: dataSelected[0].fitNgayThuongNet,
        soLuong: 0,
        tong: 0,
      };
    }

    let groupDichVu = Object.groupBy(lstDichVu, ({ dichVu }) => dichVu);
    let keyGroupDichVu = Object.keys(groupDichVu);
    let nhomTreEms = JSON.parse(JSON.stringify(dataNhomTreEm));
    if (bgNhomNguoiLonId !== 0) {
      nhomTreEms = nhomTreEms.filter(
        (item) => item.nhomNguoiLonId === bgNhomNguoiLonId
      );
    }
    let lenDataTE = nhomTreEms.reduce((num, item) => {
      item.details.forEach(() => {
        num++;
      });
      return num;
    }, 0);

    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    let idx = 0;
    let idxCheckRowSpanTE = 1;
    if (action === "UPDATE_NCC") {
      // setBgNhomNguoiLonId(0);
      lstDichVuTreEm = [];
      let idxCheckRowSpanDV = 1;
      for (let index = 0; index < keyGroupDichVu.length - 1; index++) {
        let key = keyGroupDichVu[index];
        if (dataNhomTreEm.length === 0) {
          lstDichVuTreEm = [
            ...lstDichVuTreEm,
            {
              id: "addNew",
              dichVu: key,
              giaNet: dataSelected[0].fitNgayThuongNet,
              nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
              tranxNhaHangId: dataSelected[0].tranxNhaHangId,
            },
          ];
        } else if (!(bgNhomNguoiLonId === 0 && dataNhomNgLon.length > 0)) {
          for (let index = 0; index < nhomTreEms.length; index++) {
            let element = nhomTreEms[index];
            let dichVuTEByGroup = element.details?.map((item) => {
              idx++;
              return {
                id: idx,
                dichVu: key,
                tenNhomTE: element.tenNhom,
                phanTram: 0,
                giaNet: groupDichVu[key][0].giaNet,
                soLuong: item.soLuong,
                thanhTien: 0,
                nccDichVuNhaHangId: groupDichVu[key][0].nccDichVuNhaHangId,
                tranxNhaHangId: groupDichVu[key][0].tranxNhaHangId,
                bgNhomTreEmId: element.id,
                dmDoTuoiId: item.dmDoTuoiId,
                phanTram: 0,
                apDung: false,
                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                rowSpanTE:
                  idx === idxCheckRowSpanTE ? element.details.length : 0,
              };
            });
            idxCheckRowSpanTE += element.details.length;
            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
          }
          idxCheckRowSpanDV += lenDataTE;
        }
      }
    } else if (keyGroupDichVu.length > prevCountDichVu.current) {
      if (dataNhomTreEm.length === 0) {
        lstDichVuTreEm = [
          ...lstDichVuTreEm,
          {
            id: "addNew",
            dichVu: dataSelected[0].tenDichVu,
            giaNet: dataSelected[0].fitNgayThuongNet,
            nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
            tranxNhaHangId: dataSelected[0].tranxNhaHangId,
          },
        ];
      } else if (!(bgNhomNguoiLonId === 0 && dataNhomNgLon.length > 0)) {
        idx = lenDataTE * (prevCountDichVu.current - 1);
        idxCheckRowSpanTE = lenDataTE * (prevCountDichVu.current - 1) + 1;
        for (let index = 0; index < nhomTreEms.length; index++) {
          let element = nhomTreEms[index];
          let dichVuTEByGroup = element.details?.map((item) => {
            idx++;
            return {
              id: idx,
              dichVu: dataSelected[0].tenDichVu,
              tenNhomTE: element.tenNhom,
              phanTram: 0,
              giaNet: dataSelected[0].fitNgayThuongNet,
              soLuong: item.soLuong,
              thanhTien: 0,
              nccDichVuNhaHangId: dataSelected[0].nccDvNhaHangId,
              tranxNhaHangId: dataSelected[0].tranxNhaHangId,
              bgNhomTreEmId: element.id,
              dmDoTuoiId: item.dmDoTuoiId,
              phanTram: 0,
              apDung: false,
              rowSpanDV:
                idx === lenDataTE * (prevCountDichVu.current - 1) + 1
                  ? lenDataTE
                  : 0,
              rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
            };
          });
          idxCheckRowSpanTE += element.details.length;
          lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
        }
      }
    } else if (keyGroupDichVu.length < prevCountDichVu.current) {
      lstDichVuTreEm = lstDichVuTreEm.filter(
        (item) => item.dichVu !== dichVus[idxEdit].dichVu
      );
    } else {
      if (
        dataNhomTreEm.length > 0 &&
        !(bgNhomNguoiLonId === 0 && dataNhomNgLon.length > 0)
      ) {
        lstDichVuTreEm = [];
        let idxCheckRowSpanDV = 1;
        for (let index = 0; index < keyGroupDichVu.length - 1; index++) {
          let key = keyGroupDichVu[index];
          for (let index = 0; index < nhomTreEms.length; index++) {
            let element = nhomTreEms[index];
            let dichVuTEByGroup = element.details?.map((item) => {
              idx++;
              return {
                id: idx,
                dichVu: key,
                tenNhomTE: element.tenNhom,
                phanTram: 0,
                giaNet: groupDichVu[key][0].giaNet,
                soLuong: item.soLuong,
                thanhTien: 0,
                nccDichVuNhaHangId: groupDichVu[key][0].nccDichVuNhaHangId,
                tranxNhaHangId: groupDichVu[key][0].tranxNhaHangId,
                bgNhomTreEmId: element.id,
                dmDoTuoiId: item.dmDoTuoiId,
                phanTram: 0,
                apDung: false,
                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                rowSpanTE:
                  idx === idxCheckRowSpanTE ? element.details.length : 0,
              };
            });
            idxCheckRowSpanTE += element.details.length;
            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
          }
          idxCheckRowSpanDV += lenDataTE;
        }
      }
    }
    if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
    setDichVuTreEms(lstDichVuTreEm);
    setDichVus(lstDichVu);
    setCountDichVu(keyGroupDichVu.length);
    setDataSource({ ...dataSource });
    console.log(dichVuTreEms);
  };

  return (
    <>
      <Modal
        width={1300}
        title={t("tour.nhaHang.capNhatDichVu")}
        footer={null}
        open={isOpen}
        onCancel={() => handleCloseModal()}
      >
        {contextHolder}
        <Divider style={{ background: "#898989" }} />
        <Box component="form" noValidate>
          <Row>
            <TextField
              variant="outlined"
              fullWidth
              label={t("supplier")}
              required
              value={dataSource.tenNcc}
              InputProps={{
                style: { height: "50px" },
                endAdornment: (
                  <>
                    <BorderColorOutlined
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => handleUpdateNCC()}
                    />
                    <CloseCircleFilled
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => handleClear()}
                    />
                  </>
                ),
              }}
            ></TextField>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Table
                dataSource={dichVus}
                columns={columnServices.filter((item) => item.visible)}
                bordered
                pagination={false}
                summary={(pageData) => {
                  // console.log(pageData);
                  let totalDonGia = 0;
                  let totalChiPhi = 0;

                  pageData.forEach(({ soLuong, tong, id }) => {
                    if (id !== "NULL") {
                      totalDonGia += parseInt(soLuong);
                      totalChiPhi += parseInt(tong);
                    }
                  });
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          {t("tour.tongKet")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          colSpan={2}
                          align="center"
                        >
                          <Tooltip title={t("totalSet")}>{totalDonGia}</Tooltip>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={2}
                          colSpan={2}
                          align="center"
                        >
                          <Tooltip title={t("totalCost")}>
                            {handleData(totalChiPhi)}
                          </Tooltip>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                id="dmGiaApDungId"
                select
                InputProps={{
                  style: { height: "50px" },
                }}
                fullWidth
                label={t("priceApply")}
                value={dataSource.dmGiaApDungId}
                name="dmGiaApDungId"
                onChange={(e) => handleChangeValueDataSoure(e)}
              >
                {dmGia?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.gia_ap_dung}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <FormControl disabled={true} style={{ width: "100%" }}>
                <Row>
                  {listNgay?.map((element) => {
                    return (
                      <FormControlLabel
                        key={element.ngay}
                        control={
                          <Checkbox
                            checked={
                              (dataSource.dmGiaApDungId === 1 &&
                                parseInt(element.ngay) >= ngay) ||
                              (dataSource.dmGiaApDungId === 2 &&
                                parseInt(element.ngay) === ngay)
                            }
                          />
                        }
                        label={"Ngày " + element.ngay}
                      />
                    );
                  })}
                </Row>
              </FormControl>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                select
                fullWidth
                label={t("typeOfApply")}
                value={1}
                disabled
                InputProps={{
                  style: { height: "50px" },
                }}
              >
                {dmLoaiApDung?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.loai_ap_dung}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                select
                fullWidth
                label={t("action.apply") + " " + t("thuevat")}
                value={dataSource.dmVatId}
                disabled
                InputProps={{
                  style: { minHeight: "50px" },
                }}
              >
                {dmVat?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.vat}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={11}>
              <TextField
                fullWidth
                label={t("typeTour.description")}
                value={dataSource.moTa}
                name="moTa"
                onChange={(e) => handleChangeValueDataSoure(e)}
                // inputProps={{ maxLength: 500 }}
                // multiline
                rows={3}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <h3>{t("hotel.table.title")}</h3>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                dataSource={dichVuTreEms}
                // rowSelection={{}}
                columns={columnsTE.filter((item) => item.visible)}
                pagination={false}
              />
            </Col>
          </Row>
          <Row>
            <h3>{t("tour.tongKet")}</h3>
          </Row>
          <Row>
            <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              style={{ fontSize: "18px" }}
            >
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {t("tour.tongChiPhi") + " " + t("adult")}
                </Col>

                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {handleData(chiPhiNguoiLon)}
                </Col>
              </Row>
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {t("tour.tongChiPhi") + " " + t("children")}
                </Col>

                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {handleData(chiPhiTreEm)}
                </Col>
              </Row>
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
                </Col>

                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  {handleData(tongChiPhi)}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row style={{ marginTop: 6 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={10}>
              <Space>
                <Button
                  variant="contained"
                  key="save"
                  startIcon={<SaveIcon />}
                  onClick={(e) => handleSave(e)}
                >
                  {t("action.ok")}
                </Button>
                <Button
                  style={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleCloseModal()}
                  key="clear"
                >
                  {t("action.cancel")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Box>
        <ModalThongTinNccNhaHang
          isOpen={isShowNccNH}
          action={action}
          onIsShow={setIsShowNccNH}
          onSaves={handleChangeDataServiceNhaHang}
          maNcc={maNcc}
        />
      </Modal>
    </>
  );
};
export default ModalCapNhatNhaHang;
