import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDmQuocTich = (data) => {
  return axios.post(API_BASE_URL + "category/getDmQuocTich", data, {
    headers: authHeader(),
  });
};
const getDmGioiTinh = (data) => {
  return axios.post(API_BASE_URL + "category/getDmGioiTinh", data, {
    headers: authHeader(),
  });
};
const getDmVaiTro = (data) => {
  return axios.post(API_BASE_URL + "category/getDmVaiTro", data, {
    headers: authHeader(),
  });
};
const getDmNganHangKeToan = (data) => {
  return axios.post(API_BASE_URL + "category/getDmNganHangKeToan", data, {
    headers: authHeader(),
  });
};
export default {
  getDmQuocTich,
  getDmGioiTinh,
  getDmVaiTro,
  getDmNganHangKeToan,
};
