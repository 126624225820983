import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDataDSBooking = (data) => {
    return axios.post(API_BASE_URL + "booking/getDataDSTTBooking", data, {
        headers: authHeader(),
    });
};

const layTTChuongTrinhTourByBkThongTinChungId = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTChuongTrinhTourByBkThongTinChungId", data, {
        headers: authHeader(),
    });
};

const layTTTongQuanBookingById = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTTongQuanBookingById", data, {
        headers: authHeader(),
    });
};
const layTrangThaiBooking = (data) => {
    return axios.post(API_BASE_URL + "booking/layTrangThaiBooking", data, {
        headers: authHeader(),
    });
};
const updateMemberInBkDanhSachDoan = (data) => {
    return axios.post(API_BASE_URL + "booking/updateMemberInBkDanhSachDoan", data, {
        headers: authHeader(),
    });
};
const layThongTinTourSaleRate = (data) => {
    return axios.post(API_BASE_URL + "booking/layThongTinTourSaleRate", data, {
        headers: authHeader(),
    });
};
export default {
    getDataDSBooking,
    layTTChuongTrinhTourByBkThongTinChungId,
    layTrangThaiBooking,
    layTTTongQuanBookingById,
    updateMemberInBkDanhSachDoan,
    layThongTinTourSaleRate,
};
