import axios from "axios";
import authHeader from "./auth_header";
import { API_BASE_URL } from "../constants";

const getDmLoaiHinhDuLich = (data) => {
  return axios.post(API_BASE_URL + "category/getDmLoaiHinhDuLich", data, {
    headers: authHeader(),
  });
};
const getDmLoaiKhach = (data) => {
  return axios.post(API_BASE_URL + "category/getDmLoaiKhach", data, {
    headers: authHeader(),
  });
};

const getDataTreEm = (data) => {
  return axios.post(API_BASE_URL + "ncc/treem/getData", data, {
    headers: authHeader(),
  });
};

export default {
  getDmLoaiHinhDuLich,
  getDmLoaiKhach,
  getDataTreEm,
};
