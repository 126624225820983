import {createRoot} from "react-dom/client";
import React from 'react';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import "./i18n";
import {Provider} from "react-redux";
import store from "./store";



const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    <Provider store={store.store}>
        <App history={store.history}/>
    </Provider>
);

serviceWorker.unregister();