import CryptoJS from 'crypto-js';
import {key_crypt, ACCESS_TOKEN} from "../constants";


export default function authHeader() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
        const decrypToken = CryptoJS.AES.decrypt(accessToken, key_crypt).toString(CryptoJS.enc.Utf8);
        // For Spring Boot back-end
        return {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + decrypToken
        };

    } else {
        return window.location.reload();
    }
}


