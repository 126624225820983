import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import {
    Affix,
    Cascader,
    Col,
    Divider,
    message,
    Modal,
    notification,
    Radio,
    Row,
    Space,
    Typography,
    Upload,
} from "antd";
import Buttons from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@material-ui/core/TextField";
import { MenuItem } from "@mui/material";
import DmQuocGia from "../../../../api/category/dm_quoc_gia";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import { PlusOutlined } from "@ant-design/icons";
import thuc_thu_services from "../../../../api/booking/thucThu/thuc_thu_services";
import DmNHKeToan from "../../../../api/category/dm_nh_ke_toan";
import moment from "moment";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import dayjs from "dayjs";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: 800,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    overflowY: "scroll",
    p: 4,
};
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const FormPhieuThuAction = ({ isOpen, onCancels, handleFinish, editingRecord, bkThongTinChungId }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [soPhieu, setSoPhieu] = useState("");
    const [soPhieuErrorText, setSoPhieuErrorText] = useState("");
    const [loaiBN, setLoaiBN] = useState("");
    const [loaiBNErrorText, setLoaiBNErrorText] = useState("");
    const [dmLoaiBienNhan, setDmLoaiBienNhan] = useState([]);
    const [maBooking, setMaBooking] = useState("");
    const [idBookingErrorText, setIdBookingErrorText] = useState("");
    const [tenBooking, setTenBooking] = useState("");
    const [tenBookingErrorText, setTenBookingErrorText] = useState("");
    const [loaiKh, setLoaiKh] = useState("");
    const [dmLoaiKhachHang, setDmLoaiKhachHang] = useState([]);
    const [loaiKhErrorText, setLoaiKhErrorText] = useState("");
    const [tenKh, setTenKh] = useState("");
    const [tenKhErrorText, setTenKhErrorText] = useState("");
    const [quocGiaId, setQuocGiaId] = useState(0);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [diaChi, setDiaChi] = useState([]);
    const [diaChiCuThe, setDiaChiCuThe] = useState("");
    const [tinhId, setTinhId] = useState();
    const [huyenId, setHuyenId] = useState();
    const [xaId, setXaId] = useState();
    const [loaiTien, setLoaiTien] = useState("");
    const [dataLoaiTien, setDataLoaiTien] = useState([]);
    const [loaiTienErrorText, setLoaiTienErrorText] = useState(false);
    const [soTien, setSoTien] = useState("");
    const [soTienErrorText, setSoTienErrorText] = useState("");
    const [vietBangChu, setVietBangChu] = useState("");
    const [userAssign, setUserAssign] = useState("");
    const [dataUserAssign, setDataUserAssign] = useState([]);
    const [valueRadio, setValueRadio] = useState(true);
    const [ghiChu, setGhiChu] = useState("");
    const [dmNHKeToan, setDmNHKeToan] = useState([]);
    const [nhKeToan, setNhKeToan] = useState("");
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileImg, setFileImg] = useState("");
    const [ngayThanhToan, setNgayThanhToan] = useState();
    const [ngayThanhToanErrorText, setNgayThanhToanErrorText] = useState();
    const [ngayTaoPhieu, setNgayTaoPhieu] = useState();
    const [ngayTaoPhieuErrorText, setNgayTaoPhieuErrorText] = useState();
    const [nhKeToanErrorText, setnhKeToanErrorText] = useState();
    const [error, setError] = useState({});
    useEffect(() => {
        if (editingRecord)
            thuc_thu_services
                .layTTBkPhieuThuById({
                    bkPhieuThuId: editingRecord.id,
                })
                .then((response) => {
                    if (response.status === 200 && response.data.code === "00") {
                        console.log(response.data.data);
                        let data = response.data.data;
                        let date_format = "DD/MM/YYYY";
                        setNgayTaoPhieu(dayjs(data.ngayTaoPhieu, date_format));
                        setNgayThanhToan(dayjs(data.ngayThanhToan, date_format));
                        setSoPhieu(data.soPhieu);
                        setLoaiBN(data.dmLoaiBienNhanId);
                        setLoaiTien(data.loaiTienMacDinhId);
                        setMaBooking(data.mabooking);
                        setTenBooking(data.tenbooking);
                        setLoaiKh(data.dmLoaiKhachHangId);
                        setTenKh(data.tenKhachHang);
                        setQuocGiaId(data.quocGiaId);
                        handleChangeQG(data.quocGiaId);
                        setDiaChi(data.diaDiem);
                        setDiaChiCuThe(data.diaChiCuThe);
                        setSoTien(data.soTien);
                        setUserAssign(data.userAssignId);
                        setGhiChu(data.ghiChu);
                        setValueRadio(data.thanhToanTrucTiep);
                        setNhKeToan(data.dmNganHangKeToanId);
                    }
                })
                .catch((err) => {
                    console.log("Get data bao gia erorrs " + err);
                });
    }, [editingRecord]);
    useEffect(() => {
        getDmQuocGia();
        getDmNHKeToan();
        getDmLoaiTien();
        getUserAssign();
        getDmLoaiKhachHang();
        getDmLoaiBienNhan();
    }, []);
    const getDmLoaiBienNhan = () => {
        thuc_thu_services
            .getDmLoaiBienNhan()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmLoaiBienNhan(data);
                }
            })
            .catch((rs) => console.log("Error getDmKhachHang: " + rs));
    };
    const getDmLoaiKhachHang = () => {
        baoGiaService
            .getDmLoaiKhachHang()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmLoaiKhachHang(data);
                }
            })
            .catch((rs) => console.log("Error getDmKhachHang: " + rs));
    };
    const getDmLoaiTien = () => {
        dm_ty_gia
            .getDmTyGiaHeThong()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDataLoaiTien(data);
                }
            })
            .catch((rs) => console.log("Error getDmLoaiTien: " + rs));
    };
    const getUserAssign = () => {
        baoGiaService
            .getUserAssign()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDataUserAssign(data);
                }
            })
            .catch((rs) => console.log("Error getUserAssign: " + rs));
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const getDmNHKeToan = () => {
        DmNHKeToan.getDmNganHangKeToan()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmNHKeToan(data);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const getDmQuocGia = () => {
        DmQuocGia.getDanhSachQuocGia()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataQuocGia = data
                        .filter((item) => item.hieu_luc === true)
                        .map((item) => ({ value: item.id, label: item.quoc_gia }));
                    setDataQuocGia(dataQuocGia);
                }
            })
            .catch((rs) => console.log("Error getDmQuocTich: " + rs));
    };
    const handleChangeQG = (value) => {
        setQuocGiaId(value);
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        if (data.length > 0) {
                            const result = data.reduce((acc, item) => {
                                const tinhId = item.dm_tinh_id;
                                const cur = acc[tinhId] || {
                                    value: item.tinh_thanhpho,
                                    label: item.tinh_thanhpho,
                                    id: item.dm_tinh_id,
                                    children: [],
                                };
                                const huyenId = item.dm_huyen_id;
                                const curHuyen = cur.children.find((child) => child.id === huyenId);
                                if (!curHuyen) {
                                    const newHuyen = {
                                        value: item.quan_huyen,
                                        label: item.quan_huyen,
                                        id: item.dm_huyen_id,
                                        children: [],
                                    };
                                    cur.children.push(newHuyen);
                                }
                                const curPhuongXa = {
                                    value: item.phuong_xa,
                                    label: item.phuong_xa,
                                    id: item.dm_xa_id,
                                };
                                cur.children.find((child) => child.id === huyenId).children.push(curPhuongXa);
                                acc[tinhId] = cur;
                                return acc;
                            }, {});

                            const formattedResult = Object.values(result).map((tinhTP) => {
                                tinhTP.children = tinhTP.children.map((huyen) => {
                                    huyen.children = huyen.children.map((phuongXa) => ({
                                        value: phuongXa.value,
                                        label: phuongXa.label,
                                        id: phuongXa.id,
                                    }));
                                    return {
                                        value: huyen.value,
                                        label: huyen.label,
                                        id: huyen.id,
                                        children: huyen.children,
                                    };
                                });
                                return tinhTP;
                            });

                            setDataTinhTP(formattedResult);
                        }
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleCancelImg = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };

    const onChangeCascader = (value, selectedOptions) => {
        setDiaChi(value ? value.join(",") : "");
        console.log(value, selectedOptions);
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    setTinhId(selectedOptions[i].id);
                }
                if (i === 1) {
                    setHuyenId(selectedOptions[i].id);
                }
                if (i === 2) {
                    setXaId(selectedOptions[i].id);
                }
            }
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const handleNHKToan = (value) => {
        setNhKeToan(value);
    };
    const options = [
        {
            label: t("hinhThucThanhToan") + " " + t("chuyenKhoan").toLowerCase(),
            value: false,
        },
        {
            label: t("hinhThucThanhToan") + " " + t("trucTiep").toLowerCase(),
            value: true,
        },
    ];
    const onChangeRadio = (e) => {
        setValueRadio(e.target.value);
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <h5 style={{ fontSize: "20px", marginTop: "20px" }}>{t("booking.thongTinBienNhan")}</h5>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={t("booking.ngayTaoPhieu") + " *"}
                                        required
                                        format="DD/MM/YYYY"
                                        value={ngayTaoPhieu}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(value) => setNgayTaoPhieu(value)}
                                    />
                                    {!!ngayTaoPhieuErrorText ? (
                                        <Typography.Text type="danger">{ngayTaoPhieuErrorText}</Typography.Text>
                                    ) : (
                                        <></>
                                    )}
                                </LocalizationProvider>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        size="small"
                                        required
                                        value={ngayThanhToan}
                                        format="DD/MM/YYYY"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        label={t("booking.ngayThanhToan") + " *"}
                                        onChange={(value) => setNgayThanhToan(value)}
                                    />
                                    {!!ngayThanhToanErrorText ? (
                                        <Typography.Text type="danger">{ngayThanhToanErrorText}</Typography.Text>
                                    ) : (
                                        <></>
                                    )}
                                </LocalizationProvider>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="soPhieu"
                                    id="soPhieu"
                                    fullWidth
                                    required
                                    label={t("booking.soPhieu")}
                                    variant="outlined"
                                    value={soPhieu}
                                    onChange={(e) => setSoPhieu(e.target.value)}
                                    error={!!soPhieuErrorText}
                                    helperText={soPhieuErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("booking.loaiBienNhan")}
                                    select
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="loaiBN"
                                    onChange={(e) => setLoaiBN(e.target.value)}
                                    value={loaiBN}
                                    error={!!loaiBNErrorText}
                                    helperText={loaiBNErrorText}
                                >
                                    {dmLoaiBienNhan?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.loai_bien_nhan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Divider />
                        <h5 style={{ fontSize: "20px" }}>{t("booking.thongTinChung")}</h5>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="maBooking"
                                    id="maBooking"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label={"ID Booking"}
                                    value={maBooking}
                                    onChange={(e) => setMaBooking(e.target.value)}
                                    error={!!idBookingErrorText}
                                    helperText={idBookingErrorText}
                                    disabled
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="tenBooking"
                                    id="tenBooking"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label={t("name") + " Booking"}
                                    value={tenBooking}
                                    onChange={(e) => setTenBooking(e.target.value)}
                                    error={!!tenBookingErrorText}
                                    helperText={tenBookingErrorText}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("loaiKH")}
                                    select
                                    required
                                    variant="outlined"
                                    fullWidth
                                    name="loaiKh"
                                    onChange={(e) => setLoaiKh(e.target.value)}
                                    value={loaiKh}
                                    error={!!loaiKhErrorText}
                                    helperText={loaiKhErrorText}
                                >
                                    {dmLoaiKhachHang?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.loai_khach_hang}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="tenKh"
                                    id="tenKh"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    label={t("tenKH")}
                                    value={tenKh}
                                    onChange={(e) => setTenKh(e.target.value)}
                                    error={!!tenKhErrorText}
                                    helperText={tenKhErrorText}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("location.country")}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    name="quocGiaId"
                                    onChange={(e) => handleChangeQG(e.target.value)}
                                    value={quocGiaId}
                                >
                                    {dataQuocGia.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                    }}
                                    size="large"
                                    options={dataTinhTP}
                                    onChange={onChangeCascader}
                                    showSearch={{
                                        filterCascader,
                                    }}
                                    placeholder={
                                        t("location.city") +
                                        " - " +
                                        t("location.district") +
                                        " - " +
                                        t("location.wards")
                                    }
                                    onSearch={(value) => console.log(value)}
                                    value={diaChi.length > 0 ? diaChi.split(",") : []}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    label={t("addressDetail")}
                                    onChange={(e) => setDiaChiCuThe(e.target.value)}
                                    value={diaChiCuThe}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("loaiTienMacDinh")}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    name="loaiTien"
                                    onChange={(e) => setLoaiTien(e.target.value)}
                                    value={loaiTien}
                                    required
                                    error={!!loaiTienErrorText}
                                    helperText={loaiTienErrorText}
                                >
                                    {dataLoaiTien.map((option) => (
                                        <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                            {option.maNgoaiTe}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    label={t("booking.soTien")}
                                    name="soTien"
                                    required
                                    onChange={(e) => setSoTien(e.target.value)}
                                    value={soTien}
                                    error={!!soTienErrorText}
                                    helperText={soTienErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("booking.vietBangChu")}
                                    fullWidth
                                    variant="outlined"
                                    name="vietBangChu"
                                    onChange={(e) => setVietBangChu(e.target.value)}
                                    value={vietBangChu}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label="User Assign"
                                    select
                                    fullWidth
                                    variant="outlined"
                                    name="userAssign"
                                    onChange={(e) => setUserAssign(e.target.value)}
                                    value={userAssign}
                                >
                                    {dataUserAssign?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.fullName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={t("notes")}
                                    fullWidth
                                    variant="outlined"
                                    name="ghiChu"
                                    onChange={(e) => setGhiChu(e.target.value)}
                                    value={ghiChu}
                                ></TextField>
                            </Col>
                        </Row>
                        <Divider />
                        <h5 style={{ fontSize: "20px" }}>{t("hinhThucThanhToan")}</h5>

                        <Row style={{ marginTop: "1%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Radio.Group
                                    style={{ marginTop: "10px" }}
                                    options={options}
                                    onChange={onChangeRadio}
                                    value={valueRadio}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    label={
                                        t("hotel.accountBanking.bank") +
                                        "/" +
                                        t("hotel.accountBanking.accountNumber") +
                                        "/" +
                                        t("tenTaiKhoan")
                                    }
                                    variant="outlined"
                                    select
                                    fullWidth
                                    name="nhKeToan"
                                    onChange={(e) => handleNHKToan(e.target.value)}
                                    value={nhKeToan}
                                    error={!!nhKeToanErrorText}
                                    helperText={nhKeToanErrorText}
                                >
                                    {dmNHKeToan.map((option) => (
                                        <MenuItem key={option.dmNganHangKeToanId} value={option.dmNganHangKeToanId}>
                                            {option.shortName + "/" + option.soTaiKhoan + "/" + option.tenTaiKhoan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileImg.length > 0 ? fileImg : fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelImg}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const onFinish = async (e) => {
        e.preventDefault();
        let err = false;
        debugger;
        // if (!maBooking) {
        //     err = true;
        //     setIdBookingErrorText(t("validate") + " " + t("booking.maTenBooking"));
        // } else {
        //     setIdBookingErrorText();
        // }
        if (!ngayTaoPhieu) {
            err = true;
            setNgayTaoPhieuErrorText(t("validate") + " " + t("booking.ngayTaoPhieu"));
        } else {
            setNgayTaoPhieuErrorText();
        }
        if (!ngayThanhToan) {
            err = true;
            setNgayThanhToanErrorText(t("validate") + " " + t("booking.ngayThanhToan"));
        } else {
            setNgayThanhToanErrorText();
        }
        if (!soPhieu) {
            err = true;
            setSoPhieuErrorText(t("validate") + " " + t("booking.soPhieu"));
        } else {
            setSoPhieuErrorText();
        }
        // if (!loaiBN) {
        //     err = true;
        //     setLoaiBNErrorText(t("validate") + " " + t("booking.loaiBienNhan"));
        // } else {
        //     setLoaiBNErrorText();
        // }
        if (!loaiKh) {
            err = true;
            setLoaiKhErrorText(t("validate") + " " + t("loaiKH"));
        } else {
            setLoaiKhErrorText();
        }
        if (!tenKh) {
            err = true;
            setTenKhErrorText(t("validate") + " " + t("tenKH"));
        } else {
            setTenKhErrorText();
        }
        if (!tenBooking) {
            err = true;
            setTenBookingErrorText(t("validate") + " " + t("maTenBooking"));
        } else {
            setTenBookingErrorText();
        }
        if (!loaiTien) {
            err = true;
            setLoaiTienErrorText(t("validate") + " " + t("loaiTienMacDinh"));
        } else {
            setLoaiTienErrorText();
        }
        if (!nhKeToan) {
            err = true;
            setnhKeToanErrorText(
                t("validate") +
                    " " +
                    t("hotel.accountBanking.bank") +
                    "/" +
                    t("hotel.accountBanking.accountNumber") +
                    "/" +
                    t("tenTaiKhoan")
            );
        } else {
            setnhKeToanErrorText();
        }
        if (err) {
            return;
        }
        let base64 = "";
        if (fileList[0]) base64 = await getBase64(fileList[0].originFileObj);
        let data = {
            bkPhieuThuId: editingRecord.id || 0, // 0 là thêm mới, >0 là update
            bkThongTinChungId: 2,
            ngayTaoPhieu: ngayTaoPhieu.format("DD/MM/YYYY"),
            ngayThanhToan: ngayThanhToan.format("DD/MM/YYYY"),
            soPhieu: soPhieu,
            dmLoaiBienNhanId: loaiBN ? loaiBN : 1, // 1 Tạm ứng, 2 Thanh toán, 3 Hoàn tiền, 4 Thu khác
            dmLoaiKhachHangId: loaiKh, // 1 Cá nhân, 2 Đại lý, 3 Doanh nghiệp, 4 Nhà cung cấp
            tenKhachHang: tenKh,
            quocGiaId: quocGiaId,
            tinhId: tinhId,
            huyenId: huyenId,
            xaId: xaId,
            diaDiem: diaChi,
            diaChiCuThe: diaChiCuThe,
            soTien: soTien ? soTien : 0,
            lyDo: ghiChu,
            thanhToanTrucTiep: valueRadio,
            dmNganHangKeToanId: 1,
            fileDinhKem: fileList[0]
                ? {
                      id: 0,
                      tenFile: fileList[0].name,
                      base64Value: base64,
                  }
                : {},
        };
        handleFinish(data);
    };
    const handleClear = () => {};
    const handleCancels = () => {
        onCancels();
    };

    return (
        <Modal open={isOpen} onCancel={handleCancels} width="70%" footer={null}>
            {contextHolder}
            <Row>
                <Col xs={23} sm={23} md={24} lg={23} xl={23}>
                    <h4 style={{ fontSize: "25px", marginTop: "10px" }}>{t("booking.phieuThu")}</h4>
                </Col>
            </Row>
            <Divider style={{ marginTop: "-10px" }} />
            {dataRender()}
            <Affix>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                height: 60,
                                paddingInline: 50,
                                lineHeight: "60px",
                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                borderRadius: "4px",
                            }}
                        >
                            <Space>
                                <Buttons
                                    variant="contained"
                                    onClick={(e) => onFinish(e)}
                                    type="submit"
                                    startIcon={<SaveIcon />}
                                >
                                    {t("action.ok")}
                                </Buttons>
                                <Buttons
                                    sx={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                    }}
                                    key="reset"
                                    startIcon={<RestartAltIcon />}
                                    onClick={(e) => handleClear(e)}
                                >
                                    {t("action.reset")}
                                </Buttons>
                                <Buttons
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleCancels}
                                    key="clear"
                                >
                                    {t("action.cancel")}
                                </Buttons>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Affix>
        </Modal>
    );
};
export default FormPhieuThuAction;
