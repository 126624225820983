import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getTTChiPhiUocTinhByBkThongTinChungId = (data) => {
  return axios.post(
    API_BASE_URL + "booking/layTTChiPhiUocTinhByBkThongTinChungId",
    data,
    {
      headers: authHeader(),
    }
  );
};

export default {
  getTTChiPhiUocTinhByBkThongTinChungId,
};
