import { DeleteOutlined, DownloadOutlined, EditFilled, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import Delete from "@mui/icons-material/Delete";
import Search from "@mui/icons-material/Search";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    MenuItem,
    Select,
    OutlinedInput,
    Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Col, notification, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import danh_sach_doan_service from "../../../api/dieu_hanh/danh_sach_doan/danh_sach_doan_service";
import Add from "@mui/icons-material/Add";
import DanhSachDoanService from "../../../api/booking/DanhSachDoanService";
import { Link, useNavigate } from "react-router-dom";
import dm_booking from "../../../api/category/dm_booking";
import moment from "moment";
import { API_BASE_URL, data_getDM } from "../../../constants";
import axios from "axios";
import authHeader from "../../../api/auth_header";
import bookingService from "../../../api/booking/bookingService";
const DanhSachDoan = (params) => {
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [textSearch, setTextSearch] = useState();
    const [bkThongTinChungId, setBkThongTinChungId] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const [opNhanSuResponseDetailList, setOpNhanSuResponseDetailList] = useState([]);
    const [bookingId, setBookingId] = useState(62);
    const [dataQuocTich, setDataQuocTich] = useState([]);
    const [dataVaiTro, setDataVaiTro] = useState([]);
    const [dataGioiTinh, setDataGioiTinh] = useState([]);
    const [bkDanhSachDoanId, setBkDanhSachDoanId] = useState(0);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [reset, setReset] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dataNgoaiNgu, setDataNgoaiNgu] = useState([]);
    useEffect(() => {
        getDmQuocTich();
        getDmGioiTinh();
        getDmVaiTro();
        getData({
            opThongTinChungId: 3,
        });
        getDanhSachDoan({ bkThongTinChungId: bookingId, textSearch: "" });
        getDmNgoaiNgu();
    }, []);

    const getData = async (data) => {
        let response = await danh_sach_doan_service.layTTDanhSachNhanSuByThongTinChungId(data);
        if (response.data.code === "00") {
            setData(response.data.data);
            let opNhanSuResponseDetailList = response.data.data.opNhanSuResponseDetailList.map((dt, key) => {
                return { stt: key + 1, ...dt };
            });
            opNhanSuResponseDetailList.push({ id: "SUBTOTAL" });
            setOpNhanSuResponseDetailList(opNhanSuResponseDetailList);
        }
    };
    const getDmNgoaiNgu = () => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNgoaiNgu",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].ngoai_ngu,
                            });
                        }
                        setDataNgoaiNgu(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    const getDmVaiTro = () => {
        dm_booking
            .getDmVaiTro()
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    const data = res.data.data
                        ?.filter((it) => it.hieu_luc)
                        .map((it) => {
                            return {
                                value: it.id,
                                label: it.vai_tro,
                            };
                        });
                    console.log(data);

                    setDataVaiTro(data);
                }
            })
            .catch((err) => console.log("getDmVaiTro error " + err));
    };
    const getDmQuocTich = () => {
        dm_booking
            .getDmQuocTich()
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    const data = res.data.data
                        ?.filter((it) => it.hieu_luc)
                        .map((it) => {
                            return {
                                value: it.id,
                                label: it.quoc_tich,
                            };
                        });
                    setDataQuocTich(data);
                }
            })
            .catch((err) => console.log("getDmQuocTich error " + err));
    };

    const getDmGioiTinh = () => {
        dm_booking
            .getDmGioiTinh()
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    const data = res.data.data
                        ?.filter((it) => it.hieu_luc)
                        .map((it) => {
                            return {
                                value: it.id,
                                label: it.gioi_tinh,
                            };
                        });
                    setDataGioiTinh(data);
                }
            })
            .catch((err) => console.log("getDmGioiTinh error " + err));
    };
    const getDanhSachDoan = (dataReq) => {
        DanhSachDoanService.getDanhSachDoan(dataReq)
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    const data = res.data.data;
                    const danhSachDoan = data?.danhSachDoanDetails?.map((item, idx) => {
                        return {
                            ...item,
                            stt: idx + 1,
                            id: item.bkDanhSachDoanId + "",
                        };
                    });
                    danhSachDoan.push({ id: "NULL" });
                    setDataTable(danhSachDoan);
                    setBkThongTinChungId(data.bkThongTinChungId);
                }
            })
            .catch((err) => console.log("getDataDanhSachDoan error " + err));
    };
    const handleAddRow = (params) => {};
    const opnSaveOpNhanSu = (row, value, type) => {
        debugger;
        let data = {
            id: row.id, // update > 0
            opThongTinChungId: 3,
            dmVaiTroId: row.dmVaiTroId,
            dmGioiTinhId: row.dmGioiTinhId,
            hoVaTen: row.hoVaTen,
            email: row.email, // required: bat buoc vs dmVaiTroId = 3
            soDienThoai: row.soDienThoai, // Invalid regex:10
            ghiChu: row.ghiChu,
            bienSoXe: row.bienSoXe, // required voi TH co dmVaiTroId = 4
            ngoaiNguIds: row.ngoaiNguDetail?.map((dt) => dt.id), // required voi TH co dmVaiTroId = 4
        };
        const typeMapping = {
            dmGioiTinhId: "dmGioiTinhId",
            dmQuocTichId: "dmQuocTichId",
            dmVaiTroId: "dmVaiTroId",
            hoVaTen: "hoVaTen",
            soDienThoai: "soDienThoai",
            email: "email",
            ghiChu: "ghiChu",
            bienSoXe: "bienSoXe",
            ngoaiNguIds: "ngoaiNguIds",
        };

        // Cập nhật thuộc tính tương ứng của đối tượng row
        if (type === "ngoaiNguIds") {
            data.ngoaiNguIds = value;
        } else {
            if (typeMapping[type]) {
                data[typeMapping[type]] = value;
            }
        }
        console.log(JSON.stringify(data));
        if (data.dmVaiTroId === 3) {
            if (!data.email) {
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error.email_required").toLowerCase(),
                    duration: 0,
                });
                return;
            }
            if (!data.ngoaiNguIds || data.ngoaiNguIds.length === 0) {
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error.language_required").toLowerCase(),
                    duration: 0,
                });
                return;
            }
        }
        if (data.dmVaiTroId === 4) {
            if (!data.bienSoXe) {
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error.car_number_required").toLowerCase(),
                    duration: 0,
                });
                return;
            }
        }

        danh_sach_doan_service
            .saveOpNhanSu(data)
            .then((response) => {
                console.log(response);
                if (response.data.code === "00") {
                    getData({
                        opThongTinChungId: 3,
                    });
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };

    const colums_laixe = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            align: "center",
            width: 50,
            type: "number",
            headerAlign: "center",
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 2;
                }
            },
            renderCell: ({ value, row }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Link onClick={() => handleAddRow()}>
                            <PlusOutlined /> {t("addNewTourStaff").toLowerCase()}
                        </Link>
                    );
                }
                return value;
            },
        },
        {
            headerName: t("sex"),
            field: "dmGioiTinhId",
            key: "dmGioiTinhId",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL")
                    return (
                        <>
                            <TextField
                                value={value}
                                select
                                fullWidth
                                onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "dmGioiTinhId")}
                            >
                                {dataGioiTinh.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </>
                    );
            },
        },
        {
            headerName: t("fullname"),
            field: "hoVaTen",
            key: "hoVaTen",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL")
                    return (
                        <>
                            <TextField
                                value={value}
                                onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "hoVaTen")}
                            ></TextField>
                        </>
                    );
            },
        },
        {
            headerName: t("phoneNumber"),
            field: "soDienThoai",
            key: "soDienThoai",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL")
                    return (
                        <>
                            <TextField
                                value={value}
                                onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "soDienThoai")}
                            ></TextField>
                        </>
                    );
            },
        },
        {
            headerName: "Email",
            field: "email",
            key: "email",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL")
                    return (
                        <>
                            <TextField
                                value={value}
                                onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "email")}
                            ></TextField>
                        </>
                    );
            },
        },
        {
            headerName: t("role"),
            field: "dmVaiTroId",
            key: "dmVaiTroId",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL")
                    return (
                        <>
                            <TextField
                                value={value}
                                select
                                fullWidth
                                onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "dmVaiTroId")}
                            >
                                {dataVaiTro.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </>
                    );
            },
        },
        {
            headerName: t("bienSo/NN"),
            field: "bienSoXe",
            key: "bienSoXe",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL") {
                    let vaitro = dataVaiTro.find((item) => item.value === row.dmVaiTroId)?.label;
                    if (vaitro === "Lái xe")
                        return (
                            <>
                                <TextField
                                    value={value}
                                    onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "bienSoXe")}
                                ></TextField>
                            </>
                        );
                    if (vaitro === "Hướng dẫn viên") {
                        let data = row.ngoaiNguDetail.map((item) => item.id);
                        return (
                            <>
                                <Select
                                    multiple
                                    value={data}
                                    onChange={(e) => opnSaveOpNhanSu(row, e.target.value, "ngoaiNguIds")}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={dataNgoaiNgu?.find((e) => e.value === value)?.label}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    fullWidth
                                    MenuProps={MenuProps}
                                >
                                    {dataNgoaiNgu.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        );
                    }
                }
            },
        },
        {
            headerName: t("notes"),
            field: "ghiChu",
            key: "ghiChu",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL")
                    return (
                        <>
                            <TextField value={value}></TextField>
                        </>
                    );
            },
        },
        {
            headerName: t("action"),
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL") {
                    return (
                        <div>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(row)}
                            >
                                <IconButton>
                                    <Delete />
                                </IconButton>
                            </Popconfirm>
                        </div>
                    );
                }
            },
        },
    ];
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleDelete = (row) => {
        danh_sach_doan_service
            .deleteOpNhanSu({ id: row.id })
            .then((response) => {
                console.log(response);
                if (response.data.code === "00") {
                    getData({
                        opThongTinChungId: 3,
                    });
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const handleSearch = (params) => {};

    const columns = [
        {
            title: "STT",
        },
        {
            title: t("sex"),
        },
        {
            title: t("fullName"),
        },
        {
            title: t("phoneNumber"),
        },
        {
            title: t("email"),
        },
        {
            title: t("dob"),
        },
        {
            title: t("quocTich"),
        },
        {
            title: t("passport"),
        },
        {
            title: t("expirationDate"),
        },
        {
            title: t("role"),
        },
        {
            title: t("notes"),
        },
        {
            title: t("action"),
        },
    ];
    const handleAddThanhVien = (item) => {
        // const newDataTable = [...dataTable];
        // let indexAdd = newDataTable.length - 1;
        // newDataTable[indexAdd] = {
        //   id: Math.random() + "",
        //   stt: newDataTable.length,
        //   gioiTinhId: 0,
        //   dmQuocTichId: 0,
        //   dmVaiTroId: 0,
        // };
        // newDataTable.push({ id: "NULL" });
        // setDataTable(newDataTable);

        setHanhDong("ADD");
        setBkDanhSachDoanId(0);
        setIsOpen(true);
    };

    const handleChangeDataTable = (e, row) => {
        // e.prevenDe
        let name = e.target.name;
        let value = e.target.value;
        const newDataTable = [...dataTable];
        let index = newDataTable.findIndex((it) => it.id === row.id);
        if (name === "ngaySinh" || name === "ngayHetHan") {
            let day = moment(value).format("DD/MM/YYYY");
            newDataTable[index][name] = day;
        } else {
            newDataTable[index][name] = value;
        }
        bookingService
            .updateMemberInBkDanhSachDoan({
                bkThongTinChungId: bookingId,
                maxNguoiLon: newDataTable[index].maxNguoiLon,
                maxTreEm: newDataTable[index].maxTreEm,
                DanhSachDoanDetails: [
                    {
                        bkDanhSachDoanId: newDataTable[index].bkDanhSachDoanId,
                        gioiTinhId: newDataTable[index].gioiTinhId,
                        hoVaTen: newDataTable[index].hoVaTen,
                        soDienThoai: newDataTable[index].soDienThoai,
                        email: newDataTable[index].email,
                        ngaySinh: newDataTable[index].ngaySinh,
                        dmQuocTichId: newDataTable[index].dmQuocTichId,
                        soHoChieu: newDataTable[index].soHoChieu,
                        ngayHetHan: newDataTable[index].ngayHetHan,
                        dmVaiTroId: newDataTable[index].dmVaiTroId,
                        ghiChu: newDataTable[index].ghiChu,
                    },
                ],
            })
            .then((response) => {
                console.log(response);
                if (response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("update") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                    setDataTable(newDataTable);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("update") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const handleAddOrUpdateThanhVien = (item) => {
        if (item.bkDanhSachDoanId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setBkDanhSachDoanId(item.bkDanhSachDoanId ? item.bkDanhSachDoanId : 0);
        setIsOpen(true);
    };
    const handleDeleteService = (id) => {
        if (id)
            DanhSachDoanService.deleteThanhVienDanhSachDoan({ bkDanhSachDoanId: id }).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleDelete(id);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        else handleDelete(id);
    };
    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                    <div style={{ fontSize: "23px", fontWeight: "bold" }}>{t("thongTinLaiXeHDV")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextField
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                        fullWidth
                        size="small"
                        placeholder={t("booking.soPhieu")}
                        value={textSearch}
                        onChange={(e) => setTextSearch(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <Button variant="contained" color="success" onClick={handleSearch}>
                        {t("search")}
                    </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={5}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    <Button startIcon={<UploadOutlined />}>Export</Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    <Button startIcon={<DownloadOutlined />}>Import</Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
                <Col span={24}>
                    <DataGrid
                        columns={colums_laixe}
                        rows={opNhanSuResponseDetailList}
                        autoHeight
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                backgroundColor: "#e2e2e2",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontWeight: "bold",
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
                <Col span={24}>
                    <table>
                        <thead
                            style={{
                                backgroundColor: "#E2E2E2",
                                color: "black",
                                fontWeight: "bold",
                            }}
                        >
                            {columns.map((cl) => {
                                return <th>{cl.title}</th>;
                            })}
                        </thead>
                        <>
                            {dataTable.length > 0 ? (
                                dataTable.map((item) => {
                                    return (
                                        <tr>
                                            {item.id.indexOf("NULL") !== -1 ? (
                                                <>
                                                    <td colSpan="100%">
                                                        <Link onClick={() => handleAddThanhVien(item)}>
                                                            <PlusOutlined />
                                                            {t("add") + " " + t("member").toLowerCase()}
                                                        </Link>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td>{item.stt}</td>
                                                    <td>
                                                        <TextField
                                                            value={item.gioiTinhId}
                                                            variant="outlined"
                                                            name="gioiTinhId"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                            select
                                                            // inputProps={{ style: { fontSize: "13px" } }}
                                                            SelectProps={{
                                                                style: { fontSize: "13px" },
                                                                MenuProps: {
                                                                    style: { fontSize: "13px" },
                                                                },
                                                            }}
                                                        >
                                                            {dataGioiTinh.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.hoVaTen}
                                                            variant="outlined"
                                                            name="hoVaTen"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.soDienThoai}
                                                            variant="outlined"
                                                            name="soDienThoai"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.email}
                                                            variant="outlined"
                                                            name="email"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.ngaySinh}
                                                            variant="outlined"
                                                            name="ngaySinh"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.dmQuocTichId}
                                                            select
                                                            variant="outlined"
                                                            name="dmQuocTichId"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                            SelectProps={{
                                                                style: { fontSize: "13px" },
                                                                MenuProps: {
                                                                    style: { fontSize: "13px" },
                                                                },
                                                            }}
                                                        >
                                                            {dataQuocTich.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.soHoChieu}
                                                            variant="outlined"
                                                            name="soHoChieu"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.ngayHetHan}
                                                            variant="outlined"
                                                            name="ngayHetHan"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.dmVaiTroId}
                                                            select
                                                            variant="outlined"
                                                            name="dmVaiTroId"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                            SelectProps={{
                                                                style: { fontSize: "13px" },
                                                                MenuProps: {
                                                                    style: { fontSize: "13px" },
                                                                },
                                                            }}
                                                        >
                                                            {dataVaiTro.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            value={item.ghiChu}
                                                            variant="outlined"
                                                            name="ghiChu"
                                                            size="small"
                                                            onChange={(e) => handleChangeDataTable(e, item)}
                                                            fullWidth
                                                        />
                                                    </td>
                                                    {item.rowSpan !== 0 ? (
                                                        <td rowSpan={item.rowSpan} width={"7%"}>
                                                            {/* <IconButton size="small">
                                                                <EditFilled
                                                                    onClick={() => handleAddOrUpdateThanhVien(item)}
                                                                />
                                                            </IconButton> */}
                                                            <Popconfirm
                                                                title={t("deleteTheTask")}
                                                                description={t("areYouConfirm")}
                                                                okText={t("delete")}
                                                                cancelText={t("huy")}
                                                                onConfirm={() =>
                                                                    handleDeleteService(item.bkDanhSachDoanId)
                                                                }
                                                            >
                                                                <IconButton>
                                                                    <Delete />
                                                                </IconButton>
                                                            </Popconfirm>
                                                        </td>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </tr>
                                    );
                                })
                            ) : (
                                <td colSpan="100%">
                                    <Link onClick={() => handleAddThanhVien()}>
                                        <PlusOutlined /> {t("add") + " " + t("member").toLowerCase()}
                                    </Link>
                                </td>
                            )}
                        </>
                    </table>
                </Col>
            </Row>
            {contextHolder}
        </>
    );
};
export default DanhSachDoan;
