import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Space, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Menu_Item } from "../../../constants";
import RouterBooking from "./RouterBooking";
import SideBarBooking from "./SideBarBooking";
import { DataGrid } from "@mui/x-data-grid";
import bookingService from "../../../api/booking/bookingService";
const { Header, Sider, Content } = Layout;

const AddBooking = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const location = useLocation();
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(false);
    const [idBooking, setIdBooking] = useState("new");
    const [item, setItem] = useState("new");
    const [rows, setRows] = useState([]);

    const keyOnClick = (key) => {
        //setKeyClick(key);
    };
    useEffect(() => {
        if (location.search.length > 0) {
            let id = location.search.split("=");
            setIdBooking("edit?id=" + id[1]);
            getThongTin(id[1]);
        }
        if (location.pathname.length > 0) {
            let path = location.pathname.split("/");
            setItem(Menu_Item[path[2]]);
        }
    }, [item, idBooking]);
    const getThongTin = async (id) => {
        let data = {
            bkThongTinChungId: id,
        };
        const response = await bookingService.layTTTongQuanBookingById(data);
        if (response.status === 200) {
            console.log(JSON.stringify(response.data.data, null, 2));
            setRows(
                response.data.data.map((dt) => {
                    return { ...dt, id: 1 };
                })
            );
        }
    };

    const columns = [
        {
            headerName: t("maBooking"),
            field: "maBooking",
            key: "maBooking",

            flex: 1,
        },
        {
            headerName: t("tenBooking"),
            field: "tenBooking",
            key: "tenBooking",

            flex: 1,
        },
        {
            headerName: t("typeTour"),
            field: "loaiTour",
            key: "loaiTour",

            flex: 1,
            renderCell: ({ value, row }) => (
                <strong>
                    <Button type="primary" size="small" style={{ marginLeft: 16 }}>
                        {value}
                    </Button>
                </strong>
            ),
        },
        {
            headerName: t("soNgay"),
            field: "soNgay",
            key: "soNgay",
            flex: 1,
        },
        {
            headerName: t("timeTour"),
            field: "thoiGianTour",
            key: "thoiGianTour",
            flex: 1,
        },
        {
            headerName: t("quantity"),
            field: "soLuong",
            key: "soLuong",

            flex: 1,
        },
        {
            headerName: t("tongTien"),
            field: "tongTien",
            key: "tongTien",

            flex: 1,
        },
        {
            headerName: t("tamUng"),
            field: "tamUng",
            key: "tamUng",
            flex: 1,
        },
        {
            headerName: t("conLai"),
            field: "conLai",
            key: "conLai",
            flex: 1,
        },
    ];
    return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DataGrid columns={columns} rows={rows} autoHeight />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Layout>
                    <Sider
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                        style={{
                            background: colorBgContainer,
                        }}
                    >
                        <SideBarBooking keyOnClick={keyOnClick} idBooking={idBooking} />
                    </Sider>
                    <Layout>
                        <Header
                            style={{
                                padding: 0,
                                background: "#fafafa",
                                borderRadius: "5px 5px 5px 5px",
                            }}
                        >
                            <Space>
                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                                <div style={{ display: "flex", marginLeft: "10px" }}>{t(item)}</div>
                            </Space>
                        </Header>
                        <Content
                            style={{
                                backgroundColor: "#ffffff",
                                marginLeft: "20px",
                                borderRadius: "5px",
                                marginTop: "10px",
                            }}
                            className="site-layout"
                        >
                            <div style={{}}>
                                <RouterBooking />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Col>
        </Row>
    );
};
export default AddBooking;
