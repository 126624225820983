import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridActionsCellItem,
  GridPagination,
  enUS,
  viVN,
} from "@mui/x-data-grid";
import bookingService from "../../../api/booking/bookingService";
import { Col, Row, Space } from "antd";
import { Box, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@mui/icons-material/Done";

const TableBooking = ({ dataSource }) => {
  const { t, i18n } = useTranslation();
  const [vsFooter, setVsFooter] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const navigate = useNavigate();
  let formatter = new Intl.NumberFormat("en-US", {});

  const handleEditBooking = (param) => {
    navigate("/dat-dich-vu/edit?id=" + param.id);
  };
  const columns = [
    {
      headerName: "STT",
      field: "stt",
      key: "stt",
      align: "center",
      width: 50,
      type: "number",
      headerAlign: "center",
    },
    {
      headerName: "ID " + t("booking"),
      field: "maBooking",
      key: "maBooking",
      align: "center",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <Link
          to={"/dat-dich-vu/edit?id=" + params.id}
          style={{ color: "#007cff" }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: t("name") + " " + t("booking"),
      field: "tenBooking",
      key: "tenBooking",
      align: "center",
      width: 210,
      headerAlign: "center",
    },
    {
      headerName: t("customerInfor"),
      field: "thongTinKhachHang",
      key: "thongTinKhachHang",
      align: "center",
      width: 240,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("loaiKH") + ": "}
            </span>
            <span>{params.value.loaiKhachHang}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("tenKH") + ": "}
            </span>
            <span>{params.value.tenKhachHang}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("phone") + ": "}
            </span>
            <span>{params.value.soDienThoai}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("email") + ": "}
            </span>
            <span>{params.value.email}</span>
          </div>
        </div>
      ),
    },
    {
      headerName: t("tourCost"),
      field: "chiPhiTour",
      key: "chiPhiTour",
      align: "center",
      width: 300,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("tongTien") + ": "}
            </span>
            <span>{formatter.format(params.value.tongTien)}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("thucThu") + ": "}
            </span>
            <span>{formatter.format(params.value.thucThu)}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("conLai") + ": "}
            </span>
            <span>{formatter.format(params.value.conLai)}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("loiNhuan") + ": "}
            </span>
            <span>{formatter.format(params.value.loiNhuan)}</span>
          </div>
        </div>
      ),
    },
    {
      headerName: t("perform"),
      field: "thucHien",
      key: "thucHien",
      align: "center",
      width: 220,
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("nguoiTao") + ": "}
            </span>
            <span>{params.value.nguoiTao}</span>
          </div>
          <div>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("sale") + ": "}
            </span>
            <span>{params.value.saleName}</span>
          </div>
          <div style={{ width: "200px" }}>
            <span style={{ fontWeight: 600, color: "#a0a0a0" }}>
              {t("Executive") + ": "}
            </span>
            <span style={{ whiteSpace: "normal" }}>
              {params.value.tenNguoiDieuHanh}
            </span>
          </div>
        </div>
      ),
    },
    {
      headerName: t("status"),
      field: "tenTrangThai",
      key: "tenTrangThai",
      align: "center",
      minWidth: 170,
      headerAlign: "center",
      renderCell: ({ value }) => {
        if (value === "Tạo mới Booking")
          return <Chip icon={<AddIcon />} label={value} color="primary" />;
        else if (value === "Yêu cầu Booking")
          return (
            <Chip color="warning" icon={<RequestPageIcon />} label={value} />
          );
        else if (value === "Đã hoàn thành")
          return <Chip color="success" icon={<DoneIcon />} label={value} />;
        else if (value === "Đã hủy")
          return <Chip icon={<CloseIcon />} label={value} color="error" />;
      },
    },
    {
      headerName: t("lanSuaCuoi"),
      field: "lanSuaCuoi",
      key: "lanSuaCuoi",
      align: "center",
      // flex: 2,
      width: 170,
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: (params) => [
        <GridActionsCellItem
          label={t("tour.taiXuong")}
          // onClick={viewRecord(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("edit")}
          onClick={() => handleEditBooking(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("delete")}
          // onClick={viewRecord(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("nhanBan")}
          // onClick={viewRecord(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("Executivetransfer")}
          // onClick={viewRecord(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("Cancelquote")}
          // onClick={viewRecord(params)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("viewHistory")}
          // onClick={viewRecord(params)}
          showInMenu
        />,
      ],
    },
  ];
  return (
    <Row style={{ marginTop: "1%" }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <DataGrid
          onRowDoubleClick={(record) => handleEditBooking(record.row)}
          rows={dataSource}
          columns={columns}
          checkboxSelection={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            newRowSelectionModel.length > 0
              ? setVsFooter(true)
              : setVsFooter(false);
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowHeight={120}
          sx={{
            "& .MuiDataGrid-cell": {
              borderRight: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiDataGrid-columnHeader": {
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              backgroundColor: "#e2e2e2",
            },
            "& .MuiDataGrid-columnHeaderTitle ": {
              fontWeight: "bold",
            },
          }}
          slots={{
            footer: () => (
              <>
                <GridPagination />
                {vsFooter ? (
                  <Box>
                    <Row>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        <ClearIcon onClick={() => setVsFooter(false)} />
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        {rowSelectionModel.length} selected
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                      <Space>
                        <Button variant="outlined">
                          {t("Executivetransfer")}
                        </Button>

                        <Button variant="outlined" fullWidth>
                          {t("traLai")}
                        </Button>

                        <Button variant="outlined">{t("CancelBooking")}</Button>

                        <Button variant="outlined">{t("Replication")}</Button>

                        <Button variant="contained" color="error">
                          {t("action.cancel")}
                        </Button>
                      </Space>
                    </Row>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            ),
          }}
          localeText={
            i18n.language === "vi"
              ? viVN.components.MuiDataGrid.defaultProps.localeText
              : enUS
          }
        />
      </Col>
    </Row>
  );
};
export default TableBooking;
