import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row } from "antd";
import { Tabs } from "antd";
import { TabsProps } from "antd";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";

const ModalCauHinhLaiTheoTour = ({ isOpen, onCancel, tourChietTinhId, dataThoiGianAD }) => {
    const { t } = useTranslation();
    const [thoiGianApDung, setThoiGianApDung] = useState(null);
    const [loaiTien, setLoaiTien] = useState(null);
    const [dataThoiGianApDung, setdataThoiGianApDung] = useState([]);
    const [dataLoaiTien, setDataLoaiTien] = useState([]);
    const handleCancels = (e) => {
        e.preventDefault();
        onCancel(false);
    };
    useEffect(() => {
        setdataThoiGianApDung(dataThoiGianAD);
    }, [dataThoiGianAD]);
    const onChange = (key) => {
        console.log(key);
    };

    const tab1 = () => {
        return (
            <div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                        <TextField
                            label={t("hotel.priceRoom.applicationTime")}
                            select
                            fullWidth
                            name="thoiGianApDung"
                            onChange={(e) => setThoiGianApDung(e.target.value)}
                            value={thoiGianApDung}
                        >
                            {dataThoiGianApDung.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.tuNgay + " - " + option.denNgay}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5}></Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                        <TextField
                            select
                            fullWidth
                            name="loaiTien"
                            onChange={(e) => setLoaiTien(e.target.value)}
                            value={loaiTien}
                        >
                            {dataLoaiTien.map((option) => (
                                <MenuItem key={option.id} value={option.maNgoaiTe}>
                                    {option.maNgoaiTe}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                </Row>
                <table></table>
            </div>
        );
    };
    const items = [
        {
            key: "1",
            label: "Tab 1",
            children: tab1(),
        },
        {
            key: "2",
            label: "Tab 2",
            children: "Content of Tab Pane 2",
        },
        {
            key: "3",
            label: "Tab 3",
            children: "Content of Tab Pane 3",
        },
    ];
    return (
        <Modal
            title={t("xemChiTiet") + " - " + t("tour.cauHinhLai")}
            open={isOpen}
            onCancel={handleCancels}
            footer={null}
            width={1000}
            height={700}
        >
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Modal>
    );
};
export default ModalCauHinhLaiTheoTour;
