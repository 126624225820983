import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTOpThucChiById = (data) => {
    return axios.post(API_BASE_URL + "op/layTTOpThucChiById", data, {
        headers: authHeader(),
    });
};

export default {
    layTTOpThucChiById,
};
