import {
  CloseCircleFilled,
  EditFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Box, IconButton } from "@mui/material";
import { Badge, Button, Col, Collapse, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DichVuLeService from "../../../../../api/dieu_hanh/op_rate/DichVuLeService";
import ModalCapNhatKS from "./modalCapNhatDV/ModalCapNhatKS";
import ModalCapNhatNhaHang from "./modalCapNhatDV/ModalCapNhatNhaHang";
import ModalCapNhatOto from "./modalCapNhatDV/ModalCapNhatOto";

import { ReactComponent as BFIcon } from "../../../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../../../assets/img/chiettinh/L.svg";
const DichVuLe = (tongGia) => {
  const { t } = useTranslation();
  const [danhSachDichVuLeRespones, setDanhSachDichVuLeRespones] = useState([]);
  const [tabOpRateTongGiaResponse, setTabOpRateTongGiaResponse] = useState([]);
  const [status, setStatus] = useState("0");
  const [countNoti, setCountNoti] = useState({
    tatCa: 0,
    daHuy: 0,
  });
  const [isOpenOto, setIsOpenOto] = useState(false);
  const [isOpenKs, setIsOpenKs] = useState(false);
  const [isOpenNh, setIsOpenNh] = useState(false);
  const [hanhDong, setHanhDong] = useState("ADD");
  const [tranxOpSaleRateId, setTranxOpSaleRateId] = useState(0);
  const [reset, setReset] = useState(false);
  useEffect(() => {
    getData(3);
  }, [reset]);
  let formatter = new Intl.NumberFormat("en-US", {});
  const getData = async (data) => {
    let response = await DichVuLeService.layDSTTOPRateDichVuLe({
      opThongTinChungId: data,
      trangThai: status,
    });
    if (response.data.code === "00") {
      var danhSachDichVuLeRespones = [];
      for (
        let i = 0;
        i < response.data.data.loaiDichVuDetailsList.length;
        i++
      ) {
        danhSachDichVuLeRespones.push(
          response.data.data.loaiDichVuDetailsList[i]
        );
        danhSachDichVuLeRespones.push({
          dmDichVuId: "NEW",
          loaiDichVu: response.data.data.loaiDichVuDetailsList[i]?.loaiDichVu,
          // tranxOpSaleRateId:
          //   response.data.data.loaiDichVuDetailsList[i]?.tranxOpSaleRateId,
        });
      }
      setCountNoti({
        tatCa: response.data.data.tatCa,
        daHuy: response.data.data.daHuy,
      });
      setDanhSachDichVuLeRespones(danhSachDichVuLeRespones);
      setTabOpRateTongGiaResponse([
        response.data.data.tabOpRateTongGiaResponse,
      ]);
    }
  };
  const handleAddOrUpdateServices = (tranxOpSaleRateId, loaiDichVu) => {
    if (tranxOpSaleRateId) setHanhDong("EDIT");
    else setHanhDong("ADD");
    setTranxOpSaleRateId(tranxOpSaleRateId);
    switch (loaiDichVu) {
      case "Khách sạn":
        return setIsOpenKs(true);
      case "Nhà hàng":
        return setIsOpenOto(true);
      case "Ô tô":
        return setIsOpenNh(true);
      default:
        return null;
    }
  };
  const handleReset = () => {
    setReset(!reset);
  };
  const columns_date = [
    {
      title: "STT",
    },
    {
      title: t("typeService"),
    },
    {
      title: t("supplier"),
    },
    {
      title: t("service"),
    },
    {
      title: t("tour.donGia"),
    },
    {
      title: t("tour.vanChuyen.soLuong"),
    },
    {
      title: t("hotel.roomClass.conditionFoc"),
    },
    {
      title: t("hotel.roomClass.intoMoney"),
    },
    {
      title: t("hotel.priceRoom.applicationTime"),
    },
    {
      title: t("thuevat"),
    },
  ];
  const columnsTG = [
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
    },
    {
      title: t("totalPriceNet/Group"),
      dataIndex: "tongGiaNet",
    },
    {
      title: t("totalPriceSell/Group"),
      dataIndex: "tongGiaBan",
    },
    {
      title: t("loiNhuan"),
      dataIndex: "loiNhuan",
    },
  ];

  const handleDv = (value) => {
    if (value === "Bữa ăn sáng") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <BFIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Bữa ăn trưa") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <LIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Bữa tối") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <DIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    if (value === "Gala dinner") {
      return (
        <div style={{ display: "flex", justifyItems: "center" }}>
          <GDIcon className="css-class" />
          &nbsp; &nbsp; {value}
        </div>
      );
    }
    return value;
  };
  const cauHinh = [
    {
      key: "1",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("bookRetailService")}
        </div>
      ),
      children: (
        <>
          <table>
            <thead>
              {columns_date.map((cl) => {
                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
              })}
            </thead>
            <tbody>
              {danhSachDichVuLeRespones?.map((ds, key) => {
                if (ds.dmDichVuId === "NEW") {
                  return (
                    <tr key={Math.random()}>
                      <td colSpan={10}>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                          <Link
                            underline="none"
                            onClick={() =>
                              handleAddOrUpdateServices(0, ds.loaiDichVu)
                            }
                          >
                            {t("addService")}
                          </Link>
                        </Box>
                      </td>
                    </tr>
                  );
                }
                let liss =
                  ds.loaiDichVu === "Khách sạn"
                    ? ds.loaiDichVuKhachSanDetailsList
                    : ds.loaiDichVuNhaHangOrOtoDetailsList;
                let rowSpan = liss.reduce((sum, item) => {
                  sum += item.thongTinDichVuKhachSan.length;
                  return sum;
                }, 0);
                console.log("rowSpan: " + rowSpan);
                return liss.map((loai, keyL) => {
                  return (
                    <>
                      {loai.thongTinDichVuKhachSan.map((thongtin, keyT) => {
                        return (
                          <>
                            <tr key={Math.random()}>
                              {keyL === 0 && keyT === 0 ? (
                                <>
                                  <td rowSpan={rowSpan + 1}>{key + 1}</td>
                                  <td rowSpan={rowSpan + 1}>{ds.loaiDichVu}</td>
                                </>
                              ) : (
                                <></>
                              )}
                              {keyT === 0 ? (
                                <>
                                  <td
                                    rowSpan={loai.thongTinDichVuKhachSan.length}
                                  >
                                    {loai.nhaCungCap} <InfoCircleOutlined />
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                              <td>
                                <Space
                                  direction="horizontal"
                                  size="small"
                                  style={{ display: "flex" }}
                                >
                                  {handleDv(thongtin.dichVu)}
                                  <IconButton size="small">
                                    <EditFilled
                                      onClick={() =>
                                        handleAddOrUpdateServices(
                                          loai.tranxOpSaleRateId,
                                          ds.loaiDichVu
                                        )
                                      }
                                    />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    // disabled={
                                    //     !item.nccThongTinChungId ? true : false
                                    // }
                                    // onClick={() =>
                                    //     handleClearNCC(dataS, key, id)
                                    // }
                                  >
                                    <CloseCircleFilled />
                                  </IconButton>
                                </Space>
                              </td>
                              <td>{thongtin.donGia}</td>
                              <td>{thongtin.soLuong}</td>
                              {ds.loaiDichVu === "Xe ô tô" ? (
                                <td></td>
                              ) : ds.loaiDichVu === "Khách sạn" &&
                                keyL === 0 &&
                                keyT === 0 ? (
                                <td rowSpan={rowSpan}>
                                  {t("hotel.roomClass.conditionFoc")}:{" "}
                                  {loai.foc.dieuKienFOC}
                                  <br />
                                  {t("tongsofoc")}: {loai.foc.duocFOC}
                                  <br />
                                  {t("tongTienGiam")}:{" "}
                                  {formatter.format(loai.foc.tongTienGiam)}
                                  <br />
                                </td>
                              ) : ds.loaiDichVu === "Nhà hàng" ? (
                                <td>
                                  {t("hotel.roomClass.conditionFoc")}:{" "}
                                  {thongtin.foc.dieuKienFOC}
                                  <br />
                                  {t("tongsofoc")}: {thongtin.foc.duocFOC}
                                  <br />
                                  {t("tongTienGiam")}:{" "}
                                  {formatter.format(thongtin.foc.tongTienGiam)}
                                  <br />
                                </td>
                              ) : (
                                <></>
                              )}
                              <td>
                                {t("tour.tongChiPhi")}:{" "}
                                {formatter.format(
                                  thongtin.thanhTien.tongChiPhiThanhTien
                                )}
                                <br />
                                {t("soTienGiamThanhTien")}:{" "}
                                {formatter.format(
                                  thongtin.thanhTien.soTienGiamThanhTien
                                )}
                                <br />
                                {t("hotel.roomClass.intoMoney")}:{" "}
                                {formatter.format(thongtin.thanhTien.thanhTien)}
                              </td>
                              {keyL === 0 && keyT === 0 ? (
                                <td rowSpan={rowSpan}>{loai.thoiGianSuDung}</td>
                              ) : (
                                <></>
                              )}
                              <td>{thongtin.thueVAT}</td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  );
                });
              })}
            </tbody>
          </table>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("totalPrice")}
        </div>
      ),
      children: (
        <>
          <Table columns={columnsTG} dataSource={tabOpRateTongGiaResponse} />
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Space size={20}>
          <Badge count={countNoti.tatCa} key={0}>
            <Button
              disabled={status === "0"}
              variant="outlined"
              onClick={() => setStatus("0")}
            >
              {t("tatCa")}
            </Button>
          </Badge>
          <Badge count={countNoti.daHuy} key={1}>
            <Button
              disabled={status === "1"}
              variant="outlined"
              onClick={() => setStatus("1")}
            >
              {t("daHuy")}
            </Button>
          </Badge>
        </Space>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Collapse defaultActiveKey={"2"} items={cauHinh} />
        </Col>
      </Row>
      <ModalCapNhatNhaHang
        isOpen={isOpenNh}
        handleCancel={setIsOpenNh}
        hanhDong={hanhDong}
        tranxOpSaleRateId={tranxOpSaleRateId}
        onReset={handleReset}
      />
      <ModalCapNhatKS
        isOpen={isOpenKs}
        handleCancel={setIsOpenKs}
        hanhDong={hanhDong}
        tranxOpSaleRateId={tranxOpSaleRateId}
        onReset={handleReset}
      />
      <ModalCapNhatOto
        isOpen={isOpenOto}
        handleCancel={setIsOpenOto}
        hanhDong={hanhDong}
        tranxOpSaleRateId={tranxOpSaleRateId}
        onReset={handleReset}
      />
    </>
  );
};
export default DichVuLe;
