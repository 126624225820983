import React, { useEffect, useState, useRef } from "react";
import { Table, Typography, Switch, notification, Space, Popconfirm, Input, Button, Row, Col } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import FormPR from "./FormPR";
import Highlighter from "react-highlight-words";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalPrAction from "./ModalPrAction";
import { DataGrid, GridToolbar, viVN, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DichVuCc = ({ dvName, dvId, isView }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [reload, setReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        getData();
    }, [dvId]);
    useEffect(() => {
        if (reload) getData();
    }, [reload]);
    const getData = () => {
        let requestData = {
            nccThongTinChungId: dvId,
        };
        debugger;
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/getData",
            data: requestData,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response dvNhaHang: ====", response.data);
                if (response.status === 200) {
                    setReload(false);
                    let responseData = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];

                    responseData.forEach((item, index) => {
                        let giaPhongDetail = item.tranxDvNhaHangTimeRequests || [];

                        if (giaPhongDetail.length > 0) {
                            giaPhongDetail.forEach((detail) => {
                                dataLst.push({
                                    stt: index + 1,
                                    rowKey: 1 + Math.random() * (100 - 1),
                                    ...item,
                                    ...detail,
                                    id: item.id,
                                    dvNHid: detail.id,
                                    thoiGianApDung: detail.tuNgay + " - " + detail.denNgay,
                                    conditionFoc: detail.foc + "/" + detail.dieuKienFoc,
                                });
                            });
                        } else {
                            dataLst.push({
                                stt: index + 1,
                                rowKey: index + 1,
                                ...item,
                            });
                        }

                        dataLstSearch.push({
                            value: item.id - item.maDichVu - item.tenDichVu,
                        });
                    });
                    // debugger;
                    setDataSearch(dataLstSearch);
                    setDataSource(dataLst);
                }
            })
            .catch(function (error) {
                console.error("Error response: ====", error);
                if (error.response && error.response.status === 401) {
                    // Handle unauthorized error
                }
            });
    };

    const handleBorderChange = (record, value) => {
        let values = {
            id: record.dvNHid,
            trangThaiBooking: !!record.trangThaiBooking,
            hieuLuc: value,
        };
        console.log(values);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                    setReload(true);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(false);
    };
    const handleDelete = (record) => {
        let apiData = {
            id: record.id,
            hieuLuc: false,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                    setReload(true);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const handleCancel = () => {
        setEditingRecord("");
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        console.log(JSON.stringify(values));
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let updatedList = dataSource.map((item) => {
                        values.tranxDvNhaHangTimeRequests.forEach((element) => {
                            if (element.id === item.idGp) {
                                return (item = {
                                    ...item,
                                    nccHangPhongId: values.nccHangPhongId,
                                    dmTyGiaId: values.dmTyGiaId,
                                    loaiTien: values.loaiTien,
                                    maPhong: values.maPhong,
                                    ghiChu: values.ghiChu,
                                    tenDichVu: values.tenDichVu,
                                    maDichVu: values.maDichVu,
                                    tranxDvNhaHangTimeRequests: values.tranxDvNhaHangTimeRequests.length,
                                    nccGiaPhongId: element.nccGiaPhongId,
                                    idGp: element.id,
                                    thoiGianApDung: element.tuNgay + " - " + element.denNgay,
                                    tuNgay: element.tuNgay,
                                    denNgay: element.denNgay,
                                    fitNgayThuongNet: element.fitNgayThuongNet,
                                    fitNgayThuongGiaBan: element.fitNgayThuongGiaBan,
                                    fitCuoiTuanNet: element.fitCuoiTuanNet,
                                    fitCuoiTuanGiaBan: element.fitCuoiTuanGiaBan,
                                    trangThaiBooking: element.trangThaiBooking,
                                });
                            }
                            return item;
                        });
                        return item;
                    });
                    setDataSource(updatedList);
                    setIsShow(status);
                    setAction(status);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.error").toLowerCase() + ". " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    const getColumnSearchProps = (field) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, field)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === field ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const maPhongs = new Set();
    const stts = new Set();
    const hangPhongs = new Set();
    useEffect(() => {
        // https://stackblitz.com/edit/react-bubauz?file=index.js
        // https://stackoverflow.com/questions/61285411/ant-design-table-row-merge-based-on-data-instead-of-index-value
        maPhongs.clear();
        stts.clear();
        hangPhongs.clear();
    }, [hangPhongs, maPhongs, stts]);
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.roomClass.conditionFoc"),
            field: "conditionFoc",
            key: "conditionFoc",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            flex: 1,
            headerAlign: "center",
            align: "center",
            filters: [
                {
                    text: "True",
                    value: true,
                },
                {
                    text: "False",
                    value: false,
                },
            ],
            renderCell: ({ value, row }) => {
                return <Switch defaultChecked={value} onChange={() => handleBorderChange(row, value)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)} />
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),

            children: [{ field: "fitNgayThuongNet" }, { field: "fitNgayThuongGiaBan" }],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),
            children: [{ field: "fitCuoiTuanNet" }, { field: "fitCuoiTuanGiaBan" }],
        },
    ];
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }

    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormPR dataAdd={dataSource} dvId={dvId} setReload={setReload} />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <DataGrid
                onRowDoubleClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                {...dataSource}
                columnGroupingModel={columnGroupingModel}
                autoHeight
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
            />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default DichVuCc;
