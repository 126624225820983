import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getUserList = (data) => {
    return axios.post(API_BASE_URL + "user/getUserList", data, {
        headers: authHeader(),
    });
};

const getUserByRole = (data) => {
    return axios.post(API_BASE_URL + "user/getUserByRole", data, {
        headers: authHeader(),
    });
};
const getRoles = (data) => {
    return axios.post(API_BASE_URL + "user/getRoles", data, {
        headers: authHeader(),
    });
};

const editUser = (data) => {
    return axios.post(API_BASE_URL + "user/edit", data, {
        headers: authHeader(),
    });
};

const addUser = (data) => {
    return axios.post(API_BASE_URL + "user/create", data, {
        headers: authHeader(),
    });
};

export default { getUserByRole, getUserList, getRoles, addUser, editUser };
