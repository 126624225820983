import React, { useEffect, useState } from "react";
import { Space, Input, Modal, Divider, Row, Col, Upload, message, Cascader, Checkbox, Form, Affix } from "antd";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { API_BASE_URL, DM_DICH_VU, maxFile_Tour } from "../../../../../constants";
import { PlusOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MenuItem } from "@mui/material";
import axios from "axios";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/services/tour.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import TourService from "../../../../../api/tour/tour.service";

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ModalChuongTrinhTour = ({ id, ngay, isOpen, handleCancel, handleFinish, title }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [items, setItems] = useState([
        {
            quocGiaId: 0,
            tinhId: 0,
            huyenId: 0,
            xaId: 0,
            diaDiem: "",
            diaChiCuThe: "",
            TinhTp: [],
        },
    ]);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [hanhTrinh, setHanhTrinh] = useState("");
    const [hanhTrinhErrorText, setHanhTrinhErrorText] = useState("");
    const [quocGiaId, setQuocGiaId] = useState(null);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [validatePoint, setValidatePoint] = useState(true);
    const [validateDe, setValidateDe] = useState(true);
    const [noiDung, setNoiDung] = useState("");
    const [diemDi, setDiemDi] = useState({
        quocGiaId: 0,
        tinhId: 0,
        huyenId: 0,
        xaId: 0,
        diaDiem: "",
        diaChiCuThe: "",
    });
    const [height, setHeight] = useState(800);
    const [heightScroll, setHeightScroll] = useState(120);
    const [className, setClassName] = useState("diem-di");
    const [plainOptions, setPlainOptions] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [diemDiError, setDiemDiError] = useState("");
    const [diemDenError, setDiemDenError] = useState("");
    const { t } = useTranslation();
    useEffect(() => {
        let data = {
            id: id,
        };
        TourService.getByIdChuongTrinhTour(data)
            .then(function (response) {
                // console.log("Response: getByIdChuongTrinhTour====" + response.data.data.dmDichVus);
                if (response.status === 200 && response.data.code === "00") {
                    let imgData = [];
                    let dataQMP = [];
                    let data = response.data.data;
                    setNoiDung(data.noiDung);
                    setHanhTrinh(data.hanhTrinh);
                    setDiemDi(data.diemDi);
                    setQuocGiaId(data.diemDi.quocGiaId);
                    if (data.diemDen.length > 0) {
                        setItems(data.diemDen);
                    }
                    if (data.imgFile.length > 0) {
                        for (let i = 0; i < data.imgFile.length; i++) {
                            imgData.push({
                                id: data.imgFile[i].id,
                                uid: data.imgFile[i].id,
                                name: data.imgFile[i].tenFile,
                                status: "done",
                                thumbUrl: "data:image/png;base64," + data.imgFile[i].base64Value,
                            });
                        }
                    }

                    if (data.dmDichVus.length > 0) {
                        for (let j = 0; j < data.dmDichVus.length; j++) {
                            const mappedValue = DM_DICH_VU[data.dmDichVus[j].tenDichVu];
                            if (mappedValue !== undefined) {
                                dataQMP.push(mappedValue);
                            }
                        }
                        setCheckedList(dataQMP);
                    }
                    setFileList(imgData);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [id]);
    const onFinish = (e) => {
        e.preventDefault();
        if (!diemDi.quocGiaId) {
            setValidatePoint(false);
            require("../../../../../assets/css/services/modalChuongTrinhTour.css");
            setDiemDiError(t("validate") + " " + t("point").toLowerCase() + "!");
        } else {
            setValidatePoint(true);
            setDiemDiError("");
        }
        if (!items[0].diaDiem) {
            setValidateDe(false);
            setDiemDenError(t("validate") + " " + t("trip").toLowerCase() + "!");
        } else {
            setValidateDe(true);
            setDiemDenError("");
        }
        if (!hanhTrinh) {
            setHanhTrinhErrorText(t("validate") + " " + t("trip").toLowerCase() + "!");
        } else {
            setHanhTrinhErrorText("");
        }
        if (diemDi.diaDiem && hanhTrinh && items[0].diaDiem) {
            let dmDichVus = [];
            if (checkedList.length > 0) {
                for (let i = 0; i < checkedList.length; i++) {
                    dmDichVus.push({
                        id: checkedList[i],
                        tenDichVu: plainOptions[i].label,
                    });
                }
            }
            let dataImg = [];
            if (fileList.length > 0) {
                for (let j in fileList) {
                    dataImg.push({
                        // ten_file: fileList[j].name,
                        // base64Value: fileList[j].thumbUrl.split("base64,")[1],
                        id: fileList[j].id !== undefined ? fileList[j].id : 0,
                        tenFile: fileList[j].name,
                        base64Value: fileList[j].thumbUrl.split("base64,")[1],
                    });
                }
            }
            const diemDen = items.map((item) => {
                return {
                    quocGiaId: item.quocGiaId,
                    tinhId: item.tinhId,
                    huyenId: item.huyenId,
                    xaId: item.xaId,
                    diaDiem: item.diaDiem,
                    diaChiCuThe: item.diaChiCuThe,
                };
            });
            let values = {
                id: id,
                hanhTrinh: hanhTrinh,
                noiDung: noiDung,
                dmDichVus: dmDichVus,
                diemDi: diemDi,
                diemDen: diemDen,
                imgFile: dataImg,
            };
            let text = ''
            if (parseInt(id) === 0){
                text = t("add")
            }else {
                text = t("edit")
            }
            handleFinish(false, values, text);
        }
    };
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setPlainOptions(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const handleCancels = () => {
        handleCancel();
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response:11 ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);

    const handleAddField = () => {
        console.log(items);
        setItems([
            ...items,
            {
                quocGiaId: 0,
                tinhId: 0,
                huyenId: 0,
                xaId: 0,
                diaDiem: "",
                diaChiCuThe: "",
                TinhTp: [],
            },
        ]);
        setClassName("diem-di");
        setHeight(height + 100);
        setHeightScroll(heightScroll + 100);
    };

    const handleRemoveField = (index) => {
        if (items.length === 2) {
            setClassName("");
        }
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        setHeight(height - 100);
        setHeightScroll(heightScroll - 100);
    };

    const handleItemChange = (name, index, value) => {
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
    };
    const handleChangeQG_DiemDi = (e) => {
        setQuocGiaId(e.target.value);
        setDiemDi({});
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {
                                        value: o.quan_huyen,
                                        label: o.quan_huyen,
                                        id: o.dm_huyen_id,
                                        children: [],
                                    };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleChangeQG_item = (e, i) => {
        const newItems = [...items];
        newItems[i].quocGiaId = e.target.value;
        newItems[i].diaDiem = "";
        setItems(newItems);
        // handleItemChange(e.target.name, i, e.target.value);
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {
                                        value: o.quan_huyen,
                                        label: o.quan_huyen,
                                        id: o.dm_huyen_id,
                                        children: [],
                                    };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        handleItemChange("TinhTp", i, result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const onChangeCascader_item = (value, selectedOptions, index) => {
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    handleItemChange("tinhId", index, selectedOptions[i].id);
                }
                if (i === 1) {
                    handleItemChange("huyenId", index, selectedOptions[i].id);
                }
                if (i === 2) {
                    handleItemChange("xaId", index, selectedOptions[i].id);
                }
                handleItemChange("diaDiem", index, value.join(","));
            }
        }
    };
    const onChangeCascader_diemDi = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        let tinhId = 0;
        let huyenId = 0;
        let xaId = 0;
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    tinhId = selectedOptions[i].id;
                }
                if (i === 1) {
                    huyenId = selectedOptions[i].id;
                }
                if (i === 2) {
                    xaId = selectedOptions[i].id;
                }
            }
        }
        setDiemDi({
            quocGiaId: quocGiaId,
            diaDiem: value.join(","),
            tinhId: tinhId,
            huyenId: huyenId,
            xaId: xaId,
            diaChiCuThe: "",
        });
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const onChangeCheckBox = (list) => {
        setCheckedList(list);
    };
    const checkBoxRender = () => {
        let html;
        let checkBox = [];
        for (let i = 0; i < plainOptions.length; i++) {
            checkBox.push(
                <Col offset={1} span={5} style={{ marginTop: "10px" }}>
                    <Checkbox key={i} value={plainOptions[i].value} label={plainOptions[i].label}>
                        {plainOptions[i].label}
                    </Checkbox>
                </Col>
            );
        }
        html = (
            <Checkbox.Group
                style={{
                    width: "92%",
                }}
                onChange={onChangeCheckBox}
                value={checkedList}
            >
                <Row
                    style={{
                        height: "50px",
                        borderRadius: "6px",
                        border: "1px solid #d9d9d9",
                    }}
                >
                    {checkBox}
                </Row>
            </Checkbox.Group>
        );
        return html;
    };
    const handleValueCascader = () => {
        let data = [];
        if (diemDi !== null) {
            if (diemDi.diaDiem != undefined) {
                data = diemDi.diaDiem.split(",");
            }
        }
        return data;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const dataRender = () => {
        let html;
        html = (
            <Space className={className} direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <Row style={{ marginTop: "2%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    disabled={true}
                                    fullWidth
                                    label={t("day")}
                                    value={ngay}
                                />
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={2}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile_Tour ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                onCancel={(e) => setPreviewOpen(false)}
                            >
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                        <TextField
                            name="tenTour"
                            id="tenTour"
                            sx={{
                                marginBottom: 2,
                            }}
                            required
                            multiline
                            fullWidth
                            label={t("trip")}
                            value={hanhTrinh}
                            onChange={(e) => setHanhTrinh(e.target.value)}
                            error={!!hanhTrinhErrorText}
                            helperText={hanhTrinhErrorText}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={noiDung}
                            onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setNoiDung(data);
                                setClassName("diem-di");
                                console.log("========ckeditor====", { event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <Space.Compact
                            size="large"
                            style={{
                                width: "100%",
                                marginBottom: 15,
                                height: "56px",
                            }}
                            className=""
                        >
                            <Input
                                style={{
                                    width: "25%",
                                    borderRadius: "4px",
                                }}
                                readOnly
                                defaultValue={t("point") + " *"}
                            />
                            <TextField
                                xs={{
                                    width: "75%",
                                    borderRadius: "4px",
                                }}
                                label={t("location.country")}
                                select
                                fullWidth
                                name="quocGiaId"
                                onChange={(e) => handleChangeQG_DiemDi(e)}
                                value={quocGiaId}
                                error={!!diemDiError}
                            >
                                {dataQuocGia.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Space.Compact>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Form.Item validateStatus={!!diemDiError ? "error" : ""}>
                            <Cascader
                                style={{
                                    width: "100%",
                                    borderRadius: "4px",
                                }}
                                size="large"
                                options={dataTinhTP}
                                onChange={onChangeCascader_diemDi}
                                showSearch={{
                                    filterCascader,
                                }}
                                defaultValue={handleValueCascader}
                                placeholder={
                                    t("location.city") + " - " + t("location.district") + " - " + t("location.wards")
                                }
                                value={diemDi.diaDiem ? diemDi.diaDiem?.split(",") : []}
                                onSearch={(value) => console.log(value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div
                    hidden={validatePoint}
                    style={{
                        marginTop: "-30px",
                        marginLeft: "20px",
                        color: "#d32f2f",
                    }}
                >
                    {t("validate") + " " + t("point").toLowerCase() + "!"}
                </div>
                <Row style={{ height: heightScroll }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                        {items.map((item, index) => (
                            <Row key={index} style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                    <Space.Compact
                                        size="large"
                                        style={{
                                            width: "100%",
                                            marginBottom: 15,
                                            height: "56px",
                                        }}
                                    >
                                        <Input
                                            style={{
                                                width: "25%",
                                                borderRadius: "4px",
                                            }}
                                            readOnly
                                            defaultValue={t("destination") + " *"}
                                        />
                                        <TextField
                                            xs={{
                                                width: "70%",
                                                borderRadius: "4px",
                                            }}
                                            label={t("location.country")}
                                            select
                                            fullWidth
                                            name="quocGiaId"
                                            onChange={(event) => handleChangeQG_item(event, index)}
                                            value={item.quocGiaId}
                                            error={!!diemDenError}
                                        >
                                            {dataQuocGia.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Space.Compact>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Form.Item validateStatus={!!diemDenError ? "error" : ""}>
                                        <Cascader
                                            style={{
                                                width: "100%",
                                                borderRadius: "4px",
                                            }}
                                            size="large"
                                            options={item.TinhTp}
                                            onChange={(value, selectedOptions) =>
                                                onChangeCascader_item(value, selectedOptions, index)
                                            }
                                            showSearch={{
                                                filterCascader,
                                            }}
                                            placeholder={
                                                t("location.city") +
                                                " - " +
                                                t("location.district") +
                                                " - " +
                                                t("location.wards")
                                            }
                                            onSearch={(value) => console.log(value)}
                                            value={item.diaDiem.length > 0 ? item.diaDiem.split(",") : []}
                                        />
                                    </Form.Item>
                                </Col>
                                {items.length > 1 ? (
                                    <RemoveCircleOutlineIcon
                                        style={{ marginTop: "15px", marginLeft: "10px" }}
                                        onClick={(e) => handleRemoveField(index)}
                                    />
                                ) : null}
                            </Row>
                        ))}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                        <AddBoxIcon
                            style={{ marginTop: "15px", marginLeft: "10px" }}
                            onClick={() => handleAddField()}
                        />
                    </Col>
                </Row>
                <div
                    hidden={validateDe}
                    style={{
                        marginTop: "-80px",
                        marginLeft: "20px",
                        color: "#d32f2f",
                    }}
                >
                    {t("validate") + " " + t("destination").toLowerCase() + "!"}
                </div>

                {checkBoxRender()}
            </Space>
        );
        return html;
    };
    // const isEmpty = (record) => {
    //     for (const prop in record) {
    //         if (Object.hasOwn(record, prop)) {
    //             return false;
    //         }
    //     }
    //
    //     return true;
    // };
    const handleClear = () => {};
    return (
        <Modal
            title={t(title) + " " + t("trip").toLowerCase()}
            open={isOpen}
            onOk={onFinish}
            onCancel={handleCancels}
            footer={null}
            width={1300}
            bodyStyle={{ height: height }}
        >
            <Divider />
            <Box component="form" noValidate>
                {dataRender()}

                <Affix offsetBottom={50}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    height: 60,
                                    paddingInline: 50,
                                    lineHeight: "60px",
                                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                                    borderRadius: "4px",
                                }}
                            >
                                <Space>
                                    <Button
                                        variant="contained"
                                        onClick={onFinish}
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        {t("action.ok")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAltIcon />}
                                        onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={handleCancels}
                                        key="clear"
                                    >
                                        {t("action.cancel")}
                                    </Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Affix>
            </Box>
        </Modal>
    );
};
export default ModalChuongTrinhTour;
