import { PlusOutlined } from "@ant-design/icons";
import { Affix, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import Save from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ModalTkAction = ({ isOpen, handleCancel, handleFinish, record, action }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tenNH, setTenNH] = useState([]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNganHang",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].name,
                                });
                            }
                        }
                        setTenNH(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const onFinish = (values) => {
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const [fileList, setFileList] = useState([]);
    const handleCancelImg = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const dataRender = () => {
        let html;
        let dataNHID = 0;
        let fileImg = [];
        if (!isEmpty(record)) {
            let apiData = {
                id: record.id,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "ncc/tknh/getDataById",
                data: apiData,
                headers: authHeader(),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        console.log("Response: ====" + data);
                        form.setFieldsValue({
                            id: data.id,
                            dmNganHangId: data.dmNganHangId,
                            tenNganHang: data.tenNganHang,
                            soTaiKhoan: data.soTaiKhoan,
                            chuSoHuu: data.chuSoHuu,
                            chiNhanh: data.chiNhanh,
                            maSwift: data.maSwift,
                            qrPath: data.qrPath,
                            base64Value: data.base64Value,
                        });
                        dataNHID = data.dmNganHangId;
                        fileImg.push({
                            uid: "-1",
                            name: data.soTaiKhoan + ".png",
                            status: "done",
                            thumbUrl: "data:image/png;base64," + data.base64Value,
                        });
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col xs={17} sm={17} md={17} lg={17} xl={17}>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <Form.Item
                                    name="dmNganHangId"
                                    label={t("hotel.accountBanking.bank")}
                                    {...formItemLayoutBank}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("validate") + " " + t("hotel.accountBanking.bank").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare((optionB?.label ?? "").toLowerCase())
                                        }
                                        defaultValue={tenNH[dataNHID]}
                                        options={tenNH}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                <Form.Item
                                    name="soTaiKhoan"
                                    label={t("hotel.accountBanking.accountNumber")}
                                    {...formItemLayout}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                t("validate") +
                                                " " +
                                                t("hotel.accountBanking.accountNumber").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                <Form.Item
                                    name="chuSoHuu"
                                    label={t("hotel.accountBanking.accountOwner")}
                                    {...formItemLayout}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                t("validate") +
                                                " " +
                                                t("hotel.accountBanking.accountOwner").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <Form.Item
                                    name="chiNhanh"
                                    label={t("hotel.accountBanking.branch")}
                                    {...formItemLayoutBank}
                                    rules={[
                                        {
                                            required: false,
                                            message:
                                                t("validate") + " " + t("hotel.accountBanking.branch").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <Form.Item
                                    name="maSwift"
                                    label={t("hotel.accountBanking.swiftCode")}
                                    {...formItemLayoutBank}
                                    rules={[
                                        {
                                            required: false,
                                            message:
                                                t("validate") + " " + t("hotel.accountBanking.swiftCode").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <Form.Item
                                    name="ghiChu"
                                    label={t("notes")}
                                    {...formItemLayoutBank}
                                    rules={[
                                        {
                                            required: false,
                                            message: t("validate") + " " + t("notes").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileImg.length > 0 ? fileImg : fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelImg}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const handleGenQrCode = () => {};
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutMax = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 13,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutBank = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 5,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 19,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("hotel.accountBanking").toLowerCase() : t("hotel.accountBanking")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
                bodyStyle={{ height: 400 }}
            >
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    {...formItemLayout}
                    initialValues={{
                        remember: true,
                    }}
                >
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        height: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="clear"
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button key="back" onClick={handleCancels}>
                                            {t("action.cancel")}
                                        </Button>
                                        <Button onClick={handleGenQrCode} type="dashed">
                                            {t("hotel.accountBanking.genQrCode")}
                                        </Button>
                                    </Space>
                                </div>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalTkAction;
