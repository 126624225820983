import React from "react";
import "../../assets/css/auth/login.css";
import { API_BASE_URL, HEADERS, ACCESS_TOKEN, key_crypt } from "../../constants";
import axios from "axios";
import { setStorage } from "../../api/BaseApi";
import LoginForm from "./LoginForm";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';



const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const sendLogin = (values) => {
        // console.log('Received values of form: ', values);
        let data = JSON.stringify({
            username: values.username,
            password: values.password,
        });
        axios({
            method: "post",
            url: API_BASE_URL + "login",
            data: data,
            headers: HEADERS,
        })
            .then(function (response) {
                if (response.status === 200) {
                    let datRes = response.data;
                    let data_encrypted = CryptoJS.AES.encrypt(JSON.stringify(datRes), key_crypt).toString();
                    let data_token= CryptoJS.AES.encrypt(datRes.accessToken, key_crypt).toString();
                    setStorage("auth", data_encrypted);
                    setStorage(ACCESS_TOKEN, data_token);
                    setStorage("USERNAME", datRes.username);
                    dispatch({ type: 'LOGIN_SUCCESS', payload: datRes });
                    navigate('/');
                    api["success"]({
                        message: t("stour"),
                        description: t("login") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                dispatch({ type: 'LOGIN_FAIL', payload: response });
                api["error"]({
                    message: t("stour"),
                    description: t("login") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };

    return (
        <div>
            {" "}
            {contextHolder}
            <LoginForm sendLogin={sendLogin} />
        </div>
    );
};

export default Login;
