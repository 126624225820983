import React, { useEffect, useState, useRef } from "react";
import { Table, Typography, Switch, notification, Space, Popconfirm, Input, Button, Row, Col } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import FormPR from "./FormPR";
import Highlighter from "react-highlight-words";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalPrAction from "./ModalPrAction";
import { DataGrid, GridToolbar, viVN, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DichVuCungCap = ({ dvName, dvId, isView }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/vexebus/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            let giaPhongDetail = data[i].tranxVeXeBusRequests;
                            if (giaPhongDetail.length > 0) {
                                for (let k = 0; k < giaPhongDetail.length; k++) {
                                    dataLst.push({
                                        stt: i + 1,
                                        rowKey: 1 + Math.random() * (100 - 1),
                                        id: data[i].id,
                                        nccThongTinChungId: data[i].nccThongTinChungId,
                                        maDichVu: data[i].maDichVu,
                                        tenDichVu: data[i].tenDichVu,
                                        dmTyGiaId: data[i].dmTyGiaId,
                                        loaiTien: data[i].maNgoaiTe,
                                        tranxVeXeBusRequests: data[i].tranxVeXeBusRequests.length,
                                        nncDichVuNhId: giaPhongDetail[k].nncDichVuNhId,
                                        thoiGianApDung: giaPhongDetail[k].tuNgay + " - " + giaPhongDetail[k].denNgay,
                                        tuNgay: giaPhongDetail[k].tuNgay,
                                        denNgay: giaPhongDetail[k].denNgay,
                                        giaNet: giaPhongDetail[k].giaNet,
                                        giaBan: giaPhongDetail[k].giaBan,
                                        ve: giaPhongDetail[k].ve,
                                        foc: giaPhongDetail[k].foc,
                                        trangThaiBooking: giaPhongDetail[k].trangThaiBooking,
                                        hieuLuc: giaPhongDetail[k].hieuLuc,
                                    });
                                }
                            } else {
                                dataLst.push({
                                    stt: i,
                                    rowKey: i++,
                                    id: data[i].id,
                                    nccThongTinChungId: data[i].nccThongTinChungId,
                                    maDichVu: data[i].maDichVu,
                                    tenDichVu: data[i].tenDichVu,
                                    loaiTien: data[i].maNgoaiTe,
                                    dmTyGiaId: data[i].dmTyGiaId,
                                    ghiChu: data[i].ghiChu,
                                });
                            }
                            dataLstSearch.push({
                                value: data[i].id - data[i].maDichVu - data[i].tenDichVu,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [dvId]);
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
    };
    const handleBorderChange = (record) => {
        let values = {
            id: record.idGp,
            trangThaiBooking: record.trangThaiBooking === true ? false : true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/vexebus/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc == true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/vexebus/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
        console.log(JSON.stringify(values));
        debugger;
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/vexebus/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let updatedList = dataSource.map((item) => {
                        values.tranxVeXeBusRequests.forEach((element) => {
                            if (element.id === item.idGp) {
                                return (item = {
                                    ...item,
                                    nccHangPhongId: values.nccHangPhongId,
                                    dmTyGiaId: values.dmTyGiaId,
                                    loaiTien: values.loaiTien,
                                    maPhong: values.maPhong,
                                    ghiChu: values.ghiChu,
                                    tenDichVu: values.tenDichVu,
                                    maDichVu: values.maDichVu,
                                    tranxVeXeBusRequests: values.tranxVeXeBusRequests.length,
                                    nccGiaPhongId: element.nccGiaPhongId,
                                    idGp: element.id,
                                    thoiGianApDung: element.tuNgay + " - " + element.denNgay,
                                    tuNgay: element.tuNgay,
                                    denNgay: element.denNgay,
                                    giaBan: element.giaBan,
                                    giaNet: element.giaNet,
                                    foc: element.foc,
                                    ve: element.ve,
                                    trangThaiBooking: element.trangThaiBooking,
                                });
                            }
                            return item;
                        });
                        return item;
                    });
                    setDataSource(updatedList);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    const getColumnSearchProps = (field) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, field)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === field ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const maPhongs = new Set();
    const stts = new Set();
    const hangPhongs = new Set();
    useEffect(() => {
        // https://stackblitz.com/edit/react-bubauz?file=index.js
        // https://stackoverflow.com/questions/61285411/ant-design-table-row-merge-based-on-data-instead-of-index-value
        maPhongs.clear();
        stts.clear();
        hangPhongs.clear();
    }, [hangPhongs, maPhongs, stts]);
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            width: 60,
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            align: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "giaNet",
            key: "giaNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "giaBan",
            key: "giaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            align: "center",
            renderCell: ({ value, row }) => {
                return <Switch defaultChecked={value} onChange={() => handleBorderChange(row)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)} />
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            align: "center",
        },
    ];

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormPR dataAdd={dataSource} />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            <DataGrid
                onRowClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                autoHeight
                pageSizeOptions={[10]}
                // columnGroupingModel={columnGroupingModel}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
            />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default DichVuCungCap;
