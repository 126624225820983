import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Affix, Col, Collapse, Row, Space, Table } from "antd";
import { InputAdornment, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import TableDvTour from "./TableDvTour";
import TableDvLe from "./TableDvLe";
import { useNavigate } from "react-router";
import ChiPhiUocTinhService from "../../../../api/booking/ChiPhiUocTinhService";
import { ReactComponent as BFIcon } from "../../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../../assets/img/chiettinh/L.svg";
import { ReactComponent as CIcon } from "../../../../assets/img/chiettinh/C.svg";

const ChiPhiUocTinh = ({ bookingId, action }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [dichVuTours, setDichVuTours] = useState([]);
  const [dichVuLes, setDichVuLes] = useState([]);
  const [tongKet, setTongKet] = useState([]);

  useEffect(() => {
    let dataReq = {
      bkThongTinChungId: bookingId,
      textSearch: "",
    };
    getChiPhiUocTinh(dataReq);
  }, [bookingId]);

  const getChiPhiUocTinh = (dataReq) => {
    ChiPhiUocTinhService.getTTChiPhiUocTinhByBkThongTinChungId(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          let formatter = new Intl.NumberFormat("en-US", {});
          const lstDichVuTour = res.data.data?.dichVuTours.map((item, idx) => {
            return {
              ...item,
              stt: idx + 1,
              giaNet: formatter.format(item.giaNet),
              thanhTien: formatter.format(item.thanhTien),
            };
          });
          const lstDichVuLe = res.data.data?.chiPhiUocTinhs.map((item, idx) => {
            return {
              ...item,
              stt: idx + 1,
              giaNet: formatter.format(item.giaNet),
              thanhTien: formatter.format(item.thanhTien),
            };
          });

          let lstTongKet = [
            {
              title: t("chiPhiUocTinh"),
              doanhThu: formatter.format(res.data.data.doanhThuUocTinh),
              tongChi: formatter.format(res.data.data.tongChiUocTinh),
              loiNhuan: formatter.format(res.data.data.loiNhuanUocTinh),
            },
            {
              title: t("chiPhiThucThu"),
              doanhThu: formatter.format(res.data.data.doanhThuThucThu),
              tongChi: formatter.format(res.data.data.tongChiThucThu),
              loiNhuan: formatter.format(res.data.data.loiNhuanThucThu),
            },
          ];

          setDichVuTours(lstDichVuTour);
          setDichVuLes(lstDichVuLe);
          setTongKet(lstTongKet);
        }
      })
      .catch((res) =>
        console.log("Error getTTChiPhiUocTinhByBkThongTinChungId " + res)
      );
  };

  const handleSearch = () => {
    let dataReq = { bkThongTinChungId: bookingId, textSearch: textSearch };
    getChiPhiUocTinh(dataReq);
  };

  const handleContinue = (act) => {
    if (act === "end") {
      navigate("/dat-dich-vu");
    } else if (action === "edit") {
      navigate("/dat-dich-vu/chuong-trinh-tour/" + action + "?id=" + bookingId);
    } else {
      navigate("/dat-dich-vu/chuong-trinh-tour/" + action);
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 50,
      type: "number",
    },
    {
      title: t("typeService"),
      dataIndex: "loaiDichVu",
      key: "loaiDichVu",
      align: "center",
      flex: 1,
    },
    {
      title: t("supplier"),
      dataIndex: "nhaCungCap",
      key: "nhaCungCap",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("service"),
      dataIndex: "dichVu",
      key: "dichVu",
      align: "center",
      flex: 1,
      headerAlign: "center",
      render: (value, record) => {
        if (record.loaiDichVu === "Nhà hàng") {
          if (value.indexOf("Bữa ăn sáng") !== -1) {
            return (
              <div style={{ display: "flex", justifyItems: "center" }}>
                <BFIcon className="css-class" />
                &nbsp;{value}
                {value.indexOf("tuổi") !== -1 ? (
                  <span>
                    &nbsp;
                    <CIcon className="css-class" />
                  </span>
                ) : null}
              </div>
            );
          }
          if (value.indexOf("Bữa ăn trưa") !== -1) {
            return (
              <div style={{ display: "flex", justifyItems: "center" }}>
                <LIcon className="css-class" />
                &nbsp;{value}
                {value.indexOf("tuổi") !== -1 ? (
                  <span>
                    &nbsp;
                    <CIcon className="css-class" />
                  </span>
                ) : null}
              </div>
            );
          }
          if (value.indexOf("Bữa tối") !== -1) {
            return (
              <div style={{ display: "flex", justifyItems: "center" }}>
                <DIcon className="css-class" />
                &nbsp;{value}
                {value.indexOf("tuổi") !== -1 ? (
                  <span>
                    &nbsp;
                    <CIcon className="css-class" />
                  </span>
                ) : null}
              </div>
            );
          }
          if (value.indexOf("Gala dinner") !== -1) {
            return (
              <div style={{ display: "flex", justifyItems: "center" }}>
                <GDIcon className="css-class" />
                &nbsp;{value}{" "}
                {value.indexOf("tuổi") !== -1 ? (
                  <span>
                    &nbsp;
                    <CIcon className="css-class" />
                  </span>
                ) : null}
              </div>
            );
          } else {
            {
              return value.indexOf("tuổi") !== -1 ? (
                <div style={{ display: "flex", justifyItems: "center" }}>
                  <span> {value} &nbsp;</span>
                  <CIcon className="css-class" />
                </div>
              ) : (
                <>{value}</>
              );
            }
          }
        } else if (value.indexOf("tuổi") !== -1) {
          return (
            <div style={{ display: "flex", justifyItems: "center" }}>
              <span> {value} &nbsp; &nbsp;</span>
              <CIcon className="css-class" />
            </div>
          );
        } else return <>{value}</>;
      },
    },
    {
      title: t("address"),
      dataIndex: "diaChi",
      key: "diaChi",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("booking.ngaySuDung"),
      dataIndex: "ngaySuDung",
      key: "ngaySuDung",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("hotel.priceClass.giaNet"),
      dataIndex: "giaNet",
      key: "giaNet",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("tour.vanChuyen.soLuong"),
      dataIndex: "soLuong",
      key: "soLuong",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("hotel.roomClass.foc"),
      dataIndex: "foc",
      key: "foc",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("donVi"),
      dataIndex: "donVi",
      key: "donVi",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
      key: "thanhTien",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
  ];
  const columnsTongChiPhi = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "26%",
      render: (text, record) => {
        return (
          <>
            <div style={{ fontWeight: "bold" }}>{text}</div>
            {text === t("chiPhiUocTinh") ? (
              <div>{"(" + t("VATIncluded") + ")"}</div>
            ) : null}
          </>
        );
      },
    },
    {
      title: t("revenue"),
      dataIndex: "doanhThu",
      key: "doanhThu",
      align: "center",
      flex: 1,
    },
    {
      title: t("totalSpending"),
      dataIndex: "tongChi",
      key: "tongChi",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      title: t("loiNhuan"),
      dataIndex: "loiNhuan",
      key: "loiNhuan",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("service") + " Tour"}
        </div>
      ),
      children: (
        <>
          <TableDvTour columns={columns} dataSource={dichVuTours} />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("dichVuLe")}
        </div>
      ),
      children: (
        <>
          <TableDvLe columns={columns} dataSource={dichVuLes} />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <div
          style={{
            textTransform: "uppercase",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {t("tour.tongChiPhi")}
        </div>
      ),
      children: (
        <>
          <Table
            style={{ width: "100%" }}
            bordered
            dataSource={tongKet}
            columns={columnsTongChiPhi}
            pagination={false}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <Row style={{ marginTop: "1%" }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={10}>
          <Space size={20}>
            <OutlinedInput
              variant="outlined"
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              size="small"
              placeholder={t("search")}
              value={textSearch}
              onChange={(e) => setTextSearch(e.target.value)}
            />

            <Button
              variant="contained"
              onClick={handleSearch}
              startIcon={<Search />}
              color="success"
            >
              {t("search")}
            </Button>
          </Space>
        </Col>
      </Row>
      <Collapse
        style={{ marginTop: "1%" }}
        defaultActiveKey={"1"}
        items={items}
      />
      <Affix offsetBottom={50}>
        <Row>
          <Col span={12} />
          <Col span={12}>
            <div
              style={{
                textAlign: "center",
                color: "#fff",
                height: 60,
                paddingInline: 50,
                lineHeight: "60px",
                backgroundColor: "rgba(150, 150, 150, 0.2)",
                borderRadius: "4px",
              }}
            >
              <Space>
                <Button
                  variant="contained"
                  onClick={() => handleContinue("end")}
                  type="end"
                  // startIcon={<SaveIcon />}
                >
                  {t("action.end")}
                </Button>
                <Button
                  variant="outlined"
                  // startIcon={<DeleteIcon />}
                  onClick={() => handleContinue("continue")}
                  key="continue"
                >
                  {t("action.continue")}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Affix>
    </>
  );
};
export default ChiPhiUocTinh;
