import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";
const layTTDanhSachRateDichVuTour = (data) => {
  return axios.post(API_BASE_URL + "op/layTTDanhSachRateDichVuTour", data, {
    headers: authHeader(),
  });
};
const getDataNhomNgLonVaTreEm = (data) => {
  return axios.post(
    API_BASE_URL + "op/layTTDSNhomNguoiLonAndTreEmCuaDichVuTour",
    data,
    {
      headers: authHeader(),
    }
  );
};
export default { layTTDanhSachRateDichVuTour, getDataNhomNgLonVaTreEm };
