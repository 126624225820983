import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { createBrowserHistory } from "history";


const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];



const store = createStore(
    reducers,
    compose(
        applyMiddleware(...middlewares))
);
// eslint-disable-next-line import/no-anonymous-default-export
export default { store, history };

