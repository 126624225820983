import axios from "axios";
import authHeader from "../../../auth_header";
import { API_BASE_URL } from "../../../../constants";

const getChietTinhSaleNhaHang = (data) => {
    return axios.post(API_BASE_URL + "baogia/layTTCTSaleNhaHangById", data, {
        headers: authHeader(),
    });
};
const saveChietTinhSaleNhaHang = (data) => {
    return axios.post(API_BASE_URL + "baogia/saveCTSaleNhaHang", data, {
        headers: authHeader(),
    });
};

const saveChietTinhDoTuoi = (data) => {
    return axios.post(API_BASE_URL + "baogia/saveChietTinhDoTuoiTreEmSaleNhaHang", data, {
        headers: authHeader(),
    });
};

const deleteChietTinhDoTuoi = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteCTDoTuoiTreEmSaleNhaHang", data, {
        headers: authHeader(),
    });
};
const deleteCTSaleNhaHang = (data) => {
    return axios.post(API_BASE_URL + "baogia/deleteCTSaleNhaHang", data, {
        headers: authHeader(),
    });
};
export default {
    getChietTinhSaleNhaHang,
    saveChietTinhSaleNhaHang,
    saveChietTinhDoTuoi,
    deleteChietTinhDoTuoi,
    getChietTinhSaleNhaHang,
    saveChietTinhSaleNhaHang,
    deleteCTSaleNhaHang,
};
