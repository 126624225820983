import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";


const getDanhSachQuocGia = (data) => {
    return axios.post(API_BASE_URL + "category/danhSachQuocGia", data,  { headers: authHeader() });
};

export default {
    getDanhSachQuocGia,

}