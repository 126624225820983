import React, { useState } from "react";
import { Button, Col, Row, Space, Input, AutoComplete } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ModalTypeTour from "./ModalTypeTour";
import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import authHeader from "../../../api/auth_header";

const FormTT = ({ dataAdd, deleteLst, handleSearch, dataSearch }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = (status, values) => {
        setIsModalOpen(false);
        let data = {
            loai_tour: values.loai_tour,
            mo_ta: values.mo_ta,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/saveDmLoaiTour",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let data = response.data.data;
                let dataLst = [];
                let dataLstSearch = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        dataLst.push({
                            stt: i + 1,
                            id: data[i].id,
                            loai_tour: data[i].loai_tour,
                            mo_ta: data[i].mo_ta,
                            hieu_luc: data[i].hieu_luc,
                        });
                        dataLstSearch.push({
                            value: data[i].id + "- " + data[i].loai_tour + " " + data[i].mo_ta,
                        });
                    }
                    dataAdd(dataLst, dataLstSearch);
                }
            }
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onSearch = (value) => {
        handleSearch(value);
    };
    const deleteClick = () => {
        deleteLst();
    };
    return (
        <Row>
            <Col span={6}></Col>
            <Col span={6} offset={12}>
                <Space size="large">
                    <Button icon={<DeleteOutlined />} type="primary" danger onClick={deleteClick}>
                        {t("delete")}
                    </Button>
                    <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                        {t("add")}
                    </Button>
                    <ModalTypeTour isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleOk} />
                </Space>
            </Col>
        </Row>
    );
};
export default FormTT;
