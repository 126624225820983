import { DataGrid } from "@mui/x-data-grid";
import { Form, Input, Table } from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

const EditableContext = React.createContext(null);

const TableModalGP = ({ keyNum, setDataEdit, dataEdit, dataS }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState(
        dataS
            ? dataS
            : [
                  {
                      id: 0,
                      typePrice: "Fit",
                      fitNgayThuongNet: 0,
                      fitNgayThuongGiaBan: 0,
                      fitCuoiTuanNet: 0,
                      fitCuoiTuanGiaBan: 0,
                      uuid: 1
                  },
                  {
                      id: 1,
                      typePrice: "Extra",
                      fitNgayThuongNet: 0,
                      fitNgayThuongGiaBan: 0,
                      fitCuoiTuanNet: 0,
                      fitCuoiTuanGiaBan: 0,
                      uuid: 2
                  },
              ]
    );
    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    const EditableCell = ({ headerName, editable, children, field, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [field]: record[field],
            });
        };
        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        };
        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={field}
                    rules={[
                        {
                            required: true,
                            message: `${headerName} is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };
    const defaultColumns = [
        {
            headerName: t("typePrice") + " " + t("action.apply").toLowerCase(),
            field: "typePrice",
            key: "typePrice",
            width: 200,
            align: "center",
            headerAlign: "center",
        },

        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            type: "number",
            editable: true,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            type: "number",
            align: "center",
            flex: 1,
            headerAlign: "center",
            editable: true,
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            type: "number",
            align: "center",
            flex: 1,
            headerAlign: "center",
            editable: true,
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            type: "number",
            align: "center",
            flex: 1,
            headerAlign: "center",
            editable: true,
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),

            children: [{ field: "fitNgayThuongNet" }, { field: "fitNgayThuongGiaBan" }],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),

            children: [{ field: "fitCuoiTuanNet" }, { field: "fitCuoiTuanGiaBan" }],
        },
    ];
    const handleSave = (row) => {
        // const newData = [...dataSource];
        // const index = newData.findIndex((item) => row.rowKey === item.rowKey);
        // const item = newData[index];
        // newData.splice(index, 1, {
        //     ...item,
        //     ...row,
        // });
        // var data = [];
        // if (dataEdit) {
        //     const i = dataEdit.findIndex((item) => keyNum === item.key);
        //     data = dataEdit;
        //     if (i === -1) {
        //         data.push({
        //             fit: newData[0],
        //             extra: newData[0],
        //             key: keyNum,
        //         });
        //     } else {
        //         data[i] = {
        //             fit: newData[0],
        //             extra: newData[0],
        //             key: keyNum,
        //         };
        //     }
        // } else {
        //     data.push({
        //         fit: newData[0],
        //         extra: newData[0],
        //         key: keyNum,
        //     });
        // }
        let data =[];
        let objIndex = dataSource.findIndex(obj => obj.typePrice === row.typePrice);
        const newData = [...dataSource];
        newData.splice(objIndex, 1);
        newData.push(row);
        const newDataSort = newData.sort((a,b) => a.uuid - b.uuid).slice(0,10);
        setDataSource(newDataSort);
        for (let i =0; i <newDataSort.length; i++){
            if (newDataSort[i].typePrice==="Fit"){
                data.push({
                    fit: newDataSort[i]
                })
            }else {
                data.push({
                    extra: newDataSort[i]
                })
            }
        }
        data.push({
            key: keyNum
        })
        setDataEdit(data);
        // console.log("=====new=====", data);
    };
    // const components = {
    //     body: {
    //         row: EditableRow,
    //         cell: EditableCell,
    //     },
    // };
    const mapColumns = (col) => {
        if (!col.editable) {
            return col;
        }
        const newCol = {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                field: col.field,
                headerName: col.headerName,
                handleSave,
            }),
        };
        if (col.children) {
            newCol.children = col.children.map(mapColumns);
        }
        return newCol;
    };
    const processRowUpdate = (updatedRow, originalRow) => {
        handleSave(updatedRow);
    };
    // const columns = defaultColumns.map(mapColumns);
    return (
        <>
            <DataGrid
                editMode="row"
                rows={dataSource}
                processRowUpdate={processRowUpdate}
                columns={defaultColumns}
                experimentalFeatures={{ columnGrouping: true }}
                pageSizeOptions={[10]}
                autoHeight
                columnGroupingModel={columnGroupingModel}
            />
        </>
    );
};
export default TableModalGP;
