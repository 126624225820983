import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTDanhSachDatDichVu = (data) => {
    return axios.post(API_BASE_URL + "op/layTTDanhSachDatDichVu", data, { headers: authHeader() });
};

const saveBkThongTinChung = (data) => {
    return axios.post(API_BASE_URL + "booking/saveBkThongTinChung", data, { headers: authHeader() });
};
const layTTOpXacThucDichVu = (data) => {
    return axios.post(API_BASE_URL + "op/layTTOpXacThucDichVu", data, { headers: authHeader() });
};
const saveOpXacThucDichVu = (data) => {
    return axios.post(API_BASE_URL + "op/saveOpXacThucDichVu", data, { headers: authHeader() });
};
export default {
    layTTDanhSachDatDichVu,
    saveBkThongTinChung,
    layTTOpXacThucDichVu,
    saveOpXacThucDichVu,
};
