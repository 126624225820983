import DoneIcon from "@mui/icons-material/Done";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import QuanLyBaoGia from "./QuanLyBaoGia";
import baoGiaService from "../../api/bao_gia/baoGiaService";

const BaoGia = () => {
  const { t } = useTranslation();
  const [defaultActiveKey, setDefaultActiveKey] = useState("1");
  const [dmTrangThaiId, setDmTrangThaiId] = useState(0);
  const [dataTrangThaiAll, setDataTrangThaiAll] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  useEffect(() => {
    baoGiaService
      .layTrangThai()
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDataTrangThaiAll(data);
          setDmTrangThaiId(data[0]?.dmtrangThaiTourDetails[0]?.dmTrangThaiId);
        }
      })
      .catch((err) => {
        console.log("Get data layTrangThaiBooking erorrs " + err);
      });
  }, []);

  const items = [
    {
      key: "1",
      label: t("tatCa"),
      children: (
        <QuanLyBaoGia
          activeKey={defaultActiveKey}
          dataTrangThaiAll={dataTrangThaiAll}
          dmTrangThaiId={dmTrangThaiId}
          setDmTrangThaiId={setDmTrangThaiId}
        />
      ),
      icon: <SelectAllIcon fontSize="small" />,
    },
    {
      key: "2",
      label: t("choXuLy"),
      children: (
        <QuanLyBaoGia
          activeKey={defaultActiveKey}
          dataTrangThaiAll={dataTrangThaiAll}
          dmTrangThaiId={dmTrangThaiId}
          setDmTrangThaiId={setDmTrangThaiId}
        />
      ),
      icon: <HourglassBottomIcon fontSize="small" />,
    },
    {
      key: "3",
      label: t("dangXuLy"),
      children: (
        <QuanLyBaoGia
          activeKey={defaultActiveKey}
          dataTrangThaiAll={dataTrangThaiAll}
          dmTrangThaiId={dmTrangThaiId}
          setDmTrangThaiId={setDmTrangThaiId}
        />
      ),
      icon: <RunningWithErrorsIcon fontSize="small" />,
    },
    {
      key: "4",
      label: t("daHoanThanh"),
      children: (
        <QuanLyBaoGia
          activeKey={defaultActiveKey}
          dataTrangThaiAll={dataTrangThaiAll}
          dmTrangThaiId={dmTrangThaiId}
          setDmTrangThaiId={setDmTrangThaiId}
        />
      ),
      icon: <DoneIcon fontSize="small" />,
    },
  ];

  const handleChangeTab = (activeKey) => {
    setDefaultActiveKey(activeKey);
    let dmTT = 0;
    switch (activeKey) {
      case "1":
        dmTT = dataTrangThaiAll[0]?.dmtrangThaiTourDetails[0]?.dmTrangThaiId;
        break;
      case "2":
        dmTT = dataTrangThaiAll[1]?.dmtrangThaiTourDetails[0]?.dmTrangThaiId;
        break;
      case "3":
        dmTT = dataTrangThaiAll[2]?.dmtrangThaiTourDetails[0]?.dmTrangThaiId;
        break;
      case "4":
        dmTT = dataTrangThaiAll[3]?.dmtrangThaiTourDetails[0]?.dmTrangThaiId;
        break;
      default:
        break;
    }
    setDmTrangThaiId(dmTT);
  };

  return (
    <>
      <Tabs
        activeKey={defaultActiveKey}
        items={items}
        onChange={(key) => handleChangeTab(key)}
      />
    </>
  );
};
export default BaoGia;
