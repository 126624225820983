import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Row, Space } from "antd";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { InputAdornment, MenuItem } from "@mui/material";
import Search from "@mui/icons-material/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import bookingService from "../../../api/booking/bookingService";
import moment from "moment";

dayjs.extend(customParseFormat);
const FormTimKiem = ({
  activeKey,
  setDmTrangThaiId,
  dmTrangThaiId,
  dataSearch,
  setDataSearch,
  dataTrangThaiAll,
  onSearch,
  onReset,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const itemThoiGian = [
    {
      value: 1,
      key: t("thoiGianTao"),
    },
    {
      value: 2,
      key: t("thoiGianDiTour"),
    },
    {
      value: 3,
      key: t("thoiGianKetThuc"),
    },
  ];
  const onClickSearch = () => {
    onSearch();
  };
  const onClickFillter = (data) => {
    setDmTrangThaiId(data);
  };
  const onClickAdd = () => {
    return navigate("/dat-dich-vu/new");
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const handleChangeDataSearch = (e, typeDate) => {
    let name = e.target?.name;
    let value = e.target?.value;
    const newDataSearch = { ...dataSearch };
    if (name === "codeOrName" || name === "loaiThoiGian") {
      newDataSearch[name] = value;
    } else {
      let day = moment(e.$d).format("DD/MM/YYYY");
      if (typeDate === "tuNgay") {
        newDataSearch.tuNgay = day;
      } else {
        newDataSearch.denNgay = day;
      }
      if (newDataSearch.tuNgay && newDataSearch.denNgay)
        newDataSearch.thoiGianTour =
          newDataSearch.tuNgay + "-" + newDataSearch.denNgay;
    }
    setDataSearch(newDataSearch);
  };
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <TextField
            variant="outlined"
            name="codeOrName"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            fullWidth
            size="small"
            placeholder={t("maTenBooking")}
            value={dataSearch.codeOrName}
            onChange={(e) => handleChangeDataSearch(e)}
          />
        </Col>
        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
        <Col style={{marginLeft:"10px"}} xs={12} sm={12} md={12} lg={12} xl={3}>
          <TextField
            variant="outlined"
            name="loaiThoiGian"
            label={t("chonThoiGian")}
            fullWidth
            size="small"
            select
            value={dataSearch.loaiThoiGian}
            onChange={(e) => handleChangeDataSearch(e)}
          >
            {itemThoiGian.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </TextField>
        </Col>
        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
        <Col style={{marginLeft:"10px"}} xs={12} sm={12} md={12} lg={12} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("toDate")}
              value={
                dataSearch.tuNgay
                  ? dayjs(formatStringDateView(dataSearch.tuNgay))
                  : null
              }
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: "small" } }}
              onChange={(date) => handleChangeDataSearch(date, "tuNgay")}
            />
          </LocalizationProvider>
        </Col>
        <HorizontalRuleIcon sx={{ marginTop: "10px" }} />
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("fromDate")}
              value={
                dataSearch.denNgay
                  ? dayjs(formatStringDateView(dataSearch.denNgay))
                  : null
              }
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: "small" } }}
              onChange={(date) => handleChangeDataSearch(date, "denNgay")}
            />
          </LocalizationProvider>
        </Col>
        {/* <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col> */}
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          <Space>
            <Button
                variant="contained"
                onClick={onClickSearch}
                color="success"
                style={{ margin: "0 10px" }}
            >
              {t("search")}
            </Button>
            <Button variant="contained" onClick={onReset} color="primary">
              {t("action.reset")}
            </Button>
          </Space>

        </Col>
        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <Button
            variant="outlined"
            onClick={onClickAdd}
            startIcon={<AddIcon />}
            color="warning"
          >
            {t("add")}
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Space>
          {activeKey === "1" ? (
            dataTrangThaiAll[0]?.dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : activeKey === "2" ? (
            dataTrangThaiAll[1].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : activeKey === "3" ? (
            dataTrangThaiAll[2].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : activeKey === "4" ? (
            dataTrangThaiAll[3].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    variant="outlined"
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : (
            <></>
          )}
        </Space>
      </Row>
    </>
  );
};
export default FormTimKiem;
