import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  TextField,
} from "@mui/material";
import { Col, Divider, Row, Table, notification } from "antd";
import Modal from "antd/es/modal/Modal";
import { t } from "i18next";
import { useEffect, useState } from "react";
// import { useLocation } from "react-router";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ModalThongTinNccOto from "../chietTinhXeOto/ModalThongTinNccOto";
import dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
import otoService from "../../../../../api/tour/oto/oto.service";
import khachSanService from "../../../../../api/tour/khachSan/khachSanService";
import ModalThongTinNccKhachSan from "../chietTinhKhachSan/ModalThongTinNccKhachSan";
import nhaHangService from "../../../../../api/tour/nhaHang/nhaHangService";
import ModalThongTinNccNhaHang from "../chietTinhNhaHang/ModalThongTinNccNhaHang";
import categoryService from "../../../../../api/category.service";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";

const ModalChietTinhCommon = ({
  isOpen,
  isAdd,
  onSave,
  onCancel,
  onCancelKhachSan,
  onCancelNhaHang,
  chietTinh,
  tourMapChietTinh,
  tranxChietTinh,
  tourQuanLyKhoangKhachId,
  tourChuongTrinhId,
  tourChuongTrinh,
  day,
  tourThongTinChungId,
  tourThoiGianApDungId,
  tourChietTinhId,
  tabChietTinhId,
  loaiTong,
  thoiGianAD,
  dsDichVuByDay,
}) => {
  // const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [dataTreEm, setDataTreEm] = useState([]);
  const [dataTreEmInit, setDataTreEmInit] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dataInit, setDataInit] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [isShowNccKS, setIsShowNccKS] = useState(false);
  const [isShowNccNH, setIsShowNccNH] = useState(false);
  const [dmGia, setDmGia] = useState([]);
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [dmBuaAn, setDmBuaAn] = useState([]);
  const [reset, setReset] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowInit, setSelectedRowInit] = useState([]);
  const [selectedRowKeyInit, setSelectedRowKeyInit] = useState([]);
  const [selectdTreEm, setSelectdTreEm] = useState({
    selectedRowKeys: [],
    loading: false,
  });
  const { selectedRowKeys, loading } = selectdTreEm;

  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmBuaAn();
    initData();
  }, []);

  useEffect(() => {
    if (!isAdd && tranxChietTinh !== 0) {
      if (chietTinh === "oto")
        getCauHinhOtoTheoMap({
          tourMapChietTinhChuongTrinhId: tourMapChietTinh,
          tranxChietTinhOtoId: tranxChietTinh, //0 là mặc định, != 0 lấy theo id
        });
      else if (chietTinh === "khachSan")
        getCauHinhKhachSanTheoMap({
          tourMapChietTinhChuongTrinhId: tourMapChietTinh,
          tranxKhachSanId: tranxChietTinh,
        });
      else {
        getCauHinhNhaHangTheoMap({
          tourMapChietTinhChuongTrinhId: tourMapChietTinh,
          tranxChietTinhNhaHangId: tranxChietTinh,
        });
      }
    } else {
      initData();
    }
  }, [chietTinh, tranxChietTinh, tourMapChietTinh, isAdd]);

  const initData = () => {
    let dataInit = {
      tranxKhachSanId: 0,
      nccThongTinChungId: 0,
      nccHangPhongId: 0,
      nccGiaPhongId: 0,
      dmBuaAnId: 1,
      dmGiaApDungId: 1,
      dmLoaiApDungId: 1,
      dmHinhThucGiaId: 1,
      dmSoChoId: 0,
      isVat: false,
      dmVatId: 1,
      vat: 0,
      hinh_thuc_gia: "",
      tenLoaiXe: "",
      tenNcc: "",
      maNcc: "",
      dichVu: "",
      tenDichVu: "",
      soCho: "",
      tongSoKm: 0,
      dmTyGiaId: 1,
      maNgoaiTe: "",
      giaDichVu: 0,
      giaExtra: 0,
      dieuKienFoc: 0,
      foc: 0,
      moTa: "",
    };
    setData(dataInit);
    setDataInit({ ...dataInit });
    if (chietTinh !== "oto")
      getDataTreEm({
        nccThongTinChungId: tourThongTinChungId,
      });
  };

  const getDataTreEm = (data) => {
    categoryService
      .getDataTreEm(data)
      .then(function (response) {
        let treEms = [];
        if (response.status === 200) {
          treEms = response.data.data;
          treEms = treEms.map((item, index) => {
            return {
              ...item,
              nccTreEmId: item.id,
              key: item.id,
              stt: index + 1,
              giaNet: 0,
              soLuong: 0,
              giaDichVu: 0,
            };
          });
        }
        setDataTreEm(treEms);
        setDataTreEmInit(JSON.parse(JSON.stringify(treEms)));
        setSelectedRowKeyInit([]);
        setSelectedRows([]);
        setSelectedRowInit([]);
        setSelectdTreEm({
          ...selectdTreEm,
          selectedRowKeys: [],
        });
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmBuaAn = () => {
    nhaHangService
      .getDanhMucBuaAn()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmBuaAn(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getCauHinhOtoTheoMap = (data) => {
    otoService
      .getCauHinhOtoTheoMap(data)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data[0];
          data && setData({ ...data, moTa: "" });
          data && setDataInit({ ...data, moTa: "" });
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  };

  const getCauHinhKhachSanTheoMap = async (data) => {
    let response = await categoryService.getDataTreEm({
      nccThongTinChungId: tourThongTinChungId,
    });
    let dataTreEms = response.data?.data?.map((item, index) => {
      return {
        ...item,
        nccTreEmId: item.id,
        key: item.id,
        stt: index + 1,
        giaNet: 0,
        soLuong: 0,
        giaDichVu: 0,
      };
    });
    khachSanService
      .getCauHinhKhachSanTheoMap(data)
      .then((response) => {
        if (response.status === 200) {
          let dataNcc = response.data.data.nhaCungCaps[0];
          dataNcc && setData({ ...dataNcc });
          dataNcc && setDataInit({ ...dataNcc });
          let treEms = response.data.data.treEms;
          dataTreEms = dataTreEms.map((it) => {
            return { ...it, giaNet: dataNcc.giaDichVu };
          });
          let selectedRowKey = [];
          treEms?.forEach((item, index) => {
            selectedRowKey.push(item.nccTreEmId);
            let idx = dataTreEms.findIndex(
              (it) => it.nccTreEmId === item.nccTreEmId
            );
            if (idx !== -1) {
              dataTreEms[idx] = {
                ...item,
                key: item.nccTreEmId,
                stt: idx + 1,
                giaNet: dataNcc.giaDichVu,
                giaDichVu:
                  dataNcc.giaDichVu * (item.phanTram / 100) * item.soLuong,
              };
            }
          });
          setDataTreEm(dataTreEms);
          setDataTreEmInit(JSON.parse(JSON.stringify(dataTreEms)));
          setSelectedRowKeyInit(selectedRowKey);
          setSelectedRows(treEms);
          setSelectedRowInit(JSON.parse(JSON.stringify(treEms)));
          setSelectdTreEm({
            ...selectdTreEm,
            selectedRowKeys: selectedRowKey,
          });
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  };

  const getCauHinhNhaHangTheoMap = async (data) => {
    let response = await categoryService.getDataTreEm({
      nccThongTinChungId: tourThongTinChungId,
    });
    let dataTreEms = response.data?.data?.map((item, index) => {
      return {
        ...item,
        nccTreEmId: item.id,
        key: item.id,
        stt: index + 1,
        giaNet: 0,
        soLuong: 0,
        giaDichVu: 0,
      };
    });
    nhaHangService
      .getCauHinhNhaHangTheoMap(data)
      .then((response) => {
        if (response.status === 200) {
          let dataNcc = response.data.data.nhaCungCaps[0];
          dataNcc && setData({ ...dataNcc });
          dataNcc && setDataInit({ ...dataNcc });
          let treEms = response.data.data.treEms;
          let selectedRowKey = [];
          dataTreEms = dataTreEms.map((it) => {
            return { ...it, giaNet: dataNcc.giaDichVu };
          });
          treEms?.forEach((item) => {
            selectedRowKey.push(item.nccTreEmId);
            let idx = dataTreEms.findIndex(
              (it) => it.nccTreEmId === item.nccTreEmId
            );
            if (idx !== -1) {
              dataTreEms[idx] = {
                ...item,
                key: item.nccTreEmId,
                stt: idx + 1,
                giaNet: dataNcc.giaDichVu,
                giaDichVu:
                  dataNcc.giaDichVu * (item.phanTram / 100) * item.soLuong,
              };
            }
          });
          setDataTreEm(dataTreEms);
          setDataTreEmInit(JSON.parse(JSON.stringify(dataTreEms)));
          setSelectedRowKeyInit(selectedRowKey);
          setSelectedRowInit(JSON.parse(JSON.stringify(treEms)));
          setSelectedRows(treEms);
          setSelectdTreEm({
            ...selectdTreEm,
            selectedRowKeys: selectedRowKey,
          });
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  };

  const handleChangeValue = (e) => {
    data[e.target.name] = e.target.value;
    setIsUpdate(true);
    setReset(!reset);
  };

  const handleChangeDataService = (dataChange) => {
    console.log(dataChange);
    data.tenNcc = dataChange[0].tenNcc;
    data.tenDichVu = dataChange[0].tenDichVu;
    data.nccDichVuOtoId = dataChange[0].nccDichVuOtoId;
    data.nccThongTinChungId = dataChange[0].nccThongTinChungId;
    data.hinh_thuc_gia = dataChange[0].hinhThucGia;
    data.dmHinhThucGiaId = dataChange[0].hinhThucGiaId;
    data.tranxOtoId = dataChange[0].tranxOtoId;
    data.tenLoaiXe = dataChange[0].loaiXe;
    data.tongSoKm = dataChange[0].kmUocTinh;
    data.giaDichVu = dataChange[0].giaNet;
    data.maNgoaiTe = dataChange[0].maNgoaiTe;
    // data.tongChiPhi = dataChange.giaNet * dataChange.kmUocTinh;
    data.vat = dataChange[0].vat;
    data.isVat = dataChange[0].isVat;
    data.dmVatId = dataChange[0].dmVatId;
    data.dmTyGiaId = dataChange[0].dmTyGiaId;
    setIsUpdate(true);
    setReset(!reset);
  };

  const handleChangeDataServiceKhachSan = (dataChange) => {
    console.log(dataChange);
    data.tenNcc = dataChange.tenNcc;
    data.dichVu = dataChange.kieuPhong;
    data.nccGiaPhongId = dataChange.nccGiaPhongId;
    data.nccHangPhongId = dataChange.nccHangPhongId;
    data.tranxGiaPhongId = dataChange.tranxGiaPhongId;
    data.nccThongTinChungId = dataChange.nccThongTinChungId;
    data.giaDichVu = dataChange.fitNgayThuongNet;
    data.giaExtra = dataChange.extraNgayThuongNet;
    data.maNgoaiTe = dataChange.maNgoaiTe;
    data.dieuKienFoc = dataChange.dieuKienFoc;
    data.foc = dataChange.foc;
    data.tongChiPhi = data.giaDichVu;
    data.vat = dataChange.vat;
    data.isVat = dataChange.isVat;
    data.dmVatId = dataChange.dmVatId;
    data.dmTyGiaId = dataChange.dmTyGiaId;
    let treEms = dataTreEm.map((item) => {
      return {
        ...item,
        giaNet: data.giaDichVu,
        giaDichVu: data.giaDichVu * (item.phanTram / 100) * item.soLuong,
      };
    });
    setDataTreEm(treEms);
    setIsUpdate(true);
    setReset(!reset);
  };

  const handleChangeDataServiceNhaHang = (dataChange) => {
    if (dataChange !== undefined) {
      data.tenNcc = dataChange.tenNcc;
      data.dichVu = dataChange.tenDichVu;
      // data.tranxChietTinhNhaHangId = dataChange.tranxChietTinhNhaHangId;
      data.nccThongTinChungId = dataChange.nccThongTinChungId;
      data.nccDvNhaHangId = dataChange.nccDvNhaHangId;
      data.tranxNhaHangId = dataChange.tranxNhaHangId;
      // data.dmBuaAnId = 1;
      data.giaDichVu = dataChange.fitNgayThuongNet;
      // data.giaExtra =dataChange.giaExtra;
      data.maNgoaiTe = dataChange.maNgoaiTe;
      data.dieuKienFoc = dataChange.dieuKienFoc;
      data.foc = dataChange.foc;
      data.vat = dataChange.vat;
      data.isVat = dataChange.isVat;
      data.dmVatId = dataChange.dmVatId;
      data.dmTyGiaId = dataChange.dmTyGiaId;
      let treEms = dataTreEm.map((item) => {
        return {
          ...item,
          giaNet: data.giaDichVu,
          giaDichVu: data.giaDichVu * (item.phanTram / 100) * item.soLuong,
        };
      });
      setDataTreEm(treEms);
      setIsUpdate(true);
      setReset(!reset);
    }
  };

  const handleShowModal = () => {
    if (chietTinh === "oto") {
      setIsShow(true);
    } else if (chietTinh === "khachSan") {
      setIsShowNccKS(true);
    } else {
      setIsShowNccNH(true);
    }
  };

  const handleCancels = (e) => {
    setSelectdTreEm({ selectedRowKeys: selectedRowKeyInit, loading: false });
    setSelectedRows(selectedRowInit);
    setDataTreEm(JSON.parse(JSON.stringify(dataTreEmInit)));
    setIsUpdate(false);
    setData({ ...dataInit });
    // setReset(!reset);
    if (chietTinh === "oto") onCancel(false);
    else if (chietTinh === "khachSan") onCancelKhachSan();
    else onCancelNhaHang();
  };
  const handleReset = (e) => {
    setSelectdTreEm({ selectedRowKeys: selectedRowKeyInit, loading: false });
    setSelectedRows(selectedRowInit);
    setDataTreEm(JSON.parse(JSON.stringify(dataTreEmInit)));
    setIsUpdate(false);
    setData({ ...dataInit });
  };
  const onFinish = (e) => {
    if (isUpdate) {
      if (isAdd || tranxChietTinh === 0) {
        if (data.tenNcc) {
          saveCauHinh();
        } else {
          api["error"]({
            message: t("stour"),
            description: t("pleaseSelectSupplier"),
            duration: 0,
          });
        }
      } else {
        updateCauHinh();
      }
    } else {
      api["error"]({
        message: t("stour"),
        description: t("noti.saveWarning"),
        duration: 0,
      });
    }
  };

  const updateCauHinh = () => {
    let dataReq = {};
    if (chietTinh === "oto") {
      dataReq = getDataReqUpdateChietTinhOto();
    } else if (chietTinh === "khachSan") {
      dataReq = getDataReqUpdateChietTinhKhachSan();
    } else {
      dataReq = getDataReqUpdateChietTinhNhaHang();
    }
    console.log("@@@@@@ dataReq: ", JSON.stringify(dataReq));
    if (isUpdate)
      updateCauHinhService(dataReq)
        .then((response) => {
          if (response.status === 200 && response.data.code === "00") {
            api["success"]({
              message: t("stour"),
              description: t("edit") + " " + t("noti.success").toLowerCase(),
              duration: 0,
            });
            // setSelectdTreEm({ selectedRowKeys: [], loading: false });
            // setSelectedRows([]);
            setIsUpdate(false);
            onSave();
          } else {
            api["error"]({
              message: t("stour"),
              // description: t("edit") + " " + t("noti.error").toLowerCase(),
              description: response.data.message,
              duration: 0,
            });
          }
        })
        .catch((response) => {
          api["error"]({
            message: t("stour"),
            description: t("system.error"),
            duration: 0,
          });
        });
  };

  const updateCauHinhService = (dataReq) => {
    if (chietTinh === "oto") {
      return otoService.updateCauHinhOtoTheoMap(dataReq);
    } else if (chietTinh === "khachSan") {
      return khachSanService.updateCauHinhKhachSanTheoMap(dataReq);
    } else {
      if (
        dsDichVuByDay?.some(
          (item) =>
            item.tourMapChietTinhChuongTrinhId ===
              data.tourMapChietTinhChuongTrinhId &&
            item.tranxChietTinhNhaHangId === data.tranxChietTinhNhaHangId &&
            item.dmBuaAnId === data.dmBuaAnId
        )
      ) {
        api["error"]({
          message: t("stour"),
          description: t("noti.mealDuplicateWarning"),
          duration: 0,
        });
        return false;
      } else return nhaHangService.updateCauHinhNhaHangTheoMap(dataReq);
    }
  };

  const saveCauHinhService = (dataReq) => {
    if (chietTinh === "oto") {
      return otoService.saveCauHinhOto(dataReq);
    } else if (chietTinh === "khachSan") {
      return khachSanService.saveCauHinhKhachSan(dataReq);
    } else {
      return nhaHangService.saveCauHinhNhaHang(dataReq);
    }
  };

  const saveCauHinh = () => {
    let dataReq = {};
    if (chietTinh === "oto") {
      dataReq = getDataReqSaveChietTinhOto();
    } else if (chietTinh === "khachSan") {
      dataReq = getDataReqSaveChietTinhKhachSan();
    } else {
      if (dsDichVuByDay?.some((item) => item.dmBuaAnId === data.dmBuaAnId)) {
        api["error"]({
          message: t("stour"),
          description: t("noti.mealDuplicateWarning"),
          duration: 0,
        });
        return false;
      } else dataReq = getDataReqSaveChietTinhNhaHang();
    }

    console.log("@@@@@@ dataReq: ", JSON.stringify(dataReq));
    // if (isUpdate)
    //   saveCauHinhService(dataReq)
    //     .then((response) => {
    //       if (response.status === 200 && response.data.code === "00") {
    //         api["success"]({
    //           message: t("stour"),
    //           description: t("add") + " " + t("noti.success").toLowerCase(),
    //           duration: 0,
    //         });
    //         // setSelectdTreEm({ selectedRowKeys: [], loading: false });
    //         // setSelectedRows([]);
    //         setIsUpdate(false);
    //         onSave();
    //       } else {
    //         api["error"]({
    //           message: t("stour"),
    //           // description: t("add") + " " + t("noti.error").toLowerCase(),
    //           description: response.data.message,
    //           duration: 0,
    //         });
    //       }
    //     })
    //     .catch((response) => {
    //       api["error"]({
    //         message: t("stour"),
    //         description: t("system.error"),
    //         duration: 0,
    //       });
    //     });
  };

  const getDataReqUpdateChietTinhOto = () => {
    let thongTinChiTiets = [
      {
        tranxChietTinhOtoId: data.tranxChietTinhOtoId,
        tourQuanLyKhoangKhachId: data.tourQuanLyKhoangKhachId,
        nccThongTinChungId: data.nccThongTinChungId,
        nccDichVuOtoId: data.nccDichVuOtoId,
        hinhThucGiaId: data.dmHinhThucGiaId,
        tranxOtoId: data.tranxOtoId,
        isVat: data.isVat,
        dmTyGiaId: data.dmTyGiaId,
        dmVatId: data.dmVatId ? data.dmVatId : 1,
        tongSoKm: data.tongSoKm,
        dmGiaApDungId: data.dmGiaApDungId,
        moTa: data.moTa,
      },
    ];
    let tourDichVu = {
      tourMapChietTinhChuongTrinhId: tourMapChietTinh,
      tourChuongTrinhId: tourChuongTrinhId,
      thongTinOtoChiTiets: thongTinChiTiets,
    };

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      loaiTong: loaiTong,
      tourDichVu: tourDichVu,
    };
    return dataReq;
  };

  const getDataReqSaveChietTinhOto = () => {
    let thongTinOtoChiTiets = [
      {
        tourQuanLyKhoangKhachId:
          tranxChietTinh === 0
            ? tourQuanLyKhoangKhachId
            : data.tourQuanLyKhoangKhachId,
        nccThongTinChungId: data.nccThongTinChungId,
        nccDichVuOtoId: data.nccDichVuOtoId,
        hinhThucGiaId: data.dmHinhThucGiaId,
        tranxOtoId: data.tranxOtoId,
        isVat: data.isVat,
        dmTyGiaId: data.dmTyGiaId,
        dmVatId: data.dmVatId ? data.dmVatId : 1,
        tongSoKm: data.tongSoKm,
        dmGiaApDungId: data.dmGiaApDungId,
        moTa: data.moTa,
      },
    ];
    let tourDichVu = {
      tourChuongTrinhId: tourChuongTrinhId,
      // tourMapChietTinhChuongTrinhId: tourMapChietTinh,
      thongTinOtoChiTiets: thongTinOtoChiTiets,
    };

    let tabChietTinh = [{ id: tabChietTinhId, tourDichVu: tourDichVu }];

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      loaiTong: loaiTong,
      tabChietTinh: tabChietTinh,
    };
    return dataReq;
  };

  const getDataReqUpdateChietTinhKhachSan = () => {
    let treEms = selectedRows.map((item) => {
      return {
        nccTreEmId: item.nccTreEmId,
        phanTram: item.phanTram,
        soLuong: item.soLuong,
        apDung: true,
      };
    });
    let thongTinKhachSanChiTiet = {
      tranxKhachSanId: data.tranxKhachSanId,
      nccThongTinChungId: data.nccThongTinChungId,
      nccHangPhongId: data.nccHangPhongId,
      nccGiaPhongId: data.nccGiaPhongId,
      tranxGiaPhongId: data.tranxGiaPhongId,
      dmGiaApDungId: data.dmGiaApDungId,
      isVat: data.isVat,
      dmVatId: data.dmVatId ? data.dmVatId : 1,
      dmTyGiaId: data.dmTyGiaId,
      moTa: data.moTa,
      treEms: treEms,
    };
    let tourDichVu = {
      tourMapChietTinhChuongTrinhId: tourMapChietTinh,
      tourChuongTrinhId: tourChuongTrinhId,
      thongTinKhachSanChiTiet: thongTinKhachSanChiTiet,
    };

    let tabChietTinh = [{ id: tabChietTinhId, tourDichVu: tourDichVu }];

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      loaiTong: loaiTong,
      tabChietTinh: tabChietTinh,
    };
    return dataReq;
  };

  const getDataReqSaveChietTinhKhachSan = () => {
    let treEms = selectedRows.map((item) => {
      return {
        nccTreEmId: item.nccTreEmId,
        phanTram: item.phanTram,
        soLuong: item.soLuong,
      };
    });
    let thongTinKhachSanChiTiet = {
      // tranxKhachSanId: data.tranxKhachSanId,
      nccThongTinChungId: data.nccThongTinChungId,
      nccHangPhongId: data.nccHangPhongId,
      nccGiaPhongId: data.nccGiaPhongId,
      tranxGiaPhongId: data.tranxGiaPhongId,
      dmGiaApDungId: data.dmGiaApDungId,
      isVat: data.isVat,
      dmVatId: data.dmVatId ? data.dmVatId : 1,
      dmTyGiaId: data.dmTyGiaId,
      moTa: data.moTa,
      treEms: treEms,
    };
    let tourDichVu = {
      tourChuongTrinhId: tourChuongTrinhId,
      thongTinKhachSanChiTiet: thongTinKhachSanChiTiet,
      // tourMapChietTinhChuongTrinhId: tourMapChietTinh,
    };

    let tabChietTinh = [{ id: tabChietTinhId, tourDichVu: tourDichVu }];

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      loaiTong: loaiTong,
      tabChietTinh: tabChietTinh,
    };
    return dataReq;
  };

  const getDataReqUpdateChietTinhNhaHang = () => {
    let treEms = selectedRows.map((item) => {
      return {
        nccTreEmId: item.nccTreEmId,
        phanTram: item.phanTram,
        soLuong: item.soLuong,
        apDung: true,
      };
    });
    let thongTinNhaHangChiTiet = {
      tranxChietTinhNhaHangId: data.tranxChietTinhNhaHangId,
      nccThongTinChungId: data.nccThongTinChungId,
      nccDvNhaHangId: data.nccDvNhaHangId,
      tranxNhaHangId: data.tranxNhaHangId,
      dmBuaAnId: data.dmBuaAnId,
      dmGiaApDungId: data.dmGiaApDungId,
      isVat: data.isVat,
      dmVatId: data.dmVatId ? data.dmVatId : 1,
      dmTyGiaId: data.dmTyGiaId,
      moTa: data.moTa,
      treEms: treEms,
    };
    let tourDichVu = {
      tourMapChietTinhChuongTrinhId: tourMapChietTinh,
      tourChuongTrinhId: tourChuongTrinhId,
      thongTinNhaHangChiTiet: thongTinNhaHangChiTiet,
    };

    let tabChietTinh = [{ id: tabChietTinhId, tourDichVu: tourDichVu }];

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      loaiTong: loaiTong,
      tabChietTinh: tabChietTinh,
    };
    return dataReq;
  };

  const getDataReqSaveChietTinhNhaHang = () => {
    let treEms = selectedRows.map((item) => {
      return {
        nccTreEmId: item.nccTreEmId,
        phanTram: item.phanTram,
        soLuong: item.soLuong,
        apDung: true,
      };
    });
    let thongTinNhaHangChiTiet = {
      // tranxChietTinhNhaHangId: data.tranxChietTinhNhaHangId,
      nccThongTinChungId: data.nccThongTinChungId,
      nccDvNhaHangId: data.nccDvNhaHangId,
      tranxNhaHangId: data.tranxNhaHangId,
      dmBuaAnId: data.dmBuaAnId,
      dmGiaApDungId: data.dmGiaApDungId,
      isVat: data.isVat,
      dmVatId: data.dmVatId ? data.dmVatId : 1,
      dmTyGiaId: data.dmTyGiaId,
      moTa: data.moTa,
      treEms: treEms,
    };
    let tourDichVu = {
      tourChuongTrinhId: tourChuongTrinhId,
      thongTinNhaHangChiTiet: thongTinNhaHangChiTiet,
      // tourMapChietTinhChuongTrinhId: tourMapChietTinh,
    };

    let tabChietTinh = [{ id: tabChietTinhId, tourDichVu: tourDichVu }];

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      loaiTong: loaiTong,
      tabChietTinh: tabChietTinh,
    };
    return dataReq;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      console.log(selectedRowKeys);
      setIsUpdate(true);
      setSelectdTreEm({
        ...selectdTreEm,
        selectedRowKeys: selectedRowKeys,
      });
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.soLuong <= 0 || !record.soLuong,
      // name: record.name,
    }),
  };

  const onChangeValueTreEm = (index, event) => {
    dataTreEm[index - 1][event.target.name] = event.target.value;
    dataTreEm[index - 1].giaDichVu =
      dataTreEm[index - 1].giaNet *
      (dataTreEm[index - 1].phanTram / 100) *
      dataTreEm[index - 1].soLuong;
    setDataTreEm([...dataTreEm]);
  };

  const columns = [
    {
      title: t("stt"),
      dataIndex: "stt",
      key: "stt",
      width: "5%",
    },
    {
      title: t("age"),
      dataIndex: "doTuoi",
      key: "doTuoi",
      width: "20%",
    },
    {
      title: t("tour.percentage"),
      dataIndex: "phanTram",
      key: "phanTram",
      width: "18%",
      render: (_, record) => {
        return (
          <TextField
            id="outlined-number"
            size="small"
            value={record.phanTram}
            name="phanTram"
            onChange={(e) => {
              onChangeValueTreEm(record.stt, e);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        );
      },
    },
    {
      title: t("hotel.priceClass.giaNet"),
      dataIndex: "giaNet",
      key: "giaNet",
      width: "17%",
      render: (_, record) => {
        return handleData(record.giaNet);
      },
    },
    {
      title: t("hotel.roomClass.numberOfRoom"),
      dataIndex: "soLuong",
      key: "soLuong",
      width: "20%",
      render: (_, record) => {
        return (
          <TextField
            id="outlined-number"
            size="small"
            value={record.soLuong}
            type="number"
            name="soLuong"
            onChange={(e) => {
              onChangeValueTreEm(record.stt, e);
            }}
          />
        );
      },
    },
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "giaDichVu",
      key: "giaDichVu",
      width: "20%",
      render: (_, record) => {
        return handleData(record.giaDichVu);
      },
    },
  ];

  const dataRender = () => {
    return (
      <>
        <Row>
          <TextField
            sx={{
              marginBottom: 2,
            }}
            fullWidth
            label={t("supplier")}
            value={data.tenNcc}
            required
            InputProps={{
              endAdornment: (
                <BorderColorOutlinedIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => handleShowModal()}
                />
              ),
            }}
          ></TextField>
        </Row>
        <Row>
          <TextField
            sx={{
              marginBottom: 2,
            }}
            fullWidth
            label={t("service")}
            value={chietTinh === "oto" ? data.tenDichVu : data.dichVu}
            disabled
          ></TextField>
        </Row>
        {chietTinh === "oto" && (
          <>
            <Row>
              <Col span={11}>
                <TextField
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label={t("number")}
                  value={data.soCho}
                  disabled
                ></TextField>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <TextField
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label={t("formOfPricing")}
                  value={data.hinh_thuc_gia}
                  disabled
                ></TextField>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <TextField
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label={t("vehicleType")}
                  value={data.tenLoaiXe}
                  disabled
                ></TextField>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <TextField
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label={t("numberOfKm")}
                  value={data.tongSoKm}
                  name="tongSoKm"
                  disabled={data.dmHinhThucGiaId === 1}
                  onChange={(e) => handleChangeValue(e)}
                ></TextField>
              </Col>
            </Row>
          </>
        )}
        <Divider style={{ background: "#898989" }} />
        <Row>
          <Col span={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("currency")}
              value={data.maNgoaiTe}
              disabled
            ></TextField>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeOfApply")}
              value={chietTinh === "oto" ? 2 : 1}
              disabled
            >
              {dmLoaiApDung?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.loai_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <TextField
              id="dmGiaApDungId"
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("priceApply")}
              value={data.dmGiaApDungId}
              name="dmGiaApDungId"
              onChange={(e) => handleChangeValue(e)}
            >
              {dmGia?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.gia_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <FormControl disabled={true} style={{ width: "100%" }}>
              <Row>
                {tourChuongTrinh?.map((element) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (data.dmGiaApDungId === 1 &&
                              parseInt(element.ngay) >= day) ||
                            (data.dmGiaApDungId === 2 &&
                              parseInt(element.ngay) === day)
                          }
                        />
                      }
                      label={"Ngày " + element.ngay}
                    />
                  );
                })}
              </Row>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("priceOfService")}
              value={handleData(data.giaDichVu)}
              disabled
            ></TextField>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            {chietTinh === "nhaHang" ? (
              <>
                <TextField
                  select
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label={t("eatTime")}
                  value={data.dmBuaAnId}
                  defaultValue={data.dmBuaAnId}
                  name="dmBuaAnId"
                  onChange={(e) => handleChangeValue(e)}
                >
                  {dmBuaAn?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.ten_bua_an}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <TextField
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label={t("eatTime")}
                  value={data.dichVu}
                  disabled
                ></TextField> */}
              </>
            ) : chietTinh === "khachSan" ? (
              <>
                <TextField
                  sx={{
                    marginBottom: 2,
                  }}
                  fullWidth
                  label="Extra/ 1 Pax"
                  value={handleData(data.giaExtra)}
                  disabled
                ></TextField>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("tour.tongChiPhi")}
              value={
                chietTinh === "oto"
                  ? handleData(data.giaDichVu * data.tongSoKm)
                  : handleData(data.giaDichVu)
              }
              disabled
            ></TextField>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            {chietTinh === "khachSan" || chietTinh === "nhaHang" ? (
              <>
                <Row>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                    <TextField
                      sx={{
                        marginBottom: 2,
                      }}
                      fullWidth
                      disabled
                      label={t("hotel.roomClass.conditionFoc")}
                      value={data.dieuKienFoc}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {chietTinh === "khachSan" ? t("room") : t("set")}
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                    <TextField
                      sx={{
                        marginBottom: 2,
                      }}
                      fullWidth
                      disabled
                      value={data.foc}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">FOC</InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Divider style={{ background: "#898989" }} />
        <Row>
          <TextField
            sx={{
              marginBottom: 2,
            }}
            fullWidth
            label={t("typeTour.description")}
            value={data.moTa}
            name="moTa"
            onChange={(e) => handleChangeValue(e)}
            // multiline={true}
            // maxRows={3}
          ></TextField>
        </Row>
        <Divider style={{ background: "#898989" }} />
        <FormControl style={{ width: "100%" }}>
          <Row>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <FormControlLabel
                disabled
                value="8"
                control={<Radio checked={data.isVat} />}
                label={t("vat8%")}
              />
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <FormControlLabel
                disabled
                value="0"
                control={<Radio checked={!data.isVat} />}
                label={t("vat0%")}
              />
            </Col>
          </Row>
        </FormControl>
        {(chietTinh === "khachSan" || chietTinh === "nhaHang") && (
          <>
            <Row>
              <h3 style={{ color: "#898989", marginLeft: 40 }}>
                {t("hotel.table.title")}
              </h3>
            </Row>
            <Row>
              <Table
                columns={columns}
                rowSelection={rowSelection}
                dataSource={dataTreEm}
                loading={loading}
                style={{ width: "100%" }}
                pagination={false}
              />
            </Row>
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      title={
        chietTinh === "oto"
          ? t("tour.oto.capNhatDichVu")
          : chietTinh === "khachSan"
          ? t("tour.khachSan.capNhatDichVu")
          : t("tour.nhaHang.capNhatDichVu")
      }
      open={isOpen}
      onOk={onFinish}
      onCancel={handleCancels}
      footer={null}
      width={1200}
      // bodyStyle={{ background: "#F5F5F5" }}
    >
      {contextHolder}
      <ModalThongTinNccOto
        isOpen={isShow}
        onIsShow={setIsShow}
        isCapNhatAll={false}
        soCho={data.dmSoChoId}
        onSaves={handleChangeDataService}
        thoiGianAD={thoiGianAD}
      />
      <ModalThongTinNccKhachSan
        isOpen={isShowNccKS}
        onIsShow={setIsShowNccKS}
        onSaves={handleChangeDataServiceKhachSan}
        thoiGianAD={thoiGianAD}
      />
      <ModalThongTinNccNhaHang
        isOpen={isShowNccNH}
        onIsShow={setIsShowNccNH}
        onSaves={handleChangeDataServiceNhaHang}
        thoiGianAD={thoiGianAD}
      />
      <Divider />
      <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
      <Box component="form" noValidate>
        {dataRender()}
        <Divider style={{ background: "#898989" }} />
        <Row>
          <Col span={12}></Col>
          <Col span={12}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={onFinish}
              sx={{
                marginRight: "5px",
              }}
            >
              {t("action.ok")}
            </Button>
            <Button
              style={{
                backgroundColor: "#898989",
                color: "white",
                marginRight: "5px",
              }}
              key="reset"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              {t("action.reset")}
            </Button>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleCancels}
            >
              {t("huy")}
            </Button>
          </Col>
        </Row>
      </Box>
    </Modal>
  );
};

export default ModalChietTinhCommon;
