import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Row, Space } from "antd";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { InputAdornment, MenuItem } from "@mui/material";
import Search from "@mui/icons-material/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DieuHanhService from "../../../api/dieu_hanh/DieuHanhService";

dayjs.extend(customParseFormat);
const FormTimKiem = ({ activeKey, dmTrangThaiId, setDmTrangThaiId, getDataSource, dataTrangThaiAll }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [thoiGian, setThoiGian] = useState(0);
    const [textSearch, setTextSearch] = useState("");
    const [thoiGianBatDau, setThoiGianBatDau] = useState(null);
    const [thoiGianKetThuc, setThoiGianKetThuc] = useState(null);
    const handleChangeThoiGian = (e) => {
        if (e.target.value === 1) {
            let crr = getCurrDate();
            setThoiGianBatDau(dayjs(crr[0] + "-" + crr[1] + "-" + (crr[2] - 1)));
            setThoiGianKetThuc(dayjs(crr[0] + "-" + crr[1] + "-" + (crr[2] + 1)));
        }
        setThoiGian(e.target.value);
    };
    const getCurrDate = () => {
        let date = new Date();
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    };

    const onClickSearch = () => {
        const dataReq = {
            searchMaOrTen: textSearch,
            loaiThoiGian: thoiGian, // 1: ưu tiên, 2 đi tour, 3 kết thúc, 0: không chọn
            thoiGianBatDau: thoiGianBatDau ? thoiGianKetThuc.format("DD/MM/YYYY") : "",
            thoiGianKetThuc: thoiGianKetThuc ? thoiGianKetThuc.format("DD/MM/YYYY") : "",
            loaiTourId: 1,
            tienDo: 0,
            maBooking: "",
            tenBooking: "",
            thongTinTour: "",
            thucHien: "",
            dmTrangThaiId: dmTrangThaiId,
            pageIndex: 1,
            pageSize: 10,
        };
        getDataSource(dataReq);
    };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf("day");
    };
    const itemThoiGian = [
        {
            value: 1,
            key: t("thoiGianTao"),
        },
        {
            value: 2,
            key: t("thoiGianDiTour"),
        },
        {
            value: 3,
            key: t("thoiGianKetThuc"),
        },
    ];
    const onClickFillter = (data) => {
        setDmTrangThaiId(data);
    };
    const onClickAdd = () => {
        return navigate("/dieu-hanh/new");
    };
    return (
        <Row>
            <Col span={24}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                        <TextField
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            }
                            fullWidth
                            size="small"
                            placeholder={t("search")}
                            value={textSearch}
                            onChange={(e) => setTextSearch(e.target.value)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                        <TextField
                            variant="outlined"
                            label={t("chonThoiGian")}
                            fullWidth
                            size="small"
                            select
                            value={thoiGian}
                            onChange={(e) => handleChangeThoiGian(e)}
                        >
                            {itemThoiGian.map((option) => (
                                <MenuItem key={option.key} value={option.value}>
                                    {option.key}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{}}
                                label={t("toDate")}
                                slotProps={{ textField: { size: "small" } }}
                                value={thoiGianBatDau}
                                onChange={(newValue) => setThoiGianBatDau(newValue)}
                            />
                        </LocalizationProvider>
                    </Col>
                    <HorizontalRuleIcon sx={{ marginTop: "10px" }} />
                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t("fromDate")}
                                slotProps={{ textField: { size: "small" } }}
                                value={thoiGianKetThuc}
                                onChange={(newValue) => setThoiGianKetThuc(newValue)}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                        <Button variant="contained" onClick={onClickSearch} color="success">
                            {t("search")}
                        </Button>
                    </Col>
                    {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
                    {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
                    <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                        <Button variant="outlined" onClick={onClickAdd} startIcon={<AddIcon />} color="warning">
                            {t("add")}
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Space>
                        {activeKey === "1" ? (
                            dataTrangThaiAll[0].dmtrangThaiTourDetails?.map((dt, key) => {
                                return (
                                    <Badge count={dt.soLuong} key={key}>
                                        <Button
                                            disabled={dmTrangThaiId === dt.dmTrangThaiId}
                                            variant="outlined"
                                            onClick={() => onClickFillter(dt.dmTrangThaiId)}
                                        >
                                            {dt.tenTrangThai}
                                        </Button>
                                    </Badge>
                                );
                            })
                        ) : activeKey === "2" ? (
                            dataTrangThaiAll[1].dmtrangThaiTourDetails?.map((dt, key) => {
                                return (
                                    <Badge count={dt.soLuong} key={key}>
                                        <Button
                                            disabled={dmTrangThaiId === dt.dmTrangThaiId}
                                            variant="outlined"
                                            onClick={() => onClickFillter(dt.dmTrangThaiId)}
                                        >
                                            {dt.tenTrangThai}
                                        </Button>
                                    </Badge>
                                );
                            })
                        ) : activeKey === "3" ? (
                            dataTrangThaiAll[2].dmtrangThaiTourDetails?.map((dt, key) => {
                                return (
                                    <Badge count={dt.soLuong} key={key}>
                                        <Button
                                            disabled={dmTrangThaiId === dt.dmTrangThaiId}
                                            variant="outlined"
                                            onClick={() => onClickFillter(dt.dmTrangThaiId)}
                                        >
                                            {dt.tenTrangThai}
                                        </Button>
                                    </Badge>
                                );
                            })
                        ) : activeKey === "4" ? (
                            dataTrangThaiAll[3].dmtrangThaiTourDetails?.map((dt, key) => {
                                return (
                                    <Badge count={dt.soLuong || 0} key={key}>
                                        <Button variant="outlined" onClick={onClickFillter}>
                                            {dt.tenTrangThai}
                                        </Button>
                                    </Badge>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </Space>
                </Row>
            </Col>
        </Row>
    );
};
export default FormTimKiem;
