import React, { useState } from "react";
import { Breadcrumb, theme, Row, Col } from "antd";
import AppRouter from "../components/routing/AppRouter";
import { HomeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Menu_Item } from "../constants/index";
import { Link, useLocation } from "react-router-dom";

const Contents = ({ keySide, keyPath }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [key, setKey] = useState(0);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const items = [
    {
      title: (
        <Link to="/">
          {" "}
          <HomeOutlined />
        </Link>
      ),
    },
  ];
  let item = "";
  let showProcess = false;
  if (location.pathname.length > 0) {
    let path = location.pathname.split("/");
    let link = null;
    for (let i = 1; i < path.length; i++) {
      item = Menu_Item[path[i]];
      if (path[i] === "dich-vu") {
        showProcess = true;
      }
      if (link !== null) {
        link = link + "/" + path[i];
      } else {
        link = path[i];
      }
      items.push({
        title: <Link to={`/${link}`}>{t(item)}</Link>,
      });
    }
  }
  // const steps = [
  //     t("hotel.generalInfo"),
  //     t("chietTinhTour"),
  //     t("moBanTour"),
  // ];

  return (
    <div style={{ height: "100%" }}>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Breadcrumb
            style={{
              margin: "14px 0",
              marginBottom: "20px",
              color: "black",
              fontSize: "16px",
              //   fontWeight: "bold",
            }}
            items={items}
          />
        </Col>
        {/*{showProcess ?*/}
        {/*<Col xs={24} sm={24} md={12} lg={12} xl={10}>*/}
        {/*    <Stepper activeStep={1} alternativeLabel>*/}
        {/*        {steps.map((label) => (*/}
        {/*            <Step key={label}>*/}
        {/*                <StepLabel>{label}</StepLabel>*/}
        {/*            </Step>*/}
        {/*        ))}*/}
        {/*    </Stepper>*/}
        {/*</Col>*/}
        {/*    : null}*/}
      </Row>

      {/* <div style={{ minHeight: 10, background: colorBgContainer }}></div> */}
      <div
        style={{
          minHeight: 380,
          background: colorBgContainer,
          borderRadius: "5px",
          padding: "10px 10px 0 10px",
          height: "100%",
        }}
      >
        <AppRouter />
      </div>
    </div>
  );
};

export default Contents;
