import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Divider, Row, Slider, Space } from "antd";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import baoGiaService from "../../api/bao_gia/baoGiaService";
import moment from "moment";

function valuetext(value) {
  return `${value} đ`;
}
dayjs.extend(customParseFormat);
const FormTimKiemBG = ({
  activeKey,
  dmTrangThaiId,
  setDmTrangThaiId,
  dataTrangThaiAll,
  dataSearch,
  setDataSearch,
  onSearch,
  onReset,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState([20000, 7000000]);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const itemThoiGian = [
    {
      value: 1,
      key: t("thoiGianTao"),
    },
    {
      value: 2,
      key: t("thoiGianDiTour"),
    },
    {
      value: 3,
      key: t("thoiGianKetThuc"),
    },
  ];
  const onClickSearch = () => {
    onSearch();
  };
  const onClickFillter = (data) => {
    setDmTrangThaiId(data);
  };
  const onClickAdd = () => {
    return navigate("/bao-gia/new");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const handleChangeDataSearch = (e, typeDate) => {
    let name = e.target?.name;
    let value = e.target?.value;
    const newDataSearch = { ...dataSearch };
    if (name === "codeOrName" || name === "loaiThoiGian") {
      newDataSearch[name] = value;
    } else {
      let day = moment(e.$d).format("DD/MM/YYYY");
      if (typeDate === "tuNgay") {
        newDataSearch.tuNgay = day;
      } else {
        newDataSearch.denNgay = day;
      }
      if (newDataSearch.tuNgay && newDataSearch.denNgay)
        newDataSearch.thoiGianTour =
          newDataSearch.tuNgay + "-" + newDataSearch.denNgay;
    }
    setDataSearch(newDataSearch);
  };
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <OutlinedInput
            variant="outlined"
            name="codeOrName"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            fullWidth
            size="small"
            placeholder={t("code/NameQuote")}
            value={dataSearch.codeOrName}
            onChange={(e) => handleChangeDataSearch(e)}
          />
        </Col>
        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
        <Col style={{marginLeft:"10px"}} xs={12} sm={12} md={12} lg={12} xl={3}>
          <TextField
            variant="outlined"
            name="loaiThoiGian"
            label={t("chonThoiGian")}
            fullWidth
            select
            size="small"
            value={dataSearch.loaiThoiGian}
            onChange={(e) => handleChangeDataSearch(e)}
          >
            {itemThoiGian.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </TextField>
        </Col>
        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
        <Col style={{marginLeft:"10px"}} xs={12} sm={12} md={12} lg={12} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("toDate")}
              value={
                dataSearch.tuNgay
                  ? dayjs(formatStringDateView(dataSearch.tuNgay))
                  : null
              }
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: "small" } }}
              onChange={(date) => handleChangeDataSearch(date, "tuNgay")}
            />
          </LocalizationProvider>
        </Col>
        <HorizontalRuleIcon sx={{ marginTop: "12px" }} />
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("fromDate")}
              value={
                dataSearch.denNgay
                  ? dayjs(formatStringDateView(dataSearch.denNgay))
                  : null
              }
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: "small" } }}
              onChange={(date) => handleChangeDataSearch(date, "denNgay")}
            />
          </LocalizationProvider>
        </Col>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          // onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <DialogContent>
            <Row
              style={{
                display: "flex",
                width: "400px",
                alignItems: "center",
              }}
            >
              <Col span={5}>
                <b>Loại tiền</b>
              </Col>
              <Col span={9}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  // name="thoiGianApDung"
                  // onChange={(e) => setThoiGianApDung(e.target.value)}
                  // value={thoiGianApDung}
                >
                  <MenuItem value={0}>VND</MenuItem>
                  <MenuItem value={1}>USD</MenuItem>
                </TextField>
              </Col>
            </Row>
            <Divider></Divider>
            <Row>
              <Col span={5}>
                <b>Mức giá</b>
              </Col>
            </Row>
            <Row>
              <FormGroup
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "400px",
                }}
              >
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Label"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Required"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Disabled"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Label"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Required"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Disabled"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Label"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Required"
                />
                <FormControlLabel
                  sx={{ width: "120px" }}
                  control={<Checkbox />}
                  label="Disabled"
                />
              </FormGroup>
            </Row>
            <Divider />
            <Row>
              <Col span={5}>
                <b>Khoảng giá</b>
              </Col>
            </Row>
            <Row>
              <Col span={4} />
              <Col span={16}>
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={20000}
                  max={7000000}
                />
              </Col>
            </Row>
            <Row>
              <Col span={4} />
              <Col span={7}>
                <TextField
                  label={t("tour.oto.minValue")}
                  value={`${value[0]} đ`}
                  size="small"
                  disabled
                ></TextField>
              </Col>
              <Col span={2}></Col>
              <Col span={7}>
                <TextField
                  label={t("tour.oto.maxValue")}
                  value={`${value[1]} đ`}
                  size="small"
                  disabled
                ></TextField>
              </Col>
            </Row>
            <Divider />
            <Row style={{ justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  marginRight: "5px",
                  backgroundColor: "#898989",
                }}
              >
                {t("action.reset")}
              </Button>
              <Button variant="contained" sx={{ textTransform: "none" }}>
                {t("action.apply")}
              </Button>
            </Row>
          </DialogContent>
        </Menu>

        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          <Space>
            <Button
                variant="contained"
                onClick={onClickSearch}
                color="success"
                style={{ margin: "0 10px" }}
            >
              {t("search")}
            </Button>
            <Button variant="contained" onClick={onReset} color="primary">
              {t("action.reset")}
            </Button>
          </Space>

        </Col>
        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <Button
            variant="outlined"
            onClick={onClickAdd}
            startIcon={<AddIcon />}
            color="warning"
          >
            {t("add")}
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Space>
          {activeKey === "1" ? (
            dataTrangThaiAll[0].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : activeKey === "2" ? (
            dataTrangThaiAll[1].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : activeKey === "3" ? (
            dataTrangThaiAll[2].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : activeKey === "4" ? (
            dataTrangThaiAll[3].dmtrangThaiTourDetails?.map((dt, key) => {
              return (
                <Badge count={dt.soLuong || 0} key={key}>
                  <Button
                    disabled={dmTrangThaiId === dt.dmTrangThaiId}
                    variant="outlined"
                    onClick={() => onClickFillter(dt.dmTrangThaiId)}
                  >
                    {dt.tenTrangThai}
                  </Button>
                </Badge>
              );
            })
          ) : (
            <></>
          )}
        </Space>
      </Row>
    </>
  );
};
export default FormTimKiemBG;
