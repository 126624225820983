import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Radio, Row, Button, Table } from "antd";
import { MenuItem, TextField } from "@mui/material";
import dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
// import baoGiaService from "../../../../api/bao_gia/baoGiaService";

export default function TongKet({ baoGiaTongQuanId, tongGia, setReload }) {
  const { t } = useTranslation();
  const [valueRadio, setValueRadio] = useState("noVat");
  const [isOpenCH, setIsOpenCH] = useState(false);
  const [dmVat, setDmVat] = useState([]);
  const [vat, setVat] = useState(0);

  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValueRadio(e.target.value);
    if (e.target.value === "vat") {
      handleSaveThueGTGT(vat, true);
    } else {
      setVat(0);
      handleSaveThueGTGT(0, false);
    }
  };
  useEffect(() => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return { value: it.vat, label: it.vat_str, id: it.id };
            });
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error getDmVat response: ====" + response);
      });
  }, []);
  const options = [
    {
      label: t("khongApDungThue"),
      value: "noVat",
    },
    {
      label: t("apDungThue"),
      value: "vat",
    },
  ];

  const columnsTT = [
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
    },
    {
      title: t("totalPriceNet/Group"),
      dataIndex: "tongGiaNetTheoNhom",
    },
    {
      title: t("totalPriceSell/Group"),
      dataIndex: "tongGiaBanTheoNhom",
    },
    {
      title: t("priceNet/Persion"),
      dataIndex: "tongGiaNetTheoNguoi",
    },
    {
      title: t("priceSell/Person"),
      dataIndex: "tongGiaBanTheoNguoi",
    },
  ];
  const handleTyLe = (params) => {
    setIsOpenCH(params);
  };

  const handleChangeVat = (value) => {
    setVat(value);
    handleSaveThueGTGT(value, true);
  };

  const handleSaveThueGTGT = (thue, isApDung) => {
    let dataReq = {
      baoGiaTongQuanId: baoGiaTongQuanId,
      apDungThueGTGT: isApDung,
      thueGTGT: thue,
    };
    // baoGiaService
    //   .saveThueGTGT(dataReq)
    //   .then((res) => {
    //     setReload(true);
    //   })
    //   .catch((err) => console.log("Save Thue GTGT Error: " + err));
  };

  const onReset = () => {
    setReload(true);
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Radio.Group
            style={{ marginTop: "10px" }}
            options={options}
            onChange={onChangeRadio}
            value={valueRadio}
          />
          {valueRadio === "vat" ? (
            <TextField
              select
              value={vat}
              variant="standard"
              style={{ width: "60px" }}
              onChange={(e) => handleChangeVat(e.target.value)}
            >
              {dmVat.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10}></Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button
          // onClick={() => handleTyLe(true)}
          >
            Markup All
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={24}>
          <Table columns={columnsTT} dataSource={tongGia} />
        </Col>
      </Row>
      {/* <ModalCauHinhTyLe
        open={isOpenCH}
        handleTyLe={handleTyLe}
        baoGiaTongQuanId={baoGiaTongQuanId}
        onReset={onReset}
      /> */}
    </>
  );
}
