import axios from "axios";
import authHeader from "../../../auth_header";
import { API_BASE_URL } from "../../../../constants";

const getChietTinhSaleOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/layTTCTSaleOtoById", data, {
    headers: authHeader(),
  });
};
const saveChietTinhSaleOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveCTSaleOto", data, {
    headers: authHeader(),
  });
};
const saveChietTinhDoTuoi = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveCTDoTuoiSaleKhachSan", data, {
    headers: authHeader(),
  });
};

const deleteChietTinhDoTuoi = (data) => {
  return axios.post(API_BASE_URL + "baogia/deleteCTDoTuoiSaleKhachSan", data, {
    headers: authHeader(),
  });
};
const saveCTSaleOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/saveCTSaleOto", data, {
    headers: authHeader(),
  });
};
const deleteCTSaleDichVuOto = (data) => {
  return axios.post(API_BASE_URL + "baogia/deleteCTSaleDichVuOto", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getDmSoCho = (data) => {
  return axios.post(API_BASE_URL + "category/getDmSoCho", data, {
    headers: authHeader(),
  });
};

const getDmLoaiXe = (data) => {
  return axios.post(API_BASE_URL + "category/getDmLoaiXe", data, {
    headers: authHeader(),
  });
};

const getDmHinhThucGia = (data) => {
  return axios.post(API_BASE_URL + "category/getDmHinhThucGia", data, {
    headers: authHeader(),
  });
};

const getNccOto = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccOto", data, {
    headers: authHeader(),
  });
};
export default {
  getChietTinhSaleOto,
  saveChietTinhSaleOto,
  saveChietTinhDoTuoi,
  deleteChietTinhDoTuoi,
  saveCTSaleOto,
  deleteCTSaleDichVuOto,
  getQuocGia,
  getDmLoaiXe,
  getDmSoCho,
  getDmHinhThucGia,
  getNccOto,
};
