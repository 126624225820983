import "./App.css";
import React, { Fragment, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main.jsx";
import Login from "../components/login/Login";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const App = () => {
    return (
        <Suspense fallback="loading">
            <DndProvider backend={HTML5Backend}>
                <Router>
                    <Fragment>
                        <Routes>
                            <Route exact path="*" element={<Main />} />
                            <Route exact path="/login" element={<Login />} />
                        </Routes>
                    </Fragment>
                </Router>
            </DndProvider>
        </Suspense>
    );
};
export default App;
