import React, {  useState } from "react";
import {useTranslation} from "react-i18next";
import '../assets/css/containers/topbar.css'
import {
    AppstoreOutlined,
    MessageOutlined,
    BellOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import {Space, Col, Row, Tooltip} from 'antd';
import ReactFlagsSelect from "react-flags-select";

const TopBar = () => {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    const [select, setSelect] = useState("VN");
    const onSelect = (code) => {
        setSelect(code);
        let lang = ""
        if (code === "VN"){
            lang = "vi"
        }else {
            lang ="en"
        }
        i18n.changeLanguage(lang).then(r => r);
    }
    // const onChangeLang = (e) => {
    //     const lang_code = e.target.value;
    //     i18n.changeLanguage(lang_code).then(r => r);
    // };
    const logout=()=>{
        localStorage.clear();
        return navigate("/login");
    }
    return (

        <div className="topBar">
            <div className="topBarLeft">
                {/*<Row>*/}
                {/*   {t("home")}*/}
                {/*</Row>*/}
            </div>
            <div className="topBarRight">
                <Row>
                    <Col span={18}></Col>
                    <Col span={5}>
                        <Space size={"middle"}>
                            <MessageOutlined style={{fontSize: '20px'}}/>
                            <BellOutlined style={{fontSize: '20px'}}/>
                            <AppstoreOutlined style={{fontSize: '20px'}}/>
                            {/*<select defaultValue={i18n.language} onChange={onChangeLang}>*/}
                            {/*    <option key='vi' value='vi'>VN</option>*/}
                            {/*    <option key='en' value='en'>EN</option>*/}
                            {/*</select>*/}
                            <ReactFlagsSelect
                                selected={select}
                                onSelect={onSelect}
                                countries={["VN", "US"]}
                            />
                            <Tooltip><LogoutOutlined style={{fontSize: '20px'}} onClick={logout}/></Tooltip>
                        </Space>
                    </Col>

                </Row>
            </div>
        </div>


    );
}

export default TopBar;
