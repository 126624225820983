import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ThongTinChung from "./thongtinchung/ThongTinChung";
import DichVuTour from "./dichVuTour/DichVuTour";
import DichVuLe from "./dichVuLe/DichVuLe";
import DanhSachDoan from "./danhSachDoan/DanhSachDoan";
import ThucThu from "./thucThu/ThucThu";
import TongChiPhi from "./tongChiPhi/TongChiPhi";
import ChiPhiUocTinh from "./chiPhiUocTinh/ChiPhiUocTinh";
import ChuongTrinhTour from "./ChuongTrinhTour/ChuongTrinhTour";
import ThongTinTour from "./dichVuTour/ThongTinTour";
import SaleRate from "./dichVuTour/SaleRate";

const RouterBooking = () => {
  const location = useLocation();
  const display = () => {
    let bookingId = 0;
    let hanhDong = "new";
    if (location.search.length > 0) {
      let id = location.search.split("=");
      bookingId = id[1];
      if (location.pathname.indexOf("edit") !== -1) hanhDong = "edit";
    }
    if (location.pathname.length > 0) {
      let path = location.pathname.split("/");
      switch (path[2]) {
        case "thong-tin-chung":
          return <ThongTinChung bookingId={bookingId} />;
        case "dich-vu-tour":
          if (path[3] === "sale-rate" || path[4] === "sale-rate")
            return <SaleRate bookingId={bookingId} />;
          else if (path[3] === "thong-tin-tour")
            return <ThongTinTour bookingId={bookingId} action={hanhDong} />;
          else return <DichVuTour bookingId={bookingId} action={hanhDong} />;
        case "dich-vu-le":
          return <DichVuLe />;
        case "danh-sach-doan":
          return <DanhSachDoan bookingId={bookingId} action={hanhDong} />;
        case "tong-chi-phi":
          return <TongChiPhi bookingId={bookingId} action={hanhDong} />;
        case "thuc-thu":
          return <ThucThu bookingId={bookingId} />;
        case "chi-phi-uoc-tinh":
          return <ChiPhiUocTinh bookingId={bookingId} action={hanhDong} />;
        case "chuong-trinh-tour":
          return <ChuongTrinhTour bookingId={bookingId} action={hanhDong} />;
        default:
          return null;
      }
    }
  };
  return <>{display()}</>;
};
export default RouterBooking;
