import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, FormControlLabel, InputAdornment, Radio, TextField } from "@mui/material";
import Buttons from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { Col, Divider, Modal, Row, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tourService from "../../../../api/tour/tour.service";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
const ModalTourSanPham = ({ isOpen, handleFinish, handleCancel }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [recordSelected, setRecordSelected] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        let data = {
            loaiHinhDuLich: null,
            maTour: null,
            tenTour: null,
            soNgay: null,
            loaiTour: null,
            booking: null,
            hangTour: null,
            trangThai: null,
            nguoiTao: null,
            pageIndex: 1,
            pageSize: 10000,
        };
        tourService
            .getTourSp(data)
            .then(function (response) {
                console.log("Response: ====", response.data);
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataArr.push({
                                stt: i + 1,
                                id: data[i].id,
                                maTour: data[i].maTour,
                                typeTourism: data[i].loaiHinhDuLich,
                                tenTour: data[i].tenTour,
                                soNgay: data[i].soNgay + "n/" + data[i].soDem + "đ",
                                typeTour: data[i].loaiTour,
                                loaiTour: data[i].loaiTour,
                                booking: data[i].booking,
                                hangTour: data[i].hangTour,
                                trangThai: data[i].trangThai,
                                nguoiTao: data[i].nguoiTao,
                                lanSuaCuoi: data[i].lanSuaCuoi,
                            });
                        }
                    }
                    setDataSource(dataArr);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const columns = [
        {
            headerName: t("select"),
            field: "select",
            key: "select",

            headerAlign: "center",
            renderCell: (record) => {
                return (
                    <>
                        <Radio
                            checked={recordSelected.id === record.row.id ? true : false}
                            onClick={() => setRecordSelected(record.row)}
                        />
                    </>
                );
            },
        },
        { headerName: t("stt"), field: "id", key: "id", headerAlign: "center" },
        {
            headerName: t("maTour"),
            field: "maTour",
            key: "maTour",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("tenTour"),
            field: "tenTour",
            key: "tenTour",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("soNgay"),
            field: "soNgay",
            key: "soNgay",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("typeTourism"),
            field: "typeTourism",
            key: "typeTourism",

            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("category.typeTour"),
            field: "typeTour",
            key: "typeTour",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (record) => {
                return <Tag color="green">{record.row.typeTour}</Tag>;
            },
        },
        {
            headerName: t("hangTour"),
            field: "hangTour",
            key: "hangTour",

            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("tour.chiTiet") + " Tour",
            field: "ChiTiet",
            key: "ChiTiet",

            headerAlign: "center",
            renderCell: (record) => {
                return (
                    <Link to={"/dich-vu/tour-san-pham/edit?id=" + record.row.id} target="_blank" rel="noreferrer">
                        {t("watchHere")}
                    </Link>
                );
            },
        },
    ];
    return (
        <>
            <Modal title="Tour module" width={1500} open={isOpen} onCancel={handleCancel} footer={null}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                        <TextField
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t("Code/TourProduct")}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                        <Button size="small" variant="outlined">
                            {t("search")}
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <DataGrid autoHeight rows={dataSource} columns={columns} />
                    </Col>
                </Row>
                {/* <Row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={apDungThongTinTour}
                                onChange={(e) => setApDungThongTinTour(e.target.checked)}
                            />
                        }
                        label={t("action.apply") + "" + t("thongTinTour")}
                    />
                </Row> */}
                <Divider />

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={10}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={() => handleFinish(recordSelected)} // handleSaveChooseService
                            startIcon={<SaveIcon />}
                        >
                            {t("action.ok")}
                        </Button>
                        <Button
                            key="reset"
                            startIcon={<RestartAltIcon />}
                            style={{
                                backgroundColor: "#898989",
                                color: "white",
                                marginRight: "5px",
                            }}
                            // onClick={handleResetChooseService}
                        >
                            {t("action.reset")}
                        </Button>
                        <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => handleCancel()}>
                            {t("action.cancel")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ModalTourSanPham;
