import { TextField } from "@mui/material";
import Buttons from "@mui/material/Button";
import { Col, Modal, Row, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const ModalCopyCHietTinh = ({ isOpen, handleFinish, handleCancel }) => {
    const [hangSao, setHangSao] = useState(0);
    const { t } = useTranslation();
    return (
        <>
            <Modal
                open={isOpen}
                onCancel={handleCancel}
                onOk={handleFinish}
                footer={null}
                width={700}
                title={"Copy " + t("Worksheet")}
            >
                <Row>
                    Để sao chép bảng chiết tính, bạn vui lòng <b> Chọn hạng sao </b> cần sao chép sau đó nhấp vào nút{" "}
                    <b>"Copy"</b>. Nếu bạn không muốn sao chép, bạn có thể nhấp vào nút <b>"Hủy"</b> để hủy bao thao
                    tác.
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextField
                            error
                            style={{ margin: 5 }}
                            select
                            fullWidth
                            value={hangSao}
                            label="Chọn hạng sao"
                            helperText="Bạn vui lòng chọn hạng sao để thực hiện copy bảng chiết tính"
                        ></TextField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                        <Space>
                            <Buttons variant="outlined" key="clear">
                                {t("huy")}
                            </Buttons>
                            <Buttons
                                disabled={hangSao ? false : true}
                                variant="contained"
                                type="submit"
                                onClick={() => handleFinish()}
                            >
                                Copy
                            </Buttons>
                        </Space>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ModalCopyCHietTinh;
