import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Affix, Col, Divider, Form, Modal, notification, Row, Space, Tabs } from "antd";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import dat_dich_vu_service from "../../../../api/dieu_hanh/dat_dich_vu/dat_dich_vu_service";
import TextArea from "antd/es/input/TextArea";
import dm_trang_thai from "../../../../api/category/dm_trang_thai";

const ModalXacThucDV = ({ isOpen, record, handleCancels }) => {
    const [open, setOpen] = useState(isOpen);
    const [thoiGianSuDung, setThoiGianSuDung] = useState(null);
    const [soNgaySuDung, setSoNgaySuDung] = useState("");
    const [nhaCC, setNhaCC] = useState("");
    const [dichVu, setDichVu] = useState("");
    const [soLuong, setSoLuong] = useState("");
    const [tongTien, setTongTien] = useState("");
    const [dieuHanh, setDieuHanh] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [tinhTrang, setTinhTrang] = useState("");
    const [bottom, setBottom] = useState(50);
    const [chiTietDichVuResponse, setChiTietDichVuResponse] = useState([]);
    const [lichSuXacThucResponses, setLichSuXacThucResponses] = useState([]);
    const [noiDung, setNoiDung] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [tinhTrangOption, setTinhTrangOption] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        getData({
            tranxOpRateId: 1, // id test của BE: 1 ks, 3 nh, 5 oto
        });
    }, []);
    useEffect(() => {
        dm_trang_thai.getDmTrangThai().then((response) => {
            if (response.data.code === "00") {
                let tinhTrangOption = [];
                response.data.data.forEach((t) => {
                    if (
                        t.ten_trang_thai === "Đã thanh toán" ||
                        t.ten_trang_thai === "Chưa thanh toán" ||
                        t.ten_trang_thai === "Nháp" ||
                        t.ten_trang_thai === "Không hợp lệ" ||
                        t.ten_trang_thai === "Chưa tạo phiếu"
                    ) {
                        tinhTrangOption.push({
                            value: t.id,
                            label: t.ten_trang_thai,
                        });
                    }
                });
                console.log("respons trang thai: " + JSON.stringify(tinhTrangOption));
                setTinhTrangOption(tinhTrangOption);
            }
        });
    }, []);
    const onFinish = (e) => {
        e.preventDefault();
        dat_dich_vu_service
            .saveOpXacThucDichVu({
                tranxOpRateId: 1,
                noiDung: noiDung,
                tinhTrangId: 0,
            })
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                    handleCancels();
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                console.log("Update baoGiaTongQuanError ...");
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const getData = async (data) => {
        let response = await dat_dich_vu_service.layTTOpXacThucDichVu(data);
        if (response.data.code === "00") {
            let data = response.data.data;
            setNhaCC(data.nhaCC);
            setThoiGianSuDung(data.thoiGianSuDung);
            setSoNgaySuDung(data.soNgaySuDung);
            setTongTien(data.tongTien);
            setChiTietDichVuResponse(data.chiTietDichVuResponse);
            setNoiDung(data.noiDung);
            setDieuHanh(data.dieuHanh);
            setTinhTrang(data.tinhTrang);
            setLichSuXacThucResponses(
                data.lichSuXacThucResponses?.map((dt, key) => {
                    return { ...dt, stt: key + 1, id: key };
                })
            );
        }
    };

    useEffect(() => {}, []);

    const handleClear = () => {
        handleCancels();
    };
    const dataRender = () => {
        let html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <TextField
                            sx={{
                                marginTop: 1,
                            }}
                            onChange={(e) => setThoiGianSuDung(e.target.value)}
                            value={thoiGianSuDung}
                            fullWidth
                            disabled
                            label={t("usedTime")}
                        ></TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            sx={{
                                marginTop: 1,
                            }}
                            type="number"
                            onChange={(e) => setSoNgaySuDung(e.target.value.replace(/\D/g, ""))}
                            value={soNgaySuDung}
                            fullWidth
                            disabled
                            label={t("numberDayUse")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <TextField
                            sx={{
                                marginTop: 1,
                            }}
                            onChange={(e) => setNhaCC(e.target.value)}
                            value={nhaCC}
                            fullWidth
                            disabled
                            label={t("supplier")}
                        ></TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        {/* <TextField
                            sx={{
                                marginTop: 1,
                            }}
                            onChange={(e) => setDichVu(e.target.value)}
                            value={dichVu}
                            fullWidth
                            label={t("service")}
                        /> */}
                        <TextField
                            sx={{
                                marginTop: 1,
                            }}
                            onChange={(e) => setTongTien(e.target.value)}
                            value={tongTien}
                            disabled
                            fullWidth
                            label={t("tongTien")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <Row>
                            <TextField
                                sx={{
                                    marginTop: 1,
                                }}
                                onChange={(e) => setDieuHanh(e.target.value)}
                                value={dieuHanh}
                                disabled
                                fullWidth
                                label={t("Executive")}
                            ></TextField>
                        </Row>
                        <Row>
                            <TextField
                                sx={{
                                    marginTop: 1,
                                }}
                                onChange={(e) => setTinhTrang(e.target.value)}
                                value={tinhTrang}
                                select
                                fullWidth
                                required
                                label={t("status")}
                            >
                                {tinhTrangOption.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextArea
                            sx={{
                                marginTop: 1,
                            }}
                            onChange={(e) => setGhiChu(e.target.value)}
                            value={ghiChu}
                            fullWidth
                            placeholder={t("notes")}
                            multiline
                            rows={5}
                            style={{
                                resize: "none",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const onChange = (key) => {
        console.log(key);
    };
    const colums_dich_vu = [
        {
            title: "#",
        },
        {
            title: t("service"),
        },
        {
            title: t("quantity"),
        },
        {
            title: t("soNgay"),
        },
        {
            title: t("tour.donGia"),
        },
        {
            title: t("hotel.roomClass.intoMoney"),
        },
    ];
    const colums_ls = [
        {
            headerName: "#",
            field: "stt",
            key: "stt",
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("handler"),
            field: "nguoiXuLy",
            key: "nguoiXuLy",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("action"),
            field: "hanhDong",
            key: "hanhDong",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("status"),
            field: "tinhTrang",
            key: "tinhTrang",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("noiDung"),
            field: "noiDung",
            key: "noiDung",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("time"),
            field: "thoiGian",
            key: "thoiGian",
            flex: 1,
            headerAlign: "center",
        },
    ];
    const items = [
        {
            key: "1",
            label: t("tour.chiTiet") + " " + t("service"),
            children: (
                <table>
                    <thead>
                        <tr>
                            {colums_dich_vu.map((item, index) => (
                                <th key={index}>{item.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {chiTietDichVuResponse?.dichVu?.dichVuKsResponse.map((item, index) => {
                            let id = 1;
                            return item.dichVuDetail.map((itemDv, indexDv) => {
                                return (
                                    <tr key={id}>
                                        <td>{id++}</td>
                                        {indexDv === 0 ? (
                                            <td rowSpan={item.dichVuDetail.length}>{item.dichVu}</td>
                                        ) : (
                                            <></>
                                        )}

                                        <td>{itemDv.soLuong}</td>
                                        <td>{itemDv.soNgay}</td>
                                        <td>{itemDv.donGia}</td>
                                        <td>{itemDv.thanhTien}</td>
                                    </tr>
                                );
                            });
                        })}
                    </tbody>
                </table>
            ),
        },
        {
            key: "2",
            label: t("noiDung"),
            children: (
                <TextArea
                    sx={{
                        marginTop: 1,
                    }}
                    onChange={(e) => setNoiDung(e.target.value)}
                    value={noiDung}
                    fullWidth
                    multiline
                    rows={15}
                    // style={{
                    //     resize: "none",
                    // }}
                />
            ),
        },
        {
            key: "3",
            label: t("confirmationHistory"),
            children: (
                <Row style={{ marginTop: "1%" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <DataGrid rows={lichSuXacThucResponses} columns={colums_ls} autoHeight fullWidth />
                    </Col>
                </Row>
            ),
        },
    ];
    // {
    //     "nguoiXuLy": "Phạm Đức Anh",
    //     "hanhDong": "Thay đổi tình trạng",
    //     "tinhTrang": "Đã hủy",
    //     "noiDung": "Nam",
    //     "thoiGian": "20/07/2024 11:58"
    // }

    return (
        <div>
            {contextHolder}
            <Modal
                title={t("dieuHanh.xacThucDv")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
                // bodyStyle={{ height: 800 }}
            >
                <Divider />
                <Box component="form" noValidate>
                    {dataRender()}

                    <Affix offsetBottom={bottom}>
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <Row>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            color: "#fff",
                                            height: 60,
                                            paddingInline: 50,
                                            lineHeight: "60px",
                                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Space>
                                            <Button
                                                variant="contained"
                                                onClick={onFinish}
                                                type="submit"
                                                startIcon={<Save />}
                                            >
                                                {t("action.ok")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#898989",
                                                    color: "white",
                                                }}
                                                key="reset"
                                                startIcon={<RestartAlt />}
                                                onClick={(e) => handleClear(e)}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<Delete />}
                                                onClick={handleCancels}
                                                key="clear"
                                            >
                                                {t("action.cancel")}
                                            </Button>
                                        </Space>
                                    </div>
                                </Row>
                            </Affix>
                        </Form.Item>
                    </Affix>
                </Box>
            </Modal>
        </div>
    );
};
export default ModalXacThucDV;
