import React, {useEffect, useState} from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import GeneralInfo from "./tabThongTinChung/GeneralInfo";
import { useLocation } from "react-router-dom";
import ChuongTrinhTour from "./tabChuongTrinhTour/ChuongTrinhTour";
import ThongTinTour_new from "./tabThongTinTour/ThongTinTour_new";
import FormProcess from "../FormProcess";

const AddTourSp = () => {
  const onChange = (key) => {
    console.log(key);
  };
  const location = useLocation();
  const [isView, setIsView] = useState(location.pathname.includes("view"));
  const [isNew, setIsNew] = useState(location.pathname.includes("new"));
  const [isDis, setIsDis] = useState(
    location.pathname.includes("new") ? true : false
  );
  const [soNgay, setSoNgay] = useState();
  const [activeKey, setActiveKey] = useState(
    location.pathname.includes("ql-hanh-trinh") ? "2" : "1"
  );
  const [idTour, setIdTour]=useState(0);
  useEffect(() => {
    getDvId();
  },[]);
  const getDvId = (value) => {
    let Id = 0;
    if (value === undefined) {
      if (location.search.length > 0) {
        let id = location.search.split("=");
        Id = id[1];
      }
    } else {
      Id = value;
    }
    setIdTour(Id);
  };
  const saveNew = (value)=>{
    setIdTour(value)
  }
  const getDv = () => {
    let name = "";
    if (location.pathname.length > 0) {
      let id = location.pathname.split("/");
      name = id[id.length - 2];
    }
    return name;
  };
  const { t } = useTranslation();
  const addId = (status) => {
    if (isNew) {
      if (status !== undefined) {
        if (status) {
          return setIsDis(false);
        } else {
          return setIsDis(true);
        }
      } else {
        return setIsDis(false);
      }
    } else {
      return setIsDis(false);
    }
  };
  const onChangeSoNgay = (value) => {
    setSoNgay(value);
  };
  const items = [
    {
      key: "1",
      label: t("hotel.generalInfo"),
      children: (
        <GeneralInfo
          dvName={getDv()}
          dvId={idTour}
          addId={addId}
          isView={isView}
          setSoNgayAll={onChangeSoNgay}
          newId={saveNew}
        />
      ),
    },
    {
      key: "2",
      label: t("chuongTrinhTour"),
      children: (
        <ChuongTrinhTour
          dvName={getDv()}
          dvId={idTour}
          addId={addId}
          isView={isView}
          soNgay={soNgay}
        />
      ),
      disabled: isDis,
    },
    {
      key: "3",
      label: t("thongTinTour"),
      children: (
        <ThongTinTour_new
          dvName={getDv()}
          dvId={idTour}
          addId={addId}
          isView={isView}
        />
      ),
      disabled: isDis,
    },
  ];
  return (
    <div>
      <FormProcess text="tourSanPham" step={2} />
      {/*<Divider/>*/}
      <Tabs
        defaultActiveKey={activeKey}
        items={items}
        onChange={onChange}
        style={{ marginLeft: "10px" }}
      />
    </div>
  );
};
export default AddTourSp;
