import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getTTTongChiPhiByThongTinChungId = (data) => {
  return axios.post(
    API_BASE_URL + "booking/layTTTongChiPhiByThongTinChungId",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateTongChiPhi = (data) => {
  return axios.post(API_BASE_URL + "booking/updateTongChiPhi", data, {
    headers: authHeader(),
  });
};
export default {
  getTTTongChiPhiByThongTinChungId,
  updateTongChiPhi,
};
