import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import Save from "@mui/icons-material/Save";
import Search from "@mui/icons-material/Search";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Col, Row, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Thuc_chi_service from "../../../../api/dieu_hanh/thuc_chi/Thuc_chi_service";
import ModalThucChi from "./ModalThucChi";

const ThucChi = ({ dataTQ }) => {
    const { t } = useTranslation();
    const [countNoti, setCountNoti] = useState({
        tatCa: 0,
        chuaThanhToan: 0,
        daThanhToan: 0,
    });
    const [tranxOpRateId, setTranxOpRateId] = useState(0);
    const [reset, setReset] = useState(false);
    const [action, setAction] = useState("add");
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState("0");
    const [isOpenTC, setIsOpenTC] = useState(false);
    const [phieuChiResponses, setPhieuChiResponses] = useState([]);
    const [thongKe, setThongKe] = useState({});
    const [textSearch, setTextSearch] = useState();
    const [opPhieuChiId, setOpPhieuChiId] = useState(0);
    useEffect(() => {
        getData({
            opThongTinChungId: 3,
        });
    }, [status, reset]);
    const getData = async (data) => {
        var response = await Thuc_chi_service.layTTOpThucChiById(data);
        if (response.data.code === "00") {
            debugger;
            setPhieuChiResponses(
                response.data.data.phieuChiResponses.map((dt, key) => {
                    return { ...dt, stt: key + 1, id: key };
                })
            );
            setThongKe(response.data.data.thongKe);
            setCountNoti({
                tatCa: response.data.data.totalPhieuChi,
                daThanhToan: response.data.data.daThanhToan,
                chuaThanhToan: response.data.data.chuaThanhToan,
                tongChiDuToan: formatter.format(response.data.data.tongChiDuToan),
                tongChiThucTe: formatter.format(response.data.data.tongChiThucTe),
                daChi: formatter.format(response.data.data.daChi),
                conLai: formatter.format(response.data.data.conLai),
            });
        }
    };
    const handleSearch = () => {
        setIsOpen(true);
    };
    let formatter = new Intl.NumberFormat("en-US", {});

    const columns = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            align: "center",
            width: 50,
            type: "number",
            headerAlign: "center",
        },
        {
            headerName: t("booking.soPhieu"),
            field: "soPhieu",
            key: "soPhieu",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("booking.ngayTaoPhieu"),
            field: "ngayTaoPhieu",
            key: "ngayTaoPhieu",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("booking.ngayThanhToan"),
            field: "ngayThanhToan",
            key: "ngayThanhToan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("loaiThanhToan"),
            field: "loaiThanhToan",
            key: "loaiThanhToan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("tenKH"),
            field: "khachHang",
            key: "khachHang",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("nguoiNhan"),
            field: "nguoiNhan",
            key: "nguoiNhan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("performer"),
            field: "nguoiThucHien",
            key: "nguoiThucHien",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("hinhThucThanhToan"),
            field: "hinhThucThanhToan",
            key: "hinhThucThanhToan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("booking.soTien"),
            field: "soTien",
            key: "soTien",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                return formatter.format(value);
            },
        },
        {
            headerName: t("booking.lyDo"),
            field: "lyDo",
            key: "lyDo",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("status"),
            field: "trangThai",
            key: "trangThai",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ value, row }) => {
                return handleTinhTrang(value);
            },
        },
        {
            headerName: t("action"),
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                return (
                    <div>
                        <IconButton onClick={() => handleEdit(row)} variant="contained" color="primary">
                            <Check />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(row)} variant="contained" color="secondary">
                            <Delete />
                        </IconButton>
                    </div>
                );
            },
        },
    ];
    const handleEdit = (params) => {};
    const handleDelete = (params) => {};

    const handleTinhTrang = (value) => {
        if (value === "Yêu cầu điều hành") {
            return <Tag color="magenta">{value}</Tag>;
        } else if (value === "") {
            return <Tag color="Đã hủy">{value}</Tag>;
        } else if (value === "Chờ hủy") {
            return <Tag color="volcano">{value}</Tag>;
        } else if (value === "Đã đặt") {
            return <Tag color="orange">{value}</Tag>;
        } else if (value === "Chờ phản hồi") {
            return <Tag color="gold">{value}</Tag>;
        } else if (value === "Chưa đặt") {
            return <Tag color="lime">{value}</Tag>;
        } else if (value === "") {
            return <Tag color="green">{value}</Tag>;
        } else if (value === "Tạm dừng") {
            return <Tag color="cyan">{value}</Tag>;
        } else if (value === "Đã thanh toán") {
            return <Tag color="blue">{value}</Tag>;
        } else if (value === "Nháp") {
            return <Tag color="geekblue">{value}</Tag>;
        } else if (value === "Chưa thanh toán") {
            return <Tag color="purple">{value}</Tag>;
        } else if (value === "Chưa tạo phiếu") {
            return <Tag color="blue-inverse">{value}</Tag>;
        }
        return <Tag color="cyan">{value}</Tag>;
    };
    const handleClear = (params) => {};

    const handleHidden = () => {
        setIsOpenTC(false);
    };
    const handleCreatePhieuChi = (tranxOpRateId) => {
        setTranxOpRateId(0);
        setOpPhieuChiId(0);
        setIsOpenTC(true);
        setAction("add");
    };

    return (
        <>
            <Row>
                <Col
                    span={24}
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "10px",
                        justifyContent: "space-around",
                        display: "flex",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        textAlign: "center",
                    }}
                >
                    <div style={{ fontWeight: "bold" }}>
                        <div style={{ fontSize: "20px" }}>{t("totalService")}</div>
                        <div style={{ fontSize: "50px", alignSelf: "flex-end" }}>{countNoti?.tatCa}</div>
                    </div>
                    <div
                        style={{
                            justifyContent: "space-around",
                            alignItems: "center",
                            display: "flex",
                            width: "70%",
                        }}
                    >
                        <div>
                            <h4>{t("daThanhToan")}</h4>
                            <div>{countNoti?.daThanhToan}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("chuaThanhToan")}</h4>
                            <div>{countNoti?.chuaThanhToan}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("totalSpending")}</h4>
                            <div>{countNoti?.tongChiDuToan}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("spent")}</h4>
                            <div>{countNoti?.daChi}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("conLai")}</h4>
                            <div>{countNoti?.conLai}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextField
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                        fullWidth
                        size="small"
                        placeholder={t("booking.soPhieu")}
                        value={textSearch}
                        onChange={(e) => setTextSearch(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <Button variant="contained" color="success" onClick={handleSearch}>
                        {t("search")}
                    </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <Button color="info" onClick={handleCreatePhieuChi}>
                        {t("add")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col span={24}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                backgroundColor: "#e2e2e2",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontWeight: "bold",
                            },
                        }}
                        columns={columns}
                        rows={phieuChiResponses}
                        autoHeight
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col xs={15} sm={15} md={15} lg={15} xl={15}></Col>
                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                    <Row style={{ marginTop: "20px" }}>
                        {t("tongChiDuToan")} : {formatter.format(thongKe.tongChiDuToan)}
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        {t("tongChiTT")} : {formatter.format(thongKe.tongChiThucTe)}
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        {t("spent")} : {formatter.format(thongKe.daChi)}
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        {t("conLai")} : {formatter.format(thongKe.conLai)}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={2} xl={9}></Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={7}>
                    <div
                        style={{
                            textAlign: "center",
                            color: "#fff",
                            height: 60,
                            paddingInline: 50,
                            lineHeight: "60px",
                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                            borderRadius: "4px",
                        }}
                    >
                        <Space>
                            <Button
                                variant="contained"
                                type="submit"
                                startIcon={<Save />}
                                // onClick={(e) => handleSave(e, "luu")}
                            >
                                {t("action.end")}
                            </Button>
                            <Button
                                variant="outlined"
                                // startIcon={<DeleteIcon />}
                                // onClick={(e) => handleSave(e, "tiep_tuc")}
                                key="continue"
                                // disabled={errorSoNgay}
                            >
                                {t("action.continue")}
                            </Button>
                        </Space>
                    </div>
                </Col>
            </Row>
            <ModalThucChi
                isOpen={isOpenTC}
                action={action}
                onHidden={handleHidden}
                opThongTinChungId={3}
                opPhieuChiId={opPhieuChiId}
                tranxOpRateId={tranxOpRateId}
            />
        </>
    );
};
export default ThucChi;
