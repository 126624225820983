import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {Col, Row} from "antd";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {useTranslation} from "react-i18next";


const FormProcess = ({text, step}) => {
    const {t} = useTranslation();
    // const location = useLocation();
    // const [isDis, setIsDis] = useState(location.pathname.includes("new") ? true : false);
    const steps = [
        t("hotel.generalInfo"),
        t("chietTinhTour"),
        t("moBanTour"),
    ];
    return(
        <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{marginLeft: "10px"}}>
                {/*{isDis ? <h4>{t("add") + " " + t(text)}</h4> :*/}
                {/*    <h3>{t("edit") + " " + t(text)}</h3>}*/}
                <h3>{t(text)}</h3>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Col>
        </Row>
    )
}

export default FormProcess