import Delete from "@mui/icons-material/Delete";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Save from "@mui/icons-material/Save";
import { Box, Button, TextField } from "@mui/material";
import { Affix, Col, Divider, Form, Input, Modal, Row, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const ModalLP = ({ isOpen, handleCancel, handleFinish }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const [id, setId] = useState("");
    const [nccThongTinChungId, setNccThongTinChungId] = useState("");
    const [loaiCabin, setLoaiCabin] = useState("");
    const [soLuong, setSoLuong] = useState("");
    const [soKhach, setSoKhach] = useState("");
    const [kichThuoc, setKichThuoc] = useState("");
    const [foc, setFoc] = useState("");
    const [poc, setPoc] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [hieuLuc, setHieuLuc] = useState("");
    const [loaiCabinError, setLoaiCabinError] = useState(false);
    const [soLuongError, setSoLuongError] = useState(false);
    const [soKhachError, setSoKhachError] = useState(false);
    const [focError, setFocError] = useState(false);
    const { t } = useTranslation();
    const onFinish = (e) => {
        e.preventDefault();
        setLoaiCabinError(false);
        setSoLuongError(false);
        setSoKhachError(false);
        setSoKhachError(false);
        setFocError(false);
        var error = false;
        if (!loaiCabin) {
            setLoaiCabinError(true);
            error = true;
        }
        if (!soLuong) {
            setSoLuongError(true);
            error = true;
        }
        if (!soKhach) {
            setSoKhachError(true);
            error = true;
        }
        if (!foc) {
            setFocError(true);
            error = true;
        }
        if (error) return;
        var values = {
            id: id,
            nccThongTinChungId: nccThongTinChungId,
            loaiCabin: loaiCabin,
            soLuong: soLuong,
            soKhach: soKhach,
            kichThuoc: kichThuoc,
            foc: foc,
            poc: poc,
            ghiChu: ghiChu,
            hieuLuc: hieuLuc,
        };
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        // if (record !== null || record !== '') {
        //     form.setFieldsValue({});
        // }
        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginBottom: 1,
                                    }}
                                    label={t("roomType")}
                                    onChange={(e) => setLoaiCabin(e.target.value)}
                                    value={loaiCabin}
                                    error={loaiCabinError}
                                    helperText={loaiCabinError ? t("validate") + " " + t("roomType").toLowerCase() : ""}
                                    required
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginButton: 1,
                                    }}
                                    label={t("hotel.roomClass.numberOfRoom")}
                                    onChange={(e) => setSoLuong(e.target.value)}
                                    value={soLuong}
                                    error={soLuongError}
                                    helperText={
                                        soLuongError
                                            ? t("validate") + " " + t("hotel.roomClass.numberOfRoom").toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                <TextField
                                    sx={{
                                        marginButton: 1,
                                    }}
                                    label={t("hotel.roomClass.guestMax")}
                                    onChange={(e) => setSoKhach(e.target.value)}
                                    value={soKhach}
                                    error={soKhachError}
                                    helperText={
                                        soKhachError
                                            ? t("validate") + " " + t("hotel.roomClass.guestMax").toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                ></TextField>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField disabled value={"/" + t("room")}></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginButton: 1,
                                    }}
                                    label={t("hotel.roomClass.sizeRoomM2")}
                                    onChange={(e) => setKichThuoc(e.target.value)}
                                    value={kichThuoc}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextField fullWidth disabled value={t("hotel.roomClass.conditionFoc")}></TextField>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <TextField
                            sx={{
                                marginButton: 1,
                            }}
                            onChange={(e) => setFoc(e.target.value)}
                            value={foc}
                            error={focError}
                            helperText={
                                focError ? t("validate") + " " + t("hotel.roomClass.conditionFoc").toLowerCase() : ""
                            }
                            required
                            fullWidth
                        ></TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        <TextField fullWidth disabled value={t("room")}></TextField>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextField
                            sx={{
                                marginButton: 1,
                            }}
                            onChange={(e) => setPoc(e.target.value)}
                            value={poc}
                            fullWidth
                        ></TextField>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        <TextField disabled value={t("hotel.roomClass.foc")}></TextField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextField
                            sx={{
                                marginButton: 1,
                            }}
                            label={t("notes")}
                            onChange={(e) => setGhiChu(e.target.value)}
                            value={ghiChu}
                            fullWidth
                        ></TextField>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutMax = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 13,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") + " " + t("informationRoomType").toLowerCase()
                        : t("add") + " " + t("informationRoomType").toLowerCase()
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
                bodyStyle={{ height: 600 }}
            >
                <Divider />
                <Box component="form" noValidate>
                    {dataRender()}
                    <Affix offsetBottom={50}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                            <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        height: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button
                                            variant="contained"
                                            onClick={onFinish}
                                            type="submit"
                                            startIcon={<Save />}
                                        >
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAlt />}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Delete />}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Affix>
                </Box>
            </Modal>
        </div>
    );
};
export default ModalLP;
