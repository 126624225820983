import { Col, Collapse, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { MenuItem, TextField } from "@mui/material";
import DichVuTour from "./dichVuTour/DichVuTour";
import DichVuLe from "./dichVuLe/DichVuLe";
import DieuHanhService from "../../../../api/dieu_hanh/DieuHanhService";

const OpRate = ({ dataTQ }) => {
    const { t } = useTranslation();
    const [tabDichVu, setTabDichVu] = useState(0);
    const [isDisableSelectDvTour, setIsDisableSelectDvTour] = useState(false);

    const [dataDv, setDataDv] = useState({});
    const [opDichVuId, setOpDichVuId] = useState(0);
    const [loaiDichVuTour, setLoaiDichVuTour] = useState(1);
    useEffect(() => {
        layTTDSDichVu({
            opThongTinChungId: 3,
        });
    }, []);
    const handleChangTabDichVu = (dichVu) => {
        if (dichVu === "dichVuTour") {
            setTabDichVu(0);
            setIsDisableSelectDvTour(false);
        } else {
            setTabDichVu(1);
            setIsDisableSelectDvTour(true);
        }
    };

    const layTTDSDichVu = async (data) => {
        let response = await DieuHanhService.layTTDSDichVu(data);
        if (response.status === 200 && response.data.code === "00") {
            setDataDv(response.data.data);
        }
    };
    const handleChangTour = (e) => {
        console.log(e.target.value);
        setOpDichVuId(e.target.value);
        setLoaiDichVuTour(dataDv.listDichVuTour.find((dt) => e.target.value === dt.opDichVuId).loaiDichVuTour);
    };
    return (
        <>
            <Row>
                <Col
                    span={14}
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "10px",
                        justifyContent: "space-around",
                        display: "flex",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        textAlign: "center",
                    }}
                >
                    <div style={{ fontWeight: "bold" }}>
                        <div style={{ fontSize: "20px" }}>{t("totalService")}</div>
                        <div style={{ fontSize: "50px", alignSelf: "flex-end" }}>{dataTQ?.totalDv}</div>
                    </div>
                    <div
                        style={{
                            justifyContent: "space-around",
                            alignItems: "center",
                            display: "flex",
                            width: "70%",
                        }}
                    >
                        <div>
                            <h4>{t("tourService")}</h4>
                            <div>{dataTQ?.dvTour}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("dichVuLe")}</h4>
                            <div>{dataTQ?.dvLe}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("checked")}</h4>
                            <div>{dataTQ?.checked}</div>
                        </div>
                        <span
                            style={{
                                borderLeft: "1px solid black",
                                height: "50%",
                                alignSelf: "flex-end",
                                marginBottom: "1%",
                            }}
                        />
                        <div>
                            <h4>{t("unchecked")}</h4>
                            <div>{dataTQ?.unchecked}</div>
                        </div>
                    </div>
                </Col>
                <Col span={10}>
                    <div style={{ marginLeft: "30px", height: "100%" }}>
                        <div
                            style={{
                                border: "1px solid black",
                                width: "50%",
                                height: "30%",
                                display: "flex",
                            }}
                        >
                            <button
                                style={{
                                    width: "50%",
                                    backgroundColor: `${!tabDichVu ? "#e6e6e6" : "white"}`,
                                    border: "none",
                                    height: "100%",
                                }}
                                onClick={() => handleChangTabDichVu("dichVuTour")}
                            >
                                Dịch vụ Tour ({dataDv.totalDichVuTour})
                            </button>
                            <span
                                style={{
                                    borderLeft: "1px solid black",
                                    height: "100%",
                                }}
                            />
                            <button
                                style={{
                                    width: "50%",
                                    backgroundColor: `${tabDichVu === 1 ? "#e6e6e6" : "white"}`,
                                    border: "none",
                                    height: "100%",
                                }}
                                onClick={() => handleChangTabDichVu("dichVuLe")}
                            >
                                Dịch vụ lẻ ({dataDv.totalDichVuLe})
                            </button>
                        </div>
                        <div style={{ width: "100%", marginTop: "20px" }}>
                            {!isDisableSelectDvTour ? (
                                <TextField select label="Dịch vụ Tour" fullWidth onChange={handleChangTour}>
                                    {dataDv?.listDichVuTour?.map((dt, key) => {
                                        return (
                                            <MenuItem key={key} value={dt.opDichVuId}>
                                                {dt.tenDichVu}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: "2%" }}>
                {tabDichVu === 0 ? (
                    <Col span={24}>
                        <DichVuTour opDichVuId={opDichVuId}/>
                    </Col>
                ) : (
                    <Col span={24}>
                        <DichVuLe />
                    </Col>
                )}
            </Row>
        </>
    );
};
export default OpRate;
