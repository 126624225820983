import React, { useEffect, useState } from "react";
import {
    Col,
    Input,
    Row,
    Typography,
    message,
    Upload,
    Modal,
    Radio,
    Space,
    Form,
    Button,
    Cascader,
    notification,
    Affix,
} from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "../../../../../assets/css/supplier/holtel/GeInfo.css";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { API_BASE_URL, maxFile, Ncc_ID } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import { useLocation, useNavigate } from "react-router-dom";

import Buttons from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { MenuItem } from "@mui/material";
import "../../../../../assets/css/supplier/GeInfo.css";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
const { Text } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const GeneralInfo = ({ dvName, dvId, addId, isView }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [dataDichVu, setDataDichVu] = useState([]);
    const [dataVat, setDataVat] = useState([]);
    const [vatId, setVatId] = useState(0);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [form] = Form.useForm();
    const [fileListPdf, setFileListPdf] = useState([]);
    const [id, setId] = useState(0);
    const [dmDichVuId, setDmDichVuId] = useState(0);
    const [dataWK, setdataWK] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [typeBt1, setTypeBt1] = useState("default");
    const [typeBt2, setTypeBt2] = useState("default");
    const [typeBt3, setTypeBt3] = useState("default");
    const [typeBt4, setTypeBt4] = useState("default");
    const [typeBt5, setTypeBt5] = useState("default");
    const [typeBt6, setTypeBt6] = useState("default");
    const [typeBt7, setTypeBt7] = useState("default");
    const [tinhId, setTinhId] = useState();
    const [huyenId, setHuyenId] = useState();
    const [xaId, setXaId] = useState();

    const [maNcc, setMaNcc] = useState("");
    const [maNccErrorText, setMaNccErrorText] = React.useState("");
    const [tenNcc, setTenNcc] = useState("");
    const [tenNccErrorText, setTenNccErrorText] = useState("");
    const [maSoThueErrorText, setMaSoThueErrorText] = useState("");
    const [quocGiaIdErrorText, setQuocGiaIdErrorText] = useState("");
    const [diaChiErrorText, setDiaChiErrorText] = useState("");
    const [boderColorCasca, setBoderColorCasca] = useState("");
    const [colorCasca, setColorCasca] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [sdtErrorText, setSdtErrorText] = useState("");
    const [trangThai, setTrangThai] = useState(false);
    const [maSoThue, setMaSoThue] = useState("");
    const [quyMoPhongId, setQuyMoPhongId] = useState(null);
    const [hangSaoId, setHangSaoId] = useState(null);
    const [loaiKhachSanId, setLoaiKhachSanId] = useState(null);
    const [quocGiaId, setQuocGiaId] = useState(0);
    const [diaChi, setDiaChi] = useState("");
    const [diaChiCuThe, setDiaChiCuThe] = useState("");
    const [email, setEmail] = useState("");
    const [sdt, setSdt] = useState("");
    const [website, setWebsite] = useState("");
    const [fax, setFax] = useState("");
    const [moTa, setMoTa] = useState("");
    const [isCod, setIsCod] = useState(0);
    const [cod, setCod] = useState("");
    const [isVat, setIsVat] = useState(0);
    const [ngayCuoiTuan, setNgayCuoiTuan] = useState({});
    const [pdfFile, setPdfFile] = useState([]);
    const [imgFile, setImgFile] = useState([]);
    const [hieuLuc, setHieuLuc] = useState(0);
    const [dmDichVus, setDmDichVus] = useState([]);
    const [dmHangDuThuyenId, setDmHangDuThuyenId] = useState([]);
    const [dmQuyMoNhId, setDmQuyMoNhId] = useState([]);

    const location = useLocation();
    const props = {
        name: "file",
        multiple: true,
        maxCount: maxFile,
        // disabled: fileListPdf.length >= 5? true: false,
        onRemove: (file) => {
            const index = fileListPdf.indexOf(file);
            const newFileList = fileListPdf.slice();
            newFileList.splice(index, 1);
            setFileListPdf(newFileList);
        },
        beforeUpload: (file) => {
            const isPNG = file.type === "application/pdf";
            if (!isPNG) {
                message.error(`${file.name}` + " " + t("errorUploadPDF"));
            }
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                message.error("Image must smaller than 3MB!");
            }
            return isPNG || Upload.LIST_IGNORE;
            // setFileListPdf([...fileListPdf, file]);
            // return false;
        },
        onChange: ({ fileListPdf: newFileList }) => {
            setFileListPdf(newFileList);
        },
        fileListPdf,
    };
    useEffect(() => {
        let apiData = {
            id: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                //convert string to array stringObject.replace('[','').replace(']','').split(",").map(String)
                if (response.status === 200 && response.data.code === "00") {
                    console.log("Response: ====" + response.data.data);
                    let data = response.data.data;
                    setDmDichVuId(data.dmDichVuId);
                    setMaNcc(data.maNcc);
                    setTrangThai(data.trangThai);
                    setTenNcc(data.tenNcc);
                    setMaSoThue(data.maSoThue);
                    setQuyMoPhongId(data.quyMoPhongId);
                    setHangSaoId(data.hangSaoId);
                    setLoaiKhachSanId(data.loaiKhachSanId);
                    setQuocGiaId(data.quocGiaId);
                    if (data.quocGiaId) handleChangeQG(data.quocGiaId);
                    setTinhId(data.tinhId);
                    setHuyenId(data.huyenId);
                    setXaId(data.xaId);
                    setDiaChi(data.diaChi);
                    setDiaChiCuThe(data.diaChiCuThe);
                    setEmail(data.email);
                    setSdt(data.sdt);
                    setWebsite(data.website);
                    setFax(data.fax);
                    setMoTa(data.moTa);
                    setIsCod(data.isCod);
                    setCod(data.cod);
                    setVatId(data.vatId);
                    setIsVat(data.isVat);
                    setHieuLuc(data.hieuLuc);
                    var dmDichVusId = [];
                    if (data.dmDichVus)
                        data.dmDichVus.forEach((dt) => {
                            dmDichVusId.push(dt.id);
                        });
                    setDmDichVus(dmDichVusId);
                    setNgayCuoiTuan(data.ngayCuoiTuan);
                    setPdfFile(data.pdfFile);
                    setImgFile(data.imgFile);
                    setDmQuyMoNhId(data.dmQuyMoNhId);
                    setId(data.id);
                    setDmDichVuId(data.dmDichVuId);
                    setValueRadio(data.isCod ? 1 : 2);
                    setValueRadioPay(data.isVat ? 1 : 2);
                    // setdataWK(data.ngayCuoiTuan)
                    setVatId(data.vatId);
                    if (data.isCod) {
                        setCodTrue(data.cod);
                    } else {
                        setCodFalse(data.cod);
                    }
                    if (data.ngayCuoiTuan) {
                        if (data.ngayCuoiTuan.monday) {
                            setTypeBt1("primary");
                        }
                        if (data.ngayCuoiTuan.tuesday) {
                            setTypeBt2("primary");
                        }
                        if (data.ngayCuoiTuan.wednesday) {
                            setTypeBt3("primary");
                        }
                        if (data.ngayCuoiTuan.thursday) {
                            setTypeBt4("primary");
                        }
                        if (data.ngayCuoiTuan.friday) {
                            setTypeBt5("primary");
                        }
                        if (data.ngayCuoiTuan.saturday) {
                            setTypeBt6("primary");
                        }
                        if (data.ngayCuoiTuan.sunday) {
                            setTypeBt7("primary");
                        }
                        setdataWK((prevState) => ({
                            ...prevState,
                            id: data.ngayCuoiTuan.id,
                            ncc_thong_tin_chung_id: data.ngayCuoiTuan.ncc_thong_tin_chung_id,
                            monday: data.ngayCuoiTuan.monday,
                            tuesday: data.ngayCuoiTuan.tuesday,
                            wednesday: data.ngayCuoiTuan.wednesday,
                            thursday: data.ngayCuoiTuan.thursday,
                            friday: data.ngayCuoiTuan.friday,
                            saturday: data.ngayCuoiTuan.saturday,
                            sunday: data.ngayCuoiTuan.sunday,
                        }));
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, [dvId]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmVat",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataVat = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataVat.push({
                                    value: data[i].id,
                                    label: data[i].vat,
                                });
                            }
                        }
                        setDataVat(dataVat);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const dataTrangThai = [
        {
            label: t("active"),
            value: false,
        },
        {
            label: t("deactivate"),
            value: true,
        },
    ];
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    id: data[i].id,
                                    value: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const [valueRadio, setValueRadio] = useState();
    const [valueRadioPay, setValueRadioPay] = useState();
    const onChangeRadio = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadio(e.target.value);
    };
    const onChangeRadioPay = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadioPay(e.target.value);
    };
    const [codTrue, setCodTrue] = useState(0);
    const [codFalse, setCodFalse] = useState(0);
    const handleInputChange = (e) => {
        if (e.target.name === "codTrue") {
            setCodTrue(e.target.value);
        } else {
            setCodFalse(e.target.value);
        }
    };
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleChangeQG = (value) => {
        setQuocGiaId(value);
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = { value: o.quan_huyen, label: o.quan_huyen, id: o.dm_huyen_id, children: [] };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleExit = () => {
        navigate("/ncc/" + dvName);
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const onChangeCascader = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        if (selectedOptions.length > 0) {
            setDiaChi(value.join(","));
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    setTinhId(selectedOptions[i].id);
                }
                if (i === 1) {
                    setHuyenId(selectedOptions[i].id);
                }
                if (i === 2) {
                    setXaId(selectedOptions[i].id);
                }
            }
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    // const getDvId=()=>{
    //     let Id = 0;
    //     if (location.search.length > 0) {
    //         let id = location.search.split('=');
    //         Id = id[1]
    //     }
    //     return Id
    // }
    // const getDv=()=>{
    //     let name = ''
    //     if (location.pathname.length > 0) {
    //         let id = location.pathname.split('/');
    //         name = id[id.length -2]
    //     }
    //     return name
    // }
    const onFinish = (e) => {
        e.preventDefault();
        var error = false;
        if (!maNcc) {
            setMaNccErrorText(t("validate") + " " + t("supply.code").toLowerCase() + "!");
            error = true;
        } else {
            setMaNccErrorText("");
        }
        if (!tenNcc) {
            setTenNccErrorText(t("validate") + " " + t("supply.name").toLowerCase() + "!");
            error = true;
        } else {
            setTenNccErrorText("");
        }
        if (!maSoThue) {
            setMaSoThueErrorText(t("validate") + " " + t("taxCode").toLowerCase() + "!");
            error = true;
        } else {
            setMaSoThueErrorText("");
        }
        if (!sdt) {
            setSdtErrorText(t("validate") + " " + t("phone").toLowerCase() + "!");
            error = true;
        } else {
            setSdtErrorText("");
        }
        if (!email) {
            setEmailErrorText(t("validate") + " " + t("email").toLowerCase() + "!");
            error = true;
        } else {
            setEmailErrorText("");
        }
        if (!quocGiaId) {
            setQuocGiaIdErrorText(t("validate") + " " + t("location.country").toLowerCase() + "!");
            error = true;
        } else {
            setQuocGiaIdErrorText("");
        }
        if (!diaChi) {
            setDiaChiErrorText(t("validate") + " " + t("address").toLowerCase() + "!");
            error = true;
        } else {
            setDiaChiErrorText("");
        }
        if (error) return;
        let dataImg = [];
        if (fileList.length > 0) {
            for (let j in fileList) {
                dataImg.push({
                    ten_file: fileList[j].name,
                    base64Value: fileList[j].thumbUrl.split("base64,")[1],
                });
            }
        }
        let dataPdf = [];
        if (fileListPdf.length > 0) {
            for (let k in fileListPdf) {
                dataPdf.push({
                    ten_file: fileListPdf[k].name,
                    base64Value: fileList[k].thumbUrl.split("base64,")[1],
                });
            }
        }
        let cod = null;
        let isVat = null;
        let isCod = null;
        if (valueRadioPay !== undefined) {
            cod = valueRadioPay === 1 ? codTrue : codFalse;
            isCod = valueRadioPay === 1 ? true : false;
        }
        if (valueRadio !== undefined) {
            isVat = valueRadio === 1 ? true : false;
        }
        console.log("dvId: ", dvId);
        let data = {
            id: id,
            quocGiaId: quocGiaId,
            maNcc: maNcc,
            tenNcc: tenNcc,
            email: email,
            dmDichVuId: dmDichVuId !== 0 && dmDichVuId !== null ? dmDichVuId : Ncc_ID[dvName],
            diaChi: diaChi,
            tinhId: tinhId,
            huyenId: huyenId,
            maSoThue: maSoThue,
            sdt: sdt,
            xaId: xaId,
            pdfFile: dataPdf,
            imgFile: dataImg,
            trangThai: trangThai !== undefined ? trangThai : null,
            isVat: isVat,
            isCod: isCod,
            cod: cod,
            ngayCuoiTuan: dataWK,
            fax: fax !== undefined ? fax : null,
            diaChiCuThe: diaChiCuThe !== undefined ? diaChiCuThe : null,
            website: website !== undefined ? website : null,
            moTa: moTa !== undefined ? moTa : null,
            vatId: vatId !== undefined ? vatId : 0,
            dmQuyMoNhId: 0,
            dmPcAmThucId: 0,
            dmDichVus: [],
            dmHangDuThuyenId: 0,
            dmDongDuThuyenId: 0,
            quyMoPhongId: 0,
            hangSaoId: 0,
            loaiKhachSanId: 0,
        };
        console.log("Received data of form: ", JSON.stringify(data));

        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response ====: ", response);
                if (response.status === 200 && response.data.code === "00") {
                    dvId = response.data.data;
                    addId(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    addId(false);
                    api["error"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                addId(false);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase() + " " + response.message,
                    duration: 0,
                });
            });
    };
    const handleClear = () => {
        form.resetFields();
    };
    const handleCancels = () => {
        form.resetFields();
        navigate("/ncc/" + dvName);
    };
    const handleWk1 = () => {
        setTypeBt1(typeBt1 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            monday: dataWK["monday"] ? false : true,
        }));
    };
    const handleWk2 = () => {
        setTypeBt2(typeBt2 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            tuesday: dataWK["tuesday"] ? false : true,
        }));
    };
    const handleWk3 = () => {
        setTypeBt3(typeBt3 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            wednesday: dataWK["wednesday"] ? false : true,
        }));
    };
    const handleWk4 = () => {
        setTypeBt4(typeBt4 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            thursday: dataWK["thursday"] ? false : true,
        }));
    };
    const handleWk5 = () => {
        setTypeBt5(typeBt5 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            friday: dataWK["friday"] ? false : true,
        }));
    };
    const handleWk6 = () => {
        setTypeBt6(typeBt6 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            saturday: dataWK["saturday"] ? false : true,
        }));
    };
    const handleWk7 = () => {
        setTypeBt7(typeBt7 === "default" ? "primary" : "default");
        setdataWK((prevState) => ({
            ...prevState,
            sunday: dataWK["sunday"] ? false : true,
        }));
    };

    const dataRender = () => {
        let html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="maNcc"
                                    id="maNcc"
                                    fullWidth
                                    required
                                    label={t("supply.code")}
                                    value={maNcc}
                                    onChange={(e) => setMaNcc(e.target.value)}
                                    error={!!maNccErrorText}
                                    helperText={maNccErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    name="trangThai"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    label={t("status")}
                                    select
                                    fullWidth
                                    value={trangThai}
                                    onChange={(e) => setTrangThai(e.target.value)}
                                >
                                    {dataTrangThai.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <TextField
                                    name="tenNcc"
                                    id="tenNcc"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    required
                                    fullWidth
                                    label={t("supply.name")}
                                    value={tenNcc}
                                    onChange={(e) => setTenNcc(e.target.value)}
                                    error={!!tenNccErrorText}
                                    helperText={tenNccErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("phone")}
                                    required
                                    maxLength={11}
                                    minLength={10}
                                    value={sdt}
                                    onChange={(e) => setSdt(e.target.value)}
                                    inputProps={{ inputMode: "numeric", pattern: "[0-11]*" }}
                                    error={!!sdtErrorText}
                                    helperText={sdtErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("email")}
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!!emailErrorText}
                                    helperText={emailErrorText}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    name="maSoThue"
                                    id="maSoThue"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    required
                                    fullWidth
                                    label={t("taxCode")}
                                    value={maSoThue}
                                    onChange={(e) => setMaSoThue(e.target.value)}
                                    error={!!maSoThueErrorText}
                                    helperText={maSoThueErrorText}
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("website")}
                                    name="website"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Space.Compact
                                    size="large"
                                    style={{
                                        width: "100%",
                                        marginBottom: 15,
                                        height: "56px",
                                    }}
                                    className=""
                                >
                                    <Input
                                        style={{
                                            width: "15%",
                                            borderRadius: "4px",
                                            color: { colorCasca },
                                            borderColor: { boderColorCasca },
                                        }}
                                        readOnly
                                        defaultValue={t("address") + " *"}
                                    />
                                    <TextField
                                        xs={{
                                            width: "85%",
                                            borderRadius: "4px",
                                        }}
                                        required
                                        label={t("location.country")}
                                        select
                                        fullWidth
                                        name="quocGiaId"
                                        onChange={(e) => handleChangeQG(e.target.value)}
                                        value={quocGiaId}
                                        error={!!quocGiaIdErrorText}
                                        helperText={quocGiaIdErrorText}
                                    >
                                        {dataQuocGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Space.Compact>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                    }}
                                    size="large"
                                    options={dataTinhTP}
                                    onChange={onChangeCascader}
                                    showSearch={{
                                        filterCascader,
                                    }}
                                    status={diaChiErrorText ? "error" : ""}
                                    placeholder={
                                        t("location.city") +
                                        " - " +
                                        t("location.district") +
                                        " - " +
                                        t("location.wards")
                                    }
                                    value={diaChi ? diaChi.split(",") : []}
                                    onSearch={(value) => console.log(value)}
                                />
                                {diaChiErrorText ? <Text type="danger">{diaChiErrorText}</Text> : <></>}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("addressDetail")}
                                    value={diaChiCuThe}
                                    onChange={(e) => setDiaChiCuThe(e.target.value)}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("fax")}
                                    value={fax}
                                    onChange={(e) => setFax(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("description")}
                                    multiline
                                    rows={5}
                                    value={moTa}
                                    onChange={(e) => setMoTa(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">{t("ClickorDag")}</p>
                                    <p className="ant-upload-hint">{t("validateUploadPdf")}</p>
                                </Dragger>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>

                        <Row>
                            <Space direction="vertical">
                                <h2>{t("paymentTime")}</h2>
                                <Row>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={valueRadioPay}
                                        onChange={onChangeRadioPay}
                                    >
                                        <Space direction="horizontal">
                                            <FormControlLabel value={1} control={<Radio />} label={t("payBeforeUse")} />
                                            <TextField
                                                label={t("day")}
                                                value={codTrue}
                                                size="small"
                                                onChange={(e) => setCodTrue(e.target.value)}
                                                style={{
                                                    width: 70,
                                                    marginLeft: 10,
                                                }}
                                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                            />
                                        </Space>
                                        <Space direction="horizontal">
                                            <FormControlLabel value={2} control={<Radio />} label={t("payAfterUse")} />
                                            <TextField
                                                label={t("day")}
                                                value={codFalse}
                                                size="small"
                                                onChange={(e) => setCodFalse(e.target.value)}
                                                style={{
                                                    width: 70,
                                                    marginLeft: 25,
                                                }}
                                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                            />
                                        </Space>
                                    </RadioGroup>
                                </Row>
                            </Space>
                        </Row>

                        <Row style={{ marginTop: "5%" }}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    select
                                    label={t("tax") + " " + t("vat")}
                                    fullWidth
                                    value={isVat}
                                    onChange={(e) => setIsVat(e.target.value)}
                                >
                                    {dataVat.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Radio.Group onChange={onChangeRadio} value={valueRadio}>*/}
                            {/*    <Space direction="vertical">*/}
                            {/*        <Radio value={1}>{t("priceIncludeTax")} </Radio>*/}
                            {/*        <Radio value={2}>{t("priceNotIncludeTax")} </Radio>*/}
                            {/*    </Space>*/}
                            {/*</Radio.Group>*/}
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={valueRadio}
                                onChange={onChangeRadio}
                            >
                                <FormControlLabel value={1} control={<Radio />} label={t("priceIncludeTax")} />
                                <FormControlLabel value={2} control={<Radio />} label={t("priceNotIncludeTax")} />
                            </RadioGroup>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const [bottom, setBottom] = React.useState(50);
    return (
        <Box component="form" noValidate>
            {contextHolder}
            {dataRender()}
            <Affix offsetBottom={bottom}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={2} xl={17}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={7}>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                height: 60,
                                paddingInline: 50,
                                lineHeight: "60px",
                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                borderRadius: "4px",
                            }}
                        >
                            {isView ? (
                                <Buttons key="back" onClick={handleExit}>
                                    {t("exit")}
                                </Buttons>
                            ) : (
                                <Space>
                                    <Buttons
                                        variant="contained"
                                        onClick={onFinish}
                                        type="submit"
                                        endIcon={<SaveIcon />}
                                    >
                                        {t("action.ok")}
                                    </Buttons>
                                    <Buttons
                                        style={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAltIcon />}
                                        onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Buttons>
                                    <Buttons
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={handleCancels}
                                        key="clear"
                                    >
                                        {t("action.cancel")}
                                    </Buttons>
                                </Space>
                            )}
                        </div>
                    </Col>
                </Row>
            </Affix>
        </Box>
    );
};
export default GeneralInfo;
