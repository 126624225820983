import Search from "@mui/icons-material/Search";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { Col, notification, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ghi_chu_service from "../../../api/dieu_hanh/ghi_chu/ghi_chu_service";
import Delete from "@mui/icons-material/Delete";
import { EditFilled } from "@ant-design/icons";
import { DataGrid } from "@mui/x-data-grid";
import ModalGhiChu from "./ModalGhiChu";
const GhiChu = (params) => {
    const [api, contextHolder] = notification.useNotification();
    const [textSearch, setTextSearch] = useState();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [ghiChuId, setGhiChuId] = useState(0);
    const [isReset, setIsReset] = useState(false);
    useEffect(() => {
        getData({
            opThongTinChungId: 3,
        });
    }, []);
    useEffect(() => {
        if (isReset)
            getData({
                opThongTinChungId: 3,
            });
    }, [isReset]);
    const handleSearch = () => {
        // setIsOpen(true);
    };
    const getData = async (data) => {
        let response = await ghi_chu_service.getOpGhiChuByOpTTC(data);
        if (response.status === 200 && response.data.code === "00") {
            setData(
                response.data.data.map((data, key) => {
                    return { ...data, stt: key + 1 };
                })
            );
        }
    };
    const columns = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            align: "center",
            width: 50,
            type: "number",
            headerAlign: "center",
            editable: false,
        },
        {
            headerName: t("name") + " " + t("ghiChu"),
            field: "ghiChu",
            key: "ghiChu",
            flex: 1,
            editable: false,
        },
        {
            headerName: t("noiDung"),
            field: "noiDung",
            key: "noiDung",
            editable: false,
            flex: 1,
        },
        {
            headerName: t("action"),
            headerAlign: "center",
            editable: false,
            renderCell: ({ value, row }) => {
                return (
                    <div>
                        <IconButton onClick={() => handleEdit(row.id)} variant="contained" color="primary">
                            <EditFilled />
                        </IconButton>
                        <Popconfirm
                            title={t("deleteTheTask")}
                            description={t("areYouConfirm")}
                            okText={t("delete")}
                            cancelText={t("huy")}
                            onConfirm={() => handleDelete(row)}
                        >
                            <IconButton variant="contained" color="secondary">
                                <Delete />
                            </IconButton>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    const handleEdit = (id) => {
        setGhiChuId(id);
        setIsOpen(true);
    };
    const handleDelete = (row) => {
        ghi_chu_service
            .deleteOpGhiChu({ id: row.id })
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        key: "success",
                        message: t("success"),
                        description: t("message.deleteSuccess"),
                    });
                    getData({
                        opThongTinChungId: 3,
                    });
                } else {
                    api["error"]({
                        key: "error",
                        message: t("error"),
                        description: response.data.message,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    key: "error",
                    message: t("error"),
                    description: error.toString(),
                });
            });
    };
    const handleAdd = () => {
        setIsOpen(true);
        setGhiChuId(0);
    };
    const onHidden = () => {
        setIsOpen(false);
    };

    return (
        <>
            {contextHolder}
            <ModalGhiChu isOpen={isOpen} onHidden={onHidden} ghiChuId={ghiChuId} setIsReset={setIsReset} />
            <Row style={{ marginTop: "20px" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextField
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                        fullWidth
                        size="small"
                        placeholder={t("booking.soPhieu")}
                        value={textSearch}
                        onChange={(e) => setTextSearch(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <Button variant="contained" color="success" onClick={handleSearch}>
                        {t("search")}
                    </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={14}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    <Button onClick={handleAdd}>{t("add")}</Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                backgroundColor: "#e2e2e2",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontWeight: "bold",
                            },
                        }}
                        columns={columns}
                        rows={data}
                        autoHeight
                    />
                </Col>
            </Row>
        </>
    );
};
export default GhiChu;
