import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Col, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag"
import Dm_ty_gia from "../../../../../api/category/dm_ty_gia";


const ModalChonLoaiTien = ({ isOpen, onSave, onCancel }) => {
    const { t } = useTranslation();
    const [loaiTien, setLoaiTien] = useState(null);
    const [dataLoaiTien, setDataLoaiTien] = useState([]);
    useEffect(() => {
        Dm_ty_gia.getDmTyGiaHeThong({
                hieu_luc: true,
            })
            .then(function (response) {
                if (response.data.code === "00") {
                    setDataLoaiTien(
                        response.data.data.map((dt) => {
                            return {
                                value: dt.maNgoaiTeId,
                                label: dt.maNgoaiTe,
                            };
                        })
                    );
                } else {
                    console.log("Error response: ====" + response);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    const onFinish = (e) => {
        e.preventDefault();
        onSave(loaiTien);
    };
    const handleCancels = (e) => {
        e.preventDefault();
        onCancel();
    };
    const onchangeLoaiTien=(e)=>{
        setLoaiTien(e.target.value)
    }
    return (
        <Modal title={t("tour.chonLoaiTien")} open={isOpen} onOk={onFinish} onCancel={handleCancels} footer={null}>
            <Box component="form" noValidate>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                        <TextField
                            label={t("tour.loaiTienBanDau")}
                            select
                            fullWidth
                            name="loaiTien"
                            onChange={(e) => onchangeLoaiTien(e)}
                            value={loaiTien}
                        >
                            {dataLoaiTien.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <ReactCountryFlag
                                        countryCode={option.label.substring(0, 2)}
                                        svg
                                        style={{
                                            width: '2em',
                                            height: '2em',
                                        }}
                                        title={option.label.substring(0, 2)}
                                    /> &nbsp;&nbsp;
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                </Row>
                <Row style={{ marginTop: 6 }}>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Button variant="outlined" onClick={handleCancels} key="clear">
                            {t("tour.back")}
                        </Button>
                        <Button disabled={loaiTien ? false : true} variant="contained" onClick={onFinish} type="submit">
                            {t("tour.chietTinh")}
                        </Button>
                    </Space>
                </Row>
            </Box>
        </Modal>
    );
};
export default ModalChonLoaiTien;
