import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDanhSachDoan = (data) => {
  return axios.post(
    API_BASE_URL + "booking/layTTDanhSachDoanByThongTinChungId",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveDanhSachDoan = (data) => {
  return axios.post(API_BASE_URL + "booking/saveBkDanhSachDoan", data, {
    headers: authHeader(),
  });
};

const deleteThanhVienDanhSachDoan = (data) => {
  return axios.post(
    API_BASE_URL + "booking/deleteThanhVienTrongDanhSachDoan",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateMemberInBkDanhSachDoan = (data) => {
  return axios.post(
    API_BASE_URL + "booking/updateMemberInBkDanhSachDoan",
    data,
    {
      headers: authHeader(),
    }
  );
};

const layTTThanhVienTrongDanhSachDoanById = (data) => {
  return axios.post(
    API_BASE_URL + "booking/layTTThanhVienTrongDanhSachDoanById",
    data,
    {
      headers: authHeader(),
    }
  );
};

export default {
  getDanhSachDoan,
  saveDanhSachDoan,
  deleteThanhVienDanhSachDoan,
  updateMemberInBkDanhSachDoan,
  layTTThanhVienTrongDanhSachDoanById,
};
