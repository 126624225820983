import Delete from "@mui/icons-material/Delete";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Save from "@mui/icons-material/Save";
import { Box, Button, TextField } from "@mui/material";
import { Affix, Col, Divider, Form, message, Modal, notification, Row, Space, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import ghi_chu_service from "../../../api/dieu_hanh/ghi_chu/ghi_chu_service";
const { Dragger } = Upload;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ModalGhiChu = ({ isOpen, onHidden, ghiChuId, setIsReset }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [tenGhiChu, setTenGhiChu] = useState("");
    const [noiDungInit, setNoiDungInit] = useState("");
    const [tenGhiChuInit, setTenGhiChuInit] = useState("");
    const [fileList, setFileList] = useState([]);
    const [fileListInit, setFileListInit] = useState([]);
    const [title, settitle] = useState();
    const [noiDung, setNoiDung] = useState("");
    const [action, setAction] = useState();
    useEffect(() => {
        if (isOpen) {
            if (ghiChuId) {
                getData();
                settitle(t("edit") + " " + t("ghiChu"));
                setAction("edit");
            } else {
                settitle(t("add") + " " + t("ghiChu"));
                setAction("add");
            }
        }
    }, [ghiChuId, isOpen]);
    const getData = async () => {
        const response = await ghi_chu_service.getOpGhiChuById({ id: ghiChuId });
        if (response.data.code === "00") {
            let data = response.data.data;
            setTenGhiChu(response.data.data.ghiChu);
            setNoiDung(response.data.data.noiDung);
            setTenGhiChuInit(response.data.data.ghiChu);
            setNoiDungInit(response.data.data.noiDung);
            setFileList([
                {
                    id: data.fileDinhKem ? data.fileDinhKem.id : 0,
                    uid: "-1",
                    name: data.fileDinhKem ? data.fileDinhKem.tenFile : "",
                    status: "done",
                    thumbUrl: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                    preview: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                },
            ]);
            setFileListInit([
                {
                    id: data.fileDinhKem ? data.fileDinhKem.id : 0,
                    uid: "-1",
                    name: data.fileDinhKem ? data.fileDinhKem.tenFile : "",
                    status: "done",
                    thumbUrl: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                    preview: data.base64Value ? "data:image/png;base64," + data.base64Value : "",
                },
            ]);
        }
    };

    const onFinish = async (e) => {
        e.preventDefault();
        let base64 = "";
        if (fileList[0] && fileList[0].originFileObj) base64 = await getBase64(fileList[0].originFileObj);
        let data = {
            id: ghiChuId, // update > 0
            opThongTinChungId: 3,
            ghiChu: tenGhiChu,
            noiDung: noiDung,
            fileRequest: {
                id: fileListInit[0] ? fileListInit[0].id : 0,
                tenFile: fileList[0].name,
                base64Value: base64
                    ? base64.substring(base64.indexOf("base64") + 7)
                    : fileList[0].preview?.substring(fileList[0].preview?.indexOf("base64") + 7),
            },
        };
        saveData(data);
    };

    const saveData = (data) => {
        ghi_chu_service
            .saveOpGhiChu(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        key: "success",
                        message: t("success"),
                        description: title,
                    });
                    setIsReset(true);
                    onHidden();
                    handleClearALL();
                } else {
                    api["error"]({
                        key: "error",
                        message: t("error"),
                        description: response.data.message,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    key: "error",
                    message: t("error"),
                    description: error.toString(),
                });
            });
    };
    const props = {
        name: "file",
        multiple: false,
        maxCount: 1,
        // disabled: fileList.length >= 5? true: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPdf = file.type === "application/pdf";
            if (!isPdf) {
                message.error(`${file.name}` + " " + t("errorUploadPDF"));
            }
            // const isLt3M = file.size / 1024 / 1024 < 3;
            // if (!isLt3M) {
            //     message.error("Image must smaller than 3MB!");
            // }
            return isPdf ? true : Upload.LIST_IGNORE;
        },
        onChange(info) {
            let newFileList = [...info.fileList];
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.status = "done";
                }
                return file;
            });
            debugger;
            setFileList(newFileList);
        },
        fileList,
    };
    const dataRender = () => {
        return (
            <>
                <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                        <TextField
                            label={t("name") + " " + t("ghiChu")}
                            value={tenGhiChu}
                            onChange={(e) => setTenGhiChu(e.target.value)}
                            required
                            fullWidth
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                        <TextField
                            label={t("noiDung") + " " + t("ghiChu")}
                            value={noiDung}
                            onChange={(e) => setNoiDung(e.target.value)}
                            required
                            fullWidth
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                        <div>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{t("ClickorDag")}</p>
                                <p className="ant-upload-hint">{t("restrictFile")}</p>
                            </Dragger>
                        </div>
                    </Col>
                </Row>
            </>
        );
    };
    const handleClear = () => {
        setFileList([...fileListInit]);
        setTenGhiChu(tenGhiChuInit);
        setNoiDung(noiDungInit);
    };

    const handleClearALL = () => {
        setFileListInit([]);
        setTenGhiChuInit();
        setNoiDungInit();
        setFileList([]);
        setTenGhiChu("");
        setNoiDung("");
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={<h2>{title}</h2>}
                open={isOpen}
                onOk={onFinish}
                onCancel={onHidden}
                footer={null}
                width={1300}
                // bodyStyle={{ height: 800 }}
            >
                <Divider style={{ margin: "0px 0px", backgroundColor: "black" }} />
                <Box component="form" noValidate>
                    {dataRender()}
                    <Affix offsetBottom={50}>
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <Row>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            color: "#fff",
                                            height: 60,
                                            paddingInline: 50,
                                            lineHeight: "60px",
                                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Space>
                                            <Button
                                                variant="contained"
                                                onClick={onFinish}
                                                type="submit"
                                                startIcon={<Save />}
                                            >
                                                {t("action.ok")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#898989",
                                                    color: "white",
                                                }}
                                                key="reset"
                                                startIcon={<RestartAlt />}
                                                onClick={(e) => handleClear(e)}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<Delete />}
                                                onClick={() => {
                                                    onHidden();
                                                    handleClearALL();
                                                }}
                                                key="clear"
                                            >
                                                {t("action.cancel")}
                                            </Button>
                                        </Space>
                                    </div>
                                </Row>
                            </Affix>
                        </Form.Item>
                    </Affix>
                </Box>
            </Modal>
        </>
    );
};
export default ModalGhiChu;
