import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getOpGhiChuByOpTTC = (data) => {
    return axios.post(API_BASE_URL + "op/getOpGhiChuByOpTTC", data, { headers: authHeader() });
};

const deleteOpGhiChu = (data) => {
    return axios.post(API_BASE_URL + "op/deleteOpGhiChu", data, { headers: authHeader() });
};
const saveOpGhiChu = (data) => {
    return axios.post(API_BASE_URL + "op/saveOpGhiChu", data, { headers: authHeader() });
};
const getOpGhiChuById = (data) => {
    return axios.post(API_BASE_URL + "op/getOpGhiChuById", data, { headers: authHeader() });
};
export default {
    getOpGhiChuByOpTTC,
    deleteOpGhiChu,
    saveOpGhiChu,
    getOpGhiChuById,
};
