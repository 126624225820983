import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from "@mui/material";
import { Col, Modal, Row, Space, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from "../../../api/user/UserService";
import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import authHeader from "../../../api/auth_header";
import { useTheme } from "@mui/material/styles";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";
import DieuHanhService from "../../../api/dieu_hanh/DieuHanhService";
const PhanCongDieuHanh = ({ isOpen, handleCancel, handleFinish, opThongTinChungId }) => {
    const [api, contextHolder] = notification.useNotification();
    const theme = useTheme();
    const { t } = useTranslation();
    const [dataDv, setDataDv] = useState([]);
    const [value, setValue] = useState(0);
    const [roles, setRoles] = useState([]);
    const [userDH, setUserDH] = useState([]);
    const [userRolesNH, setUserRolesNH] = useState([]);
    const [userRolesKS, setUserRolesKS] = useState([]);
    const [userRolesOTO, setUserRolesOTO] = useState([]);
    const [userRolesALL, setUserRolesALL] = useState([]);
    const [opId, setOpId] = useState(1);
    const onChangeRadio = (event) => {
        console.log(event.target.value);
        setValue(event.target.value);
    };
    useEffect(() => {
        getDataDSTTPhanCongDieuHanh();
    }, [opThongTinChungId]);
    const getDataDSTTPhanCongDieuHanh = async (params) => {
        let respone = await DieuHanhService.getDataDSTTPhanCongDieuHanh({
            opThongTinChungId: opId,
        });
        if (respone.data.code === "00") {
            let data = respone.data?.data || [];
            setValue(data.loaiPhuTrach);
            if (data.loaiPhuTrach === 0) {
                setUserRolesALL(data.phuTrachs[0].listUserId);
            } else {
                data.phuTrachs.map((pt) => {
                    if (pt.loaiDichVuId === 3) {
                        setUserRolesNH(pt.listUserId);
                    } else if (pt.loaiDichVuId === 1) {
                        setUserRolesKS(pt.listUserId);
                    } else if (pt.loaiDichVuId === 2) {
                        setUserRolesOTO(pt.listUserId);
                    }
                });
            }
        }
    };

    useEffect(() => {
        UserService.getRoles()
            .then((res) => {
                if (res.status === 200 && res.data.code === "00") {
                    let data = res.data?.data || [];
                    let dataPQ = [];
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        if (element.name === "OPERATOR") {
                            dataPQ.push({ id: element.id, name: element.name });
                        }
                    }
                    getUserByRole(dataPQ);
                    setRoles(data);
                }
            })
            .catch((err) => console.log("Get Roles Error ..." + err));
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====", response.data.data);
                    let dataDv = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDv.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                    hieu_luc: data[i].hieu_luc,
                                });
                            }
                        }
                        setDataDv(dataDv);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const getUserByRole = async (data) => {
        console.log("data: " + JSON.stringify(data));
        let response = await UserService.getUserByRole({
            role: data,
        });
        if (response.data.code === "00" && response.data.data) {
            console.log("resdata: " + JSON.stringify(response.data));
            setUserDH(response.data.data);
        }
    };
    const handleChange = (event, key) => {
        const {
            target: { value },
        } = event;
        if (key === 0)
            setUserRolesKS(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        else if (key === 1)
            setUserRolesOTO(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        else if (key === 2)
            setUserRolesNH(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        else
            setUserRolesALL(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.findIndex((n) => n === name.value) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const render = () => {
        return (
            <>
                {dataDv.map((data, key) => {
                    return (
                        <>
                            <Row key={key} style={{ marginTop: "20px" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label={t("typeofservice")}
                                        value={data.label}
                                        disabled
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-multiple-name-label">{t("personincharge")}</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            value={key === 0 ? userRolesKS : key === 1 ? userRolesOTO : userRolesNH}
                                            onChange={(e) => handleChange(e, key)}
                                            input={<OutlinedInput label="Name" />}
                                            MenuProps={MenuProps}
                                        >
                                            {userDH.map((data, key) => (
                                                <MenuItem
                                                    key={key}
                                                    style={getStyles(
                                                        data,
                                                        key === 0
                                                            ? userRolesKS
                                                            : key === 1
                                                            ? userRolesOTO
                                                            : userRolesNH,
                                                        theme
                                                    )}
                                                    value={data.id}
                                                >
                                                    {data.full_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </>
                    );
                })}
            </>
        );
    };
    const [error, setError] = useState(false);
    const handleSave = async (e) => {
        e.preventDefault();
        if (!value || value === "0" || value === 0) {
            if (userRolesALL.length === 0) {
                setError(true);
                return;
            }
        } else {
            if (userRolesKS.length === 0 && userRolesOTO.length === 0 && userRolesNH.length === 0) {
                setError(true);
                return;
            }
        }
        let data = {
            opThongTinChungId: opId,
            loaiPhuTrach: value, // 0: Tất cả, 1: dịch vụ
            phuTrachs:
                !value || value === "0" || value === 0
                    ? [
                          {
                              loaiDichVuId: 0,
                              listUserId: userRolesALL,
                          },
                      ]
                    : [
                          {
                              loaiDichVuId: 1,
                              listUserId: userRolesKS,
                          },
                          {
                              loaiDichVuId: 2,
                              listUserId: userRolesOTO,
                          },
                          {
                              loaiDichVuId: 3,
                              listUserId: userRolesNH,
                          },
                      ],
        };
        let response = await DieuHanhService.savePhanCongDieuHanh(data);
        if (response.data.code === "00" && response.data.data) {
            api["success"]({
                message: t("stour"),
                description: t("save") + " " + t("noti.success").toLowerCase(),
                duration: 0,
            });
            handleCancel();
        }
    };

    return (
        <>
            <Modal title={t("ExecutiveAssignment")} width={700} open={isOpen} onCancel={handleCancel} footer={null}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={onChangeRadio}
                        >
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <FormControlLabel value={0} control={<Radio />} label={t("Inchargeofallservices")} />
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={4}></Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label={t("Responsibleforeachtypeofservice")}
                                />
                            </Col>
                        </RadioGroup>
                    </Col>
                </Row>
                {!value || value === "0" || value === 0 ? (
                    <FormControl fullWidth size="small" required>
                        <InputLabel fullWidth id="demo-multiple-name-label">
                            {t("personincharge")}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={userRolesALL}
                            onChange={(e) => handleChange(e)}
                            input={<OutlinedInput label={t("personincharge")} />}
                            MenuProps={MenuProps}
                        >
                            {userDH.map((data, key) => (
                                <MenuItem key={key} style={getStyles(data, userRolesALL, theme)} value={data.id}>
                                    {data.full_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    render()
                )}
                <Row>{error ? <Typography.Text type="danger">{t("datDichVu")}</Typography.Text> : null}</Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={2} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={17}>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                height: 60,
                                paddingInline: 50,
                                lineHeight: "60px",
                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                borderRadius: "4px",
                            }}
                        >
                            <Space>
                                <Button variant="contained" type="submit" startIcon={<Delete />} onClick={handleCancel}>
                                    {t("action.cancel")}
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<Save />}
                                    onClick={(e) => handleSave(e)}
                                    key="continue"
                                >
                                    {t("hoanThanh")}
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Modal>
            {contextHolder}
        </>
    );
};
export default PhanCongDieuHanh;
