import React, { useEffect, useState } from "react";
import { Space, Input, Modal, Divider, Form, Row, Col, Select, DatePicker, Card, Switch, Affix } from "antd";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import "../../../../../assets/css/supplier/oto/dvCc/modal.css";
import moment from "moment";
import { Button } from "@mui/material";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ModalPR = ({ isOpen, handleCancel, handleFinish }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const onFinish = (values) => {
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        // if (record !== null || record !== '') {
        //     form.setFieldsValue({});
        // }
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <div className="">
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="maDichVu"
                                    label={t("res.code")}
                                    {...formItemLayout}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("validate") + " " + t("res.code").toLowerCase(),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="tenDichVu"
                                        label={t("res.serviceProvided") + "/" + t("trip")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("res.serviceProvided").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="dmTyGiaId"
                                        label={t("exchangeRate.currency")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: false,
                                                message: t("validate") + " " + t("exchangeRate.currency").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                                            }
                                            defaultValue={1}
                                            options={tiGia}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="dmHinhThucGiaId"
                                        label={t("formOfPricing")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("formOfPricing").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                                            }
                                            options={tiGia}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="kmUocTinh"
                                        label={t("estimatedKm")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: false,
                                                message: t("validate") + " " + t("estimatedKm").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="dmLoaiXeId"
                                        label={t("vehicleType")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("vehicleType").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                                            }
                                            options={tiGia}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="dmSoChoId"
                                        label={t("number")}
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("number").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                                            }
                                            options={tiGia}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="info-price">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map((field) => (
                                            <Card
                                                size="large"
                                                key={field.key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            name="rangeTime"
                                                            label={t("hotel.priceRoom.applicationTime")}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        <Form.Item
                                                            name="trangThaiBooking"
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <Switch defaultChecked={true} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    name="giaNet"
                                                                    label={t("hotel.priceClass.giaNet")}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    name="giaBan"
                                                                    label={t("hotel.priceClass.giaBan")}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                ) : null}
                                            </Card>
                                        ))}

                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="ghiChu"
                                label={t("notes")}
                                {...formItemLayoutNotes}
                                rules={[
                                    {
                                        required: false,
                                        message: t("validate") + " " + t("notes").toLowerCase(),
                                    },
                                ]}
                            >
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutPoc = {
        labelCol: {
            xs: {
                span: 19,
            },
            sm: {
                span: 16,
            },
        },
        wrapperCol: {
            xs: {
                span: 5,
            },
            sm: {
                span: 5,
            },
        },
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") + " " + t("service").toLowerCase()
                        : t("add") + " " + t("service").toLowerCase()
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                bodyStyle={{ height: 700, background: "#F5F5F5" }}
            >
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    {...formItemLayout}
                    initialValues={{
                        items: [{}],
                    }}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 12,
                        }}
                    >
                        <Affix offsetBottom={50}>
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    height: 60,
                                    paddingInline: 50,
                                    lineHeight: "60px",
                                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                                    borderRadius: "4px",
                                }}
                            >
                                <Space>
                                    <Button variant="contained" onClick={onFinish} type="submit" startIcon={<Save />}>
                                        {t("action.ok")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAlt />}
                                        onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Delete />}
                                        onClick={handleCancels}
                                        key="clear"
                                    >
                                        {t("action.cancel")}
                                    </Button>
                                </Space>
                            </div>
                        </Affix>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPR;
