import React, { useEffect, useState } from "react";
import {
  Space,
  Input,
  Modal,
  Divider,
  Form,
  Row,
  Col,
  Select,
  Cascader,
  Typography,
  Affix,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";

const { TextArea } = Input;
const { Text } = Typography;

const ModalContact = ({ isOpen, handleCancel, handleFinish, record }) => {
  const [open, setOpen] = useState(isOpen);
  const [dataQuocGia, setDataQuocGia] = useState([]);
  const [dataTinhTP, setDataTinhTP] = useState([]);
  const [quocGiaId, setQuocGiaId] = useState(0);
  const [tinhId, setTinhId] = useState();
  const [huyenId, setHuyenId] = useState();
  const [xaId, setXaId] = useState();

  const [id, setid] = useState("");
  const [nccThongTinChungId, setNccThongTinChungId] = useState("");
  const [hoVaTen, setHoVaTen] = useState("");
  const [ngaySinh, setngaySinh] = useState("");
  const [dataGioiTinh, setDataGioiTinh] = useState("");
  const [cccd, setCccd] = useState("");
  const [noiCap, setNoiCap] = useState("");
  const [chucVu, setChucVu] = useState("");
  const [phongBan, setPhongBan] = useState("");
  const [email, setEmail] = useState("");
  const [sdt, setSdt] = useState("");
  const [quocGia, setQuocGia] = useState("");
  const [diaChi, setDiaChi] = useState("");
  const [diaChiCuThe, setDiaChiCuThe] = useState("");
  const [website, setWebsite] = useState("");
  const [ghiChu, setGhiChu] = useState("");
  const [hieuLuc, setHieuLuc] = useState("");

  const [hoVaTenError, setHoVaTenError] = useState(false);
  const [ngaySinhError, setngaySinhError] = useState(false);
  const [chucVuError, setChucVuError] = useState(false);
  const [phongBanError, setPhongBanError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [sdtError, setSdtError] = useState(false);
  const [quocGiaError, setQuocGiaError] = useState(false);
  const [diaChiError, setDiaChiError] = useState(false);
  const [boderColorCasca, setBoderColorCasca] = useState("");
  const [colorCasca, setColorCasca] = useState("");
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const onFinish = (e) => {
    e.preventDefault();
    setHoVaTenError(false);
    setPhongBanError(false);
    setChucVuError(false);
    setSdtError(false);
    setEmailError(false);
    setQuocGiaError(false);
    setDiaChiError(false);
    var error = false;
    if (!hoVaTen) {
      setHoVaTenError(true);
      error = true;
    }
    if (!phongBan) {
      setPhongBanError(true);
      error = true;
    }
    if (!chucVu) {
      setChucVuError(true);
      error = true;
    }
    if (!sdt) {
      setSdtError(true);
      error = true;
    }
    if (!email) {
      setEmailError(true);
      setHelperTextEmail(t("validate") + " " + t("email").toLowerCase());
      error = true;
    } else if (!validateEmail(email)) {
      error = true;
      setEmailError(true);
      setHelperTextEmail(t("invalEmail"));
    }
    if (!quocGiaId) {
      setQuocGiaError(true);
      error = true;
    }
    if (!diaChi) {
      setDiaChiError(true);
      error = true;
    }
    if (error) return;
    let data = {
      id: id,
      nccThongTinChungId: nccThongTinChungId,
      hoVaTen: hoVaTen,
      ngaySinh: ngaySinh,
      gioiTinh: dataGioiTinh,
      cccd: cccd,
      noiCap: noiCap,
      chucVu: chucVu,
      phongBan: phongBan,
      email: email,
      sdt: sdt,
      quocGiaId: quocGiaId,
      quocGia: quocGia,
      tinhId: tinhId,
      huyenId: huyenId,
      xaId: xaId,
      diaChi: diaChi,
      diaChiCuThe: diaChiCuThe,
      website: website,
      ghiChu: ghiChu,
      hieuLuc: hieuLuc,
    };
    form.resetFields();
    handleClear();
    // setOpen(false);
    handleFinish(!isOpen, data);
  };
  useEffect(() => {
    axios({
      method: "post",
      url: API_BASE_URL + "category/danhSachQuocGia",
      headers: authHeader(),
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response: ====" + response.data.data);
          let dataQG = [];
          let data = response.data.data;
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].hieu_luc === true) {
                dataQG.push({
                  value: data[i].id,
                  label: data[i].quoc_gia,
                });
              }
            }
            setDataQuocGia(dataQG);
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  }, []);
  const handleCancels = () => {
    form.resetFields();
    setHoVaTenError(false);
    setPhongBanError(false);
    setChucVuError(false);
    setSdtError(false);
    setEmailError(false);
    setQuocGiaError(false);
    setDiaChiError(false);
    handleClear();
    handleCancel();
  };
  const handleChangeQG = (value) => {
    setQuocGiaId(value);
    setDiaChi("");
    console.log(`selected ${value}`);
    let data = {
      dm_quoc_gia_id: value,
    };
    axios({
      method: "post",
      url: API_BASE_URL + "category/danhSachDiaDiem",
      data: data,
      headers: authHeader(),
    })
      .then(function (response) {
        console.log("Response: ====" + response.status);
        if (response.status === 200) {
          console.log(response.data.data);
          let data = response.data.data;

          if (data.length > 0) {
            let result = data.reduce((acc, o) => {
              let tinhId = o.dm_tinh_id;
              let cur = acc[tinhId];
              if (!cur) {
                cur = {
                  value: o.tinh_thanhpho,
                  label: o.tinh_thanhpho,
                  id: o.dm_tinh_id,
                  children: [],
                };
                acc[tinhId] = cur;
              }
              cur.children.push(o);
              return acc;
            }, {});
            result = Object.values(result).map((v) => {
              let dic = v.children.reduce((acc, o) => {
                let huyenId = o.dm_huyen_id;
                let cur = acc[huyenId];
                if (!cur) {
                  cur = {
                    value: o.quan_huyen,
                    label: o.quan_huyen,
                    id: o.dm_huyen_id,
                    children: [],
                  };
                  acc[huyenId] = cur;
                }
                cur.children.push({
                  value: o.phuong_xa,
                  label: o.phuong_xa,
                  id: o.dm_xa_id,
                });
                return acc;
              }, {});
              v.children = Object.values(dic);
              return v;
            });
            setDataTinhTP(result);
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
        if (response.status === 401) {
        }
      });
  };
  const onChangeCascader = (value, selectedOptions) => {
    setDiaChi(value ? value.join(",") : "");
    console.log(value, selectedOptions);
    if (selectedOptions.length > 0) {
      for (let i = 0; i < selectedOptions.length; i++) {
        if (i === 0) {
          setTinhId(selectedOptions[i].id);
        }
        if (i === 1) {
          setHuyenId(selectedOptions[i].id);
        }
        if (i === 2) {
          setXaId(selectedOptions[i].id);
        }
      }
    }
  };
  const filterCascader = (inputValue, path) => {
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };
  const handleClear = () => {
    form.resetFields();
    setid("");
    setNccThongTinChungId("");
    setHoVaTen("");
    setngaySinh("");
    setDataGioiTinh("");
    setCccd("");
    setNoiCap("");
    setChucVu("");
    setPhongBan("");
    setEmail("");
    setSdt("");
    setQuocGiaId(0);
    setQuocGia("");
    setDiaChi("");
    setDiaChiCuThe("");
    setWebsite("");
    setGhiChu("");
    setHieuLuc("");
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };
  const gioiTinh = [
    {
      value: "Khác",
      label: "Khác",
    },
    {
      value: "Nam",
      label: "Nam",
    },
    {
      value: "Nữ",
      label: "Nữ",
    },
  ];
  const dataRender = () => {
    let html;
    if (record !== null || record !== "") {
      form.setFieldsValue({});
    }
    html = (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  onChange={(e) => setHoVaTen(e.target.value)}
                  value={hoVaTen}
                  error={hoVaTenError}
                  helperText={
                    hoVaTenError
                      ? t("validate") + " " + t("fullName").toLowerCase()
                      : ""
                  }
                  required
                  fullWidth
                  label={t("fullName")}
                ></TextField>
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Row>
              <Col xs={16} sm={16} md={16} lg={16} xl={15}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: "outlined",
                          error: ngaySinhError,
                          helperText: ngaySinhError
                            ? t("validate") + " " + t("dob").toLowerCase()
                            : "",
                        },
                      }}
                      label={t("dob")}
                      value={
                        ngaySinh ? dayjs(formatStringDateView(ngaySinh)) : null
                      }
                      format="DD/MM/YYYY"
                      onChange={(newValue) =>
                        setngaySinh(moment(newValue.$d).format("DD/MM/YYYY"))
                      }
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  onChange={(e) => setDataGioiTinh(e.target.value)}
                  value={dataGioiTinh}
                  label={t("sex")}
                  fullWidth
                  select
                >
                  {gioiTinh.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  onChange={(e) => setCccd(e.target.value.replace(/\D/g, ""))}
                  value={cccd}
                  fullWidth
                  label={t("cccd")}
                ></TextField>
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  label={t("noiCap")}
                  onChange={(e) => setNoiCap(e.target.value)}
                  value={noiCap}
                  fullWidth
                ></TextField>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  label={t("position")}
                  onChange={(e) => setChucVu(e.target.value)}
                  value={chucVu}
                  error={chucVuError}
                  helperText={
                    chucVuError
                      ? t("validate") + " " + t("position").toLowerCase()
                      : ""
                  }
                  required
                  fullWidth
                ></TextField>
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  label={t("departments")}
                  onChange={(e) => setPhongBan(e.target.value)}
                  value={phongBan}
                  error={phongBanError}
                  helperText={
                    phongBanError
                      ? t("validate") + " " + t("departments").toLowerCase()
                      : ""
                  }
                  required
                  fullWidth
                ></TextField>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <h3>{t("contactInfo")}</h3>
        </Row>
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  label={t("email")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  error={emailError}
                  helperText={helperTextEmail}
                  required
                  type="email"
                  fullWidth
                ></TextField>
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  label={t("phone")}
                  sx={{
                    marginTop: 1,
                  }}
                  type="number"
                  onChange={(e) => setSdt(e.target.value.replace(/\D/g, ""))}
                  value={sdt}
                  error={sdtError}
                  helperText={
                    sdtError
                      ? t("validate") + " " + t("phone").toLowerCase()
                      : ""
                  }
                  required
                  fullWidth
                ></TextField>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Space.Compact
              size="large"
              style={{
                width: "100%",
                marginBottom: 15,
                height: "56px",
              }}
              className=""
            >
              <Input
                style={{
                  width: "50%",
                  borderRadius: "4px",
                  color: { colorCasca },
                  borderColor: { boderColorCasca },
                }}
                readOnly
                defaultValue={t("address") + " *"}
              />
              <TextField
                xs={{
                  width: "85%",
                  borderRadius: "4px",
                }}
                required
                label={t("location.country")}
                select
                fullWidth
                name="quocGiaId"
                onChange={(e) => handleChangeQG(e.target.value)}
                value={quocGiaId}
                error={quocGiaError}
                helperText={
                  quocGiaError
                    ? t("validate") + " " + t("location.country").toLowerCase()
                    : ""
                }
              >
                {dataQuocGia.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Space.Compact>
          </Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={11}>
            <Form.Item
              validateStatus={diaChiError ? "error" : ""}
              help={
                diaChiError
                  ? t("validate") + " " + t("address").toLowerCase()
                  : ""
              }
            >
              <Cascader
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  height: "55px",
                }}
                size="large"
                options={dataTinhTP}
                onChange={onChangeCascader}
                showSearch={{
                  filterCascader,
                }}
                placeholder={
                  t("location.city") +
                  " - " +
                  t("location.district") +
                  " - " +
                  t("location.wards")
                }
                value={diaChi ? diaChi.split(",") : []}
                onSearch={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  label={t("addressDetail")}
                  onChange={(e) => setDiaChiCuThe(e.target.value)}
                  value={diaChiCuThe}
                  fullWidth
                ></TextField>
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TextField
                  sx={{
                    marginTop: 1,
                  }}
                  label={t("website")}
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                  fullWidth
                ></TextField>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <TextField
              sx={{
                marginTop: 1,
              }}
              label={t("notes")}
              onChange={(e) => setGhiChu(e.target.value)}
              value={ghiChu}
              multiline
              rows={5}
              fullWidth
            ></TextField>
          </Col>
        </Row>
      </Space>
    );
    return html;
  };
  const isEmpty = (record) => {
    for (const prop in record) {
      if (Object.hasOwn(record, prop)) {
        return false;
      }
    }

    return true;
  };
  const formItemLayoutCcd = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayout1 = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };
  const formItemLayoutDob = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };
  const formItemLayoutNote = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 0,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
    },
  };
  const [bottom, setBottom] = React.useState(50);
  return (
    <div>
      <Modal
        title={
          !isEmpty
            ? t("edit") + " " + t("informationContact").toLowerCase()
            : t("add") + " " + t("informationContact").toLowerCase()
        }
        open={isOpen}
        onOk={onFinish}
        onCancel={handleCancels}
        footer={null}
        width={1000}
        // bodyStyle={{ height: 800 }}
      >
        <Divider />
        <Box component="form" noValidate>
          {dataRender()}

          <Affix offsetBottom={bottom}>
            <Form.Item
              wrapperCol={{
                span: 12,
                offset: 12,
              }}
            >
              <Affix offsetBottom={50}>
                <div
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    height: 60,
                    paddingInline: 50,
                    lineHeight: "60px",
                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                    borderRadius: "4px",
                  }}
                >
                  <Space>
                    <Button
                      variant="contained"
                      onClick={onFinish}
                      type="submit"
                      startIcon={<Save />}
                    >
                      {t("action.ok")}
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#898989",
                        color: "white",
                      }}
                      key="reset"
                      startIcon={<RestartAlt />}
                      onClick={(e) => handleClear(e)}
                    >
                      {t("action.reset")}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<Delete />}
                      onClick={handleCancels}
                      key="clear"
                    >
                      {t("action.cancel")}
                    </Button>
                  </Space>
                </div>
              </Affix>
            </Form.Item>
          </Affix>
        </Box>
      </Modal>
    </div>
  );
};
export default ModalContact;
