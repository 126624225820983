import { Card, Row, Tabs, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SaleRate from "./saleRate/SaleRate";
import OpRate from "./opRate/OpRate";
import DatDichVu from "./datDichVu/DatDichVu";
import DieuHanhService from "../../../api/dieu_hanh/DieuHanhService";
import ChiPhiThucTe from "./chiPhiThucTe/ChiPhiThucTe";
import ThucChi from "./ThucChi/ThucChi";
import BaoCao from "./baoCao/BaoCao";
import DanhSachBooking from "./danhSachBooking/DanhSachBooking";
import DanhSachDoan from "../DanhSachDoan/DanhSachDoan";
import GhiChu from "../GhiChu/GhiChu";

const DieuHanhDichVu = () => {
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState("1");
    const [data, setData] = useState({});
    const [dataTQ, setDataTQ] = useState();
    const items = [
        {
            label: "Sale Rate",
            children: <SaleRate dataTQ={dataTQ} />,
            key: "1",
        },
        {
            label: "OP Rate",
            children: <OpRate dataTQ={dataTQ} />,
            key: "2",
        },
        {
            label: t("booking"),
            children: <DatDichVu />,
            key: "3",
        },
        {
            label: t("chiPhiThucTe"),
            children: <ChiPhiThucTe />,
            key: "4",
        },
        {
            label: t("thucchi"),
            children: <ThucChi />,
            key: "5",
        },

        {
            label: t("report"),
            children: <BaoCao />,
            key: "6",
        },
        {
            label: t("bookingList"),
            children: <DanhSachBooking />,
            key: "7",
        },
        {
            label: t("danhSachDoan"),
            children: <DanhSachDoan />,
            key: "8",
        },
        {
            label: t("ghiChu"),
            children: <GhiChu />,
            key: "9",
        },
    ];
    useEffect(() => {
        getData();
        getDataTongQuanDichVu();
    }, [activeKey]);
    const getData = async () => {
        let response = await DieuHanhService.getDataTongQuanOPThongTinChung(3);
        if (response.status === 200 && response.data.code === "00") {
            console.log("response.data.data: ", response.data.data);
            setData(response.data.data);
        }
    };

    const getDataTongQuanDichVu = async () => {
        let response = await DieuHanhService.getDataTongQuanDichVu(3);
        if (response.status === 200 && response.data.code === "00") {
            console.log("response.data.data: ", response.data.data);
            setDataTQ(response.data.data);
        }
    };
    const onChangeTab = (newActiveKey) => {
        setActiveKey(newActiveKey);
    };

    return (
        <>
            <Card title={<h3>{t("serviceManagement")}</h3>}>
                <Row
                    style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "10px",
                        justifyContent: "space-around",
                        paddingBottom: "20px",
                    }}
                >
                    <div>
                        <h4>{"ID " + t("booking").toLowerCase()}</h4>
                        <div>{data.idBooking}</div>
                    </div>
                    <div>
                        <h4>{t("name") + " " + t("booking").toLowerCase()}</h4>
                        <div>{data.tenBooking}</div>
                    </div>
                    <div>
                        <h4>{t("soNgay")}</h4>
                        <div>{data.soNgay}</div>
                    </div>
                    <div>
                        <h4>{t("timeTour")}</h4>
                        <div>{data.thoiGianTour}</div>
                    </div>
                    <div>
                        <h4>{t("quantity")}</h4>
                        <div>{data.soLuong}</div>
                    </div>
                    <div>
                        <h4>{t("sell")}</h4>
                        <div>{data.saleName}</div>
                    </div>
                    <div>
                        <h4>{t("Executive")}</h4>
                        <div>{data.dieuHanh}</div>
                    </div>
                    <div>
                        <h4>{t("progress")}</h4>
                        <div>
                            <Progress
                                type="circle"
                                percent={data.tienDo}
                                strokeColor={{
                                    "0%": "#87d068",
                                    "50%": "#ffe58f",
                                    "100%": "#ffccc7",
                                }}
                                size={50}
                                strokeWidth={12}
                            />
                        </div>
                    </div>
                </Row>
                <Row
                    style={{
                        marginTop: "2%",
                        width: "100%",
                    }}
                >
                    <Tabs
                        type="card"
                        onChange={onChangeTab}
                        activeKey={activeKey}
                        items={items}
                        style={{ width: "100%" }}
                    />
                </Row>
            </Card>
        </>
    );
};
export default DieuHanhDichVu;
