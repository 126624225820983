import React, { useState } from "react";
import { Button, Col, Row, Space, Input, AutoComplete } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ModalExRate from "./ModalExRate";
import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import authHeader from "../../../api/auth_header";

const FormEx = ({ dataAdd, deleteLst, handleSearch, dataSearch }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSelect, setIsSelect] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleFinish = (status, values) => {
        setIsModalOpen(status);
        setIsModalOpen(false);
        let data = {
            ma_ngoai_te: values.ma_ngoai_te,
            loai_tien: values.loai_tien,
            ty_gia: parseInt(values.ty_gia),
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/saveDmTyGia",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let data = response.data.data;
                let dataLst = [];
                let dataLstSearch = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        dataLst.push({
                            stt: i + 1,
                            id: data[i].id,
                            ma_loai_hinh: data[i].ma_loai_hinh,
                            loai_hinh_du_lich: data[i].loai_hinh_du_lich,
                            mo_ta: data[i].mo_ta,
                            hieu_luc: data[i].hieu_luc,
                        });
                        dataLstSearch.push({
                            value: data[i].id + " - " + data[i].ma_loai_hinh + " - " + data[i].loai_hinh_du_lich,
                        });
                    }
                    dataAdd(dataLst, dataLstSearch);
                }
            }
        });
    };
    const deleteClick = () => {
        deleteLst();
    };

    const onSearch = (value) => {
        handleSearch(value);
        setIsSelect(true);
    };
    return (
        <Row>
            <Col
                xs={{
                    span: 6,
                }}
                lg={{
                    span: 6,
                }}
            ></Col>
            <Col
                xs={{
                    span: 11,
                    offset: 1,
                }}
                lg={{
                    span: 6,
                    offset: 2,
                }}
            ></Col>
            <Col
                xs={{
                    span: 5,
                    offset: 1,
                }}
                lg={{
                    span: 6,
                    offset: 2,
                }}
            >
                <Space size="large">
                    <Button icon={<DeleteOutlined />} type="primary" danger onClick={deleteClick}>
                        {t("delete")}
                    </Button>
                    <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                        {t("add")}
                    </Button>
                    <ModalExRate isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleFinish} />
                </Space>
            </Col>
        </Row>
    );
};
export default FormEx;
