import { Box, Button, TextField } from "@mui/material";
import { Affix, Checkbox, Col, Divider, Form, Input, Modal, Row, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";

const { TextArea } = Input;
const ModalHpAction = ({ isOpen, handleCancel, handleFinish, record, action }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [plainOptions, setPlainOptions] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [id, setId] = useState("");
    const [nccThongTinChungId, setNccThongTinChungId] = useState("");
    const [hangPhong, setHangPhong] = useState("");
    const [soLuongPhong, setSoLuongPhong] = useState(0);
    const [soKhachToiDa, setSoKhachToiDa] = useState(0);
    const [kichThuocPhong, setKichThuocPhong] = useState("");
    const [dieuKienFoc, setDieuKienFoc] = useState("");
    const [foc, setFoc] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [hieuLuc, setHieuLuc] = useState("");

    const [hangPhongErr, setHangPhongErr] = useState(false);
    const [soLuongPhongErr, setSoLuongPhongErr] = useState(false);
    const [soKhachToiDaErr, setSoKhachToiDaErr] = useState(false);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    useEffect(() => {
        let lstTienIch = [];
        if (!isEmpty(record)) {
            let apiData = {
                id: record.id,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "ncc/hangphong/getDataById",
                data: apiData,
                headers: authHeader(),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        console.log("Response: data ====", data);
                        setId(data.id);
                        setNccThongTinChungId(data.nccThongTinChungId);
                        setHangPhong(data.hangPhong);
                        setSoLuongPhong(data.soLuongPhong);
                        setSoKhachToiDa(data.soKhachToiDa);
                        setKichThuocPhong(data.kichThuocPhong);
                        setDieuKienFoc(data.dieuKienFoc);
                        setFoc(data.foc);
                        setGhiChu(data.ghiChu);
                        setHieuLuc(data.hieuLuc);
                        if (data.dmTienIchList.length > 0) {
                            for (let i = 0; i < data.dmTienIchList; i++) {
                                lstTienIch.push(data.dmTienIchList[i].id);
                            }
                            setCheckedList(lstTienIch);
                        }
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [record]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTienIch",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].ten_tien_ich,
                                });
                            }
                        }
                        setPlainOptions(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const onFinish = (e) => {
        e.preventDefault();
        setHangPhongErr(false);
        setSoKhachToiDaErr(false);
        setSoLuongPhongErr(false);
        var err = false;
        if (!hangPhong) {
            setHangPhongErr(true);
            err = true;
        }
        if (!soKhachToiDa) {
            setSoKhachToiDaErr(true);
            err = true;
        }
        if (!soLuongPhong) {
            setSoLuongPhongErr(true);
            err = true;
        }
        if (err) return;
        var dmTienIchList = [];
        if (checkedList)
            for (let i = 0; i < checkedList.length; i++) {
                const element = checkedList[i];
                var check = plainOptions.find((it) => it.value === element);
                dmTienIchList.push({
                    id: check.value,
                    ten_tien_ich: check.label,
                });
            }
        var values = {
            id: id, // thêm mới để 0, update truyền id đúng vào
            nccThongTinChungId: nccThongTinChungId,
            hangPhong: hangPhong,
            soLuongPhong: soLuongPhong,
            soKhachToiDa: soKhachToiDa,
            kichThuocPhong: kichThuocPhong,
            dieuKienFoc: dieuKienFoc,
            foc: foc,
            ghiChu: ghiChu,
            dmTienIchList: dmTienIchList,
        };
        console.log(dmTienIchList);
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const onChangeCheckBox = (list) => {
        setCheckedList(list);
    };
    const checkBoxRender = () => {
        let html;
        let checkBox = [];
        for (let i = 0; i < plainOptions.length; i++) {
            checkBox.push(
                <Col span={6}>
                    <Checkbox key={i} value={plainOptions[i].value} label={plainOptions[i].label}>
                        {plainOptions[i].label}
                    </Checkbox>
                </Col>
            );
        }
        html = (
            <Checkbox.Group
                key={1}
                style={{
                    width: "100%",
                }}
                disabled={action ? false : true}
                onChange={onChangeCheckBox}
            >
                <Row>{checkBox}</Row>
            </Checkbox.Group>
        );
        return html;
    };

    const dataRender = () => {
        let html;

        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    value={hangPhong ? hangPhong : ""}
                                    fullWidth
                                    onChange={(e) => setHangPhong(e.target.value)}
                                    label={t("hotel.roomClass")}
                                    required
                                    error={hangPhongErr}
                                    helperText={
                                        hangPhongErr ? t("validate") + " " + t("hotel.roomClass").toLowerCase() : ""
                                    }
                                ></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    value={soLuongPhong ? soLuongPhong : ""}
                                    required
                                    fullWidth
                                    type="number"
                                    label={t("hotel.roomClass.numberOfRoom")}
                                    onChange={(e) => setSoLuongPhong(e.target.value)}
                                    error={soLuongPhongErr}
                                    helperText={
                                        soLuongPhongErr
                                            ? t("validate") + " " + t("hotel.roomClass.numberOfRoom").toLowerCase()
                                            : ""
                                    }
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                <TextField
                                    type="number"
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    label={t("hotel.roomClass.guestMax")}
                                    value={soKhachToiDa ? soKhachToiDa : ""}
                                    fullWidth
                                    required
                                    onChange={(e) => setSoKhachToiDa(e.target.value)}
                                    error={soKhachToiDaErr}
                                    helperText={
                                        soKhachToiDaErr
                                            ? t("validate") + " " + t("hotel.roomClass.guestMax").toLowerCase()
                                            : ""
                                    }
                                ></TextField>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    disabled
                                    value={"/" + t("room")}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    label={t("hotel.roomClass.sizeRoomM2")}
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    value={kichThuocPhong ? kichThuocPhong : ""}
                                    fullWidth
                                    onChange={(e) => setKichThuocPhong(e.target.value)}
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                <TextField
                                    label={t("hotel.roomClass.conditionFoc")}
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    type="number"
                                    value={dieuKienFoc ? dieuKienFoc : ""}
                                    fullWidth
                                    onChange={(e) => setDieuKienFoc(e.target.value)}
                                ></TextField>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    disabled
                                    value={t("room")}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    label={t("hotel.roomClass.foc")}
                                    sx={{
                                        marginTop: 1,
                                    }}
                                    type="number"
                                    value={foc ? foc : ""}
                                    fullWidth
                                    onChange={(e) => setFoc(e.target.value)}
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextField
                            sx={{
                                marginTop: 1,
                            }}
                            label={t("notes")}
                            value={ghiChu ? ghiChu : ""}
                            fullWidth
                            multiline
                            rows={5}
                            onChange={(e) => setGhiChu(e.target.value)}
                        ></TextField>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Space direction="horizontal" size="middle" style={{ display: "flex" }}>
                        <h3>{t("hotel.roomClass.extension")}</h3>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} />
                    </Space>
                </Row>
                <Row>{checkBoxRender()}</Row>
            </Space>
        );
        return html;
    };
    const onCheckAllChange = (e) => {
        let data = [];
        if (e.target.checked) {
            for (let i = 0; i < plainOptions.length; i++) {
                data.push(plainOptions[i].value);
            }
        }
        setCheckedList(data);
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutMax = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 13,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("informationRoomClass").toLowerCase() : t("informationRoomClass")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
                bodyStyle={{}}
            >
                <Divider />
                <Box component="form" noValidate>
                    {dataRender()}
                    <Divider />
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        height: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button
                                            variant="contained"
                                            onClick={onFinish}
                                            type="submit"
                                            startIcon={<Save />}
                                        >
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAlt />}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Delete />}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                    </Space>
                                </div>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>
        </div>
    );
};
export default ModalHpAction;
