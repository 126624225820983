import React, { useState } from "react";
import { Modal, Input, Button, Row, Col, Form, Space, Divider, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

const ModalExRateEdit = ({ isOpen, handleCancel, handleFinish, record }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const onFinish = (values) => {
        let data = {
            id: record.id,
            ty_gia: values.ty_gia,
        };
        form.resetFields();
        setIsModalOpen(false);
        handleFinish(isModalOpen, data);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        if (record !== null || record !== "") {
            form.setFieldsValue({
                ma_ngoai_te: record.ma_ngoai_te,
                loai_tien: record.loai_tien,
                ty_gia: record.ty_gia,
            });
        }
        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col span={18} push={6}>
                        <Form.Item
                            name="ma_ngoai_te"
                            rules={[
                                {
                                    required: true,
                                    message: t("validate") + " " + t("exchangeRate.currencyCode").toLowerCase(),
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6} pull={18}>
                        {t("exchangeRate.currencyCode")}
                    </Col>
                </Row>
                <Row>
                    <Col span={18} push={6}>
                        <Form.Item
                            name="loai_tien"
                            rules={[
                                {
                                    required: true,
                                    message: t("validate") + " " + t("exchangeRate.currency").toLowerCase(),
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6} pull={18}>
                        {t("exchangeRate.currency")}
                    </Col>
                </Row>
                <Row>
                    <Col span={18} push={6}>
                        <Form.Item
                            name="ty_gia"
                            rules={[
                                {
                                    required: true,
                                    message: t("validate") + " " + t("exchangeRate.rate").toLowerCase(),
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} pull={18}>
                        {t("exchangeRate.rate")}
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={t("edit") + " " + t("exchangeRate").toLowerCase()}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
            >
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                    }}
                >
                    {dataRender()}
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {t("action.ok")}
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#898989",
                                color: "white",
                            }}
                            key="clear"
                            onClick={(e) => handleClear(e)}
                        >
                            {t("action.reset")}
                        </Button>
                        <Button key="back" onClick={handleCancels}>
                            {t("action.cancel")}
                        </Button>
                    </Space>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalExRateEdit;
