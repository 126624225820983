import { Col, Row, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import BaoCaoService from "../../../../api/dieu_hanh/bao_cao/BaoCaoService";
import { useEffect, useState } from "react";

const BaoCao = () => {
  const { t } = useTranslation();
  const [thongTinChiPhiTour, setThongTinChiPhiTour] = useState([]);
  const [bangDuToanSale, setBangDuToanSale] = useState([]);
  const [bangThucTeDieuHanh, setBangThucTeDieuHanh] = useState([]);
  const columnChiPhiTour = [
    {
      title: t("thongTinChiPhiTour"),
      dataIndex: "key",
      key: "key",
      width: "45%",
      align: "center",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
      width: "30%",
      align: "center",
    },
    {
      title: t("ghiChu"),
      dataIndex: "ghiChu",
      key: "ghiChu",
      width: "25%",
      align: "center",
    },
  ];
  const columnBangDuToan = [
    {
      title: t("bangThucTinhCuaDieuHanh"),
      dataIndex: "key",
      key: "key",
      width: "45%",
      align: "center",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
      width: "30%",
      align: "center",
    },
    {
      title: t("ghiChu"),
      dataIndex: "ghiChu",
      key: "ghiChu",
      width: "25%",
      align: "center",
    },
  ];
  const columnBangThucTeDieuHanh = [
    {
      title: t("bangThucTinhCuaDieuHanh"),
      dataIndex: "key",
      key: "key",
      width: "45%",
      align: "center",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
      width: "30%",
      align: "center",
    },
    {
      title: t("ghiChu"),
      dataIndex: "ghiChu",
      key: "ghiChu",
      width: "25%",
      align: "center",
    },
  ];

  useEffect(() => {
    getTTBaoCao({ opThongTinChungId: 3 });
  }, []);

  const getTTBaoCao = (dataReq) => {
    BaoCaoService.layTTBaoCao(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const thongTinChiPhiTour = res.data.data.thongTinChiPhiTour;
          const duToan = res.data.data.bangDuToanSale;
          const thuTeDieuHanh = res.data.data.bangThucTeDieuHanh;
          const lstTTChiPhi = Object.entries(thongTinChiPhiTour).map(
            ([key, value]) => {
              if (key === "giaVonUocTinh")
                return { key: "Giá vốn ước tính", value };
              else if (key === "loiNhuan") return { key: "Lợi nhuận", value };
              else if (key === "tongTienTruocThue")
                return { key: "Tổng tiền trước thuế", value };
              else if (key === "vatBanRa") return { key: "VAT bán ra", value };
              else if (key === "tongTienTrietKhau")
                return { key: "Tổng tiền Chiết khấu", value };
              else if (key === "tongTien") return { key: "Tổng tiền", value };
              else if (key === "soLuong") return { key: "Số lượng", value };
              else return { key, value };
            }
          );
          const lstDuToan = Object.entries(duToan).map(([key, value]) => {
            if (key === "doanhThu") return { key: "Doanh thu", value };
            else if (key === "tongThuThucTe")
              return { key: "Tổng thu thực tế", value };
            else if (key === "tongTienVATMuaVao")
              return { key: "Tổng tiền VAT (Mua vào)", value };
            else if (key === "tongTienVATBanRa")
              return { key: "Tổng tiền VAT (Bán ra)", value };
            else if (key === "tongTienVATPhaiNop")
              return { key: "Tổng tiền VAT (Phải nộp)", value };
            else if (key === "laiGop") return { key: "Lãi gộp", value };
            else if (key === "tyLeLaiGop")
              return { key: "Tỷ lệ % lãi gộp", value };
            else return { key, value };
          });
          const lstThucTeDieuHanh = Object.entries(thuTeDieuHanh).map(
            ([key, value]) => {
              if (key === "tongChiThucTe")
                return { key: "Tổng chi thực tế", value };
              else if (key === "tongThuThucTe")
                return { key: "Tổng thu thực tế", value };
              else if (key === "tongTienVATMuaVao")
                return { key: "Tổng tiền VAT (Mua vào)", value };
              else if (key === "tongTienVATBanRa")
                return { key: "Tổng tiền VAT (Bán ra)", value };
              else if (key === "tongTienVATPhaiNop")
                return { key: "Tổng tiền VAT (Phải nộp)", value };
              else if (key === "laiGop") return { key: "Lãi gộp", value };
              else if (key === "tyLeLaiGop")
                return { key: "Tỷ lệ % lãi gộp", value };
              else return { key, value };
            }
          );
          setThongTinChiPhiTour(lstTTChiPhi);
          setBangDuToanSale(lstDuToan);
          setBangThucTeDieuHanh(lstThucTeDieuHanh);
        }
      })
      .catch((err) =>
        console.log("Lay thong tin bao cao dieu hanh error ..." + err)
      );
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Table
          dataSource={thongTinChiPhiTour}
          columns={columnChiPhiTour}
          bordered
          pagination={false}
          style={{
            width: "33%",
          }}
        />
        <Table
          dataSource={bangDuToanSale}
          columns={columnBangDuToan}
          bordered
          pagination={false}
          style={{
            width: "33%",
          }}
        />
        <Table
          dataSource={bangThucTeDieuHanh}
          columns={columnBangThucTeDieuHanh}
          bordered
          pagination={false}
          style={{
            width: "33%",
          }}
        />
      </div>
    </>
  );
};

export default BaoCao;
