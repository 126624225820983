import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTDichVuLeOtoById = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTDichVuLeOtoById", data, {
        headers: authHeader(),
    });
};
const saveDichVuLeOto = (data) => {
    return axios.post(API_BASE_URL + "booking/saveDichVuLeOto", data, {
        headers: authHeader(),
    });
};
const layTTDichVuLeNhaHangById = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTDichVuLeNhaHangById  ", data, {
        headers: authHeader(),
    });
};
const saveDichVuLeNhaHang = (data) => {
    return axios.post(API_BASE_URL + "booking/saveDichVuLeNhaHang", data, {
        headers: authHeader(),
    });
};
const layTTDichVuLeKhachSanById = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTDichVuLeKhachSanById", data, {
        headers: authHeader(),
    });
};
const saveDichVuLeKhacSan = (data) => {
    return axios.post(API_BASE_URL + "booking/saveDichVuLeKhachSan", data, {
        headers: authHeader(),
    });
};
const saveDichVuLeKhachSan = (data) => {
    return axios.post(API_BASE_URL + "booking/saveDichVuLeKhachSan", data, {
        headers: authHeader(),
    });
};
const layTTDichVuLeByBkThongTinChungId = (data) => {
    return axios.post(API_BASE_URL + "booking/layTTDichVuLeByBkThongTinChungId", data, {
        headers: authHeader(),
    });
};
const deleteDichVuLeById = (data) => {
    return axios.post(API_BASE_URL + "booking/deleteDichVuLeById", data, {
        headers: authHeader(),
    });
};
const updateDichVuLe = (data) => {
    return axios.post(API_BASE_URL + "booking/updateDichVuLe", data, {
        headers: authHeader(),
    });
};
export default {
    layTTDichVuLeOtoById,
    saveDichVuLeOto,
    layTTDichVuLeNhaHangById,
    saveDichVuLeNhaHang,
    layTTDichVuLeKhachSanById,
    saveDichVuLeKhacSan,
    saveDichVuLeKhachSan,
    layTTDichVuLeByBkThongTinChungId,
    deleteDichVuLeById,
    updateDichVuLe,
};
