import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTDSBooking = (data) => {
  return axios.post(API_BASE_URL + "op/layTTDSBookingById", data, {
    headers: authHeader(),
  });
};

export default {
  layTTDSBooking,
};
