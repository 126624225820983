import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Link, MenuItem, TextField, Button } from "@mui/material";
import { Col, Divider, Modal, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import DanhSachDoanService from "../../../../api/booking/DanhSachDoanService";
import dm_booking from "../../../../api/category/dm_booking";
const ModalCapNhatThanhVien = ({
  isOpen,
  onReset,
  handleCancel,
  bkDanhSachDoanId,
  bkThongTinChungId,
  hanhDong,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState({
    gioiTinhId: 0,
    hoVaTen: "",
    dmQuocTichId: 0,
    dmVaiTroId: 0,
    soDienThoai: "",
    email: "",
    soHoChieu: "",
    ghiChu: "",
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    gioiTinhId: 0,
    hoVaTen: "",
    dmQuocTichId: 0,
    dmVaiTroId: 0,
    soDienThoai: "",
    email: "",
    soHoChieu: "",
    ghiChu: "",
  });
  const [dataQuocTich, setDataQuocTich] = useState([]);
  const [dataVaiTro, setDataVaiTro] = useState([]);
  const [dataGioiTinh, setDataGioiTinh] = useState([]);

  useEffect(() => {
    getDmQuocTich();
    getDmGioiTinh();
    getDmVaiTro();
  }, []);

  useEffect(() => {
    if (hanhDong === "EDIT" && isOpen) {
      getDetailsThanhVien(bkDanhSachDoanId);
    } else {
      setDataSource({
        gioiTinhId: 0,
        hoVaTen: "",
        dmQuocTichId: 0,
        dmVaiTroId: 0,
        soDienThoai: "",
        email: "",
        soHoChieu: "",
        ghiChu: "",
      });
      setDataSourceInit({
        gioiTinhId: 0,
        hoVaTen: "",
        dmQuocTichId: 0,
        dmVaiTroId: 0,
        soDienThoai: "",
        email: "",
        soHoChieu: "",
        ghiChu: "",
      });
    }
  }, [hanhDong, bkDanhSachDoanId, isOpen]);

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const getDetailsThanhVien = (bkDanhSachDoanId) => {
    let dataReq = { bkDanhSachDoanId: bkDanhSachDoanId };
    DanhSachDoanService.layTTThanhVienTrongDanhSachDoanById(dataReq)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDataSource(data.danhSachDoanDetails[0]);
          setDataSourceInit({ ...data.danhSachDoanDetails[0] });
        }
      })
      .catch(function (response) {
        console.log(
          "Error response layTTThanhVienTrongDanhSachDoanById: ====" + response
        );
      });
  };

  const getDmQuocTich = () => {
    dm_booking
      .getDmQuocTich()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return {
                value: it.id,
                label: it.quoc_tich,
              };
            });
          setDataQuocTich(data);
        }
      })
      .catch((err) => console.log("getDmQuocTich error " + err));
  };

  const getDmGioiTinh = () => {
    dm_booking
      .getDmGioiTinh()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return {
                value: it.id,
                label: it.gioi_tinh,
              };
            });
          setDataGioiTinh(data);
        }
      })
      .catch((err) => console.log("getDmGioiTinh error " + err));
  };

  const getDmVaiTro = () => {
    dm_booking
      .getDmVaiTro()
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data
            ?.filter((it) => it.hieu_luc)
            .map((it) => {
              return {
                value: it.id,
                label: it.vai_tro,
              };
            });
          setDataVaiTro(data);
        }
      })
      .catch((err) => console.log("getDmVaiTro error " + err));
  };

  const handleChangeValueDataSoure = (e) => {
    let newDataSource = { ...dataSource };
    newDataSource[e.target.name] = e.target.value;
    setDataSource(newDataSource);
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = {
        bkThongTinChungId: bkThongTinChungId,
        DanhSachDoanDetails: [
          {
            bkDanhSachDoanId: bkDanhSachDoanId,
            gioiTinhId: dataSource.gioiTinhId,
            hoVaTen: dataSource.hoVaTen,
            soDienThoai: dataSource.soDienThoai,
            email: dataSource.email,
            ngaySinh: dataSource.ngaySinh,
            dmQuocTichId: dataSource.dmQuocTichId,
            soHoChieu: dataSource.soHoChieu,
            ngayHetHan: dataSource.ngayHetHan,
            dmVaiTroId: dataSource.dmVaiTroId,
            ghiChu: dataSource.ghiChu,
          },
        ],
      };
      console.log(dataReq);
      // console.log(JSON.stringify(dataReq));
      DanhSachDoanService.updateMemberInBkDanhSachDoan(dataReq)
        .then((response) => {
          let action = "add";
          if (bkDanhSachDoanId !== 0) action = "edit";
          if (response.status === 200 && response.data.code === "00") {
            showNoti(
              "success",
              t(action) + " " + t("noti.success").toLowerCase()
            );
            setDataSourceInit({ ...dataSource });
            handleCancel(false);
            onReset();
          } else {
            showNoti("error", response.data.message);
          }
        })
        .catch(() => {
          showNoti("error", t("system.error"));
        });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    // if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNgayApDungs");
    // }
    // else if (dataNhomNgLon.length > 0 && bgNhomNguoiLonId === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNhomNgLon");
    // }
    return result;
  };

  const formatStringDateView = (strDate) => {
    let date = strDate.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  };

  const handleClear = () => {
    setDataSource({ ...dataSourceInit });
  };

  const handleCloseModal = () => {
    handleCancel();
    handleClear();
  };

  const handleChangeNgaySinh = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    newDataSource.ngaySinh = day;
    setDataSource(newDataSource);
  };

  const handleChangeNgayHetHan = (value) => {
    let day = moment(value.$d).format("DD/MM/YYYY");
    const newDataSource = { ...dataSource };
    newDataSource.ngayHetHan = day;
    setDataSource(newDataSource);
  };

  return (
    <>
      <Modal
        width={1100}
        title={t("add") + " " + t("member").toLowerCase()}
        footer={null}
        open={isOpen}
        onCancel={() => handleCloseModal()}
      >
        {contextHolder}
        <Divider style={{ background: "#898989" }} />
        <Box component="form" noValidate>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("sex")}
                value={dataSource.gioiTinhId}
                select
                variant="outlined"
                name="gioiTinhId"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
                // SelectProps={{
                //   style: { fontSize: "13px" },
                //   MenuProps: {
                //     style: { fontSize: "13px" },
                //   },
                // }}
              >
                {dataGioiTinh.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("fullName")}
                value={dataSource.hoVaTen}
                variant="outlined"
                name="hoVaTen"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
                // inputProps={{ style: { fontSize: "13px" } }}
              ></TextField>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("phoneNumber")}
                value={dataSource.soDienThoai}
                variant="outlined"
                name="soDienThoai"
                size="small"
                type="number"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
              ></TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("email")}
                value={dataSource.email}
                variant="outlined"
                name="email"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
              ></TextField>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={11}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("dob")}
                  value={
                    dataSource.ngaySinh
                      ? dayjs(formatStringDateView(dataSource.ngaySinh))
                      : null
                  }
                  name="ngaySinh"
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                  onChange={(date) => handleChangeNgaySinh(date)}
                  // slotProps={{
                  //   textField: { variant: "standard" },
                  // }}
                  // className="custom-datepicker"
                />
              </LocalizationProvider>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("expirationDate")}
                  value={
                    dataSource.ngayHetHan
                      ? dayjs(formatStringDateView(dataSource.ngayHetHan))
                      : null
                  }
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                  onChange={(date) => handleChangeNgayHetHan(date)}
                  // slotProps={{
                  //   textField: {
                  //     variant: "standard",
                  //   },
                  // }}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("quocTich")}
                value={dataSource.dmQuocTichId}
                select
                variant="outlined"
                name="dmQuocTichId"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
              >
                {dataQuocTich.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("passport")}
                value={dataSource.soHoChieu}
                variant="outlined"
                name="soHoChieu"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
              ></TextField>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("role")}
                value={dataSource.dmVaiTroId}
                select
                variant="outlined"
                name="dmVaiTroId"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
              >
                {dataVaiTro.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                label={t("notes")}
                value={dataSource.ghiChu}
                variant="outlined"
                name="ghiChu"
                size="small"
                onChange={(e) => handleChangeValueDataSoure(e)}
                fullWidth
              ></TextField>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={11}>
              <TextField
                fullWidth
                label={t("typeTour.description")}
                value={dataSource.moTa}
                name="moTa"
                onChange={(e) => handleChangeValueDataSoure(e)}
                // multiline
                maxRows={3}
              ></TextField>
            </Col>
          </Row> */}
          <Divider />
          <Row style={{ marginTop: 6 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={10}>
              <Space>
                <Button
                  variant="contained"
                  key="save"
                  startIcon={<SaveIcon />}
                  onClick={(e) => handleSave(e)}
                >
                  {t("action.ok")}
                </Button>
                <Button
                  style={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleCloseModal()}
                  key="clear"
                >
                  {t("action.cancel")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Box>
      </Modal>
    </>
  );
};
export default ModalCapNhatThanhVien;
