import { CloseCircleFilled } from "@ant-design/icons";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import Buttons from "@mui/material/Button";
import {
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccOto from "./modalNcc/ModalThongTinNccOto";
import otoService from "../../../../api/bao_gia/sale_rate/oto/otoService";

const ModalCapNhatOto = ({
  isOpen,
  onReset,
  handleCancel,
  dataNhomNgLon,
  dataNhomTreEm,
  baoGiaHanhTrinhId,
  baoGiaTongQuanId,
  bgChietTinhSaleId,
  dmDichVuId,
  bgMapCTSaleHtId,
  soLuongTreEmBG,
  soLuongNgLonBG,
  hanhDong,
  soNgay,
  ngay,
  bgHanhTrinhs,
}) => {
  const [dmGia, setDmGia] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { t, i18n } = useTranslation();
  const [dmLoaiApDung, setDmLoaiApDung] = useState([]);
  const [isOpenTT, setIsOpenTT] = useState(false);
  const [dataSource, setDataSource] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
    ngayApDungs: [],
  });
  const [dataSourceInit, setDataSourceInit] = useState({
    tenNcc: "",
    dmGiaApDungId: 2,
    dmVatId: 1,
    moTa: "",
    ngayApDungs: [],
  });
  const [dichVus, setDichVus] = useState([{ id: "NULL" }]);
  const [dichVusInit, setDichVusInit] = useState([{ id: "NULL" }]);
  const [dichVuTreEms, setDichVuTreEms] = useState([]);
  const [dichVuTreEmsInit, setDichVuTreEmsInit] = useState([]);
  const [action, setAction] = useState("");
  const [idxEdit, setIdxEdit] = useState(0);
  const [dmVat, setDmVat] = useState([]);
  const [dmDoTuoi, setDmDoTuoi] = useState([]);
  const [countDichVu, setCountDichVu] = useState(0);
  const [reset, setReset] = useState(false);
  const [maNcc, setMaNcc] = useState("");
  const prevCountDichVu = useRef();
  // const soLuongTreEmBG = 5;
  // const soLuongNgLonBG = 8;
  // const dataNhomTreEm = [
  //   {
  //     id: 4,
  //     nhomNguoiLonId: 4,
  //     maNhom: "",
  //     tenNhom: "tre 1",
  //     details: [
  //       {
  //         soLuong: 1,
  //         dmDoTuoiId: 1,
  //       },
  //       {
  //         soLuong: 1,
  //         dmDoTuoiId: 2,
  //       },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     nhomNguoiLonId: 5,
  //     maNhom: "",
  //     tenNhom: "tre 2",
  //     details: [
  //       {
  //         soLuong: 1,
  //         dmDoTuoiId: 1,
  //       },
  //     ],
  //   },
  //   // {
  //   //   id: 6,
  //   //   nhomNguoiLonId: 5,
  //   //   maNhom: "",
  //   //   tenNhom: "tre 3",
  //   //   details: [
  //   //     {
  //   //       soLuong: 1,
  //   //       dmDoTuoiId: 1,
  //   //     },
  //   //     {
  //   //       soLuong: 1,
  //   //       dmDoTuoiId: 2,
  //   //     },
  //   //   ],
  //   // },
  // ];

  // const dataNhomNgLon = [
  //   {
  //     id: 4,
  //     maNhomTreEm: [],
  //     tenNhom: "ngl 1",
  //     foc: 3,
  //     soLuong: 3,
  //   },
  //   {
  //     id: 5,
  //     maNhomTreEm: [],
  //     tenNhom: "ngl 2",
  //     foc: 3,
  //     soLuong: 3,
  //   },
  // ];
  useEffect(() => {
    getDmGiaApDung();
    getDmLoaiApDung();
    getDmVat();
    getDmDoTuoi();
  }, []);

  useEffect(() => {
    prevCountDichVu.current = countDichVu;
  }, [countDichVu]);

  useEffect(() => {
    if (hanhDong === "EDIT" && isOpen) {
      getChietTinhSaleOto({ bgMapCTSaleHtId: bgMapCTSaleHtId });
    }
  }, [hanhDong, bgMapCTSaleHtId, isOpen]);

  useEffect(() => {
    if (hanhDong === "ADD") {
      setDichVus([{ id: "NULL" }]);
      setDichVusInit([{ id: "NULL" }]);
      setDataSource({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        ngayApDungs: [],
      });
      setDataSourceInit({
        tenNcc: "",
        dmGiaApDungId: 2,
        dmVatId: 1,
        moTa: "",
        ngayApDungs: [],
      });
      setDichVuTreEms([]);
      setDichVuTreEmsInit([]);
    }
  }, [isOpen]);

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const chiPhiTreEm = useMemo(() => {
    return dichVuTreEms.reduce((sum, item) => {
      if (item.id !== "addNew") sum += parseFloat(item.thanhTien);
      return sum;
    }, 0);
  }, [dichVuTreEms, dichVus]);

  const chiPhiNguoiLon = useMemo(() => {
    return dichVuTreEms.reduce((sum, item) => {
      if (item.id !== "addNew") sum += parseFloat(item.giaNet);
      return sum;
    }, 0);
  }, [dichVuTreEms, dichVus]);

  let tongChiPhiNguoiLon = useMemo(() => {
    return chiPhiNguoiLon * soLuongNgLonBG;
  }, [chiPhiNguoiLon]);

  const tongChiPhi = chiPhiTreEm + tongChiPhiNguoiLon;

  const compareNumber = (a, b) => {
    return a - b;
  };

  const getChietTinhSaleOto = (id) => {
    otoService
      .getChietTinhSaleOto(id)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          let ds = {
            nccThongTinChungId: data[0].nccThongTinChungId,
            tenNcc: data[0].tenNhaCungCap,
            ngayApDungs: data[0].ngayApDungs,
            dmGiaApDungId: data[0].dmGiaApDungId,
            ngayApDungs: data[0].ngayApDungs,
            dmLoaiApDung: data[0].dmLoaiApDungId,
            dmVatId: data[0].dmVatId, // đang null
            dmTyGiaId: data[0].dmTyGiaId, // đang null
            moTa: data[0].moTa,
          };

          let lstDichVu = data[0]?.dichVuNguoiLons.map((it, idx) => {
            it.id = idx + 1;
            it.tenDichVu = it.dichVu;
            it.giaNet = it.donGia;
            it.soCho = it.soChoStr;
            it.loaiXe = it.tenLoaiXe;
            it.bgNhomNguoiLons = it.bgNhomNguoiLons?.map(
              (dt) => dt.bgNhomNguoiLonId
            );
            it.tong =
              it.hinhThucGiaId === 2
                ? it.giaNet * it.soLuong * it.tongSoKm
                : it.giaNet * it.soLuong;
            return it;
          });
          lstDichVu.push({ id: "NULL" });

          let dichVuTreEms =
            data[1]?.dichVuTreEms?.flatMap(
              (dt) =>
                dt.details?.map((it) => ({
                  tenDichVu: dt.tenDichVu,
                  tranxBgChietTinhOtoId: dt.tranxBgChietTinhOtoId,
                  ...it,
                })) || []
            ) || [];
          let groupDichVu = Object.groupBy(
            data[0]?.dichVuNguoiLons,
            ({ tranxBgChietTinhOtoId }) => tranxBgChietTinhOtoId
          );
          let keyGroupDichVu = Object.keys(groupDichVu);
          let idx = 0;
          let dichVuTEs = [];
          let idxCheckRowSpanDV = 1;
          let idxCheckRowSpanTE = 1;
          //Loop by dich vu
          // if (data.dichVuTreEms && data.dichVuTreEms.length > 0)
          for (let index = 0; index < keyGroupDichVu.length; index++) {
            let tranxBgChietTinhOtoId = keyGroupDichVu[index];
            if (
              dichVuTreEms &&
              dichVuTreEms.length > 0 &&
              dichVuTreEms[0]?.bgNhomTreEmId
            ) {
              let lstTreEm = dichVuTreEms.filter(
                (it) =>
                  it.tranxBgChietTinhOtoId === parseInt(tranxBgChietTinhOtoId)
              );
              let groupDichVuTreEm = Object.groupBy(
                lstTreEm,
                ({ bgNhomTreEmId }) => bgNhomTreEmId
              );
              let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
              // loop by group tre em
              for (let k = 0; k < keyGroupDichVuTreEm.length; k++) {
                let bgNhomTreEmId = keyGroupDichVuTreEm[k];
                let dichVuTEByGroup = lstTreEm.filter(
                  (it) => it.bgNhomTreEmId === parseInt(bgNhomTreEmId)
                );
                let data = dichVuTEByGroup.map((it) => {
                  idx++;
                  it.id = idx;
                  it.tenNhomTE = it.tenNhomTreEm;
                  it.giaNet = it.giaDichVu;
                  it.bgNhomNguoiLons =
                    groupDichVu[tranxBgChietTinhOtoId][0]?.bgNhomNguoiLons;
                  it.thanhTien = (it.phanTram / 100) * it.soLuong * it.giaNet;
                  it.rowSpanDV =
                    idx === idxCheckRowSpanDV
                      ? dataNhomTreEm?.length !== 0
                        ? lstTreEm.length
                        : lstTreEm.length + 1
                      : 0;
                  it.rowSpanTE =
                    idx === idxCheckRowSpanTE
                      ? dataNhomTreEm?.length !== 0
                        ? dichVuTEByGroup.length
                        : 0
                      : 0;
                  return it;
                });
                idxCheckRowSpanTE += dichVuTEByGroup.length;

                dichVuTEs = [...dichVuTEs, ...data];
              }
            }
            if (dataNhomTreEm?.length === 0) {
              dichVuTEs.push({
                id: "addNew",
                tenDichVu: groupDichVu[tranxBgChietTinhOtoId][0].dichVu,
                bgNhomNguoiLons:
                  groupDichVu[tranxBgChietTinhOtoId][0]?.bgNhomNguoiLons,
                giaNet: groupDichVu[tranxBgChietTinhOtoId][0].donGia,
                nccDichVuOtoId:
                  groupDichVu[tranxBgChietTinhOtoId][0].nccDichVuOtoId,
                tranxOtoId: groupDichVu[tranxBgChietTinhOtoId][0].tranxOtoId,
                rowSpanDV: !dichVuTreEms ? 1 : 0,
              });
            }
            idxCheckRowSpanDV += dichVuTEs.length;
          }

          setCountDichVu(lstDichVu.length);
          setDichVus(lstDichVu);
          setDichVusInit(JSON.parse(JSON.stringify(lstDichVu)));
          setDataSource(ds);
          setDataSourceInit({ ...ds });
          setDichVuTreEms(dichVuTEs);
          setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTEs)));
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmGiaApDung = () => {
    dm_gia_ap_dung
      .getDmGiaApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiApDung = () => {
    dm_gia_ap_dung
      .getDmLoaiApDung()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmLoaiApDung(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmVat = () => {
    dm_gia_ap_dung
      .getDmVat()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmVat(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmDoTuoi = () => {
    dm_gia_ap_dung
      .getDmDoTuoi()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmDoTuoi(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleAddServiceTreEm = (row) => {
    let data = [];
    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    let lstTreEmByGroup = lstDichVuTreEm.filter(
      (item) => item.tenDichVu === row.tenDichVu
    );
    lstDichVuTreEm.forEach((dt, idx) => {
      if (row.tenDichVu === dt.tenDichVu && dt.id === "addNew") {
        dt.id = idx;
        dt.phanTram = 0;
        dt.soLuong = 0;
        dt.thanhTien = 0;
        dt.bgNhomTreEmId = 0;
        dt.dmDoTuoiId = 1;
        dt.apDung = false;
        dt.rowSpanDV = lstTreEmByGroup.length === 1 ? 2 : 0;
        dt.rowSpanTE = 0;
        data.push(dt);
        data.push({
          id: "addNew",
          tenDichVu: row.tenDichVu,
          giaNet: row.giaNet,
          nccDichVuOtoId: row.nccDichVuOtoId,
          tranxOtoId: row.tranxOtoId,
          rowSpanDV: 0,
        });
      } else {
        if (
          dt.id === lstTreEmByGroup[0].id &&
          dt.tenDichVu === lstTreEmByGroup[0].tenDichVu
        )
          dt.rowSpanDV = lstTreEmByGroup.length + 1;
        if (dt.id !== "addNew") dt.id = idx;
        data.push(dt);
      }
    });
    setDichVuTreEms(data);
  };

  const handleChangeValueDataSoure = (e) => {
    dataSource[e.target.name] = e.target.value;
    if (e.target.name === "dmGiaApDungId") dataSource.ngayApDungs = [];
    setReset(!reset);
  };

  const handleUpdateService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    setIsOpenTT(true);
    setAction("UPDATE_SERVICE");
    setIdxEdit(index);
  };

  const handleUpdateNCC = () => {
    setMaNcc("");
    setIsOpenTT(true);
    setAction("UPDATE_NCC");
  };

  const handleAddService = () => {
    if (dichVus.length === 1) setMaNcc("");
    setIsOpenTT(true);
    setAction("ADD_SERVICE");
  };

  const handleDeleteService = (record) => {
    let index = dichVus.findIndex((item) => item.id === record.id);
    let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    lstDichVu.splice(index, 1);
    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    if (dataNhomNgLon.length === 0) {
      if (
        !lstDichVu.find(
          (it) =>
            it.nccDichVuOtoId === record.nccDichVuOtoId &&
            it.tranxOtoId === record.tranxOtoId
        )
      )
        lstDichVuTreEm = lstDichVuTreEm.filter(
          (it) =>
            !(
              it.nccDichVuOtoId === record.nccDichVuOtoId &&
              it.tranxOtoId === record.tranxOtoId
            )
        );
    } else {
      lstDichVuTreEm = dichVuTreEms.filter(
        (item) =>
          !(
            item.nccDichVuOtoId === record.nccDichVuOtoId &&
            item.tranxOtoId === record.tranxOtoId &&
            item.bgNhomNguoiLons?.toString() ===
              record.bgNhomNguoiLons?.toString()
          )
      );
    }
    setDichVuTreEms(lstDichVuTreEm);
    setDichVus(lstDichVu);
    setCountDichVu(lstDichVu.length);
    // setReset(!reset);
  };

  const handleChangeDataServiceOto = (dataSelected) => {
    console.log(dataSelected);
    let lstDichVu = [];
    if (action === "UPDATE_NCC") {
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = "";
      lstDichVu = dataSelected?.map((item, idx) => {
        item.id = idx + 1;
        item.soLuong = 0;
        item.tong = 0;
        item.bgNhomNguoiLons = [];
        item.tongSoKm = 0;
        return item;
      });
      lstDichVu.push({ id: "NULL" });
    } else if (action === "ADD_SERVICE") {
      if (
        dataNhomNgLon.length === 0 &&
        dichVus.find(
          (it) =>
            it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
            it.tranxOtoId === dataSelected[0].tranxOtoId
        )
      ) {
        return showNoti("error", t("noti.errorSelectedService"));
      }
      dataSource.tenNcc = dataSelected[0].tenNcc;
      dataSource.dmGiaApDungId = 1;
      dataSource.nccThongTinChungId = dataSelected[0].nccThongTinChungId;
      dataSource.dmVatId = dataSelected[0].dmVatId
        ? dataSelected[0].dmVatId
        : 1;
      dataSource.dmTyGiaId = dataSelected[0].dmTyGiaId;
      dataSource.moTa = "";
      dataSource.tenNcc = dataSelected[0].tenNcc;
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu.splice(dichVus.length - 1, 1);
      lstDichVu.push({
        ...dataSelected[0],
        id: dichVus.length,
        bgNhomNguoiLons: [],
        soLuong: 0,
        tong: 0,
        tongSoKm: 0,
      });
      lstDichVu.push({ id: "NULL" });
    } else {
      if (
        dataNhomNgLon.length === 0 &&
        dichVus.find(
          (it) =>
            it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
            it.tranxOtoId === dataSelected[0].tranxOtoId
        )
      ) {
        return showNoti("error", t("noti.errorSelectedService"));
      }
      lstDichVu = JSON.parse(JSON.stringify(dichVus));
      lstDichVu[idxEdit] = {
        ...dataSelected[0],
        id: idxEdit + 1,
        bgNhomNguoiLons: [],
        soLuong: 0,
        tong: 0,
        tongSoKm: 0,
      };
    }

    let nhomTreEms = JSON.parse(JSON.stringify(dataNhomTreEm));
    let lenDataTE = nhomTreEms.reduce((num, item) => {
      item.details.forEach(() => {
        num++;
      });
      return num;
    }, 0);
    let dichVusByNgLon = lstDichVu;
    if (dataNhomNgLon.length > 0)
      dichVusByNgLon = lstDichVu.filter((it) => it.bgNhomNguoiLons?.length > 0);

    let lstDichVuTreEm = JSON.parse(JSON.stringify(dichVuTreEms));
    let idx = 0;
    let idxCheckRowSpanTE = 1;
    if (action === "UPDATE_NCC") {
      lstDichVuTreEm = [];
      let idxCheckRowSpanDV = 1;
      for (let i = 0; i < dichVusByNgLon.length - 1; i++) {
        if (dataNhomTreEm.length === 0) {
          if (
            lstDichVuTreEm.length === 0 ||
            !lstDichVuTreEm.find(
              (it) =>
                it.nccDichVuOtoId === dichVusByNgLon[i].nccDichVuOtoId &&
                it.tranxOtoId === dichVusByNgLon[i].tranxOtoId
            )
          )
            lstDichVuTreEm = [
              ...lstDichVuTreEm,
              {
                id: "addNew",
                tenDichVu: dichVusByNgLon[i].tenDichVu,
                giaNet: 0,
                nccDichVuOtoId: dichVusByNgLon[i].nccDichVuOtoId,
                tranxOtoId: dichVusByNgLon[i].tranxOtoId,
              },
            ];
        } else if (
          dataNhomNgLon.length === 0 &&
          !lstDichVuTreEm.find(
            (it) =>
              it.nccDichVuOtoId === dichVusByNgLon[i].nccDichVuOtoId &&
              it.tranxOtoId === dichVusByNgLon[i].tranxOtoId
          )
        ) {
          for (let index = 0; index < nhomTreEms.length; index++) {
            let element = nhomTreEms[index];
            let dichVuTEs = element.details?.map((item) => {
              idx++;
              return {
                id: idx,
                tenDichVu: dichVusByNgLon[i].tenDichVu,
                tenNhomTE: element.tenNhom,
                phanTram: 0,
                giaNet: 0,
                soLuong: item.soLuong,
                thanhTien: 0,
                nccDichVuOtoId: dichVusByNgLon[i].nccDichVuOtoId,
                tranxOtoId: dichVusByNgLon[i].tranxOtoId,
                bgNhomTreEmId: element.id,
                bgNhomNguoiLons: [],
                dmDoTuoiId: item.dmDoTuoiId,
                phanTram: 0,
                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                rowSpanTE:
                  idx === idxCheckRowSpanTE ? element.details.length : 0,
              };
            });
            idxCheckRowSpanTE += element.details.length;
            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
          }
          idxCheckRowSpanDV += lenDataTE;
        }
      }
    } else if (action === "ADD_SERVICE") {
      if (dataNhomTreEm.length === 0) {
        if (
          lstDichVuTreEm.length === 0 ||
          !lstDichVuTreEm.find(
            (it) =>
              it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
              it.tranxOtoId === dataSelected[0].tranxOtoId
          )
        )
          lstDichVuTreEm = [
            ...lstDichVuTreEm,
            {
              id: "addNew",
              tenDichVu: dataSelected[0].tenDichVu,
              giaNet: 0,
              nccDichVuOtoId: dataSelected[0].nccDichVuOtoId,
              tranxOtoId: dataSelected[0].tranxOtoId,
            },
          ];
      } else if (
        dataNhomNgLon.length === 0 &&
        !lstDichVuTreEm.find(
          (it) =>
            it.nccDichVuOtoId === dataSelected[0].nccDichVuOtoId &&
            it.tranxOtoId === dataSelected[0].tranxOtoId
        )
      ) {
        idx = lenDataTE * (prevCountDichVu.current - 1);
        idxCheckRowSpanTE = lenDataTE * (prevCountDichVu.current - 1) + 1;
        for (let index = 0; index < nhomTreEms.length; index++) {
          let element = nhomTreEms[index];
          let dichVuTEByGroup = element.details?.map((item) => {
            idx++;
            return {
              id: idx,
              tenDichVu: dataSelected[0].tenDichVu,
              tenNhomTE: element.tenNhom,
              phanTram: 0,
              giaNet: dataSelected[0].giaNet,
              soLuong: item.soLuong,
              thanhTien: 0,
              nccDichVuOtoId: dataSelected[0].nccDichVuOtoId,
              tranxOtoId: dataSelected[0].tranxOtoId,
              bgNhomTreEmId: element.id,
              bgNhomNguoiLons: [],
              dmDoTuoiId: item.dmDoTuoiId,
              phanTram: 0,
              rowSpanDV:
                idx === lenDataTE * (prevCountDichVu.current - 1) + 1
                  ? lenDataTE
                  : 0,
              rowSpanTE: idx === idxCheckRowSpanTE ? element.details.length : 0,
            };
          });
          idxCheckRowSpanTE += element.details.length;
          lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEByGroup];
        }
      }
    } else {
      if (dataNhomTreEm.length === 0) {
        for (let i = 0; i < lstDichVu.length - 1; i++) {
          if (
            lstDichVuTreEm.length === 0 ||
            !lstDichVuTreEm.find(
              (it) =>
                it.nccDichVuOtoId === lstDichVu[i].nccDichVuOtoId &&
                it.tranxOtoId === lstDichVu[i].tranxOtoId
            )
          )
            lstDichVuTreEm = [
              ...lstDichVuTreEm,
              {
                id: "addNew",
                tenDichVu: lstDichVu[i].tenDichVu,
                giaNet: 0,
                nccDichVuOtoId: lstDichVu[i].nccDichVuOtoId,
                tranxOtoId: lstDichVu[i].tranxOtoId,
                rowSpanDV: 1,
              },
            ];
        }
      } else if (dataNhomNgLon.length === 0) {
        lstDichVuTreEm = [];
        let idxCheckRowSpanDV = 1;
        for (let i = 0; i < lstDichVu.length - 1; i++) {
          if (
            !lstDichVuTreEm.find(
              (it) =>
                it.nccDichVuOtoId === lstDichVu[i].nccDichVuOtoId &&
                it.tranxOtoId === lstDichVu[i].tranxOtoId
            )
          ) {
            for (let index = 0; index < nhomTreEms.length; index++) {
              let element = nhomTreEms[index];
              let dichVuTEs = element.details?.map((item) => {
                idx++;
                return {
                  id: idx,
                  tenDichVu: lstDichVu[i].tenDichVu,
                  tenNhomTE: element.tenNhom,
                  phanTram: 0,
                  giaNet: 0,
                  soLuong: item.soLuong,
                  thanhTien: 0,
                  nccDichVuOtoId: lstDichVu[i].nccDichVuOtoId,
                  tranxOtoId: lstDichVu[i].tranxOtoId,
                  bgNhomTreEmId: element.id,
                  bgNhomNguoiLons: [],
                  dmDoTuoiId: item.dmDoTuoiId,
                  phanTram: 0,
                  rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                  rowSpanTE:
                    idx === idxCheckRowSpanTE ? element.details.length : 0,
                };
              });
              idxCheckRowSpanTE += element.details.length;
              lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
            }
          }
          idxCheckRowSpanDV += lenDataTE;
        }
      }
    }

    if (dataSelected.length > 0) setMaNcc(dataSelected[0].maNcc);
    setDichVuTreEms(lstDichVuTreEm);
    setDichVus(lstDichVu);
    setCountDichVu(lstDichVu.length);
    setDataSource(dataSource);
    console.log(dichVuTreEms);
    if (action === "ADD_SERVICE")
      showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
    else showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
  };

  const handleChangeEleDichVu = (id, event) => {
    debugger;
    let index = dichVus.findIndex((item) => item.id === id);
    let name = event.target.name;
    dichVus[index][name] = event.target.value;
    if (name === "soLuong" || name === "tongSoKm") {
      if (event.target.value < 0) dichVus[index][name] = 0;
      dichVus[index].tong =
        dichVus[index].hinhThucGiaId === 2
          ? dichVus[index].giaNet *
            dichVus[index].soLuong *
            dichVus[index].tongSoKm
          : dichVus[index].giaNet * dichVus[index].soLuong;
      if (dataNhomNgLon.length === 0) {
        let dichVuTreEmByNgLon = dichVuTreEms.filter(
          (it) =>
            it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
            it.tranxOtoId === dichVus[index].tranxOtoId &&
            it.id !== "addNew"
        );

        let giaNet =
          parseInt(dichVus[index].tong) /
          (parseInt(soLuongNgLonBG) +
            parseInt(
              dichVuTreEmByNgLon.reduce(
                (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                0
              )
            ));

        for (let i = 0; i < dichVuTreEms.length; i++) {
          let it = dichVuTreEms[i];
          if (
            it &&
            it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
            it.tranxOtoId === dichVus[index].tranxOtoId &&
            it.id !== "addNew"
          ) {
            it.giaNet = giaNet ? giaNet : 0;
            it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
          }
        }
      } else {
        let soLuongNgLon = dataNhomNgLon.reduce((sum1, dt) => {
          if (dichVus[index].bgNhomNguoiLons.includes(dt.id))
            return sum1 + parseInt(dt.soLuong);
          else return sum1;
        }, 0);

        let dichVuTreEmByDichVu = dichVuTreEms.filter(
          (it) =>
            it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
            it.tranxOtoId === dichVus[index].tranxOtoId &&
            it.bgNhomNguoiLons.toString() ===
              dichVus[index].bgNhomNguoiLons?.toString()
        );

        let giaNet =
          parseInt(dichVus[index].tong) /
          (parseInt(soLuongNgLon) +
            parseInt(
              dichVuTreEmByDichVu && dichVuTreEmByDichVu.length > 0
                ? dichVuTreEmByDichVu.reduce(
                    (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                    0
                  )
                : 0
            ));
        for (let i = 0; i < dichVuTreEms.length; i++) {
          let it = dichVuTreEms[i];
          if (
            it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
            it.tranxOtoId === dichVus[index].tranxOtoId &&
            dichVus[index]?.bgNhomNguoiLons?.toString() ===
              it.bgNhomNguoiLons?.toString()
          ) {
            it.giaNet = giaNet ? giaNet : 0;
            it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
          }
        }
      }
    }
    setDichVus([...dichVus]);
  };

  const handleChangeEleDichVuTreEm = (id, event) => {
    let index = dichVuTreEms.findIndex((item) => item.id === id);
    dichVuTreEms[index][event.target.name] = event.target.value;
    if (event.target.name === "soLuong" || event.target.name === "phanTram") {
      if (event.target.value < 0) {
        dichVuTreEms[index][event.target.name] = 0;
      }
      if (dataNhomNgLon.length === 0) {
        let dichVu = dichVus.find(
          (it) =>
            it.nccDichVuOtoId === dichVuTreEms[index].nccDichVuOtoId &&
            it.tranxOtoId === dichVuTreEms[index].tranxOtoId
        );
        let dichVuTreEmByNgLon = dichVuTreEms.filter(
          (it) =>
            it.nccDichVuOtoId === dichVu.nccDichVuOtoId &&
            it.tranxOtoId === dichVu.tranxOtoId &&
            it.id !== "addNew"
        );
        for (let i = 0; i < dichVuTreEms.length; i++) {
          let it = dichVuTreEms[i];
          if (
            it &&
            it.nccDichVuOtoId === dichVu.nccDichVuOtoId &&
            it.tranxOtoId === dichVu.tranxOtoId &&
            it.id !== "addNew"
          ) {
            let giaNet =
              parseInt(dichVu.tong) /
              (parseInt(soLuongNgLonBG) +
                parseInt(
                  dichVuTreEmByNgLon.reduce(
                    (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                    0
                  )
                ));
            it.giaNet = giaNet ? giaNet : 0;
            it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
          }
        }
      } else {
        let dichVu = dichVus.find(
          (dv) =>
            dv.nccDichVuOtoId === dichVuTreEms[index].nccDichVuOtoId &&
            dv.tranxOtoId === dichVuTreEms[index].tranxOtoId &&
            dv.bgNhomNguoiLons.toString() ===
              dichVuTreEms[index].bgNhomNguoiLons?.toString()
        );
        let soLuongNgLon = dataNhomNgLon.reduce((sum1, dt) => {
          if (dichVu?.bgNhomNguoiLons.includes(dt.id))
            return sum1 + parseInt(dt.soLuong);
          else return sum1;
        }, 0);

        let dichVuTreEmByDichVu = dichVuTreEms.filter(
          (it) =>
            it.nccDichVuOtoId === dichVu?.nccDichVuOtoId &&
            it.tranxOtoId === dichVu?.tranxOtoId &&
            it.bgNhomNguoiLons?.toString() ===
              dichVu.bgNhomNguoiLons?.toString()
        );

        let giaNet =
          parseInt(dichVu.tong) /
          (parseInt(soLuongNgLon) +
            parseInt(
              dichVuTreEmByDichVu && dichVuTreEmByDichVu.length > 0
                ? dichVuTreEmByDichVu.reduce(
                    (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                    0
                  )
                : 0
            ));

        for (let i = 0; i < dichVuTreEms.length; i++) {
          let it = dichVuTreEms[i];
          if (
            it.nccDichVuOtoId === dichVu?.nccDichVuOtoId &&
            it.tranxOtoId === dichVu?.tranxOtoId &&
            dichVu.bgNhomNguoiLons?.toString() ===
              it.bgNhomNguoiLons?.toString()
          ) {
            it.giaNet = giaNet ? giaNet : 0;
            it.thanhTien = it.giaNet * it.soLuong * (it.phanTram / 100);
          }
        }
      }
    }

    setDichVuTreEms([...dichVuTreEms]);
  };

  const handleChangeNhomNgLon = (event, row) => {
    debugger;
    let index = dichVus.findIndex((item) => item.id === row.id);
    // let lstDichVu = JSON.parse(JSON.stringify(dichVus));
    let name = event.target.name;
    let value = event.target.value;
    if (
      !dichVus.find(
        (it) =>
          it.bgNhomNguoiLons?.toString() ===
            value?.sort(compareNumber).toString() &&
          it.nccDichVuOtoId === dichVus[index].nccDichVuOtoId &&
          it.tranxOtoId === dichVus[index].tranxOtoId
      )
    ) {
      if (dataNhomTreEm.length > 0) {
        dichVus[index][name] = value?.sort(compareNumber);
        let dichVusByNgLon = dichVus.filter(
          (it) => it.bgNhomNguoiLons?.length > 0
        );
        let idx = 0;
        let idxCheckRowSpanTE = 1;
        let lstDichVuTreEm = [];
        let idxCheckRowSpanDV = 1;
        for (let i = 0; i < dichVusByNgLon.length; i++) {
          let nhomTreEms = dataNhomTreEm.filter((item) =>
            dichVusByNgLon[i].bgNhomNguoiLons?.includes(item.nhomNguoiLonId)
          );
          let lenDataTE = nhomTreEms.reduce((num, item) => {
            item.details.forEach(() => {
              num++;
            });
            return num;
          }, 0);
          for (let index = 0; index < nhomTreEms.length; index++) {
            let element = nhomTreEms[index];
            let dichVuTEs = element.details?.map((item) => {
              idx++;
              return {
                id: idx,
                tenDichVu: dichVusByNgLon[i].tenDichVu,
                tenNhomTE: element.tenNhom,
                phanTram: 0,
                giaNet: 0,
                soLuong: item.soLuong,
                thanhTien: 0,
                nccDichVuOtoId: dichVusByNgLon[i].nccDichVuOtoId,
                tranxOtoId: dichVusByNgLon[i].tranxOtoId,
                bgNhomTreEmId: element.id,
                bgNhomNguoiLons:
                  dichVusByNgLon[i].bgNhomNguoiLons?.sort(compareNumber),
                dmDoTuoiId: item.dmDoTuoiId,
                phanTram: 0,
                rowSpanDV: idx === idxCheckRowSpanDV ? lenDataTE : 0,
                rowSpanTE:
                  idx === idxCheckRowSpanTE ? element.details.length : 0,
              };
            });
            idxCheckRowSpanTE += element.details.length;
            lstDichVuTreEm = [...lstDichVuTreEm, ...dichVuTEs];
          }
          idxCheckRowSpanDV += lenDataTE;
        }
        setDichVuTreEms(lstDichVuTreEm);
        setDichVus([...dichVus]);
      }
    } else {
      showNoti("error", t("noti.errorNhomNgLonSelected"));
    }
  };

  const handleSave = (e) => {
    let rsCheck = handleValidateData();
    if (rsCheck.check) {
      let dataReq = getDataReq();
      console.log(dataReq);
      console.log(JSON.stringify(dataReq));
      otoService
        .saveChietTinhSaleOto(dataReq)
        .then((response) => {
          let action = "add";
          if (bgMapCTSaleHtId !== 0) action = "edit";
          if (response.status === 200 && response.data.code === "00") {
            showNoti(
              "success",
              t(action) + " " + t("noti.success").toLowerCase()
            );
            setDataSourceInit({ ...dataSource });
            setDichVusInit(JSON.parse(JSON.stringify(dichVus)));
            setDichVuTreEmsInit(JSON.parse(JSON.stringify(dichVuTreEms)));
            handleCancel();
            onReset();
          } else {
            showNoti("error", response.data.message);
          }
        })
        .catch(() => {
          showNoti("error", t("system.error"));
        });
    } else {
      showNoti("error", rsCheck.message);
    }
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: 0,
    });
  };

  const getDataReq = () => {
    let dataReq = {
      ngay: ngay,
      baoGiaHanhTrinhId: baoGiaHanhTrinhId,
      baoGiaTongQuanId: baoGiaTongQuanId,
      ngayApDungs: dataSource.ngayApDungs,
      dmDichVuId: dmDichVuId,
      bgCTSaleId: bgChietTinhSaleId,
      bgMapCTSaleHtId: bgMapCTSaleHtId ? bgMapCTSaleHtId : 0, //thêm mới, >0 chỉnh sửa
      nccThongTinChungId: dataSource.nccThongTinChungId,
      tenNcc: dataSource.tenNcc,
      dmGiaApDungId: dataSource.dmGiaApDungId,
      // dmVatId: dataSource.dmVatId,
      dmTyGiaId: dataSource.dmTyGiaId,
      moTa: dataSource.moTa,
      dichVus: dichVus
        .filter((dt) => dt.id !== "NULL")
        .map((it) => {
          return {
            nccDichVuOtoId: it.nccDichVuOtoId,
            tenDichVu: it.tenDichVu,
            dmSoChoId: it.dmSoChoId,
            dmHinhThucGiaId: it.hinhThucGiaId,
            soLuong: it.soLuong,
            dmVatId: dataSource.dmVatId,
            dmLoaiXeId: it.dmLoaiXeId,
            tongSoKm: it.tongSoKm,
            soXe: "",
            giaOtoNhapTay: 0,
            giaNguoiLon: 0,
            tranxOtoId: it.tranxOtoId,
            bgNhomNguoiLons: it.bgNhomNguoiLons?.map((id) => {
              return { bgNhomNguoiLonId: id };
            }),
            dichVuTreEms: handleGroupDichVuTreEm(
              it.nccDichVuOtoId,
              it.tranxOtoId,
              it.bgNhomNguoiLons
            ),
          };
        }),
    };
    return dataReq;
  };

  const handleGroupDichVuTreEm = (
    nccDichVuOtoId,
    tranxOtoId,
    bgNhomNguoiLons
  ) => {
    let lstDichVuTreEm =
      dataNhomNgLon.length === 0
        ? dichVuTreEms.filter(
            (it) =>
              it.id !== "addNew" &&
              it.nccDichVuOtoId === nccDichVuOtoId &&
              it.tranxOtoId === tranxOtoId
          )
        : dichVuTreEms.filter(
            (it) =>
              it.id !== "addNew" &&
              it.nccDichVuOtoId === nccDichVuOtoId &&
              it.tranxOtoId === tranxOtoId &&
              it.bgNhomNguoiLons?.toString() === bgNhomNguoiLons?.toString()
          );
    let groupDichVuTreEm = Object.groupBy(
      lstDichVuTreEm,
      ({ bgNhomTreEmId }) => bgNhomTreEmId
    );
    let keyGroupDichVuTreEm = Object.keys(groupDichVuTreEm);
    let dichVuTEs = keyGroupDichVuTreEm.map((key) => {
      return {
        bgNhomTreEmId: key,
        details: groupDichVuTreEm[key]?.map((it) => {
          return {
            dmDoTuoiId: it.dmDoTuoiId,
            phanTram: it.phanTram,
            tranxOtoId: it.tranxOtoId,
            donGiaNhapTay: 0,
            soLuong: it.soLuong,
            dmVatId: dataSource.dmVatId,
            apDung: true,
          };
        }),
      };
    });
    return dichVuTEs;
    // console.log(groupDichVuTreEm);
    // console.log(dichVuTEs);
  };

  const handleValidateData = () => {
    let result = { check: true, message: "" };
    let soLuongTE = dichVuTreEms.reduce((sum, item) => {
      if (item.id !== "addNew") sum += parseInt(item.soLuong);
      return sum;
    }, 0);
    if (soLuongTE > soLuongTreEmBG) {
      result.check = false;
      result.message = t("noti.errorSoLuongTE");
    } else if (ngay === 0 && dataSource.ngayApDungs.length === 0) {
      result.check = false;
      result.message = t("noti.errorNgayApDungs");
    }
    // else if (dataNhomNgLon.length > 0 && bgNhomNguoiLonId === 0) {
    //   result.check = false;
    //   result.message = t("noti.errorNhomNgLon");
    // }
    return result;
  };

  const handleChangNgayApDung = (e, ngayOrder) => {
    // let name = parseInt(e.target.name);
    let value = e.target.checked;
    if (dataSource.dmGiaApDungId === 2) {
      if (value) dataSource.ngayApDungs.push(ngayOrder);
      else
        dataSource.ngayApDungs = dataSource.ngayApDungs.filter(
          (it) => it !== ngayOrder
        );
    } else {
      if (value) {
        for (let i = ngayOrder; i < bgHanhTrinhs.length + 1; i++) {
          dataSource.ngayApDungs.push(i);
        }
      } else {
        dataSource.ngayApDungs = [];
      }
    }
    console.log(dataSource.ngayApDungs);
    setDataSource({ ...dataSource });
  };

  const columnDichVus = [
    {
      title: t("service") + "/" + t("trip"),
      dataIndex: "tenDichVu",
      key: "tenDichVu",
      width: "15%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link onClick={() => handleAddService()} underline="none">
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 8,
            },
          };
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={record.tenDichVu}
            InputProps={{
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateService(record)}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleDeleteService(record)}
                  />
                </>
              ),
            }}
          />
        );
      },
    },
    {
      title: t("number"),
      dataIndex: "soCho",
      key: "soCho",
      width: "8%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return (
          <TextField
            value={record.soCho}
            name="soCho"
            variant="outlined"
            size="small"
            fullWidth
            // onChange={(e) => onChangeEleDichVu(record.id, e)}
            disabled
          ></TextField>
        );
      },
    },
    {
      title: t("formOfPricing"),
      dataIndex: "hinhThucGia",
      key: "hinhThucGia",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: text,
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("vehicleType"),
      dataIndex: "loaiXe",
      key: "loaiXe",
      width: "8%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: text,
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("numberOfKm"),
      dataIndex: "tongSoKm",
      key: "tongSoKm",
      width: "10%",
      align: "center",
      visible: true,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return (
          <TextField
            value={record.tongSoKm}
            name="tongSoKm"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) => handleChangeEleDichVu(record.id, e)}
          ></TextField>
        );
      },
    },
    {
      title: t("tour.donGia"),
      children: [
        {
          title: t("hotel.priceClass.giaNet"),
          dataIndex: "giaNet",
          key: "giaNet",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: handleData(text),
              props: {
                rowSpan: 1,
              },
            };
            // return handleData(record.fitCuoiTuanNet);
          },
        },
        {
          title: t("tour.vanChuyen.soLuong"),
          dataIndex: "soLuong",
          key: "soLuong",
          width: "10%",
          align: "center",
          render: (text, record) => {
            if (record.id === "NULL") {
              return {
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <TextField
                value={text}
                name="soLuong"
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => handleChangeEleDichVu(record.id, e)}
                fullWidth
              ></TextField>
            );
          },
        },
      ],
      // width: "25%",
      visible: true,
      align: "center",
    },
    {
      title: t("tour.tongChiPhi"),
      dataIndex: "tong",
      key: "tong",
      width: "12%",
      align: "center",
      visible: true,
      render: (text, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return {
          children: handleData(text),
          props: {
            rowSpan: 1,
          },
        };
      },
    },
    {
      title: t("adultGroup"),
      dataIndex: "bgNhomNguoiLons",
      key: "bgNhomNguoiLons",
      width: "15%",
      align: "center",
      visible: dataNhomNgLon.length > 0,
      render: (_, record) => {
        if (record.id === "NULL") {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                name="bgNhomNguoiLons"
                select
                SelectProps={{
                  multiple: true,
                  value: record.bgNhomNguoiLons,
                  onChange: (e) => handleChangeNhomNgLon(e, record),
                }}
              >
                {dataNhomNgLon.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.tenNhom}
                  </MenuItem>
                ))}
              </TextField>
            ),
            props: {
              colSpan: 1,
              // rowSpan: dichVus.length - 1,
            },
          };
        }
        // else {
        //   return {
        //     props: {
        //       rowSpan: 0,
        //     },
        //   };
        // }
      },
    },
  ];
  const columnsTE = [
    {
      title: t("service"),
      dataIndex: "tenDichVu",
      align: "center",
      width: "15%",
      visible: true,
      render: (text, row) => {
        return {
          children: text,
          props: {
            rowSpan: row.rowSpanDV,
          },
        };
      },
    },
    {
      title: t("childrenGroup"),
      dataIndex: "tenNhomTE",
      align: "center",
      width: "15%",
      visible: dataNhomTreEm.length > 0,
      render: (text, row) => {
        return {
          children: (
            <TextField
              variant="outlined"
              value={text}
              size="small"
              fullWidth
              disabled={true}
            ></TextField>
          ),
          props: {
            rowSpan: row.rowSpanTE,
          },
        };
      },
    },
    {
      title: t("age") + " " + t("children"),
      dataIndex: "dmDoTuoiId",
      align: "center",
      width: "15%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            children: (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Link
                  onClick={() => handleAddServiceTreEm(row)}
                  underline="none"
                >
                  {t("addService")}
                </Link>
              </Box>
            ),
            props: {
              colSpan: 6,
            },
          };
        return (
          <>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={22}>
                <TextField
                  variant="outlined"
                  value={text}
                  size="small"
                  name="dmDoTuoiId"
                  fullWidth
                  select
                  disabled={dataNhomTreEm.length > 0}
                  onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
                >
                  {dmDoTuoi.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.do_tuoi}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                {dataNhomNgLon.length === 0 && (
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t("tour.percentage"),
      dataIndex: "phanTram",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: (
            <TextField
              variant="outlined"
              value={text}
              size="small"
              name="phanTram"
              fullWidth
              type="number"
              onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
            />
          ),
          props: {},
        };
      },
    },
    {
      title: t("hotel.priceClass.giaNet"),
      dataIndex: "giaNet",
      align: "center",
      width: "12%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: handleData(text),
          props: {},
        };
      },
    },
    {
      title: t("tour.vanChuyen.soLuong"),
      dataIndex: "soLuong",
      align: "center",
      width: "10%",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };

        return {
          children: (
            <TextField
              value={text}
              variant="outlined"
              name="soLuong"
              size="small"
              type="number"
              fullWidth
              onChange={(e) => handleChangeEleDichVuTreEm(row.id, e)}
            ></TextField>
          ),
          props: {},
        };
      },
    },
    {
      title: t("hotel.roomClass.intoMoney"),
      dataIndex: "thanhTien",
      width: "15%",
      align: "center",
      visible: true,
      render: (text, row) => {
        if (row.id === "addNew" && dataNhomTreEm.length === 0)
          return {
            props: {
              colSpan: 0,
            },
          };
        // if (text)
        return {
          children: handleData(text),
          props: {},
        };
      },
    },
  ];

  const handleClear = () => {
    setMaNcc("");
    setDataSource({ ...dataSourceInit });
    setDichVus(JSON.parse(JSON.stringify(dichVusInit)));
    setDichVuTreEms(JSON.parse(JSON.stringify(dichVuTreEmsInit)));
  };

  const handleCloseModal = () => {
    handleCancel();
    handleClear();
  };

  const handleTinhChiPhiMotPax = (idNhomNgLon) => {
    // debugger;
    let dichVuTreEmByGroup = Object.groupBy(
      dichVuTreEms,
      ({ bgNhomNguoiLons }) => bgNhomNguoiLons
    );
    let keyGroupDichVuTE = Object.keys(dichVuTreEmByGroup);

    let chiPhiMotPaxNL = keyGroupDichVuTE?.reduce((sum, key) => {
      if (key.indexOf(idNhomNgLon) !== -1) {
        let dichVuTreEmByDichVu = dichVuTreEmByGroup[key];
        let dichVu = dichVus.find(
          (dv) => dv.bgNhomNguoiLons?.toString() === key
        );
        let soLuongNgLon = dataNhomNgLon.reduce((sum1, dt) => {
          if (dichVu?.bgNhomNguoiLons.includes(dt.id))
            return sum1 + parseInt(dt.soLuong);
          else return sum1;
        }, 0);

        let chiPhiMotNguoiLon =
          parseInt(dichVu.tong) /
          (parseInt(soLuongNgLon) +
            parseInt(
              dichVuTreEmByDichVu && dichVuTreEmByDichVu.length > 0
                ? dichVuTreEmByDichVu.reduce(
                    (sum, item) => sum + item.soLuong * (item.phanTram / 100),
                    0
                  )
                : 0
            ));
        return sum + parseInt(chiPhiMotNguoiLon);
      } else {
        return sum;
      }
    }, 0);
    // chiPhiMotPax.current = chiPhiMotPaxNL;
    return chiPhiMotPaxNL;
  };

  const handleTinhChiPhiNhomTE = (idTreEm) => {
    let chiPhiTE = dichVuTreEms
      ?.filter((it) => it.bgNhomTreEmId === idTreEm)
      ?.reduce((sum, te) => sum + te.thanhTien, 0);
    chiPhiTE = dataSource.dmGiaApDungId === 1 ? chiPhiTE * soNgay : chiPhiTE;
    return chiPhiTE;
  };

  const handleTinhTongChiPhiTheoTungNhomNgLon = (idNhomNguoiLon, soLuong) => {
    let chiPhiNhomNgLon =
      dataSource.dmGiaApDungId === 1
        ? handleTinhChiPhiMotPax(idNhomNguoiLon) * soLuong * soNgay
        : handleTinhChiPhiMotPax(idNhomNguoiLon) * soLuong;
    let chiPhiTreEm = dataNhomTreEm.reduce((sum, item) => {
      if (item.nhomNguoiLonId === idNhomNguoiLon) {
        return sum + handleTinhChiPhiNhomTE(item.id);
      } else {
        return sum;
      }
    }, 0);
    return chiPhiNhomNgLon + chiPhiTreEm;
  };

  const tongThanhTien = () => {
    return dataNhomNgLon.reduce(
      (sum, item) =>
        sum + handleTinhTongChiPhiTheoTungNhomNgLon(item.id, item.soLuong),
      0
    );
  };

  return (
    <>
      <Modal
        width={1300}
        title={t("tour.oto.capNhatDichVu")}
        footer={null}
        open={isOpen}
        onCancel={handleCloseModal}
      >
        {contextHolder}
        <ModalThongTinNccOto
          isOpen={isOpenTT}
          onIsShow={setIsOpenTT}
          onSaves={handleChangeDataServiceOto}
          action={action}
          maNcc={maNcc}
        />
        <Row style={{ marginTop: "20px" }}>
          <TextField
            variant="outlined"
            fullWidth
            label={t("supplier")}
            value={dataSource.tenNcc}
            required
            InputProps={{
              style: { height: "50px" },
              endAdornment: (
                <>
                  <BorderColorOutlined
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleUpdateNCC()}
                  />
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => handleClear()}
                  />
                </>
              ),
            }}
          ></TextField>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Table
              dataSource={dichVus}
              columns={columnDichVus.filter((item) => item.visible)}
              bordered
              pagination={false}
              summary={(pageData) => {
                // console.log(pageData);
                let totalDonGia = 0;
                let totalChiPhi = 0;

                pageData.forEach(({ soLuong, tong, id }) => {
                  if (id !== "NULL") {
                    totalDonGia += parseInt(soLuong);
                    totalChiPhi += parseInt(tong);
                  }
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5}>
                        {t("tour.tongKet")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={2} align="center">
                        <Tooltip title={t("totalVehicles")}>
                          {totalDonGia}
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} colSpan={1} align="center">
                        <Tooltip title={t("totalCost")}>
                          {handleData(totalChiPhi)}
                        </Tooltip>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              id="dmGiaApDungId"
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("priceApply")}
              value={dataSource.dmGiaApDungId}
              name="dmGiaApDungId"
              onChange={(e) => handleChangeValueDataSoure(e)}
            >
              {dmGia?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.gia_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <FormControl disabled={ngay !== 0} style={{ width: "100%" }}>
              <Row>
                {bgHanhTrinhs?.map((element) => {
                  return (
                    <FormControlLabel
                      key={element.id}
                      control={
                        <Checkbox
                          name={element.id}
                          onChange={(e) =>
                            handleChangNgayApDung(e, element.ngay_order)
                          }
                          checked={
                            ngay !== 0
                              ? (dataSource.dmGiaApDungId === 1 &&
                                  parseInt(element.ngay_order) >= ngay) ||
                                (dataSource.dmGiaApDungId === 2 &&
                                  parseInt(element.ngay_order) === ngay)
                              : dataSource.ngayApDungs?.includes(
                                  element.ngay_order
                                )
                          }
                        />
                      }
                      label={"Ngày " + element.ngay_order}
                    />
                  );
                })}
              </Row>
            </FormControl>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeOfApply")}
              value={1}
              disabled
            >
              {dmLoaiApDung?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.loai_ap_dung}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              select
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("action.apply") + " " + t("thuevat")}
              value={dataSource.dmVatId}
              disabled
              InputProps={{
                style: { minHeight: "50px" },
              }}
            >
              {dmVat?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.vat_str}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              sx={{
                marginBottom: 2,
              }}
              fullWidth
              label={t("typeTour.description")}
              value={dataSource.moTa}
              name="moTa"
              onChange={(e) => handleChangeValueDataSoure(e)}
              //   disabled
            ></TextField>
          </Col>
        </Row>
        <Divider />
        <Row>
          <h3>{t("hotel.table.title")}</h3>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              dataSource={dichVuTreEms}
              columns={columnsTE.filter((it) => it.visible)}
              pagination={false}
            />
          </Col>
        </Row>
        <Row>
          <h3>{t("tour.tongKet")}</h3>
        </Row>
        <Row>
          <Col xs={16} sm={16} md={19} lg={16} xl={16}></Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "18px" }}>
            {dataNhomNgLon.length > 0 ? (
              dataNhomNgLon.map((it, idx) => {
                return (
                  <Row key={idx}>
                    <Col
                      span={11}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {t("tour.chiPhi") + "/ 1 Pax " + it.tenNhom + ":"}
                    </Col>
                    <Col span={3}></Col>
                    <Col
                      span={5}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {dataSource.dmGiaApDungId === 1
                        ? handleData(handleTinhChiPhiMotPax(it.id) * soNgay)
                        : handleData(handleTinhChiPhiMotPax(it.id))}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <>
                <Row>
                  <Col
                    span={11}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {t("tour.chiPhi") + " " + t("adult")}
                  </Col>

                  <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                  <Col
                    span={5}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {handleData(chiPhiNguoiLon)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={11}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {t("tour.tongChiPhi") + " " + t("adult")}
                  </Col>

                  <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                  <Col
                    span={5}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {handleData(tongChiPhiNguoiLon)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={11}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {t("tour.tongChiPhi") + " " + t("children")}
                  </Col>

                  <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                  <Col
                    span={5}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {handleData(chiPhiTreEm)}
                  </Col>
                </Row>
              </>
            )}

            {dataNhomNgLon.length > 0 &&
              dataNhomNgLon.map((it, idx) => {
                return (
                  <Row key={idx}>
                    <Col
                      span={11}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {t("tour.chiPhi") + " " + it.tenNhom + ":"}
                    </Col>
                    <Col span={3}></Col>
                    <Col
                      span={5}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {dataSource.dmGiaApDungId === 1
                        ? handleData(
                            handleTinhChiPhiMotPax(it.id) * it.soLuong * soNgay
                          )
                        : handleData(
                            handleTinhChiPhiMotPax(it.id) * it.soLuong
                          )}
                    </Col>
                  </Row>
                );
              })}
            {dataNhomNgLon.length > 0 &&
              dataNhomTreEm.map((it, idx) => {
                return (
                  <Row key={idx}>
                    <Col
                      span={11}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {t("tour.chiPhi") + " " + it.tenNhom + ":"}
                    </Col>
                    <Col span={3}></Col>
                    <Col
                      span={5}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {handleData(handleTinhChiPhiNhomTE(it.id))}
                    </Col>
                  </Row>
                );
              })}
            {dataNhomNgLon.length > 0 &&
              dataNhomNgLon.map((it, idx) => {
                return (
                  <Row key={idx}>
                    <Col
                      span={11}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {t("tour.tongChiPhi") + " " + it.tenNhom + ":"}
                    </Col>
                    <Col span={3}></Col>
                    <Col
                      span={5}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {handleData(
                        handleTinhTongChiPhiTheoTungNhomNgLon(it.id, it.soLuong)
                      )}
                    </Col>
                  </Row>
                );
              })}
            <Row>
              <Col
                span={11}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {t("tour.tong") + " " + t("hotel.roomClass.intoMoney")}
              </Col>

              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {dataNhomNgLon.length === 0
                  ? handleData(tongChiPhi)
                  : handleData(tongThanhTien())}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginTop: 6 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={10}>
            <Space>
              <Buttons
                variant="contained"
                // type="submit"
                startIcon={<SaveIcon />}
                onClick={(e) => handleSave(e)}
              >
                {t("action.ok")}
              </Buttons>
              <Buttons
                style={{
                  backgroundColor: "#898989",
                  color: "white",
                }}
                key="reset"
                startIcon={<RestartAltIcon />}
                onClick={(e) => handleClear(e)}
              >
                {t("action.reset")}
              </Buttons>
              <Buttons
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={handleCloseModal}
                key="clear"
              >
                {t("action.cancel")}
              </Buttons>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ModalCapNhatOto;
