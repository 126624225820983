import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  notification,
  Tabs,
  theme,
  Row,
  Col,
  Space,
  Affix,
  Popconfirm,
} from "antd";
import FormChietTinh from "./FormChietTinh";
import ChildrenTab from "./ChildrenTab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormProcess from "../FormProcess";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ChietTinhService from "../../../../api/tour/chiettinh.service";
import { useLocation } from "react-router-dom";
import Buttons from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalRenameTab from "./modal/ModalRenameTab";
import { soChoPax } from "../../../../constants";
import { MoreOutlined } from "@ant-design/icons";

const { useToken } = theme;
const styleM = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
};

const ChieTinhTour = ({}) => {
  const { t } = useTranslation();
  const [dataNew, setDataNew] = useState([]);
  const [danhSachKhoangKhach, setDanhSachKhoangKhach] = useState([]);
  const [tongSoNgay, setTongSoNgay] = useState(0);
  const [loaiTong, setLoaiTong] = useState("total");
  const [api, contextHolder] = notification.useNotification();
  const [tyGia, setTyGia] = useState(0);
  const [reset, setReset] = useState(false);
  const [isOpenTab, setIsOpenTab] = useState(false);
  const [tourThoiGianApDungId, setTourThoiGianApDungId] = useState(0);
  const [tourThongTinChungId, setTourThongTinChungId] = useState(0);
  const [tyGiaGocId, setTyGiaGocId] = useState(0);
  const [tourQuanLyKhoangKhachId, setTourQuanLyKhoangKhachId] = useState(0);
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(1);
  const [items, setItems] = useState([]);
  const [resetAll, setResetAll] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [tourChietTinhId, setTourChietTinhId] = useState(0);
  const [keyTabEditName, setKeyTabEditName] = useState(0);
  const [confirmVisible, setConfirmVisible] = useState({});
  const [tabNameEdit, setTabNameEdit] = useState("");
  const newTabName = useRef();
  useEffect(() => {
    let data = {
      typeData: 0,
      tourThongTinChungId: getDvId(),
      tyGiaGocId: getTiGiaGoc(),
      tourTabChietTinhId: 0,
      tourThoiGianApDungId: tourThoiGianApDungId,
      tourQuanLyKhoangKhachId: tourQuanLyKhoangKhachId,
      loaiTong: loaiTong,
    };
    initData(data);
  }, []);
  useEffect(() => {
    let data = {
      typeData: -1,
      tourThongTinChungId: tourThongTinChungId,
      tyGiaGocId: tyGiaGocId,
      tourTabChietTinhId: activeKey,
      tourThoiGianApDungId: tourThoiGianApDungId,
      tourQuanLyKhoangKhachId: tourQuanLyKhoangKhachId,
      loaiTong: loaiTong,
    };
    callApi(data, activeKey);
  }, [reset, activeKey, tourThoiGianApDungId, tourQuanLyKhoangKhachId, tyGia]);
  const getDvId = (value) => {
    let Id = 0;
    if (value === undefined || value === null) {
      if (location.search.length > 0) {
        let id = location.search.split("?=");
        setTyGiaGocId(id[2]);
        Id = id[1].match(/\d+/)[0];
        setTourThongTinChungId(Id);
      }
    } else {
      Id = value;
    }
    return Id;
  };
  const getTiGiaGoc = (value) => {
    let tiGia = 0;
    if (value === undefined || value === null) {
      if (location.search.length > 0) {
        let id = location.search.split("?=");
        setTyGiaGocId(id[2]);
        tiGia = id[2];
      }
    } else {
      tiGia = value;
    }
    return tiGia;
  };
  const saveChangeLoaiTong = (data) => {
    ChietTinhService.changeLoaiTong(data)
      .then(function (response) {
        console.log("ChieuTinh changeLoaiTong: " + response);
      })
      .catch(function (response) {
        if (response.status === 401) {
        }
        api["error"]({
          message: t("stour"),
          description: t("noti.error").toLowerCase(),
          duration: 0,
        });
      });
  };
  const handleChangeTK = (loaiTong) => {
    let data = {
      typeData: 0,
      tourThongTinChungId: tourThongTinChungId,
      tyGiaGocId: tyGiaGocId,
      tourTabChietTinhId: 1,
      tourThoiGianApDungId: tourThoiGianApDungId,
      tourQuanLyKhoangKhachId: tourQuanLyKhoangKhachId,
      loaiTong: loaiTong,
    };
    setLoaiTong(loaiTong);
    callApi(data, activeKey);
    let data_changeLoaiTien = {
      tourChietTinhId: tourThongTinChungId,
      loaiTong: loaiTong,
    };
    saveChangeLoaiTong(data_changeLoaiTien);
  };
  const callApi = (data, key) => {
    ChietTinhService.getChietTinh(data)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let dataRes = response.data.data;
          setDataNew(dataRes);
          let initialItems =
            items?.map((item) => {
              return {
                label: item.label,
                icon: (
                  <a onClick={() => onClickTab(item.key, item.label)}>
                    <MoreOutlined />
                  </a>
                ),
                children: (
                  <ChildrenTab
                    dataSource={dataRes}
                    changeTk={handleChangeTK}
                    tyGia={tyGia}
                    onReset={handleReset}
                    keyTab={activeKey}
                    tourThongTinChungId={data.tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={dataRes.id}
                    tabChietTinhId={item.key}
                    tongSoNgay={tongSoNgay}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                  />
                ),
                key: item.key,
                closeIcon: renderCloseIcon(item.key),
              };
            }) || [];
          setActiveKey(key);
          setItems(initialItems);
          setDanhSachKhoangKhach(
            dataRes.danhSachKhoangKhach?.map((item) =>
              item.dmLoaiKhachId === 1
                ? item.soKhachToiThieu + "Pax"
                : item.soKhachToiThieu + "-" + item.soKhachToiDa + "Pax"
            ) || []
          );
        }
      })
      .catch((response) => console.log("GetChietTinh Error ...", response));
  };
  const initData = (dataReq) => {
    ChietTinhService.getChietTinh(dataReq)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setTourThoiGianApDungId(data.thoiGianApDung[0]?.id);
          setTourChietTinhId(data.id);
          setDataNew(data);
          setTongSoNgay(data.tongSoNgay);
          setTyGia(data.tyGia);
          setDataForm({
            id: data.id,
            thoiGianApDung: data.thoiGianApDung,
            tyGiaQuyDoi: data.tyGiaQuyDoi,
            tyGiaQuyDoiId: data.tyGiaQuyDoiId,
            tyGiaGoc: data.tyGiaGoc,
            tyGia: data.tyGia,
            danhSachKhoangKhach: data.danhSachKhoangKhach,
            tourThongTinChungId: data.tourThongTinChungId,
          });
          let initialItems =
            data.tabChietTinh?.map((item) => {
              return {
                label: item.tenTab,
                icon: (
                  <a onClick={() => onClickTab(item.id, item.tenTab)}>
                    <MoreOutlined />
                  </a>
                ),
                children: (
                  <ChildrenTab
                    dataSource={data}
                    changeTk={handleChangeTK}
                    tyGia={tyGia}
                    onReset={handleReset}
                    keyTab={item.id}
                    tourThongTinChungId={data.tourThongTinChungId}
                    tourThoiGianApDungId={data.thoiGianApDung[0]?.id}
                    tourChietTinhId={item.tourChietTinhId}
                    tabChietTinhId={item.id}
                    tongSoNgay={data.tongSoNgay}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                  />
                ),
                key: item.id,
                closeIcon: renderCloseIcon(item.id),
              };
            }) || [];
          setActiveKey(initialItems[0].key);
          setItems(initialItems);
          setDanhSachKhoangKhach(
            data.danhSachKhoangKhach?.map(
              (item) => soChoPax[parseInt(item.dmSoCho)]
            ) || []
          );
        }
      })
      .catch((response) => console.log("GetChietTinh Error ...", response));
  };
  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };
  const handleReset = () => {
    setReset(!reset);
  };
  const add = () => {
    debugger;
    let lstTab =
      items?.map((item) => ({ id: item.key, tenTab: item.label })) || [];
    lstTab.push({ id: 0, tenTab: newTabName.current });
    let data = {
      tourChietTinhId: tourChietTinhId,
      tabChietTinh: lstTab,
    };
    ChietTinhService.saveTabChietTinh(data)
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          let lenTab = response.data.data.length;
          if (lenTab > 0) {
            const newItems = [...items];
            const newTab = response.data.data[lenTab - 1];
            newItems.push({
              label: newTab.ten_tab,
              icon: (
                <a onClick={() => onClickTab(newTab.id, newTab.ten_tab)}>
                  <MoreOutlined />
                </a>
              ),
              children: (
                <ChildrenTab
                  dataSource={dataNew}
                  changeTk={handleChangeTK}
                  tyGia={tyGia}
                  onReset={handleReset}
                  keyTab={newTab.id}
                  tourThongTinChungId={tourThongTinChungId}
                  tourThoiGianApDungId={tourThoiGianApDungId}
                  tourChietTinhId={newTab.tour_chiet_tinh_id}
                  tabChietTinhId={newTab.id}
                  tongSoNgay={tongSoNgay}
                  loaiTong={loaiTong}
                  tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                />
              ),
              key: newTab.id,
              closeIcon: renderCloseIcon(newTab.id),
            });
            setActiveKey(newTab.id);
            setItems(newItems);
            api["success"]({
              message: t("stour"),
              description:
                t("action.ok") + " " + t("noti.success").toLowerCase(),
              duration: 0,
            });
          }
        } else {
          api["error"]({
            message: t("stour"),
            description: t("action.ok") + " " + t("noti.error").toLowerCase(),
            duration: 0,
          });
        }
      })
      .catch((response) => {
        api["error"]({
          message: t("stour"),
          description: t("action.ok") + " " + t("noti.error").toLowerCase(),
          duration: 0,
        });
      });
  };

  const confirmRemove = (targetKey) => {
    debugger;
    setConfirmVisible({ ...confirmVisible, [targetKey]: true });
  };

  const handleCancel = (targetKey) => {
    debugger;
    setConfirmVisible({ ...confirmVisible, [targetKey]: false });
  };

  const handleConfirm = (targetKey) => {
    remove(targetKey);
    setConfirmVisible({ ...confirmVisible, [targetKey]: false });
  };

  const handleVisibleChange = (targetKey, visible) => {
    setConfirmVisible({ ...confirmVisible, [targetKey]: visible });
  };

  const renderCloseIcon = (targetKey) => (
    <Popconfirm
      title={t("deleteTabCT")}
      visible={confirmVisible[targetKey]}
      onConfirm={() => handleConfirm(targetKey)}
      onCancel={() => handleCancel(targetKey)}
      onVisibleChange={(visible) => handleVisibleChange(targetKey, visible)}
      okText={t("delete")}
      cancelText={t("huy")}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          confirmRemove(targetKey);
        }}
        style={{ marginLeft: 8, fontSize: "16px" }}
      >
        x
      </span>
    </Popconfirm>
  );

  const remove = (targetKey) => {
    let data = {
      id: targetKey,
    };
    ChietTinhService.deleteTabChietTinh(data)
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          const newItems = [...items];
          let idxDel = newItems.findIndex((dt) => dt.key === targetKey);
          if (idxDel !== -1) {
            newItems.splice(idxDel, 1);
            setActiveKey(newItems[0].key);
          }
          setItems(newItems);
          api["success"]({
            message: t("stour"),
            description: t("delete") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: response.data.message,
            duration: 0,
          });
        }
      })
      .catch((err) => {
        console.log("Del tab chiet tinh error ..." + err);
        api["error"]({
          message: t("stour"),
          description: t("delete") + " " + t("noti.error").toLowerCase(),
          duration: 0,
        });
      });
  };
  const onEdit = (targetKey, action) => {
    console.log("====onEdit====", action);
    if (action === "add") {
      // add();
    }
    // else {
    //   confirmRemove(targetKey);
    // }
  };
  const chooseHangTour = (value) => {
    if (value === 1) {
      newTabName.current = t("tour.hangMacDinh");
      onClickTab(0, newTabName.current);
    } else {
      newTabName.current = value;
      add();
    }
  };
  const { token } = useToken();
  const contentStyle = {
    width: "200px",
    height: "150px",
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    border: "1px solid #CFC8C8",
  };
  const items_select = [];
  const MyButton = () => {
    return (
      <Dropdown
        menu={{
          items_select,
        }}
        dropdownRender={(menu) => (
          <div style={contentStyle}>
            <List sx={styleM} component="nav" aria-label="mailbox folders">
              <ListItem button>
                <ListItemText
                  primary={t("add")}
                  onClick={(e) => chooseHangTour(1)}
                />
              </ListItem>
              <Divider />
              {items.map((item) => {
                return (
                  <ListItem
                    button
                    divider
                    onClick={(e) => chooseHangTour(item.label)}
                  >
                    <ListItemText
                      primary={t("Replication") + " " + item.label}
                    />
                  </ListItem>
                );
              })}
              {/* <ListItem button divider onClick={(e) => chooseHangTour(2)}>
                <ListItemText primary={t("tour.nhanBanHang3Star")} />
              </ListItem>
              <ListItem button onClick={(e) => chooseHangTour(3)}>
                <ListItemText primary={t("tour.nhanBanHang4Star")} />
              </ListItem>
              <Divider light />
              <ListItem button onClick={(e) => chooseHangTour(4)}>
                <ListItemText primary={t("tour.nhanBanHang5Star")} />
              </ListItem> */}
            </List>
          </div>
        )}
      >
        <AddCircleIcon type="primary" />
      </Dropdown>
    );
  };

  const handleChangeTyGia = (e) => {
    setTyGia(e);
  };
  const onClickTab = (key, tabName) => {
    debugger;
    setKeyTabEditName(key);
    setTabNameEdit(tabName);
    setIsOpenTab(true);
  };
  const onSaveTab = (value) => {
    debugger;
    // console.log(keyTabEditName);
    const newItems = [...items];
    let idxEdit = newItems.findIndex((dt) => dt.key === keyTabEditName);
    if (idxEdit !== -1) {
      newItems[idxEdit].label = value;
      setItems(newItems);
    } else {
      newTabName.current = value;
      add();
    }
    setIsOpenTab(false);
  };
  const onCancelTab = () => {
    setIsOpenTab(false);
  };
  const handleClear = () => {
    let newArr = [...items];
    for (let i = 0; i < items.length; i++) {
      newArr[i].children.props.dataSource.vanChuyenValues = [];
      newArr[i].children.props.dataSource.khachSanResponse = [];
      newArr[i].children.props.dataSource.nhaHangResponse = [];
      newArr[i].children.props.dataSource.tongVanChuyen = [];
      newArr[i].children.props.dataSource.tongChietTinhKhachSanResponse = [];
      newArr[i].children.props.dataSource.tongChietTinhNhaHangResponse = [];
      newArr[i].children.props.dataSource.tongChietTinhResponses = [];
      newArr[i].children.props.dataSource.tongChiPhiTreEmResponse = [];
    }
    setItems(newArr);
    setResetAll(true);
  };
  const handleCancels = () => {};
  const handleSave = (e) => {
    let lstTab =
      items?.map((item) => ({ id: item.key, tenTab: item.label })) || [];
    let data = {
      tourChietTinhId: tourChietTinhId,
      tabChietTinh: lstTab,
    };
    ChietTinhService.saveTabChietTinh(data)
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          api["success"]({
            message: t("stour"),
            description: t("action.ok") + " " + t("noti.success").toLowerCase(),
            duration: 0,
          });
        } else {
          api["error"]({
            message: t("stour"),
            description: response.data.message,
            duration: 0,
          });
        }
      })
      .catch(function (response) {
        api["error"]({
          message: t("stour"),
          description: response.data.message,
          duration: 0,
        });
      });
  };
  const handleFilter = (name, value) => {
    if (name === "TG") setTourThoiGianApDungId(value);
    if (name === "SK") setTourQuanLyKhoangKhachId(value);
  };

  const tabsItems = items.map((item) => ({
    ...item,
    closeIcon: renderCloseIcon(item.key),
  }));
  const callApiUpdate = (value) => {
    if (value) {
      let data = {
        typeData: -1,
        tourThongTinChungId: tourThongTinChungId,
        tyGiaGocId: tyGiaGocId,
        tourTabChietTinhId: activeKey,
        tourThoiGianApDungId: tourThoiGianApDungId,
        tourQuanLyKhoangKhachId: tourQuanLyKhoangKhachId,
        loaiTong: loaiTong,
      };
      callApi(data, activeKey);
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <FormProcess text="chietTinhTourSanPham" step={2} />
      </div>
      <Divider />
      <div
        style={{
          marginBottom: 16,
          marginTop: 10,
        }}
      >
        <FormChietTinh
          dataForm={dataForm}
          handleTiGia={handleChangeTyGia}
          handleFilter={handleFilter}
          tourThoiGianApDungId={tourThoiGianApDungId}
          setTourThoiGianApDungId={setTourThoiGianApDungId}
          tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
          setTourQuanLyKhoangKhachId={setTourQuanLyKhoangKhachId}
          handleUpdate={callApiUpdate}
        />
        <ModalRenameTab
          isOpen={isOpenTab}
          tabNameEdit={tabNameEdit}
          idTabEdit={keyTabEditName}
          onSave={onSaveTab}
          onCancel={onCancelTab}
          items={items}
        />
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={2} xl={12}></Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
          <span>
            {t("cauHinhSoKhoangKhach") + " : " + danhSachKhoangKhach.toString()}{" "}
          </span>
        </Col>
      </Row>
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        // onEdit={onEdit}
        addIcon={<MyButton />}
        items={tabsItems}
        // onTabClick={onClickTab}
      />
      <Affix offsetBottom={50}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={2} xl={15}></Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={9}>
            <div
              style={{
                textAlign: "center",
                color: "#fff",
                height: 60,
                paddingInline: 50,
                lineHeight: "60px",
                backgroundColor: "rgba(150, 150, 150, 0.2)",
                borderRadius: "4px",
              }}
            >
              <Space>
                <Buttons
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                  onClick={(e) => handleSave(e)}
                >
                  {t("action.ok")}
                </Buttons>
                <Buttons
                  style={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Buttons>
                <Buttons
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleCancels}
                  key="clear"
                >
                  {t("action.cancel")}
                </Buttons>
              </Space>
            </div>
          </Col>
        </Row>
      </Affix>
    </div>
  );
};
export default ChieTinhTour;
