import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, MenuItem, TextField } from "@mui/material";
import { Affix, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Switch } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";

const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ModalPrAction = ({ isOpen, handleCancel, handleFinish, record, action }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [dataSourceTable, setDataSourceTable] = useState([]);
    const [idTable, setIdTable] = useState([]);
    const [tienId, setTienId] = useState();
    const [error, setError] = useState();
    const [ghiChu, setGhiChu] = useState(0);
    useEffect(() => {
        let apiData = {
            id: record.id,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvkhac/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    console.log("Response: ====", data);
                    setTienId(data.maNgoaiTe);
                    if (data.tranxDichVuKhacRequests.length > 0) {
                        let dataFit = data.tranxDichVuKhacRequests;
                        for (let k = 0; k < dataFit.length; k++) {
                            var tuNgay = dataFit[k].tuNgay.split("/");
                            var denNgay = dataFit[k].denNgay.split("/");
                            dataArr.push({
                                id: dataFit[k].id,
                                nccDichVuOtoId: dataFit[k].nccDichVuOtoId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                ve: dataFit[k].ve,
                                foc: dataFit[k].foc,
                                giaNet: dataFit[k].giaNet,
                                giaBan: dataFit[k].giaBan,
                            });
                        }

                        form.setFieldsValue({
                            id: data.id,
                            nccThongTinChungId: data.nccThongTinChungId,
                            maDichVu: data.maDichVu,
                            tenDichVu: data.tenDichVu,
                            dmTyGiaId: data.dmTyGiaId,
                            ghiChu: data.ghiChu,
                            soCho: data.soCho,
                            maNgoaiTe: data.maNgoaiTe,
                            loaiTien: data.loaiTien,
                            ve: data.ve,
                            foc: data.foc,
                            giaBan: data.giaBan,
                            giaNet: data.giaNet,
                            trangThaiBooking: data.trangThaiBooking,
                            items: dataArr,
                        });
                        setGhiChu(data.ghiChu);
                        setDataSourceTable(dataArr);
                        setIdTable(data.id);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [form, record.id]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const onFinish = (values) => {
        form.resetFields();
        setOpen(false);
        var tranxDichVuKhacRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                tranxDichVuKhacRequests.push({
                    nccDichVuNhId: element.nccDichVuNhId,
                    id: element.id,
                    tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                    denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                    giaNet: element.giaNet,
                    giaBan: element.giaBan,
                    trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                    foc: element.foc,
                    poc: element.poc,
                });
            }
        }
        let data = {
            id: values.id,
            nccThongTinChungId: values.nccThongTinChungId,
            maDichVu: values.maDichVu,
            tenDichVu: values.tenDichVu,
            dmTyGiaId: values.dmTyGiaId ? values.dmTyGiaId : 1,
            loaiTien: tiGia[values.dmTyGiaId] ? tiGia[values.dmTyGiaId].label : "",
            ghiChu: values.ghiChu ? values.ghiChu : "",
            tranxDichVuKhacRequests: tranxDichVuKhacRequests,
        };
        handleFinish(open, data);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };

    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <div className="">
                    <Form.Item hidden name="id">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name="nccThongTinChungId">
                        <Input />
                    </Form.Item>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="maDichVu"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("res.code").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <TextField
                                            sx={{
                                                marginTop: 1,
                                            }}
                                            defaultValue={" "}
                                            error={error?.maDichVu}
                                            label={t("res.code") + " *"}
                                            fullWidth
                                        ></TextField>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="tenDichVu"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("validate") + " " + t("res.serviceProvided").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <TextField
                                            sx={{
                                                marginTop: 1,
                                            }}
                                            defaultValue={" "}
                                            error={error?.tenDichVu}
                                            label={t("res.serviceProvided") + " *"}
                                            fullWidth
                                        ></TextField>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="dmTyGiaId"
                                        rules={[
                                            {
                                                required: false,
                                                message: t("validate") + " " + t("exchangeRate.currency").toLowerCase(),
                                            },
                                        ]}
                                    >
                                        <TextField
                                            sx={{
                                                marginTop: 1,
                                            }}
                                            label={t("exchangeRate.currency")}
                                            defaultValue={1}
                                            fullWidth
                                            select
                                        >
                                            {tiGia.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            label={t("hotel.priceRoom.applicationTime") + " *"}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        <Form.Item
                                                            {...restField}
                                                            valuePropName="checked"
                                                            name={[name, "trangThaiBooking"]}
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <Switch defaultChecked={true} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaNet"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        defaultValue={0}
                                                                        label={t("hotel.priceClass.giaNet") + " *"}
                                                                        error={error?.items[key].giaNet}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaBan"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaBan") + " *"}
                                                                        defaultValue={0}
                                                                        error={error?.items[key].giaBan}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "foc"]}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.roomClass.conditionFoc"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        defaultValue={0}
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("hotel.roomClass.conditionFoc")}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={15} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "ve"]}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t("ticket").toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        defaultValue={0}
                                                                        label={t("ticket")}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                        {t("hotel.roomClass.foc")}
                                                    </Col>
                                                </Row>
                                                {fields.length > 0 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(name)}
                                                    />
                                                ) : null}
                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                            <Form.Item
                                name="ghiChu"
                                rules={[
                                    {
                                        required: false,
                                        message: t("validate") + " " + t("notes").toLowerCase(),
                                    },
                                ]}
                            >
                                <TextField
                                    sx={{
                                        marginTop: 2,
                                    }}
                                    defaultValue={" "}
                                    label={t("notes")}
                                    fullWidth
                                    multiline
                                    rows={5}
                                ></TextField>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutMax = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 13,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        var itemsErr = [];
        values.items.forEach((element) => {
            itemsErr.push({
                giaBan: element.giaBan ? false : true,
                giaNet: element.giaNet ? false : true,
            });
        });
        setError({
            maDichVu: values.maDichVu ? false : true,
            tenDichVu: values.tenDichVu ? false : true,
            items: itemsErr,
        });
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("informationRoomClass").toLowerCase() : t("informationRoomClass")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                bodyStyle={{ background: "#F5F5F5" }}
            >
                <Divider />
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    onFinishFailed={onFinishFailed}
                    // initialValues={{
                    //     items: [{}],
                    // }}
                    noValidate
                >
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 16,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                color: "#fff",
                                                height: 60,
                                                paddingInline: 50,
                                                lineHeight: "60px",
                                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <Space>
                                                <Button
                                                    variant="contained"
                                                    onClick={onFinish}
                                                    type="submit"
                                                    startIcon={<Save />}
                                                >
                                                    {t("action.ok")}
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: "#898989",
                                                        color: "white",
                                                    }}
                                                    key="reset"
                                                    startIcon={<RestartAlt />}
                                                    onClick={(e) => handleClear(e)}
                                                >
                                                    {t("action.reset")}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<Delete />}
                                                    onClick={handleCancels}
                                                    key="clear"
                                                >
                                                    {t("action.cancel")}
                                                </Button>
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPrAction;
