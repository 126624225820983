import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";
const layTTDanhSachNhanSuByThongTinChungId = (data) => {
    return axios.post(API_BASE_URL + "op/layTTDanhSachNhanSuByThongTinChungId", data, {
        headers: authHeader(),
    });
};
const layTTDanhSachNhanSuById = (data) => {
    return axios.post(API_BASE_URL + "op/layTTDanhSachNhanSuById", data, {
        headers: authHeader(),
    });
};
const deleteOpNhanSu = (data) => {
    return axios.post(API_BASE_URL + "op/deleteOpNhanSu", data, {
        headers: authHeader(),
    });
};
const saveOpNhanSu = (data) => {
    return axios.post(API_BASE_URL + "op/saveOpNhanSu", data, {
        headers: authHeader(),
    });
};
export default { layTTDanhSachNhanSuByThongTinChungId, layTTDanhSachNhanSuById, deleteOpNhanSu, saveOpNhanSu };
