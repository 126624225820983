import axios from "axios";
import authHeader from "../auth_header";
import { API_BASE_URL } from "../../constants";

const getDmGiaApDung = (data) => {
  return axios.post(API_BASE_URL + "category/getDmGiaApDung", data, {
    headers: authHeader(),
  });
};

const getDmLoaiApDung = (data) => {
  return axios.post(API_BASE_URL + "category/getDmLoaiApDung", data, {
    headers: authHeader(),
  });
};

const getDmVat = (data) => {
  return axios.post(API_BASE_URL + "category/getDmVat", data, {
    headers: authHeader(),
  });
};

const getDmDoTuoi = (data) => {
  return axios.post(API_BASE_URL + "category/getDmDoTuoi", data, {
    headers: authHeader(),
  });
};

const getDmBuaAn = (data) => {
  return axios.post(API_BASE_URL + "category/getDmBuaAn", data, {
    headers: authHeader(),
  });
};

export default {
  getDmGiaApDung,
  getDmLoaiApDung,
  getDmVat,
  getDmDoTuoi,
  getDmBuaAn,
};
