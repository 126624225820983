import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTimKiem from "./FormTimKiem";
import TableBooking from "./TableBooking";
import bookingService from "../../../api/booking/bookingService";

const QuanLyBooking = ({
  activeKey,
  dmTrangThaiId,
  setDmTrangThaiId,
  dataTrangThaiAll,
}) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [dataSearch, setDataSearch] = useState({
    codeOrName: "",
    loaiThoiGian: null,
    tuNgay: null,
    denNgay: null,
  });

  useEffect(() => {
    getDataSource({
      searchMaOrTen: dataSearch.codeOrName ? dataSearch.codeOrName : "",
      loaiThoiGian: dataSearch.loaiThoiGian ? dataSearch.loaiThoiGian : 0, // 1: thời gian tạo, 2 đi tour, 3 kết thúc
      thoiGianBatDau: dataSearch.tuNgay ? dataSearch.tuNgay : "",
      thoiGianKetThuc: dataSearch.denNgay ? dataSearch.denNgay : "",
      maBooking: "",
      tenBooking: "",
      thongTinKhachHang: "",
      chiPhiTour: 0,
      thucHien: "",
      dmTrangThaiId: dmTrangThaiId,
      pageIndex: 1,
      pageSize: 10,
    });
  }, [dmTrangThaiId]);

  const getDataSource = (data) => {
    bookingService
      .getDataDSBooking(data)
      .then((response) => {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data?.map((item, idx) => {
            return {
              ...item,
              id: item.bkThongTinChungId,
              nguoiTao: item.userCreated,
              dieuHanh: item.tenNguoiDieuHanh,
              loaiTien: item.maLoaiTienMacDinh,
              phanTram: item.phanTramLoiNhuan,
              stt: idx + 1,
            };
          });
          setDataSource(data);
        }
      })
      .catch((err) => {
        console.log("Get data booking erorrs " + err);
      });
  };

  const handleSearch = () => {
    const dataReq = {
      searchMaOrTen: dataSearch.codeOrName,
      loaiThoiGian: dataSearch.loaiThoiGian ? dataSearch.loaiThoiGian : 0, // 1: thời gian tạo, 2 đi tour, 3 kết thúc
      thoiGianBatDau: dataSearch.tuNgay ? dataSearch.tuNgay : "",
      thoiGianKetThuc: dataSearch.denNgay ? dataSearch.denNgay : "",

      maBooking: "",
      tenBooking: "",
      thongTinKhachHang: "",
      chiPhiTour: 0,
      thucHien: "",
      dmTrangThaiId: dmTrangThaiId,
      pageIndex: 1,
      pageSize: 10,
    };
    getDataSource(dataReq);
  };

  const handleResetDataSearch = () => {
    setDataSearch({
      codeOrName: "",
      loaiThoiGian: null,
      tuNgay: null,
      denNgay: null,
    });
  };

  return (
    <>
      <FormTimKiem
        activeKey={activeKey}
        dataSearch={dataSearch}
        setDataSearch={setDataSearch}
        setDmTrangThaiId={setDmTrangThaiId}
        dmTrangThaiId={dmTrangThaiId}
        dataTrangThaiAll={dataTrangThaiAll}
        onSearch={handleSearch}
        onReset={handleResetDataSearch}
      />
      <TableBooking dataSource={dataSource} />
    </>
  );
};
export default QuanLyBooking;
