import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const layTTSaleRateKhachSanById = (data) => {
  return axios.post(API_BASE_URL + "booking/layTTSaleRateKhachSanById", data, {
    headers: authHeader(),
  });
};
const saveSaleRateKhachSan = (data) => {
  return axios.post(API_BASE_URL + "booking/saveSaleRateKhachSan", data, {
    headers: authHeader(),
  });
};
export default {
  layTTSaleRateKhachSanById,
  saveSaleRateKhachSan,
};
